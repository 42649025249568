import React, { useState, useEffect } from 'react';
import { View, Pressable } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useTheme } from 'components';
import { Competition } from 'business';
import { getInstance } from 'bridge';
import { useInvitedCompetitions, useBookedCompetitions, useRecentCompetitions, useIsLatestVersion, useStyles, usePreloadUsers } from '../hooks';
import { DoubleFlatList, TabHeader, UserStats, TutorialsMenu, UpdateVersionMenu, CompetitionCard } from '../composites';
import { NavigationProvider } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import WaitingScreen from './WaitingScreen';
import { SectionHeader } from '../composites';

export default function HomeScreen(props: any) {

    const { currentUser } = useAppContext();
    const theme = useTheme();
    const styles = useStyles();

    const [invitedCompetitions, onLoadMoreInvitedCompetitions] = useInvitedCompetitions(currentUser?.id);
    const [bookedCompetitions, onLoadMoreBookedCompetitions] = useBookedCompetitions(currentUser?.id);
    const rootCompetitions = useRootCompetitions(bookedCompetitions);

    const recentCompetitions = useRecentCompetitions(rootCompetitions && rootCompetitions.length === 0 ? currentUser?.id : undefined);
    const isLatestVersion = useIsLatestVersion();

    const playersLoaded = usePreloadPlayers(rootCompetitions);

    const { setTarget, showUser } = useActions();

    useFocusEffect(
        React.useCallback(() => {
            setTarget(null);
        }, [])
    );

    return playersLoaded && (rootCompetitions || recentCompetitions) ? (
        <NavigationProvider>
            <View style={styles.screen}>
                <TabHeader title={StringTable.getString('Home')} />
                <DoubleFlatList
                    header={(
                        <View>
                            {currentUser ? (
                                <Pressable onPress={e => showUser(e, currentUser?.id)}>
                                    <UserStats user={currentUser} style={{ margin: theme["spacing-medium"], marginTop: theme["spacing-large"], marginRight: theme["spacing-extrasmall"] }} />
                                </Pressable>
                            ) : null}
                            <View style={{ alignItems: 'flex-end' }}>
                                {isLatestVersion ? <TutorialsMenu /> : <UpdateVersionMenu />}
                            </View>
                        </View>
                    )}
                    primaryData={rootCompetitions?.length ? rootCompetitions : (recentCompetitions || [])}
                    secondaryData={invitedCompetitions}
                    onPrimaryEndReached={onLoadMoreBookedCompetitions}
                    onSecondaryEndReached={onLoadMoreInvitedCompetitions}
                    primaryHeader={(
                        <SectionHeader
                            header={StringTable.getString(rootCompetitions?.length ? 'Booked' : 'Recent')}
                            counter={rootCompetitions?.length ? `${rootCompetitions.length}` : undefined}
                            sectionId={'event'}
                            isAdmin={true}
                            isPrimary={true}
                            addLabel={StringTable.getString('Create')}
                        />
                    )}
                    secondaryHeader={(
                        <SectionHeader
                            header={StringTable.getString('Invitations')}
                            counter={invitedCompetitions?.length ? `${invitedCompetitions.length}` : undefined}
                            isAdmin={false}
                        />
                    )}
                    renderPrimaryItem={({ item, index }) => (
                        <CompetitionCard competition={item} />
                    )}
                    renderSecondaryItem={({ item, index, horizontal }) => (
                        <CompetitionCard competition={item} style={horizontal ? { marginRight: 0 } : undefined} />
                    )}
                />
            </View>
        </NavigationProvider>
    ) : <WaitingScreen />
}

function usePreloadPlayers(competitions: Competition[] | undefined) {

    const [playerRefs, setPlayerRefs] = useState<string[]>();
    useEffect(() => {
        if (competitions) {
            let players: { [groupId: string]: string[] | undefined } = {};
            for (const competition of competitions) {
                players[competition.id] = competition.participation.userRefs
            }
            setPlayerRefs(Object.values(players).reduce((acc: string[], curr) => curr ? ([...acc, ...curr]) : acc, []));
        }
    }, [competitions]);

    const preloaded = usePreloadUsers(playerRefs);
    return preloaded;
}

function useRootCompetitions(bookedCompetitions: Competition[] | undefined) {
    const [rootCompetitions, setRootCompetitions] = useState<Competition[]>();

    useEffect(() => {
        const fetchRootCompetitions = async () => {
            const rootCompetitions: Competition[] = [];
            const uniqueIds = new Set<string>();

            for (const competition of bookedCompetitions || []) {
                const { settings } = competition;

                if (settings.parentCompetitionRef) {
                    const parentCompetition = await getInstance<Competition>('competitions', settings.parentCompetitionRef);
                    const validCompetition = parentCompetition || competition;

                    if (!uniqueIds.has(validCompetition.id)) {
                        uniqueIds.add(validCompetition.id);
                        rootCompetitions.push(validCompetition);
                    }
                } else {
                    if (!uniqueIds.has(competition.id)) {
                        uniqueIds.add(competition.id);
                        rootCompetitions.push(competition);
                    }
                }
            }
            setRootCompetitions(rootCompetitions);
        };

        bookedCompetitions && fetchRootCompetitions();
    }, [bookedCompetitions]);

    return rootCompetitions;
}
