import { arrayUnique } from '../tools';
import { IUserSettings } from '../interfaces';

export class UserSettings implements IUserSettings {

    title?: string;
    imageUrl?: string;
    hand?: 'left' | 'right' | 'both';
    side?: 'left' | 'right' | 'both';
    bestShot?: string;
    worstShot?: string;
    localeCode?: string;
    region?: string;
    clubRefs?: string[];

    constructor(data: IUserSettings) {
        this.title = data.title;
        this.imageUrl = data.imageUrl;
        this.hand = data.hand;
        this.side = data.side;
        this.bestShot = data.bestShot;
        this.worstShot = data.worstShot;        
        this.localeCode = data.localeCode;
        this.region = data.region;
        this.clubRefs = data.clubRefs;
    }

    serialize() : IUserSettings {
        return {
            title: this.title, 
            imageUrl: this.imageUrl,
            hand: this.hand,
            side: this.side,
            bestShot: this.bestShot, 
            worstShot: this.worstShot, 
            localeCode: this.localeCode,
            clubRefs: this.clubRefs,
            region: this.region
        }
    }

    setImageUrl(url: string) {
        this.imageUrl = url;
    }

    setTitle(title?: string) {
        if (title) {
            this.title = title;
        }
    }

    setBestShot(shot: string) {
        this.bestShot = shot;
    }

    setWorstShot(shot: string) {
        this.worstShot = shot;
    }

    setSide(side: 'left' | 'right' | 'both') {
        this.side = side;
    }

    setHand(hand: 'left' | 'right' | 'both') {
        this.hand = hand;
    }

    setLocaleCode(localeCode: string) {
        this.localeCode = localeCode;
    }

    setRegion(region?: string) {
        if (region) {
            this.region = region;
        }
    }

    followClub(clubRef: string) {
        this.clubRefs = arrayUnique([...(this.clubRefs || []), clubRef]);
    }

    unfollowClub(clubRef: string) {
        const index = this.clubRefs ? this.clubRefs.indexOf(clubRef) : -1;
        if (index >= 0) {
            this.clubRefs?.splice(index, 1);
        }
    }
}
