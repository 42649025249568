import React, { useState } from 'react';
import { View } from 'react-native';
import { useTheme, Subheader, Section, Button, Paragraph, TextInput } from 'components';
import { User } from 'business';
import useSheetStyle from '../../actions/sheets/useSheetStyle';
import { useStyles } from '../../hooks';
import { validatePhoneNumber } from 'tools';
import { searchAsync } from 'bridge';
import UserView from './UserView';

interface Props {
    targetTitle: string,
    style?: any,
    resolve?: (subjectId: string | null) => any, 
}

export default function UserMergeSheet(props: Props) {

    const {
        targetTitle,
        style,
        resolve
    } = props;

    const [selection, setSelection] = useState<User>();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<User[] | undefined>();

    async function search() {
        const queryIsPhoneNumber = validatePhoneNumber(searchQuery);
        const i = await searchAsync<User>('users', queryIsPhoneNumber ? { documentId: searchQuery } : { searchQuery: searchQuery});
        setSearchResults(i);    
    }

    const theme = useTheme();
    const sheetStyle = useSheetStyle();

    return (
        <View style={[sheetStyle, style]}>
            <View style={{flex: 1}}>
                <Subheader>{selection ? `Merge ${selection.getTitle()} into ${targetTitle}` : `Find user to merge into ${targetTitle}`}</Subheader>

                <Section size={'S'} style={{flexDirection: 'row', alignItems: 'center'}}>
                    <TextInput
                        style={{flex: 1}}
                        iconLeft={'ico_search'} 
                        iconRight={'ico_clear'} 
                        placeholder={`Query...`} 
                        value={searchQuery}
                        onChangeText={(t: string) => setSearchQuery(t)}
                        onPressIconRight={() => setSearchQuery('')}
                    />
                    <Button label={'Search'} onPress={search} style={{marginLeft: 6}}/>
                </Section>

                {searchResults?.length ? (
                    <Section>
                        { searchResults.map((item: User) => (
                            <View key={item.id} style={{marginTop: theme["spacing-small"]}}>
                                <UserView user={item} userId={item.id} accountType={item.accountType} onPress={(e: any) => setSelection(item)}/>
                            </View>
                        ))}
                    </Section>
                ) : null }
                { searchResults?.length === 0 ? (
                    <Paragraph>{'No search results'}</Paragraph>
                ) : null }


            </View>
            <Section style={{ flexDirection: 'row' }}>
                <Button 
                    onPress={() => {
                        resolve && resolve(null)
                    }} 
                    mode={'outlined'} 
                    style={{ flex: 1, marginRight: theme["spacing-extrasmall"] }} 
                    label={'Cancel'}
                />
                <Button
                    onPress={(e: any) => {
                        resolve && selection && resolve(selection.id)
                    }}
                    mode={selection ? 'primary' : 'passive'}
                    style={{ flex: 1, marginLeft: theme["spacing-extrasmall"] }}
                    label={'Merge'}
                />
            </Section>
        </View>
    )
}
