import { Competition } from 'business';

export default function useIsCompetitionSchedulable(competition?: Competition, subCompetitions?: Competition[]) {
    if ((competition?.participation.userRefs?.length || 0) >= 4) {
        return true;
    }
    else if (subCompetitions?.find(c => (c.participation.userRefs?.length || 0) >= 4)) {
        return true;
    }
    return false;
}
