import { ILocation } from '../interfaces';

export class Location implements ILocation {

    lat?: number;
    lon?: number;
    geohash?: string;
    region?: string;
    address?: string;

    constructor(data?: ILocation) {
        this.lat = data?.lat;
        this.lon = data?.lon;
        this.geohash = data?.geohash;
        this.region = data?.region;
        this.address = data?.address;
    }

    serialize() : ILocation {
        return {
            lat: this.lat, 
            lon: this.lon, 
            geohash: this.geohash, 
            region: this.region, 
            address: this.address
        }
    }
}
