import React, { useState } from 'react';
import { View, FlatList, ScrollView } from 'react-native';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTheme, Section, TextInput, Button, Paragraph, TextInputNumeric } from 'components';
import { searchAsync, createFromInstance, useBatchedQuery } from 'bridge';
import { User } from 'business';
import { IPressEvent } from 'interfaces';
import MenuSheet from '../../actions/sheets/MenuSheet';
import useModal from '../../actions/useModal';
import UserView from './UserView';
import UserEditorSheet from './UserEditorSheet';
import UserMergeSheet from './UserMergeSheet';
import SavingMessageSheet from '../../actions/sheets/SavingMessageSheet';
import { validatePhoneNumber } from 'tools';

interface Props {
    app?: any, 
    devApp: any,
    style?: any
}
export default function UsersList(props: Props) {

    const { app, devApp, style } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<User[] | undefined>();
    const [users, onLoadMoreUsers] = useBatchedQuery<User>({queryType: 'allUsers'}, app);

    const { pushModal, popModal, popAllModals } = useModal();

    async function search() {
        const queryIsPhoneNumber = validatePhoneNumber(searchQuery);
        const i = await searchAsync<User>('users', queryIsPhoneNumber ? { documentId: searchQuery } : { searchQuery: searchQuery}, app);
        setSearchResults(i);    
    }
    
    async function onPressInstance(e: IPressEvent, user: User) {

        const sheetResult = await pushModal((
            <MenuSheet title={user.settings.title || 'No instance title'}>
                <Button
                    label={'Edit user'}
                    style={{marginTop: theme["spacing-small"]}}
                    onPress={async(e: any) => {
                        const result = await pushModal((
                            <UserEditorSheet
                                user={user}
                                style={{flex: 1}}
                            />
                        ), {...e, pageY: 0});
                        if (result) {
                            const updateUser = httpsCallable(getFunctions(), 'updateUser');
                            pushModal((
                                <SavingMessageSheet title={'Updating user'}/>
                            ), e);
                            await updateUser(result);
                            popAllModals();
                        }
                        else {
                            popModal();
                        }
                    }}
                />
                <Button
                    label={'Consume another user'}
                    style={{marginTop: theme["spacing-small"]}}
                    onPress={async(e: any) => {
                        const result = await pushModal((
                            <UserMergeSheet
                                targetTitle={user.getTitle()}
                                style={{flex: 1}}
                            />
                        ), {...e, pageY: 0});
                        if (result) {
                            const mergeUser = httpsCallable(getFunctions(), 'mergeUser');
                            pushModal((
                                <SavingMessageSheet title={'Merging user'}/>
                            ), e);
                            await mergeUser({targetId: user.id, subjectId: result});
                            popAllModals();
                        }
                        else {
                            popModal();
                        }
                    }}
                />
                <Button 
                    label={'Clone to prank-dev'} 
                    style={{marginTop: theme["spacing-small"]}}
                    onPress={() => {
                        cloneInstanceToDev(user);
                        popAllModals();
                    }}
                />
            </MenuSheet>
        ), e)
        popAllModals();
    }

    async function cloneInstanceToDev(instance: any) {
        createFromInstance(instance, devApp);
    }

    const theme = useTheme();
    return(
        <View style={[style, {flex: 1}]}>
            <Section size={'S'} style={{flexDirection: 'row', alignItems: 'center'}}>
                <TextInput
                    style={{flex: 1}}
                    iconLeft={'ico_search'} 
                    iconRight={'ico_clear'} 
                    placeholder={`Query...`} 
                    value={searchQuery}
                    onChangeText={(t: string) => setSearchQuery(t)}
                    onPressIconRight={() => setSearchQuery('')}
                />
                <Button label={'Search'} onPress={search} style={{marginLeft: 6}}/>
            </Section>

            <View style={{ flex: 1 }}>
                {searchResults?.length ? null : (
                    <FlatList
                        style={{ flex: 1 }}
                        data={users}
                        keyExtractor={item => item.id}
                        onEndReached={onLoadMoreUsers}
                        onEndReachedThreshold={0.1}
                        renderItem={({ item }) => (
                            <UserView user={item} userId={item.id} accountType={item.accountType} onPress={(e: any) => onPressInstance(e, item)} />
                        )}
                    />
                )}

                {searchResults?.length ? (
                    <ScrollView style={{flex: 1}}>
                        { searchResults.map((item: User) => (
                            <View key={item.id} style={{marginTop: theme["spacing-small"]}}>
                                <UserView user={item} userId={item.id} accountType={item.accountType} onPress={(e: any) => onPressInstance(e, item)}/>
                            </View>
                        ))}
                    </ScrollView>
                ) : null }
            </View>

            { searchResults?.length === 0 ? (
                <Paragraph>{'No search results'}</Paragraph>
            ) : null }
            
        </View>
    )
}
