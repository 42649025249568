import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Subheader, Button, useTheme } from 'components';
import { useNavigation } from '../navigation';
import { useIsAdmin, useStyles } from '../hooks';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import { IPressEvent } from 'interfaces';
import { useAppContext } from '../appcontext';

interface Props {
    header?: string, 
    counter?: string, 
    sectionId?: string, 
    style?: any,
    isAdmin?: boolean,
    isPrimary?: boolean,
    canAdd: boolean,
    addLabel?: string
}

SectionHeader.defaultProps = {
    canAdd: true
}
export default function SectionHeader(props: Props) {

    const { header, counter, sectionId, style, isAdmin : isAdminProp, isPrimary, addLabel, canAdd } = props;
    const { currentUserId } = useAppContext();
    const theme = useTheme();
    const isAdmin = useIsAdmin(currentUserId);
    const { addClub, addGroupToTarget, addCompetitionToTarget, addPlayersToTarget, addInvitationsToTarget } = useActions();
    const { navigate } = useNavigation();
    const styles = useStyles();

    async function addCompetition(e: IPressEvent) {
        const competitionId = await addCompetitionToTarget(e);
        competitionId && navigate({competitionId});
    }

    async function addGroup(e: IPressEvent) {
        const groupId = await addGroupToTarget(e);
        groupId && navigate({groupId});
    }

    async function handleAdd(e: IPressEvent, sectionId?: string) {
        switch(sectionId) {
            case 'members':
                return addPlayersToTarget(e);
            case 'players':
                return addPlayersToTarget(e);
            case 'invited':
                return addInvitationsToTarget(e);
            case 'event':
                return addCompetition(e);
            case 'group':
                return addGroup(e);
            case 'club':
                return addClub(e);
            case 'classes':
                //Without navigating there, in this instance
                return addCompetitionToTarget(e);
            default:
                break;
        }
    }

    return(
        <View style={[styles.SectionHeader, {flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}, style]}>
            <View style={{flexDirection: 'row', flex: 1}}>
                {header ? <Subheader>{header}</Subheader> : null }
                {counter ? <Subheader style={{marginLeft: theme["spacing-small"], color: theme["color-ink-soft"]}}>{counter}</Subheader> : null }
            </View>
            { (canAdd && (isAdminProp || (isAdminProp === undefined && isAdmin))) ? <Button icon={'ico_add'} label={`${addLabel || StringTable.getString('Add')} ${StringTable.getString(sectionId)}`} mode={isPrimary ? 'primary' : undefined} onPress={(e: IPressEvent) => handleAdd(e, sectionId)} style={{marginBottom: 5}}/> : null }
        </View>
    )
}