import React from 'react';
import { IPressEvent } from 'interfaces';
import ConfirmSheet from './sheets/ConfirmSheet';
import { StringTable } from 'utils';

export default async function stopRepeatTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet 
            title={StringTable.getString('Confirm stop repeat')} 
            doneLabel={StringTable.getString('Stop repeat')} 
            doneMode={'primary'}
    />), e);
    confirmResult?.confirmed && target?.competition?.setRepeat(false);
    popAllModals();

}
