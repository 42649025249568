import React from 'react';
import { User } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Factory } from '../../factories';
import EventSheet from './sheets/EventSheet/EventSheet';
import SavingMessageSheet from './sheets/SavingMessageSheet';

export default async function addCompetitionToTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User) {

    if (!currentUser) { return };

    //@ts-ignore
    const result : IEventSheetResult | null = await pushModal((
        <EventSheet
            title={StringTable.getString(target?.competition ? 'Create class' : 'Create event')}
            doneLabel={StringTable.getString('Create')}
            initialSettings={{
                groupRef: target?.group?.id, 
                parentCompetitionRef: target?.competition?.id,
                clubRef: target?.club?.id, 
                location: target?.club?.settings.location,
                parentTitle: target?.competition?.settings.title,
                date: target?.competition?.settings.date,
                type: target ? (target.competition ? 'TOURNAMENT' : 'WINNERS COURT') : 'MATCH',
                courts: { 0: { courtId: 0, courtName: 'Court 1', queue: []}}    
            }}
            style={{flex: 1}}
        />
    ), {...e, pageY: 0});

    let competitionId;
    if (result) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Saving')}/>
        ), e);

        const admin = [currentUser.id];
        const userRefs = (result.settings.teamSignup || result.settings.type === 'BIG TOURNAMENT') ? [] : [currentUser.id];
        const invitedRefs = (target?.group?.participation.userRefs || []).filter((invitedRef: string) => !userRefs.includes(invitedRef));
        competitionId = await Factory.makeCompetition(result.settings, { admin, userRefs, invitedRefs });
    }
    popAllModals();
    return competitionId;
}
