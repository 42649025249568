import React, { useState, useCallback, ReactElement } from 'react';
import { View, Dimensions } from 'react-native';
import Constants from 'expo-constants'
import { IPressEvent } from 'interfaces';
import { useTheme } from 'components';
import { useLayout } from '../hooks';
import ModalStack from './ModalStack';
import ModalContext from './ModalContext';

interface Props {
    children: any
}

export type ModalStackEntry = {
    instance: ReactElement,
    resolve: (data: any) => void,
    visible: boolean
}

export default function ModalProvider(props: Props) {

    const { children } = props;
    const theme = useTheme();

    const { layout } = useLayout();

    const [modalStack, setModalStack] = useState<ModalStackEntry[]>([]);

    const createInstance = useCallback((element: ReactElement, pressEvent: IPressEvent, resolve: (value: unknown) => void) => {

        const absolutePosition = (layout === 'desktop') ? true : false;
        return (absolutePosition && pressEvent) ? (
            <View 
                style={[
                    { 
                        backgroundColor: theme["color-card"], 
                        padding: theme["spacing-modal"], 
                        borderRadius: theme["roundness"], 
                        position: 'absolute',
                        height: element?.props?.style?.flex ? '100%' : undefined,
                        width: theme["card-maxwidth"],
                        maxWidth: Dimensions.get('window').width * 0.7                
                    },
                    pressEvent.pageX > Dimensions.get('window').width / 2 ? {
                        right: Dimensions.get('window').width - pressEvent.pageX, 
                        maxWidth: pressEvent.pageX
                    } : {
                        left: pressEvent.pageX,
                        maxWidth: Dimensions.get('window').width - pressEvent.pageX
                    }, 
                    pressEvent.pageY > Dimensions.get('window').height / 2 ? {
                        bottom: Dimensions.get('window').height - pressEvent.pageY,
                        maxHeight: pressEvent.pageY
                    } : {
                        top: pressEvent.pageY,
                        maxHeight: Dimensions.get('window').height - pressEvent.pageY
                    }
                ]}
            >
                { React.cloneElement(element, { resolve }) }
            </View>
        ) : (
            <View 
                style={{
                    flex: element?.props?.style?.flex,
                    marginTop: 'auto', 
                    maxHeight: Dimensions.get('window').height - Constants.statusBarHeight, 
                    backgroundColor: theme["color-card"], 
                    padding: theme["spacing-modal"], 
                    borderRadius: theme["roundness"]
                }}
            >
                { React.cloneElement(element, { resolve }) }
            </View>
        );
    }, [layout]);
        
    const pushModal = useCallback((element: ReactElement, pressEvent: IPressEvent ) => {
        const promise = new Promise((resolve: (value?: any) => void, reject) => {
            const instance = createInstance(element, pressEvent, resolve);
            setModalStack(prev => ([
                ...(prev || []), {
                    instance, resolve, visible: true
                }]));
        });    
        return promise;
    }, [createInstance]);

    const popModal = useCallback(() => {
        setModalStack((prev) => {
          const n = [...(prev || [])];
          if (n.length > 0) {
            n[n.length - 1].visible = false;
          }
          return n;
        });
      }, []);
      
    const popAllModals = useCallback(() => {
        setModalStack((prev) =>
            prev ? prev.map((modal) => ({
                ...modal,
                visible: false,
            })) : []
        );
    }, []);


    return(
        <ModalContext.Provider 
            value={{
                pushModal, 
                popModal, 
                popAllModals
            }}
        >
            {children}

            <ModalStack 
                stack={modalStack}
                setStack={setModalStack}
                popModal={popModal}
                animationType={layout === 'desktop' ? 'none' : 'slide'}
            />
       </ModalContext.Provider>
    )
}
