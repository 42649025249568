import { Club, Competition, Group, Conversation, User, SuperSchedule } from 'business';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import parse from './parse';
import save from './save';

export default async function getInstance<T extends Club | Group | Competition | Conversation | User | SuperSchedule>(collectionId: string, documentId: string, app?: any) : Promise<T | null | undefined> {

    const _classes = {
        "groups": Group,
        "clubs": Club,
        "competitions": Competition,
        "conversations": Conversation, 
        "users": User,
        "schedules": SuperSchedule
    }
    const _class = _classes[collectionId as keyof typeof _classes];

    if (collectionId && documentId) {
        const document = await getDoc(doc(getFirestore(app), collectionId, documentId));
        const instance = document.exists() ? new _class(parse(collectionId, document), save) : null;
        //@ts-ignore
        return instance;
    }

    return undefined;
}
