import { getFirestore, addDoc, collection } from 'firebase/firestore';

export default function saveError(userId: string, error: any, location?: string) {

    let writeError;
    if (error.name && error.name === 'FirebaseError') {
        writeError = {
            location: location || 'unknown',
            message: error.message,
            name: error.name,
            stack: error.stack || null
        }
    }
    else {
        writeError = {
            location: location || 'unknown',
            message: error.message
        }
    }

    const firestore = getFirestore();
    addDoc(collection(firestore, 'errors'), {
        userId: userId,
        date: new Date(),
        error: writeError
    });
}

