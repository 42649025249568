import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import { Competition, Group, Club } from 'business';

export default async function directionsToTarget(target: IActionTarget | null) {

    const instance : Competition | Group | Club | undefined | null = target?.competition || target?.group ||target?.club;
    const location = instance?.settings.location;
                    
    if (location) {        
        const scheme = Platform.select({ 
            ios: 'maps:0,0?q=', 
            android: 'geo:0,0?q=',
            web: 'https://www.google.com/maps/search/?api=1&query='
        });
        const latLng = `${location.lat},${location.lon}`;
        const label = instance?.settings.title;
        const url = Platform.select({
            ios: `${scheme}${label}@${latLng}`,
            android: `${scheme}${latLng}(${label})`,
            web: `${scheme}${latLng}`
        });

        if (url) {
            if (Platform.OS === 'web') {
                window.open(url, '_blank');
            } else {
                Linking.openURL(url);
            }
        }
    }
}
