export default function navigateUrl(navigation: any, url: string) {

    if (!(navigation && url)) return;

    const parts = url.split('/');
    const instanceId = parts.pop();
    const instanceCollection = parts.pop();

    switch (instanceCollection) {
        case 'competitions':
            navigation.navigate('HomeStack', { screen: 'Competition', params: { competitionId: instanceId }});
            break;
        case 'groups':
            navigation.navigate('GroupsStack', { screen: 'Group', params: { groupId: instanceId }});
            break;
        case 'conversations':
            navigation.navigate('ConversationsStack', { screen: 'Conversation', params: { conversationId: instanceId }});
            break;
        case 'clubs':
            navigation.navigate('ClubsStack', { screen: 'Club', params: { clubId: instanceId }});
            break;
        default:
            break;
    }
}
