import React from 'react';
import { View } from 'react-native';
import { User } from 'business';
import { useTheme, Padel, Headline, Caption, Graph } from 'components';
import { StringTable } from 'utils';
import { useMatchHistory, useStyles } from '../hooks';

interface Props {
    user: User, 
    defaultTitle?: string,
    style?: any
}

export default function UserStats(props: Props) {

    const { user, defaultTitle, style } = props;

    const theme = useTheme();
    const styles = useStyles();
    const history = useMatchHistory(user?.id, user?.state.matchHistory);

    return (
        <View style={style}>
            <View style={[styles.userStatsSection, {alignItems: 'center'}]}>
                <Padel size={'XL'} imageBackground={user?.settings.imageUrl} showLogo={user?.settings.imageUrl ? false : true} strokeWidth={2} strokeColor={theme["color-card"]} style={{marginRight: theme["spacing-medium"]}}/>
                <Headline numberOfLines={2} style={{fontSize: 28, lineHeight: 32, flex: 1}}>{user?.settings.title || user?.pName || defaultTitle || StringTable.getString("Welcome to Padel iD")}</Headline>
            </View>

            <View style={styles.userStatsSection}>
                <View style={{marginBottom: -9, marginRight: theme["spacing-large"]}}>
                    <Caption>{StringTable.getString('RANK')}</Caption>
                    <Headline style={{color: theme["color-primary"]}}>{`${Math.round(user?.state.rating || 1000)}`}</Headline>
                </View>

                <Graph 
                    data={history.map((match: any) => match.rating)}
                    dots={history.map((match: any) => match.result)}
                    domainPadding={[-20, 0]}
                    style={{flex: 1}}
                />
            </View>
        </View>            
    );
}
