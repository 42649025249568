import { getFirestore, doc, updateDoc } from "firebase/firestore";
import Constants from 'expo-constants'

const __ISDEV__ = __DEV__ || Constants.appOwnership === 'expo' || window?.location?.href?.includes('localhost') || window?.location?.href?.includes('192') || window?.location?.href?.includes('prank-dev') ? true : false;

class _StringTable {

    constructor() {
        this.language = 'en';
        this.missing = {};
        this.table = null;
        this.dayLabels = [];
        this.shortDayLabels = [];
        this.shortMonthLabels = [];
    }

    setStringTable(table) {
        this.table = table;
        this.dayLabels = this.createDayLabels();
        this.shortDayLabels = this.createShortDayLabels();
        this.monthLabels = this.createMonthLabels();
        this.shortMonthLabels = this.createShortMonthLabels();
        this.participationLabelMap = this.createParticipationLabelMap();
    }

    setLanguage(lang) {
        const newLanguage = lang.substr(0, 2);
        if (this.language !== newLanguage) {
            this.language = lang.substr(0, 2);
            this.dayLabels = this.createDayLabels();
            this.shortDayLabels = this.createShortDayLabels();
            this.monthLabels = this.createMonthLabels();
            this.shortMonthLabels = this.createShortMonthLabels();
            this.participationLabelMap = this.createParticipationLabelMap();
            return true;
        }
        return false;
    }

    hasLanguage(lang) {
        const l = lang.substr(0, 2);
        return this.table.hasOwnProperty(l);
    }

    getString(inString) {
        if (!inString) {
            return '';
        }

        if (!this.table) {
            return inString;
        }

        /*
        const phrase = inString.replace('.', '_');
        if (this.table['en'] && !this.table['en'].hasOwnProperty(phrase)) {
            if (__ISDEV__) {
                console.log(phrase);
                console.log('[updateDoc]: StringTable')
                updateDoc(doc(getFirestore(), 'system/stringtable'), { [`en.${phrase}`]: ''});
            }
        }
        */

        const table = this.table.hasOwnProperty(this.language) ? this.table[this.language] : this.table['en'];
        if (table[inString]) {
            return table[inString];
        }
        else {
            return this.table['en'][inString] || inString;
        }
    }

    getSystemMessage(systemMessage) {
        let message = systemMessage;
        message = message.replace('<competition.clear>', this.getString('cleared the event. All results have been cancelled'));
        message = message.replace('<competition.start>', this.getString('Started the event'));
        message = message.replace('<competition.match>', this.getString('match'));
        message = message.replace('<competition.matchchanged>', this.getString('changed'));
        message = message.replace('<competition.matchsaved>', this.getString('saved'));
        message = message.replace('<conversation.created>', this.getString('created'));
        message = message.replace('<conversation.added>', this.getString('added'));
        message = message.replace('<conversation.removed>', this.getString('removed'));
        message = message.replace('<conversation.joined>', this.getString('joined'));
        message = message.replace('<conversation.left>', this.getString('left'));
        return message;
    }

    _parseAs(str, userId) {
        if (str.startsWith('+')) {
            let i = str.indexOf(':');
            if (str.substring(0, i) === userId) {
                return this.getString('you')
            }
            else {
                return str.substring(i+1).trim();
            }
        }
        else {
            return this.getString(str)
        }
    }
    
    parseAs(str, userId) {

        let parsedString = str;
        let match;
        while (match = parsedString?.match(/{([^}]+)}/)) {
            parsedString = parsedString.replace(match[0], this._parseAs(match[1], userId));
        }
        return parsedString;
    }


    createDayLabels() {
        return [
            this.getString('Sunday'),
            this.getString('Monday'),
            this.getString('Tuesday'),
            this.getString('Wednesday'),
            this.getString('Thursday'),
            this.getString('Friday'),
            this.getString('Saturday')
        ];
    }

    createShortDayLabels() {
        return [
            this.getString('Sun'),
            this.getString('Mon'),
            this.getString('Tue'),
            this.getString('Wed'),
            this.getString('Thu'),
            this.getString('Fri'),
            this.getString('Sat')
        ];
    }

    createMonthLabels() {
        return [
            this.getString('January'),
            this.getString('February'),
            this.getString('March'),
            this.getString('April'),
            this.getString('May'),
            this.getString('June'),
            this.getString('July'),
            this.getString('August'),
            this.getString('September'),
            this.getString('October'),
            this.getString('November'),
            this.getString('December')    
        ];
    }

    createShortMonthLabels() {
        return [
            this.getString('Jan'),
            this.getString('Feb'),
            this.getString('Mar'),
            this.getString('Apr'),
            this.getString('May'),
            this.getString('Jun'),
            this.getString('Jul'),
            this.getString('Aug'),
            this.getString('Sep'),
            this.getString('Oct'),
            this.getString('Nov'),
            this.getString('Dec')
        ];
    }

    createParticipationLabelMap() {
        return {
            'OPEN': this.getString('Join'),
            'JOINED': this.getString('Joined'),
            'RESERVE': this.getString('Reserve'),
            'FULL': this.getString('Full'),
            'UNDETERMINED': ''
        }
    }


}

const StringTable = new _StringTable();
export default StringTable;
