import { useMemo } from 'react';
import { Participation } from 'business';
import { StringTable } from 'utils';
import { arrayUnique } from 'tools';

export default function useCompetitionSections(participation?: Participation, slots?: number, teamSignup?: boolean) {
    
    const playerData = usePlayerData(participation?.userRefs, teamSignup);
    const reservesData = usePlayerData(participation?.reservesRefs, teamSignup);
    const invitedData = usePlayerData(participation?.invitedRefs, false);

    const participationData : ISectionListSection[] = useMemo(() => {

        const p = [];
        p.push({ 
            id: 'players',
            header: StringTable.getString('Players'),
            counter: `${participation?.userRefs?.length || 0}${slots ? `/${slots}`: ''}`,
            data: playerData || []
        });
        reservesData?.length && p.push({
            id: 'reserves',
            header: `${StringTable.getString('Reserves')}`,
            counter: `${participation?.reservesRefs?.length || 0}`,
            data: reservesData
        });
        p.push({
            id: 'invited',
            header: `${StringTable.getString('Invited')}`,
            counter: `${participation?.invitedRefs?.length || 0}`,
            data: invitedData || []
        });
        return p;
    }, [playerData, reservesData, invitedData, slots]);

    return participationData;
}

function usePlayerData(userRefs?: string[], fixedTeams?: boolean) {

    const shapedData = useMemo(() => {

        if (!userRefs) {
            return;
        }

        const guaranteedUniqueUserRefs = arrayUnique(userRefs);

        let players : Array<string | string[]> = [];
        if (fixedTeams && guaranteedUniqueUserRefs) {
            for (var i=0; i<guaranteedUniqueUserRefs.length; i+=2) {
                const el = [guaranteedUniqueUserRefs[i], guaranteedUniqueUserRefs[i+1]];
                players.push(el);
            }                        
        }
        else if (guaranteedUniqueUserRefs) {
            players = guaranteedUniqueUserRefs;
        }
        return players;

    }, [userRefs && JSON.stringify(userRefs), fixedTeams]);

    return shapedData;
}
