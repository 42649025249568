import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTheme, Paragraph, Caption, Icon, Popup } from 'components';
import { useUser } from 'bridge';
import { ConversationMessage } from 'business';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';

interface Props {
    message: ConversationMessage,
    senderId: string,
    showSenderName?: boolean,
    onPressDelete: () => void,
    onPressReaction: (reaction: string) => void, 
    onPressCopy: () => void,
    style?: any
}

export default function ConversationMessageView(props: Props) {

    const {
        message,
        senderId,
        showSenderName,
        onPressDelete, 
        onPressReaction,
        onPressCopy,
        style
    } = props;
    
    const senderIdentity = useUser(senderId);
    const { currentUser } = useAppContext();
    const theme = useTheme();

    const [showPopup, setShowPopup] = useState(false);

    return message && senderIdentity ? (
        <View style={style}>
            <TouchableOpacity 
                style={[
                    {alignSelf: 'flex-start', maxWidth: '80%', marginTop: theme["spacing-small"], padding: theme["spacing-medium"], borderRadius: theme["roundness"], backgroundColor: theme["color-message"]},
                    currentUser?.id === senderId && {marginLeft: 'auto', backgroundColor: theme["color-message-mine"]},
                ]}
                onLongPress={() => setShowPopup(true)}
            >
                {showSenderName && <Caption weight={'bold'} style={{color: theme["color-primary"]}}>{senderIdentity.getTitle()}</Caption>}
                <Paragraph weight={'book'} style={{color: theme["color-message-pen"]}}>{message.getMessage()}</Paragraph>
                <Caption style={{marginTop: 4, marginLeft: 0, opacity: 0.6, color: theme["color-message-pen"]}}>{message.getTimeStamp(StringTable.shortDayLabels, StringTable.shortMonthLabels, StringTable.getString('Yesterday'))}</Caption>
                { message.reactions ? (
                    <View style={{position: 'absolute', bottom: -9, left: theme["spacing-medium"]-2, right: theme["spacing-medium"], overflow: 'hidden'}}>
                        <View style={{padding: 4, paddingBottom: 2, alignSelf: 'flex-start', flexDirection: 'row', borderRadius: theme["roundness"], backgroundColor: theme["color-message"], borderWidth: 1, borderColor: theme["color-paper"]}}>
                            {Object.keys(message.reactions).map((reactionType: string) => (
                                <Paragraph key={reactionType} style={{fontSize: 10, lineHeight: 13, color: theme["color-ink-soft"], marginRight: theme["spacing-extrasmall"]}}>{`${reactionType}${(message.reactions?.[reactionType]?.length || 0) > 1 ? (' ' + message.reactions?.[reactionType]?.length) : ''}`}</Paragraph>
                            ))}
                        </View>
                    </View>

                ) : null }
            </TouchableOpacity>
            <Popup visible={showPopup} onDismiss={() => setShowPopup(false)}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: theme["spacing-small"], borderRadius: theme["roundness"], backgroundColor: theme["color-message"]}}>
                    <View style={{flexDirection: 'row'}}>
                        {['👍', '❤️', '🍿','🎾', '🥲', '🤯' ].map((reactionType: string) => (
                            <TouchableOpacity 
                                key={reactionType}
                                onPress={() => {
                                    setShowPopup(false);
                                    onPressReaction(reactionType);
                                }}
                            >
                                <Paragraph style={{fontSize: 25, lineHeight: 32, color: theme["color-message-pen"], marginRight: theme["spacing-medium"]}}>{reactionType}</Paragraph>
                            </TouchableOpacity>
                        ))}
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity 
                            onPress={() => {
                                setShowPopup(false);
                                onPressCopy();
                            }}
                            style={{marginRight: theme["spacing-medium"], marginTop: 2}}
                        >
                            <Icon name={'ico_copy'} size={29} color={theme["color-ink"]}/>
                        </TouchableOpacity>
                        {senderId === currentUser?.id ? (
                            <TouchableOpacity 
                                onPress={() => {
                                    setShowPopup(false);
                                    onPressDelete();
                                }}
                            >
                                <Icon name={'ico_delete'} size={32} color={theme["color-ink"]}/>
                            </TouchableOpacity>
                        ) : null }                                            
                    </View>

                </View>
            </Popup>
        </View>
    ) : null;
}
