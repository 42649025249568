import React, { useMemo, ReactElement } from "react";
import { View, FlatList, FlatListProps, Text } from "react-native";

interface Item {
    sectionId?: string,
    renderer: 'header' | 'item' | 'null',
    header?: string,
    counter?: string,
    item?: any
}

interface Props extends Omit<FlatListProps<any>, 'renderItem'> {
    style?: any,
    data: ISectionListSection[];
    numColumns: number;
    renderSectionItem: (item: any, index: number, sectionId?: string) => ReactElement | null,
    renderSectionHeader: (header: string, counter?: string, sectionId?: string) => ReactElement | null,
    keyExtractor: (item: any, index: number, sectionId?: string) => string,
}

SectionList.defaultProps = {
    renderSectionItem: (item: any, index: number, sectionId?: string) => (
        <Text>{item.id}</Text>
    ),
    renderSectionHeader: (header: string, counter?: string, sectionId?: string) => (
        <Text>{header}</Text>
    )
};

/**
 * Renders a sectioned flatlist by formatting the incoming data such 
 * that the section header is always on the first index in any numColumns tuple,
 * the numColumns - 1 items after are null, and each section is padded to make
 * up even numColumns tuples.
 */
export default function SectionList(props: Props) {

    const {
        style,
        data, 
        numColumns, 
        renderSectionItem, 
        renderSectionHeader,
        keyExtractor, 
        ...otherProps
    } = props;

    const renderData = useMemo(() => {
        if (data) {
            const formattedData: Item[] = data.reduce((acc: Item[], section: ISectionListSection) => {
                acc.push({ renderer: 'header', header: section.header, counter: section.counter, sectionId: section.id });
                for (let i = 0; i < (numColumns || 1) - 1; i++) {
                    acc.push({ renderer: 'null', sectionId: section.id });
                }
                section.data.forEach((item: any) => {
                    acc.push({ renderer: 'item', item, sectionId: section.id })
                });
                for (let i = 0; i < (numColumns || 1) - section.data.length % (numColumns || 1); i++) {
                    acc.push({ renderer: 'null', sectionId: section.id });
                }
                return acc;
            }, []);
            return formattedData;
        }
        return [];
    }, [data, numColumns]);

    const _renderItem = ({ item, index }: { item: Item, index: number }) => (
        <View style={{flex: 1}}>
            {item.renderer === 'header' && item.header ? 
            renderSectionHeader(item.header || '', item.counter || '', item.sectionId) : (
                (item.renderer === 'item' && item.item) ? renderSectionItem(item.item, index, item.sectionId) : null
            )}
        </View>
    );

    return (
        <FlatList
            data={renderData}
            key={numColumns}
            numColumns={numColumns}
            style={style}
            renderItem={_renderItem}
            keyExtractor={(item, index) => item.header || item.item && keyExtractor(item.item, index, item.sectionId) || `${index}`}
            {...otherProps}
        />            
    );
};
