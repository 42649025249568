import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Subheader, Section, Bullet, Paragraph, Pressable, Padel, useTheme, Sticker } from 'components';
import { Competition } from 'business';
import { capitalize } from 'tools';
import { StringTable } from 'utils';
import { useStyles, useUsers, useParticipationPortraits, useParticipationTitles, useClub, useSubCompetitions } from '../hooks';
import { useNavigation } from '../navigation';
import { useAppContext } from '../appcontext';

interface Props {
    competition: Competition,
    competitionTitle?: string,
    onPress?: any,
    style?: any
}

export default function CompetitionCard(props: Props) {

    const { competition, competitionTitle, style, onPress } = props;
    const { settings, participation } = competition || {};

    const theme = useTheme();
    const styles = useStyles();
    const { navigate } = useNavigation();
    const { currentUser, simpleRendering } = useAppContext();
    const userId = currentUser?.id;
    const club = useClub(competition?.settings.clubRef);
    const subCompetitions = useSubCompetitions(competition.settings.type === 'BIG TOURNAMENT' ? competition.id : undefined);

    const users = useUsers(simpleRendering ?  undefined : participation?.userRefs);
    let titlesString = useParticipationTitles(users) || participation?.userRefs?.reduce((acc, curr, index) => {
        return acc + `${index === 0 ? '' : ', '}${curr}`;
    }, '');
    if (competition.settings.type === 'BIG TOURNAMENT') {
        titlesString = subCompetitions?.reduce((acc, curr, index) => {
            return acc + `${index === 0 ? '' : ', '}${curr.getTitle(false)}`;
        }, '');
    }

    const portraits = useParticipationPortraits(users);

    let stickerColor;
    let stickerText;
    if (competition.state.started) {
        stickerColor = competition.state.closed ? theme["color-ink-soft"] : theme["color-negative"];
        stickerText = competition.state.closed ? StringTable.getString('ENDED') : StringTable.getString('LIVE');
    }
    else if (competition.settings.type === 'BIG TOURNAMENT') {
        stickerColor = subCompetitions?.find(c => c.participation.isPlayer(userId)) ? theme["color-positive"] : ( 
            subCompetitions?.find(c => c.participation.isReserve(userId)) ? theme["color-neutral"] : theme["color-ink-soft"] 
        );
        stickerText = `${subCompetitions?.reduce((acc, curr) => acc + (curr.participation.userRefs?.length || 0), 0) || 0} ${StringTable.getString('PLAYERS')}`;
    }
    else {
        stickerColor = participation?.isPlayer(userId) ? theme["color-positive"] : ( participation?.isReserve(userId) ? theme["color-ink-soft"] : theme["color-ink-soft"] );
        stickerText = participation?.isReserve(userId) ? StringTable.getString('RESERVE') : `${participation?.userRefs?.length || 0}${settings?.slots ? '/' + settings.slots : ''}`;
    }
    
    return(
        <TouchableOpacity style={[styles.CompetitionCard, style]} onPress={(e: any) => onPress ? onPress(e) : navigate({competitionId: competition.id})}>
            <View style={{flexDirection: 'row'}}>
                <Subheader style={{flex: 1}} numberOfLines={1}>{competitionTitle || settings?.getTitle()}</Subheader>
                <Sticker startColor={stickerColor} label={stickerText}/>
            </View>
            <Section size={'XS'}>
                <Bullet size={'M'} style={{width: '100%'}} icon={'ico_calendar'} label={capitalize(settings?.getDateTimeString(StringTable.shortDayLabels, StringTable.shortMonthLabels))} color={theme['color-ink']}/>
                <Bullet size={'M'} style={{width: '100%', marginTop: 3}} icon={'ico_location'} label={club?.settings.getTitle() || StringTable.getString('No location')} color={theme['color-ink']}/>
            </Section>
            { simpleRendering ? null : (
                <Section style={{marginLeft: 0, alignSelf: 'stretch', flexDirection: 'row'}}>
                    {portraits.map((p: string) => (
                        <Padel key={p} size={'S'} imageBackground={p} showLogo={false}/>
                    ))}
                    <Paragraph style={{flex: 1, marginLeft: portraits.length ? 4 : 0}} numberOfLines={1} weight={'book'}>{titlesString}</Paragraph>
                </Section>
            )}
        </TouchableOpacity>
    )
}






