import { useState, useEffect } from 'react';
import { Dimensions, useWindowDimensions } from 'react-native';

export default function useLayout() {

    function getLayout(width: number) {
        return {
            layout: width > 500 ? 'desktop' : 'mobile', 
            columns: width > 800 ? 2 : 1
        }
    }
    const [layout, setLayout] = useState(getLayout(Dimensions.get('window').width));
    const { width } = useWindowDimensions();

    useEffect(() => {
        const newLayout = getLayout(width);
        setLayout(prev => prev.layout === newLayout.layout && prev.columns === newLayout.columns ? prev : getLayout(width));
    }, [width]);

    return layout;
}