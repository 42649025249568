import React, { useState, useEffect, useRef } from 'react';
import { View, Platform } from 'react-native';
import { Video as ExpoVideo, ResizeMode } from 'expo-av';
import { useAppContext } from '../appcontext';
import { useStyles } from '../hooks';
import { Pressable, Paragraph, IconButton, useTheme } from 'components';
import { StringTable } from 'utils';


export default function Tutorials(props: any) {

    const { onDismiss } = props;
    const { tutorials } = useAppContext();
    const theme = useTheme();
    const styles = useStyles();
    const videoRef = useRef<any>();
    const [source, setSource] = useState();
    const [title, setTitle] = useState(StringTable.getString('How it works'));

    useEffect(() => {
        setTitle(tutorials?.[0]?.title || StringTable.getString('How it works'));
        setSource(tutorials?.[0]?.url || null);
    }, [tutorials]);

    useEffect(() => {
        Platform.OS === 'web' && videoRef.current?.load();
    }, [source]);

    return(
        <View style={{flex: 1}}>
            <View style={{position: 'absolute', top: 12, left: 12, zIndex: 2, flexDirection: 'row'}}>
                <IconButton mode={'film'} name={'ico_clear'} onPress={onDismiss}/>
            </View>
            <View style={{flex: 1, backgroundColor: 'pink'}}>
                {source ? ( Platform.OS === 'web' ?
                    <View style={styles.tutorialsPlayer}>
                        <video style={{width: '100%', height: '100%'}} src={source} controls autoPlay/>
                    </View>
                    : 
                    <ExpoVideo
                        ref={videoRef}
                        style={styles.tutorialsPlayer}
                        source={{uri: source}}
                        resizeMode={ResizeMode.CONTAIN}
                        useNativeControls={true}
                        shouldPlay={true}
                    />
                ) : (
                    <View style={styles.tutorialsPlayer}/>
                )}
            </View>
            <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                { tutorials?.map((video: any, index: number) => (
                    <Pressable 
                        key={index} 
                        onPress={() => {
                            setSource(video.url);
                            setTitle(video.title);
                        }} 
                        style={[styles.row, styles.listItem]}
                    >
                        <Paragraph weight={title === video.title ? 'bold' : 'book'} style={{color: theme["color-primary"]}}>{StringTable.getString(video.title)}</Paragraph>
                    </Pressable>
                ))}
            </View>                    
        </View>
    )
}