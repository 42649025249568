import { useState, useEffect } from 'react';
import { SuperSchedule, Competition } from 'business';

export default function useFilteredSuperScheduleData(schedule: SuperSchedule, limitToCompetition: Competition | undefined, limitToCourt: string | undefined, limitToGroup: number | undefined, layout: string) {
    const [data, setData] = useState<IScheduleCell[][] | null>(null);

    useEffect(() => {

        const filteredData = schedule.data?.reduce((acc: IScheduleCell[][], curr: IScheduleCell[]) => {

            const dummy : IScheduleCell = { roundIndex: curr[0].roundIndex, courtId: curr[0].courtId, type: 'gap', delay: curr[0].delay };

            const round = curr.filter((cell: IScheduleCell) => {
                if (limitToCompetition && cell.competitionId != limitToCompetition.id) {
                    return false;
                }
                if (limitToCourt !== undefined && cell.courtId != limitToCourt) {
                    return false;
                }
                if (limitToGroup !== undefined && limitToCompetition?.getGroupIndex(cell.id) != limitToGroup) {
                    return false;
                }
                return true;
            });

            if (round.length) {
                acc.push(round);
            }
            else if (layout === 'desktop') {
                acc.push([dummy]);
            }
            return acc;

        }, []) || null;
        setData(filteredData);
    }, [schedule, limitToCompetition, limitToCourt, limitToGroup, layout]);

    return data;
}