import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Pressable, Padel, Paragraph, Icon, Subheader, Section, useTheme, TimedView } from 'components';
import { StringTable } from 'utils';
import { useStyles, useUsers, useParticipationTitles, useParticipationPortraits, useAverageRating } from '../hooks';
import { useNavigation } from '../navigation';
import { IPressEvent } from 'interfaces';
import { useAppContext } from '../appcontext';

interface Props {
    group: any,
    onPress?: (e: IPressEvent) => any
    style?: any
}

export default function GroupCard(props: Props) {

    const { group, style, onPress } = props;
    const { settings, participation } = group || {};

    const theme = useTheme();
    const styles = useStyles();
    const { navigate } = useNavigation();
    const { simpleRendering } = useAppContext();

    const users = useUsers(simpleRendering ? undefined : participation?.userRefs);
    const titlesString = useParticipationTitles(users);
    const portraits = useParticipationPortraits(users);
    const avgRating = useAverageRating(users);

    return(
        <TouchableOpacity style={[styles.GroupCard, style]} onPress={(e: any) => onPress ? onPress(e) : navigate({groupId: group.id})}>
            <View style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                <View style={{alignItems: 'center', marginRight: theme["spacing-medium"]}}>
                    <Padel strokeWidth={settings.imageUrl ? 1 : 0} strokeColor={theme["color-ink-soft"]} size={'L'} imageBackground={settings.imageUrl} logoBackground={true} style={{justifyContent: 'center', alignItems: 'center'}}>
                        { !settings.imageUrl ? (
                            <>
                                <Paragraph weight={'bold'} style={{color: "white"}}>{`${Math.round(avgRating || 1000)}`}</Paragraph>
                                <View style={{flexDirection: 'row'}}>
                                    <Icon name={'ico_circle'} size={5} color={'gold'}/>
                                    <Icon name={'ico_circle'} size={5} color={'gold'}/>
                                    <Icon name={'ico_circle'} size={5} color={'gold'}/>
                                    <Icon name={'ico_circle'} size={5} color={'gold'}/>
                                    <Icon name={'ico_circle'} size={5} color={'gold'}/>
                                </View>
                            </>
                        ) : null }
                    </Padel>
                    <Paragraph weight={'bold'} style={{color: theme["color-primary"]}}>{`${Math.round(avgRating || 1000)}`}</Paragraph>
                </View>
                <View style={{flex: 1}}>
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flex: 1}}>
                            <Subheader numberOfLines={1}>{`${settings.getTitle?.()}`}</Subheader>
                            <Paragraph weight={'book'}>{settings.getByline?.() || StringTable.getString('Group')}</Paragraph>
                        </View>
                    </View>
                    { simpleRendering ? null : (
                        <Section style={{flexDirection: 'row'}}>
                            {portraits.map((p: string) => (
                                <Padel key={p} size={'S'} imageBackground={p} showLogo={false}/>
                            ))}
                            <Paragraph style={{flex: 1, marginLeft: portraits.length ? 4 : 0}} numberOfLines={1} weight={'book'}>{titlesString + ' '}</Paragraph>
                        </Section>
                    )}
                </View>
            </View>
        </TouchableOpacity>
    )
}
