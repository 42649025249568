import React from 'react';
import { View } from 'react-native';
import { User, Competition, Group } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import ConfirmSheet from './sheets/ConfirmSheet';
import { Paragraph } from 'components';

export default async function leaveTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User) {
    
    if (!currentUser) {
        popAllModals();
        return;
    }

    if (target?.competition || target?.group) {
        const instance : Competition | Group = target.competition || target.group;
        const isTeamSignup = target.competition?.settings.teamSignup;

        const leaveVerb = target.competition ? 'Unregister' : 'Leave group'
        const confirmedResult : IConfirmSheetResult | null = await pushModal((
            <ConfirmSheet 
                title={StringTable.getString(`Confirm ${leaveVerb}`)}
                doneLabel={StringTable.getString(leaveVerb)}
                doneMode={'negative'}
            >
                <Paragraph weight={'bold'}>{instance.getTitle()}</Paragraph>
                {isTeamSignup ? (
                    <View style={{marginTop: 14}}>
                        <Paragraph>{StringTable.getString('Unregister with partner')}</Paragraph>
                    </View>
                ) : null }
            </ConfirmSheet>
        ), e);

        if (confirmedResult?.confirmed) {
            instance.leave(currentUser.id);
        }
        popAllModals();
    }
};
