import React, { useMemo, ReactElement, useCallback, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { useTheme, Caption, Pressable, Sticker, Buttonlet } from 'components';
import { Match, Competition, SuperSchedule } from 'business';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import NumericCell from './NumericCell';
import TeamCard from './TeamCard';
import { useStyles, useIsAdmin } from '../hooks';
import { useActions } from '../actions';
import { useAppContext } from '../appcontext';

interface Props {
    competition?: Competition, 
    match?: Match,
    court?: { id: string, name: string }
    schedule?: SuperSchedule,
    editable: boolean,
    palette?: {[competitionId: string]: string},
    showCompetitionName?: boolean,
    showCourtName?: boolean,
    showPortraits?: boolean,
    children?: ReactElement,
    style?: any
}

MatchCard.defaultProps = {
    editable: true
}

export default function MatchCard(props: Props) {

    const {
        competition,
        match,
        court,
        schedule,
        editable,
        palette,
        showCompetitionName,
        showCourtName,
        showPortraits,
        style
    } = props;
    
    const styles = useStyles();
    const theme = useTheme();

    const competitionRef = useRef<Competition | undefined>(competition);
    useEffect(() => {
        competitionRef.current = competition;
    }, [competition]);

    const { setMatchResult, showUserMenu, swapCompetitionPlayoffSeeds, swapTeamsInPlayoffMatch, requireMatchCourt, startCompetition, showMessage } = useActions();
    const { currentUserId } = useAppContext();

    const isAdmin = useIsAdmin(currentUserId);
    const isStarted = (competition?.state.started && !competition?.state.closed) ? true : false;
    const hasResultPrivileges = (isAdmin || match?.userRefs?.includes(currentUserId || '')) ? true : false;

    const result = useMemo(() => {
        return match?.result
    }, [match?.result?.[0], match?.result?.[1], match?.result?.[2], match?.result?.[3], match?.result?.[4], match?.result?.[5]]);

    const matchRatings = useMemo(() => {
        return match?.ratings?.change;
    }, [match?.ratings?.change?.[0], match?.ratings?.change?.[1], match?.ratings?.change?.[2], match?.ratings?.change?.[3]])

    const userRefs = useMemo(() => {
        return match?.userRefs;
    }, [match?.userRefs[0], match?.userRefs[1], match?.userRefs[2], match?.userRefs[3]]);

    const onPressRegisterResult = useCallback(async(e: IPressEvent) => {
        if (match) {
            await setMatchResult(e, competitionRef.current, match.id, schedule);
        }
    }, [setMatchResult, schedule, match?.id]);

    const onPressRegisterResultBeforeStarted = useCallback(async(e: IPressEvent) => {       
        if (isAdmin) {
            const wasStarted = await startCompetition(e, true);
            if (wasStarted) {
                setTimeout(() => {
                    onPressRegisterResult({...e});                    
                }, 400);
            }
        }
        else {
            showMessage(e, StringTable.getString('Admin has not yet started the event'))
        }
    }, [onPressRegisterResult, showMessage, startCompetition, isAdmin]);

    const onPressUser = useCallback(async(e: IPressEvent, userRef: string) => {
        competitionRef.current && showUserMenu(e, userRef, competitionRef.current, match?.id);
    }, [showUserMenu, match?.id])

    const prediction = useMemo(() => {
        return match?.points ? match?.prediction?.map(p => p * 4) : match?.prediction;
    }, [match?.points, match?.prediction?.[0], match?.prediction?.[1], match?.prediction?.[2], match?.prediction?.[3], match?.prediction?.[4], match?.prediction?.[5]])

    const description = competition?.getMatchDescription(match?.id || '');
    const { title, home, away } = description || {};
    
    return competition && match ? (
        <View style={[styles.MatchCard, style]}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>

                {/* Left side of header */ }
                <View style={{maxWidth: '50%', alignItems: 'flex-start'}}>
                    { showCompetitionName || (competition.groups?.length || 0) > 1 ? (
                        <Sticker style={{marginRight: theme["spacing-medium"]}} icon={'ico_ball'} label={showCompetitionName ? (competition.settings.title || competition.settings.type) : title } startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/>                    
                    ) : null }
                    { showCompetitionName && (competition.groups?.length || 0) > 1 ? (
                        <Caption style={{marginTop: theme["spacing-extrasmall"], marginRight: theme["spacing-extrasmall"]}}>{title}</Caption>
                    ) : null }
                </View>

                {/* Right side of header */ }
                <View style={{maxWidth: '50%', alignItems: 'flex-end'}}>
                    {(court && showCourtName) ? ( 
                        isAdmin ? (
                            <Pressable style={{maxWidth: '100%'}} onPress={(e: IPressEvent) => requireMatchCourt(e, competition, match.id, schedule)}>
                                <Sticker style={{marginLeft: theme["spacing-medium"]}} icon={'ico_court'} label={court.name} startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/>
                            </Pressable>
                        ) : (
                            <Sticker style={{marginLeft: theme["spacing-medium"]}} icon={'ico_court'} label={court.name} startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/>
                        )
                    ) : null }
                </View>

            </View>

            { match.seed ? (
                <View style={{flexDirection: 'row', marginTop: theme["spacing-extrasmall"]}}>
                    { !match.hasPlayers() ? <Caption style={{marginRight: theme["spacing-extrasmall"]}}>{`${home} v ${away}`}</Caption> : null }
                    { isAdmin ? (
                        <Buttonlet
                            icon={'ico_settings'}
                            label={StringTable.getString('Edit')}
                            onPress={(e: any) => {
                                if (match.hasPlayers()) {
                                    swapTeamsInPlayoffMatch(e, match, competition)
                                }
                                else {
                                    swapCompetitionPlayoffSeeds(e, competition, match, schedule);
                                }
                            }}
                        />
                    ) : null }
                </View>
            ) : null }

            <MMatchResultView 
                userRefs={userRefs}
                result={result?.length ? result : undefined} 
                matchRatings={matchRatings}
                prediction={prediction}
                points={match.points || false}
                onPressRegisterResult={editable && match && hasResultPrivileges && !matchRatings ? (isStarted ? onPressRegisterResult : onPressRegisterResultBeforeStarted) : undefined}
                onPressUser={onPressUser}
                showPortraits={showPortraits}
            />
        </View>
    ) : null;
}

interface MMatchResultViewProps {
    result?: (number | null)[], 
    userRefs?: (string | null)[],
    matchRatings?: number[] | null,
    prediction?: number[] | null,
    points: boolean,
    onPressUser?: (e: any, userRef: string) => void,
    onPressRegisterResult?: (e: any) => Promise<void>,
    showPortraits?: boolean
}

function MMatchResultView(props : MMatchResultViewProps) {

    const { 
        result : resultProp, 
        userRefs,
        matchRatings,
        prediction,
        onPressUser,
        points,
        onPressRegisterResult,
        showPortraits
    } = props;

    function getMatchRatingsString(playerIndex: number) {
        return `${(matchRatings?.[playerIndex] || 0) > 0 ? '+' : ''}${Math.round(matchRatings?.[playerIndex] || 0)}`;
    }
 
    return(
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 1}}>
                <Caption style={{marginLeft: 5, marginBottom: -2, opacity: 0.8}}>{matchRatings ? `${getMatchRatingsString(0)}  ${getMatchRatingsString(1)}` : ' '}</Caption>
                <TeamCard onPressUser={onPressUser} userRefs={[userRefs?.[0] || StringTable.getString('Unknown'), userRefs?.[1] || StringTable.getString('Unknown')]} showPortraits={showPortraits} style={{marginLeft: 0}}/>
                <TeamCard onPressUser={onPressUser} userRefs={[userRefs?.[2] || StringTable.getString('Unknown'), userRefs?.[3] || StringTable.getString('Unknown')]} showPortraits={showPortraits} style={{marginLeft: 0}}/>
                <Caption style={{marginLeft: 5, marginTop: -3, opacity: 0.8}}>{matchRatings ? `${getMatchRatingsString(2)}  ${getMatchRatingsString(3)}` : ' '}</Caption>
            </View>
            <View style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <Caption style={{marginLeft: 5}}>{StringTable.getString(resultProp ? 'Result' : 'Prediction')}</Caption>
                <Pressable onPress={onPressRegisterResult} style={{opacity: resultProp ? 1.0 : 0.4}}>
                    <View style={{flexDirection: 'row'}}>
                        <NumericCell value={resultProp?.length ? resultProp?.[0] : prediction?.[0]} extraWide={points}/>
                        {points ? null : (
                            <>
                                <NumericCell value={resultProp?.length ? resultProp?.[2] : prediction?.[2]}/>
                                <NumericCell value={resultProp?.length ? resultProp?.[4] : prediction?.[4]}/>
                            </>
                        )}
                    </View>
                    <View style={{flexDirection: 'row'}}>
                        <NumericCell value={resultProp?.length ? resultProp?.[1] : prediction?.[1]} extraWide={points}/>
                        { points ? null : (
                            <>
                                <NumericCell value={resultProp?.length ? resultProp?.[3] : prediction?.[3]}/>
                                <NumericCell value={resultProp?.length ? resultProp?.[5] : prediction?.[5]}/>
                            </>
                        )}
                    </View>
                </Pressable>
                <Caption>{` `}</Caption>
            </View>
        </View>
    )
}
