import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useTheme, Icon, Caption, Padel } from 'components';
import {useStyles } from '../../../hooks';
import { CheckMark } from 'components';
import PlayerName from '../../../composites/PlayerName'
import useExtendedContactData from './useExtendedContactData';

interface RenderProps {
    contactData: ContactData,
    onPress?: () => any,
    selected?: boolean, 
    filtered?: boolean,
    caption?: string
}

const ContactListItem = React.memo((props : RenderProps) => {
    const {
        contactData,
        onPress, 
        selected, 
        filtered, 
        caption
    } = props;
    
    const theme = useTheme();
    const extendedContactData = useExtendedContactData(contactData);

    const styles = useStyles();
    const redactedId = contactData.id?.startsWith('+') ? `${contactData.id?.slice(0,6)}***${contactData.id?.slice(-3)}` : contactData.id;
    
    return extendedContactData ? (
        <TouchableOpacity style={[styles.ContactsListItem, { flexDirection: 'row', opacity: filtered ? 0.6 : 1.0 }]} onPress={onPress}>
            <Padel size={'M'} imageBackground={extendedContactData.imageUrl} showLogo={extendedContactData.imageUrl ? false : true} style={{marginRight: theme["spacing-small"]}} strokeWidth={2} strokeColor={theme["color-card"]}/>
            <View style={{flex: 1, justifyContent: 'center'}}>
                <PlayerName
                    verbose={true}
                    registered={extendedContactData.registered}
                    title={extendedContactData.title}
                    rating={extendedContactData.rating}
                />
                { caption ? (
                    <Caption style={{color: theme["color-ink-soft"]}}>{caption}</Caption>
                ) : (
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icon name={'ico_oldphone'} size={12} color={theme["color-ink-soft"]}/>
                            <Caption style={{marginRight: theme["spacing-small"], color: theme["color-ink-soft"]}}>{redactedId}</Caption>
                        </View>
                    </View>
                )}
            </View>
                <CheckMark
                    checked={selected}
                    passive={filtered}
                />
        </TouchableOpacity>
    ) :  null;
});

export default ContactListItem;