import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useTheme, Button, Section } from 'components';
import { StringTable } from 'utils';
import CloseHeader from '../CloseHeader';
import useSheetStyle from '../useSheetStyle';
import { capitalize } from 'tools';


interface ShotsSheetProps {
    title: string, 
    doneLabel: string,
    initialShot?: string,
    shots: string[], 
    resolve?: (data: string | undefined | null) => void,
    //Note that style SHOULD be required, it's not a typo. Modals need to read flex from style
    style: any
}

ShotsSheet.defaultProps = {
    title: StringTable.getString('Select shot'),
    doneLabel: StringTable.getString('Done')
}

export default function ShotsSheet(props: ShotsSheetProps) {

    const {
        title,
        doneLabel,
        initialShot,
        resolve,
        shots,
        style : styleProp
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const [shot, setShot] = useState<string | undefined>(initialShot);

    return(
        <View style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={title || StringTable.getString('Courts?')}/>
            <View style={{flex: 1}}>
                <ScrollView>
                { shots.map(t => (
                    <Section size={'M'} key={t}>
                        <Button
                            onPress={() => setShot(t)}
                            icon={'ico_thumbup'}
                            label={capitalize(t)}
                            mode={t === shot ? 'contained' : 'outlined'}
                        />
                    </Section>
                ))}                            

                </ScrollView>
            </View>
            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button onPress={(e: any) => resolve && resolve(shot)} mode={'primary'} style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>
        </View>
    )
}
