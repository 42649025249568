import { ClubSettings } from './ClubSettings';
import { Participation } from './Participation';
import extractSearchTokens from './extractSearchTokens';
import getVersion from './getVersion';
import { IClub, IClubMeta, IClubSettings } from '../interfaces';

export class Club implements IClub {

    id: string;
    collection: string;
    settings: ClubSettings;
    participation: Participation;
    followerCount?: number;
    playerCount?: number;
    courtCount?: number;
    reviews?: {[uId: string]: number };
    creationDate?: Date;
    saveFunc: any;

    constructor(data: IClub, saveFunc?: any) {
        this.id = data.id;
        this.collection = 'clubs';
        this.saveFunc = saveFunc || this.defaultSaveFunc;
        this.participation = new Participation(data.participation);
        this.settings = new ClubSettings(data.settings);
        this.followerCount = data.followerCount;
        this.playerCount = data.playerCount;
        this.courtCount = data.courtCount;
        this.reviews = data.reviews;
        this.creationDate = data.creationDate || new Date();
    }

    defaultSaveFunc(f: (self: Club) => void) {
        f(this);
    }

    serialize() : IClub {
        return {
            id: this.id, 
            participation: this.participation.serialize(),
            followerCount: this.followerCount, 
            playerCount: this.playerCount, 
            courtCount: this.courtCount,
            reviews: this.reviews, 
            creationDate: this.creationDate,
            settings: this.settings.serialize()
        }
    }

    meta() : IClubMeta {
        return {
            id: this.id, 
            location: this.settings.location?.serialize(), 
            region: this.settings.location?.region || '',
            lastModifiedDate: new Date(), 
            creationDate: this.creationDate,
            public: this.settings.public,
            searchTokens: extractSearchTokens(this.settings.title || ''),
            rating: Object.values(this.reviews || {}).reduce((acc, curr) => acc + curr, 0) / (Object.values(this.reviews || {}).length || 1),
            version: getVersion()
        }
    }

    /**
     * 
     * @param settings 
     */
    updateSettings(settings: IClubSettings) {
        this.saveFunc((self: Club) => {
            self.onUpdateSettings(settings);
        });
    }
    onUpdateSettings(settings: IClubSettings) {
        this.settings.update(settings);        
    }

    /**
     * User made admin by another
     * @param userRef 
     */
    makeAdmins(userRefs: string[]) {
        this.saveFunc((self: Club) => {
            self.onMakeAdmins(userRefs);
        })
    }
    onMakeAdmins(userRefs: string[]) {
        this.participation.admin = userRefs;
    }


    /**
     * 
     * @param userId 
     * @param review 
     */
    setReview(userId: string, review: number) {
        this.saveFunc((self: Club) => {
            self.onSetReview(userId, review);
        });
    }
    onSetReview(userId: string, review: number) {
        this.reviews = this.reviews || {};
        this.reviews[userId] = review || 0;
    }

    getAverageReview() {
        const sum = Object.values(this.reviews || {}).reduce((acc, curr) => acc + curr, 0);
        const count = Object.values(this.reviews || {}).length;

        if (count) {
            return sum / count;
        }
        return 0;
    }

}
