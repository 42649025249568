import React, { useState, useEffect } from 'react';
import { View, FlatList, TouchableOpacity } from 'react-native';
import { useTheme, KeyboardDismissingView, Section, Button, TextInput } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import { Club } from 'business';
import { searchAsync } from 'bridge';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';
import ClubCard from '../../composites/ClubCard'
import { useClubs } from '../../hooks';
import { useAppContext } from '../../appcontext';

interface ClubSheetProps {
    resolve?: (data: IClubSheetResult | null) => void,
    selectedClub?: Club;
    //Note that style SHOULD be required, it's not a typo. Modals need to read flex from style
    style: any
}

export default function ClubSheet(props: ClubSheetProps) {

    const {
        resolve,
        selectedClub, 
        style : styleProp
    } = props;

    const { currentUser } = useAppContext();

    const clubs : Club[] = useClubs(currentUser?.settings.region, currentUser?.settings.clubRefs);
    const theme = useTheme();

    const sheetStyle = useSheetStyle();
    const [club, setClub] = useState(selectedClub);
    const [searchQuery, setSearchQuery] = useState('');

    const [searchResults, setSearchResults] = useState<Club[] | null | undefined>();
    async function searchClubs(q: string) {
        const results = q.length > 3 && await searchAsync<Club>("clubs", { searchQuery: q, public: true });
        setSearchResults(results || []);
    }
    
    useEffect(() => {
        if (!searchQuery) {
            setSearchResults(null);
        }
    }, [searchQuery]);

    return(
        <KeyboardDismissingView style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Select club')}/>
            <View style={{flex: 1}}>
                <Section>
                    <TextInput
                        iconLeft={'ico_search'} 
                        iconRight={'ico_clear'} 
                        placeholder={'Search clubs'} 
                        value={searchQuery}
                        onChangeText={(t: string) => setSearchQuery(t)}
                        onEndEditing={(e: any) => {
                            searchClubs(e.nativeEvent.text);
                        }}
                        onPressIconRight={() => setSearchQuery('')}
                    />
                </Section>

                <Section>
                    <Button icon={'ico_clear'} label={StringTable.getString('Clear club')} onPress={() => setClub(undefined)}/>
                </Section>
                
                <FlatList 
                    data={searchResults?.length ? searchResults : clubs}
                    keyExtractor={item => item.id}
                    style={{flex: 1, marginTop: theme["spacing-medium"]}}
                    renderItem={({item}) => (
                        <View style={{marginHorizontal: theme["spacing-medium"], marginTop: theme["spacing-medium"]}}>
                            <ClubCard 
                                club={item} 
                                onPress={() => setClub(item)}
                            />
                            {item.id === club?.id ? <View style={{position: 'absolute', top: theme["spacing-small"], bottom: theme["spacing-small"], left: -7, width: 4, backgroundColor: theme["color-primary"]}}/> : null }
                        </View>
                    )}
                />
            </View>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={(e: any) => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button onPress={(e: any) => resolve && resolve({e, club})} mode={'primary'} style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} label={StringTable.getString('Select')}/>
            </Section>

        </KeyboardDismissingView>
    )
}
