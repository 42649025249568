import React from 'react';
import { View, Pressable as RNPressable, Animated } from 'react-native';

const AnimatedPressable = Animated.createAnimatedComponent(RNPressable);

export default function Pressable(props) {

    return <RNPressable {...props}/>

    /*
    const { onPress, children, ...otherProps } = props;
    return onPress ? (
        <AnimatedPressable onPress={onPress} {...otherProps}>
            {children}
        </AnimatedPressable>
    ) : (
        <View {...otherProps}>
            {children}
        </View>
    )
    */
}
