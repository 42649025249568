import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { Platform } from 'react-native';
import { getFirestore, getDocs, onSnapshot, query, collection, orderBy, where, limit, startAfter, DocumentSnapshot } from "firebase/firestore";
import { getDatabase, ref, set, increment } from "firebase/database";
import { Club, Competition, Group, Conversation, User } from 'business';
import parse from './parse';
import save from './save';

interface IBatchedQueryOptions {
    userId?: string,
    groupId?: string,
    clubId?: string,
    isClubAdmin?: boolean,
    region?: string, 
    queryType: 'bookedCompetitions' | 'invitedCompetitions' | 'competitionHistory' | 'groupsMine' | 'groupsPublic' | 'groupCompetitions' | 'groupCompetitionHistory' | 'discoverFeatured' | 'discoverDaily' | 'conversationsPublic' | 'conversationsMine' | 'clubsPublic' | 'allClubs' | 'clubCompetitions' | 'clubGroups' | 'clubMembers' | 'allCompetitions' | 'allGroups' | 'allUsers'
    preventStateChange?: boolean,
    date?: Date
}

export default function useBatchedQuery<T>(options: IBatchedQueryOptions, app?: any) : [T[] | undefined, () => void] {
    
    const stableOptions = useMemo(() => {
        return {...options};
    }, [options.userId, options.groupId, options.clubId, options.region, options.queryType, options.preventStateChange, options.date?.getDate(), options.date?.getMonth()]);

    const querySettings = getQuerySettings(stableOptions);
    const { batchSize } = querySettings || {};
    const [max, setMax] = useState(batchSize || 7);
    const [instances, setInstances] = useState<T[] | undefined>();

    const docs = useRef<{[hash: string]: DocumentSnapshot[][]}>({}).current;
    const unsubscribers = useRef<{[hash: string]: any[]}>({}).current;

    const onLoadMore = useCallback(() => {
        setMax(prev => prev + (batchSize || 7));
    }, [batchSize]);

    useEffect(() => {
        
        const { userId, queryType, date, region } = stableOptions;

        const querySettings = getQuerySettings(stableOptions);    
        if (!querySettings) {
            return;
        }

        const { params, batchSize, collectionName, classType } = querySettings;
        const batches = max / batchSize;

        const hash = `${queryType}.${userId || '.'}${app ? app._name : 'defaultApp'}.${region}.${date?.toISOString() || 'nodate'}`;            

        //If we have results from this query already, set instances
        const allBatches = docs[hash]?.reduce((acc, curr) => ([...acc, ...curr]), []);
        allBatches && setInstances(allBatches.map((doc: DocumentSnapshot) => new classType(parse(collectionName, doc), save)));

        //Attempt to set up listeners for all batches of this query
        for (let i = 0; i < batches; i++) {
            const last = i > 0 ? docs?.[hash]?.[i-1]?.[batchSize - 1] : undefined;
    
            //Previous batch was not full
            if (i && !last) {
                continue;
            };
    
            //Already subscribing to batch
            if (unsubscribers[hash]?.[i]) {
                continue;
            }
            
            const queryParms = [...params];
            last && queryParms.push(startAfter(last));
            queryParms.push(limit(batchSize));    
            const q = query(collection(getFirestore(app), collectionName), ...queryParms);
    
            //use to get an index link
            //const res = getDocs(q);
    
            unsubscribers[hash] = unsubscribers[hash] || [];
            docs[hash] = docs[hash] || [];
            unsubscribers[hash][i] = onSnapshot(q, qSnapshot => {
                set(ref(getDatabase(app), `analytics/reads/useBatchedQuery`), increment(qSnapshot.size || 1));
                docs[hash][i] = qSnapshot.docs || [];
                const allBatches = docs[hash].reduce((acc, curr) => ([...acc, ...curr]), []);
                setInstances(allBatches.map((doc: DocumentSnapshot) => new classType(parse(collectionName, doc), save)));
            });
        }

    }, [max, stableOptions, app]);

    //Never stop listening to anything
    
    return [instances, onLoadMore];
}
    


function getQuerySettings(options: IBatchedQueryOptions) : { params: any[], batchSize: number, collectionName: string, classType: any} | undefined {

    const {
        queryType, 
        userId, 
        groupId,
        clubId,
        isClubAdmin,
        region,
        date
    } = options

    const today = date ? new Date(date) : new Date();
    today.setHours(0,0,0,0);
    const tomorrow = date ? new Date(date) : new Date();
    tomorrow.setHours(23,59,59,999);
        
    const querySettings = {
        'bookedCompetitions': userId ? {
            params: userId === '+46123123456' ? [
                where('meta.date', '>=', today),
                orderBy('meta.date', 'asc')    
            ] : [
                where('meta.recipients', 'array-contains', userId), 
                where('meta.date', '>=', today),
                orderBy('meta.date', 'asc')    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'invitedCompetitions': userId ? {
            params: [
                where('meta.invitedRefs', 'array-contains', userId), 
                where('meta.parentCompetitionRef', '==', false),
                where('meta.date', '>=', today),
                orderBy('meta.date', 'asc')    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'competitionHistory': userId ? {
            params: [
                where('meta.recipients', 'array-contains', userId),
                where('meta.date', '<=', today),
                orderBy('meta.date', 'desc')
            ],
            batchSize: 5,
            collectionName: 'competitions', 
            classType: Competition
        } : undefined,
        'groupsMine': userId ? {
            params: userId === '+46123123456' ? [
                orderBy('meta.lastModifiedDate', 'desc')
            ] : [
                where('meta.recipients', 'array-contains', userId), 
                orderBy('meta.lastModifiedDate', 'desc')    
            ],
            batchSize: Platform.OS === 'web' ? 17 : 7, 
            collectionName: 'groups',
            classType: Group
        } : undefined,
        'groupsPublic': {
            params: region ? [
                where('meta.public', '==', true), 
                where('meta.region', '==', region),
                orderBy('meta.lastModifiedDate', 'desc')    
            ] : [
                where('meta.public', '==', true), 
                orderBy('meta.lastModifiedDate', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'groups',
            classType: Group
        },
        'groupCompetitions': groupId ? {
            params: [
                where('meta.groupRef', '==', groupId), 
                where('meta.date', '>=', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'asc')    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'groupCompetitionHistory': groupId ? {
            params: [
                where('meta.groupRef', '==', groupId), 
                where('meta.date', '<', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'allCompetitions': {
            params: [
                orderBy('meta.date', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        },
        'allGroups': {
            params: [
                orderBy('meta.lastModifiedDate', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'groups',
            classType: Group
        },
        'allClubs': {
            params: [
                orderBy('meta.id', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'clubs',
            classType: Club
        },
        'allUsers': {
            params: [
                orderBy('meta.lastModifiedDate', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'users',
            classType: User
        },
        'discoverFeatured': {
            params: region ? [
                where('meta.featured', '==', true),
                where('meta.region', '==', region)
            ] : [
                where('meta.featured', '==', true)
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        },
        'discoverDaily': {
            params: region ? [
                where('meta.public', '==', true), 
                where('meta.region', '==', region),
                where('meta.date', '>=', today),
                where('meta.date', '<=', tomorrow)    
            ] : [
                where('meta.public', '==', true), 
                where('meta.date', '>=', today),
                where('meta.date', '<=', tomorrow)    
            ],
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        },
        'conversationsPublic': {
            params: region ? [
                where('meta.public', '==', true),
                where('meta.region', '==', region),
                orderBy('meta.lastModifiedDate', 'desc')    
            ] : [
                where('meta.public', '==', true),
                orderBy('meta.lastModifiedDate', 'desc')    
            ],
            batchSize: 7, 
            collectionName: 'conversations',
            classType: Conversation
        },
        'conversationsMine': userId ? {
            params: [
                where('meta.recipients', 'array-contains', userId),
                orderBy('meta.lastModifiedDate', 'desc')    
            ], 
            batchSize: 7, 
            collectionName: 'conversations',
            classType: Conversation
        } : undefined,
        'clubsPublic': {
            params: region ? [
                where('meta.public', '==', true), 
                where('meta.region', '==', region),
                orderBy('meta.rating', 'desc')  
            ] : [
                where('meta.public', '==', true),
                orderBy('meta.rating', 'desc')
            ],
            batchSize: 7, 
            collectionName: 'clubs',
            classType: Club
        },
        'clubCompetitions': clubId ? {
            params: isClubAdmin ? [
                where('meta.clubRef', '==', clubId), 
                where('meta.date', '>=', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'asc')    
            ] : [
                where('meta.clubRef', '==', clubId), 
                where('meta.date', '>=', today),
                where('meta.parentCompetitionRef', '==', false),
                where('meta.public', '==', true),
                orderBy('meta.date', 'asc')
            ], 
            batchSize: 7, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined, 
        'clubGroups': clubId ? {
            params: isClubAdmin ? [
                where('meta.clubRef', '==', clubId),
                orderBy('meta.lastModifiedDate', 'desc')    

            ] : [
                where('meta.clubRef', '==', clubId), 
                where('meta.public', '==', true),
                orderBy('meta.lastModifiedDate', 'desc')
            ], 
            batchSize: 7, 
            collectionName: 'groups',
            classType: Group
        } : undefined,
        'clubMembers': clubId ? {
            params: [
                where('meta.clubRefs', 'array-contains', clubId),
                orderBy('meta.rating', 'desc')
            ], 
            batchSize: 3, 
            collectionName: 'users',
            classType: User
        } : undefined
    }

    return querySettings[queryType as keyof typeof querySettings];
}

