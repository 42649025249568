import React, { useState } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { useTheme, KeyboardDismissingView, Button, Section, Pressable, Paragraph, Icon, Subheader } from 'components';
import { StringTable } from 'utils';
import CloseHeader from '../CloseHeader';
import useSheetStyle from '../useSheetStyle';

interface EventTypeSheetProps {
    type: 'MATCH' | 'AMERICANO' | 'TEAM AMERICANO' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING';
    resolve?: (type: string | null | undefined) => void,
    isSubCompetition?: boolean,
    isScheduled?: boolean,
    style: any
}

EventTypeSheet.defaultProps = {
    title: StringTable.getString('Select event type')
}
export default function EventTypeSheet(props: EventTypeSheetProps) {

    const {
        type : typeProp,
        resolve,
        isSubCompetition,
        isScheduled,
        style : styleProp
    } = props;

    const theme = useTheme();
    const [type, setType] = useState<'MATCH' | 'AMERICANO' | 'TEAM AMERICANO' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING'>(typeProp || 'MATCH');

    const types : Array<'MATCH' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'AMERICANO' | 'TEAM AMERICANO' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING'> = ['MATCH', 'WINNERS COURT', 'TEAM WINNERS COURT', 'AMERICANO', 'TEAM AMERICANO', 'TOURNAMENT', 'BIG TOURNAMENT', 'TRAINING'];

    const sheetStyle = useSheetStyle();
    return(
        <KeyboardDismissingView style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Set event type')}/>
            <View style={{flex: 1}}>
                <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}} contentContainerStyle={{paddingBottom: 340}}>
                    { types.map(t => (isSubCompetition || isScheduled) && t === 'BIG TOURNAMENT' ? null : (
                        <TypeView key={t} selected={type === t} type={t} onPress={() => setType(t)}/>
                    ))}
                </ScrollView>

                <Section style={{flexDirection: 'row'}}>
                    <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                    <Button 
                        onPress={(e: any) => {
                            resolve && resolve(type);
                        }} 
                        mode={type ? 'primary' : 'passive'} 
                        style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} 
                        label={StringTable.getString('Set type')}
                    />
                </Section>
            </View>

        </KeyboardDismissingView>
    )
}

interface TypeViewProps {
    onPress: () => void, 
    type: 'MATCH' | 'AMERICANO' | 'TEAM AMERICANO' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING';
    selected: boolean
}

function TypeView(props: TypeViewProps) {
    const {
        onPress, 
        type, 
        selected
    } = props;

    const theme = useTheme();

    const titles = {
        'MATCH': StringTable.getString('Match'), 
        'AMERICANO': StringTable.getString('Americano'),
        'TEAM AMERICANO': StringTable.getString('Team Americano (League)'),
        'WINNERS COURT': StringTable.getString('Winners Court (Mexicano)'),
        'TEAM WINNERS COURT': StringTable.getString('Team Winners Court'),
        'TOURNAMENT': StringTable.getString('Tournament'),
        'BIG TOURNAMENT': StringTable.getString('Big Tournament'),
        'TRAINING': StringTable.getString('Training')
    }

    const players = {
        'MATCH': '4', 
        'AMERICANO': '8-12',
        'TEAM AMERICANO': '8-24',
        'WINNERS COURT': '12-20',
        'TEAM WINNERS COURT': '12-28',
        'TOURNAMENT': '16+',
        'BIG TOURNAMENT': '32+',
        'TRAINING': '1-4'
    }

    const courts = {
        'MATCH': '1', 
        'AMERICANO': '2-3',
        'TEAM AMERICANO': '2-6',
        'WINNERS COURT': '3-5',
        'TEAM WINNERS COURT': '3-7',
        'TOURNAMENT': '2+',
        'BIG TOURNAMENT': '4+',
        'TRAINING': '1'
    }

    const time = {
        'MATCH': '60-120', 
        'AMERICANO': '120-180',
        'TEAM AMERICANO': '90-180',
        'WINNERS COURT': '90-120',
        'TEAM WINNERS COURT': '90-120',
        'TOURNAMENT': '180+',
        'BIG TOURNAMENT': '180+',
        'TRAINING': '60-90'
    }

    const description = {
        'MATCH': StringTable.getString('A normal game'), 
        'AMERICANO': StringTable.getString('Play with everyone once and against everyone no more than twice'),
        'TEAM AMERICANO': StringTable.getString('Play against all teams once'),
        'WINNERS COURT': StringTable.getString('Winners move up to more difficult matches, swap partners'),
        'TEAM WINNERS COURT': StringTable.getString('Winners move up to more difficult matches, keep partner'),
        'TOURNAMENT': StringTable.getString('Classic tournament with group stage and playoffs'),
        'BIG TOURNAMENT': StringTable.getString('Tournament with multiple classes, such as Ladies, Gents, Mixed, etc'),
        'TRAINING': StringTable.getString('One or more players with a coach')
    };    

    const alignment : 'center' = 'center';
    const style = {
        borderRadius: theme["roundness"], 
        padding: theme["spacing-medium"], 
        alignItems: alignment,
        backgroundColor: theme["color-card"]
    }

    return(
        <TouchableOpacity onPress={onPress} style={{marginTop: theme["spacing-medium"], paddingLeft: 8}}>
            <View style={style}>
                <Subheader style={{marginRight: theme["spacing-small"]}}>{titles[type]}</Subheader>

                <View style={{marginHorizontal: theme["spacing-medium"], alignSelf: 'stretch', height: 1, backgroundColor: theme["color-neutral"], marginTop: theme["spacing-extrasmall"]}}/>

                <View style={{flexDirection: 'row', marginTop: theme["spacing-small"]}}>
                    <View style={{alignItems: 'center', marginRight: theme["spacing-small"]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icon name={'ico_person'} size={14} color={theme["color-neutral"]}/>
                            <Paragraph weight={'bold'}>{players[type]}</Paragraph>
                        </View>
                        <Paragraph weight={'book'}>{StringTable.getString('Players')}</Paragraph>
                    </View>

                    <View style={{alignItems: 'center', marginHorizontal: theme["spacing-small"]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icon name={'ico_court'} size={14} color={theme["color-neutral"]}/>
                            <Paragraph style={{marginLeft: 2}} weight={'bold'}>{courts[type]}</Paragraph>
                        </View>
                        <Paragraph weight={'book'}>{StringTable.getString('Courts')}</Paragraph>
                    </View>

                    <View style={{alignItems: 'center', marginLeft: theme["spacing-small"]}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icon name={'ico_time'} size={14} color={theme["color-neutral"]}/>
                            <Paragraph style={{marginLeft: 2}} weight={'bold'}>{time[type]}</Paragraph>
                        </View>
                        <Paragraph weight={'book'}>{StringTable.getString('minutes')}</Paragraph>
                    </View>
                </View>

                <Section size={'S'}>
                    <Paragraph weight={'book'} style={{marginHorizontal: theme["spacing-medium"], textAlign: 'center'}}>
                        {description[type]}
                    </Paragraph>
                </Section>
            </View>
            { selected ? (
                <View style={{position: 'absolute', top: theme["spacing-small"], bottom: theme["spacing-small"], left: 0, width: 4, backgroundColor: theme["color-primary"]}}/>
            ) : null }
        </TouchableOpacity>
    )
}
