import React from 'react';
import { View, FlatList } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { useFocusEffect } from '@react-navigation/native';
import { resetNotificationCounter } from 'bridge';
import { useConversation, useStyles, useGoBackIfNull } from '../hooks';
import { DocHeader, ConversationMessageView, ConversationMessageComposer } from '../composites';
import { NavigationProvider, useRootNavigatorContext } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import WaitingScreen from './WaitingScreen';

export default function ConversationScreen(props: any) {

    const { route } = props;    
    const { conversationId } = route.params;
    const { currentUser, currentUserId } = useAppContext();

    const conversation = useConversation(conversationId);
    const { setNotificationView } = useRootNavigatorContext();

    const styles = useStyles();

    const { setTarget } = useActions();
    useFocusEffect(
        React.useCallback(() => {
            setTarget(null);
            currentUserId && conversationId && resetNotificationCounter(currentUserId, `conversations/${conversationId}`);
        }, [currentUserId, conversationId])
    );

    async function onPressCopy(message: string) {
        await Clipboard.setStringAsync(message);
        //@ts-ignore
        setNotificationView(<NotificationView notification={{request: { content: { body: StringTable.getString('Message copied')}}}} onPress={() => setNotificationView(null)} onDismiss={() => setNotificationView(null)}/>);
    }

    useGoBackIfNull(conversation);
    
    return (
        <NavigationProvider>
            { conversation ? (
                <DocHeader title={conversation.title} floating={false}>
                    <View style={{flex: 1}}>
                        <FlatList
                            data={conversation?.messages || []}
                            inverted={true}
                            keyExtractor={item => item.id}
                            renderItem={({item, index}) => (
                                <ConversationMessageView 
                                    message={item} 
                                    senderId={item.sender} 
                                    showSenderName={true}
                                    style={styles.listItem}
                                    onPressDelete={async () => conversation?.deleteMessage(item)}
                                    onPressReaction={async (reaction: string) => currentUser?.id && conversation?.reactMessage(item, reaction, currentUser.id)}
                                    onPressCopy={() => onPressCopy(item.message)}
                                />
                            )}
                        />
                        <ConversationMessageComposer conversation={conversation}/>
                    </View>
                </DocHeader>
            ) : <WaitingScreen/>}            
        </NavigationProvider>
    )
}
