import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import Icon from './helpers/Icon';
import useThemeStyle from './theme/useThemeStyle';

export interface IconButtonProps {
    name?: string,
    mode?: string,
    style?: any,
    onPress?: any,
    hitSlop?: number,
    size?: string,
    children?: any
}

IconButton.defaultProps = {
    mode: 'contained', 
    color: 'blue',
    hitSlop: 20
}

export default function IconButton(props: IconButtonProps) {

    const {
        name,
        mode,
        style : styleProp,
        onPress, 
        hitSlop,
        children
    } = props;

    //@ts-ignore
    const {iconSize, iconColor, ...style} = useThemeStyle("IconButton", props);
    return(
        <View style={styleProp} pointerEvents={mode === 'passive' ? 'none' : 'auto'}>
            <TouchableOpacity 
                style={style} 
                onPress={onPress}
            >
                <Icon name={name} color={iconColor} size={iconSize}/>
                {children}
            </TouchableOpacity>
        </View>
    )
}