import { getDatabase, ref, onValue, get, DataSnapshot } from "firebase/database";
import { UserIdentity } from "business";

class _IdentityCache {

    identities: {[key: string]: UserIdentity | null };
    subscriptions: {[key: string]: any };
    listeners: {[key: string]: any };
    db: any;

    constructor() {
        this.identities = {};
        this.subscriptions = {};
        this.listeners = {};
    }

    start(id: string) {

        if (typeof id !== 'string') {
            throw new Error('IdentityCache non-string id ' + JSON.stringify(id));
        }

        id = id.trim();

        if (this.subscriptions[id]) {
            return;
        }

        if (!this.isValidId(id)) {
            this.identities[id] = null;
            if (this.listeners[id]) {
                this.listeners[id].forEach((callback: any) => callback(id, this.identities[id]));
            }
            return;
        }

        this.db = this.db || getDatabase();
        const identityRef = ref(this.db, `identities/${id}`);

        this.subscriptions[id] = onValue(identityRef, snapshot => {
            this._populate(id, snapshot);
            if (this.listeners[id]) {
                this.listeners[id].forEach((callback: any) => callback(id, this.identities[id]));
            }
        })
    }

    listen(id: string, callback: (id: string, identity: UserIdentity | null) => void) {
        
        this.listeners[id] = this.listeners[id] || [];
        this.listeners[id].push(callback);
        if (this.identities[id] !== undefined) {
            callback(id, this.identities[id]);
        }

        if (!this.subscriptions[id]) {
            this.start(id);
        }
    }

    stop(id: string, callback: any) {
        const index = this.listeners[id] && this.listeners[id].findIndex((c: any) => c === callback);
        if (index >= 0) {
            this.listeners[id].splice(index, 1);
            //This would be the place to stop subscriptions. However, its most likely less efficient to stop, as we
            //will frequently reach zero callbacks and then go back up to one or more again.
        }
    }

    _populate(id: string, snapshot?: DataSnapshot) {
        if (snapshot && snapshot.exists()) {
            const val = snapshot.val() || {};
            this.identities[id] = new UserIdentity(id, {...val, created: true});
        }
        else {
            this.identities[id] = null;
        }
    }

    async get(id?: string | null) {
        if (id) {

            id = id.trim();
            if (this.identities[id]) {
                return this.identities[id];
            }

            const snap = this.isValidId(id) && await get(ref(getDatabase(), `identities/${id}`));
            this._populate(id, snap || undefined);
            return this.identities[id];
        }
    }

    getSync(id?: string) {
        return id ? (this.identities[id] || null) : undefined
    }

    async getAll(ids: string[]) {
        if (!(ids && ids.length)) {
            return [];
        }
        //TODO: If one get fails, snaps might be an array of fewer entries than ids. Check Promise.allSettled or similar
        const users = await Promise.all(ids.map((id: string) => this.get(id)));
        return users;
    }

    isValidId(id: string) {
        if (!id) { return false }
        const regex = /^\+[0-9]+$/;
        return regex.test(id);
    }

    count() {
        return Object.keys(this.identities).length;
    }
}
const IdentityCache = new _IdentityCache();
export default IdentityCache;
