import React from 'react';
import { IPressEvent } from 'interfaces';
import { User } from 'business';
import OnboardingSheet from './sheets/OnboardingSheet';
import safelyRegisterUser from './safelyRegisterUser';

export default async function onboard(e: IPressEvent, pushModal: any, popAllModals: any, changePinCode: (chosenPinCode: string) => Promise<void>, currentUser?: User | null, currentUserId?: string, notificationPermission?: string, canRequestNotificationPermission?: boolean, requestNotificationPermission?: () => Promise<any>) {

    const onboardingResult = await pushModal((
        <OnboardingSheet 
            style={{flex: 1}}
            showChangePin={true}
            onChangePin={changePinCode}
            showRequestPushNotifications={canRequestNotificationPermission && notificationPermission !== 'granted' ? true : false}
            onRequestPushNotifications={requestNotificationPermission}

        />
    ), e);

    popAllModals();
    safelyRegisterUser(currentUser, currentUserId, onboardingResult)
}
