import React from 'react';
import { View } from 'react-native';
import Text from './helpers/Text';
import useThemeStyle from './theme/useThemeStyle';

export interface BadgeProps {
    count: number,
    style?: any
}

Badge.defaultProps = {
    count: 0
}
export default function Badge(props: BadgeProps) {

    const {
        count,
        style : styleProp
    } = props;

    //@ts-ignore
    const {fontSize, textColor, fontFamily, ...style } = useThemeStyle('Badge', props);

    return count ? (
        <View style={[style, styleProp]}>
            <Text style={{fontFamily: fontFamily, color: textColor, fontSize: fontSize, lineHeight: fontSize}}>{count}</Text>
        </View>
    ) : null;
}