import { useMemo } from 'react';
import { UserIdentity } from 'business';

export default function useAverageRating(users?: {[userRef: string]: UserIdentity | null | undefined}) {

    const avgRating = useMemo(() => {
        return Object.values(users || {}).reduce((acc, curr) => acc + (curr?.rating || 1000), 0) / Object.values(users || {}).length;
    }, [users]);

    return avgRating;
}
