import React from 'react';
import { IPressEvent } from 'interfaces';
import { SuperSchedule, Competition } from 'business';
import CourtsSheet from './sheets/CourtsSheet';

export default async function requireMatchCourt(e: IPressEvent, competition: Competition, matchId: string, schedule: SuperSchedule, pushModal: any, popAllModals: any) {

    const match = competition?.getMatch(matchId);
    const courts = schedule.settings.courts;

    if (competition && match) {
        const result : { courtId?: string } | null = await pushModal((
            <CourtsSheet 
                courts={courts}
                currentRequiredCourt={match.requiredCourtId}
            />
        ), e);

        if (result) {
            competition.setRequiredMatchCourt(matchId, result.courtId);
            schedule.replaceMatch(matchId, match.serialize());
        }
    }
    popAllModals();
}
