import { getFirestore, doc, setDoc } from "firebase/firestore";
import { noUndefined } from 'tools'

export default async function adminSetDocument(path: string, data: any, app: any) {
        
    try {
        console.log('[setDoc]: adminSetDocument')
        await setDoc(doc(getFirestore(app), path), noUndefined(data));
    } catch(err) {
        console.log('error', err);
    }
}