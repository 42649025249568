import { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function useSystemDocument(path: string) {
        
    const [document, setDocument] = useState();    

    useEffect(() => {
        async function fetch() {

            try {
                const documentSnapshot = await getDoc(doc(getFirestore(), path));
                if (documentSnapshot.exists()) {
                    setDocument(documentSnapshot.data());                
                }
                else {
                    setDocument(null);
                }
            } catch(err) {
                console.log('error', err);
            }
        }
        fetch();
    }, [path]);

    return document;
}