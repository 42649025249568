import React, { useEffect, useRef, useCallback } from 'react';
import { Modal, Pressable, Animated } from 'react-native';
import { useTheme } from 'components';
import { ModalStackEntry } from './ModalProvider';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

interface ModalStackProps {
    stack: ModalStackEntry[], 
    setStack: React.Dispatch<React.SetStateAction<ModalStackEntry[]>>,
    popModal: () => void,
    animationType: 'none' | 'slide'
}

export default function ModalStack(props: ModalStackProps) {

    const {
        stack, 
        setStack,
        popModal,
        animationType
    } = props;

    const popInvisibleModals = useCallback(() => {
        setStack((prev) => {
          return prev.filter((modal) => modal.visible);
        });
      }, [setStack]);

    return(
        <ModalIteratorView
            stack={stack}
            popModal={popModal}
            popInvisibleModals={popInvisibleModals}
            animationType={animationType}
        />
    )
}

interface ModalIteratorViewProps {
    stack: ModalStackEntry[],
    popModal: () => void,
    popInvisibleModals: () => void,
    animationType: 'none' | 'slide'
}
function ModalIteratorView(props: ModalIteratorViewProps) {

    const {
        stack,
        popModal,
        popInvisibleModals,
        animationType
    } = props;

    const curr = stack?.[0];
    const currVisibility = curr?.visible;
            
    const rest = stack?.slice(1);

    const opacity = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        Animated.timing(opacity, {
            toValue: currVisibility ? 1 : 0, 
            duration: 300, 
            useNativeDriver: true
        }).start(() => {
            if (!currVisibility) {
                popInvisibleModals();
            }
        });
    }, [currVisibility]);

    const theme = useTheme();

    return curr ? (
        <Modal visible={currVisibility ? true : false} transparent={true} animationType={animationType}>
            <AnimatedPressable 
                style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, opacity: opacity, backgroundColor: theme["color-film"]}} 
                onPress={() => {
                    curr.resolve(null);
                    popModal();
                }}
            />
            {curr.instance}
            <ModalIteratorView {...props} stack={rest}/>
        </Modal>
    ) : null;
}