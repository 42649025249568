import { useState, useEffect, useCallback } from 'react';
import { Platform } from 'react-native';
import * as Notifications from 'expo-notifications';
import { getDatabase, ref, set } from "firebase/database";
import Constants from 'expo-constants';

export default function useNotificationPermissionImplementation(userRef?: string, userRefVerified?: boolean): [('granted' | 'undetermined' | 'denied'), () => any, boolean] {

    const [canRequestNotificationPermission, setCanRequestNotificationPermission] = useState(true);
    const [notificationPermission, setNotificationPermission] = useState<('granted' | 'undetermined' | 'denied')>('undetermined');
    const [requestNotificationPermission, setRequestNotificationPermission] = useState(false);

    const doRequestNotificationPermission = useCallback(() => {
        if (Platform.OS !== 'web' && canRequestNotificationPermission) {
            setRequestNotificationPermission(true);
        }
    }, [canRequestNotificationPermission]);

    useEffect(() => {
        async function checkPermission() {
            const { status, canAskAgain } = await Notifications.getPermissionsAsync();
            setCanRequestNotificationPermission(canAskAgain);
            setNotificationPermission(status);
        }
        if (Platform.OS !== 'web') {
            checkPermission();
        }
    }, []);

    useEffect(() => {
        async function requestPermission() {
            const { status, canAskAgain } = await Notifications.requestPermissionsAsync();
            setCanRequestNotificationPermission(canAskAgain);
            setNotificationPermission(status);
        }

        if (Platform.OS !== 'web' && requestNotificationPermission && notificationPermission !== 'granted') {
            requestPermission();
        }
    }, [requestNotificationPermission, notificationPermission]);


    useEffect(() => {
        async function fetchNotificationToken() {
            if (userRef && userRefVerified && notificationPermission === 'granted') {
                const token = (await Notifications.getExpoPushTokenAsync()).data;
                if (token) {
                    set(ref(getDatabase(), `pushTokens/${userRef}`), token);
                }
            }    
        }
        userRef && notificationPermission === 'granted' && fetchNotificationToken();
    }, [userRef, notificationPermission, userRefVerified]);

    return [notificationPermission, doRequestNotificationPermission, canRequestNotificationPermission]
}
