import React from 'react';
import { View } from 'react-native';
import { useTheme, Headline } from 'components';
import { StringTable } from 'utils';

export default function Slogan(props: any) {

    const theme = useTheme();
    return(
        <View>
            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Headline size={'XL'} style={{color: 'white'}}>{StringTable.getString('Plan')}</Headline>
                <Headline size={'XL'} style={{marginLeft: -10, color: theme["color-blue"]}}>{' .'}</Headline>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Headline size={'XL'} style={{color: 'white'}}>{StringTable.getString('Play')}</Headline>
                <Headline size={'XL'} style={{marginLeft: -3, color: theme["color-positive"]}}>{'.'}</Headline>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'flex-end'}}>
                <Headline size={'XL'} style={{color: 'white'}}>{StringTable.getString('Rank')}</Headline>
                <Headline size={'XL'} style={{marginLeft: -8, color: theme["color-negative"]}}>{' .'}</Headline>
            </View>
        </View>
    )
}