import React, { useState, useRef } from 'react';
import { View, ViewStyle, ScrollView } from 'react-native';
import useTheme from '../theme/useTheme';
import { dateCompare, dayLabels } from './calendarTools';
import useThemeStyle from '../theme/useThemeStyle';
import Text from '../helpers/Text';
import Pressable from '../helpers/Pressable';
import Paragraph from '../typography/Paragraph';
import Icon from '../helpers/Icon';

export interface CalendarWeekProps {
    style?: any,
    firstDay: number,
    showChevrons?: boolean,
    selectedDate: Date,
    onChangeDate?: (d: Date) => void,
    dayLabels: string[],
    monthLabels?: string[],
}

CalendarWeek.defaultProps = {
    firstDay: 1, 
    dayLabels: dayLabels
}

export default function CalendarWeek(props: CalendarWeekProps) {

    const {
        style,
        selectedDate,
        onChangeDate,
        dayLabels,
        monthLabels,
        showChevrons
    } = props;

    const theme = useTheme();

    const dates = useRef(initializeDates()).current;

    function initializeDates() {
        const d = new Date();
        d.setHours(2);
        d.setMinutes(0);
        d.setSeconds(0);
        const ret = [];
        for (let i = 0; i < 60; i++) {
            ret.push(new Date(d.getTime() + i * 1000 * 60 * 60 * 24));
        }
        return ret;
    };

    const containerStyle : ViewStyle = {
        flex: 1,
        alignItems: 'center', 
        justifyContent: 'center',
        paddingVertical: theme["spacing-small"],
        borderBottomWidth: 3,
        borderTopWidth: 3, 
        borderColor: "transparent",
        paddingHorizontal: theme["spacing-medium"]
    }

    const textStyle = useThemeStyle('CalendarLabel', props);
    const contentOffset = useRef(0);
    const scrollViewRef = useRef<any>();
    const [width, setWidth] = useState(0);

    return(
        <View style={style} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
            <View style={{flexDirection: 'row'}}>
                { showChevrons ? (
                    <Pressable 
                        style={{width: 26, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center'}}
                        onPress={() => scrollViewRef.current?.scrollTo({x: contentOffset.current - width * 0.5, y: 0, animated: true})}
                    >
                        <Icon name={'ico_chevronleft'}/>
                    </Pressable>
                ) : null }
                <ScrollView 
                    ref={scrollViewRef}
                    style={{flex: 1}}
                    horizontal={true} 
                    showsHorizontalScrollIndicator={false} 
                    contentContainerStyle={{paddingHorizontal: theme["spacing-small"]}}
                    onScroll={e => contentOffset.current = e.nativeEvent.contentOffset.x}
                    scrollEventThrottle={32}
                >
                    { dates.map((d: Date, i: number) => {
                        const selected = dateCompare(selectedDate, d);
                        return (
                            <Pressable 
                                key={i}
                                style={[containerStyle, selected && {borderTopColor: theme["color-primary"], borderBottomColor: theme["color-primary"]}, {alignItems: 'center'}]}
                                onPress={() => onChangeDate && onChangeDate(d)}
                            >
                                { dayLabels ? <Text style={[textStyle, selected && {color: theme["color-primary"]}]}>{dayLabels[d.getDay()]}</Text> : null }
                                <Paragraph style={selected && {color: theme["color-primary"]}}>{`${d.getDate()}`}</Paragraph>
                                { monthLabels ? <Text style={[textStyle, selected && {color: theme["color-primary"]}]}>{monthLabels[d.getMonth()]}</Text> : null }
                            </Pressable>
                        )
                    }) }
                </ScrollView>
                { showChevrons ? (
                    <Pressable 
                        style={{width: 26, alignSelf: 'stretch', justifyContent: 'center', alignItems: 'center'}}
                        onPress={() => scrollViewRef.current?.scrollTo({x: contentOffset.current + width * 0.5, y: 0, animated: true})}
                    >
                        <Icon name={'ico_chevronright'}/>
                    </Pressable>
                ) : null }                            
            </View>
        </View>
    )
}
