//@ts-nocheck
import React, { useState, useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import { useTheme, KeyboardDismissingView, Pressable, Section, TextInput, Paragraph, SectionHeader } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import { useAppContext } from '../../appcontext';
import CloseHeader from './CloseHeader';
import { IRegion } from 'interfaces';

interface RegionsSheetProps {
    selectedRegion?: string,
    resolve?: (data: string | null) => void, 
    style: any
}

export default function RegionsSheet(props : RegionsSheetProps) {

    const {
        selectedRegion,
        resolve, 
        style
    } = props;

    const { regions } = useAppContext();
    const countries = useMemo(() => {
        return regions?.reduce((acc, curr) => {
            if (!acc.includes(curr.country)) {
                acc.push(curr.country);
            }
            return acc;
        }, [])
    }, [regions]);

    const [searchRegionQuery, setSearchRegionQuery] = useState('');

    const sheetStyle = useSheetStyle();
    
    return(
        <KeyboardDismissingView style={[sheetStyle, {flex: 1}, style]} alwaysActive={true}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Select region')}/>
            <Section>
                <TextInput
                    iconLeft={'ico_search'} 
                    iconRight={'ico_clear'} 
                    placeholder={'Search regions'} 
                    value={searchRegionQuery}
                    onChangeText={(t: string) => setSearchRegionQuery(t)}
                    onPressIconRight={() => setSearchRegionQuery('')}
                />
            </Section>
            <Section style={{flex: 1}}>
                <ScrollView>
                { searchRegionQuery ? (
                    <>
                        <Pressable>
                            <SectionHeader icon={'ico_language'} label={StringTable.getString('Search results')}/>
                        </Pressable>
                        { regions.filter(region => region.title.toLowerCase().includes(searchRegionQuery.toLowerCase())).map((region: IRegion) => (
                            <RegionView key={region.title} selected={selectedRegion === region.title} onPress={() => resolve && resolve(region.title)} region={region}/>
                        ))}
                    </>
                ) : (
                    <>
                        <Pressable>
                            <Section>
                                <SectionHeader icon={'ico_language'} label={StringTable.getString('Common')}/>
                            </Section>
                        </Pressable>
                        { regions?.filter(r => r.common)?.sort().map((region: IRegion) => (
                            <RegionView key={region.title} selected={selectedRegion === region.title} onPress={() => resolve && resolve(region.title)} region={region}/>
                        ))}
                       
                        { countries.map(country => (
                            <Section key={country} size={'L'}>
                                <SectionHeader icon={'ico_language'} label={country}/>
                                { regions?.filter(r => r.country === country)?.sort().map((region: IRegion) => (
                                    <RegionView key={region.title} selected={selectedRegion === region.title} onPress={() => resolve && resolve(region.title)} region={region}/>
                                ))}
                            </Section>
                        ))}
                    </>
                )}
                </ScrollView>
            </Section>
        </KeyboardDismissingView>
    )
}

interface RegionViewProps {
    region: IRegion, 
    onPress?: any, 
    selected?: boolean
}
function RegionView(props: RegionViewProps) {

    const {
        onPress,
        region,
        selected
    } = props;

    const theme = useTheme();

    return(
        <Pressable onPress={onPress} style={{flexDirection: 'row', marginTop: theme["spacing-small"]}}>
            <Paragraph style={{color: selected === region ? theme["color-positive"] : undefined, marginTop: theme["spacing-small"]}}>{region.title}</Paragraph>
        </Pressable>
    )
}
