import React, { useState, useEffect, useRef } from 'react';
import { View, Animated, Easing, StyleSheet } from 'react-native';

interface OrderedListProps {
    order: string[],
    renderItem: (id: string, index: number, ref: any) => any,
    itemStyle?: any,
    duration: number,
    spacing: number,
    children?: any
}

OrderedList.defaultProps = {
    spacing: 6,
    duration: 400
}
export default function OrderedList(props: OrderedListProps) {

    const {
        order : orderProp,
        renderItem,
        itemStyle, 
        duration,
        spacing,
        children
    } = props;

    const [order, setOrder] = useState(orderProp);
    const [targetOrder, setTargetOrder] = useState(orderProp);

    const mounted = useRef(true);
    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    })

    const timeoutHandler = useRef<any>();
    useEffect(() => {
        if (timeoutHandler.current) {
            clearTimeout(timeoutHandler.current);
        }
        mounted.current && setTargetOrder(orderProp);
        timeoutHandler.current = setTimeout(() => {
            mounted.current && setOrder(orderProp);
        }, duration);
    }, [orderProp, duration])

    return(
        <View>
            { order && order.map((itemKey: string, index: number) => (
                <OrderedListItem 
                    key={itemKey} 
                    position={index} 
                    targetPosition={targetOrder.indexOf(itemKey)}
                    duration={duration}
                    style={[itemStyle, {margin: 0, marginBottom: 0, marginVertical: 0, marginTop: spacing}]}
                    renderItem={(ref: any) => renderItem && renderItem(itemKey, index, ref)}
                />
            ))}
            {children || null}
        </View>
    )
}

interface OrderedListItemProps {
    position: number, 
    targetPosition: number,
    duration: number,
    renderItem?: (ref: any) => any,
    style?: any
}

function OrderedListItem(props: OrderedListItemProps) {

    const {
        position, 
        targetPosition, 
        duration,
        renderItem,
        style
    } = props;

    const [height, setHeight] = useState(0);
    const translateY = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        if (position !== targetPosition) {
            Animated.timing(translateY, {
                toValue: (targetPosition - position) * height, 
                duration: duration,
                easing: Easing.linear,
                useNativeDriver: true,
            }).start()
        }
        else {
            translateY.setValue(0);
        }
    }, [duration, height, position, targetPosition]);

    const itemRef = useRef();
    return(
        <Animated.View 
            ref={itemRef}
            onLayout={e => setHeight(e.nativeEvent.layout.height)}
            style={[{ transform: [{ translateY: translateY }]}, style ]}
        >
            { renderItem ? renderItem(itemRef) : null }
        </Animated.View>
    )
}
