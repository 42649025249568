import React, { useState} from 'react';
import { View } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from '../useSheetStyle';


interface SideSheetProps {
    title: string,
    cancelLabel: string,
    doneLabel: string,
    doneColor?: string,
    initialSide?: 'left' | 'right' | 'both',
    style?: any,
    resolve?: (data: 'left' | 'right' | 'both' | undefined | null ) => void
}

SideSheet.defaultProps = {
    title: StringTable.getString('Pick side'),
    cancelLabel: StringTable.getString('Cancel'),
    doneLabel: StringTable.getString('Done')
}
export default function SideSheet(props: SideSheetProps) {

    const {
        title,
        cancelLabel,
        doneLabel,
        doneColor,
        initialSide,
        resolve,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();

    const [side, setSide] = useState<'left' | 'right' | 'both' | undefined>(initialSide)
    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            <Button onPress={() => setSide("left")} mode={side === "left" ? 'contained' : 'outlined'} label={StringTable.getString('Left')}/>
            <Button onPress={() => setSide("right")} mode={side === "right" ? 'contained' : 'outlined'} style={{marginTop: theme["spacing-medium"]}} label={StringTable.getString('Right')}/>

            <Section size={'L'} style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={cancelLabel}/>
                <Button onPress={(e: IPressEvent) => resolve && resolve(side)} mode={'primary'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>            
        </View>
    )
}
