import React, { useState } from 'react';
import { View, Platform, TouchableOpacity } from 'react-native';
import Paragraph from './typography/Paragraph';
import useTheme from './theme/useTheme';
import useThemeStyle from './theme/useThemeStyle';
import Pressable from './helpers/Pressable';
import Icon from './helpers/Icon';

export interface ButtonProps {
    label?: string,
    icon?: string,
    iconRight?: string,
    mode: string,
    color?: string,
    penColor?: string,
    style?: any,
    onPress?: any,
    numberOfLines?: number,
    children?: any,
    shadow?: boolean, 
    primary?: boolean
}

Button.defaultProps = {
    mode: 'contained',
    numberOfLines: 1,
    shadow: true
}

export default function Button(props: ButtonProps) {

    const {
        label,
        icon,
        iconRight,
        mode,
        color : colorProp,
        penColor : penColorProp,
        style : styleProp,
        onPress,
        numberOfLines, 
        shadow,
        primary
    } = props;

    const theme = useTheme();
    //@ts-ignore
    const {iconSize, ...style} = useThemeStyle("Button", props);

    const colors = {
        'contained': theme["color-ink-supersoft"],
        'primary': theme["color-primary"], 
        'negative': theme["color-paper"],
        'dangerous': theme["color-negative"],
        'passive': 'transparent',
        'outlined': 'transparent',
        'plain': 'transaprent'
    };

    const penColors = {
        'contained': theme["color-ink"],
        'primary': theme["color-ink-contained"], 
        'negative': theme["color-negative"],
        'dangerous': theme["color-ink-contained"],
        'passive': theme["color-ink-soft"], 
        'outlined': colorProp || theme["color-ink"], 
        'plain': colorProp || theme["color-ink"]
    }

    const color = colorProp || colors[mode as keyof typeof colors] || theme["color-ink-supersoft"];
    const penColor = penColorProp || penColors[mode as keyof typeof penColors] || theme["color-ink"];

    const iconPadding = {
        'ico_chevronright': Platform.OS === 'web' ? 0 : 5
    };

    const shadowStyle = ['passive', 'outlined', 'plain'].includes(mode) ? {} : {
        shadowColor: theme['color-shadow'],
        shadowOffset: {
            width: 1,
            height: 1,
        },
        shadowOpacity: 0.4,
        shadowRadius: 1,
        elevation: 1
    };

    return(
        <View style={styleProp} pointerEvents={mode === 'passive' ? 'none' : 'auto'}>
            <TouchableOpacity 
                style={[
                    { flexDirection: 'row', alignItems: 'center', justifyContent: icon ? 'flex-start' : 'center', backgroundColor: color, borderColor: (mode === 'outlined' || mode === 'passive' || mode === 'negative') ? penColor : 'transparent'},
                    style,   
                    shadow ? shadowStyle : undefined
                ]}
                onPress={onPress}
            >
                { icon ? (
                    (icon.startsWith('ico') ? 
                        <Icon name={icon} size={iconSize} color={penColor}/>
                        :
                        <Paragraph weight={'bold'} style={{width: iconSize, height: iconSize}}>{icon}</Paragraph>
                    )
                ) : null }
                <Paragraph 
                    numberOfLines={numberOfLines} 
                    weight={'bold'}
                    style={{
                        marginLeft: icon ? theme["spacing-small"] : 0, 
                        marginRight: iconRight ? theme["spacing-small"] : (icon ? theme["spacing-extrasmall"] : 0), 
                        color: penColor, 
                        textAlign: !(icon || iconRight) ? 'center' : 'left'
                    }}
                >
                    {label}
                </Paragraph>
                { iconRight ? (
                    <View style={{marginLeft: 'auto', alignItems: 'center', justifyContent: 'center', paddingBottom: iconPadding[iconRight as keyof typeof iconPadding] || 0}}>
                        <Icon name={iconRight} size={iconSize} color={penColor}/>
                    </View>
                ) : null }
            </TouchableOpacity>          
        </View>

    )
}