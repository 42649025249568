import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import useThemeStyle from './theme/useThemeStyle';

export interface CardProps {
    style?: any,
    children?: any,
    size?: string,
    onPress?: any,
    onLayout?: any
}

export default function Card(props: CardProps) {

    const {
        style : styleProp,
        onPress,
        onLayout,
        children, 
    } = props;

    const style = useThemeStyle("Card", props);

    return(
        <TouchableOpacity onPress={onPress} style={[style, styleProp]} onLayout={onLayout}>
            {children}
        </TouchableOpacity>
    )
}