import React, { useState, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import useTheme from './theme/useTheme';
import Pressable from './helpers/Pressable';
import Button from './Button';
import Paragraph from './typography/Paragraph';
import { StringTable } from 'utils';

interface Props {
    alignment: 'left' | 'right'
    options: {[id: string]: string}
    selectedValue?: string,
    onSelectValue: (value: string) => any,
    style?: any
}

DropdownMenu.defaultProps = {
    alignment: 'left'
}

export default function DropdownMenu(props: Props) {
    const { options, selectedValue, onSelectValue, style } = props;
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const handleSelect = (option: string) => {
        onSelectValue(option);
        setOpen(false);
    };

    const styles = useMemo(() => {
        return StyleSheet.create({
            buttonStyle: {
                width: '100%',
            },
            optionsContainer: {
                position: 'absolute',
                top: 60,
                left: 0,
                right: 0,
                borderWidth: 1,
                borderColor: theme["color-ink-soft"],
                borderRadius: 4,
                backgroundColor: theme["color-paper"],
                zIndex: 1,
            },
            optionsOutside: {
                position: 'absolute', 
                top: -2000, 
                left: -2000, 
                right: -2000, 
                bottom: -2000
            },
            option: {
                padding: 16,
                borderBottomWidth: 1,
                borderBottomColor: theme["color-ink-soft"],
                justifyContent: 'center',
            },
        });        
    }, [theme]);
    
    return (
        <View style={style}>
            <Button label={selectedValue ? (options[selectedValue] || selectedValue) : StringTable.getString('Select')} icon={'ico_chevrondown'} style={styles.buttonStyle} shadow={true} onPress={() => setOpen(!open)} color={theme["color-ink-supersoft"]} penColor={theme["color-ink"]}/>
            {open && (
                <>
                    <Pressable style={styles.optionsOutside} onPress={() => setOpen(false)}/>
                    <View style={styles.optionsContainer}>
                        {Object.keys(options).map(key => (
                            <Pressable
                                key={key}
                                style={styles.option}
                                onPress={() => handleSelect(key)}
                            >
                                <Paragraph weight={selectedValue === key ? 'bold' : undefined}>{options[key]}</Paragraph>
                            </Pressable>
                        ))}
                    </View>
                </>
            )}            
        </View>
    );
};
