import { getFirestore, query, collection, where, limit, getDocs } from "firebase/firestore";
import { getDatabase, ref, set, increment } from "firebase/database";
import { User } from 'business';
import parse from './parse';

export default async function searchContactData(count: number, searchQuery?: string, filter?: string[]) {

    const q = query(
        collection(getFirestore(), 'users'), 
        where('meta.searchTokens', 'array-contains', searchQuery?.toLowerCase().trim()), 
        limit(count)
    );
    const snap = await getDocs(q);  
    set(ref(getDatabase(), `analytics/reads/searchContactData`), increment(snap.size || 1));

    const found : ContactData[] = [];
    for (const doc of snap.docs) {
        if (doc.exists() && !filter?.includes(doc.id)) {
            const user = new User(parse('users', doc));
            found.push({
                id: user.id,
                title: user.getTitle(), 
                imageUrl: user.settings.imageUrl,
                registered: user.state.registered
            })
        }
    }

    return found;
}