import React, { useMemo} from 'react';
import { Image, View, StyleSheet, Platform, useWindowDimensions } from 'react-native';
import { useTheme, Paragraph, Bullet, Subheader, Section, Headline, Icon, LogoSquare } from 'components';
import { StringTable } from 'utils';
import { useLayout, useStyles, useUsers } from '../hooks';
import { UserIdentity } from 'business';

interface Props {
    imageUrl?: string, 
    userRefs?: string[],
    title?: string, 
    groupTitle?: string,
    clubTitle?: string,
    byline?: string, 
    description?: string,
    dateTimeString?: string, 
    isPublic?: boolean,
    isPrivate?: boolean,
    isRepeating?: boolean,
    isPlaying?: boolean,
    isReserve?: boolean,
    children?: any;
}

DocPanel.defaultProps = {
    showImage: true
}

export default function DocPanel(props: Props) {

    const { 
        imageUrl,
        userRefs,
        title, 
        groupTitle,
        clubTitle,
        byline, 
        description,
        dateTimeString,
        isPublic,
        isPrivate,
        isRepeating,
        isPlaying,
        isReserve,
        children
    } = props;

    const { layout } = useLayout();
    const { width } = useWindowDimensions();
    
    const theme = useTheme();
    const styles = useStyles();

    let imageSize = layout === 'mobile' ? width : Math.min(220, width / 2);

    const bulletStyle = {
        backgroundColor: theme["color-paper"],
        borderRadius: theme["roundness"],
        marginRight: layout === 'desktop' ? theme["spacing-small"] : 0,
        marginTop: layout === 'desktop' ? 0 : theme["spacing-small"],
        padding: Platform.OS === 'web' ? 2 : theme["spacing-extrasmall"],
        paddingRight: Platform.OS === 'web' ? 10 : theme["spacing-medium"],
        alignSelf: 'flex-start',
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: theme["color-ink"]
    };

    return (
        <View style={[localStyles.container, layout === 'desktop' ? styles.row : styles.column]}>
            {(imageUrl || userRefs) ? (
                <View>
                    <DocPanelImage 
                        style={{ overflow: 'hidden', borderRadius: layout === 'desktop' ? theme["roundness"] : undefined, margin: layout === 'desktop' ? theme["spacing-medium"] : 0 }}
                        size={imageSize}
                        imageUrl={imageUrl}
                        userRefs={userRefs}
                    />
                    <View style={layout === 'desktop' ? {flexDirection: 'row', marginLeft: theme["spacing-medium"], width: 0, overflow: 'visible'} : {position: 'absolute', bottom: theme["spacing-medium"], left: theme["spacing-medium"]}}>
                        {isPublic ? (
                            <Bullet size={'M'} style={bulletStyle} icon={'ico_language'} label={StringTable.getString('Public')}/>
                        ) : null }
                        {isRepeating ? (
                            <Bullet size={'M'} style={bulletStyle} icon={'ico_repeat'} label={StringTable.getString('Repeats weekly')}/>
                        ) : null }
                        {isPlaying ? (
                            <Bullet size={'M'} style={[bulletStyle, {backgroundColor: theme["color-positive"], borderColor: theme["color-ink-contained"]}]} color={theme["color-ink-contained"]} iconBackgroundColor={theme["color-positive"]} icon={'ico_court'} label={StringTable.getString('You are playing')}/>
                        ) : null }
                        {isReserve ? (
                            <Bullet size={'M'} style={[bulletStyle, {backgroundColor: theme["color-neutral"], borderColor: theme["color-ink-contained"]}]} color={theme["color-ink-contained"]} iconBackgroundColor={theme["color-neutral"]} icon={'ico_court'} label={StringTable.getString('You are reserve')}/>
                        ) : null }
                    </View>
                </View>
            ) : null }
            <View style={[{marginHorizontal: theme["spacing-medium"], alignSelf: 'stretch'}, layout === 'desktop' ? { flex: 1 } : {}]}>
                <Section>
                    {dateTimeString || clubTitle ? (
                        <Paragraph>{`${dateTimeString || ''}${dateTimeString && clubTitle ? ', ' : ''}${clubTitle || ''}`}</Paragraph>
                    ) : null }
                    {groupTitle ? (
                        <Paragraph>{groupTitle}</Paragraph>
                    ) : null }
                    <Headline style={{marginTop: theme["spacing-medium"]}}>{title || StringTable.getString('Title')}</Headline>
                    {byline ? <Paragraph>{byline}</Paragraph> : null }
                </Section>

                {children}

                { isPrivate ? (
                    <Section size={'L'}>
                        <Subheader>{StringTable.getString('Closed group')}</Subheader>
                        <Paragraph>{StringTable.getString('Only admin can add members to this group, and only group members can join events')}</Paragraph>
                    </Section>

                ) : null }
                
                { description ? (
                    <Section size={isPrivate ? 'S' : 'L'}>
                        <Subheader>{StringTable.getString('Description')}</Subheader>
                        <Paragraph 
                            numberOfLines={3} 
                            seeMore={true} 
                            seeMoreLabel={StringTable.getString('See more')}
                            seeLessLabel={StringTable.getString('See less')}
                            weight={'book'}
                        >
                            {description}
                        </Paragraph>
                    </Section>
                ) : <Section size={'L'}/> }
            </View>
        </View>
    );
};

interface DocPanelImageProps {
    size: number,
    imageUrl?: string, 
    userRefs?: string[],
    style?: any
}
function DocPanelImage(props: DocPanelImageProps) {

    const {
        size,
        imageUrl,
        userRefs,
        style
    } = props;

    const portraits = [0, 1, 2, 3];
    const theme = useTheme();

    const users = useUsers(userRefs, true);
    const familiarFaces = useMemo(() => Object.values(users || {}).filter((u: UserIdentity | null | undefined) => u?.imageUrl).slice(0, 4).map((u: UserIdentity | null | undefined) => u?.imageUrl), [users]);

    return imageUrl ? (
        <View style={[{width: size, height: size}, style]}>
            <Image style={{width: '100%', height: '100%'}} source={{uri: imageUrl}}/>
        </View>
    ) : (
        <View style={[{width: size, height: size, flexDirection: 'row', flexWrap: 'wrap'}, style]}>
            { portraits.map((p: number) => (
                <View key={p} style={{width: size/2, height: size/2, alignItems: 'center', justifyContent: 'center'}}>
                    {(userRefs?.length || 0) > p ? (
                        familiarFaces[p] ? (
                            <Image style={{width: '100%', height: '100%'}} source={{uri: familiarFaces[p]}}/>
                        ) : (
                            <LogoSquare style={{width: size/2, height: size/2}}/>
                        )
                    ) : (
                        <View style={{width: '100%', height: '100%', borderWidth: StyleSheet.hairlineWidth, borderColor: theme["color-ink-supersoft"], backgroundColor: theme["color-ink-soft"], justifyContent: 'center', alignItems: 'center'}}>
                            <Icon name={'ico_person'} size={size/5} color={theme["color-ink-contained"]}/>
                        </View>
                    )}
                </View>
            ))}
        </View>
    )

}

const localStyles = StyleSheet.create({
    container: {
        alignItems: 'flex-start',
        justifyContent: 'center',
    }
});
