import { useState, useRef } from 'react';
import AppContext from "./AppContext";
import { Auth } from 'firebase/auth';
import useCurrentUserImplementation from './hooks/useCurrentUserImplementation';
import useLocaleImplementation from './hooks/useLocaleImplementation';
import useDeviceTypeImplementation from './hooks/useDeviceTypeImplementation';
import useNotificationPermissionImplementation from './hooks/useNotificationPermissionImplementation';
import useLogoutImplementation from './hooks/useLogoutImplementation';
import useOfflineDetection from './hooks/useOfflineDetection';
import useSimpleRendering from './hooks/useSimpleRendering';
import { IRegion } from 'interfaces';
import useLoginImplementation from './hooks/useLoginImplementation';
import useChangePinCode from './hooks/useChangePinCode';
import useDeleteUser from './hooks/useDeleteUser';

interface Props {
    children: any,
    regions: IRegion[],
    locales: any, 
    tutorials?: {title: string, url: string}[],
    latestVersion?: string,
    setError: any,
    auth: Auth
}

export default function AppContextProvider(props: Props) {
    
    const { children, regions, locales, tutorials, latestVersion, setError, auth } = props;
    const [simpleRendering, setSimpleRendering] = useSimpleRendering();
    const [lastValidPinCode, setLastValidPinCode] = useState<string>();
    const [preventOnboarding, setPreventOnboarding] = useState(false);
    const { currentUser, currentUserId, currentUserVerified } = useCurrentUserImplementation();
    const locale = useLocaleImplementation(currentUser, locales);
    const deviceType = useDeviceTypeImplementation();
    const [notificationPermission, requestNotificationPermission, canRequestNotificationPermission] = useNotificationPermissionImplementation(currentUser?.id, currentUserVerified);
    const login = useLoginImplementation(auth);
    const logout = useLogoutImplementation();
    const offline = useOfflineDetection();
    const changePinCode = useChangePinCode(setLastValidPinCode, currentUserId, lastValidPinCode);
    const deleteUser = useDeleteUser(logout, setPreventOnboarding, currentUserId);

    const startTime = useRef(0);
    function setStartTime(time: number) {
        startTime.current = time;
    };

    const [target, setTarget] = useState<IActionTarget | null>(null);

    return(
        <AppContext.Provider 
            value={{
                lastValidPinCode, 
                setLastValidPinCode, 
                currentUser, 
                currentUserId,
                regions, 
                locale,
                locales, 
                notificationPermission, 
                requestNotificationPermission, 
                canRequestNotificationPermission,
                tutorials, 
                setError, 
                deviceType,
                latestVersion,
                offline,
                login,
                logout,
                startTime, 
                setStartTime,
                simpleRendering, 
                setSimpleRendering,
                target,
                setTarget,
                preventOnboarding,
                setPreventOnboarding,
                changePinCode,
                deleteUser
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
