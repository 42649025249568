import React from 'react';
import { IPressEvent } from 'interfaces';
import { Competition, SuperSchedule, Match } from 'business';
import PlayoffSeedsSheet from './sheets/PlayoffSeedsSheet';

export default async function swapCompetitionPlayoffSeeds(e: IPressEvent, target: IActionTarget | null, competition: Competition, match: Match, schedule: SuperSchedule, pushModal: any, popAllModals: any) {

    const matchId = match.id;
    const seed = match.seed;

    const playoff = competition?.playoff?.findPlayoffWithMatch(matchId);
    if (!playoff?.seedDescriptions || !seed || !matchId) {
        popAllModals();
        return;
    }

    const replacementSeed : number[] | null | undefined = await pushModal((
        <PlayoffSeedsSheet 
            seed={seed}
            seedDescriptions={playoff.seedDescriptions}
            style={{flex: 1}}
        />
    ), {...e, pageY: 0});

    if (seed && replacementSeed) {
        competition.swapSeed(seed, replacementSeed, matchId);
        schedule.replaceAllMatchesInCompetition(competition.id, competition.getAllMatches().map(m => m.serialize()));
    }
    popAllModals();
}
