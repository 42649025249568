import React, { useState, ReactElement, useMemo, useEffect } from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';
import { useTheme, IconButton, Subheader, Paragraph } from 'components';
import { useStyles } from '../hooks';
import { useNavigation } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';

interface Props {
    title?: string;
    floating?: boolean;
    children: ReactElement
}

export default function DocHeader(props: Props) {

    const { floating, title, children } = props;
    const [opaque, setOpaque] = useState(floating ? false : true);
    useEffect(() => setOpaque(floating ? false : true), [floating]);

    const { offline } = useAppContext();
    const { width } = useWindowDimensions();
    const theme = useTheme();
    const styles = useStyles();
    const { back } = useNavigation();

    function onScroll(e: any) {
        const contentOffset = e.nativeEvent.contentOffset.y;
        setOpaque(contentOffset < width ? false : true);
    }
 
    return (
        <View style={styles.screen}>
            { offline ? (
                <View style={[styles.screenHeader, { position: 'relative', backgroundColor:theme["color-negative"] }]}>
                    <View style={localStyles.backIconContainer}>
                        <IconButton onPress={back} name={"ico_back"} mode={opaque ? 'default' : 'film'}/>
                    </View>

                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Paragraph weight={'bold'} style={{color: theme["color-ink-contained"]}}>{StringTable.getString('No internet')}</Paragraph>
                        <Paragraph style={{color: theme["color-ink-contained"]}}>{StringTable.getString('No edits possible')}</Paragraph>
                    </View>

                    <View style={localStyles.backIconContainer}/>
                </View>
            ) : (
                <View style={[styles.screenHeader, { position: floating ? 'absolute' : 'relative', backgroundColor: opaque ? theme["color-card"] : 'transparent' }]}>
                    <View style={localStyles.backIconContainer}>
                        <IconButton onPress={back} name={"ico_back"} mode={opaque ? 'default' : 'film'}/>
                    </View>
                    <View style={localStyles.titleContainer}>
                        { opaque ? <Subheader numberOfLines={1}>{title || ''}</Subheader> : null }
                    </View>
                    <View style={localStyles.backIconContainer}/>
                </View>    
            )}
            {React.cloneElement(React.Children.only(children), floating ? { onScroll, scrollEventThrottle: 32} : {})}            
        </View>
    );
};

const localStyles = StyleSheet.create({
    backIconContainer: {
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    }
});
