import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

export default function useNotificationCounter(uId: string, notificationLink: string) {

    const [result, setResult] = useState();
    useEffect(() => {
        try {
            const unsubscribe = uId && notificationLink && onValue(ref(getDatabase(), `notificationCounters/${uId}/${notificationLink}`), snapshot => {
                if (snapshot.exists()) {
                    setResult(snapshot.val() || null);
                }
                else {
                    setResult(null);
                }
            });
            return () => {
                unsubscribe && unsubscribe();
            }
        } catch(err) {
            console.log('Error in useNotificationCounter', err);
        }

    }, [uId, notificationLink]);

    return result;
}
