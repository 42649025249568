import { useState, useEffect, useRef, useMemo } from 'react';
import { Animated } from 'react-native';
import useThemeContext from './useThemeContext';

export default function useThemeStyle(name, props, state) {

    const { mappings } = useThemeContext();
    const targetStyle = useStatelessTargetStyle(name, props, state, mappings);
    const interpolatedStyle = useInterpolateStyle(targetStyle, mappings?.[name]?.settings);
    return interpolatedStyle;
}

function useStatelessTargetStyle(name, props, state, mappings) {

    const baseStyle = mappings?.[name]?.base;
    let style = {...baseStyle};
    
    if (mappings?.[name]?.state?.[state]) {
        style = {...style, ...mappings?.[name]?.state?.[state]}
    }

    const propsStyle = mappings?.[name]?.props;             
    propsStyle && Object.keys(propsStyle).forEach(propName => {

        const propValue = props[propName];

        const propStyle = propValue && propsStyle[propName][propValue];

        if (propStyle?.base) {
            style = {...style, ...propStyle.base}
        }

        if (propStyle?.state?.[state]) {
            style = {...style, ...propStyle.state[state]}
        }
    })
    return style;
}

function useInterpolateStyle(toStyle, settings) {

    const fromStyle = useRef();
    const interpolatedStyle = useMemo(() => {
        if (!(toStyle && Object.keys(toStyle).length)) {
            return undefined;
        }
        const interpolators = [];
        const style = {};
        Object.keys(toStyle).forEach(styleProp => {

            const toValue = toStyle[styleProp];
            const fromValue = (fromStyle.current && fromStyle.current.hasOwnProperty(styleProp)) ? fromStyle.current[styleProp] : toStyle[styleProp];

            if (settings?.animatedProps?.[styleProp]) {
                const interpolator = new Animated.Value(toValue === fromValue ? 1.0 : 0.0);
                style[styleProp] = interpolator.interpolate({
                    inputRange: [0, 1], 
                    outputRange: [fromValue, toValue]
                });
                interpolators.push(interpolator);
            }
            else {
                style[styleProp] = toValue;
            }
        });

        Animated.parallel(interpolators.map(interpolator => Animated.timing(interpolator, {
            toValue: 1, 
            duration: settings?.animatedSpeed || 150,
            useNativeDriver: false
        }))).start(({finished}) => {
            fromStyle.current = {...toStyle};
        });
        return style;

    }, [JSON.stringify(toStyle)]);

    return interpolatedStyle;

}

