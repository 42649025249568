import React, { useRef, useState, useEffect } from 'react';
import { View, ScrollView, ViewStyle } from 'react-native';
import CalendarLabel from './CalendarLabel';
import { dateCompare, getWeekOf, getWeeksOfMonth, dayLabels, monthLabels } from './calendarTools';
import Icon from '../helpers/Icon';
import Divider from '../helpers/Divider';
import Pressable from '../helpers/Pressable';
import useTheme from '../theme/useTheme';
import Text from '../helpers/Text';
import Subheader from '../typography/Subheader';
import useThemeStyle from '../theme/useThemeStyle';
import CalendarDigit from './CalendarDigit';
import PageList from '../lists/PageList';

export interface CalendarMonthProps {
    style?: any,
    firstDay: number,
    selectedDate?: Date,
    onChangeDate?: (d: Date) => void,
    dayLabels: string[],
    monthLabels: string[],
    range?: { min: Date, max: Date} 
}

CalendarMonth.defaultProps = {
    firstDay: 1,
    dayLabels: dayLabels,
    monthLabels: monthLabels
}

export default function CalendarMonth(props: CalendarMonthProps) {

    const {
        style,
        firstDay,
        selectedDate,
        onChangeDate,
        monthLabels : monthLabelsProp,
        dayLabels : dayLabelsProp,
        range
    } = props;

    const theme = useTheme();

    const pageListRef = useRef<any>();
    const [width, setWidth] = useState();
    const [dates, setDates] = useState(createDates(new Date()));
    const [renderAdjacentMonths, setRenderAdjacentMonths] = useState(false);
    function createDates(d: Date) {
        return {
            curr: new Date(d.getFullYear(), d.getMonth(), d.getDate()),
            prevWeeks: getWeeksOfMonth(new Date(d.getFullYear(), d.getMonth()-1, 1), firstDay),
            currWeeks: getWeeksOfMonth(new Date(d.getFullYear(), d.getMonth(), 1), firstDay),
            nextWeeks: getWeeksOfMonth(new Date(d.getFullYear(), d.getMonth() + 1, 1), firstDay)
        }
    }

    function onScrollEnd(offset: number) {
        const months = offset === 0 ? -1 : (offset === width * 2 ? 1 : 0);
        if (months && pageListRef.current) {
            pageListRef.current.scrollTo({x: width, y: 0, animated: false});
            const newDate = new Date(dates.curr.getFullYear(), dates.curr.getMonth() + months, dates.curr.getDate());
            //If unmounted, scrollViewRef.current will be falsy
            setDates(createDates(newDate));
        }
    }
     
    return( 
        <View style={style}>
            <View onLayout={e => e.nativeEvent.layout.width && setWidth(e.nativeEvent.layout.width)}>
                { width ? (
                    <PageList
                        ref={pageListRef}
                        initialPage={1}
                        width={width}
                        onScrollEnd={onScrollEnd}
                    >
                        <View style={{width: width}}>
                            <MonthHeader
                                monthLabel={monthLabelsProp[dates.prevWeeks[1].getMonth()]}
                                dayLabels={dayLabelsProp}
                                firstDay={firstDay}
                            />
                            { renderAdjacentMonths ? dates.prevWeeks.map((d: Date) => (
                                <Week 
                                    key={d.toString()} 
                                    width={width} 
                                    startDate={d} 
                                    selectedDate={selectedDate}
                                    month={dates.prevWeeks[dates.prevWeeks.length-1].getMonth()}
                                    range={range}
                                />
                            )) : null }
                        </View>
                        <View style={{width: width}} onLayout={e => setRenderAdjacentMonths(true)}>
                            <MonthHeader
                                onPressChevronLeft={() => pageListRef.current?.scrollTo({x: 0, y: 0, animated: true})}
                                onPressChevronRight={() => pageListRef.current?.scrollTo({x: width*2, y: 0, animated: true})}
                                monthLabel={monthLabelsProp[dates.currWeeks[1].getMonth()]}
                                dayLabels={dayLabelsProp}
                                firstDay={firstDay}
                            />
                            { dates.currWeeks.map((d: Date, i: number) => (
                                <Week 
                                    key={d.toString()} 
                                    width={width} 
                                    startDate={d} 
                                    selectedDate={selectedDate} 
                                    month={dates.currWeeks[dates.currWeeks.length-1].getMonth()}
                                    onPressDay={(d: Date) => onChangeDate && onChangeDate(d)} 
                                    range={range}
                                />
                            ))}
                        </View>
                        <View style={{width: width}}>
                            <MonthHeader
                                monthLabel={monthLabelsProp[dates.nextWeeks[1].getMonth()]}
                                dayLabels={dayLabelsProp}
                                firstDay={firstDay}
                            />
                            { renderAdjacentMonths ? dates.nextWeeks.map((d: Date) => (
                                <Week 
                                    key={d.toString()} 
                                    width={width} 
                                    startDate={d} 
                                    selectedDate={selectedDate} 
                                    month={dates.nextWeeks[dates.nextWeeks.length-1].getMonth()}
                                    range={range}
                                />
                            )) : null }
                        </View>
                    </PageList>
                ) : null }
            </View>
        </View>
    )
}

function MonthHeader(props: any) {

    const {
        onPressChevronLeft,
        onPressChevronRight,
        monthLabel,
        firstDay,
        dayLabels
    } = props;

    const theme = useTheme();
    return(
        <View>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <Pressable hitSlop={12} onPress={onPressChevronLeft}>
                    <Icon name={'ico_chevronleft'} size={24} color={theme["color-ink"]}/>
                </Pressable>
                    <Subheader>{monthLabel}</Subheader>
                <Pressable hitSlop={12} onPress={onPressChevronRight}>
                    <Icon name={'ico_chevronright'} size={24} color={theme["color-ink"]}/>
                </Pressable>
            </View>
            <Divider style={{marginTop: 8}}/>
            <CalendarLabel style={{marginTop: 14}} firstDay={firstDay} dayLabels={dayLabels}/>
        </View>
    )
}
interface WeekProps {
    width: number,
    startDate: Date,
    selectedDate?: Date,
    month: number,
    onPressDay?: (d: Date) => void,
    markedDates?: {date: Date, primaryValue?: number, secondaryValue?: number}[],
    range?: { min: Date, max: Date },
    compact?: boolean,
    monthLabels?: string[],
    log?: boolean
}

function Week(props: WeekProps) {

    const {
        width,
        startDate,
        selectedDate,
        month,
        onPressDay,
        markedDates,
        range,
        compact,
        monthLabels,
    } = props;

    const days = [0, 1, 2, 3, 4, 5, 6];

    const containerStyle : ViewStyle = {
        flex: 1,
        alignItems: 'center', 
        justifyContent: 'center', 
        paddingBottom: 6, 
        marginTop: compact ? 0 : 6
    }

    const textStyle = useThemeStyle('CalendarLabel', props);

    return width ? (
        <View style={{flexDirection: 'row', width: width}}>
            { startDate && days.map(day => {
                const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + day, 1);
                const mark = markedDates && markedDates.find(d => dateCompare(date, d.date));
                const inRange = range && (date < range.min || date > range.max) ? false : true;
                const inMonth = date.getMonth() === month;

                return (
                    <Pressable 
                        key={day}
                        style={[containerStyle, {opacity: inMonth ? (inRange ? 1.0 : 0.4) : 0.0}]}
                        onPress={() => inRange && inMonth && onPressDay && onPressDay(date)}
                    >
                        <CalendarDigit
                            value={date.getDate()}
                            selected={dateCompare(date, selectedDate)}
                            primaryMark={mark?.primaryValue}
                            secondaryMark={mark?.secondaryValue}
                            compact={compact}
                        />
                        { monthLabels ? <Text style={textStyle}>{monthLabels[date.getMonth()]}</Text> : null }
                    </Pressable>
                )
            })}
        </View>
    ) : null;
}
