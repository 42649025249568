import React from 'react';
import { View } from 'react-native';

import Padel from './Padel';
import Headline from './typography/Headline';

import useTheme from './theme/useTheme';

export interface LogoProps {
    color?: string,
    size: string,
    style?: any
}

Logo.defaultProps = {
    size: 'L'
}
export default function Logo(props: LogoProps) {

    const {
        color,
        style : styleProp,
        size
    } = props;

    const theme = useTheme();
    const xlProps = size === 'XL' ? {strokeColor: 'white', strokeWidth: 2} : {};
    return(
        <View style={[{flexDirection: 'row', alignItems: 'flex-end'}, styleProp]}>
            <Padel size={size} showLogo={true} {...xlProps}/>
        </View>
    )
}
