import React from 'react';
import { Platform } from 'react-native';
import { getFunctions, httpsCallable } from "firebase/functions";
import { User, Competition, Group } from 'business';
import { createProvisionalUsers } from 'bridge';
import { IPressEvent } from 'interfaces';
import { StringTable, saveError } from 'utils';
import { Section, Paragraph } from 'components';
import ContactsListSheet from './sheets/ContactsSheet/ContactsListSheet';
import { arrayUnique, noUndefined } from 'tools';
import RegistrationSheet from './sheets/RegistrationSheet';
import RespondInvitationSheet from './sheets/RespondInvitationSheet';
import safelyRegisterUser from './safelyRegisterUser';
import ConfirmSheet from './sheets/ConfirmSheet';
import LoginSheet from './sheets/LoginSheet';
import ClassSelectionSheet from './sheets/ClassSelectionSheet';

export default async function joinTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popModal: any, popAllModals: any, login: (phoneNumber: string, pinCode: string) => Promise<any>, setPreventOnboarding: any, currentUser?: User | null) {

    let userId = currentUser?.id;
    let partnerId : string | undefined;
    let partnerTitle;

    if (target?.competition) {
        let competition : Competition = target.competition;

        //Step 0: Select class if competition has classes
        if (competition.settings.type === 'BIG TOURNAMENT') {
            const subCompetition = await pushModal((
                <ClassSelectionSheet
                    competition={competition}
                />
            ), e);
            if (subCompetition) {
                competition = subCompetition;
            }
            else {
                popAllModals();
                return;
            }
        }

        //Step 1: Show invitation response sheet if user was invited
        if (currentUser && competition.participation.isInvited(currentUser.id)) {
            const invitationResponse : IRespondInvitationResult | null = await pushModal((
                <RespondInvitationSheet 
                    title={competition.getTitle()} 
                    asReserve={competition.isFull()}
                />
            ), e);

            if (invitationResponse?.response !== true) {
                invitationResponse?.response === false && competition.decline(currentUser.id);
                popAllModals();
                return;
            }
        }

        //Step 2 (web): Show registration sheet if team signup or if user is anonymous
        if (Platform.OS === 'web' && (!currentUser || competition.settings.teamSignup)) {
            const registrationResults : IRegistrationSheetResult | null = await pushModal((
                <RegistrationSheet
                    participants={arrayUnique([...(competition.participation.userRefs || []), ...(competition.participation.reservesRefs || [])])}
                    userId={currentUser?.id}
                    userTitle={currentUser?.settings.title}
                    isTeamSignup={competition.settings.teamSignup}
                    style={{flex: 1}}
                />
            ), {...e, pageY: 0});
            if (!registrationResults) {
                popAllModals();
                return;
            }
            else {
                if (!currentUser && registrationResults.pinCode) {

                    setPreventOnboarding(true);
                    login(registrationResults.userId, registrationResults.pinCode).then(() => {
                        return safelyRegisterUser(null, registrationResults.userId, { 
                            title: registrationResults.userTitle, 
                            region: competition.getRegion() 
                        });
                    }).catch((err: any) => {
                        //Probably not useful to tell users about this error
                        saveError(registrationResults.userId, err, 'joinTarget login web');
                    })
                }
                userId = currentUser?.id || registrationResults.userId;
                partnerId = registrationResults.partnerId;
                partnerTitle = registrationResults.partnerTitle;
            }
        }
        //Step 2 (native): Pick partner if team signup
        else if (Platform.OS !== 'web' && competition.settings.teamSignup) {
            if (!currentUser) {
                popAllModals();
                return;
            }
            const contactsResult : ContactData[] | null = await pushModal((
                <ContactsListSheet
                    style={{flex: 1}}
                    headlineLabel={StringTable.getString('Your partner?')} 
                    doneLabel={StringTable.getString('Select')}
                    singleSelect={true}
                    extraContacts={target.group?.participation?.userRefs}
                    filterContacts={[...(competition.participation.userRefs || []), ...(competition.participation.reservesRefs || [])]} 
                    filterMessage={StringTable.getString('Already playing')}
                    showVacancy={competition.settings.teamSignupProhibitVacancies ? false : true}
                />
            ), {...e, pageY: 0});

            if (contactsResult?.length) {
                userId = currentUser?.id;
                partnerId = contactsResult[0].id;
                partnerTitle = contactsResult[0].title;
            }
            else {
                popAllModals();
                return;
            }            
        }
        //Step 3: Confirmation
        if (userId) {
            const confirmedResult : IConfirmSheetResult | null = userId && await pushModal((
                <ConfirmSheet 
                    title={StringTable.getString(`Confirm register`)}
                    doneLabel={StringTable.getString('Register')}
                    doneMode={'primary'}
                >
                    <Paragraph weight={'bold'}>{competition.getTitle()}</Paragraph>
                    {partnerId ? (
                        <Section size={'L'}>
                            <Paragraph>{`${StringTable.getString('Playing with')} ${partnerTitle || partnerId}`}</Paragraph>
                        </Section>
                    ) : null }
                </ConfirmSheet>
            ), e);
    
            if (confirmedResult?.confirmed && userId) {
                competition.join(partnerId ? [userId, partnerId] : [userId]);
                if (partnerId && partnerTitle) {
                    createProvisionalUsers([{
                        id: partnerId, title: partnerTitle, region: competition.getRegion()
                    }]);
                }
            }
        }
    }
    else if (target?.group) {
        const group : Group = target.group;
        if (!currentUser) {
            await pushModal((
                <LoginSheet
                    style={{flex: 1}}
                />
            ), {...e, pageY: 0})
            popAllModals();
            return;
        }
        else {
            const confirmedResult : IConfirmSheetResult | null = await pushModal((
                <ConfirmSheet 
                    title={StringTable.getString(`Confirm join`)}
                    doneLabel={StringTable.getString('Join')}
                    doneMode={'primary'}
                >
                    <Paragraph weight={'bold'}>{group.getTitle()}</Paragraph>
                </ConfirmSheet>
            ), e);
    
            if (confirmedResult?.confirmed) {
                group.join([currentUser.id]);
            }
        }
    }
    popAllModals();
}
