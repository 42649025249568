//@ts-nocheck
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { useTheme, Paragraph, Pressable } from 'components';
import { navigateUrl } from 'utils';

interface NotificationsViewProps {
    notification: Notifications.Notification, 
    onDismiss: any, 
    onPress?: any,
    onFollow: any
}

export default function NotificationView(props: NotificationsViewProps) {
    const {
        notification,
        onDismiss, 
        onPress : onPressProp
    } = props;

    const theme = useTheme();
    const navigation = useNavigation();

    useEffect(() => {
        const timeout = notification && setTimeout(() => {
            onDismiss();
        }, 4000);

        return () => {
            timeout && clearTimeout(timeout);
        }
    }, [notification]);

    function onPress() {
        let url = notification.request?.content?.data?.url;
        navigateUrl(navigation, url);
        onDismiss();
    }
    
    return(
        <Pressable onPress={onPressProp || onPress}>
            <View style={{borderRadius: theme["roundness"], borderWidth: 1, borderColor: theme["color-ink"], backgroundColor: theme["color-card"], padding: theme["spacing-large"], margin: theme["spacing-large"]}}>
                {notification?.request?.content?.title ? <Paragraph weight={'bold'}>{notification?.request?.content?.title}</Paragraph> : null }
                <Paragraph>{notification?.request?.content?.body}</Paragraph>
            </View>
        </Pressable>
    )
}
