import { useState, useEffect } from 'react';
import { Competition } from 'business';

export default function useCompetitionUserRefs(competition?: Competition | null, subCompetitions?: Competition[]): string[] | undefined {

    const [userRefs, setUserRefs] = useState<string[]>();
    useEffect(() => {
        const refs = [...(competition?.participation.userRefs || [])];
        if (subCompetitions) {
            for (const subCompetition of subCompetitions) {
                refs.push(...(subCompetition?.participation.userRefs || []));
            }
        }
        setUserRefs(prev => JSON.stringify(prev) === JSON.stringify(refs) ? prev : refs);

    }, [competition, subCompetitions]);

    return userRefs;
}
