import React from 'react';
import Text from '../helpers/Text';
import useThemeStyle from '../theme/useThemeStyle';

export interface CaptionProps {
    children?: string,
    style?: any,
    numberOfLines?: number, 
    weight?: 'bold' | 'book',
    onLayout?: (e: any) => void
}

export default function Caption(props: CaptionProps) {

    const {
        style : styleProp,
        children,
        numberOfLines,
        onLayout,
    } = props;
    const style = useThemeStyle('Caption', props);

    return(
        <Text onLayout={onLayout} style={[style, styleProp]} numberOfLines={numberOfLines}>
            {children}
        </Text>
    )
}