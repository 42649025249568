import React from 'react';
import { ScrollView } from 'react-native';
import { Paragraph } from 'components';
import { IPressEvent } from 'interfaces';
import MessageSheet from './sheets/MessageSheet';
import { StringTable } from 'utils';

export default async function showLog(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    await pushModal((
        <MessageSheet style={{flex: 1}} title={StringTable.getString('log')} showConfirmButton={true}>
            <ScrollView style={{flex: 1}}>
                <Paragraph>{target?.competition?.log || target?.group?.log ||target?.club?.log || StringTable.getString('No log')}</Paragraph>
            </ScrollView>
        </MessageSheet>
    ), { ...e, pageY: 0});
    popAllModals();

}
