import React from 'react';
import { View, PixelRatio, TouchableOpacity } from 'react-native'
import { useTheme, Subheader, Pressable, Section, Icon, Paragraph, Caption, Badge } from 'components';
import { StringTable } from 'utils';
import { Conversation, ConversationMessage } from 'business';
import { useUser, useNotificationCounter } from 'bridge';
import { useAppContext } from '../appcontext';
import { useNavigation } from '../navigation';
import { useStyles } from '../hooks';

interface Props {
    conversation: Conversation,
    style?: any
}

export default function ConversationCard(props: Props ) {

    const {
        conversation, 
        style
    } = props;
    
    const fontScale = PixelRatio.getFontScale();

    const { currentUser } = useAppContext();
    const { navigate } = useNavigation();
    const theme = useTheme();
    const styles = useStyles();
   
    
    const latestMessage : ConversationMessage | undefined = conversation.getLatestMessage();
    const latestMessageSenderIdentity = useUser(latestMessage?.sender);
    const notificationCount = useNotificationCounter(currentUser?.id || '', `conversations/${conversation.id}`);

    return(
        <TouchableOpacity style={styles.ConversationCard} onPress={() => navigate({conversationId: conversation.id})}>
            <View style={{flex: 1}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <View style={{marginRight: theme["spacing-extrasmall"], width: 24, height: 24, borderRadius: 12, backgroundColor: theme["color-paper"], justifyContent: 'center', alignItems: 'center'}}>
                        <Icon name={conversation.groupRef ? 'ico_group' : 'ico_calendar'} size={12} color={theme["color-ink"]}/>
                    </View>
                    <Subheader style={{flex: 1}} numberOfLines={1}>{conversation.getTitle()}</Subheader>
                    { latestMessage ? (
                        <Caption weight={'book'}>{latestMessage.getTimeStamp(StringTable.shortDayLabels, StringTable.shortMonthLabels, StringTable.getString('Yesterday'))}</Caption>
                    ) : null }
                </View>
                <Section size={'S'} style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                    <Paragraph weight={'book'} numberOfLines={2} style={{flex: 1}}>{latestMessage ? `${latestMessageSenderIdentity?.getTitle() || latestMessage.sender}: ${latestMessage.message}` : StringTable.getString('No messages')}</Paragraph>
                    <Badge count={notificationCount} style={{position: 'absolute', top: 0, right: 0}}/>
                </Section>
            </View>
        </TouchableOpacity>
    )
}
