import React from 'react';
import { useAppContext } from '../appcontext';
import MessageSheet from '../actions/sheets/MessageSheet';
import { useStyles } from '../hooks';
import { Pressable, Paragraph, Icon, useTheme } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import Tutorials from './Tutorials';
import { useModal } from '../actions';

export default function TutorialsMenu(props: any) {

    const { tutorials } = useAppContext();
    const theme = useTheme();
    const styles = useStyles();
    const { pushModal, popModal } = useModal();

    async function handlePress(e: IPressEvent) {
        await pushModal((
            <MessageSheet 
                style={{flex: 1, padding: 0}}
            >
                <Tutorials onDismiss={() => popModal()}/>
            </MessageSheet>
        ), {...e, pageY: 0})
        
    }

    return tutorials?.length ? (
        <Pressable style={[styles.TutorialsMenu, {flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}]} onPress={handlePress}>
            <Paragraph style={{color: theme["color-primary"]}}>{StringTable.getString('How it works')}</Paragraph>
            <Icon name={'ico_chevronright'} size={12} color={theme["color-primary"]}/>
        </Pressable>
    ) : null;
}
