//@ts-nocheck
import React, { useState, useRef } from 'react';
import { View, TextInput as RNTextInput, Platform } from 'react-native';
import useThemeStyle from './theme/useThemeStyle';
import useTheme from './theme/useTheme';
import Pressable from './helpers/Pressable';
import Icon from './helpers/Icon';

export interface TextInputProps {
    value?: string, 
    defaultValue?: string,
    placeholder?: string,
    onChangeText?: any,
    style?: any, 
    iconLeft?: string, 
    iconRight?: string, 
    onPressIconLeft?: any, 
    onPressIconRight?: any, 
    multiline?: boolean, 
    numberOfLines?: number,
    onEndEditing?: any, 
    onFocus?: any,
    mode?: string,
    autoFocus?: boolean,
    editable?: boolean
}

export default function TextInput(props : TextInputProps) {

    const {
        value, 
        defaultValue,
        placeholder,
        onChangeText,
        style : styleProp, 
        iconLeft,
        iconRight,
        onPressIconLeft : onPressIconLeftProp,
        onPressIconRight : onPressIconRightProp,
        multiline,
        numberOfLines,
        onEndEditing : onEndEditingProp,
        onFocus : onFocusProp,
        autoFocus,
        editable
    } = props;

    const [focused, setFocused] = useState(false);
    const style = useThemeStyle('TextInput', props, focused && 'focused');
    const {textColor, ...containerStyle} = style || {};
    const theme = useTheme();

    function onEndEditing(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS !== 'web' && onEndEditingProp(e)
    }

    //https://github.com/facebook/react-native/issues/7275
    function onBlur(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS === 'web' && onEndEditingProp(e);
    }

    const outlineStyle = Platform.OS === 'web' ? { outline: 'none'} : {};
    const textInputRef = useRef();
    function handleOnPressContainer() {
        textInputRef.current?.focus();
    }

    function onPressIconLeft() {
        textInputRef.current?.blur();
        onPressIconLeftProp && onPressIconLeftProp();
    }

    function onPressIconRight() {
        textInputRef.current?.blur();
        onPressIconRightProp && onPressIconRightProp();
    }

    return (
        <Pressable style={[containerStyle, styleProp]} onPress={handleOnPressContainer} hitSlop={15}>
            <View style={[{flexDirection: 'row', alignItems: 'flex-start'}]}>
                {iconLeft ? (
                    <Pressable tabIndex={-1} onPress={onPressIconLeft} style={{marginTop: 3, marginHorizontal: theme["spacing-small"]}} hitSlop={15}>
                        <Icon 
                            name={iconLeft} 
                            size={18} 
                            color={textColor}                                
                        />
                    </Pressable>
                ) : null}
                <RNTextInput
                    ref={textInputRef}
                    style={[{flex: 1, color: textColor, padding: iconLeft ? 0 : theme["spacing-small"], height: (multiline && numberOfLines) ? (numberOfLines * style.lineHeight + (iconLeft ? 0 : 2 * theme["spacing-small"])) : style.lineHeight + (iconLeft ? 0 : 2 * theme["spacing-small"])}, outlineStyle]}
                    value={value}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    onFocus={(e: any) => {
                        setFocused(true);
                        onFocusProp && onFocusProp(e);
                    }}
                    onBlur={onBlur}
                    onEndEditing={onEndEditing}
                    multiline={multiline}
                    numberOfLines={numberOfLines}
                    onChangeText={onChangeText}
                    placeholderTextColor={textColor}
                    autoFocus={autoFocus}
                    editable={editable}
                />
                {iconRight && !multiline ? (
                    <Pressable onPress={onPressIconRight} hitSlop={15} style={{marginHorizontal: theme["spacing-small"]}}>
                        <Icon 
                            name={iconRight} 
                            size={18} 
                            color={textColor}
                        />
                    </Pressable>
                ) : null }
            </View>
        </Pressable>
    )
}
