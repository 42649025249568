import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";

export default function useNotificationCounter(uId: string, notificationLink: string) {

    const [result, setResult] = useState();
    useEffect(() => {

        
        const unsubscribe = uId && notificationLink && onValue(ref(getDatabase(), `notificationCounters/${uId}/${notificationLink}`), snapshot => {
            if (snapshot.exists()) {
                setResult(snapshot.val() || null);
            }
            else {
                setResult(null);
            }
        });

        return () => {
            unsubscribe && unsubscribe();
        }

    }, [uId, notificationLink]);

    return result;
}
