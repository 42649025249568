import { View } from 'react-native';
import { Competition } from "business";
import { IPressEvent } from "interfaces";
import { Factory } from '../../factories';
import SavingMessageSheet from "./sheets/SavingMessageSheet";
import { StringTable } from 'utils';
import ConfirmSheet from "./sheets/ConfirmSheet";
import { Paragraph } from "components";

export default async function pauseCompetition(e: IPressEvent, requestConfirmation: boolean, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const targetCompetition : Competition | undefined = target?.competition;

    if (!targetCompetition) { 
        return false;
    };

    if (requestConfirmation) {
        const confirmResult : IConfirmSheetResult | null = await pushModal((
            <ConfirmSheet
                title={StringTable.getString('Pause event')}
                doneLabel={StringTable.getString('Pause')}
            >
                <View style={{margin: 12, justifyContent: 'center', alignItems: 'center'}}>
                    <Paragraph>{StringTable.getString('Event is started')}</Paragraph>
                    <Paragraph>{StringTable.getString('Do you want to pause it?')}</Paragraph>
                </View>
            </ConfirmSheet>
        ), e);

        if (!confirmResult?.confirmed) {
            popAllModals();
            return false;
        }
    }

    pushModal((<SavingMessageSheet title={StringTable.getString('Pausing')}/>), e);
    
    const competitions = await Factory.getCompetitions(targetCompetition);
    if (competitions) {
        await Promise.all(competitions.map(competition => competition.pause()));
    }    

    popAllModals();
    return true;
}
