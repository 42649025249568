import React from 'react';
import { User } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Factory } from '../../factories';
import GroupSheet from './sheets/GroupSheet';
import SavingMessageSheet from './sheets/SavingMessageSheet';

export default async function addGroupToTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User) {

    if (!currentUser) { return };

    //@ts-ignore
    const result : IGroupSheetResult | null = await pushModal((
        <GroupSheet
            title={StringTable.getString('Create group')}
            doneLabel={StringTable.getString('Create')}
            style={{flex: 1}}
            initialSettings={{
                clubRef: target?.club?.id
            }}
        />
    ), {...e, pageY: 0});

    let groupId;
    if (result) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Saving')}/>
        ), e);

        const admin = [currentUser.id];
        const userRefs = [currentUser.id];
        groupId = await Factory.makeGroup(result.settings, { admin, userRefs });
    }
    popAllModals();
    return groupId;
}
