import React from 'react';
import { IPressEvent } from 'interfaces';
import { Competition, SuperSchedule } from 'business';
import TeamsSheet from './sheets/TeamsSheet';
import { StringTable } from 'utils';
import MessageSheet from './sheets/MessageSheet';
import { Paragraph } from 'components';

export default async function swapTeamsInGroup(e: IPressEvent, teams: [string, string][] | undefined | null, competition: Competition | undefined, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const schedule : SuperSchedule | null = target?.schedule || null;
    
    if (!competition || !schedule || !teams) {
        await pushModal((
            <MessageSheet
                title={StringTable.getString('Error')}
                showConfirmButton={true}
            >
                <Paragraph>{'No target'}</Paragraph>
            </MessageSheet>
        ), e);
        popAllModals();
        return;
    }

    if (competition?.state?.started) {
        await pushModal((
            <MessageSheet
                title={StringTable.getString('Error')}
                showConfirmButton={true}
            >
                <Paragraph>{'Cannot change groups, competition already started'}</Paragraph>
            </MessageSheet>
        ), e);
        popAllModals();
        return;
    }

    function filterTeams(teams: [string, string][] | null | undefined, skipTeams: [string, string][] | null) : [string, string][] | null | undefined {
        if (!teams || !skipTeams) {
            return teams;
        }

        const skipTeamsMap = new Map(skipTeams);
        const filteredTeams = teams.filter(team => !skipTeamsMap.has(team[0]));
        return filteredTeams;
    }

    const otherTeams = filterTeams(competition?.getTeams(), teams);

    if (!teams || !otherTeams) {
        popAllModals();
        return;
    }

    const selectedTeam : [string, string] | null | undefined = await pushModal((
        <TeamsSheet 
            style={{flex: 1}}
            title={StringTable.getString('Team to swap out')} 
            doneLabel={StringTable.getString('Select')}
            teams={teams}
        />
    ), {...e, pageY: 0});

    if (!selectedTeam) {
        popAllModals();
        return;
    }

    const replacementTeam : [string, string] | null | undefined = await pushModal((
        <TeamsSheet 
            style={{flex: 1}}
            title={StringTable.getString('Team to swap in')} 
            doneLabel={StringTable.getString('Swap')}
            teams={otherTeams}
        />
    ), {...e, pageY: 0})

    if (!replacementTeam) {
        popAllModals();
        return;
    }

    const modifiedMatches = competition.swapTeams(competition.getAllMatches(), selectedTeam, replacementTeam, true);
    modifiedMatches && schedule.replaceSomeMatchesInCompetition(competition.id, modifiedMatches.map(m => m.serialize()));
    popAllModals();
}
