import React from 'react';
import { Pressable } from 'react-native';
import Icon from './helpers/Icon';
import Paragraph from './typography/Paragraph';
import useThemeStyle from './theme/useThemeStyle';

export interface CheckBoxProps {
    checked?: boolean,
    label?: string,
    mode?: string,
    size?: string,
    style?: any,
    onPress?: any,
    hitSlop?: number, 
    disabled?: boolean
}

export default function CheckBox(props: CheckBoxProps) {

    const {
        checked,
        label,
        style : styleProp,
        onPress,
        hitSlop,
        disabled
    } = props;

    //@ts-ignore
    const { color, iconSize, ...textStyle } = useThemeStyle('CheckBox', props);

    return(
        <Pressable pointerEvents={disabled ? 'none' : 'auto'} style={[{flexDirection: 'row', alignItems: 'center'}, styleProp]} onPress={onPress} hitSlop={hitSlop}>
            <Icon name={checked ? 'ico_checked' : 'ico_unchecked'} color={color} size={iconSize}/>
            <Paragraph style={[textStyle, {color: color}]}>{label}</Paragraph>
        </Pressable>
    )
}