import React from 'react';
import { View, Platform, useWindowDimensions } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useStyles } from '../hooks';
import { IconButton, Button, Paragraph, useTheme, Logo, Subheader } from 'components';
import { useNavigation } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import { useActions, useModal } from '../actions';
import MenuSheet from '../actions/sheets/MenuSheet';

interface Props {
    title: string,
    children?: any
}
export default function TabHeader(props: Props) {
    
    const { title, children } = props;
    const theme = useTheme();
    const styles = useStyles();
    const route = useRoute();

    const { currentUser, offline, deviceType } = useAppContext();
    const { navigate } = useNavigation();
    const { target, addCompetitionToTarget, addGroupToTarget, addClub } = useActions();
    const { width } = useWindowDimensions();

    async function createCompetition(e: IPressEvent) {
        const competitionId = await addCompetitionToTarget(e);
        competitionId && navigate({competitionId});
    }

    async function createGroup(e: IPressEvent) {
        const groupId = await addGroupToTarget(e);
        groupId && navigate({groupId});
    }

    async function createClub(e: IPressEvent) {
        const clubId = await addClub(e);
        clubId && navigate({clubId});
    }
    
    return offline ? (
        <View style={[styles.screenHeader, {backgroundColor: theme["color-negative"]}]}>
            <View style={{flex: 1, alignItems: 'center'}}>
                <Paragraph weight={'bold'} style={{color: theme["color-ink-contained"]}}>{StringTable.getString('No internet')}</Paragraph>
                <Paragraph style={{color: theme["color-ink-contained"]}}>{StringTable.getString('No edits possible')}</Paragraph>
            </View>
            <View style={styles.row}>
                <TabHeaderNavigation collapsed={Platform.OS === 'web' && width < 700 ? true : false}/>
            </View>
        </View>
    ) : (
        <View style={styles.screenHeader}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Logo size={'M'} style={{marginRight: theme["spacing-small"]}}/>
                <Subheader>{title || 'Padel iD'}</Subheader>
            </View> 

            <View style={styles.row}>
                {children}
                <TabHeaderNavigation collapsed={Platform.OS === 'web' && width < 700 ? true : false}/>
            </View>
        </View>
    )
}

function TabHeaderNavigation(props: any) {
    const { collapsed} = props;

    const theme = useTheme();
    const styles = useStyles();
    const route = useRoute();
    const { navigate } = useNavigation();
    const { editUserSettings } = useActions();
    const { pushModal, popAllModals } = useModal();



    function doNavigate(params: INavigationParams) {
        popAllModals();
        navigate(params);
    }

    async function onPressNavigationMenu(e: IPressEvent) {
        const result = await pushModal((
            <MenuSheet title={StringTable.getString('Navigate to')}>
                { renderLinks(true) }
            </MenuSheet>
        ), {...e, pageY: 0});

    }
    function renderLinks(collapsed: boolean) {
        const routes = {
            'Home': 'HomeStack', 
            'Groups': 'GroupsStack', 
            'Discover': 'DiscoverStack', 
            'Conversations': 'ConversationsStack', 
            'Clubs': 'ClubsStack'
        }
        return (
            <>
            { Platform.OS === 'web' ? Object.keys(routes).map(routeName => (
                <Button 
                    key={routeName} 
                    onPress={() => doNavigate({stackId: routes[routeName as keyof typeof routes]})} 
                    mode={route.name === routeName ? 'outlined' : 'plain'} 
                    label={StringTable.getString(routeName)} 
                    style={{marginRight: collapsed ? 0 : theme["spacing-medium"], marginBottom: collapsed ? theme["spacing-medium"] : 0}}
                    shadow={false}
                    penColor={theme["color-primary"]}
                />
            )) : null }
            { Platform.OS === 'web' ? (
                <Button 
                    onPress={editUserSettings}
                    mode={'plain'} 
                    label={StringTable.getString('Settings')}
                    shadow={false}
                    penColor={theme["color-primary"]}
                />
            ) : (
                <IconButton 
                    name={'ico_person'}
                    onPress={editUserSettings}
                />
            )}

            </>
        )
    }
    return collapsed ? (
        <IconButton name={'ico_more'} onPress={onPressNavigationMenu}/>
    ) : (
        <View style={styles.row}>
            {renderLinks(false)}
        </View>
    )
}