import React from 'react';
import { IPressEvent } from 'interfaces';
import MessageSheet from './sheets/MessageSheet';
import StringTable from '../utils/StringTable';
import { Paragraph, Section } from 'components';

export default async function showMessage(e: IPressEvent, message: string, pushModal: any, popModal: any) {
    await pushModal((
        <MessageSheet
            title={StringTable.getString('System message')}
            showConfirmButton={true}
            confirmButtonLabel={StringTable.getString('OK')}
            confirmButtonMode={'primary'}
        >
            <Section style={{alignItems: 'center'}}>
                <Paragraph>{message}</Paragraph>
            </Section>
        </MessageSheet>
    ), e);
    popModal();
}

