import { useEffect, useState } from 'react';
import { UserIdentity } from 'business';
import { IdentityCache } from 'bridge';
import { validatePhoneNumber } from 'tools';

export default function useExtendedContactData(contactData: ContactData): ContactData {

    const [extendedContactData, setExtendedContactData] = useState<ContactData>(contactData);

    useEffect(() => {
        function onPlayerChange(userRef: string, userIdentity: UserIdentity | null) {
                    
            if (userIdentity) {
                const data = {...contactData};
                if (userIdentity.imageUrl) {
                    data.imageUrl = userIdentity.imageUrl;
                }
                if (userIdentity.registered) {
                    data.registered = userIdentity.registered;
                }
                if (userIdentity.title && !validatePhoneNumber(userIdentity.title)) {
                    data.title = userIdentity.title;
                }

                setExtendedContactData(data);
            }
        }

        if (contactData.id && contactData.registered === undefined) {
            IdentityCache.listen(contactData.id, onPlayerChange);
            return () => {
                IdentityCache.stop(contactData.id, onPlayerChange);
            };
        }
    }, [contactData]);

    return extendedContactData;
}
