import { useState, useEffect } from 'react';
import { getResult } from 'business';
import { Match } from 'business';

export default function useMatchHistory(userId?: string, matchHistory?: Match[]) {

    const [history, setHistory] = useState<{rating: number, result: boolean | undefined}[]>([]);

    useEffect(() => {
        if (userId && matchHistory?.length) {

            function isDefinedMatch(m: Match): m is Match & { endDate: number } {
                return m.endDate !== undefined;
            }

            const sortedHistory = matchHistory
                .filter(isDefinedMatch)  
                .sort((a, b) => (a.endDate - b.endDate));

            let rating = sortedHistory[0].ratings?.in[sortedHistory[0].userRefs.indexOf(userId)] || 1000;
            setHistory(sortedHistory.map((match: Match) => {
                const result = getResult(match, match.points, true);
                const index = match.userRefs.indexOf(userId);
                rating += match.ratings?.change?.[index] || 0;
                return index !== -1 ? {
                    rating: rating,
                    result: result?.winners === -1 ? undefined : (Math.floor(match.userRefs.indexOf(userId) / 2) === result?.winners ? true : false)
                } : {
                    rating: 0, 
                    result: false
                }
            }));
        }
        else {
            setHistory([]);
        }
    }, [userId, matchHistory]);

    return history;
}