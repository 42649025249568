import React, { useState } from 'react';
import { View } from 'react-native';
import { Paragraph, TextInput, Button, Headline, useTheme, Overline, Section } from 'components';
import { StringTable } from 'utils';
import { useDocumentInstance, createFromInstance } from 'bridge';
import { Conversation } from 'business';
import { useAppContext } from '../appcontext';
import { createId } from 'tools';

export default function SupportScreen(props: any) {

    const { currentUserId } = useAppContext();
    const conversationId = `support${currentUserId || 'anonymous'}`;

    const conversation : Conversation | undefined = useDocumentInstance('conversations', conversationId);

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [label, setLabel] = useState('submit');
    return (
        <View style={{padding: 12}}>
            <Headline>{'Support'}</Headline>
            <Paragraph style={{marginTop: 12}} numberOfLines={8}>{'Feedback is a gift and we welcome yours. However, please review the FAQ below before posting a question.'}</Paragraph>
            <Section>
                <Overline>{StringTable.getString('Your email')}</Overline>
                <TextInput 
                    value={email}
                    onChangeText={(t: string) => setEmail(t)}
                    multiline={false}
                />
            </Section>

            <Section>
                <Overline>{StringTable.getString('Message')}</Overline>
                <TextInput 
                    value={message}
                    onChangeText={(t: string) => setMessage(t)}
                    multiline={true}
                    numberOfLines={6}
                />
            </Section>
            <Button 
                style={{marginTop: 18, alignSelf: 'flex-start'}}
                label={label}
                mode={'contained'}
                onPress={async () => {
                    setLabel('sending');
                    if (conversation) {
                        conversation.addMessage({
                            id: createId(14),
                            sender: currentUserId || 'anonymous', 
                            message: `[${email}]: ${message}`, 
                            date: new Date()
                        })
                    }
                    else {
                        const newConversation = new Conversation({
                            id: conversationId, 
                            participation: {
                                admin: ['+46123123456'],
                                userRefs: ['+46123123456', ...(currentUserId ? [currentUserId] : [])]
                            }, 
                            title: 'Support',
                            messages: [{
                                id: createId(23),
                                sender: currentUserId || 'anonymous', 
                                message: `[${email}]: ${message}`, 
                                date: new Date()    
                            }]                        
                        }, null);
                        createFromInstance(newConversation);
                    }
                    setEmail('');
                    setMessage('');
                    setTimeout(() => {
                        setLabel('sent');
                    }, 1000);
                    setTimeout(() => {
                        setLabel('submit');
                    }, 2000);
                }}
            />
            <Headline style={{marginTop: 36}}>{'FAQ'}</Headline>
            <Section>
                <Paragraph>{'This FAQ will expand as we grow our userbase'}</Paragraph>
            </Section>

        </View>
    );
}
