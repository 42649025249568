import React from 'react';
import { View } from 'react-native';
import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import useThemeStyle from '../theme/useThemeStyle.js';

const Icon = createIconSetFromIcoMoon(
    require('../../../assets/fonts/utilityIcons/selection.json'),
    'utilityFonts',
    'fonts/utilityFonts/icomoon.ttf');


const sizeModifiers = {
    'ico_chevronleft': 1.3,
    'ico_chevronright': 1.3,
    'ico_settings': 0.85,
    'ico_chatround': 0.75,
    'ico_searchheavy': 0.8
};

export default function IconWrapper(props) {

    const {
        style : styleProp,
        mode,
        size,
        name,
        ...otherProps
    } = props;

    const style = useThemeStyle("Icon", props);
    const modifiedSize = size * (sizeModifiers[name] || 1.0);
    return mode === 'contained' ? (
        <View style={[style, {width: size, height: size, alignItems: 'center', justifyContent: 'center'}]}>
            <Icon selectable={false} style={[style, styleProp]} {...otherProps} name={name} size={modifiedSize}/>
        </View>
    ) : (
        <View style={{width: size, height: size, justifyContent: 'center', alignItems: 'center'}}>
            <Icon selectable={false} style={[styleProp]} {...otherProps} name={name} size={modifiedSize}/>
        </View>
    )
}
