import React from 'react';
import { View } from 'react-native';
import { Subheader } from 'components';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Factory } from '../../factories';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import ConfirmSheet from './sheets/ConfirmSheet';

export default async function deleteTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const targetInstance = target?.competition || target?.group || target?.club;
    if (!targetInstance) { return }

    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet
            title={StringTable.getString('Confirm delete')}
            doneLabel={StringTable.getString('Delete')}
            doneMode={'dangerous'}
        >
            <View style={{margin: 12, justifyContent: 'center', alignItems: 'center'}}>
                <Subheader>{targetInstance.settings.title}</Subheader>
            </View>
        </ConfirmSheet>
    ), e);

    if (confirmResult?.confirmed) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Deleting')}/>
        ), confirmResult.e);

        if (target?.competition) {
            await Factory.deleteCompetition(target.competition);
        }
        else if (target?.group) {
            await Factory.deleteGroup(target.group);
        }
        else if (target?.club) {
            await Factory.deleteClub(target.club);
        }
    }
    popAllModals();
}