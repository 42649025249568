import React from 'react';
import { View, ActivityIndicator, FlatList } from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { Caption, Section, TrophyCabinet, useTheme } from 'components';
import { StringTable } from 'utils';
import { useDocumentInstance, useBatchedQuery } from 'bridge';
import useSheetStyle from './useSheetStyle';
import CompetitionCard from '../../composites/CompetitionCard';
import UserStats from '../../composites/UserStats';
import CloseHeader from './CloseHeader';
import { useLayout } from '../../hooks';
import { User, Competition } from 'business';
import { Trophy } from 'interfaces';

interface Props {
    userId: string,
    resolve?: (data: null) => void
    style?: any
}

export default function UserSheet(props: Props) {

    const {
        userId,
        style,
        resolve
    } = props;

    const viewUser = useDocumentInstance<User>('users', userId);
    const [competitionHistory, onLoadMoreCompetitionHistory] = useBatchedQuery<Competition>({queryType: 'competitionHistory', userId: viewUser?.id});

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const { layout } = useLayout();

    //Sheets are typically rendered by ActionProvider which is outside of the Navigation context
    //provided by screens. As such we have to fall back to the core navigation system
    const navigation = useNavigation();
    function navigate(params: INavigationParams) {
        resolve?.(null);
        //@ts-ignore
        navigation.navigate('Competition', params);
    }

    return viewUser ? (
        <View style={[sheetStyle, style]}>
            <CloseHeader title={StringTable.getString('User')} onDismiss={() => resolve?.(null)}/>

            <Section size={'L'} style={{flex: 1}}>
                <FlatList
                    data={competitionHistory}
                    onEndReached={onLoadMoreCompetitionHistory}
                    onEndReachedThreshold={0.01}
                    keyExtractor={item => item.id}
                    showsVerticalScrollIndicator={layout === 'mobile' ? false : true}
                    ListHeaderComponent={(
                        <>
                            <UserStats user={viewUser} defaultTitle={StringTable.getString('Anonymous user')}/>
                            { viewUser?.state.trophies?.length ? (
                                <Section size={'L'}>
                                    <Caption>{StringTable.getString('ACHIEVEMENTS')}</Caption>
                                    <TrophyCabinet 
                                        style={{marginTop: 3}} 
                                        trophies={viewUser?.state.trophies}
                                        onPressTrophy={(trophy: Trophy) => {
                                            resolve?.(null);
                                            navigate({competitionId: trophy.competitionId})
                                        }}
                                    />
                                </Section>
                            ) : null }
                            <Section size={'L'}>
                                <Caption style={{marginBottom: theme["spacing-small"]}}>{StringTable.getString('HISTORY')}</Caption>
                            </Section>
                        </>

                    )}
                    renderItem={({item}) => (
                        <CompetitionCard 
                            competition={item} 
                            onPress={() => navigate({competitionId: item.id})} 
                            style={{marginHorizontal: 0}}
                        />
                    )}
                />
            </Section>
        </View>            
    ) : (
        <View style={[sheetStyle, style]}>
            <CloseHeader title={StringTable.getString('User')} onDismiss={() => resolve && resolve(null)}/>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <ActivityIndicator/>
            </View>
        </View>
    )
} 
