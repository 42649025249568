import React, { useEffect, useMemo, useCallback } from 'react';
import { View, FlatList, ScrollView } from 'react-native';
import { useTheme, SectionList } from 'components';
import { useCompetition, useCompetitionSchedule, useGroup, useClub, useLayout, useStyles, useSubCompetitions, useCompetitionSections, useIsCompetitionSchedulable, useCompetitionUserRefs, usePreloadUsers, useGoBackIfNull } from '../hooks';
import { DocHeader, UserCard, DocPanel, DocMenu, TeamCard, CompetitionCard, SectionHeader } from '../composites';
import { NavigationProvider } from '../navigation';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import { Competition } from 'business';
import WaitingScreen from './WaitingScreen';
import SuperScheduleView from '../composites/SuperScheduleView';
import { useIsFocused } from '@react-navigation/native';
import { LegacyScheduleView } from '../legacy';
import { useAppContext } from '../appcontext';
import { IPressEvent } from 'interfaces';

export default function CompetitionScreen(props: ScreenProps) {

    const { route } = props;    
    const { competitionId } = route.params;
    const { currentUserId } = useAppContext();
    const competition : Competition | null | undefined = useCompetition(competitionId);
    const parentCompetition : Competition | null | undefined = useCompetition(competition?.settings?.parentCompetitionRef);
    const subCompetitions = useSubCompetitions(competitionId);
    const schedule = useCompetitionSchedule(competition);
    const userRefs = useCompetitionUserRefs(competition, subCompetitions);
    const usersPreloaded = usePreloadUsers(userRefs);
        
    const group = useGroup(competition?.settings.groupRef);
    const club = useClub(competition?.settings.clubRef);
    const sections = useCompetitionSections(competition?.participation, competition?.settings.slots, competition?.settings.teamSignup);
    const styles = useStyles();
    const theme = useTheme();
    const { columns } = useLayout();
    
    const palette = useMemo(() => {
        const colors = [theme["color-competition-2"], theme["color-competition-3"], theme["color-competition-4"], theme["color-competition-5"], theme["color-competition-6"]];
        const p = competition ? {[competition.id]: theme["color-competition-1"]} : {};
        subCompetitions?.forEach((c, i) => p[c.id] = colors[i % colors.length])
        return p;
    }, [competition, subCompetitions]);

    const { setTarget, showUserMenu } = useActions();
    const doShowUserMenu = useCallback((e: IPressEvent, userRef: string) => {
        competition && showUserMenu(e, userRef, competition)
    }, [competition]);

    const isFocused = useIsFocused();
    useEffect(() => {
        isFocused && setTarget({competition, parentCompetition, group, club, schedule});
    }, [isFocused, competition, parentCompetition, group, club, schedule]);

    const isSchedulable = useIsCompetitionSchedulable(competition || undefined, subCompetitions);
    const isLegacy = competition?.schedule && competition?.state.closed ? true : false;
    
    function renderPanel(noImage?: boolean) {
        return(
            <View>
                <DocPanel 
                    imageUrl={noImage ? undefined : competition?.settings.imageUrl} 
                    userRefs={(noImage || competition?.settings.type === 'BIG TOURNAMENT') ? undefined : (competition?.participation.userRefs || [])} 
                    title={competition?.settings.getTitle()} 
                    groupTitle={group?.settings.getTitle()}
                    clubTitle={club?.settings?.getTitle()}
                    dateTimeString={competition?.settings.getDateTimeString(StringTable.shortDayLabels, StringTable.shortMonthLabels)}
                    byline={competition?.settings.getByline()}  
                    description={competition?.settings.description} 
                    isPublic={competition?.settings.public} 
                    isRepeating={competition?.settings.repeat}
                    isPlaying={competition?.participation.isPlayer(currentUserId)}
                    isReserve={competition?.participation.isReserve(currentUserId)}
                >
                    {isLegacy ? null : <DocMenu title={competition?.settings.getTitle()} schedule={schedule} isSchedulable={isSchedulable}/>}
                </DocPanel>
                { competition?.settings.type === 'BIG TOURNAMENT' && !schedule && !isLegacy ? (
                    <SectionHeader header={StringTable.getString('Classes')} counter={`(${subCompetitions?.length || 0})`} sectionId={'classes'}/>
                ) : null }
            </View>
        )
    }
    
    const loading = 
        competition === undefined || 
        (competition?.settings.groupRef && group === undefined) || 
        (competition?.settings.clubRef && club === undefined) ||
        (competition?.superScheduleRef && schedule === undefined) ||
        !usersPreloaded ||
        sections === undefined || 
        !isFocused;

    useGoBackIfNull(competition);

    function render() {
        if (competition) {
            if (schedule) {
                return(
                    <SuperScheduleView 
                        ListHeaderComponent={renderPanel(true)}
                        schedule={schedule}
                        parentCompetition={competition?.settings.type === 'BIG TOURNAMENT' ? competition : undefined}
                        competitions={subCompetitions?.length ? subCompetitions : [competition]}
                        palette={palette}
                    />
                )
            }
            else if (competition.settings.type === 'BIG TOURNAMENT') {
                return(
                    <FlatList
                        data={subCompetitions}
                        style={{flex: 1}}
                        numColumns={columns}
                        key={columns}
                        keyExtractor={item => item.id}
                        ListHeaderComponent={renderPanel()}
                        renderItem={({item, index}) => (
                            <CompetitionCard  
                                competition={item} 
                                competitionTitle={item.settings.title}
                                style={columns === 2 ? styles.listItemColumns : styles.listItem}
                            />
                        )}
                    />
                )
            }
            else if (competition?.schedule && competition?.state.closed ) {
                return (
                    <LegacyScheduleView
                        ListHeaderComponent={renderPanel()}
                        competition={competition}
                        group={group || undefined}
                        club={club}
                        palette={palette}
                    />
                )
            }
            else {
                return (
                    <SectionList
                        style={{flex: 1}}
                        numColumns={columns}
                        showsVerticalScrollIndicator={false}
                        data={sections}
                        keyExtractor={(item) => item?.id || item}
                        ListHeaderComponent={renderPanel()}
                        renderSectionItem={(item, index, sectionId) => Array.isArray(item) ? (
                                <TeamCard 
                                    userRefs={item}
                                    verbose={true}
                                    onPressUser={doShowUserMenu} 
                                /> 
                            ) : (
                                <UserCard 
                                    userRef={item}
                                    verbose={true}
                                    onPress={doShowUserMenu}
                                />
                            )
                        }
                        renderSectionHeader={(header, counter, sectionId) => (
                            <SectionHeader 
                                header={header} 
                                counter={counter} 
                                sectionId={sectionId}
                            />
                        )}
                    />
                )
            }
        }
        //This will never happen
        return <ScrollView showsVerticalScrollIndicator={false}/>;
    }
    
    return (
        <NavigationProvider>
        { loading ? <WaitingScreen/> : (
            <View style={styles.screen}>
                <DocHeader title={competition?.getTitle()} floating={schedule ? false : true}>
                    {render()}
                </DocHeader>                    
            </View>
        )}
        </NavigationProvider>
    )
} 
