//@ts-nocheck
import React, { useState } from 'react';
import { View, TextInput as RNTextInput, Platform, TouchableOpacity } from 'react-native';
import useThemeStyle from './theme/useThemeStyle';
import useTheme from './theme/useTheme';
import Icon from './helpers/Icon';
import Text from './helpers/Text';
import Subheader from './typography/Subheader';
import { Locale } from 'interfaces';
import { splitPhoneNumber } from 'tools';

export interface TextInputPhoneProps {
    placeholder?: string,
    locale?: Locale,
    locales: {[key: string]: Locale },
    onPressLocale: (e: any) => void,
    digits: string, 
    onChangeDigits: (t: string) => any,
    onChangeLocale: (l: Locale) => any,
    onEndEditing?: any,
    onFocus?: any,
    mode?: string,
    style?: any,
    editable?: boolean
}

export default function TextInputPhone(props : TextInputPhoneProps) {

    const {
        placeholder,
        locale,
        locales,
        onPressLocale,
        digits, 
        onChangeDigits,
        onChangeLocale,
        onEndEditing : onEndEditingProp,
        onFocus : onFocusProp,
        style : styleProp,
        editable
    } = props;

    const [focused, setFocused] = useState(false);
    const style = useThemeStyle('TextInputPhone', props, focused && 'focused');
    //@ts-ignore
    const {textColor, fontFamily, fontSize, lineHeight, ...containerStyle} = style || {};
    const theme = useTheme();

    function onEndEditing(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS !== 'web' && onEndEditingProp(e)
    }

    //https://github.com/facebook/react-native/issues/7275
    function onBlur(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS === 'web' && onEndEditingProp(e);
    }

    const textStyle = { color: textColor, fontFamily, fontSize, lineHeight };
    const outlineStyle = Platform.OS === 'web' ? { outline: 'none'} : {};

    return(
        <View style={styleProp}>
            <View style={containerStyle}>
                <View style={[{flexDirection: 'row', alignItems: 'center'}]}>
                    <TouchableOpacity 
                        onPress={onPressLocale} 
                        style={{marginTop: 3, flexDirection: 'row', paddingLeft: theme["spacing-small"], borderRightWidth: 1, borderColor: textColor}}
                        hitSlop={15}
                    >
                        <Subheader style={{width: 24, marginTop: 4}}>{locale?.flag}</Subheader>
                        <Icon name={'ico_chevrondown'} size={24} color={textColor}/>
                    </TouchableOpacity>

                    <Text style={[textStyle, {marginLeft: 5}]}>{locale?.dialCode}</Text>
                    <RNTextInput
                        style={[{flex: 1, marginTop: Platform.OS === 'web' ? 0 : 2, padding: theme["spacing-small"]}, outlineStyle, textStyle]}
                        value={digits}
                        placeholder={placeholder}
                        onFocus={() => {
                            setFocused(true);
                            onFocusProp && onFocusProp();
                        }}
                        onBlur={onBlur}
                        onEndEditing={onEndEditing}
                        onChangeText={(t: string) => {
                            const isPaste = Math.abs(t.length - digits.length) > 1 ? true : false;

                            if (isPaste && t.length) {
                                try {
                                    const { locale, digits } = splitPhoneNumber(t, locales);
                                    const isNum = /^\d+$/.test(digits) || !digits.length;
                                    isNum && onChangeDigits(digits);
                                    locale && onChangeLocale(locale);
                                } catch(err) {
                                    //Do nothing, the paste was invalid
                                    console.log(err);
                                }    
                            }
                            else {
                                const isNum = /^\d+$/.test(t) || !t.length;
                                isNum && onChangeDigits(t);    
                            }
                        }}
                        placeholderTextColor={focused ? theme["color-ink"] : theme["color-ink-contained"]}
                        keyboardType={'numeric'}
                        editable={editable}
                    /> 
                </View>
            </View>
        </View>
    )
}
