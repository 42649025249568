import { Platform } from 'react-native';

const theme = {
    "color-ink": "#444444",
    "color-ink-soft": "#999999",
    "color-ink-supersoft": "#D9D9D9",
    "color-ink-contained": "#FFFFFF",
    "color-background": "#D9D9D9", 
    "color-paper": "#F4F4F4", 
    "color-card": "#FFFFFF",
    "color-primary": "#ff905c",
    "color-blue": "#0A70FF",
    "color-shadow": "#222222",
    "color-negative": "#FF6B6B",
    "color-neutral": "#F9A318",
    "color-positive": "#6BCB77",
    "color-competition-1": '#F9A318',
    "color-competition-2": '#C25451',
    "color-competition-3": '#0082C9',
    "color-competition-4": '#447B75',
    "color-competition-5": '#91714F',
    "color-competition-6": '#00C9AE',
    "color-passive": "#DBD8C5",
    "color-gold": "#DAA520",
    "color-film": "rgba(0,0,0,0.6)",
    "color-message": "#FFFFFF",
    "color-message-mine": "#D9F9D9",
    "color-message-pen": "#222222",
    "text-extraextralarge": Platform.OS === 'web' ? 48 : 50,
    "text-extraextralarge-lineheight": Platform.OS === 'web' ? 52 : 54,
    "text-extralarge": 36,
    "text-extralarge-lineheight": 37,
    "text-large": 17, 
    "text-large-lineheight": 20,
    "text-medium": 14,
    "text-medium-lineheight": 16,
    "text-small": Platform.OS === 'web' ? 12 : 13,
    "text-small-lineheight": Platform.OS === 'web' ? 14 : 15,
    "spacing-extralarge": 30, 
    "spacing-large": 20,
    "spacing-large-negative": -20,
    "spacing-medium": 11,
    "spacing-medium-negative": -11,
    "spacing-small": 5,
    "spacing-extrasmall": 3,
    "spacing-modal": 14,
    "icon-medium": 24,
    "roundness": 18,
    "roundness-sharp": 8,
    "card-maxwidth": 500
}

export default theme;