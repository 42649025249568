import React from 'react';
import { Paragraph } from 'components';
import { IMatch, IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Competition, SuperSchedule, Match } from 'business';
import ResultSheet from './sheets/ResultSheet/ResultSheet';
import MessageSheet from './sheets/MessageSheet';
import SavingMessageSheet from './sheets/SavingMessageSheet';

export default async function setMatchResult(e: IPressEvent, competition: Competition, matchId: string, pushModal: any, popAllModals: any, schedule?: SuperSchedule) {
    
    if (!competition) { return };

    const match = competition?.getMatch(matchId);
    if (!match) { return }

    const resultSheetResult : IResultSheetResult | null = await pushModal((
        <ResultSheet
            userRefs={match.userRefs}
            initialResult={match.result}
            points={match.points}
            style={{flex: 1}}
        />
    ), {...e, pageY: 0});

    if (!resultSheetResult || resultSheetResult.cancelled) {
        popAllModals();
        return;
    }

    if (!competition.canEndMatch(matchId, resultSheetResult.result)) {
        await pushModal((
            <MessageSheet
                showConfirmButton={true}
                confirmButtonLabel={StringTable.getString('OK')}
            >
                <Paragraph>{StringTable.getString('This match cannot be updated as it has already influenced the outcome of other matches')}</Paragraph>
            </MessageSheet>
        ), e);
        popAllModals();
        return;
    }

    pushModal((<SavingMessageSheet title={StringTable.getString('Saving result')}/>), e);
    const endMatchResult : Match[] | boolean = await competition.endMatch(match.id, new Date(), resultSheetResult.result);
    
    if (schedule && Array.isArray(endMatchResult)) {
        //The only time we get matches back from competition.endMatch is in a mexicano 
        //But we can't do schedule.setMatches because then we'd eliminate any other competition's matches
        schedule.replaceAllMatchesInCompetition(competition.id, endMatchResult.map(m => m.serialize()));
    }
    else if (!endMatchResult) {
        console.log('An unknown error occured')
        popAllModals();
        return;
    }
    //And otherwise the result was accepted and saved
    popAllModals();    
}
