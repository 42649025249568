import React, { useState, useRef } from 'react';
import { View } from 'react-native';
import { useTheme, Popup, Button, Divider, SectionHeader, Overline, CalendarMonth, Section, Pressable, CalendarTime, CalendarDigit, Paragraph } from 'components';
import { StringTable } from 'utils';
import { Settings } from 'business';
import { ICompetitionSettings } from 'interfaces';

interface Props {
    competitionSettings: ICompetitionSettings,
    setCompetitionSettings: any
}

export default function EventSheet(props: Props) {

    const { competitionSettings, setCompetitionSettings } = props;
    const theme = useTheme();

    function initDateRange() {
        const min = new Date();
        min.setHours(0);
        min.setMinutes(0);
        min.setSeconds(0);
        return { min: min, max: new Date(2200, 1, 1) }
    }
    const dateRange = useRef(initDateRange()).current;

    const [popup, setPopup] = useState('');
    const [dateString, timeString] = competitionSettings.date && Settings.getDateTimeStrings(competitionSettings.date, StringTable.shortDayLabels, StringTable.shortMonthLabels) || [];
    
    const style = {
        left: { flex: 1, marginRight: theme["spacing-extrasmall"] },
        right: { flex: 1, marginLeft: theme["spacing-extrasmall"] }
    }

    return(
        <Section>
            <SectionHeader icon={'ico_calendar'} label={StringTable.getString('WHEN?')}/>
            <Section size={'M'} style={{flexDirection: 'row'}}>
                <Button 
                    icon={'ico_calendar'}
                    label={dateString || StringTable.getString('Set date')}
                    onPress={() => setPopup('date')}
                    mode={dateString ? 'contained' : 'primary'}
                    style={style.left}
                />
                <Button 
                    icon={'ico_time'}
                    label={timeString || StringTable.getString('Set time')}
                    onPress={() => competitionSettings.date && setPopup('time')}
                    mode={timeString ? 'contained' : 'primary'}
                    style={style.right}
                />
            </Section>
            <Popup visible={popup === 'date'} onDismiss={() => setPopup('')}>
                <View style={{maxWidth: theme["card-maxwidth"], padding: theme["spacing-medium"], backgroundColor: theme["color-card"], borderRadius: theme["roundness"]}}>
                    <CalendarMonth 
                        selectedDate={competitionSettings.date}
                        onChangeDate={(d: Date) => {
                            const old = competitionSettings.date;
                            const date = new Date(
                                d.getFullYear(),
                                d.getMonth(), 
                                d.getDate(), 
                                old?.getHours() || 0,
                                old?.getMinutes() || 0
                            );
                            setCompetitionSettings((prev: ICompetitionSettings) => ({...prev, date}));
                        }}
                        dayLabels={StringTable.shortDayLabels}
                        monthLabels={StringTable.monthLabels}
                        range={dateRange}
                        firstDay={0}
                    />
                </View>
            </Popup>
            <Popup visible={popup === 'time'} onDismiss={() => setPopup('')}>
                <View style={{maxWidth: theme["card-maxwidth"], padding: theme["spacing-medium"], backgroundColor: theme["color-card"], borderRadius: theme["roundness"]}}>
                    <CalendarTime 
                        selectedTime={competitionSettings.date}
                        onChangeTime={(d: Date) => {
                            const old = competitionSettings.date || new Date();
                            setCompetitionSettings((prev: ICompetitionSettings) => ({
                                ...prev, 
                                date: new Date(
                                    old.getFullYear(), 
                                    old.getMonth(), 
                                    old.getDate(), 
                                    d.getHours(), 
                                    d.getMinutes()
                                )
                            }))
                        }}
                        hourLabel={StringTable.getString('HOUR')}
                        minuteLabel={StringTable.getString('MINUTE')}
                    />

                    <Overline style={{marginTop: 14}}>{StringTable.getString('DURATION')}</Overline>
                    <Divider style={{marginBottom: 6}}/>
                    <View style={{flexDirection: 'row'}}>
                        { [60, 90, 120, 0].map((duration: number) => (
                            <Pressable 
                                key={duration} 
                                onPress={() => {
                                    setCompetitionSettings((prev: ICompetitionSettings) => ({...prev, duration: duration}));
                                }}
                            >   
                                { duration ? (
                                    <CalendarDigit compact={true} value={duration} selected={competitionSettings.duration === duration}/>
                                ) : (
                                    <View style={{marginLeft: theme["spacing-medium"], flex: 1, height: '100%', alignItems: 'flex-end', justifyContent: 'center'}}>
                                        <Paragraph style={{color: competitionSettings.duration === 0 ? theme["color-primary"] : theme["color-ink"]}}>{StringTable.getString('Unspecified')}</Paragraph>
                                    </View>
                                )}
                            </Pressable>
                        ))}                                    
                    </View>
                </View>
            </Popup>
        </Section>
    )
}

