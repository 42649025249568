import { getFirestore, query, collection, where, limit, getDocs, QueryConstraint } from "firebase/firestore";
import { getDatabase, ref, set, increment } from "firebase/database";
import { Group, Club, Competition, Conversation, User } from 'business';
import parse from './parse';
import save from './save';

export default async function searchAsync<T>(collectionId: string, options: { documentId?: string, groupRef?: string, clubRef?: string, parentCompetitionRef?: string, searchQuery?: string, public?: boolean, future?: boolean}, fbApp?: any ) : Promise<T[] | undefined> {

    const _classes = {
        "groups": Group,
        "clubs": Club,
        "competitions": Competition,
        "conversations": Conversation,
        "users": User
    }
    const _class = _classes[collectionId as keyof typeof _classes];
    if (!_class) {
        return undefined;
    }

    const qparams : QueryConstraint[] = [];
    if (options.public) {
        qparams.push(where('meta.public', '==', true));
    }
    if (options.documentId) {
        qparams.push(where('meta.id', '==', options.documentId));
    }
    if (options.searchQuery) {
        qparams.push(where('meta.searchTokens', 'array-contains', options.searchQuery.toLowerCase()));
    }
    if (options.future) {
        qparams.push(where('meta.date', '>=', new Date()));
    }
    if (options.groupRef) {
        qparams.push(where('meta.groupRef', '==', options.groupRef));
    }
    if (options.clubRef) {
        qparams.push(where('meta.clubRef', '==', options.clubRef));
    }
    if (options.parentCompetitionRef) {
        qparams.push(where('meta.parentCompetitionRef', '==', options.parentCompetitionRef));
    }

    let q = query(
        collection(getFirestore(fbApp), collectionId), 
        ...qparams,
        limit(50)
    );

    const snap = await getDocs(q);
    set(ref(getDatabase(), `analytics/reads/searchAsync`), increment(snap.size || 1));

    const instances = [];

    for (const doc of snap.docs) {
        try {
            if (_class && doc.exists()) {
                instances.push(new _class(parse(collectionId, doc), save));
            }
        } catch(err) {
            console.log(err);
            return undefined;
        }
    }
    //@ts-ignore
    return instances;
}