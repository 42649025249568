import { arrayMove, clamp, createId } from '../tools';
import { IMatch, TableRow } from '../interfaces';
import type { Competition } from './Competition';
import type { SuperSchedule } from './SuperSchedule';
import type { Match } from './Match';

export interface MatchResult {
    winners: -1 | 0 | 1, 
    points?: {
        home: number, 
        away: number
    }, 
    sets?: {
        home: number,
        away: number,
        draw: number
    },
    games?: {
        home: number, 
        away: number
    }
}

/**
 * Zero if no players.
 * @param players 
 */
export function getRoundsize(players: string[]) {
    return Math.floor((players?.length || 0) / 4);
}

/**
 * Ignores odd players. Empty array if no players.
 * @param players
 */
export function getPairing(players: string[]) {
    return players.reduce((result: [string, string][], value: string, index: number, array: string[]) => {
        //To include odd players, check for index % 2 === 0 and push array[index], array[index+1]
        if (index % 2 === 1) {
            result.push([array[index-1], array[index]]);
        }
        return result;
    }, []) || [];
}


/**
 * Last round may be smaller than roundsize. 
 * @param players 
 * @param matches 
 */
export function getRounds(players: string[], matches: IMatch[]) : IMatch[][] {
    const size = getRoundsize(players);
    return matches.reduce((result: (IMatch[])[], value: IMatch, index: number, array: IMatch[]) => {
        if (index % size === 0) {
            result.push(array.slice(index, index + size));
        }
        return result;
    }, []) || [];
}

/**
 * TODO: We don't have to pass the entire match, just results should be enough
 * @param match 
 * @param points 
 */
export function getResult(match: IMatch, points: boolean = false, candraw: boolean = true) : MatchResult | undefined {

    if (!(match.result)) {
        return;
    }

    if (match.result.length < 2) {
        return;
    }
    
    let ret : MatchResult = points ? { winners: 0, points: { home: 0, away: 0 } } : { winners: 0, sets: { home: 0, away: 0, draw: 0}, games: {home: 0, away: 0} };

    if (points && match.result) {
        ret = {
            points: {
                home: match.result[0] || 0,
                away: match.result[1] || 0
            },
            winners: (candraw && match.result[0] === match.result[1]) ? -1 : ((match.result[0] || 0) >= (match.result[1] || 0) ? 0 : 1)
        }
    }
    else if (match.result) {
        ret = match.result.reduce((acc, curr, index, arr) => {
            if (index % 2 === 0) {
                if ((curr || 0) > (arr[index+1] || 0)) { acc.sets.home += 1 }
                if ((curr || 0) < (arr[index+1] || 0)) { acc.sets.away += 1 }
                if (curr === (arr[index+1] || 0)) { acc.sets.draw += 1 }
                acc.games.home += (curr || 0);
                acc.games.away += (arr[index+1] || 0);
            }
            return acc;
        }, { winners: 0, sets: { home: 0, away: 0, draw: 0}, games: { home: 0, away: 0}});

        if (candraw) {
            //@ts-ignore
            if (ret.sets.home === ret.sets.away && ret.games.home === ret.games.away) {
                ret.winners = -1;
            }
            else {
            //@ts-ignore
            ret.winners = (ret.sets.home > ret.sets.away || (ret.sets.home === ret.sets.away && ret.games.home > ret.games.away) ? 0 : 1);
            }
        }
        else {
            //in mexicano we cannot draw a match (e.g. result 4-4 => home team wins)
            //@ts-ignore
            ret.winners = (ret.sets.home > ret.sets.away || (ret.sets.home === ret.sets.away && ret.games.home >= ret.games.away) ? 0 : 1);
        }
    }
    return ret;
}

export function getWinnersLosers(match: IMatch, points: boolean = false, candraw: boolean = true) : {winners?: (string | null)[], losers?: (string | null)[]} {

    const byeIndex = match.userRefs.indexOf('bye');
    if (match.userRefs.length === 4 && byeIndex !== -1) {
        //@ts-ignore
        return {
            winners: match.userRefs.slice(byeIndex === 0 ? 2 : 0, byeIndex === 0 ? 4 : 2), 
            losers: match.userRefs.slice(byeIndex, byeIndex + 2)
        };
    }    

    const result = getResult(match, points, candraw);
    if (!result) {
        return {}
    }
    const players = [...match.userRefs];
    
    const winners = players.splice(result.winners * 2, result.winners * 2 + 2);
    const losers = players;
    //@ts-ignore

    return { winners, losers };
}
/**
 * Returns a map of players with their points or sets and games
 * @param matches 
 * @param points count points or sets 
 */
export function getTable(matches: IMatch[], points?: boolean) : TableRow[] | null {

    if (!matches) {
        return null
    }

    const players : {[key: string]: TableRow } = matches.reduce((acc: {[key: string]: TableRow }, curr) => {
        curr.userRefs?.forEach(pId => {
            if (pId) {
                acc[pId] = { id: pId, wins: 0, draws: 0, losses: 0, score: 0, setdiff: 0, gamediff: 0, pointsdiff: 0 };
            }
        })
        return acc;
    }, {});

    matches.filter(m => m.userRefs?.length && m.result?.length).forEach((match: IMatch) => {
        const result = getResult(match, points);

        result && match.userRefs?.forEach((player, index) => {
            if (player) {
                players[player].wins += result.winners === -1 ? 0 : (Math.floor(index / 2) === result.winners ? 1 : 0);
                players[player].losses += result.winners === -1 ? 0 : (Math.floor(index / 2) === result.winners ? 0 : 1);
                players[player].draws += result.winners === -1 ? 1 : 0;
                if (points) {
                    players[player].score += index < 2 ? (result.points?.home || 0) : (result.points?.away || 0);
                    players[player].pointsdiff += index < 2 ? (result.points?.home || 0) - (result.points?.away || 0) : (result.points?.away || 0) - (result.points?.home || 0);
                }
                else {
                    players[player].score += result.winners === -1 ? 1 : (Math.floor(index / 2) === result.winners ? 2 : 0);
                    players[player].setdiff += index < 2 ? (result.sets?.home || 0) - (result.sets?.away || 0) : (result.sets?.away || 0) - (result.sets?.home || 0);
                    players[player].gamediff += index < 2 ? (result.games?.home || 0) - (result.games?.away || 0) : (result.games?.away || 0) - (result.games?.home || 0);
                }
            }
        });
    });

    return Object.values(players).sort((a, b) => tiebreakers(a, b, matches, points ? true : false));
}

export function tiebreakers(a: TableRow | undefined, b: TableRow | undefined, matches: IMatch[] | null, points: boolean) {
    if (!(a && b)) {
        return 0;
    }

    if (b.score !== a.score) {
        return b.score - a.score;
    }

    if (points) {
        a.tiebreakers = { pointsdiff: true };
        b.tiebreakers = { pointsdiff: true };
        return b.pointsdiff - a.pointsdiff;
    }

    if (b.setdiff !== a.setdiff) {
        a.tiebreakers = { setdiff: true };
        b.tiebreakers = { setdiff: true };
        return b.setdiff - a.setdiff;
    }

    a.tiebreakers = { gamediff: true };
    b.tiebreakers = { gamediff: true };
    return b.gamediff - a.gamediff;
}

/**
 * 
 * @param players 
 */
export function getRoundrobin(players: string[], competitionId: string) {

    const BYE = null;
    const matches : IMatch[] = [];
    const pairs : ([string, string] | null)[] = getPairing(players);
    if (pairs.length % 2 !== 0) { pairs.unshift(BYE) }

    for (let round = 0; round < pairs.length-1; round++) {
        for (let match = 0; match < pairs.length / 2; match++) {
            const home = pairs[match];
            const away = pairs[match + pairs.length/2];

            if (!(home === BYE || away === BYE)) {
                matches.push({
                    competitionId,
                    userRefs: [...home, ...away],
                    id: createId(23)
                })
            }
        }
        arrayMove(pairs, (pairs.length / 2) - 1, pairs.length - 1);
        arrayMove(pairs, (pairs.length / 2) - 1, 1);
    }
    return matches;
}

/**
 * http://www.durangobill.com/BridgeCyclicSolutions.html
 * 
 * There are many possible permutations of an Ameriano schedule. We determine how good they are 
 * by calculating a variation score. The variation score is calculated as:
 * 
 * For each player P: variationScore = variationScore + (2 ^ doubleOpponents(P))-1
 * 
 * For 8 players, we have 24 permutations. The optimal variation score is [0, 0, 8] when we only consider 
 * the first [2, 3, 4] rounds respectively. Out of the 24 permutations, 8 produce this optimal variation score. 
 * Our previous indicies also produced the optimal variation score.
 * 
 * For 12 players, we have 20 permutations. All permutations provided by durangobill gives [2, 6, 14, 32]
 * 
 * For 16 players, we have 128 permutations. Permutations provided by durangoboll gives highly varying score, 
 * the best one being [0, 0, 0, 4, 9] when checking over 2-6 rounds.
 * 
 * @param players 
 * @param competitionId 
 * @param metaIndicies 
 * @returns 
 */
export function getAmericano(players: string[], competitionId: string, overrideIndicies?: number[]) {

    const matches: IMatch[] = [];

    if (players.length < 4) { throw new Error('Too few players') }
    if (players.length > 31) { throw new Error('Too many players') } 

    const indiciesMap = {
        4: [0,1,2,3], //This is not the durangobill way, however for single matches we now get player 0&1 vs player 2&3 in the first round
        8: [2,3,4,6,5,1,7,0],
        12: [8, 9, 7, 1, 4, 6, 10, 3, 2, 5, 11, 0], 
        16: [12,13,3,9,4,6,14,2,7,11,5,10,1,8,15,0],
        20: [14,15,19,0,16,18,1,10,4,7,6,13,5,9,12,17,2,8,3,11],
        24: [17,18,3,13,20,22,6,9,15,19,8,16,5,10,2,11,21,4,23,0,7,14,1,12],
        28: [22,23,1,14,5,7,8,17,9,12,4,11,21,25,10,18,19,24,3,15,20,26,6,16,2,13,27,0]
    };

    const indicies = overrideIndicies || indiciesMap[Math.floor(players.length/4)*4 as keyof typeof indiciesMap];

    if (!indicies) {
        return [];
    }
    for (let round = 0; round < players.length-1; round++) {
        for (let match = 0; match < indicies.length / 4; match++) {
            matches.push({
                competitionId,
                userRefs: [ players[indicies[match*4]], players[indicies[match*4+1]], players[indicies[match*4+2]], players[indicies[match*4+3]]],
                id: createId(23)
            });
        }
        arrayMove(players, players.length-1, 1);
    }
    return matches;
}

/**
 * Calculates the variation score over the first N rounds
 * @param rounds 
 * @param N 
 * @returns 
 */
export function getAmericanoVariationScore(rounds: IMatch[][], N: number) {

    const hasSeen : { [player: string]: { [ coplayer: string]: number } }= {};

    for (let r = 0; r < N && r < rounds.length; r++) {

        const round = rounds[r];

        for (let m = 0; m < round.length; m++) {
            const match = round[m];

            match.userRefs.forEach((player, playerIndex) => {
                if (player) {
                    hasSeen[player] = hasSeen[player] || {};
                    const partnerIndex = playerIndex % 2 === 0 ? playerIndex + 1 : playerIndex - 1;
                    const partner = match.userRefs[partnerIndex];
                    match.userRefs.forEach(coPlayer => {
                        if (coPlayer) {
                            if (player !== coPlayer && partner !== coPlayer) {
                                hasSeen[player][coPlayer] = (hasSeen[player][coPlayer] || 0) + 1;
                            }
                        }
                    })
                }
            })
        }
    }

    let variationScore = 0;
    Object.values(hasSeen).forEach(player => {
        const contribution = Math.pow(2, Object.values(player).filter(v => v > 1).length) - 1;
        variationScore += contribution;
    });

    return variationScore;
}


/**
 * Returns false if a match in last round was missing a result, i.e. not able to create new round.
 * Creates matches from order of players in competition if no matches provided, discarding non-quadruple players.
 * @param players 
 * @param settings { fixedTeams: boolean, points: boolean }
 * @param matches 
 */
export function getMexicano(players: string[], competitionId: string, previousMatches?: IMatch[], fixedTeams?: boolean, points?: boolean) {

    let matchNumber = 0;

    if (!players?.length) {
        throw new Error('No users, cannot generate mexicano');
    }
    if (!previousMatches) {
        return players.reduce((acc: IMatch[], curr, index, arr) => {
            if ((index + 1) % 4 === 0) {
                acc.push({
                    competitionId,
                    userRefs: arr.slice(index - 3, index + 1), 
                    id: `match_${String(matchNumber).padStart(4, '0')}`
                })
                matchNumber += 1;
            }
            return acc;
        }, []);
    }

    /**
     * If none of the matches in the last round has a result, remove it.
     */
    const roundSize = getRoundsize(players);
    let lastRound = previousMatches?.slice(-roundSize);
    if (lastRound?.length && !lastRound.find((m: IMatch) => m.result?.length)) {
        previousMatches.splice(previousMatches.length - roundSize);
        //change the ref so we trigger inequality checks later
        previousMatches = [...previousMatches];
    }

    /**
     * If at least one match in the last round is missing a result, don't do anything
     */
    lastRound = previousMatches.slice(-roundSize);
    if (lastRound?.length && lastRound.find((m: IMatch) => !m.result?.length)) {
        return previousMatches;
    }

    /**
     * Calculate previous pairings
     */
    const previousPairings = previousMatches?.reduce((pairings: { [user: string]: {[partner: string]: number}}, match) => {
        match.userRefs.forEach((userRef: string | null) => {
            if (userRef && !pairings.hasOwnProperty(userRef)) {
                pairings[userRef] = {};
            }
        })
        for (let i = 0; i < 4; i += 2) {
            const player = match.userRefs[i];
            const partner = match.userRefs[i+1];
            if (player && partner) {
                pairings[player][partner] = (pairings[player][partner] || 0) + 1;
                pairings[partner][player] = (pairings[partner][player] || 0) + 1;
            }
        }    
        return pairings;
    }, {});

    /**
     * If all the matches in the last round have a result, create a new round.
     */
    const results = lastRound.map((match: IMatch) => {
        const result = getResult(match, points, false);
        const losers = result?.winners === 1 ? 0 : 1;
        //@ts-ignore because we know match.userRefs is defined
        return { promoted: match.userRefs.slice(result.winners * 2, result.winners * 2 + 2), relegated: match.userRefs.slice(losers * 2, losers * 2 + 2)}
    });

    /**
     * Create next round with ids starting from match length
     */
    matchNumber = previousMatches.length;
    const nextround = results.reduce((acc: IMatch[], curr, index, array) => {
        const match : IMatch = {
            competitionId,
            userRefs: [
                ...(index === 0 ? curr.promoted : array[index-1].relegated), 
                ...(index === array.length-1 ? curr.relegated : array[index+1].promoted)
            ],
            id: `match_${String(matchNumber).padStart(4, '0')}`
        }
        matchNumber += 1;
        if (!fixedTeams && !match.userRefs.includes(null)) {

            //Swap players to achiveve maximum variability, i.e. lowest permutationVariabilityScore
            const permutationCandidates = [
                [0, 2, 3, 1],
                [0, 3, 2, 1],
                [1, 2, 3, 0],
                [1, 3, 2, 0]
            ];

            const permutationVariabilityScores = permutationCandidates.reduce((acc: number[], candidate: number[]) => {
                const playerA = match.userRefs[candidate[0]];
                const playerB = match.userRefs[candidate[1]];
                const playerC = match.userRefs[candidate[2]];
                const playerD = match.userRefs[candidate[3]];

                if (playerA === null || playerB === null || playerC === null || playerD === null) {
                    return acc;
                }
                const score = (previousPairings[playerA][playerB] || 0) + (previousPairings[playerC][playerD] || 0);
                acc.push(score);
                return acc;
            }, []);

            //Sort is not deterministic for return value 0, so sort on index if scoreDifference is 0
            const permutation = permutationCandidates.sort((a, b) => {
                const indexA = permutationCandidates.indexOf(a);
                const indexB = permutationCandidates.indexOf(b);
            
                const scoreDifference = permutationVariabilityScores[indexA] - permutationVariabilityScores[indexB];
                
                return scoreDifference !== 0 ? scoreDifference : indexA - indexB;
            })[0];

            //Apply the permutation with the lowest permutationVariabilityScore
            match.userRefs = [
                match.userRefs[permutation[0]],
                match.userRefs[permutation[1]],
                match.userRefs[permutation[2]],
                match.userRefs[permutation[3]]
            ]
        }

        acc.push(match);
        return acc;
    }, []);

    return [...previousMatches, ...nextround];
}

/*
ARBITRARILY CHOSEN EXPONENT
teamStrengthExponent = 2.0
stakeMagnitude = 2.0

strengthRatio = (homeRating^teamStrengthExponent)/(awayRating^teamStrengthExponent)
where homeRating and awayRating = strongestPlayer * (weakestPlayer/strongestPlayer) + weakestPlayer * (1.0 - weakestPlayer/strongestPlayer)
EXPECTATION: strengthRatio / (1.0 + strengthRatio)

RESULT: = if (win: 0.5 + weightedGameFraction * 0.5, draw: 0.3 + weightedGameFraction * 0.4, loss: 0.0 + weightedGameFraction * 0.5)
where weightedGameFraction = (gameFraction - worst(win|draw|loss)) / (best(win|dwaw|loss) - worst(win|draw|loss))
where gameFraction = wonGames / (wonGames + lostGames)

RATING: = (RESULT - EXPECTATION) * stake
where stake is stakeMagnitude * min(playerRatings) * min(gamesPlayed / 100, 0.1)
*/

export function getExpectedHomeWinFraction(ratings: number[], exponent: number = 3) {

    const weakestHomePlayerProportion = Math.min(ratings[0], ratings[1]) / Math.max(ratings[0], ratings[1]);
    const weakestAwayPlayerProportion = Math.min(ratings[2], ratings[3]) / Math.max(ratings[2], ratings[3]);

    const homeRating = Math.max(ratings[0], ratings[1]) * weakestHomePlayerProportion + Math.min(ratings[0], ratings[1]) * (1.0 - weakestHomePlayerProportion);
    const awayRating = Math.max(ratings[2], ratings[3]) * weakestAwayPlayerProportion + Math.min(ratings[2], ratings[3]) * (1.0 - weakestAwayPlayerProportion);

    const proportionalStrength = Math.pow(homeRating, exponent) / Math.pow((awayRating), exponent);
    return proportionalStrength / (1.0 + proportionalStrength);
}

export function getActualHomeWinFraction(result: MatchResult) {

    if (result.points) {
        const home = result.points.home || 0;
        const away = result.points.away || 0;
        return home / (home + away);
    }
    else {

        const WORSTDRAW = 7/19; //0-6, 7-6
        const BESTDRAW = 12/19; //6-0, 6-7
        const WORSTWIN = 14/32; //7-6, 0-6, 7-6
        const BESTWIN = 1; //6-0, ...
        const WORSTLOSS = 0; //0-6, ...
        const BESTLOSS = 18/32; //6-7, 6-0, 6-7

        const gameFraction = (result?.games?.home || 0) / ((result?.games?.home || 0) + (result?.games?.away || 0));

        //Set up for draws
        let fractionRange = [0.3, 0.7];
        let fractionWeight = (gameFraction - WORSTDRAW) / (BESTDRAW - WORSTDRAW);


        if ((result.sets?.home || 0) > (result.sets?.away || 0)) {
            fractionRange = [0.5, 1.0];
            fractionWeight = (gameFraction - WORSTWIN) / (BESTWIN - WORSTWIN);
        }
        else if ((result.sets?.away || 0) > (result.sets?.home || 0)) {
            fractionRange = [0.0, 0.5];
            fractionWeight = (gameFraction - WORSTLOSS) / (BESTLOSS - WORSTLOSS);
        }
        
        if (fractionWeight > 1.0 || fractionWeight < 0.0) {
            console.log('warning, clamping of win-fraction weight should not be needed', result);
            fractionWeight = clamp(fractionWeight, 0.0, 1.0);
        }
        const homeFraction = fractionRange[0] + fractionWeight * (fractionRange[1] - fractionRange[0]);
        return homeFraction;
    }
}

export function getRating(inRatings: number[], match: IMatch) {

    const result = getResult(match, match.points);

    if (inRatings.find((r: number) => !r)) {
        return null;
    }

    if (!result) {
        return null;
    }

    const homeExpectation = getExpectedHomeWinFraction(inRatings);
    const homeActual = getActualHomeWinFraction(result);
    const homePerformance = homeActual - homeExpectation;

    //stake per player is always X% of the lowest ranked player's pot
    const stakeSize = 2;
    const stake = Math.min(...inRatings) * stakeSize * (result.points ? Math.min(((result.points.home || 0) + (result.points.away || 0))/600, 0.1) : Math.min(((result.games?.home || 0) + (result.games?.away || 0))/100, 0.1));

    return inRatings.map((playerRating: number, index: number) => (index < 2 ? 1 : -1 ) * stake * homePerformance)
}

export function getPrediction(ratings: number[]) {

    //We can put in any number of predictions, as long as the "spacing" between each is the same
    const predictions = [
        [0,6,0,6],  //0.0
        [1,6,1,6],  //0.1
        [2,6,2,6],  //0.2
        [3,6,3,6],  //0.3
        [4,6,4,6],  //0.4
        [4,6,6,4],  //0.5
        [6,4,6,4],  //0.6
        [6,3,6,3],  //0.7
        [6,2,6,2],  //0.8
        [6,1,6,1],  //0.9
        [6,0,6,0]   //1.0
    ];

    if (ratings && ratings.length === 4 && !ratings.find(n => isNaN(n))) {
        const homeTeamWinFraction = getExpectedHomeWinFraction(ratings);
        return predictions[Math.floor(homeTeamWinFraction * predictions.length)];
    }
    else {
        return null;
    }
}

export function swapPlayer(competition: Competition, playerRef: string, replacementRef: string, schedule?: SuperSchedule | null, options?: { swapFromPlayoffMatchView?: boolean, log?: boolean }) {

    let modifiedMatches : Match[] | undefined;
    const fixedTeams = competition.settings.fixedTeams;

    if (fixedTeams) {
        if (options?.swapFromPlayoffMatchView) {
            //Swap from playoff: Swap only for unplayed matches and not in group tables
            modifiedMatches = competition._swapPlayer(competition.getUnplayedMatches(), playerRef, replacementRef, { preservePlayingStatus: true, log: options?.log });
            modifiedMatches && schedule && schedule.replaceSomeMatchesInCompetition(competition.id, modifiedMatches.map(m => m.serialize()));
        }
        else {
            //Swap from table view, group match view or pre scheduling: Swap for all matches and in group tables
            modifiedMatches = competition._swapPlayer(competition.getAllMatches(), playerRef, replacementRef, { updateTables: true, log: options?.log });
            modifiedMatches && schedule && schedule.replaceSomeMatchesInCompetition(competition.id, modifiedMatches.map(m => m.serialize()));
        }
    }
    else {
        //Swap from non fixed teams: Swap for unplayed matches and in group tables
        const hasPlayed = competition.getAllMatches().find(match => match.hasResult() && match.userRefs.includes(playerRef)) ? true : false;
        modifiedMatches = competition._swapPlayer(competition.getUnplayedMatches(), playerRef, replacementRef, { updateTables: true, preservePlayingStatus: hasPlayed, log: options?.log });
    }
}
