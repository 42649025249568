import React, { useEffect, useRef } from 'react';
import { View, ScrollView, Animated } from 'react-native';
import useThemeStyle from '../theme/useThemeStyle';

export interface PageListProps {
    width: number,
    initialPage: number,
    children: any,
    onScrollEnd: (contentOffset: number) => any,
    showIndicator?: boolean
}

const PageList = React.forwardRef((props: PageListProps, ref: any) => {

    const {
        width, 
        initialPage,
        onScrollEnd,
        showIndicator,
        children
    } = props;

    const indicatorStyle = useThemeStyle('PageListIndicator', props);

    const scrollEndTimeout = useRef<any>();
    const isScrolling = useRef(false);
    const indicatorTranslate = useRef(new Animated.Value(0)).current;

    useEffect(() => {
        setTimeout(() => {
            width && initialPage !== undefined && ref.current.scrollTo({x: initialPage * width, y: 0, animated: false});            
        }, 0);
    }, [width, initialPage]);


    function handleScroll(e: any) {
        isScrolling.current = true;
        if (scrollEndTimeout.current) {
            clearTimeout(scrollEndTimeout.current);
        }

        const contentOffset = e.nativeEvent.contentOffset.x;
        const contentSize = e.nativeEvent.contentSize.width;
        indicatorTranslate.setValue(width * (contentOffset / contentSize))

        scrollEndTimeout.current = setTimeout(() => {
            onScrollEnd && onScrollEnd(contentOffset);
            isScrolling.current = false;
        }, 100);
    }

    return(
        <>
            {showIndicator ? <Animated.View style={[ indicatorStyle, { width: width / (React.Children.count(children) || 1), transform: [{ translateX: indicatorTranslate }] }]} /> : null }
            <ScrollView
                ref={ref}
                horizontal={true}
                pagingEnabled={true}
                decelerationRate={"fast"}
                showsHorizontalScrollIndicator={false}
                onScroll={handleScroll}
                scrollEventThrottle={32}
            >
                { React.Children.map(children, child => (
                    <View style={{width: width, height: '100%'}}>
                        { child }
                    </View>
                ))}
            </ScrollView>
        </>
    )
});

export default PageList;
