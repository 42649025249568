import React from 'react';
import { View } from 'react-native';
import { initializeApp } from "firebase/app";
import { createFromInstance, getInstance, searchAsync } from 'bridge';
import { Competition, Group, User, Club, SuperSchedule } from 'business';
import { IPressEvent } from "interfaces";
import { Subheader } from 'components';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import ConfirmSheet from './sheets/ConfirmSheet';
import { StringTable } from "utils";

export default async function publishTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {
    if (!target) {
        console.log('no target');
        return;
    }

    const isDev = window?.location?.href?.includes('prank-dev') || window?.location?.href?.includes('localhost');
    const isStaging = window?.location?.href?.includes('staging');

    if (!(isDev || isStaging)) {
        console.log('not pre-release');
        return;
    }
    
    const publishToProdApp = initializeApp({
        apiKey: "AIzaSyDOYdJ73FP-p0KOO5dFHWYEEchKyKtU_LY",
        authDomain: "padel-id.firebaseapp.com",
        projectId: "padel-id",
        storageBucket: "padel-id.appspot.com",
        messagingSenderId: "989899674812",
        appId: "1:989899674812:web:73d7bcba4327ddbfacecb9",
        databaseURL: "https://padel-id-default-rtdb.europe-west1.firebasedatabase.app"
    }, 'publishToProdApp');

    const publishToStagingApp = initializeApp({
        apiKey: "AIzaSyDYEXRwoOsMLHIBDaiEVRnVmNlikASObFU",
        authDomain: "padel-id-staging.firebaseapp.com",
        projectId: "padel-id-staging",
        storageBucket: "padel-id-staging.appspot.com",
        messagingSenderId: "1072244716078",
        appId: "1:1072244716078:web:fec0e5ce2183b64a51e5bf",
        databaseURL: "https://padel-id-staging-default-rtdb.europe-west1.firebasedatabase.app"
    }, 'publishToStagingApp');

    const destDb = isDev ? publishToStagingApp : publishToProdApp;

    async function cloneInstance(instance?: Club | Group | Competition | User | SuperSchedule) {
        if (instance && destDb) {
            const existing : Club | Group | Competition | User | null | undefined = await getInstance(instance.collection, instance.id, destDb);
            if (!existing) {
                await createFromInstance(instance, destDb);
            }
        }
    }

    const competition : Competition = target?.competition;
    if (competition) {

        const confirmResult : IConfirmSheetResult | null = await pushModal((
            <ConfirmSheet
                title={StringTable.getString('Confirm publish')}
                doneLabel={StringTable.getString('Publish')}
                doneMode={'primary'}
            >
                <View style={{margin: 12, justifyContent: 'center', alignItems: 'center'}}>
                    <Subheader>{competition.settings.title}</Subheader>
                </View>
            </ConfirmSheet>
        ), e);
    
        if (confirmResult?.confirmed) {

            pushModal((
                <SavingMessageSheet title={`${StringTable.getString('Publishing to')} ${isDev ? 'staging' : 'production'}`}/>
            ), e);
        
            const userRefs = competition.participation.getAllParties();
            for (const userRef of userRefs) {
                const user : User | null | undefined = await getInstance('users', userRef);
                user && await cloneInstance(user);
            }
            if (competition.settings.clubRef) {
                const club : Club | null | undefined = await getInstance('clubs', competition.settings.clubRef);
                club && await cloneInstance(club);
            }
            if (competition.settings.groupRef) {
                const group : Group | null | undefined = await getInstance('groups', competition.settings.groupRef);
                group && await cloneInstance(group);
            }
            if (competition.settings.type === 'BIG TOURNAMENT') {
                const subCompetitions : Competition[] | undefined = await searchAsync('competitions', { parentCompetitionRef: competition.id});
                if (subCompetitions) {
                    for (const subCompetition of subCompetitions) {
                        await cloneInstance(subCompetition);
                    }
                }
                if (competition.superScheduleRef) {
                    const schedule : SuperSchedule | null | undefined = await getInstance('schedules', competition.superScheduleRef);
                    if (schedule) {
                        await cloneInstance(schedule);
                    }

                }
            }
            await cloneInstance(competition);
        }
    }
    else {
        console.log('no competition');
    }

    popAllModals();

}