import React, { useState, useRef } from 'react';
import { View, ScrollView, Platform, TouchableOpacity } from 'react-native';
import { PageIndicator, Headline, Logo, TextInput, Section, Pressable, useTheme, Button, Subheader, Caption, GradientView } from 'components';
import PinCodeInput from '../../composites/PinCodeInput';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import RegionsSheet from './RegionsSheet';
import useSheetStyle from './useSheetStyle';
import useModal from '../useModal';

interface OnboardingSheetProps {
    showChangePin?: boolean,
    onChangePin: (chosenPinCode: string) => Promise<void>,
    showRequestPushNotifications?: boolean,
    onRequestPushNotifications?: () => Promise<any>,
    style?: any,     
    resolve?: (result: null | { title: string, region: string} ) => void
}
export default function OnboardingSheet(props: OnboardingSheetProps) {

    const {
        showChangePin,
        onChangePin,
        showRequestPushNotifications,
        onRequestPushNotifications,
        style,
        resolve
    } = props;

    const [width, setWidth] = useState(0);
    const [title, setTitle] = useState('');
    const [region, setRegion] = useState('');

    function onDismiss() {
        resolve && resolve({title, region})
    }
    const theme = useTheme();

    const pageStyle={ flex: 1, width: width, padding: 10};

    const scrollRef = useRef<any>();
    const { pushModal, popModal } = useModal();
    const sheetStyle = useSheetStyle();

    const pageCount = 2 + (showChangePin ? 1 : 0) + (showRequestPushNotifications ? 1 : 0);
    return(
        <GradientView 
            style={[sheetStyle, style, {padding: 0, paddingTop: 12}]} 
            startColor={theme["color-paper"]} 
            endColor={theme["color-card"]} 
            onLayout={(e: any) => setWidth(e.nativeEvent.layout.width)}
        >
            { width ? (
                <PageIndicator 
                    pageCount={pageCount}
                    renderFooter={(pageIndex: number) => (
                        <View style={{paddingBottom: 19, paddingHorizontal: 11, flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Button onPress={() => scrollRef.current.scrollTo({x: width * (pageIndex - 1), y: 0, animated: true})} mode={pageIndex === 0 ? 'passive' : 'contained'} style={{opacity: pageIndex === 0 ? 0 : 1}} label={StringTable.getString('Back')}/>
                            { pageIndex === pageCount-1 ? null : (
                                <Button onPress={() => scrollRef.current.scrollTo({x: width * (pageIndex + 1), y: 0, animated: true})} mode={pageIndex === 0 && !title ? 'passive' : 'contained'} style={{opacity: pageIndex === 0 && !title ? 0 : 1}} label={StringTable.getString('Next')}/>
                            )}
                        </View>
                    )}
                >
                    <ScrollView 
                        ref={scrollRef} 
                        horizontal={true} 
                        pagingEnabled={true} 
                        scrollEnabled={title ? true : false} 
                        showsHorizontalScrollIndicator={false}
                        keyboardShouldPersistTaps="handled"
                        keyboardDismissMode="on-drag"
                    >
                        {/* PAGE 1, SET NAME */ }
                        <View style={pageStyle}>
                            <View style={{flex: 1, alignItems: 'center'}}>
                                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                    <Logo size={'L'}/>
                                </View>

                                <Section size={'L'}>
                                    <Subheader style={{alignSelf: 'center', color: theme["color-ink"]}}>{StringTable.getString('Welcome to Padel iD')}</Subheader>
                                    <Subheader style={{alignSelf: 'center', marginTop: theme["spacing-small"], color: theme["color-ink"]}}>{StringTable.getString('Please enter your name')}</Subheader>
                                    <Section size={'L'}>
                                        <TextInput
                                            defaultValue={''}
                                            placeholder={StringTable.getString('Name')}
                                            onEndEditing={(e: any) => {
                                                setTitle(prev => {
                                                    if (!prev) {
                                                        scrollRef.current.scrollTo({x: width, y: 0, animated: true});
                                                    }
                                                    return e.nativeEvent.text.trim();
                                                })
                                            }}
                                            iconLeft={'ico_title'}
                                            style={{width: width * 0.8}}
                                        />
                                    </Section>
                                </Section>
                            </View>
                        </View>

                        {/* PAGE 2, CHANGE PIN CODE */}
                        {showChangePin ? (
                        <View style={pageStyle}>
                            <View style={{flex: 1, alignItems: 'center'}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Logo size={'L'}/>
                                        <Headline style={{marginLeft: theme["spacing-small"], color: theme["color-ink"]}}>{StringTable.getString('Padel iD')}</Headline>
                                    </View>
                                    <PinCodeInput 
                                        onDone={async(chosenPinCode: string) => {
                                            await onChangePin(chosenPinCode);
                                            scrollRef.current.scrollTo({x: width * 2, y: 0, animated: true});
                                        }}
                                        message={StringTable.getString('If you skip this step, your PIN code will be one you received by SMS')}
                                    />
                                </View>
                            </View>
                        ) : null }


                        {/* PAGE 3, SET REGION */ }
                        <Pressable style={pageStyle} onPress={() => scrollRef.current.scrollTo({x: width * 2, y: 0, animated: true})}>
                            <View style={{flex: 1}}>
                                <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                    <Logo size={'L'}/>
                                </View>

                                <Section size={'L'}>
                                    <Subheader style={{alignSelf: 'center', color: theme["color-ink"]}}>{StringTable.getString('Please select your area of residence')}</Subheader>
                                    <Section size={'L'} style={{alignItems: 'center'}}>
                                        <Button 
                                            icon={'ico_language'} 
                                            label={region || StringTable.getString('Set region')}
                                            mode={'primary'}
                                            onPress={async (e: IPressEvent) => {
                                                const regionResult = await pushModal((
                                                    <RegionsSheet
                                                        selectedRegion={region}
                                                        style={{flex: 1}}
                                                    />
                                                ), {...e, pageY: 0});

                                                if (regionResult) {
                                                    setRegion(regionResult);
                                                }
                                                popModal();
                                            }}
                                        />
                                    </Section>

                                    { showRequestPushNotifications ? null : (
                                        <View style={{alignItems: 'center'}}>
                                            <TouchableOpacity style={{ marginTop: 150, backgroundColor: theme["color-primary"], borderRadius: theme["roundness-sharp"], padding: theme["spacing-medium"], paddingHorizontal: theme["spacing-large"]}} onPress={onDismiss}>
                                                <Subheader style={{color: theme["color-ink-contained"]}}>{StringTable.getString('Done')}</Subheader>
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                </Section>
                            </View>
                        </Pressable>

                        {/* PAGE 4, REQUEST PUSH NOTIFICATION PERMISSIONS */}
                        {showRequestPushNotifications ? (
                            <Pressable style={pageStyle} onPress={() => scrollRef.current.scrollTo({x: width * 3, y: 0, animated: true})}>
                                <View style={{flex: 1, alignItems: 'center'}}>
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        <Logo size={'L'}/>
                                    </View>

                                    <Section size={'L'} style={{alignItems: 'center'}}>
                                        <Subheader style={{textAlign: 'center', color: theme["color-ink"]}}>{StringTable.getString('Enable push notifications now to never miss a game')}</Subheader>
                                        <Section size={'L'}>
                                            {Platform.OS === 'web' ? (
                                                <Caption style={{marginBottom: 3, color: theme["color-ink"]}}>{StringTable.getString('Not available in browser')}</Caption>
                                            ) : (
                                                <Button 
                                                    icon={'ico_notifications'} 
                                                    label={StringTable.getString('Enable notifications')}
                                                    mode={'primary'}
                                                    onPress={async (e: IPressEvent) => {
                                                        onRequestPushNotifications?.();
                                                    }}
                                                />
                                            )}
                                        </Section>

                                        <View style={{alignItems: 'center'}}>
                                            <TouchableOpacity style={{ marginTop: 150, backgroundColor: theme["color-primary"], borderRadius: theme["roundness-sharp"], padding: theme["spacing-medium"], paddingHorizontal: theme["spacing-large"]}} onPress={onDismiss}>
                                                <Subheader style={{color: theme["color-ink-contained"]}}>{StringTable.getString('Done')}</Subheader>
                                            </TouchableOpacity>
                                        </View>

                                    </Section>
                                </View>
                            </Pressable>
                        ) : null }
                    </ScrollView>
                </PageIndicator>
            ) : null }
        </GradientView>
    )
}