import { useState, useEffect, useRef } from 'react';
import { UserIdentity } from 'business';
import IdentityCache from './IdentityCache';

export default function useUser(userRef?: string, once?: boolean) : UserIdentity | null | undefined {
    
    const [user, setUser] = useState<UserIdentity | null | undefined>(userRef ? IdentityCache.getSync(userRef) : null);    
    const hasUpdated = useRef(false);
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        }
    }, []);

    function onPlayerChange(userRef: string, identity: UserIdentity | null) {   

        if (!(once && hasUpdated.current)) {
            mounted.current && setUser(identity);
            hasUpdated.current = true;
        }
    }

    useEffect(() => {

        hasUpdated.current = false;

        userRef && IdentityCache.listen(userRef, onPlayerChange);
        return () => {
            userRef && IdentityCache.stop(userRef, onPlayerChange);
        }
    }, [userRef]);

    return user;
}
