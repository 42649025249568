import React from 'react';
import { Platform } from 'react-native';
import { useStyles } from '../hooks';
import { Pressable, Paragraph, Icon, useTheme } from 'components';
import { StringTable } from 'utils';
import * as Linking from 'expo-linking';

export default function UpdateVersionMenu(props: any) {

    const theme = useTheme();
    const styles = useStyles();

    async function handlePress() {
        if (Platform.OS === 'ios') {
            Linking.openURL('https://apps.apple.com/pt/app/padel-id/id1630485168');
        }
        else if (Platform.OS === 'android') {
            Linking.openURL('https://play.google.com/store/apps/details?id=com.staffanklashed.padelid');
        }
    }
    return (
        <Pressable style={[styles.UpdateVersionMenu, {flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}]} onPress={handlePress}>
            <Paragraph style={{color: theme["color-primary"]}}>{StringTable.getString('Get the latest version')}</Paragraph>
            <Icon name={'ico_chevronright'} size={12} color={theme["color-primary"]}/>
        </Pressable>
    );
}
