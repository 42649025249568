import React from 'react';
import { View } from 'react-native';
import { Paragraph, Headline } from 'components';

export default function TermsScreen() {
    return (
        <View>
            <Headline>{'Terms of Use'}</Headline>
            <Headline size={'S'}>{'General terms of use for the Padel.id services.'}</Headline>
            <Paragraph>
                {`The general terms of use ("Terms of Use") are available on the Padel.id websites.Terms of Use are applicable to all services, all usage and all content offered, sold and/or made available on any Padel.id channel such as web site, mobile app and so forth ("the Padel.id Channels").`}
            </Paragraph>
            <Paragraph>
                {`Unless otherwise stated in relation to a specific country, the Padel.id Channels and services are provided by Guesswork AB, 559200-8097 (Henceforth referred to as "Padel.id"). Padel.id reserves the right to take legal actions in all cases of dissemination, reproduction or usage of these Terms of Use, for other than personal use, unless written consent from Padel.id has been obtained.`}
            </Paragraph>
            <Paragraph>
                {`The Padel.id Channels and services are offered on the condition that you accept the Terms of Use without reservation. This also applies to terms that appear on other pages of the website. Your use of any of the Padel.id Channels confirms this agreement and all its points and conditions.`}
            </Paragraph>
            <Headline size={'S'}>{'Changes in terms of use'}</Headline>
            <Paragraph>
                {`Padel.id reserves the right to change the terms of use without notice`}
            </Paragraph>
            <Headline size={'S'}>{"Padel.id's commitments"}</Headline>
            <Paragraph>
                {`Padel.id will provide planning, ranking and matchmaking services online through use of the Internet and electronic devices.`}
            </Paragraph>
 
            <Headline size={'S'}>{"User commitments"}</Headline>
            <Paragraph>
            {`Information that is submitted or sent to Padel.id must be honest and correct. This applies, for example, to your name and phone number.`}
            </Paragraph>
            <Paragraph>
            {`By using the website and its services you agree to abide by all laws and to respect all third parties and their rights, as well as these Terms of Use.`}
            </Paragraph>
            <Paragraph>
            {`In order to best enjoy the Padel.id Channels and to maintain a comfortable and safe environment, you pledge to:`}
            </Paragraph>
            <Paragraph>
            {`* Act in a correct manner towards Padel.id and other users of the website`}
            </Paragraph>

            <Paragraph>
            {`* Photos and other information in the form of data or files that you provide should be decent and exclusively about you or another person who has granted you permission for usage.`}
            </Paragraph>

            <Paragraph>
            {`* Only use your credentials to gain access to the website for yourself.`}
            </Paragraph>

            <Paragraph>
            {`You may not disclose, divulge or make your user information available to third parties.`}
            </Paragraph>
            
            <Headline size={'S'}>{"Links to other websites"}</Headline>
            <Paragraph>
            {`The web site can contain links to other websites ("Links"). These websites are not controlled by Padel.id and therefore Padel.id takes no responsibility for the content on these websites. This applies, without restriction, to all links to external websites. Padel.id solely offers these links as a service to the user. Furthermore, Padel.id is not responsible for any transfer of data from the external links referred to on the website. This applies, without restriction, to all forms of transfer. `}
            </Paragraph>

            <Headline size={'S'}>{"Copyright and trademark information"}</Headline>
            <Paragraph>
            {`All content on the padel.id site is Copyright 2021-2022 by Padel.id and/or their partners. All rights reserved. You may not in any way publish, distribute or reproduce the material, trademarks, images, or other intellectual property rights or third party proprietary information in any form, commercial or private, without first obtaining permission from the owners of the work or the rights holders.`}
            </Paragraph>

            <Headline size={'S'}>{"Abuse and illegal use"}</Headline>
            <Paragraph>
            {`By using the Padel.id Channels, you affirm and guarantee that you will not use the Padel.id Channels in such a manner that would go against Swedish legislation or the terms set out on this page. You will not under any circumstances use the Padel.id Channels in a way that may injure, deactivate, overload, destroy, or in any other way negatively affect it or other users' visits to the Padel.id Channels. You will not acquire or try to acquire access to material which you did not receive written permission for through the Padel.id Channels.`}
            </Paragraph>

            <Paragraph>
            {`You accept that: you will not in any way violate the security of others. You will not publish, upload or distribute any form of harmful or inappropriate material or information. You will not upload files or other material that is protected by copyright laws without owning said rights or having the explicit permission of the proprietor. You will not advertise, promote or in any way attempt to sell goods or services. You will not perform surveys, convey chain letters, or organise pyramid schemes. You will not download any file that, by your knowledge or not, can not be distributed with respect to copyright laws. You will not book, utilize or purchase resources or offers on the Padel.id Channels using, for example, web scraping solutions or other automated solutions. You will also not use the Padel.id Channels with any commercial intent, including, but not limited to, different marketing activities, booking and reselling times or offers to other individuals or third parties, etcetera, unless you have a written agreement for doing so with Padel.id.`}
            </Paragraph>

            <Paragraph>
            {`Padel.id has no obligation to monitor all services on the website. However, Padel.id reserves the right to evaluate all material posted on the website. Padel.id also reserves the right to exclude or terminate access to any material and remove such material without notice.`}
            </Paragraph>

            <Headline size={'S'}>{"Termination/access restriction"}</Headline>
            <Paragraph>
            {`Padel.id reserves the right to terminate your access to the website and its related services without notice. You as a visitor, user or member of this site hereby accept these conditions. Violation of these conditions may result in exclusion from the site and, in the worst case, lawsuits under Swedish and international legislation.`}
            </Paragraph>

            <Headline size={'S'}>{"Reservations and liability waiver"}</Headline>
            <Paragraph>
            {`Under no circumstances is Padel.id be liable for direct or indirect injuries suffered by you as a user, where you have contributed to the injury.`}
            </Paragraph>
            <Paragraph>
            {`To the extent deemed possible under applicable law Padel.id waives all commitments, guarantees, terms or other conditions (including but not limited to guarantees concerning quality or suitability, either expressed or implied) related to the website.`}
            </Paragraph> 
            <Headline size={'S'}>{"General Data Protection Regulation (GDPR)"}</Headline>
            <Paragraph>
            {`Padel.id handles the buyer's personal data in accordance with GDPR (General Data Protection Regulation) and never sends them to non-Padel.id parties. The only exception is whether we explicitly request your approval at the time of purchase.`}
            </Paragraph>
            <Headline size={'S'}>{"Cookies"}</Headline>
            <Paragraph>
            {`Cookies are essential to ensure that you can use Padel.id and its features on a recurring basis. Padel.id uses cookies when you visit the site. A cookie is a small information capsule which is placed in the user's computer. The cookie cannot identify you personally, only the web browser you are using to visit the site. The cookie does not contain any viruses and cannot alter or remove any other information stored on your computer.`}
            </Paragraph>
            <Paragraph>
            {`In order for you as a user to be logged in automatically we use auto-login cookies. Padel.id also collects statistics on visits to the site using cookies. The information collected includes data concerning which pages on Padel.id have been visited and how long time the user has spent on the site. These statistics do not contain any form of personal information.`}
            </Paragraph>
            <Paragraph>
            {`You have the right to deny Padel.id permission to leave cookies on your computer, although in this case you can no longer use Padel.id for functional reasons. You can also choose which level of cookies you wish to allow on your web browser.`}
            </Paragraph>
            <Headline size={'S'}>{"Applicable law"}</Headline>
            <Paragraph>
            {`These terms of use and disputes arising from these or use of the website shall be regulated and interpreted according to Swedish law. Any disputes will be resolved by the Swedish general court.`}
            </Paragraph>
            <Headline size={'S'}>{"About us"}</Headline>
            <Paragraph>
            {`The Padel.id services are provided by Guesswork AB 559200-8097, Hornsgatan 141B SE-11728, Stockholm.`}
            </Paragraph>
            <Headline size={'S'}>{"Terms & conditions"}</Headline>
            <Paragraph>
            {`Last update 2022-06-01`}
            </Paragraph>
            <Headline size={'S'}>{"Abuse"}</Headline>
            <Paragraph>
            {`If the service is misused we reserve the right to terminate the user. In this case any and all outstanding benefits owed to the user expire as well.`}
            </Paragraph>

        </View>
    )
}