//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import { View, ScrollView, ActivityIndicator } from 'react-native';
import { useTheme, Button, Paragraph, TextInput, IconButton, CheckBox } from 'components';
import { useSystemDocument } from 'bridge';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { IRegion } from 'interfaces';

interface Props {
    style?: any
}
export default function RegionsList(props: Props) {

    const {
        style
    } = props

    const theme = useTheme();
    const regionsDoc = useSystemDocument('system/regions');
    const [regions, setRegions] = useState<IRegion[] | null | undefined>();
    useEffect(() => {
        setRegions(regionsDoc?.all);
    }, [regionsDoc]);

    const [saving, setSaving] = useState(false);
    async function save() {
        setSaving(true);
        console.log('[setDoc]: RegionsList')
        await setDoc(doc(getFirestore(), 'system/regions'), {all: regions });
        setSaving(false);
    }

    function setIANA(region: IRegion, IANA: string) {
        region.iana = IANA;
        setRegions([...(regions || [])]);
    }

    function toggleCommon(region: IRegion) {
        region.common = region.common ? false : true;
        setRegions([...(regions || [])]);
    }

    function togglePoints(region: IRegion) {
        region.points = region.points ? false : true;
        setRegions([...(regions || [])]);
    }

    function deleteRegion(region: IRegion) {
        const index = regions?.indexOf(region);
        if (index !== -1) {
            regions?.splice(index, 1);
            setRegions([...(regions || [])]);
        }
    }

    const countries = ['Sverige', 'Portugal', 'Nederland', 'Україна', 'Australia', 'Malaysia'];
    const [newRegion, setNewRegion] = useState({
        'Sverige': '', 
        'Portugal': '',
        'Nederland': '',
        'Україна': '',
        'Australia': '',
        'Malaysia': ''
    });

    return (
        <View style={[style, {flex: 1}]}>

            <Button icon={'ico_arrowup'} label={saving ? 'Saving...' : 'Save'} onPress={save} mode={saving ? 'passive' : 'primary'}/>

            <ScrollView style={{flex: 1, marginTop: theme["spacing-large"]}}>


                {countries.map(country => (
                    <View key={country}>
                        <View style={{flexDirection: 'row', marginTop: theme["spacing-large"]}}>
                            <Paragraph weight={'bold'} style={{flex: 3, textAlign: 'left', paddingLeft: 8}}>{country}</Paragraph>
                            <Paragraph weight={'bold'} style={{flex: 3, textAlign: 'center'}}>IANA</Paragraph>
                            <Paragraph weight={'bold'} style={{flex: 1, textAlign: 'center'}}>Common</Paragraph>
                            <Paragraph weight={'bold'} style={{flex: 1, textAlign: 'center'}}>Points</Paragraph>
                            <Paragraph weight={'bold'} style={{flex: 1, textAlign: 'right', paddingRight: 8}}>Action</Paragraph>
                        </View>

                        { regions?.filter(r => r.country === country).map(region => (
                            <View key={region.title} style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 8, backgroundColor: theme["color-card"], marginTop: theme["spacing-medium"]}}>
                                <Paragraph style={{flex: 3}}>{region.title}</Paragraph>
                                <View style={{flex: 3, alignItems: 'center'}}>
                                    <TextInput value={region.iana || ''} onChangeText={t => setIANA(region, t)}/>
                                </View>
                                <View style={{flex: 1, alignItems: 'center'}}>
                                    <CheckBox checked={region.common} onPress={() => toggleCommon(region)}/>
                                </View>
                                <View style={{flex: 1, alignItems: 'center'}}>
                                    <CheckBox checked={region.points} onPress={() => togglePoints(region)}/>
                                </View>
                                <View style={{flex: 1, alignItems: 'flex-end'}}>
                                    <IconButton
                                        name={'ico_delete'} 
                                        onPress={() => deleteRegion(region)}
                                    />
                                </View>
                            </View>
                        ))}

                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 8, backgroundColor: theme["color-card"], marginTop: theme["spacing-medium"]}}>
                            <TextInput
                                value={newRegion?.[country]}
                                onChangeText={t => setNewRegion({...newRegion, [country]: t})}
                                style={{marginRight: 8}}
                            />
                            <IconButton 
                                name={'ico_add'} 
                                onPress={() => {
                                    setRegions(prev => {
                                        const all = [...(prev || [])];
                                        all.push({
                                            title: newRegion?.[country],
                                            country: country
                                        });
                                        return all;
                                    });
                                    setNewRegion({...newRegion, [country]: ''});
                                }}
                            />
                        </View>
                    </View>
                ))}
            </ScrollView>
        </View>
    )
}
