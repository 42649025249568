import { arrayUnique, arrayRemove } from '../tools';
import { IParticipation } from '../interfaces';
/**
 * Round
 */
export class Participation implements IParticipation {

    admin: string[];
    userRefs?: string[];
    reservesRefs?: string[];
    invitedRefs?: string[];
    declinedRefs?: string[];
    slots?: number;

    constructor(data: IParticipation) {

        this.admin = data.admin;
        this.userRefs = data.userRefs;
        this.reservesRefs = data.reservesRefs;
        this.invitedRefs = data.invitedRefs;
        this.declinedRefs = data.declinedRefs;
        this.slots = data.slots;
    }

    serialize() : IParticipation {
        return {
            admin: this.admin, 
            userRefs: this.userRefs ? this.userRefs.map(u => u.trim()) : undefined, 
            reservesRefs: this.reservesRefs ? this.reservesRefs.map(u => u.trim()) : undefined, 
            invitedRefs: this.invitedRefs ? this.invitedRefs.map(u => u.trim()) : undefined,
            declinedRefs: this.declinedRefs ? this.declinedRefs.map(u => u.trim()) : undefined,
            slots: this.slots
        }
    }

    getAllParties() {
        return arrayUnique([
            ...(this.admin || []), 
            ...(this.userRefs || []), 
            ...(this.reservesRefs || []), 
            ...(this.invitedRefs || []), 
            ...(this.declinedRefs || [])
        ]);
    }

    getRecipients() {
        return arrayUnique([...(this.admin || []), ...(this.userRefs || []), ...(this.reservesRefs || [])]);
    }

    getAdmin() {
        return this.admin;
    }

    invite(newInvitations: string[] | undefined, removedInvitations: string[] | undefined) {
        this.invitedRefs?.push(...(newInvitations || []));
        removedInvitations?.forEach(u => this.uninvite(u));
    }

    uninvite(userRef: string) {
        const index = this.invitedRefs ? this.invitedRefs.indexOf(userRef) : -1;
        if (index >= 0) {
            this.invitedRefs?.splice(index, 1);
        }
    }

    add(userRefs: string | string[]) {
        this.join(userRefs);
    }
    
    _swap(playerA: string, playerB: string): void {

        const arrays = [this.userRefs || [], this.reservesRefs || [], this.invitedRefs || []];

        arrays.forEach(array => {
            const playerAIndex = array.findIndex(player => player === playerA);
            const playerBIndex = array.findIndex(player => player === playerB);

            if (playerAIndex !== -1 && playerBIndex !== -1) {
                // Both players are in the same array
                array[playerAIndex] = playerB;
                array[playerBIndex] = playerA;
            } else if (playerAIndex !== -1) {
                // PlayerA is in the array, PlayerB is not
                array[playerAIndex] = playerB;
            } else if (playerBIndex !== -1) {
                // PlayerB is in the array, PlayerA is not
                array[playerBIndex] = playerA;
            }
        });
    }

    _inject(playerRef: string) {
        if (playerRef && !this.userRefs?.includes(playerRef)) {
            this.userRefs = [...(this.userRefs || []), playerRef];
        }
    }


    join(playerRefs: string | string[]): 'full' | 'notfull' {

        playerRefs = typeof playerRefs === 'string' ? [playerRefs] : playerRefs;
    
        const userRefs = [...(this.userRefs || [])];
        let reservesRefs = [...(this.reservesRefs || [])];
        let invitedRefs = [...(this.invitedRefs || [])];

        playerRefs.forEach(userRef => {
            if (this.slots && userRefs.length >= this.slots) {
                if (!(reservesRefs.find(r => r === userRef))) {
                    reservesRefs.push(userRef);
                }
            }
            else {
                if (!(userRefs.find(r => r === userRef))) {
                    userRefs.push(userRef);
                }
                reservesRefs = arrayRemove(reservesRefs, null, userRef) as string[];
            }
            invitedRefs = arrayRemove(invitedRefs, null, userRef) as string[];
        });

        this.userRefs = userRefs;
        this.reservesRefs = reservesRefs;
        this.invitedRefs = invitedRefs;

        if (this.slots && this.userRefs.length >= this.slots) {
            return 'full';
        }
        return 'notfull';
    }

    leave(userRef: string, partnerRef?: string | null) {

        let userRefs = [...(this.userRefs || [])];
        let reservesRefs = [...(this.reservesRefs || [])];

        if (userRefs.includes(userRef)) {
            if (partnerRef) {
                arrayRemove(userRefs, null, userRef);
                arrayRemove(userRefs, null, partnerRef);
            }
            else {
                arrayRemove(userRefs, null, userRef);
            }
        }
        else if (reservesRefs.includes(userRef)) {
            if (partnerRef) {
                arrayRemove(reservesRefs, null, userRef);
                arrayRemove(reservesRefs, null, partnerRef);
            }
            else {
                arrayRemove(reservesRefs, null, userRef);
            }
        }

        this.userRefs = userRefs;
        this.reservesRefs = reservesRefs;
    }

    decline(userRef?: string) {
        if (!userRef) {
            return;
        }
        arrayRemove(this.invitedRefs, null, userRef);
        this.declinedRefs = this.declinedRefs || [];
        this.declinedRefs.push(userRef);
    }

    makeAdmin(userRef: string) {
        this.admin = arrayUnique([...this.admin, userRef]);
    }

    revokeAdmin(userRef: string) {
        if (this.admin.length > 1) {
            const index = this.admin.indexOf(userRef);
            if (index >= 0) {
                this.admin.splice(index, 1);
            }
        }
    }

    setSlots(slots?: number) {
        
        //Demote players
        if (this.userRefs && slots && this.userRefs.length > slots) {
            const removed = this.userRefs.splice(slots);
            if (removed?.length) {
                this.reservesRefs = this.reservesRefs || [];
                this.reservesRefs.unshift(...removed);
            }
        }
        /*
        //Promote reserves
        else if (promoteReserves && this.userRefs && this.reservesRefs && slots && this.userRefs.length < slots && this.reservesRefs.length) {
            const added = this.reservesRefs.splice(0, slots - this.userRefs.length);
            this.userRefs.push(...added);
        }
        */
        this.slots = slots;
    }

    getPartner(userRef?: string) {

        if (!userRef) {
            return null;
        }

        const index: number = this.userRefs ? this.userRefs.indexOf(userRef) : -1;
        if (index === -1) {
            //Check among reserves if userRef wasn't among users
            const reserveIndex: number = this.reservesRefs ? this.reservesRefs.indexOf(userRef) : -1;
            if (reserveIndex > -1) {
                return reserveIndex % 2 === 0 ? this.reservesRefs?.[reserveIndex + 1] : this.reservesRefs?.[reserveIndex-1];
            }
            return null;
        }
        else return index % 2 === 0 ? this.userRefs?.[index + 1] : this.userRefs?.[index-1];
    }

    isAdmin(userRef?: string) {
        if (userRef && (this.admin?.includes(userRef) || userRef === '+46123123456')) {
            return true;
        }    
        return false;
    }

    adminCount() {
        return this.admin.length;
    }

    isPlayer(userRef?: string) {
        return userRef && this.userRefs?.includes(userRef) ? true : false;
    }

    isGuaranteedPlayer(userRef?: string, slots?: number) {
        return (
            userRef && 
            this.userRefs && 
            this.userRefs.includes(userRef) && (
                (this.userRefs?.indexOf(userRef) < Math.floor((this.userRefs?.length || 0) / 4) * 4) ||
                (slots && this.userRefs?.length === slots) ||
                (slots && slots < 4)
            )
        ) ? true : false;    
    }

    isMember(userRef?: string) {
        return this.isPlayer(userRef);
    }

    isReserve(userRef?: string) {
        return userRef && this.reservesRefs?.includes(userRef) ? true : false;
    }

    isInvited(userRef?: string) {
        return userRef && this.invitedRefs?.includes(userRef) ? true : false;
    }

    isSomething(userRef?: string) {
        return userRef && (this.invitedRefs?.includes(userRef) || this.userRefs?.includes(userRef) || this.reservesRefs?.includes(userRef)) ? true : false;
    }

    hasReserves() {
        return this.reservesRefs?.length ? true : false;
    }

}
