export function createId(length) {
    let result = '';
    //Don't add '-' to the chars list, we use that as a reserved character elsewhere in code (see CardList for example)
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charsLength = chars.length;
    for ( var i = 0; i < length; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
}
