import { Club, Competition, Group, Conversation, SuperSchedule } from 'business';
import { getFirestore, doc, deleteDoc } from "firebase/firestore";

export default async function destroyFromInstance(instance: Club | Competition | Group | Conversation | SuperSchedule) {

    const collectionId = (instance instanceof Competition && "competitions") || (instance instanceof Club && "clubs") || (instance instanceof Group && "groups") || (instance instanceof Conversation && "conversations") || (instance instanceof SuperSchedule && "schedules");
    if (!collectionId) {
        throw "createFromInstance error, bad instance type"
    }

    if (!instance.id) {
        throw "createfromInstance error, bad instance id"
    }

    if (instance instanceof Competition && instance.state.closed) {
        console.log('Warning: destroying a closed competition is not permitted');
        return;
    }

    const documentRef = doc(getFirestore(), `${collectionId}/${instance.id}`);
    return deleteDoc(documentRef);
}
