import { useMemo } from 'react';
import { UserIdentity } from 'business';
import { StringTable } from 'utils';
import { useUsers } from 'bridge';
import { useAppContext } from '../appcontext';


export default function useParticipationTitles(users?: {[userRef: string]: UserIdentity | null | undefined}, fallbackString?: string) {

    const { currentUser, currentUserId } = useAppContext();
    const userCount = Object.values(users || {}).length;

    const titlesString = useMemo(() => Object.values(users || {})
        .filter(u => u && u.id !== currentUser?.id)
        .slice(0, 10)
        .reduce((acc, user, index) => {
            return acc + `${acc ? ', ' : ''}${user?.getTitle().split(' ')?.[0] || ''}`;
        }, 
        currentUserId && users?.[currentUserId] ? StringTable.getString('You') : ''
        ), [currentUserId, users]);

    return userCount ? titlesString : (fallbackString || StringTable.getString('Waiting for players'));
}
