//@ts-nocheck
import React, { useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import { useTheme, Subheader, Pressable } from 'components';

interface Props {
    value?: string | number | null | undefined;
    focused?: boolean;
    onPress?: () => void;
    extraWide?: boolean
}

export default function NumericCell(props: Props) {

    const {
        value, 
        focused,
        onPress,
        extraWide
    } = props;

    const theme = useTheme();
    const style = {
        width: extraWide ? 48 : 32, 
        height: 38, 
        margin: 3,
        borderRadius: 8,
        borderColor: theme["color-background"],
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }

    return onPress ? (
        <Pressable style={style} onPress={onPress}>
            <Subheader>{value !== undefined ? (`${value}`) : ''}</Subheader>
            { focused ? <Caret style={{position: 'absolute', top: 8, left: 10, height: 20, width: 3, backgroundColor: theme["color-ink-soft"]}}/> : null }
        </Pressable>
    ) : (
        <View style={style}>
            <Subheader>{value !== undefined ? (`${value}`) : ''}</Subheader>
            { focused ? <Caret style={{position: 'absolute', top: 8, left: 10, height: 20, width: 3, backgroundColor: theme["color-ink-soft"]}}/> : null }
        </View>
    );
};

function Caret(props : any) {

    const {
        style
    } = props;

    const animatedOpacity = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        let toOn = false;
        const id = setInterval(() => {
            animatedOpacity.setValue(toOn ? 1.0 : 0.0);
            toOn = !toOn;
        }, 600);

        return () => {
            clearInterval(id);
        }
    }, []);
    
    return(
        <Animated.View style={[style, {opacity: animatedOpacity}]}/>
    )
}