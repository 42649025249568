import { useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getDatabase, ref, set } from "firebase/database";
import { destroyFromRef } from 'bridge';

export default function useDeleteUser(logout: () => Promise<void>, setPreventOnboarding: React.Dispatch<React.SetStateAction<boolean>>, userId?: string | null) {
    const deleteUser = useCallback(async() => {
        if (userId && logout) {
            setPreventOnboarding(true);
            await destroyFromRef('users', userId);
            await destroyFromRef('pinCodes', userId);
            await AsyncStorage.removeItem('@user');  
            set(ref(getDatabase(), `haspin/${userId}`), false);
            await logout();
            setPreventOnboarding(false);
        }
    }, [userId, logout]);

    return deleteUser;
}
