import React from 'react';
import { IPressEvent } from 'interfaces';
import { SuperSchedule, Competition } from 'business';
import ConstrainScheduleSheet from './sheets/ConstrainScheduleSheet';

export default async function constrainSchedule(e: IPressEvent, schedule: SuperSchedule, competitions: Competition[], roundIndex: number, pushModal: any, popAllModals: any) {
    if (!schedule) {
        return;
    }
    const result = await pushModal((
        <ConstrainScheduleSheet competitions={competitions} schedule={schedule} style={{flex: 1}} roundIndex={roundIndex}/>
    ), {...e, pageY: 0});

    if (result) {
        //Calling onSetRoundLength to not trigger double saves
        schedule.onSetRoundLength(result.roundLength, roundIndex, result.roundLengthGlobal);
        schedule.setConstraints(roundIndex, { 
            constrainedCompetitions: result.constrainedCompetitions, 
            constrainedGroups: result.constrainedGroups, 
            prioritizedCompetitions: result.prioritizedCompetitions, 
            prioritizedGroups: result.prioritizedGroups, 
            constrainedCourts: result.constrainedCourts, 
            delay: result.delay 
        });
    }        
    popAllModals();
}