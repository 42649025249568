import React, { useState, useEffect } from 'react';
import { View, Pressable } from 'react-native';
import CalendarDigit from './CalendarDigit';
import Overline from '../typography/Overline';
import Divider from '../helpers/Divider';

export interface CalendarTimeProps {
    style?: any,
    hourLabel: string, 
    minuteLabel: string,
    selectedTime?: Date,
    onChangeTime?: (d: Date) => void
}

CalendarTime.defaultProps = {
    hourLabel: 'HOURS',
    minuteLabel: 'MINUTES'
}

/**
 * 
 */
export default function CalendarTime(props: CalendarTimeProps) {

    const {
        style,
        hourLabel,
        minuteLabel,
        selectedTime,
        onChangeTime,
    } = props;

    const hours = [
        [ 0,  1,  2,  3,  4,  5,  6 ],
        [ 7,  8,  9, 10, 11, 12, 13 ],
        [14, 15, 16, 17, 18, 19, 20 ],
        [21, 22, 23, undefined, undefined, undefined, undefined]
    ];

    const minutes = [0, 15, 30, 45, undefined, undefined, undefined];

    const [hour, setHour] = useState(selectedTime?.getHours());
    const [minute, setMinute] = useState(selectedTime && minutes[Math.floor(selectedTime.getMinutes() / 15)]);

    useEffect(() => {
        if ((hour !== selectedTime?.getHours()) || (minute !== selectedTime?.getMinutes())) {
            onChangeTime && onChangeTime(new Date(1970,0,1, hour, minute))
        }
    }, [hour, minute, selectedTime]);

    return(
        <View style={style}>
            <Overline>{hourLabel}</Overline>
            <Divider style={{marginBottom: 6}}/>
            {hours.map((hs: (number | undefined)[], index: number) => (
                <View key={index} style={{flexDirection: 'row'}}>
                    { hs.map((h: number | undefined, index: number) => h !== undefined ? (
                        <Pressable style={{flex: 1}} key={index} onPress={() => setHour(h)}>
                            <CalendarDigit compact={true} value={h} selected={h === hour} pad={true}/>
                        </Pressable>
                    ) : (
                        <View key={index} style={{flex: 1}}/>
                    ))}
                </View>
            ))}
            <View style={{marginTop: 14}}>
                <Overline>{minuteLabel}</Overline>
                <Divider style={{marginBottom: 6}}/>
                <View style={{flexDirection: 'row'}}>
                    { minutes.map((m: number | undefined, index: number) => m !== undefined ? (
                        <Pressable style={{flex: 1}} key={index} onPress={() => setMinute(m)}>
                            <CalendarDigit compact={true} value={m || '00'} selected={m === minute}/>
                        </Pressable>
                    ) : (
                        <View key={index} style={{flex: 1}}/>
                    ))}
                </View>
            </View>
        </View>
    )
}
