import { getFirestore, query, collection, where, limit, getDocs, QueryConstraint } from "firebase/firestore";
import { getDatabase, ref, set, increment } from "firebase/database";
import { Group, Club, Competition, Conversation, User, SuperSchedule } from 'business';
import parse from './parse';
import save from './save';

export default async function adminGetRecentlyChanged(fbApp: any, collectionId: string, changedAfter: Date ) {

    const _classes = {
        "groups": Group,
        "clubs": Club,
        "competitions": Competition,
        "conversations": Conversation,
        "users": User,
        "schedules": SuperSchedule
    }
    const _class = _classes[collectionId as keyof typeof _classes];
    if (!_class) {
        return undefined;
    }

    const qparams : QueryConstraint[] = changedAfter ? [ where('meta.lastModifiedDate', '>=', changedAfter) ] : [];
    let q = query(
        collection(getFirestore(fbApp), collectionId), 
        ...qparams,
        limit(1000)
    );

    const snap = await getDocs(q);
    set(ref(getDatabase(), `analytics/reads/adminGetRecentlyChanged`), increment(snap.size || 1));

    const instances = [];

    for (const doc of snap.docs) {
        try {
            if (_class && doc.exists()) {
                instances.push(new _class(parse(collectionId, doc), save));                
            }
        } catch(err) {
            console.log('adminGetRecentlyChanged', err);
        }
    }
    return instances;
}