import React from 'react';
import { User } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import ContactsListSheet from './sheets/ContactsSheet/ContactsListSheet';
import { validatePhoneNumber } from 'tools';
import { createProvisionalUsers } from 'bridge';

export default async function addInvitationsToTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser: User) {
    if (target?.competition) {
        const { participation } = target?.competition;

        //@ts-ignore
        const result : ContactData[] | null = await pushModal((
            <ContactsListSheet
                style={{flex: 1}}
                headlineLabel={StringTable.getString('Invite players?')} 
                doneLabel={StringTable.getString('Invite')}
                filterContacts={[...(participation?.userRefs || []), ...(participation?.reservesRefs || []), ...(participation?.invitedRefs || [])]} 
                filterMessage={StringTable.getString('Already invited')}
            />
        ), {...e, pageY: 0});

        if (result?.length) {
            const provisionalUserCandidates = result.map((contact: ContactData) => ({ ...contact, region: target.competition?.getRegion() || target.group?.getRegion() }))
            createProvisionalUsers(provisionalUserCandidates);
            
            target.competition.invite(result.filter(contact => validatePhoneNumber(contact.id)).map(contact => contact.id));
        }
    }
    popAllModals();
}
