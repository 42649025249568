import React from 'react';
import { User } from 'business';
import { IPressEvent, NotificationImportance, NotificationTransaction } from 'interfaces';
import { StringTable } from 'utils';
import ContactsListSheet from './sheets/ContactsSheet/ContactsListSheet';
import { createProvisionalUsers, sendNotificationFromClient } from 'bridge';

export default async function addPlayersToTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser: User) {
    if (target?.group || target?.competition) {
        const { participation } = target?.competition || target?.group;

        //@ts-ignore
        const result : ContactData[] | null = await pushModal((
            <ContactsListSheet
                style={{flex: 1}}
                headlineLabel={StringTable.getString(target?.competition ? 'Add players?' : 'Add members?')} 
                doneLabel={StringTable.getString('Add')}
                filterContacts={[...(participation?.userRefs || []), ...(participation?.reservesRefs || [])]} 
                extraContacts={target?.competition && target?.group ? target.group.participation?.userRefs : undefined}
                filterMessage={StringTable.getString(target?.competition ? 'Already playing' : 'Already member')}
                showVacancy={(target?.competition?.settings.teamSignup && !target?.competition?.settings.teamSignupProhibitVacancies) ? true : false}
                requireEvenSelected={target?.competition?.settings.teamSignup}
                requireEvenSelectedMessage={target?.competition?.settings.teamSignup ? StringTable.getString('Cannot add odd number of players to event with team signup') : ''}
            />
        ), {...e, pageY: 0});

        if (result?.length) {
            const provisionalUserCandidates = result.map((contact: ContactData) => ({ ...contact, region: target.competition?.getRegion() || target.group?.getRegion() }))
            createProvisionalUsers(provisionalUserCandidates);

            const notificationTransaction : NotificationTransaction = {
                recipients: result.map(contact => contact.id),
                host: `https://padel.id/`,
                title: target.competition?.getTitle() || target.group?.getTitle() || '',
                link: target.competition ? `home/${target.competition.id}` : `groups/${target.group?.id}`,
                message: `${currentUser?.getTitle()} {added you to} ${target.competition?.getTitle() || target.group?.getTitle()}`,
                importance: NotificationImportance.MEDIUM
            }

            sendNotificationFromClient(notificationTransaction);

            if (target.competition) {
                target.competition.add(result.map(contact => contact.id));
            }
            else if (target.group) {
                target.group.join(result.map(contact => contact.id));
            }
        }
    }
    popAllModals();
};
