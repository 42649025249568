import React, { useState } from 'react';
import { View, Text, TouchableOpacity, ScrollView, StyleSheet } from 'react-native';
import { initializeApp } from "firebase/app";
import { Headline } from 'components';
import UsageGraphs from './UsageGraphs';
import ErrorList from './ErrorsList';
import EventsList from './EventsList';
import GroupsList from './GroupsList';
import ClubsList from './ClubsList';
import UsersList from './UsersList';
import DocumentsView from './DocumentsView';
import StringsList from './StringsList';
import RegionsList from './RegionsList';

const prodAppPadelId = initializeApp({
    apiKey: "AIzaSyDOYdJ73FP-p0KOO5dFHWYEEchKyKtU_LY",
    authDomain: "padel-id.firebaseapp.com",
    projectId: "padel-id",
    storageBucket: "padel-id.appspot.com",
    messagingSenderId: "989899674812",
    appId: "1:989899674812:web:73d7bcba4327ddbfacecb9",
    databaseURL: "https://padel-id-default-rtdb.europe-west1.firebasedatabase.app"
}, 'prodAppPadelId');

const stagingAppPadelId = initializeApp({
    apiKey: "AIzaSyDYEXRwoOsMLHIBDaiEVRnVmNlikASObFU",
    authDomain: "padel-id-staging.firebaseapp.com",
    projectId: "padel-id-staging",
    storageBucket: "padel-id-staging.appspot.com",
    messagingSenderId: "1072244716078",
    appId: "1:1072244716078:web:fec0e5ce2183b64a51e5bf",
    databaseURL: "https://padel-id-staging-default-rtdb.europe-west1.firebasedatabase.app"
}, 'stagingAppPadelId');

const devAppPrankDev = initializeApp({
    apiKey: "AIzaSyAiahfVHg67QdsTc_VAz1BUqFTNaGrj0Hg",
    authDomain: "prank-dev-150de.firebaseapp.com",
    projectId: "prank-dev-150de",
    storageBucket: "prank-dev-150de.appspot.com",
    messagingSenderId: "703394450763",
    appId: "1:703394450763:web:23f9687f10acbee5d32cce", 
    databaseURL: "https://prank-dev-150de-default-rtdb.europe-west1.firebasedatabase.app"
}, 'devAppPrankDev');

const backupAppPadelIdBackup = initializeApp({
    apiKey: "AIzaSyDkMgL-orFaFFa7kfQYIB8m6V0fYLJFOf4",
    authDomain: "padel-id-backup.firebaseapp.com",
    projectId: "padel-id-backup",
    storageBucket: "padel-id-backup.appspot.com",
    messagingSenderId: "137521036118",
    appId: "1:137521036118:web:d6e6c91ed9ca18059974f7"
}, 'backupAppPadelIdBackup')


const AdminDashboard: React.FC = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('Usage');

  const handleMenuItemPress = (item: string) => {
    setSelectedMenuItem(item);
  };

    return (
        <View style={styles.container}>
            
            <View style={styles.menu}>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Usage')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Usage' ? 'bold' : 'normal'}}>Usage</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Events')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Events' ? 'bold' : 'normal'}}>Events</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Groups')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Groups' ? 'bold' : 'normal'}}>Groups</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Clubs')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Clubs' ? 'bold' : 'normal'}}>Clubs</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Users')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Users' ? 'bold' : 'normal'}}>Users</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Regions')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Regions' ? 'bold' : 'normal'}}>Regions</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Strings')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Strings' ? 'bold' : 'normal'}}>Strings</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Errors')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Errors' ? 'bold' : 'normal'}}>Errors</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.menuItem} onPress={() => handleMenuItemPress('Documents')}>
                    <Text style={{fontWeight: selectedMenuItem === 'Documents' ? 'bold' : 'normal'}}>Documents</Text>
                </TouchableOpacity>
            </View>

            <View style={styles.content}>
                { selectedMenuItem === 'Usage' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Usage statistics</Headline>
                        <UsageGraphs/>
                    </>
                ) : null }
                { selectedMenuItem === 'Events' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Events</Headline>
                        <EventsList devApp={devAppPrankDev} style={{alignSelf: 'center'}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Groups' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Groups</Headline>
                        <GroupsList devApp={devAppPrankDev} style={{alignSelf: 'center'}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Users' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Users</Headline>
                        <UsersList devApp={devAppPrankDev} style={{alignSelf: 'center'}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Clubs' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Clubs</Headline>
                        <ClubsList devApp={devAppPrankDev} style={{alignSelf: 'center'}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Regions' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Regions</Headline>
                        <RegionsList style={{marginHorizontal: 20}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Strings' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Strings</Headline>
                        <StringsList style={{alignSelf: 'center'}}/>
                    </>
                ) : null }
                { selectedMenuItem === 'Errors' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Errors</Headline>
                        <ErrorList/>
                    </>
                ) : null }
                { selectedMenuItem === 'Documents' ? (
                    <>
                        <Headline style={{textAlign: 'center'}}>Docs</Headline>
                        <DocumentsView 
                            devApp={devAppPrankDev} 
                            stagingApp={stagingAppPadelId} 
                            prodApp={prodAppPadelId}
                            backupApp={backupAppPadelIdBackup}
                        />
                    </>
                ) : null }
            </View>

        </View>              
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
    marginBottom: 5
  },
  content: {
    flex: 1,
    padding: 20,
    marginLeft: 50,
  },
  menu: {
    width: 250,
    backgroundColor: 'lightgray',
    padding: 20
  },
  menuButton: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'lightgray',
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0
  },
  menuItem: {
    marginVertical: 10
  }
});

export default AdminDashboard;
