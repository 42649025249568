import React, { useState, useEffect } from 'react';
import { View, FlatList, ActivityIndicator } from 'react-native';
import { useTheme, Button, IconButton, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { useUsers } from '../../hooks';
import { IPressEvent } from 'interfaces';
import { arraySwapByIndex } from 'tools';
import TeamCard from '../../composites/TeamCard';
import UserCard from '../../composites/UserCard';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';

interface EditTeamsSheetProps {
    title?: string,
    userRefs: string[], 
    fixedTeams?: boolean,
    teamSignup?: boolean,
    resolve?: (data: IEditTeamsSheetResult | null) => void,
    groupCount?: number,
    groupSizes: number[],
    style?: any
}

EditTeamsSheet.defaultProps = {
    groupCount: 1
}

export default function EditTeamsSheet(props: EditTeamsSheetProps) {
    const {
        title,
        userRefs, 
        fixedTeams,
        teamSignup,
        resolve,
        groupCount,
        groupSizes,
        style
    } = props;

    const users = useUsers(userRefs, true);
    const theme = useTheme();

    const groupHeaderAtItemIndex = groupSizes?.length > 1 && groupSizes.reduce((acc, curr, index) => {
        acc.push((acc[index] + curr));
        return acc;
    }, [0]);

    const [shapedUserRefs, setShapedUserRefs] = useState<(string | string[])[]>();
    useEffect(() => {
        let data : Array<string | string[]> = [];
        if (teamSignup && userRefs) {
            for (var team=0; team < Math.floor(userRefs.length / 2); team += 1) {
                const el = [userRefs[team*2 + 0], userRefs[team*2+1]];
                data.push(el);
            }                        
        }
        else if (userRefs) {
            data = userRefs;
        }
        setShapedUserRefs(data);
    }, [userRefs, teamSignup])

    const [swapIndex, setSwapIndex] = useState<number>(-1);
    function onPressSwap(index: number) {
        if (swapIndex >= 0) {
            if (swapIndex === index) {
                setSwapIndex(-1);
            }
            else {
                setShapedUserRefs(prev => {
                    if (prev) {
                        prev && arraySwapByIndex(prev, swapIndex, index);
                        return [...prev];    
                    }
                });
                setSwapIndex(-1);
            }
        }
        else {
            setSwapIndex(index);
        }
    }

    const sheetStyle = useSheetStyle();

    return users ? (
        <View style={[sheetStyle, style]}>
            <CloseHeader title={StringTable.getString((groupCount || 0) > 1 ? 'Edit groups' : 'Edit teams')} onDismiss={() => resolve?.(null)}/>
            <View style={{flex: 1}}>
                {shapedUserRefs && groupCount ? (
                    <FlatList
                        data={shapedUserRefs}
                        showsVerticalScrollIndicator={false}
                        keyExtractor={item => Array.isArray(item) ? item[0] : item}
                        renderItem={({item, index}) => (
                            <View>
                                { groupCount > 1 && groupHeaderAtItemIndex && (groupHeaderAtItemIndex.indexOf(index * (teamSignup ? 2 : 1)) !== -1) ? (
                                    <Subheader style={{marginTop: theme["spacing-medium"], paddingLeft: theme["spacing-medium"]}}>{`${StringTable.getString('Group')} ${groupHeaderAtItemIndex.indexOf(index * (teamSignup ? 2 : 1)) + 1}`}</Subheader>
                                ) : null }
                                
                                <View style={{flexDirection: 'row', alignItems: 'center', marginTop: (fixedTeams && !teamSignup && index % 2 === 1) ? 0 : theme["spacing-extrasmall"], marginHorizontal: theme["spacing-medium"], backgroundColor: theme["color-card"], padding: theme["spacing-small"]}}>
                                    <View style={{flex: 1}}>
                                        { Array.isArray(item) ? (                        
                                            <TeamCard userRefs={item} verbose={true}/>
                                        ) : (
                                            <UserCard userRef={item} verbose={true}/>
                                        )}
                                    </View>
                                    <IconButton  
                                        name={'ico_updown'} 
                                        onPress={() => onPressSwap(index)}
                                        mode={swapIndex === index ? 'active' : ( swapIndex >= 0 ? 'waiting' : '')}
                                    />
                                </View>
                            </View>
                        )}
                    />
                ) : null }
            </View>

            <Section style={{flexDirection: 'row', padding: theme["spacing-medium"]}}>
                <Button 
                    label={StringTable.getString('Cancel')}
                    onPress={() => resolve && resolve(null)}
                    mode={'outlined'}
                    style={{flex: 1, marginRight: theme["spacing-small"]}}
                />
                <Button 
                    label={StringTable.getString('Set teams')} 
                    onPress={(e: IPressEvent) => {
                        const orderedUserRefs = shapedUserRefs?.reduce((orderedUserRefs : string[], curr: string | string[]) => {
                            if (Array.isArray(curr)) {
                                orderedUserRefs.push(...curr);
                            }
                            else {
                                orderedUserRefs.push(curr);
                            }
                            return orderedUserRefs;
                        }, []);

                        resolve && orderedUserRefs && resolve({
                            orderedUserRefs,
                            e
                        });
                    }} 
                    mode={'primary'}
                    style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}}
                />
            </Section>            
        </View>
    ) : (
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <ActivityIndicator color={theme["color-ink"]}/>
        </View>
    )
}
