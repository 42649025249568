import React from 'react';
import { View } from 'react-native';
import { useTheme, Section, Subheader, Button } from 'components';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';

interface MessageSheetProps {
    title?: string,
    closeHeader?: boolean,
    children?: any,
    showConfirmButton?: boolean,
    confirmButtonLabel?: string,
    confirmButtonMode?: string,
    style?: any,
    resolve?: (data: null ) => void
}

export default function MessageSheet(props: MessageSheetProps) {

    const {
        title,
        closeHeader,
        resolve,
        children,
        showConfirmButton,
        confirmButtonLabel,
        confirmButtonMode,
        style
    } = props;

    const sheetStyle = useSheetStyle();
    
    return (
        <View style={[sheetStyle, style]}>
            {closeHeader ? (
                <CloseHeader title={title} onDismiss={() => resolve?.(null)}/>
            ) : (title ? <Subheader style={{alignSelf: 'center'}}>{title}</Subheader> : null )
            }

            { children }

            <Section style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                { showConfirmButton ? <Button icon={'ico_check'} label={confirmButtonLabel || 'OK'} mode={confirmButtonMode || 'primary'} onPress={() => resolve?.(null)}/> : null }
            </Section>
        </View>
    )
}
