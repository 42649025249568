import { useState, useEffect, useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Device from 'expo-device';

export default function useSimpleRendering() {

    const [simpleRendering, setSimpleRendering] = useState(false);

    const writeSimpleRendering = useCallback((flag: boolean) => {
        AsyncStorage.setItem('simpleRendering', JSON.stringify(flag));
        setSimpleRendering(flag);
    }, []);

    useEffect(() => {
        async function initializeSimpleRendering() {
            const value = await AsyncStorage.getItem('simpleRendering');

            if (value === undefined && Device.isDevice && Device.deviceYearClass) {
                // Check if the device is relatively new (e.g., released in the last 5 years)
                const currentYear = new Date().getFullYear();
                if (currentYear - Device.deviceYearClass <= 5) {
                    setSimpleRendering(true);
                }
            }
            else {
                const flag = value ? JSON.parse(value) : false;
                setSimpleRendering(flag);    
            }
        }
        initializeSimpleRendering();
    }, []);

    return [simpleRendering, writeSimpleRendering];
}
