import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, ScrollView } from 'react-native';
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useTheme, KeyboardDismissingView, Popup, Button, SectionHeader, Section, Card, IconButton, TextInput, TextInputNumeric, CheckBox, ImageUploadButton } from 'components';
import { StringTable } from 'utils';
import CloseHeader from '../CloseHeader';
import { arrayUnique, capitalize, createId } from 'tools';
import { useAppContext } from '../../../appcontext';
import useSheetStyle from '../useSheetStyle';
import ClubSheet from '../ClubSheet';
import { IPressEvent } from 'interfaces';
import EventTypeSheet from './EventTypeSheet';
import { useClubs } from '../../../hooks';
import CourtNameInput from './CourtNameInput';
import EventDateInput from './EventDateInput';
import useModal from '../../useModal';
import { ICompetitionSettings } from 'interfaces';

const stages = ['Final', 'Semifinals', 'Quarterfinals', 'Round of 8', 'Round of 16', 'Round of 32', 'Round of 64'];

const typeDefaults = {
    "MATCH": {
        groupCount: 1,
        slots: 4,
        fixedTeams: false,
        teamSignup: false,
        sortByRanking: false
    },
    "AMERICANO": {
        groupCount: 1,
        slots: 12,
        fixedTeams: false,
        teamSignup: false,
        sortByRanking: false
    },
    "TEAM AMERICANO": {
        groupCount: 1,
        slots: 12,
        fixedTeams: true,
        teamSignup: true,
        sortByRanking: false
    },
    "WINNERS COURT": {
        groupCount: 1,
        slots: 12,
        fixedTeams: false,
        teamSignup: false,
        sortByRanking: true
    },
    "TEAM WINNERS COURT": {
        groupCount: 1,
        slots: 12,
        fixedTeams: true,
        teamSignup: true,
        sortByRanking: false
    },
    "TRAINING": {
        groupCount: 1,
        slots: 3,
        fixedTeams: false,
        teamSignup: false,
        sortByRanking: false
    },
    "TOURNAMENT": {
        groupCount: 2,
        slots: 16,
        fixedTeams: true,
        teamSignup: true,
        sortByRanking: false,
        playoffStages: 'Semifinals'
    },
    "BIG TOURNAMENT": {
        fixedTeams: true,
        teamSignup: true,
        sortByRanking: false,
        slots: 0
    }
}

interface EventSheetProps {
    title: string,
    doneLabel: string,
    resolve?: (data: IEventSheetResult | null) => void,
    initialSettings: ICompetitionSettings,
    //Note that style SHOULD be required, it's not a typo. Modals need to read flex from style
    style: any,
    isScheduled?: boolean
}

EventSheet.defaultProps = {
    doneLabel: StringTable.getString('Create'),
    title: StringTable.getString('Create event')
}
export default function EventSheet(props: EventSheetProps) {

    const {
        title,
        doneLabel,
        initialSettings,
        resolve,
        style: styleProp,
        isScheduled
    } = props;

    const theme = useTheme();
    const { currentUser, regions } = useAppContext();

    const remoteClubRefs = useMemo(() => {
        return arrayUnique([
            ...(currentUser?.settings?.clubRefs || []),
            ...(initialSettings?.clubRef ? [initialSettings.clubRef] : [])
        ]);
    }, [currentUser?.settings?.clubRefs, initialSettings?.clubRef]);

    const clubs = useClubs(currentUser?.settings?.region, remoteClubRefs);

    const isSubCompetition = initialSettings.parentCompetitionRef ? true : false;

    const userId = currentUser?.id;

    const [uploadingImage, setUploadingImage] = useState(false);
    const [competitionSettings, setCompetitionSettings] = useState<ICompetitionSettings>({ ...(initialSettings.type ? typeDefaults[initialSettings.type as keyof typeof typeDefaults] : {}), ...initialSettings });

    //Apply type defaults
    const initialRender = useRef(true);
    useEffect(() => {
        if (!initialRender.current) {
            //@ts-ignore
            setCompetitionSettings(prev => ({
                ...prev,
                ...typeDefaults[competitionSettings.type],
                slots: ['MATCH', 'TRAINING', 'BIG TOURNAMENT'].includes(competitionSettings.type) ? typeDefaults[competitionSettings.type].slots : Math.max(prev.slots || 4, typeDefaults[competitionSettings.type].slots || 4),
                points: regions?.find(r => r.title === currentUser?.settings.region)?.points && ['AMERICANO', 'TEAM AMERICANO'].includes(competitionSettings.type) ? true : prev.points
            }));
        }
        initialRender.current = false;
    }, [competitionSettings.type, currentUser?.settings.region, regions]);

    //Set default description to type
    useEffect(() => {
        setCompetitionSettings(prev => {
            if (prev.description && !Object.keys(typeDefaults).includes(capitalize(prev.description, true))) {
                return prev;
            }
            return {
                ...prev,
                description: capitalize(competitionSettings.type)
            }
        });
    }, [competitionSettings.type]);

    useEffect(() => {
        if (competitionSettings.playoffStages && competitionSettings.playoffStages !== 'Numeric') {
            setCompetitionSettings(prev => ({
                ...prev,
                playoffSize: Math.pow(2, stages.indexOf(competitionSettings.playoffStages || 'Semifinals') + 1)
            }))
        }
    }, [competitionSettings.playoffStages]);

    useEffect(() => {
        if (competitionSettings.slots && !competitionSettings.courtsEdited) {
            const courtCount = Math.max(1, Math.floor(competitionSettings.slots / 4));

            setCompetitionSettings(prev => ({
                ...prev,
                courts: new Array(courtCount || 1).fill('a').reduce((acc, curr, index) => ({
                    ...acc,
                    [index]: {
                        courtId: prev.courts?.[index]?.courtId || index,
                        courtName: prev.courts?.[index]?.courtName || `Court ${index + 1}`
                    }
                }), {})
            }));
        }
    }, [competitionSettings.slots, competitionSettings.courtsEdited]);

    const { pushModal, popModal } = useModal();
    const [popup, setPopup] = useState('');

    const style = {
        left: { flex: 1, marginRight: theme["spacing-extrasmall"] },
        right: { flex: 1, marginLeft: theme["spacing-extrasmall"] },
        overline: {
            marginTop: theme["spacing-medium"],
            marginBottom: theme["spacing-small"]
        }
    }

    async function onPressType(e: IPressEvent) {
        const type = await pushModal((
            <EventTypeSheet
                type={competitionSettings.type}
                style={{ flex: 1 }}
                isSubCompetition={isSubCompetition}
                isScheduled={isScheduled}
            />
        ), { ...e, pageY: 0 });

        if (type) {
            setCompetitionSettings(prev => ({ ...prev, type: type }));
        }
        popModal();
    }

    async function onPressWhere(e: IPressEvent) {
        const clubSelectionResult: IClubSheetResult | null = await pushModal((
            <ClubSheet selectedClub={competitionSettings?.clubRef ? clubs?.find(c => c.id === competitionSettings?.clubRef) : undefined} style={{ flex: 1 }} />
        ), { ...e, pageY: 0 });

        if (clubSelectionResult) {
            clubSelectionResult.club && currentUser?.followClub(clubSelectionResult.club.id);
            setCompetitionSettings(prev => ({
                ...prev,
                clubRef: clubSelectionResult.club?.id,
                location: clubSelectionResult.club?.settings.location
            }));
        }
        popModal();
    }

    const sheetStyle = useSheetStyle();

    return (
        <KeyboardDismissingView style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={title || StringTable.getString('Create event')} />
            <View style={{ flex: 1 }}>
                <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }} contentContainerStyle={{ paddingBottom: 340 }}>
                    {/* WHAT */}
                    <Section>
                        <SectionHeader icon={'ico_court'} label={StringTable.getString('WHAT?')} />
                        <Section size={'M'}>
                            <Button
                                icon={'ico_court'}
                                label={capitalize(StringTable.getString(competitionSettings.type))}
                                onPress={onPressType}
                            />
                        </Section>
                    </Section>

                    {/* WHEN */}
                    {isSubCompetition ? null : (
                        <EventDateInput competitionSettings={competitionSettings} setCompetitionSettings={setCompetitionSettings} />
                    )}

                    <View style={{ flexDirection: 'row' }}>
                        {/* WHERE */}
                        <Section style={style.left}>
                            <SectionHeader icon={'ico_location'} label={StringTable.getString('WHERE?')} />
                            <Section size={'M'}>
                                <Button
                                    icon={'ico_location'}
                                    label={competitionSettings?.clubRef ? clubs?.find(c => c.id === competitionSettings.clubRef)?.settings.getTitle() : StringTable.getString('Set club')}
                                    onPress={onPressWhere}
                                    mode={'contained'}
                                />
                            </Section>
                        </Section>

                        {['MATCH', 'BIG TOURNAMENT'].includes(competitionSettings.type) ? null : (
                            <Section style={style.right}>
                                <SectionHeader icon={'ico_person'} label={StringTable.getString('PLAYERS?')} />
                                <Section size={'M'}>
                                    <TextInputNumeric
                                        value={competitionSettings?.slots || 0}
                                        iconLeft={'ico_hash'}
                                        onEndEditing={(s: number) => {
                                            setCompetitionSettings(prev => ({
                                                ...prev,
                                                slots: competitionSettings.type === 'AMERICANO' ? Math.min(28, s) : s
                                            }));
                                        }}
                                    />
                                </Section>
                            </Section>
                        )}
                    </View>

                    {/* COURTS */}
                    {(["MATCH", "TRAINING"].includes(competitionSettings.type) || isSubCompetition) ? null : (
                        <Section>
                            <SectionHeader icon={'ico_court'} label={StringTable.getString('COURTS')} />
                            <Section size={'M'}>
                                <TextInputNumeric
                                    value={Object.keys(competitionSettings.courts || {}).length || 1}
                                    iconLeft={'ico_draghandle'}
                                    onEndEditing={(courtCount: number) => {
                                        setCompetitionSettings(prev => ({
                                            ...prev,
                                            courts: new Array(courtCount || 1).fill('a').reduce((acc, curr, index) => ({
                                                ...acc,
                                                [index]: {
                                                    courtId: prev.courts?.[index]?.courtId || index,
                                                    courtName: prev.courts?.[index]?.courtName || `Court ${index + 1}`
                                                }
                                            }), {}), 
                                            courtsEdited: true
                                        }));
                                    }}
                                />
                            </Section>
                            {Object.keys(competitionSettings.courts || {}).length > 1 ? (
                                <Section>
                                    <CourtNameInput competitionSettings={competitionSettings} setCompetitionSettings={setCompetitionSettings} />
                                </Section>
                            ) : null}
                        </Section>
                    )}

                    {competitionSettings.type === 'MATCH' ? null : (
                        <>
                            {/* TITLE */}
                            <Section>
                                <SectionHeader icon={'ico_title'} label={StringTable.getString('TITLE?')} />
                                <Section size={'M'}>
                                    <TextInput
                                        value={competitionSettings?.title || ''}
                                        onChangeText={(t: string) => setCompetitionSettings(prev => ({ ...prev, title: t }))}
                                        iconLeft={'ico_title'}
                                    />
                                </Section>
                            </Section>

                            {/* TOURNAMENT CATEGORIES */}
                            {competitionSettings.type === "TOURNAMENT" ? (
                                <Section>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Section style={style.left}>
                                            <SectionHeader icon={'ico_groupround'} label={StringTable.getString('GROUPS?')} />
                                            <Section size={'M'}>
                                                <TextInputNumeric
                                                    value={competitionSettings?.groupCount || 1}
                                                    iconLeft={'ico_hash'}
                                                    placeholder={StringTable.getString('Total #')}
                                                    onEndEditing={(p: number) => setCompetitionSettings(prev => ({ ...prev, groupCount: p }))}
                                                />
                                            </Section>
                                        </Section>

                                        <Section style={style.right}>
                                            <SectionHeader icon={'ico_hash'} label={StringTable.getString('PLAYOFF SIZE')} />
                                            <Section size={'M'}>
                                                {competitionSettings.playoffStages === 'Numeric' ? (
                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                        <TextInputNumeric
                                                            value={competitionSettings?.playoffSize}
                                                            iconLeft={'ico_hash'}
                                                            onEndEditing={(p: number) => setCompetitionSettings(prev => ({ ...prev, playoffSize: p }))}
                                                            style={{ flex: 1, marginRight: theme["spacing-small"] }}
                                                        />
                                                        <IconButton name={'ico_chevrondown'} onPress={() => setPopup('playoff')} />
                                                    </View>
                                                ) : (
                                                    <Button
                                                        icon={'ico_hash'}
                                                        label={competitionSettings.playoffStages}
                                                        onPress={() => setPopup('playoff')}
                                                        mode={'contained'}
                                                    />
                                                )}
                                            </Section>
                                        </Section>

                                        <Popup visible={popup === 'playoff'} onDismiss={() => setPopup('')}>
                                            <Card>
                                                <ScrollView style={{ height: 190 }} showsVerticalScrollIndicator={false}>
                                                    <Button
                                                        icon={'ico_hash'}
                                                        label={StringTable.getString('Enter by number')}
                                                        onPress={() => {
                                                            //@ts-ignore
                                                            setCompetitionSettings(prev => ({ ...prev, playoffStages: 'Numeric' }));
                                                            setPopup('');
                                                        }}
                                                        mode={'contained'}
                                                        style={{ margin: theme['spacing-medium'] }}
                                                    />
                                                    {stages.map(stage => (
                                                        <Button
                                                            key={stage}
                                                            icon={'ico_hash'}
                                                            label={StringTable.getString(stage)}
                                                            onPress={() => {
                                                                //@ts-ignore
                                                                setCompetitionSettings(prev => ({ ...prev, playoffStages: stage }));
                                                                setPopup('');
                                                            }}
                                                            mode={'contained'}
                                                            style={{ margin: theme['spacing-medium'] }}
                                                        />
                                                    ))}
                                                </ScrollView>
                                            </Card>
                                        </Popup>
                                    </View>
                                </Section>
                            ) : null}

                            {/* BRANDING */}
                            <Section>
                                <View>
                                    <SectionHeader icon={'ico_document'} label={StringTable.getString('DESCRIPTION')} />
                                    <Section size={'M'}>
                                        <TextInput
                                            value={competitionSettings?.description || ''}
                                            onChangeText={(d: string) => setCompetitionSettings(prev => ({ ...prev, description: d }))}
                                            iconLeft={'ico_document'}
                                            multiline={true}
                                            numberOfLines={4}
                                        />
                                    </Section>
                                    <Section>
                                        <SectionHeader icon={'ico_addimage'} label={StringTable.getString('EVENT PICTURE')} />
                                        <Section size={'M'}>
                                            <ImageUploadButton
                                                imageUrl={competitionSettings?.imageUrl}
                                                uploading={uploadingImage}
                                                maxImageSize={500}
                                                upload={async (blob: any) => {
                                                    if (userId) {
                                                        const metaData = {
                                                            cacheControl: 'public,max-age=31536000'
                                                        }
                                                        const t1 = new Date();
                                                        console.log(`[${0}ms]: Start image upload`)
                                                        setUploadingImage(true);
                                                        const storage = getStorage();
                                                        const storageRef = ref(storage, `uploads/${userId}/${createId(7)}.jpg`);
                                                        const uploadResult = await uploadBytes(storageRef, blob, metaData);
                                                        const downloadURL = await getDownloadURL(uploadResult.ref);
                                                        setCompetitionSettings(prev => ({ ...prev, imageUrl: downloadURL }));
                                                        setUploadingImage(false);
                                                        const t2 = new Date();
                                                        console.log(`[${t2.getTime() - t1.getTime()}ms]: Finish image upload`);
                                                    }
                                                }}
                                            />
                                        </Section>
                                    </Section>

                                    {/* ADVANCED */}
                                    {competitionSettings.type && !["MATCH", "TRAINING"].includes(competitionSettings.type) ? (
                                        <Section>
                                            <SectionHeader icon={'ico_groupround'} label={StringTable.getString('ADVANCED')} />
                                            {competitionSettings.type && !["AMERICANO", "WINNERS COURT"].includes(competitionSettings.type) ? (
                                                <Section size={'M'}>
                                                    <CheckBox
                                                        checked={competitionSettings?.teamSignup}
                                                        label={StringTable.getString('Sign up as a team')}
                                                        onPress={() => setCompetitionSettings(prev => ({ ...prev, teamSignup: prev?.teamSignup ? false : true }))}
                                                        size={'L'}
                                                    />
                                                </Section>
                                            ) : null}

                                            { competitionSettings?.teamSignup ? (
                                                <Section size={'M'}>
                                                    <CheckBox
                                                        checked={!competitionSettings?.teamSignupProhibitVacancies}
                                                        label={StringTable.getString('Allow signup with vacant partner')}
                                                        onPress={() => setCompetitionSettings(prev => ({ ...prev, teamSignupProhibitVacancies: prev?.teamSignupProhibitVacancies ? false : true }))}
                                                        size={'L'}
                                                    />
                                                </Section>
                                            ) : null }
                                            {competitionSettings.type && ["TEAM WINNERS COURT", "WINNERS COURT"].includes(competitionSettings.type) && !isScheduled ? (
                                                <Section size={'M'}>
                                                    <CheckBox
                                                        checked={competitionSettings?.sortByRanking}
                                                        label={StringTable.getString('First round by ranking')}
                                                        onPress={() => setCompetitionSettings(prev => ({ ...prev, sortByRanking: prev?.sortByRanking ? false : true }))}
                                                        size={'L'}
                                                    />
                                                </Section>
                                            ) : null}
                                            <Section size={'M'}>
                                                <CheckBox
                                                    checked={competitionSettings?.points}
                                                    label={StringTable.getString('Points instead of games')}
                                                    onPress={() => setCompetitionSettings(prev => ({ ...prev, points: prev?.points ? false : true }))}
                                                    size={'L'}
                                                />
                                            </Section>
                                        </Section>
                                    ) : null}
                                </View>
                            </Section>
                        </>
                    )}


                </ScrollView>
            </View>

            <Section style={{ flexDirection: 'row' }}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{ flex: 1, marginRight: theme["spacing-extrasmall"] }} label={StringTable.getString('Cancel')} />
                <Button
                    onPress={(e: any) => {
                        resolve && resolve({ e: e, settings: competitionSettings });
                    }}
                    mode={competitionSettings.date ? 'primary' : 'passive'}
                    style={{ flex: 1, marginLeft: theme["spacing-extrasmall"] }}
                    label={doneLabel}
                />
            </Section>

        </KeyboardDismissingView>
    )
}

