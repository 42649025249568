import React, { useState, useEffect } from 'react';
import { View, Keyboard, ScrollView, ActivityIndicator, TouchableOpacity } from 'react-native';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getHasPinCode } from 'bridge';
import { Locale, IPressEvent } from 'interfaces';
import { KeyboardDismissingView, useTheme, Button, TextInputPhone, TextInputCode, Section, Paragraph, Subheader } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import { useAppContext } from '../../appcontext';
import LocalesSheet from './LocalesSheet';
import CloseHeader from './CloseHeader';
import useModal from '../useModal';
import { composePhoneNumber, validatePhoneNumber } from 'tools';

export default function LoginSheet(props: any) {

    const {
        inkColor,
        hideHeader,
        style, 
        resolve
    } = props; 

    const { locale : systemLocale, locales, setLastValidPinCode, currentUser, login } = useAppContext();
    const [locale, setLocale] = useState(systemLocale);
    useEffect(() => {
        setLocale(systemLocale);
    }, [systemLocale])

    const theme = useTheme();

    const [digits, setDigits] = useState('');
    const [code, setCode] = useState('');
    const [forgotPinCode, setForgotPinCode] = useState(false);
    const [error, setError] = useState('');
    const [authenticating, setAuthenticating] = useState(false);
    useEffect(() => {
        setAuthenticating(false);
    }, []);

    const { pushModal, popModal, popAllModals } = useModal();

    useEffect(() => {
        setError('');
    }, [locale, digits]);

    useEffect(() => {
        if (code?.length === 6) {
            submitCodeAuth();
        }
    }, [code]);

    useEffect(() => {
        if (forgotPinCode) {
            const digitsTrimmed = digits.replace(/^0+/, '');
            const number = `${locale.dialCode}${digitsTrimmed}`
            const sendPinCode = httpsCallable(getFunctions(), 'sendPinCode');
            sendPinCode(number);
            setCode('');
            setTimeout(() => {
                setForgotPinCode(false);
            }, 5000);
        }
    }, [forgotPinCode]);

    const [mode, setMode] = useState<'signup' | 'signin'>();

    async function submitPhoneNumber() {
        Keyboard.dismiss();
        const phoneNumber = composePhoneNumber(locale, digits);
        if (validatePhoneNumber(phoneNumber)) {
            const hasPinCode = await getHasPinCode(phoneNumber as string);
            if (hasPinCode) {
                setMode('signin');
            }
            else {
                const sendPinCode = httpsCallable(getFunctions(), 'sendPinCode');
                sendPinCode(phoneNumber);
                setMode('signup');
            }    
        }
        else {
            setError(StringTable.getString('Invalid phone number'));
        }
    }

    async function submitCodeAuth() {
        const phoneNumber = composePhoneNumber(locale, digits);
        setAuthenticating(true);
        try {
            if (validatePhoneNumber(phoneNumber)) {
                await login(phoneNumber as string, code);
                setLastValidPinCode(code);    
            }
            else {
                setError(StringTable.getString('Invalid phone number'))
            }
        } catch(err: any) {
            setError(err.message || 'unknown error');
        }
        setAuthenticating(false);
    }

    useEffect(() => {
        if (currentUser) {
            popAllModals();
        }
    }, [currentUser, popAllModals]);

    const sheetStyle = useSheetStyle();

    return(
        <View style={[sheetStyle, style]}>
            {!hideHeader ? (
                <CloseHeader onDismiss={() => resolve(null)} title={StringTable.getString('Login')}/>
            ) : null }
            <View style={{justifyContent: 'flex-start'}}>
                <ScrollView keyboardShouldPersistTaps={'always'}>

                    <Section style={{alignItems: 'center'}}> 
                        <Paragraph style={{color: inkColor || theme["color-ink"]}}>{StringTable.getString('Sign in or sign up by phone')}</Paragraph>
                    </Section>

                    { forgotPinCode ? (
                        <View>
                            <Section size={'M'} style={{alignItems: 'center'}}>
                                <ActivityIndicator color={inkColor || theme["color-ink"]}/>
                                <Paragraph style={{marginTop: 8, color: inkColor || theme["color-ink"]}}>{StringTable.getString('Sending your PIN code by SMS')}</Paragraph>
                            </Section>
                        </View>
                    ) : (
                        <View>
                            <Section size={'M'}>
                                <TextInputPhone
                                    onPressLocale={async (e: IPressEvent) => {
                                        const newLocale : Locale | null = await pushModal((
                                            <LocalesSheet
                                                locales={locales}
                                                selectedLocale={locale}
                                                style={{flex: 1}}
                                            />
                                        ), {...e, pageY: 0});

                                        newLocale && setLocale(newLocale);
                                        popModal();
                                    }}
                                    locale={locale}
                                    locales={locales}
                                    digits={digits}
                                    onChangeDigits={(t: string) => setDigits(t)}
                                    onChangeLocale={(newLocale: Locale) => newLocale && setLocale(newLocale)}
                                    editable={authenticating ? false : true}
                                />
                            </Section>

                            <Section>
                                <Button
                                    mode={'primary'}
                                    label={StringTable.getString('Submit')}
                                    onPress={() => {
                                        console.log('onPress');
                                        digits && digits.length > 5 && submitPhoneNumber();
                                    }}
                                    style={{height: 44, opacity: digits && digits.length > 5 ? 1 : 0}}
                                />
                            </Section>

                            <Section>
                                <View style={{opacity: mode ? 1 : 0}} pointerEvents={mode ? 'auto' : 'none'}>
                                    <Section style={{alignItems: 'center'}}>
                                        { authenticating ? (
                                            <>
                                                <ActivityIndicator color={inkColor || theme["color-ink"]}/>
                                                <Paragraph style={{marginTop: 8, color: inkColor || theme["color-ink"]}}>{StringTable.getString('Verifying your pin code')}</Paragraph>
                                            </>
                                        ) : (
                                            <>
                                                <Paragraph style={{color: inkColor || theme["color-ink"]}}>{StringTable.getString(mode === 'signup' ? 'You will receive your PIN code by SMS shortly' : 'Enter your six digit PIN code')}</Paragraph>
                                                <Section size={'M'} style={{alignItems: 'center'}}>
                                                    <TextInputCode
                                                        cells={6}
                                                        columns={6} 
                                                        value={code}
                                                        onChangeText={(t: string) => setCode(t)}
                                                        mode={'login'}
                                                        disabled={authenticating ? true : false}
                                                    />                                            
                                                </Section>

                                                { mode === 'signin' ? (
                                                    <Section size={'M'} style={{alignItems: 'center'}}>
                                                        <TouchableOpacity onPress={() => setForgotPinCode(true)}>
                                                            <Paragraph style={{color: inkColor || theme["color-ink"]}}>{StringTable.getString('Forgot your PIN code?')}</Paragraph>
                                                        </TouchableOpacity>
                                                    </Section>
                                                ) : null }
                                            </>
                                        )}
                                    </Section>
                                </View>

                                {error ? (
                                    <Section size={'M'} style={{ alignItems: 'center' }}>
                                        <Paragraph style={{ color: inkColor || theme["color-negative"] }}>{`${StringTable.getString('Error')}: ${error}`}</Paragraph>
                                    </Section>
                                ) : null}
                                
                            </Section>
                        </View>
                    )}

                </ScrollView>
            </View>
        </View>
    )
}
