import { useState, useEffect, useContext } from 'react';
import { Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as ImageManipulator from 'expo-image-manipulator';
import { ImageCacheContext } from './ImageCacheContext';
import useAppContext from '../../ui3/appcontext/useAppContext';

const MAX_CACHE_SIZE = 50;
const MAX_WIDTH = 96;
const MAX_HEIGHT = 96;

export default function useCachedBase64(uri: string) {
    const [base64, setBase64] = useState<string | null>(null);
    const memoryCache = useContext(ImageCacheContext);
    const { startTime } = useAppContext();

    async function getLRUCache(key: string): Promise<string | null> {
        const cachedItem = memoryCache.get(key);
        if (cachedItem) {
            return cachedItem;
        }

        //console.log('in-memory cache miss');
        //console.log('looked for ' + key + 'among these entries:');
        //memoryCache.forEach((value, key) => console.log('key ' + key));

        const storedDataJSON = await AsyncStorage.getItem('cacheImages');
        if (storedDataJSON) {
            const storedData = JSON.parse(storedDataJSON);
            //Copy AsyncStorage to in-memory cache
            Object.keys(storedData).forEach(key => {
                memoryCache.set(key, storedData[key]);
            })
            //And get the requested image
            const imageData = memoryCache.get(key);
            return imageData || null;
        }
        return null;
    }

    async function setLRUCache(key: string, value: string) {
        memoryCache.set(key, value);

        const storedDataJSON = await AsyncStorage.getItem('cacheImages');
        let storedData: { [key: string]: string };
        if (storedDataJSON) {
            storedData = JSON.parse(storedDataJSON);
        } else {
            storedData = {};
        }
        storedData[key] = value;

        if (Object.keys(storedData).length > MAX_CACHE_SIZE) {
            // Remove the least recently used item from the cache
            const sortedKeys = Object.keys(storedData).sort(
                (a, b) => storedData[a].localeCompare(storedData[b])
            );
            const lruKey = sortedKeys[0];
            delete storedData[lruKey];
        }

        await AsyncStorage.setItem('cacheImages', JSON.stringify(storedData));
    }

    async function resizeImageIfNeeded(dataUrl: string) {
        const { width, height } = await getImageSize(dataUrl);

        if (width <= MAX_WIDTH && height <= MAX_HEIGHT) {
            return dataUrl;
        }

        const aspectRatio = width / height;
        let newWidth = width;
        let newHeight = height;

        if (width > MAX_WIDTH) {
            newWidth = MAX_WIDTH;
            newHeight = Math.round(newWidth / aspectRatio);
        }

        if (newHeight > MAX_HEIGHT) {
            newHeight = MAX_HEIGHT;
            newWidth = Math.round(newHeight * aspectRatio);
        }

        const resizedImage = await ImageManipulator.manipulateAsync(
            uri,
            [{ resize: { width: newWidth, height: newHeight } }],
            { format: ImageManipulator.SaveFormat.JPEG, base64: true }
        );

        return `data:image/png;base64,${resizedImage.base64}`;
    }

    async function getImageSize(dataUrl: string): Promise<{ width: number; height: number }> {
        return new Promise((resolve, reject) => {
            Image.getSize(
                dataUrl,
                (width, height) => {
                    resolve({ width, height });
                },
                (error) => {
                    console.error('Error getting image size:', error);
                    reject(error);
                }
            );
        });
    }

    useEffect(() => {
        if (!uri) {
            return;
        }

        async function handleCaching() {
            try {
                const start = performance.now();
                const imgName = uri.split('?')[0].split('/').pop();

                if (imgName) {
                    const storedData = await getLRUCache(imgName);
                    if (storedData) {
                        setBase64(storedData);
                    } else {
                        const response = await fetch(uri);
                        const blob = await response.blob();
                        const reader = new FileReader();
                        reader.readAsDataURL(blob);
                        reader.onloadend = async () => {
                            const dataUrl = reader.result as string;
                            const resizedDataUrl = await resizeImageIfNeeded(dataUrl);
                            setBase64(resizedDataUrl);
                            setLRUCache(imgName, resizedDataUrl);
                        };
                    }
                }
                else {
                    console.error('Error image did not have a name');
                }

                const end = performance.now();
                //console.log('loaded image ' + imgName + ' at ' + Math.round(end - startTime.current) + 'ms');
                //console.log('loading image, ' + (Math.round(end - start)) + 'ms');

            } catch (error) {
                console.error('Error handling image caching:', error);
            }
        }

        handleCaching();
    }, [uri]);

    return base64;
}
