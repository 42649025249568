import React from 'react';
import Text from '../helpers/Text';
import useThemeStyle from '../theme/useThemeStyle';

export interface HeadlineProps {
    children?: string,
    style?: any,
    numberOfLines?: number,
    weight?: string,
    size?: string
}

export default function Headline(props: HeadlineProps) {

    const {
        style : styleProp,
        children, 
        numberOfLines,
    } = props;
    const style = useThemeStyle('Headline', props);

    return(
        <Text numberOfLines={numberOfLines} style={[style, styleProp]}>
            {children}
        </Text>
    )
}