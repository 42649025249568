import React from 'react';
import { View } from 'react-native';
import { Button } from 'components';
import { StringTable } from 'utils';
import { useStyles, useLayout, useDocMenuButtons } from '../hooks';
import { IPressEvent } from 'interfaces';
import MenuSheet from '../actions/sheets/MenuSheet';
import { useActions } from '../actions';
import { useAppContext } from '../appcontext';
import { useNavigation } from '../navigation';
import { SuperSchedule } from 'business';

interface Props {
    title?: string,
    schedule?: SuperSchedule | null,
    isSchedulable?: boolean
}

const icons : {[buttonType: string]: string} = {
    'Register': 'ico_ball', 
    'Register as reserve': 'ico_ball',
    'Respond to invitation': 'ico_ball',
    'Unregister': 'ico_clear',
    'Join group': 'ico_add', 
    'Leave group': 'ico_clear', 
    'Group chat': 'ico_chatround',
    'Follow club': 'ico_view',
    'Unfollow club': 'ico_clear',
    'Copy link': 'ico_link', 
    'Edit': 'ico_settings', 
    'Edit teams': 'ico_group',
    'Edit groups': 'ico_group',
    'Shuffle teams': 'ico_shuffle',
    'Make public': 'ico_language',
    'Make private': 'ico_language',
    'Repeat weekly': 'ico_repeat', 
    'Stop repeat': 'ico_repeat',
    'Directions': 'ico_location',
    'Make schedule': 'ico_play_circle',
    'Clear schedule': 'ico_clear',
    'Start event': 'ico_ball',
    'Pause event': 'ico_pause',
    'End event': 'ico_trophy',
    'Reopen event': 'ico_ball',
    'Delete event': 'ico_delete',
    'Delete group': 'ico_delete', 
    'Show log': 'ico_document',
    'Edit admins': 'ico_settings',
    'Event history': 'ico_calendar',
    'Copy event': 'ico_copy',
    'Export members': 'ico_copy',
    'Delete club': 'ico_delete',    
    'Review club': 'ico_thumbup',
    'Publish': 'ico_arrowup'
}

const modes : {[buttonType: string]: string} = {
    'Register': 'primary',
    'Register as reserve': 'primary',
    'Respond to invitation': 'primary',
    'Unregister': 'negative',
    'Join group': 'primary',
    'Leave group': 'negative', 
    'Unfollow club': 'negative',
    'Clear schedule': 'negative',
    'Delete event': 'dangerous',
    'Delete group': 'dangerous',
    'Delete club': 'dangerous', 
    'Start event': 'primary', 
    'End event': 'primary',
    'Follow club': 'primary',
    'Make schedule': 'primary'
}


export default function DocMenu(props: Props) {

    const { title, schedule, isSchedulable } = props;
    const styles = useStyles();
    const { layout } = useLayout();
    const buttonStyle = layout === 'desktop' ? styles.menuButtonDesktop : styles.menuButton;

    const { currentUser } = useAppContext();
    const { navigate } = useNavigation();

    const { 
        target, 
        editTarget, 
        copyLinkToTarget, 
        joinTarget, 
        leaveTarget, 
        deleteTarget, 
        makeTargetPrivate, 
        makeTargetPublic, 
        stopRepeatTarget, 
        startRepeatTarget, 
        editCompetitionTeams, 
        shuffleCompetitionTeams, 
        scheduleTarget, 
        unscheduleTarget, 
        startCompetition, 
        pauseCompetition,
        closeCompetition, 
        reopenCompetition,
        showLog, 
        showEventHistory,
        directionsToTarget, 
        setAdmins, 
        duplicateCompetition,
        publishTarget, 
        reviewClub,
        exportMembers,
        pushModal, 
        popAllModals 
    } = useActions();

    const buttons = useDocMenuButtons(target, isSchedulable);

    async function handlePress(e: IPressEvent, buttonType: ButtonType) {
        switch(buttonType) {
            case 'Edit':
                await editTarget(e, schedule);
                break;
            case 'Copy link':
                await copyLinkToTarget();
                break;
            case 'Register':
            case 'Register as reserve':
            case 'Respond to invitation':
            case 'Join group':
                await joinTarget(e);
                break;
            case 'Unregister':
            case 'Leave group':
                await leaveTarget(e);
                break;
            case 'Delete event':
            case 'Delete group':
            case 'Delete club':
                await deleteTarget(e);
                break;
            case 'Edit teams':
            case 'Edit groups':
                await editCompetitionTeams(e, schedule);
                break;
            case 'Shuffle teams':
                await shuffleCompetitionTeams(e, schedule);
                break;
            case 'Make private':
                await makeTargetPrivate(e);
                break;
            case 'Copy event':
                await duplicateCompetition(e, navigate);
                break;
            case 'Make public':
                await makeTargetPublic(e);
                break;
            case 'Stop repeat':
                await stopRepeatTarget(e);
                break;
            case 'Repeat weekly':
                await startRepeatTarget(e);
                break;
            case 'Follow club':
                currentUser && target?.club && currentUser.followClub(target.club.id);
                break;
            case 'Unfollow club':
                currentUser && target?.club && currentUser.unfollowClub(target.club.id);
                break;
            case 'Directions':
                directionsToTarget();
                break;
            case 'Make schedule':
                await scheduleTarget(e);
                break;
            case 'Clear schedule':
                await unscheduleTarget(e);
                break;
            case 'Start event':
                await startCompetition(e);
                break;
            case 'Pause event':
                await pauseCompetition(e);
                break;
            case 'End event':
                await closeCompetition(e);
                break;
            case 'Reopen event':
                await reopenCompetition(e);
                break;
            case 'Group chat':
                target?.group?.settings.conversationRef && navigate({conversationId: target.group.settings.conversationRef})
                break;
            case 'Show log':
                await showLog(e);
                break;
            case 'Event history':
                await showEventHistory(e);
                break;
            case 'Edit admins':
                await setAdmins(e);
                break;
            case 'Publish':
                await publishTarget(e);
                break;
            case 'Review club':
                await reviewClub(e);
                break;
            case 'Export members':
                await exportMembers();
        }
        popAllModals();
    }

    async function handleMore(e: IPressEvent) {
        const sheetResult = await pushModal((
            <MenuSheet title={title || StringTable.getString('Menu')}>
                {buttons.slice(2).map((b: ButtonType) => (
                    <Button 
                        key={b} 
                        icon={icons[b]} 
                        label={StringTable.getString(b)} 
                        style={buttonStyle} 
                        onPress={(e: IPressEvent) => handlePress(e, b)}
                        mode={modes[b]}
                    />
                ))}
            </MenuSheet>
        ), e);
        popAllModals();
    }

    return(
        <View style={layout === 'mobile' ? styles.column : styles.row}>
            {buttons.slice(0, buttons.length > 3 ? 2 : 3).map((b: ButtonType) => (
                <Button 
                    key={b} 
                    icon={icons[b]} 
                    label={StringTable.getString(b)} 
                    style={buttonStyle} 
                    onPress={(e: IPressEvent) => handlePress(e, b)}
                    mode={modes[b]}
                />
            ))}
            {buttons.length > 3 ? (
                <Button 
                    icon={'ico_more'} 
                    label={StringTable.getString('More')}
                    style={buttonStyle} 
                    onPress={handleMore}
                />
            ) : null }
        </View>

    )
}