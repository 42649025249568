import { useEffect } from 'react';
import { useAppContext } from '../appcontext'
import { useActions } from '../actions';

export default function OnboardingScreen(props: any) {

    const { currentUser, currentUserId, preventOnboarding, setPreventOnboarding } = useAppContext();
    const { onboard } = useActions();

    useEffect(() => {
        if (currentUserId && currentUser !== undefined && !currentUser?.state?.registered && !preventOnboarding) {
            setPreventOnboarding(true);
            onboard();
        }
    }, [currentUser, currentUserId, onboard, preventOnboarding]);

    return null;
}
