import React, { useEffect, useState, useMemo } from "react";
import { Platform, useColorScheme } from 'react-native';
import ThemeContext from "./ThemeContext";
import theme from "./theme";
import darkTheme from "./darkTheme";

const mappingConfig = {

    "Screen": {
        settings: {}, 
        base: {
            backgroundColor: "color-paper"
        }
    },
    "Icon": {
        settings: {},
        base: {            
        }, 
        props: {
            mode: {
                contained: {
                    base: {
                        padding: "spacing-extrasmall", 
                        borderRadius: 40,
                        backgroundColor: "color-primary"
                    }    
                }
            }
        }
    },

    "Shout": {
        settings: {}, 
        base: {
            fontSize: "text-extraextralarge",
            lineHeight: "text-extraextralarge-lineheight",
            fontFamily: 'Circular-Book',
            color: "color-ink-soft",
            letterSpacing: -1,
            textTransform: 'uppercase'
        },
        props: {
            size: {
                L: {
                    base: {
                        fontSize: "text-large", 
                        fontFamily: 'Roboto-Light',
                        lineHeight: "text-large-lineheight",
                        textTransform: 'none',
                        letterSpacing: 0,
                        color: "color-ink"
                    }
                },
                M: {
                    base: {
                        fontSize: "text-medium", 
                        fontFamily: 'Roboto-Light',
                        lineHeight: "text-medium-lineheight",
                        textTransform: 'none',
                        letterSpacing: 0,
                        color: "color-ink"
                    }
                }
            }
        }
    },

    "Headline": {
        settings: {},
        base: {
            marginLeft: -1,
            fontSize: "text-extralarge",
            lineHeight: "text-extralarge-lineheight",
            fontFamily: 'Circular-Bold',
            color: "color-ink",
            letterSpacing: -1
        }, 
        props: {
            size: {
                XL: {
                    base: {
                        fontSize: "text-extraextralarge",
                        lineHeight: "text-extraextralarge-lineheight",
                        letterSpacing: -3            
                    }
                }
            },
            weight: {
                book: {
                    base: {
                        fontFamily: 'Circular-Regular'
                    }
                }
            }
        }
    },
    "Subheader": {
        settings: {},
        base: {
            fontSize: "text-large",
            lineHeight: "text-large-lineheight",
            fontFamily: 'Circular-Bold',
            color: "color-ink",
            letterSpacing: -1
        },
        props: {
            weight: {
                bold: {
                    base: {
                        fontFamily: 'Circular-Bold'
                    }
                },
                book: {
                    base: {
                        fontFamily: 'Circular-Book'
                    }
                }
            }
        }
    },
    "Paragraph": {
        settings: {},
        base: {
            fontSize: "text-medium",
            lineHeight: "text-medium-lineheight",
            textAlignVertical: "bottom",
            fontFamily: 'Circular-Regular',
            color: "color-ink",
            letterSpacing: -1
        }, 
        props: {
            weight: {
                bold: {
                    base: {
                        fontFamily: 'Circular-Bold'
                    }
                },
                book: {
                    base: {
                        fontFamily: 'Circular-Book'
                    }
                }
            }
        }
    },
    "Caption": {
        settings: {},
        base: {
            fontSize: "text-small",
            lineHeight: "text-small-lineheight", 
            fontFamily: 'Circular-Regular',
            color: "color-ink"
        }, 
        props: {
            weight: {
                bold: {
                    base: {
                        fontFamily: 'Circular-Bold'
                    }
                },
                book: {
                    base: {
                        fontFamily: 'Circular-Book'
                    }
                }
            }
        }
    },    
    "Overline": {
        settings: {},
        base: {
            fontSize: "text-small",
            fontFamily: 'Circular-Book',
            color: "color-ink",
            textTransform: 'uppercase', 
            marginBottom: "spacing-small"
        },
        props: {
            weight: {
                bold: {
                    base: {
                        fontFamily: 'Circular-Bold'
                    }
                },
                book: {
                    base: {
                        fontFamily: 'Circular-Book'
                    }
                }
            }
        }
    },
    "BigInt": {   
        settings: {},
        base: {
            fontSize: "text-extralarge",
            lineHeight: "text-extralarge",
            fontFamily: 'Circular-Bold',
            color: "color-negative",
            letterSpacing: -2,
            textTransform: 'uppercase'
        }
    },

    "Tooltip": {
        settings: {}, 
        base: {
            padding: "spacing-small", 
            arrowSize: "spacing-small",
            borderRadius: 4,
            backgroundColor: "color-primary"
        }
    },

    "CheckBox": {
        settings: {},
        base: {
            marginLeft: "spacing-extrasmall",
            color: "color-ink",
            fontSize: "text-medium", 
            iconSize: 18,
            lineHeight: "text-medium",
            fontFamily: "Circular-Book"
        },
        props: {
            mode: {
                contained: {
                    base: {
                        color: "color-ink-contained"
                    }
                }
            }, 
            disabled: {
                true: {
                    base: {
                        color: "color-passive"
                    }
                }
            },
            size: {
                L: {
                    base: {
                        iconSize: 26
                    }
                }
            }
        }
    },

    "PageIndicator": {
        settings: {}, 
        base: {
            margin: "spacing-small", 
            borderWidth: 1, 
            borderColor: "color-ink-soft",
            activeColor: "color-ink-soft",
            highlightColor: "color-ink",
            width: 8, 
            height: 8, 
            borderRadius: 4
        }
    },

    "Padel": {
        settings: {},
        base: {
            width: 32, 
            height: 32
        }, 
        props: {
            size: {
                XL: {
                    base: {
                        width: 72, 
                        height: 72
                    }
                },
                L: {
                    base: {
                        width: 48, 
                        height: 48
                    }
                },
                M: {
                    base: {
                        width: 32, 
                        height: 32
                    }
                },
                S: {
                    base: {
                        width: 20,
                        height: 20
                    }
                }
            }
        }
    },

    "Graph": {
        settings: {}, 
        base: {
            height: 80
        }
    },

    "Bullet": {
        settings: {},
        base: {
            iconSize: "text-small", 
            color: "color-ink"
        }, 
        props: {
            size: {
                M: {
                    base: {
                        iconSize: "text-medium",
                    }
                }
            }
        }
    },

    "Sticker": {
        settings: {}, 
        base: {
            penColor: "color-ink-contained", 
            iconSize: "text-small",
            paddingHorizontal: "spacing-medium",
            paddingVertical: 2,
            borderRadius: 6,
            flexDirection: 'row', 
            alignItems: 'center',
            overflow: 'hidden'
        }
    },

    "Button": {
        settings: {},
        base: {
            borderRadius: 'roundness',
            paddingHorizontal: 'spacing-medium',
            borderWidth: 1,
            height: 36, 
            iconSize: 18
        }
    },

    "IconButton": {
        settings: {},
        base: {
            height: 36,
            width: 36, 
            alignItems: 'center', 
            justifyContent: 'center',
            borderRadius: 18,
            borderWidth: 1,
            backgroundColor: "color-ink-supersoft",
            borderColor: "color-ink-supersoft",
            iconSize: 19,
            iconColor: "color-ink"
        },
        props: {
            mode: {
                film: {
                    base: {
                        backgroundColor: "color-film",
                        iconColor: "color-ink-contained",
                        borderColor: 'transparent'
                    }
                },
                active: {
                    base: {
                        backgroundColor: "color-primary",
                        iconColor: "color-ink-contained"
                    }
                }, 
                waiting: {
                    base: {
                        backgroundColor: "color-ink-soft",
                        iconColor: "color-ink-contained"
                    }
                },
                plain: {
                    base: {
                        backgroundColor: 'transparent', 
                        iconColor: "color-ink",
                        borderWidth: 0
                    }
                }, 
                primary: {
                    base: {
                        backgroundColor: 'color-primary', 
                        iconColor: 'color-ink-contained', 
                        borderColor: 'color-primary'
                    }
                }
            },
            size: {
                L: {
                    base: {
                        height: 48, 
                        width: 48, 
                        borderRadius: 24, 
                        iconSize: 35
                    }
                }, 
                S: {
                    base: {
                        height: 24, 
                        width: 24, 
                        borderRadius: 12, 
                        iconSize: 19
                    }
                }
            }
        }
    },

    "RadioButton": {
        settings: {}, 
        base: {
            width: 18,
            height: 18, 
            borderRadius: 9,
            borderWidth: 1, 
            marginRight: "spacing-small",
            borderColor: "color-ink",
            iconColor: "color-ink",
            justifyContent: 'center', 
            alignItems: 'center',
            iconSize: 13
        }
    },

    "ParticipationActionPoint": {
        settings: {}, 
        base: {
            height: 48,
            borderRadius: 21,
            borderWidth: 1, 
            paddingRight: "spacing-small"
        }, 
        props: {
            status: {
                OPEN: {
                    base: {
                        backgroundColor: "color-positive", 
                        borderColor: "color-positive", 
                        textColor: "color-ink-contained"    
                    }
                },
                JOINED: {
                    base: {
                        backgroundColor: "transparent", 
                        textColor: "color-negative",
                        borderColor: "color-negative"
                    }
                },
                RESERVE: {
                    base: {
                        backgroundColor: "transparent", 
                        textColor: "color-negative",
                        borderColor: "color-negative"
                    }
                },
                FULL: {
                    base: {
                        backgroundColor: "transparent", 
                        borderColor: "color-neutral",
                        textColor: "color-neutral"
                    }
                }
            }
        }
    },    

    "TextInput": {
        settings: {},
        base: {
            fontSize: 16, 
            lineHeight: 18,
            minHeight: 40,
            fontFamily: 'Circular-Regular',
            textColor: "color-ink",
            padding: 6,
            paddingVertical: 10,
            backgroundColor: "color-ink-supersoft", 
            borderRadius: 'roundness',
            borderWidth: 1, 
            borderColor: "color-ink-supersoft"
        },
        state: {
            focused: {
                backgroundColor: "color-card",
                textColor: "color-ink"
            }
        },
        props: {
            mode: {
                login: {
                    base: {
                        backgroundColor: "color-film", 
                        borderColor: "color-film",
                        textColor: "color-ink-contained"           
                    }
                },
                outlined: {
                    base: {
                        backgroundColor: "transparent",
                        borderColor: "color-ink-contained",
                        textColor: "color-ink-contained"
                    }
                }
            }
        }
    },

    "TextInputPhone": {
        settings: {},
        base: {
            fontSize: 16, 
            lineHeight: 18,
            fontFamily: 'Circular-Regular',
            textColor: "color-ink",
            padding: 6,
            backgroundColor: "color-ink-supersoft", 
            borderRadius: "roundness",
            borderWidth: 1, 
            borderColor: "color-ink-supersoft"
        },
        state: {
            focused: {
                backgroundColor: "color-card",
                textColor: "color-ink"
            }
        },
        props: {
            mode: {
                login: {
                    base: {
                        backgroundColor: "color-film", 
                        borderColor: "color-film",
                        textColor: "color-ink-contained"           
                    }
                }
            }
        }
    },

    "TextInputCode": {
        settings: {},
        base: {
            penColor: "color-ink",
            placeholderColor: "color-ink-supersoft",
            borderWidth: 1, 
            borderColor: "color-background",
            backgroundColor: "color-paper", 
            borderRadius: 12,
            width: 36, 
            height: 44, 
            margin: 6,
            alignItems: 'center',
            justifyContent: 'center'
        },
        state: {
            focused: {
                backgroundColor: "color-card",
                penColor: "color-ink"
            }
        },
        props: {
            mode: {
                login: {
                    base: {
                        backgroundColor: "color-ink-supersoft", 
                        borderColor: "color-ink-supersoft",
                        penColor: "color-ink",
                        margin: 3
                    }
                }
            },
            disabled: {
                true: {
                    base: {
                        backgroundColor: 'transparent'
                    }
                }
            },
            size: {
                S: {
                    base: {
                        width: 32, 
                        height: 38, 
                        margin: 3,
                        borderRadius: 8    
                    }
                }
            }
        }
    },

    "CalendarLabel": {
        settings: {},
        base: {
            fontSize: 13, 
            fontFamily: "Circular-Book", 
            color: "color-ink"
        }
    },

    "CalendarDigit": {
        settings: {},
        base: {
            width: 36, 
            height: 36, 
            fontSize: 16,
            fontFamily: "Circular-Regular",
            color: "color-ink"
        },
        props: {
            selected: {
                true: {
                    base: {
                        borderRadius: 18,
                        backgroundColor: "color-primary",
                        borderColor: "color-primary",
                        shadowColor: "#000",
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5
                    }
                }
            }
        }
    },

    "Divider": {
        settings: {},
        base: {
            height: 1, 
            backgroundColor: "color-ink-supersoft"
        },
    },
    "DividerVertical": {
        settings: {},
        base: {
            width: 1, 
            alignSelf: 'stretch',
            backgroundColor: "color-ink-supersoft"
        },
    },
    "SelectedLine": {
        settings: {},
        base: {
            height: 4, 
            marginTop: 4, 
            borderRadius: 2,
            backgroundColor: "transparent"
        },
        props: {
            selected: {
                true: {
                    base: {
                        backgroundColor: "color-primary"
                    }
                }
            }
        }
    },

    "PageListIndicator": {
        settings: {},
        base: {
            marginTop: "spacing-extrasmall", 
            height: 2, 
            backgroundColor: "color-positive"
        },
    },

    "Player": {
        settings: {}, 
        base: {
            flexDirection: 'row',
            alignItems: 'center',
            padding: "spacing-small"
        }
    },

    "Paper": {
        settings: {}, 
        base: {
            backgroundColor: "color-paper",
            paddingHorizontal: "spacing-large"
        },
        props: {
            size: {
                M: {
                    base: {
                        paddingHorizontal: "spacing-medium"
                    }
                }, 
                S: {
                    base: {
                        paddingHorizontal: "spacing-small"
                    }
                }
            }
        }        
    },

    "Section": {
        settings: {},
        base: {
            marginTop: "spacing-medium"
        },
        props: {
            size: {
                XL: {
                    base: {
                        marginTop: "spacing-extralarge"                       
                    }
                },
                L: {
                    base: {
                        marginTop: "spacing-large"
                    }
                },
                M: {
                    base: {
                        marginTop: "spacing-medium"
                    }
                }, 
                S: {
                    base: {
                        marginTop: "spacing-small"
                    }
                },
                XS: {
                    base: {
                        marginTop: "spacing-extrasmall"
                    }
                }
            }
        }
    },

    "SectionExploded": {
        settings: {}, 
        base: {
            marginTop: "spacing-large", 
            marginHorizontal: "spacing-large-negative"
        },
        props: {
            size: {
                M: {
                    base: {
                        marginTop: "spacing-medium"
                    }
                }, 
                S: {
                    base: {
                        marginTop: "spacing-small"
                    }
                }
            }
        }
    },

    "Card": {
        settings: {},
        base: {
            borderRadius: "roundness", 
            backgroundColor: "color-card", 
            padding: "spacing-medium",
            overflow: 'hidden', 
            maxWidth: "card-maxwidth"
        },
        props: {
            size: {
                L: {
                    base: {
                        padding: "spacing-large"
                    }
                },
                S: {
                    base: {
                        padding: "spacing-small",
                        paddingHorizontal: "spacing-medium", 
                    }
                }
            }
        }
    },

    "CardImage": {
        settings: {},
        base: {
            left: 0, 
            right: 0, 
            marginLeft: "spacing-large-negative",
            marginRight: "spacing-large-negative",
            marginTop: "spacing-large-negative",
            height: 130
        }
    },

    "CardImageHorizontal": {
        settings: {},
        base: {
            top: 0, 
            bottom: 0, 
            marginLeft: "spacing-large-negative",
            marginBottom: "spacing-large-negative",
            marginTop: "spacing-large-negative",
            width: 130
        }
    },

    "ConversationCard": {
        settings: {},
        base: {
            backgroundColor: "color-card", 
            padding: "spacing-medium"            
        }
    },

    "Badge": {
        settings: {},
        base: {
            fontSize: 12,
            paddingTop: Platform.OS === 'ios' ? 2: 0, 
            textColor: 'color-ink-contained', 
            fontFamily: 'Circular-Book',
            width: 16, 
            height: 16, 
            borderRadius: 8, 
            backgroundColor: 'color-negative', 
            justifyContent: 'center', 
            alignItems: 'center'
        }
    },
    
    "Spacer": {
        settings: {},
        base: { width: 0, height: 0, marginTop: "spacing-medium"}, 
        props: {
            size: {
                S: {
                    base: {
                        marginTop: "spacing-small"
                    }
                }, 
                L: {
                    base: {
                        marginTop: "spacing-large"
                    }
                },
                XL: {
                    base: {
                        marginTop: "spacing-extralarge"
                    }
                }
            }
        }
    }
};

function resolveMappingConfig(source, theme) {
    const target = {};
    function iterate(source, target) {
        Object.keys(source).forEach(key => {
            if (source[key] && theme.hasOwnProperty(source[key])) {
                target[key] = theme[source[key]];
            }
            else if (source[key] !== null && typeof source[key] === "object") {
                target[key] = {};
                iterate(source[key], target[key]);
            }
            else {
                target[key] = source[key];
            }
        });
    }

    if (typeof source === "object") {
        iterate(source, target);
    }            
    return target;
}

export default function ThemeProvider(props) {

    const {
        children
    } = props;

    const colorScheme = useColorScheme();

    const [mappings, setMappings] = useState(resolveMappingConfig(mappingConfig, theme));

    useEffect(() => {
        if (colorScheme === 'dark') {
            setMappings(resolveMappingConfig(mappingConfig, {...theme, ...darkTheme}));
        }
        else {
            setMappings(resolveMappingConfig(mappingConfig, {...theme}));
        }
    }, [colorScheme]);

    const value = useMemo(() => {
        return {
            mappings, 
            theme : {...theme, ...(colorScheme === 'dark' ? darkTheme : {})}
        }
    }, [mappings, theme, darkTheme, colorScheme]);
        
    return(
        <ThemeContext.Provider value={value}>
            {children}
        </ThemeContext.Provider>
    )
}
