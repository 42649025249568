import { useState, useEffect } from 'react';
import { Club } from 'business';
import { useQuery, useDocumentInstances } from 'bridge';
import { arrayUnique } from 'tools';

/**
 * Fetches all clubs in the users region, plus any followed clubs that are not
 * part of the regional set of clubs
 * 
 * @param region 
 * @param followedClubRefs 
 * @returns 
 */
export default function useClubs(region?: string, followedClubRefs?: string[]) : Club[] {

    //Query for regional clubs
    const clubs : Club[] | undefined = useQuery({queryType: 'clubs', region: region});

    //Remote clubs are those followed by the user but not in the result set from the region query
    const [remoteClubRefs, setRemoteClubRefs] = useState<string[] | undefined>();
    useEffect(() => {
        clubs && setRemoteClubRefs(followedClubRefs?.filter(clubRef => !clubs.find(c => c.id === clubRef)));
    }, [clubs, followedClubRefs]);

    //Fetch remote clubs
    const remoteClubs : (Club | null)[] | undefined = useDocumentInstances('clubs', remoteClubRefs);

    
    //Sort clubs such that local followed comes first, followed by local, followed by remote followed
    const [sortedClubs, setSortedClubs] = useState<Club[]>([]);
    useEffect(() => {
        if (clubs || remoteClubs) {
            const filteredClubs = clubs?.filter(c => c?.collection === 'clubs');
            const sorted = filteredClubs?.sort((a: Club, b: Club) => {
                if (followedClubRefs?.includes(b.id) && !followedClubRefs?.includes(a.id)) {
                    return 1;
                }
                else if (followedClubRefs?.includes(a.id) && !followedClubRefs?.includes(b.id)) {
                    return -1;
                }
                return 0;
            }) || [];

            remoteClubs && remoteClubs.forEach((remoteClub : Club | null) => {
                if (remoteClub && !sorted.find((regionalClub: Club) => regionalClub.id === remoteClub.id)) {
                    sorted.push(remoteClub);
                }
            })
            setSortedClubs(arrayUnique(sorted.filter(c => c?.id), 'id'));
        }
        else {
            setSortedClubs([]);
        }
    }, [clubs, remoteClubs]);

    return sortedClubs;
}
