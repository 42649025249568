import React, { useState, useEffect } from 'react';
import { ImageCacheContext } from './ImageCacheContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default function ImageCacheProvider(props: any) {

    const sharedMemoryCache = React.useRef<Map<string, string>>(new Map()).current;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function preloadImageCache() {
            const storedDataJSON = await AsyncStorage.getItem('cacheImages');
            if (storedDataJSON) {
                const storedData = JSON.parse(storedDataJSON);
                //Copy AsyncStorage to in-memory cache
                Object.keys(storedData).forEach(key => {
                    sharedMemoryCache.set(key, storedData[key]);
                })
            }
            setLoading(false);        
        }
        preloadImageCache();
    }, []);


    return loading ? null : (
        <ImageCacheContext.Provider value={sharedMemoryCache}>
            {props.children}
        </ImageCacheContext.Provider>
    )
}