import { Group } from 'business';
import { Platform } from 'react-native';
import getUsers from '../hooks/getUsers'

export default async function exportMembers(target: IActionTarget | null) {

    const group : Group | null = target?.group || null;
    if (group) {
        const members = group.participation.userRefs;
        if (members) {
            const identities = await getUsers(members);
            if (identities) {
                const header = ['Name,Number'];
                const rows = identities.filter(identity => identity?.getTitle()).map(identity => `${identity!.getTitle()},${identity!.id}`);
                const csvString = header.concat(rows).join('\n');
                if (Platform.OS === 'web') {
                    saveCSV(csvString, "members")
                }
            }
        }
    }
}

function saveCSV(csvString: string, fileName: string) {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = `${fileName}.csv`
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}
