const darkTheme = {
    "color-ink": "#FFFFFF",
    "color-ink-soft": "#BBBBBB",
    "color-ink-supersoft": "#8F8F8F",
    "color-ink-contained": "#FFFFFF",
    "color-background": "#1F1F1F",
    "color-paper": "#2D2D2D",
    "color-card": "#3A3A3A",
    "color-primary": "#ff905c",
    "color-blue": "#0A70FF",
    "color-shadow": "#222222",
    "color-negative": "#FF6B6B",
    "color-neutral": "#F9A318",
    "color-positive": "#6BCB77",
    "color-competition-1": "#F9A318",
    "color-competition-2": "#C25451",
    "color-competition-3": "#0082C9",
    "color-competition-4": "#447B75",
    "color-competition-5": "#91714F",
    "color-competition-6": "#00C9AE",
    "color-passive": "#DBD8C5",
    "color-gold": "#DAA520",
    "color-film": "rgba(0,0,0,0.6)",
    "color-message": "#FFFFFF",
    "color-message-mine": "#D9F9D9",
    "color-message-pen": "#222222"
};

export default darkTheme;