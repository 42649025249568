import { useState, useEffect } from 'react';
import * as Application from 'expo-application';
import { useAppContext } from '../appcontext';

export default function useIsLatestVersion() {
    const [isLatest, setIsLatest] = useState(true);
    const { latestVersion } = useAppContext();
    useEffect(() => {
        const version = Application.nativeApplicationVersion;

        if (__DEV__) {
            setIsLatest(true);
        }
        else {
            setIsLatest(version && latestVersion && (version !== latestVersion) ? false : true);
        }
    }, [latestVersion]);

    return isLatest;
}