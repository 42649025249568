import React from 'react';
import { Text as RNText, Animated } from 'react-native';

const AnimatedText = Animated.createAnimatedComponent(RNText);
export default function Text(props) {

    return (
        <AnimatedText {...props} selectable={false}/>
    )
}
