import { useState, useEffect } from "react";
import { User, Competition, Group, Club, UserIdentity } from 'business';
import { searchAsync } from 'bridge';

export default function useSearch(searchQuery?: string) {

    const [result, setResult] = useState<{userIdentities?: UserIdentity[], competitions?: Competition[], clubs?: Club[], groups?: Group[]}>({});

    useEffect(() => {
        async function search() {
            const users = await searchAsync<User>('users', { searchQuery });
            const competitions = await searchAsync<Competition>('competitions', { searchQuery, public: true, future: true });
            const clubs = await searchAsync<Club>('clubs', { searchQuery, public: true });
            const groups = await searchAsync<Group>('groups', { searchQuery, public: true });
            const userIdentities = users?.map(u => u.toUserIdentity());
            setResult({userIdentities, competitions, clubs, groups})
        }

        if (searchQuery && searchQuery.length > 2) {
            search();
        }
        else {
            setResult({});
        }

    }, [searchQuery]);

    return result;
}