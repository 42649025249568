import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Sticker } from 'components';
import { useStyles } from '../hooks';
import TeamCard from './TeamCard';
import UserCard from './UserCard';
import { Competition } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';

interface Props {
    competition: Competition;
    style?: any;
    onPressUser?: (e: IPressEvent, userRef: string) => Promise<void>;
    color: string;
}
export default function WinnersView(props: Props) {

    const {
        competition,
        style, 
        onPressUser,
        color,
    } = props;
    
    const winners = useMemo(() => competition.getWinners(), [competition.getWinners() ? true : false]);
    const styles = useStyles();

    const winnerRanking = winners?.[0] && competition?.state?.ratingChange?.[winners[0]];
    return(
        <View style={[style, styles.WinnersView]}>
            <View>
                <Sticker 
                    icon={'ico_ball'}
                    label={competition.settings.title || competition.settings.type} 
                    startColor={color}
                    endColor={color}
                />
            </View>
            { competition.settings.fixedTeams ? (
                <TeamCard 
                    userRefs={[winners?.[0] || StringTable.getString('Unknown'), winners?.[1] || 'Unknown']} 
                    onPressUser={onPressUser}
                    style={{margin: 0}}
                />
            ) : (
                <UserCard 
                    userRef={winners?.[0] || StringTable.getString('Unknown')} 
                    onPress={onPressUser}
                    style={{margin: 0}}
                />
            )}
        </View>
    )
} 
