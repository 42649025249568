import React, { useState, useEffect, useRef } from 'react';
import { ViewStyle, View, GestureResponderEvent, ActivityIndicator } from 'react-native';
import { Button, Section, TextInput, KeyboardDismissingView, useTheme, SectionList, Subheader, Buttonlet, Paragraph } from 'components';
import ContactListItem from './ContactsListItem';
import CreateContactSheet from './CreateContactSheet';
import useModal from '../../useModal';
import { StringTable } from 'utils';
import useSheetStyle from '../useSheetStyle';
import CloseHeader from '../CloseHeader';
import { searchContactData } from 'bridge';
import useUsersOnce from '../../../hooks/useUsersOnce';
import { createId, arrayUnique } from 'tools';
import { useAppContext } from '../../../appcontext';
import saveContactsToPhonebook from './saveContactsToPhonebook';
import usePhoneContacts from './usePhoneContacts';
import MessageSheet from '../MessageSheet';

type Props = {
    extraContacts?: string[];
    selected?: string[];
    singleSelect?: boolean;
    filterContacts?: string[];
    filterMessage?: string;
    style?: ViewStyle;
    headlineLabel: string;
    doneLabel?: string; 
    requireSelected?: boolean;
    requireEvenSelected?: boolean;
    requireEvenSelectedMessage?: string;
    showVacancy?: boolean;
    resolve?: (selectedContacts: ContactData[] | null) => void;
};

ContactsListSheet.defaultProps = {
    doneLabel: StringTable.getString('Create'),
    headlineLabel: StringTable.getString('Pick contact(s)'), 
    requireSelected: true
}

export default function ContactsListSheet(props: Props) { 

    const {
        extraContacts : extraContactsProp,
        selected : selectedProp,
        singleSelect,
        filterContacts,
        filterMessage,
        style,
        headlineLabel, 
        doneLabel,
        requireSelected,
        requireEvenSelected,
        requireEvenSelectedMessage,
        showVacancy,
        resolve,
    } = props;

    const selectedContactDataStore = useRef<{[userId: string]: ContactData}>({}).current;

    
    const theme = useTheme();

    const { extraContacts, setExtraContacts } = useExtraContacts(extraContactsProp);
    const phoneContacts = usePhoneContacts();

    const [search, setSearch] = useState('');
    const sections = useContactListSections(extraContacts, phoneContacts, search, showVacancy);

    const [selected, setSelected] = useState<string[]>(selectedProp || []);
    const { pushModal, popModal } = useModal();
    
    const handleSelect = (item: ContactData) => {
        if (singleSelect) {
            setSelected(prev => prev[0] === item.id ? [] : [item.id]);
        } else {
            setSelected(selected.includes(item.id) ? selected.filter(i => i !== item.id) : [...selected, item.id]);
        }
        selectedContactDataStore[item.id] = item;
    };

    const handleCancel = () => {
        resolve?.(null);
    };

    const handleConfirm = async (e: any) => {
        if (requireEvenSelected && ((selected.length || 1) % 2 !== 0)) {
            await pushModal((
                <MessageSheet
                    showConfirmButton={true}
                    confirmButtonLabel={StringTable.getString('OK')}
                >
                    <Paragraph>{requireEvenSelectedMessage}</Paragraph>
                </MessageSheet>
            ), e);
            popModal();
        }
        else {
            const selectedContacts = selected.map(userId => selectedContactDataStore[userId]);
            resolve?.(selectedContacts);    
        }
    }

    const handleCreate = (e?: GestureResponderEvent) => {
        async function asyncHandleCreate(e?: GestureResponderEvent) {
            const results : ICreateContactSheetResult | null = await pushModal(
                <CreateContactSheet 
                    style={{ flex: 1 }} 
                    canCreateMany={singleSelect ? false : true}
                />,
                { ...e, pageY: 0 }
            );
            popModal();
            if (results && results.created?.length) {
                saveContactsToPhonebook(results.created);
                setExtraContacts(prev => ([...(results.created || []), ...(prev || [])]));
                setSelected(prev => singleSelect ? [results.created?.[0].id] : (arrayUnique([...(prev || []), ...(results.created || []).map(r => r.id)])));
            }
            results?.created?.forEach(contact => {
                selectedContactDataStore[contact.id] = contact;
            })
        }
        asyncHandleCreate(e);
    };
    
    const sheetStyle = useSheetStyle();

    return (
        <KeyboardDismissingView style={[style, sheetStyle]} alwaysActive={true}>
            <CloseHeader title={headlineLabel} onDismiss={() => resolve?.(null)}/>
            { sections ? (
                <View style={{flex: 1}}>
                    <Section>
                        <TextInput 
                            iconLeft={'ico_search'} 
                            iconRight={'ico_clear'} 
                            placeholder={StringTable.getString("Search users")} 
                            value={search}
                            onChangeText={(t: string) => setSearch(t)}
                            onPressIconRight={() => setSearch('')}
                        />
                    </Section>
                    <Section style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Buttonlet
                            onPress={handleCreate}
                            icon={'ico_person'}
                            label={StringTable.getString('Create contact')}
                        />
                        <Buttonlet
                            onPress={handleCreate}
                            icon={'ico_clear'}
                            label={StringTable.getString('Clear selection')}
                        />
                    </Section>

                    <Section style={{flex: 1}}>
                        <SectionList
                            style={{flex: 1}}
                            numColumns={1}
                            contentContainerStyle={{paddingBottom: 320}}
                            data={sections}
                            windowSize={5}
                            maxToRenderPerBatch={10}
                            initialNumToRender={10}
                            keyExtractor={(item, index, sectionId) => `${item.id}${sectionId}`}
                            renderSectionHeader={(header) => (
                                <Section>
                                    <Subheader>{header}</Subheader>
                                </Section>
                            )}
                            renderSectionItem={(item, index, sectionId?: string) => {
                                const isFiltered = filterContacts?.includes(item.id) || false;
                                const isSelected = selected.includes(item.id);

                                return (
                                    <ContactListItem
                                        contactData={item}
                                        onPress={() => !isFiltered && handleSelect(item)}
                                        selected={isSelected}
                                        filtered={isFiltered}
                                        caption={isFiltered ? filterMessage : (sectionId === 'onlineUsers' ? StringTable.getString('Found online') : undefined )}
                                    />
                                );
                            }}
                        />                
                    </Section>                    
                </View>
            ) : (
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <ActivityIndicator color={theme["color-ink"]}/>
                </View>
            )}

            <Section style={{flexDirection: 'row'}}>
                <Button 
                    onPress={handleCancel} 
                    mode={'outlined'}
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={StringTable.getString('Cancel')}
                />
                <Button 
                    onPress={handleConfirm}
                    mode={(requireSelected && !selected.length) ? 'passive' : 'primary'} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={`${doneLabel} (${selected.length})`}
                />                
            </Section>                

        </KeyboardDismissingView>
    );
};

function useExtraContacts(userRefs?: string[]) {

    const userIdentities = useUsersOnce(userRefs);

    const [extraContacts, setExtraContacts] = useState<ContactData[]>();
    useEffect(() => {
        //We have extra contact refs
        if (userRefs) {
            //If we fetched them, return data
            if (userIdentities) {
                setExtraContacts(userRefs.map(userRef => ({
                    id: userRef, 
                    title: userIdentities?.[userRef]?.title || userIdentities?.[userRef]?.pName || userRef,
                    imageUrl: userIdentities?.[userRef]?.imageUrl,
                    rating: userIdentities?.[userRef]?.rating,
                    registered: userIdentities?.[userRef]?.registered
                })));
            }
            //If we didn't fetch them yet, just wait
            else {
            }
        }
        else {
            setExtraContacts([]);
        }
    }, [userRefs, userIdentities]);

    const appContacts = useAppContacts();

    const [contactsData, setContactsData] = useState<ContactData[]>();
    useEffect(() => {
        if (extraContacts) {
            const uniqueContacts = Array.from(
                extraContacts
                    .concat(appContacts || [])
                    .filter(c => c.title)
                    .reduce((map, contact) => map.set(contact.id, contact), new Map())
                    .values()
            );
            setContactsData(uniqueContacts);
        }
    }, [extraContacts, appContacts]);

    return { extraContacts: contactsData, setExtraContacts: setContactsData };
}

function useAppContacts() {
    const { currentUser } = useAppContext();
    const [appContacts, setAppContacts] = useState<ContactData[]>([]);
    useEffect(() => {
        setAppContacts(
            Object.keys(currentUser?.contacts || {})
            .filter(contactId => currentUser?.contacts?.[contactId].title)
            .map(contactId => ({
                id: contactId, 
                title: currentUser?.contacts?.[contactId].title || contactId 
            }))
        );
    }, [currentUser]);

    return appContacts;
}

function useContactListSections(extraContacts: ContactData[] | undefined, phoneContacts: ContactData[], searchQuery?: string, showVacancy?: boolean) {

    const [sections, setSections] = useState<ISectionListSection[]>();

    useEffect(() => {
        async function performSearch(localSearchedContacts: ContactData[]) {
            const onlineFoundContacts = await searchContactData(7, searchQuery, localSearchedContacts.map(c => c.id));
            setSections([
                {
                    id: 'localUsers', 
                    header: StringTable.getString('Found in phone'), 
                    data: localSearchedContacts                
                },
                {
                    id: 'onlineUsers', 
                    header: StringTable.getString('Found online'), 
                    data: onlineFoundContacts
                }
            ]);

        }

        //extraContacts will only be undefined prior to fetch. If no extraContacts was provided, this
        //will be an empty array post fetch
        if (!extraContacts) {
            return;
        }

        if (searchQuery) {

            const localContacts = arrayUnique([...(extraContacts || []), ...(phoneContacts || [])], 'id') as ContactData[];
            const localSearchedContacts = localContacts.filter(c => c.title.includes(searchQuery));
            
            if (localSearchedContacts.length < 7) {
                performSearch(localSearchedContacts);
            }
            else {
                setSections([
                    {
                        id: 'localUsers', 
                        header: StringTable.getString('Found in phone'), 
                        data: localSearchedContacts                
                    }    
                ]);
            }
        }
        else {

            const _sections = [
                {
                    id: 'extraContacts', 
                    header: StringTable.getString('Common'), 
                    data: extraContacts                
                }, 
                {
                    id: 'phoneContacts', 
                    header: StringTable.getString('Phonebook'), 
                    data: phoneContacts
                }
            ];

            if (showVacancy) {
                _sections.unshift({
                    id: 'vacancy', 
                    header: StringTable.getString('No partner yet?'), 
                    data: [{ id: `Vacant ${createId(5)}`, title: 'No partner yet' }]
                })
            }
            setSections(_sections);
        }

    }, [extraContacts, phoneContacts, searchQuery, showVacancy]);

    return sections;
}
