import React, { useEffect, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { Section, useTheme, Paragraph, Button, Subheader, TextInput, TextInputCode } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import { useAppContext } from '../appcontext';

interface Props {
    style?: any, 
    message?: string,
    onDone: (changedPinCode: string) => Promise<void>
}
export default function PinCodeInput(props: Props) {

    const {
        message,
        onDone
    } = props;

    const [chosenPinCode, setChosenPinCode] = useState('');
    const [repeatChosenPinCode, setRepeatChosenPinCode] = useState('');
    const [savingPinCode, setSavingPinCode] = useState(false);
    const [error, setError] = useState('');
    const theme = useTheme();

    useEffect(() => {
        if (chosenPinCode && chosenPinCode.length === repeatChosenPinCode.length && chosenPinCode !== repeatChosenPinCode) {
            setError(StringTable.getString('PIN code mismatch'))
        }
        else {
            setError('');
        }
    }, [chosenPinCode, repeatChosenPinCode]);

    return(
        <View style={{alignItems: 'center'}}>
            <Section size={'L'} style={{alignItems: 'center'}}>
                <Subheader style={{color: theme["color-ink"]}}>{StringTable.getString('Choose your own PIN code')}</Subheader>
                <TextInputCode
                    cells={6}
                    columns={6} 
                    value={chosenPinCode}
                    onChangeText={(t: string) => setChosenPinCode(t)}
                    mode={'login'}
                />
            </Section>
            <Section size={'L'} style={{alignItems: 'center'}}>
                <Subheader style={{color: theme["color-ink"]}}>{StringTable.getString('Repeat your choice for PIN code')}</Subheader>
                <TextInputCode
                    cells={6}
                    columns={6} 
                    value={repeatChosenPinCode}
                    onChangeText={(t: string) => setRepeatChosenPinCode(t)}
                    mode={'login'}
                />
            </Section>
            <Section size={'L'} style={{alignItems: 'center'}}>
                { savingPinCode ? <ActivityIndicator color={theme["color-ink"]}/> : (
                    <Button 
                        label={StringTable.getString('Save PIN code')}
                        onPress={async (e: IPressEvent) => {
                            setSavingPinCode(true);
                            try {
                                await onDone(repeatChosenPinCode);
                            } catch(err) {
                                setError(err instanceof Error ? err.message : StringTable.getString('Unknown error'));
                            }
                            setSavingPinCode(false);
                        }}
                        mode={chosenPinCode && !savingPinCode && chosenPinCode === repeatChosenPinCode ? 'primary' : 'passive'}
                        style={savingPinCode ? {opacity: 0, position: 'absolute'} : {opacity: 1}}
                    />
                )}
                { error ? (
                    <Section size={'L'}>
                        <Paragraph style={{color: theme["color-negative"]}}>{error}</Paragraph>
                    </Section>
                ) : null }
            </Section>
            { message ? (
                <Section size={'L'} style={{alignItems: 'center'}}>
                    <Paragraph>{message}</Paragraph>
                </Section>
            ) : null }
        </View>
    )
}