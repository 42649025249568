import { dateCompare } from '../tools';
import { IConversationMessage, IConversationMessageReaction } from '../interfaces';
const now = new Date();
/**
 * Match
 */
export class ConversationMessage implements IConversationMessage {

    id: string;
    sender: string;
    message: string;
    date: Date;
    reactions?: {[reaction: string]: IConversationMessageReaction[]};

    constructor(data: IConversationMessage) {
        this.id = data.id;
        this.sender = data.sender;
        this.message = data.message;
        this.date = data.date;
        this.reactions = data.reactions;
    }

    serialize() : IConversationMessage {
        return {
            id: this.id, 
            sender: this.sender, 
            date: this.date, 
            message: this.message,
            reactions: this.reactions
        }
    }

    getMessage() {
        return this.message;
    }

    getTimeStamp(dayLabels: string[], monthLabels: string[], yesterDayLabel: string) {
        const d = this.date;

        const isToday = dateCompare(this.date, now);
        const isYesterday = !isToday && (now.getTime() - d.getTime() < 1000 * 60 * 60 * 24);

        if (isToday || isYesterday) {
            const timeString = `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
            return `${isYesterday ? yesterDayLabel : ''} ${timeString}`;
        }
        return `${dayLabels[d.getDay()]} ${d.getDate()} ${monthLabels[d.getMonth()]}` + (d.getMonth() < 2 ? `${d.getFullYear()}` : ``);
    }

    addReaction(reaction: string, senderId: string) {
        this.reactions = this.reactions || {};
        this.reactions[reaction] = this.reactions[reaction] || [];

        const r : IConversationMessageReaction = {
            date: new Date(),
            sender: senderId
        }
        this.reactions[reaction].push(r);
    }
}

