import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useTheme, Button, Section, Subheader, Paragraph } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import useStyles from '../../hooks/useStyles';

interface Props {
    seed: number[],
    seedDescriptions: string[],
    style?: any,
    resolve?: (seed?: number[] | null ) => void
}

export default function PlayoffSeedsSheet(props: Props) {

    const {
        seed, 
        seedDescriptions,
        style,
        resolve
    } = props;

    const theme = useTheme();
    const styles = useStyles();
    const sheetStyle = useSheetStyle();

    const [selectedSeed, setSelectedSeed] = useState<(number | null)[]>(seed);
    const validSeed = selectedSeed && (typeof selectedSeed[0] === 'number') && (typeof selectedSeed[1] === 'number') ? true : false;

    return (
        <View style={[sheetStyle, style]}>
            <Subheader style={{alignSelf: 'center'}}>{`${StringTable.getString('Playoff matchup')}`}</Subheader>

            <ScrollView style={{flex: 1}}>
                <Section size={'M'} style={{paddingLeft: theme["spacing-medium"]}}>
                    { seedDescriptions.map((description: string, index: number) => (
                        <Button 
                            key={index}
                            label={description} 
                            icon={selectedSeed[0] === index ? 'ico_checked' : 'ico_unchecked'}
                            onPress={() => setSelectedSeed(prev => {
                                const changed = [...prev];
                                changed[0] = index;
                                if (changed[1] === index) {
                                    changed[1] = null;
                                }
                                return changed;
                            })}
                            style={styles.menuButton}
                        />
                    ))}
                </Section>
                
                <Section>
                    <Subheader style={{alignSelf: 'center'}}>{StringTable.getString('versus')}</Subheader>
                </Section>

                <Section size={'M'} style={{paddingLeft: theme["spacing-medium"]}}>
                    { seedDescriptions.map((description: string, index: number) => (
                        <Button 
                            key={index}
                            label={description} 
                            icon={selectedSeed[1] === index ? 'ico_checked' : 'ico_unchecked'}
                            onPress={() => setSelectedSeed(prev => {
                                const changed = [...prev];
                                changed[1] = index;
                                if (changed[0] === index) {
                                    changed[0] = null;
                                }
                                return changed;
                            })}
                            style={styles.menuButton}
                        />
                    ))}
                </Section>
            </ScrollView>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button 
                    onPress={() => {
                        resolve && validSeed && resolve(selectedSeed as number[])
                    }} 
                    mode={validSeed ? 'primary' : 'passive'} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={StringTable.getString('Swap')}
                />
            </Section>            
        </View>
    )
}
