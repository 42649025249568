import React from 'react';
import { View } from 'react-native';
import Icon from './helpers/Icon';
import useTheme from './theme/useTheme';

export interface CheckMarkProps {
    checked?: boolean,
    passive?: boolean,
    style?: any
}

export default function CheckMark(props: CheckMarkProps) {

    const {
        checked,
        passive,
        style : styleProp,
    } = props;

    const theme = useTheme();
    return(
        <View 
            style={[{
                width: 32, 
                height: 32, 
                borderRadius: 16, 
                borderWidth: 1, 
                borderColor: checked ? theme["color-primary"] : theme["color-inksoft"],
                backgroundColor: checked ? theme["color-primary"] : "transparent",
                alignItems: 'center',
                justifyContent: 'center'
            }, styleProp]}
        >
            { (checked) ? (
                <Icon 
                    size={28} 
                    name={"ico_check"}
                    color={passive ? theme["color-inksoft"] : theme["color-ink-contained"]}
                />                    
            ) : null }
        </View>
    )
}