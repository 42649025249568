import React from 'react';
import { View } from 'react-native';
import { useTheme, IconButton, Subheader } from 'components';

interface CloseHeaderProps {
    title?: string, 
    onDismiss: () => any,
    style?: any
}
export default function CloseHeader(props: CloseHeaderProps) {

    const {
        title,
        onDismiss,
        style
    } = props;

    const theme = useTheme();

    return (
        <View style={[{paddingBottom: theme["spacing-small"], flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}, style]}>
                <IconButton name={'ico_clear'} style={{marginRight: theme["spacing-large"]}} onPress={onDismiss}/>
                <Subheader>{title}</Subheader>
                <IconButton name={'ico_clear'} style={{marginLeft: theme["spacing-large"], opacity: 0}}/>
        </View>
    );
}
