//@ts-nocheck
import { useState, useRef, useEffect, useMemo } from 'react';
import { Club, Competition, Group, Conversation } from 'business';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import parse from './parse';
import save from './save';

export default function useDocumentInstances<T extends Club | Group | Competition | Conversation>(collectionId: string, documentIds?: string[], defaultData?: ICompetition | IClub | IGroup) : (T | null)[] | undefined {

    const [instances, setInstances] = useState();
    const stableDocumentIds = useMemo(() => documentIds, [documentIds && JSON.stringify(documentIds)]);

    const statelessInstances = useRef<{[key: string]: any}>({});
    function onInstanceChange(instanceId: string, instance: T | null) {

        statelessInstances.current[instanceId] = instance;        
        if (Object.keys(statelessInstances.current).length === documentIds?.length) {
            setInstances(Object.values(statelessInstances.current));
        }
    }

    useEffect(() => {

        const _classes = {
            "groups": Group,
            "clubs": Club,
            "competitions": Competition,
            "conversations": Conversation
        }
        const _class = _classes[collectionId as keyof typeof _classes];

        setInstances([]);
        statelessInstances.current = {};

        const unsubscribers = [];
        stableDocumentIds && stableDocumentIds.forEach((documentId: string) => {
            unsubscribers[documentId] = collectionId && documentId && onSnapshot(doc(getFirestore(), collectionId, documentId), snap => {
       
                if (snap.exists()) {
                    onInstanceChange(documentId, new _class(parse(collectionId, snap), save));
                }
                else if (defaultData) {
                    onInstanceChange(documentId, new _class(defaultData, save));
                }
                else {
                    onInstanceChange(documentId, null);
                }
            });
        });

        return () => {
            unsubscribers && unsubscribers.forEach(unsubscriber => unsubscriber());
        }
    }, [collectionId, stableDocumentIds]);

    return instances;    
}
