import React from 'react';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { User } from 'business';
import { Paragraph } from 'components';
import { Factory } from '../../factories';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import CreateClubSheet from './sheets/CreateClubSheet';
import MessageSheet from './sheets/MessageSheet';

export default async function addClub(e: IPressEvent, pushModal: any, popAllModals: any, currentUser?: User) {

    if (!currentUser) { return };

    //@ts-ignore
    const clubSettingsResult : ICreateClubSheetResult | null = await pushModal((
        <CreateClubSheet
            title={StringTable.getString('Create club')}
            doneLabel={StringTable.getString('Create')}
            style={{flex: 1}}
        />
    ), {...e, pageY: 0});

    let clubId;
    if (clubSettingsResult) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Saving')}/>
        ), e);

        try {
            clubId = await Factory.makeClub(clubSettingsResult.settings, { admin: [currentUser.id] }, clubSettingsResult.id);
            await pushModal((
                <MessageSheet
                    showConfirmButton={true}
                    confirmButtonLabel={StringTable.getString('OK')}
                >
                    <Paragraph>{StringTable.getString('Your club was saved and will become public following a successful review')}</Paragraph>
                </MessageSheet>
            ), e);
        } catch(err) {
            await pushModal((
                <MessageSheet
                    showConfirmButton={true}
                    confirmButtonLabel={StringTable.getString('OK')}
                >
                    <Paragraph>{StringTable.getString('There was an error saving your club')}</Paragraph>
                </MessageSheet>
            ), e);    
        }
    }
    popAllModals();
    return clubId;

}
