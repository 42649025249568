import React, { useState } from 'react';
import { View, FlatList, ScrollView } from 'react-native';
import { useTheme, Section, TextInput, Button, Paragraph } from 'components';
import { searchAsync, createFromInstance, getInstances, getInstance, useBatchedQuery } from 'bridge';
import { Competition, Club, User, Participation, Conversation } from 'business';
import { CompetitionCard } from '../../composites';
import { IPressEvent } from 'interfaces';
import MenuSheet from '../../actions/sheets/MenuSheet';
import { arrayUnique } from 'tools';
import useModal from '../../actions/useModal';
interface Props {
    app?: any, 
    devApp: any,
    style?: any
}
export default function EventsList(props: Props) {

    const { app, devApp, style } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<Competition[]>();
    const [competitions, onLoadMoreCompetitions] = useBatchedQuery<Competition>({queryType: 'allCompetitions'}, app);

    const { pushModal, popAllModals } = useModal();

    async function search() {
        const i = await searchAsync<Competition>('competitions', { searchQuery: searchQuery}, app);
        setSearchResults(i);    
    }
    
    async function onPressInstance(e: IPressEvent, instance: Competition) {

        const sheetResult = await pushModal((
            <MenuSheet title={instance.settings.title || 'No instance title'}>
                <Button 
                    label={instance.state.featured ? 'Unfeature' : 'Feature'} 
                    style={{marginTop: theme["spacing-small"]}}
                    onPress={() => {
                        instance.setFeatured(instance.state.featured ? false : true)
                        popAllModals();
                    }}
                />
                <Button 
                    label={'Clone to prank-dev'} 
                    onPress={() => {
                        cloneInstanceToDev(instance);
                        popAllModals();
                    }}
                />
            </MenuSheet>
        ), e)
        popAllModals();
    }

    async function _cloneClub(clubRef?: string) {
        if (clubRef) {
            //@ts-ignore
            const club : Club = await getInstance('clubs', clubRef, app);
            if (club) {
                try {
                    console.log('create club from instance', club.id);
                    await createFromInstance(club, devApp);
                } catch(err) {
                    console.log('could not clone club', club.id);
                }
            }
        }
    }

    async function _cloneConversations(conversationRefs: (string | undefined)[]) {
        console.log('clone conversations', conversationRefs);
        for (const conversationRef of conversationRefs) {
            if (conversationRef) {
                //@ts-ignore
                const conversation : Conversation = await getInstance('conversations', conversationRef, app);
                console.log('start cloning conversation', conversation);
                if (conversation) {
                    try {
                        console.log('create conversation from instance', conversation.id);
                        await createFromInstance(conversation, devApp);
                    } catch(err) {
                        console.log('could not clone conversation', conversation.id);
                    }
                }
            }
        };
    }

    async function _cloneParticipatingUsers(participations: Participation[]) {
        const userRefs = participations.reduce((acc: string[], curr: Participation) => {
            return [...acc, ...(curr.admin || []), ...(curr.userRefs || []), ...(curr.reservesRefs || []), ...(curr.invitedRefs || [])]
        }, []);

        //@ts-ignore
        const userInstances : User[] = await getInstances('users', arrayUnique(userRefs), app);
        if (userInstances.length) {
            for (const user of userInstances) {
                try {
                    if (user) {                        
                        console.log('create user from instance', user.id);
                        await createFromInstance(user, devApp);
                    }
                    else {
                        console.log('failed to read user instance');
                    }
                } catch(err) {
                    console.log('could not clone user', user);
                }    
            }
        }
    }

    async function _cloneSubCompetitions(parentCompetitionRef: string) {
        const competitions = await searchAsync<Competition>('competitions', { parentCompetitionRef });
        if (competitions) {
            for (const competition of competitions) {
                await createFromInstance(competition, devApp);
                await _cloneParticipatingUsers([competition.participation]);
            }
        }
    }

    async function _cloneSuperSchedule(superScheduleRef: string) {
        const schedule = await getInstance('schedules', superScheduleRef);
        if (schedule) {
            await createFromInstance(schedule, devApp);
        }
    }

    async function cloneInstanceToDev(instance: any) {

        createFromInstance(instance, devApp);
        const competition : Competition = instance;
        competition?.settings.type === 'BIG TOURNAMENT' && await _cloneSubCompetitions(competition.id);
        competition?.superScheduleRef && await _cloneSuperSchedule(competition.superScheduleRef);
        await _cloneParticipatingUsers([competition.participation]);
        await _cloneConversations([competition.settings.conversationRef]);
        await _cloneClub(competition.settings.clubRef);
    }

    const theme = useTheme();
    return(
        <View style={[style, {flex: 1}]}>
            <Section size={'S'} style={{flexDirection: 'row'}}>
                <TextInput
                    style={{flex: 1}}
                    iconLeft={'ico_search'} 
                    iconRight={'ico_clear'} 
                    placeholder={`Query...`} 
                    value={searchQuery}
                    onChangeText={(t: string) => {
                        setSearchQuery(t);
                    }}
                    onPressIconRight={() => setSearchQuery('')}
                />
                <Button label={'Search'} onPress={search}/>
            </Section>

            <View style={{flex: 1}}>
                {searchResults?.length ? null : (
                    <FlatList
                        style={{flex: 1}}
                        data={competitions}
                        keyExtractor={item => item.id}
                        onEndReached={onLoadMoreCompetitions}
                        onEndReachedThreshold={0.1}
                        renderItem={({item}) => (
                            <CompetitionCard style={{marginTop: 12}} competition={item} onPress={(e: any) => onPressInstance(e, item)}/>
                        )}
                    />
                )}

                {searchResults?.length ? (
                    <ScrollView style={{flex: 1}}>
                    { searchResults.map((instance: Competition) => (
                            <View key={instance.id} style={{marginTop: theme["spacing-small"]}}>
                                <CompetitionCard style={{marginTop: 12}} competition={instance} onPress={(e: any) => onPressInstance(e, instance)}/>
                            </View>
                        ))}
                    </ScrollView>
                ) : null }

            </View>

            { searchResults?.length === 0 ? (
                <Paragraph>{'No search results'}</Paragraph>
            ) : null }
            
        </View>
    )
}
