//@ts-nocheck
import React, { useRef } from 'react';
import { View, Animated, StyleSheet } from 'react-native';
import Svg, { Path, Defs, ClipPath, Image } from 'react-native-svg';
import useThemeStyle from './theme/useThemeStyle';
import { createId } from './helpers/utils';
import favIcon from '../../assets/favicon.png';
import favIconBackground from '../../assets/faviconbackground.png';
import useCachedBase64 from './imagecache/useCachedBase64';

export interface PadelProps {
    size?: string,
    strokeColor?: string, 
    strokeWidth?: number, 
    strokeDashed?: boolean, 
    fillColor?: string, 
    showLogo?: boolean, 
    imageBackground?: string, 
    logoBackground?: boolean,
    style?: any, 
    children?: any, 
}

export default function Padel(props: PadelProps) {

    const {
        strokeColor,
        strokeWidth, 
        strokeDashed,
        fillColor,
        style : styleProp,
        children,
        showLogo,
        imageBackground : imageBackgroundProp,
        logoBackground,
        ...otherProps
    } = props;
    
    const style = useThemeStyle("Padel", props, null);
    const id = useRef(createId(8)).current;

    const imageBase64 = useCachedBase64(imageBackgroundProp);

    return(
        <Animated.View style={[layout.container, style, styleProp]} {...otherProps}>
            <View 
                style={{width: '100%', height: '100%', position: 'absolute', zIndex: -1, elevation: -1, overflow: 'visible'}}
            >
                <Svg 
                    width={'100%'} 
                    height={'100%'} 
                    viewBox={`-${strokeWidth || 0} -${strokeWidth || 0} ${48 + 2*(strokeWidth || 0)} ${48 + 2*(strokeWidth || 0)}`}
                >
                    <Defs>
                        <ClipPath id={`imageClipPath${id}`} clipPathUnits={"userSpaceOnUse"}>
                            <Path d="M48 19.6364C48 32.8912 37.2548 48 24 48C10.7452 48 0 32.8912 0 19.6364C0 6.38153 10.7452 0 24 0C37.2548 0 48 6.38153 48 19.6364Z"/>
                        </ClipPath>
                    </Defs>

                    { imageBase64 || showLogo || logoBackground ? (
                        <Image 
                            x="-2%"
                            y="-2%"
                            width="104%" 
                            height="104%" 
                            href={imageBase64 || (logoBackground ? favIconBackground : favIcon)} 
                            clipPath={`url(#imageClipPath${id})`}
                            preserveAspectRatio="xMidYMid slice"
                        />
                    ) : null }
                    
                    <Path 
                        fill={fillColor || 'none'}
                        stroke={strokeColor} 
                        strokeWidth={strokeWidth}
                        strokeDasharray={strokeDashed ? "5,3" : undefined}
                        d="M48 19.6364C48 32.8912 37.2548 48 24 48C10.7452 48 0 32.8912 0 19.6364C0 6.38153 10.7452 0 24 0C37.2548 0 48 6.38153 48 19.6364Z" 
                    />
                </Svg>

            </View>
            {children}
        </Animated.View>
    )
}

const layout = StyleSheet.create({
    container: {
        justifyContent: 'center', 
        alignItems: 'center'
    }
})
