import React from 'react';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Club, User } from 'business';
import ReviewSheet from './sheets/ReviewSheet';

export default async function reviewClub(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User | null) {

    const club : Club = target?.club;
    if (!club || !currentUser) { return };

    const reviewResult : number | null = await pushModal((
        <ReviewSheet
            initialReview={club.reviews?.[currentUser.id] || undefined}
            title={`${StringTable.getString('Review')} ${club.settings.getTitle()}`}
        />
    ), e);

    if (reviewResult) {
        club.setReview(currentUser?.id, reviewResult);
    }
    popAllModals();
}
