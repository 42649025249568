import React, { ReactElement } from 'react';
import { View, ActivityIndicator, Dimensions, ScrollView } from 'react-native';
import { useTheme, Section, Caption, Paragraph } from 'components';
import { StringTable } from 'utils';
import { useUsers } from 'bridge';
import { Competition, Club, Match, Group, CompetitionGroup } from 'business';
import MatchCard from '../composites/MatchCard';
import UserCard from '../composites/UserCard'; 
import TableView from '../composites/TableView';
import { useStyles } from '../hooks';

interface Props {
    competition: Competition, 
    group?: Group | null,
    club?: Club | null, 
    ListHeaderComponent?: ReactElement,
    palette: {[competitionId: string]: string}
}

export default function LegacyScheduleView(props: Props) {

    const { competition, ListHeaderComponent, palette } = props;
    const users = useUsers(competition?.participation.userRefs);

    const { state } = competition;

    const theme = useTheme();
    const styles = useStyles();

    return (
        <ScrollView style={{flex: 1, backgroundColor: theme["color-paper"]}}>
            <View style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, justifyContent: 'center', alignItems: 'center', backgroundColor: theme["color-paper"]}}>
                <ActivityIndicator color={theme["color-ink"]}/>
            </View>
            { ListHeaderComponent }
            <View style={{maxWidth: 500, backgroundColor: theme["color-negative"], marginTop: 12, padding: 12, alignItems: 'center', justifyContent: 'center'}}>
                <Paragraph weight={'bold'} style={{color: 'white'}}>{StringTable.getString('WARNING')}</Paragraph>
                <Paragraph style={{color: 'white'}}>{StringTable.getString('This event was created with an old version of Padel iD')}</Paragraph>
                <Paragraph style={{color: 'white'}}>{StringTable.getString('No interactions are possible')}</Paragraph>
            </View>
            {state.closed && state.winners ? (
                <Section size={'L'} style={{paddingHorizontal: theme["spacing-medium"]}} pointerEvents={'none'}>
                    <Caption>{StringTable.getString('WINNERS')}</Caption>
                    {users && state.winners?.map(winnerRef => (
                        <UserCard 
                            key={winnerRef} 
                            userRef={winnerRef}
                            style={{marginHorizontal: 0}}        
                        />
                    ))}
                </Section>
            ) : null }

            <Section size={'L'} style={{paddingHorizontal: theme["spacing-medium"]}} pointerEvents={'none'}>
                <Caption>{StringTable.getString('RESULTS')}</Caption>
                <ScrollView
                    horizontal={true}
                    pagingEnabled={true}
                    pointerEvents={'none'}
                >
                    {competition?.groups?.map((group: CompetitionGroup, groupIndex: number) => (
                        <View key={groupIndex} style={{width: Math.min(500, Dimensions.get('window').width - theme["spacing-medium"] * 2)}}>
                            <TableView 
                                competition={competition}
                                groupIndex={groupIndex}
                                color={palette[competition.id]}
                                table={group.table}
                                style={{marginHorizontal: 0, width: '100%'}}
                            />
                        </View>
                    ))}
                </ScrollView>
            </Section>

            <Section size={'L'} style={{paddingHorizontal: theme["spacing-medium"]}} pointerEvents={'none'}>
                <Caption>{StringTable.getString('MATCHES')}</Caption>
                <View pointerEvents={'none'}>
                    {competition?.getAllMatches().map((match: Match, matchIndex: number) => (
                        <View style={[styles.scheduleCell, {maxWidth: 500}]} key={match.id}>
                            <MatchCard 
                                match={match} 
                                competition={competition}
                                editable={false}
                            />
                        </View>
                    ))}            
                </View>
            </Section>
        </ScrollView>
    )
}
