import { getMexicano, getRoundrobin, getAmericano, tiebreakers, getWinnersLosers } from './helpers';
import { CompetitionGroup } from './CompetitionGroup';
import { Playoff } from './Playoff';
import { Match } from './Match';
import { Schedule } from './Schedule';
import { Participation } from './Participation';
import { Settings } from './Settings';
import { State } from './State';
import extractSearchTokens from './extractSearchTokens';
import getVersion from './getVersion';
import { arrayShuffle, arrayUnique } from '../tools/tools';
import { ICompetition, ICompetitionMeta, ICompetitionSettings, ISuperScheduleCourt, ICourt, TableRow } from '../interfaces';

export class Competition implements ICompetition {

    id: string;
    collection: string;
    settings: Settings;
    participation: Participation;
    groups?: CompetitionGroup[];
    playoff?: Playoff;
    state: State;
    schedule?: Schedule;
    superScheduleRef?: string;
    log?: string;

    saveFunc: any;

    constructor(data: ICompetition, saveFunc?: any) {


        this.id = data.id;
        this.collection = "competitions";
        this.saveFunc = saveFunc || this.defaultSaveFunc;
        this.settings = new Settings(data.settings);
        this.participation = new Participation(data.participation);
        this.participation.setSlots(this.settings.slots);
        this.groups = data.groups && data.groups.map(g => new CompetitionGroup(g));
        this.playoff = data.playoff && new Playoff(data.playoff);
        this.state = new State(data.state || {});
        this.schedule = data.schedule && new Schedule(data.schedule, (matchId: string) => this.getMatch(matchId));
        this.superScheduleRef = data.superScheduleRef;
        this.log = data.log;        
    }

    defaultSaveFunc(f: (self: Competition) => void, asTransaction: boolean = false) {
        f(this);
    }

    serialize() : ICompetition {
        return {
            id: this.id,
            settings: this.settings.serialize(), 
            participation: this.participation.serialize(),
            state: this.state.serialize(),
            groups: this.groups?.map(g => g.serialize()), 
            playoff: this.playoff?.serialize(),
            schedule: this.schedule?.serialize(),
            superScheduleRef: this.superScheduleRef,
            log: this.log
        }
    }

    meta() : ICompetitionMeta {
        return {
            id: this.id,
            recipients: this.participation.getRecipients(),
            admin: this.participation.getAdmin(),
            invitedRefs: this.participation.invitedRefs,
            date: this.settings.date, 
            lastModifiedDate: new Date(),
            public: this.settings.public,
            featured: this.state?.featured || false, 
            region: this.settings?.location?.region,
            clubRef: this.settings?.clubRef,
            groupRef: this.settings?.groupRef,
            parentCompetitionRef: this.settings?.parentCompetitionRef || false,
            repeat: this.settings?.repeat,
            searchTokens: extractSearchTokens(this.settings.title || this.settings.type),
            version: getVersion()
        }
    }

    /**
     * Update settings and slots
     * @param settingsData 
     */
    async updateSettings(settingsData: Partial<ICompetitionSettings>) {
        await this.saveFunc((self: Competition) => {
            settingsData.hasOwnProperty('slots') && self.onUpdateSlots(settingsData.slots);
            self.onUpdateSettings(settingsData);
        })
    }
    onUpdateSlots(slots?: number) {
        this.participation.setSlots(slots);
        if (!slots || (slots > (this.participation.userRefs?.length || 0))) {
            this.state.full = false;
        }
        else {
            this.state.full = true;
        }
    }
    onUpdateSettings(settingsData: Partial<ICompetitionSettings>) {
        this.settings.update(settingsData);
    }
    
    /**
     * Users added by someone else
     * @param userRefs 
     */
    add(userRefs: string[]) {           
        this.saveFunc((self: Competition) => {
            self.onAdd(userRefs);
        });
    }
    onAdd(userRefs: string[]) {
        userRefs && this.participation.add(userRefs);
        this.state.full = this.participation.slots && ((this.participation.userRefs?.length || 0) >= this.participation.slots) ? true : false;
    }

    swapSeed(targetSeed: number[], replacementSeed: number[], playoffMatchId: string) {
        this.saveFunc((self: Competition) => {
            self.onSwapSeed(targetSeed, replacementSeed, playoffMatchId);
        })
    }
    onSwapSeed(targetSeed: number[], replacementSeed: number[], playoffMatchId: string) {
        const targetPlayoff = this.playoff?.findPlayoffWithMatch(playoffMatchId);
        if (targetPlayoff) {
            targetPlayoff.swapSeed(targetSeed[0], replacementSeed[0]);
            targetPlayoff.swapSeed(targetSeed[1], replacementSeed[1]);
        }
    }

    swapTeams(matches: Match[], targetTeam: [string, string], replacementTeam: [string, string], updateGroups?: boolean): Match[] | undefined {
        let retVal;
        this.saveFunc((self: Competition) => {
            retVal = self.onSwapTeams(matches, targetTeam, replacementTeam, updateGroups);
        })
        return retVal;
    }
    /**
     * Written by ChatGPT
     * @param matches 
     * @param targetTeam 
     * @param replacementTeam 
     */
    onSwapTeams(matches: Match[], targetTeam: [string, string], replacementTeam: [string, string], updateGroups?: boolean): Match[] {

        const [targetTeamARef, targetTeamBRef] = targetTeam;
        const [replacementTeamARef, replacementTeamBRef] = replacementTeam;

        const withTargetTeam = matches.filter(
            (match) => match.userRefs.includes(targetTeamARef) && match.userRefs.includes(targetTeamBRef)
        ).map(
            (match) => ({ 
                match: match, 
                indexA: match.userRefs.indexOf(targetTeamARef), 
                indexB: match.userRefs.indexOf(targetTeamBRef) 
            })
        );

        const withReplacementTeam = matches.filter(
            (match) => match.userRefs.includes(replacementTeamARef) && match.userRefs.includes(replacementTeamBRef)
        ).map(
            (match => ({
                match: match, 
                indexA: match.userRefs.indexOf(replacementTeamARef), 
                indexB: match.userRefs.indexOf(replacementTeamBRef)
            }))
        )

        function swapTeamHelper(userRefs: (string | null)[], aIndex: number, bIndex: number, replacementA: string, replacementB: string) {
            userRefs[aIndex] = replacementA;
            userRefs[bIndex] = replacementB;
        }

        withTargetTeam.forEach((entry) => {
            const { userRefs } = entry.match;
            const targetTeamAIndex = entry.indexA;
            const targetTeamBIndex = entry.indexB;

            if (targetTeamAIndex !== -1 && targetTeamBIndex !== -1) {
                swapTeamHelper(userRefs, targetTeamAIndex, targetTeamBIndex, replacementTeamARef, replacementTeamBRef);
            }
        });

        withReplacementTeam.forEach((entry) => {
            const { userRefs } = entry.match;
            const replacementTeamAIndex = entry.indexA;
            const replacementTeamBIndex = entry.indexB;

            if (replacementTeamAIndex !== -1 && replacementTeamBIndex !== -1) {
                swapTeamHelper(userRefs, replacementTeamAIndex, replacementTeamBIndex, targetTeamARef, targetTeamBRef);
            }
        });

        if (updateGroups) {            
            const groupWithTargetTeam = this.groups?.find(g => g.userRefs.includes(targetTeamARef) && g.userRefs.includes(targetTeamBRef));
            const groupWithReplacementTeam = this.groups?.find(g => g.userRefs.includes(replacementTeamARef) && g.userRefs.includes(replacementTeamBRef));

            if (groupWithTargetTeam && groupWithTargetTeam !== groupWithReplacementTeam) {
                swapTeamHelper(groupWithTargetTeam.userRefs, groupWithTargetTeam.userRefs.indexOf(targetTeamARef), groupWithTargetTeam.userRefs.indexOf(targetTeamBRef), replacementTeamARef, replacementTeamBRef);
            }
            groupWithTargetTeam && groupWithTargetTeam.updateTable(this.settings.points);

            if (groupWithReplacementTeam && groupWithReplacementTeam !== groupWithTargetTeam) {
                swapTeamHelper(groupWithReplacementTeam.userRefs, groupWithReplacementTeam.userRefs.indexOf(replacementTeamARef), groupWithReplacementTeam.userRefs.indexOf(replacementTeamBRef), targetTeamARef, targetTeamBRef);
            }
            groupWithReplacementTeam && groupWithReplacementTeam.updateTable(this.settings.points);
        }


        if (this.participation.userRefs) {
            this.participation._swap(targetTeamARef, replacementTeamARef);
            this.participation._swap(targetTeamBRef, replacementTeamBRef);
        }

        return arrayUnique([...withTargetTeam.map(w => w.match), ...withReplacementTeam.map(w => w.match)]);

    }

    _swapPlayer(matches: Match[], targetPlayer: string, replacementPlayer: string, options?: { updateTables?: boolean, preservePlayingStatus?: boolean, log?: boolean }): Match[] | undefined {
        let retVal;
        this.saveFunc((self: Competition) => {
            retVal = self.onSwapPlayer(matches, targetPlayer, replacementPlayer, options);
        })
        return retVal;
    }
    /**
     * Written by ChatGPT
     * @param matches 
     * @param targetPlayer 
     * @param replacementPlayer 
     */
    onSwapPlayer(matches: Match[], targetPlayer: string, replacementPlayer: string, options?: { updateTables?: boolean, preservePlayingStatus?: boolean, log?: boolean }): Match[] {
        
        const withTargetPlayer = matches.filter(
            (match) => match.userRefs.includes(targetPlayer)
        ).map(
            (match) => ({ match: match, index: match.userRefs.indexOf(targetPlayer) })
        );
        
        const withReplacementPlayer = matches.filter(
            (match) => match.userRefs.includes(replacementPlayer)
        ).map(
            (match) => ({ match: match, index: match.userRefs.indexOf(replacementPlayer) })
        );

        function swapPlayerHelper(userRefs: (string | null)[], index: number, replacement: string) {
            userRefs[index] = replacement;
        }

        withTargetPlayer.forEach((entry) => {
            const { userRefs } = entry.match;
            const targetPlayerIndex = entry.index;
            swapPlayerHelper(userRefs, targetPlayerIndex, replacementPlayer);
        });

        withReplacementPlayer.forEach((entry) => {
            const { userRefs } = entry.match;
            const replacementPlayerIndex = entry.index;
            swapPlayerHelper(userRefs, replacementPlayerIndex, targetPlayer);
        });

        if (options?.updateTables) {
            const groupWithTargetPlayer = this.groups?.find(g => g.userRefs.includes(targetPlayer));
            const groupWithReplacementPlayer = this.groups?.find(g => g.userRefs.includes(replacementPlayer));

            const targetPlayerIndex = groupWithTargetPlayer?.userRefs.indexOf(targetPlayer);
            const replacementPlayerIndex = groupWithReplacementPlayer?.userRefs.indexOf(replacementPlayer);

            if (groupWithTargetPlayer && targetPlayerIndex !== undefined) {
                const { userRefs } = groupWithTargetPlayer;
                swapPlayerHelper(userRefs, targetPlayerIndex, replacementPlayer);

                groupWithTargetPlayer.updateTable(this.settings.points);
            }

            if (groupWithReplacementPlayer && replacementPlayerIndex !== undefined) {
                const { userRefs } = groupWithReplacementPlayer;
                swapPlayerHelper(userRefs, replacementPlayerIndex, targetPlayer);

                groupWithReplacementPlayer.updateTable(this.settings.points);
            }
        }

        if (options?.log) {
            console.log(`swapping ${targetPlayer} for ${replacementPlayer} with preservation status ${options?.preservePlayingStatus}, pre userRefs`, JSON.stringify(this.participation.userRefs));
        }

        if (options?.preservePlayingStatus) {
            this.participation._inject(replacementPlayer);
        }
        else {
            this.participation._swap(targetPlayer, replacementPlayer);
        }

        return arrayUnique([...withTargetPlayer.map(w => w.match), ...withReplacementPlayer.map(w => w.match)]);
    }

    async setUserRefs(userRefs: string[]) {
        await this.saveFunc(async(self: Competition) => {
            await self.onSetUserRefs(userRefs);
        })
    }
    async onSetUserRefs(userRefs: string[]) {
        this.participation.userRefs = userRefs;
    }



    /**
     * Users added by themselves or their partner
     * @param userRefs 
     */
    join(userRefs?: string | string[]) {
        this.saveFunc((self: Competition) => {
            self.onJoin(userRefs);
        }, true);
    }

    onJoin(userRefs?: string | string[]) {
        userRefs && this.participation.join(userRefs);
        this.state.full = this.participation.slots && ((this.participation.userRefs?.length || 0) >= this.participation.slots) ? true : false;
    }

    /**
     * Users removed by themselves or someone else
     * @param userRef 
     */
    leave(userRef?: string) {
        const partnerRef = this.settings.teamSignup ? this.participation.getPartner(userRef) : null;
        this.saveFunc((self: Competition) => {
            self.onLeave(userRef, partnerRef);
        })
    }
    onLeave(userRef?: string, partnerRef?: string | null) {
        userRef && this.participation.leave(userRef, partnerRef);
        this.state.full = this.participation.slots && ((this.participation.userRefs?.length || 0) >= this.participation.slots) ? true : false;
    }

    /**
     * Invitations
     * @param userRefs 
     */
    invite(newInvitations: string[] | undefined, removedInvitations?: string[] | undefined) {

        this.saveFunc((self: Competition) => {
            self.onInvite(newInvitations, removedInvitations);
        })
    }
    onInvite(newInvitations: string[] | undefined, removedInvitations?: string[] | undefined) {
        this.participation.invite(newInvitations, removedInvitations);
    }

    /**
     * Revoke invitation
     * @param userRef 
     */
    uninvite(userRef: string) {
        this.saveFunc((self: Competition) => {
            self.onUninvite(userRef);
        })
    }
    onUninvite(userRef: string) {
        this.participation.uninvite(userRef);
    }

    /**
     * User declined invitation themself
     * @param userRef 
     */
    decline(userRef?: string) {
        this.saveFunc((self: Competition) => {
            self.onDecline(userRef);
        })
    }
    onDecline(userRef?: string) {
        this.participation.decline(userRef);
    }

    /**
     * User made admin by another
     * @param userRef 
     */
    makeAdmin(userRef: string) {
        this.saveFunc((self: Competition) => {
            self.onMakeAdmin(userRef);
        })
    }
    onMakeAdmin(userRef: string) {
        this.participation.makeAdmin(userRef);
    }    

    /**
     * Admin privileges revoked
     * @param userRef 
     */
    revokeAdmin(userRef: string) {
        this.saveFunc((self: Competition) => {
            self.onRevokeAdmin(userRef);
        })
    }
    onRevokeAdmin(userRef: string) {
        this.participation.revokeAdmin(userRef);
    }

    /**
     * User made admin by another
     * @param userRef 
     */
    makeAdmins(userRefs: string[]) {
        this.saveFunc((self: Competition) => {
            self.onMakeAdmins(userRefs);
        })
    }
    onMakeAdmins(userRefs: string[]) {
        this.participation.admin = userRefs;
    }


    async destroy() {
        await this.saveFunc(async(self: Competition) => {
            await self.onDestroy();
        })
    }

    async onDestroy() {
    }

    /**
     * 
     */
    setRepeat(flag: boolean) {
        this.saveFunc((self: Competition) => {
            self.onSetRepeat(flag);
        })
    }
    onSetRepeat(flag: boolean) {
        this.settings.setRepeat(flag);
    }

    setPublic(flag: boolean) {
        this.saveFunc((self: Competition) => {
            self.onSetPublic(flag); 
        })
    }
    onSetPublic(flag: boolean) {
        this.settings.setPublic(flag);
    }

    setRequiredMatchCourt(matchId: string, courtId?: string) {
        this.saveFunc((self: Competition) => {
            self.onSetRequiredMatchCourt(matchId, courtId);
        })
    }
    onSetRequiredMatchCourt(matchId: string, courtId?: string) {
        const match = this.getMatch(matchId);
        if (match) {
            match.requiredCourtId = courtId;
        }
    }

    setGroup(groupRef?: string) {
        this.saveFunc((self: Competition) => {
            self.onSetGroup(groupRef);
        })
    }
    onSetGroup(groupRef?: string) {
        this.settings.groupRef = groupRef;
    }

    async start() {
        await this.saveFunc((self: Competition) => {
            self.onStart();
        })
    }
    onStart() {
        this.state.start();
    }

    async pause() {
        await this.saveFunc((self: Competition) => {
            self.onPause();
        })
    }
    onPause() {
        this.state.pause();
    }

    async clear() {
        await this.saveFunc((self: Competition) => {
            self.onClear();
        });
    }

    onClear() { 
        delete this.groups;
        delete this.playoff;
        delete this.schedule;
        delete this.superScheduleRef;
        this.state.clear();
    }

    close() {
        if (this.state.closed) { return };

        const winners = this.getWinners();
        this.saveFunc((self: Competition) => {
            self.onClose(winners);
        });
    }
    onClose(winners?: string[]) {
        this.state.close(winners);
    }

    reOpen() {
        if (!this.state.closed) { return };
        this.saveFunc((self: Competition) => {
            self.onReOpen();
        })
    }
    onReOpen() {
        this.state.reOpen();
    }

    async makeMatches() {
        await this.saveFunc((self: Competition) => {
            self.makeMatchesWithoutSaving();
        })
    }

    makeMatchesWithoutSaving() {
        const {
            groupCount, 
            playoffStages, 
            playoffSize,
            type
        } = this.settings;

        const userRefs = this.participation.userRefs && [...this.participation.userRefs];
        if (!userRefs) {
            return;
        }

        this.settings.date = this.settings.date || new Date();

        //Discard any previous data
        this.groups = []; 
        delete this.playoff;

        //Create groups
        const groupsizes = this.getGroupSizes();

        for (let i = 0; i < (groupCount || 1); i++) {
            const players = userRefs.splice(0, groupsizes[i]);
            
            let matches;
            switch (type) {
                case "MATCH":
                case "AMERICANO":
                case "TEAM AMERICANO":
                    matches = (type === "TEAM AMERICANO" || this.settings.fixedTeams) ? getRoundrobin(players, this.id) : getAmericano(players, this.id);
                    break;
                case "TOURNAMENT":
                    matches = getRoundrobin(players, this.id);
                    break;
                case "WINNERS COURT":
                case "TEAM WINNERS COURT":
                    matches = getMexicano(players, this.id);
                    break;
                case 'BIG TOURNAMENT':
                    break;
                default:
                    throw "Bad CompetitionType";
            }
            if (Array.isArray(matches)) {
                for (const match of matches) {
                    match.competitionId = this.id;
                    match.points = this.settings.points;
                    match.groupIndex = this.groups.length;
                };
                this.groups.push(new CompetitionGroup({userRefs: players, matches}));
            }
        }

        if (type === "TOURNAMENT") { 
            this.playoff = new Playoff({
                stage: playoffStages || 'Semifinals', 
                teamCount: playoffSize ? playoffSize : Playoff.deriveTeamCount(playoffStages === 'Numeric' ? 'Semifinals' : (playoffStages || 'Semifinals')), 
                playoffRound: 1, 
                competitionId: this.id, 
                points: this.settings.points,
                seedDescriptions: this.getQualifiedTeamDescriptions()
            });
        }
    }

    setSuperScheduleRef(ref?: string) {
        this.saveFunc((self: Competition) => {
            this.onSetSuperScheduleRef(ref);
        })
    }

    onSetSuperScheduleRef(ref?: string) {
        this.superScheduleRef = ref;
        this.state.scheduled = ref ? true : false;
    }

    getWinners() : (string)[] | undefined {
        //@ts-ignore
        return this.playoff?.getWinners() || (this.groups?.length === 1 ? this.groups[0].getLeaders(this.settings.fixedTeams ? true : false) : undefined);
    }

    getAllMatches() {
        const matches : Match[] = [];

        if (this.groups) {
            for (let i = 0; i < Math.max(...this.groups.map(g => g.matches.length)); i++) {
                this.groups.forEach(group => group.matches[i] && matches.push(group.matches[i]));
            }
        }

        if (this.playoff) {
            matches.push(...this.playoff.getAllMatches());
        }
        return matches;
    }

    getCourts() : ISuperScheduleCourt[] {
        const courts = this.settings.courts;
        return courts ? Object.values(courts).map((c: ICourt, index: number) => ({id: `${c.courtId}`, name: c.courtName || `Court ${index + 1}`})) : [{id: '1', name: 'Court 1'}];
    }

    getUnplayedMatches() {
        const matches = this.getAllMatches();
        return matches.filter(m => !m.hasResult());
    }

    async endMatch(matchId: string, endDate: Date, result?: number[]) {
        try {
            return await this.saveFunc(async (self: Competition) => {
                return await self.onEndMatch(matchId, endDate, result);
            })
        } catch(err) {
            console.log('endMatch error', err);
        }   
    }

    /**
     * Determines if a match in this competition can be ended, where ending a match means settings 
     * its result regardless of whether it has been ended before or not.
     * @param matchId 
     * @param result 
     */
    canEndMatch(matchId: string, result?: number[]) {

        //The winners court format creates new rounds based on previous results, so here a match 
        //cannot be ended if it would change the next round, if the next round already has registered
        //results.
        if (["WINNERS COURT", "TEAM WINNERS COURT"].includes(this.settings.type)) {
            const group = this.groups?.[0];
            if (!group) { return false; }

            const roundSize = group.userRefs.length / 4;
            const matchIndex = group.matches.findIndex((m: Match) => m.id === matchId);
            if (matchIndex === -1) { return false; }

            const matchRound = Math.floor(matchIndex / roundSize);
            if (group.matches.slice((matchRound + 1) * roundSize).some((m: Match) => m.hasResult())) {
                const match = this.getMatch(matchId);
                if (!match) { return false }
                const { winners: oldWinners } = getWinnersLosers(match, match.points);
                const { winners: newWinners } = getWinnersLosers({...match, result}, match.points);

                if (oldWinners && oldWinners[0] !== newWinners?.[0]) {
                    return false;
                }
            }
        }
        //The tournament format creates playoff rounds based on previous results, so here a match
        //cannot be ended if it would change the next playoff round and if the next playoff round
        //was started.
        else if (this.settings.type === "TOURNAMENT" && this.groups && this.playoff) {
            const group = this.groups.find((g: CompetitionGroup) => g.matches.find(m => m.id === matchId));
            if (group && this.playoff.hasStarted()) {
                return false;
            }
            const matchPlayoff = this.playoff.findPlayoffWithMatch(matchId);
            if (matchPlayoff?.winnerPlayoff?.hasStarted()) {
                return false;
            }
        }
        return true;
    }

    async onEndMatch(matchId: string, endDate: Date, result?: number[]) : Promise<Match[] | boolean> {

        const {
            type, 
            points
        } = this.settings;

        if (!this.canEndMatch(matchId, result)) { 
            return false;
        }

        const matchOwningGroup = this.groups?.find((g: CompetitionGroup) => g.matches.find(m => m.id === matchId));
        const matchOwningPlayoff = this.playoff?.hasMatch(matchId);

        matchOwningGroup?.endMatch(matchId, endDate, result) || matchOwningPlayoff?.endMatch(matchId, endDate, result);

        if (matchOwningGroup) {
            if (type && ["WINNERS COURT", "TEAM WINNERS COURT"].includes(type)) {
                const matches = getMexicano(matchOwningGroup.userRefs, this.id, matchOwningGroup.matches, type === "TEAM WINNERS COURT", points);
                if (matches !== matchOwningGroup.matches) {
                    const matchInstances = matches.map(m => new Match({...m, competitionId: this.id, points: this.settings.points, groupIndex: this.groups?.indexOf(matchOwningGroup)}));
                    matchOwningGroup.setMatches(matchInstances);
                    return matchInstances;
                }
            }
            else if (type === "TOURNAMENT") {
                const allGroupsFinished = this.groups?.some(group => group.isFinished() ? false : true) ? false : true;
                if (this.groups && allGroupsFinished && this.playoff) {
                    const qualifiedTeams = this.getQualifiedTeams(this.playoff.teamCount || Playoff.deriveTeamCount(this.playoff.stage));
                    qualifiedTeams && this.playoff.populate(qualifiedTeams);
                }
            }
        }
        return true;
    }

    /**
     * Returns the teams, as defined by items N and N+1 in a list of userRefs, where 
     * userRefs is fetched either from the group defined by groupIndex, or from the overall
     * participation.userRefs field. 
     * 
     * If the number of user refs in the competition or referenced group is uneven, it
     * returns only the full teams and skips the last player without a partner.
     * 
     * @param groupIndex 
     * @returns 
     */
    getTeams(groupIndex?: number) : [string, string][] | null {

        if (!this.participation.userRefs || !this.settings.fixedTeams) {
            return null;
        }
        const teams : [string, string][] = [];

        const userRefs = (typeof groupIndex === 'number' && this.groups?.[groupIndex]) ? this.groups[groupIndex].userRefs : this.participation.userRefs;
        const evenUserRefs = userRefs.slice(0, Math.floor(userRefs.length / 2) * 2)
        for (let i = 0; i < evenUserRefs.length; i += 2) {
            teams.push([evenUserRefs[i], evenUserRefs[i + 1]]);
        }

        return teams;
    }

    getQualifiedTeams(teamCount: number) {
        if (this.groups) {
            const tables : TableRow[][] = this.groups.map(g => [...(g.table || [])]).filter(t => !(t === null || t === undefined));
            const qualifiedTeams : string[][] = [];        
            /**
             * G = Number of groups, T = Number of qualified teams
             * 
             * First pick G * floor(T / G) teams, one at the time from each group such that:
             * Qualified = [GAT1, GBT1, GCT1, GAT2, GBT2, GCT2]
             * 
             * Then sort the groups on the remaining top team, and pick such that:
             * Qualified = [GAT1, GBT1, GCT1, GAT2, GBT2, GCT2, GXT3, GYT3]
             */
            const directQualifiedCount = tables.length * Math.floor(teamCount / tables.length);

            for (let i = 0; i < directQualifiedCount; i++) {
                const topTeam = tables[i % tables.length].splice(0, 2).map(tr => tr.id);
                topTeam && topTeam.length === 2 && qualifiedTeams.push(topTeam);
            }
            tables.sort((a: TableRow[], b: TableRow[]) => tiebreakers(a[0], b[0], null, this.settings.points ? true : false));
            for (let i = 0; i < teamCount - directQualifiedCount; i++) {
                const topTeam = tables[i % tables.length].splice(0, 2).map(tr => tr.id);
                topTeam && topTeam.length === 2 && qualifiedTeams.push(topTeam);
            }
            return qualifiedTeams;
        }
        return undefined;
    }

    getQualifiedTeamDescriptions() {

        const slotsCount = Playoff.deriveTeamCount(this.settings.playoffStages === 'Numeric' ? Playoff.deriveStage(this.settings.playoffSize) : (this.settings.playoffStages || 'Semifinals'));
        const teamsCount = this.settings.playoffSize || slotsCount;

        const descriptions : string[] = [];
        const groupCount = this.groups?.length || 1;
        const directQualifiedCount = groupCount * Math.floor(teamsCount / groupCount);
        const tiebreakerQualifiedCount = teamsCount - directQualifiedCount;
        const byeCount = slotsCount - teamsCount;

        for (let i = 0; i < directQualifiedCount; i++) {
            const position = Math.floor(i/groupCount);
            const group = i % groupCount;
            descriptions.push(`Group ${group + 1} Pos ${position + 1}`)
        }

        const position = Math.floor(directQualifiedCount / groupCount);
        for (let i = 0; i < tiebreakerQualifiedCount; i++) {
            descriptions.push(`Best Pos ${position + 1}`)
        }

        for (let i = 0; i < byeCount; i++) {
            descriptions.push('BYE');
        }

        return descriptions;
    }

    getMatch(matchId?: string) {
        if (!matchId) { return }
        if (this.groups) {
            for (const group of this.groups) {
                const match = group.matches.find(m => m.id === matchId);
                if (match) {
                    return match;
                }
            }
        }
        return this.playoff?.getMatch(matchId);
    }

    getMatchDescription(matchId: string) : { title?: string, home?: string, away?: string } {
        const index = this.groups ? this.groups.findIndex(g => g.matches.find(m => m.id === matchId)) : -1;
        if (index > -1) {
            return {title: `Group ${index + 1}`}
        }
        if (this.groups && this.playoff) {
            return this.playoff.getMatchDescription(matchId, this.groups.length)
        }
        else {
            return {};
        }
    }

    getGroupIndex(matchId?: string) {
        return this.groups ? this.groups.findIndex((g: CompetitionGroup) => g.matches.find(m => m.id === matchId)) : -1;
    }

    /**
     * Ignores odd players. Returns the size for each group, if groups don't end up even
     */
    getGroupSizes() {

        const teamCount = Math.floor((this.participation?.userRefs?.length || 0) / 2);
        const teamsPerGroup = Math.ceil(teamCount / (this.settings.groupCount || 1));
        const missingTeams = teamsPerGroup * (this.settings.groupCount || 1) - teamCount;
        const groupSizes = new Array(this.settings.groupCount).fill(teamsPerGroup * 2);

        //Group sizes are expressed in *players*
        for (let i = 1; i <= missingTeams; i++) {
            groupSizes[groupSizes.length - i] -= 2;
        }
        return groupSizes;
    }

    getTitle(withParentTitle: boolean = true) {
        return this.settings.getTitle(withParentTitle);
    }

    getRegion() {
        return this.settings.location?.region;
    }

    isFull() {
        if (this.settings.slots) {
            return ((this.participation.userRefs?.length || 0) >= this.settings.slots);
        }
        return false;
    }

    setFeatured(flag: boolean) {
        this.saveFunc((self: Competition) => {
            self.onSetFeatured(flag);
        })
    }
    
    onSetFeatured(flag: boolean) {
        this.state.featured = flag;
    }

    shuffle() {
        this.saveFunc((self: Competition) => {
            self.onShuffle();
        })
    }
    onShuffle() {
        this.participation.userRefs = arrayShuffle(this.participation.userRefs);
    }


}

