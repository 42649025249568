import React, { useState} from 'react';
import { View, ScrollView } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from '../useSheetStyle';
import { useAppContext } from '../../../appcontext';


interface LanguageSheetProps {
    title: string,
    cancelLabel: string,
    doneLabel: string,
    doneColor?: string,
    initialLocaleCode?: string,
    style?: any,
    resolve?: (data: string | undefined | null ) => void
}

SideSheet.defaultProps = {
    title: StringTable.getString('Language?'),
    cancelLabel: StringTable.getString('Cancel'),
    doneLabel: StringTable.getString('Done')
}
export default function SideSheet(props: LanguageSheetProps) {

    const {
        title,
        cancelLabel,
        doneLabel,
        doneColor,
        initialLocaleCode,
        resolve,
        style
    } = props;

    const { locales } = useAppContext();
    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const [localeCode, setLocaleCode] = useState<string | undefined>(initialLocaleCode)
    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            <ScrollView>
                { Object.keys(locales).filter((key: string) => locales[key].language).map((key: string) => (
                    <Section size={'M'} key={key}>
                        <Button
                            onPress={() => setLocaleCode(key)}
                            icon={locales[key]?.flag}
                            label={`${locales[key].languageName || locales[key].name}`}
                            mode={locales[key].code === localeCode ? 'contained' : 'outlined'}
                            style={{justifyContent: 'flex-start'}}
                        />
                    </Section>
                ))}                
            </ScrollView>

            <Section size={'L'} style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={cancelLabel}/>
                <Button onPress={(e: IPressEvent) => resolve && resolve(localeCode)} mode={'contained'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>            
        </View>
    )
}
