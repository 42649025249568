import React, { useMemo } from 'react';
import { StringTable } from 'utils';
import { Button } from 'components';
import { useSubCompetitions } from '../../hooks';
import { Competition } from 'business';
import MenuSheet from './MenuSheet';

interface Props {
    competition: Competition;
    selectableParentCompetition: boolean;
    resolve?: (targetClass: Competition | null) => void;
}

ClassSelectionSheet.defaultProps = {
    selectableParentCompetition: false
}

export default function ClassSelectionSheet(props: Props) {

    const { competition, selectableParentCompetition, resolve } = props;
    const classes = useSubCompetitions(competition?.id);

    const classNames = useMemo(() => {
        if (classes?.length) {
            return classes.reduce((acc: {[id: string]: string}, curr: Competition) => {
                acc[curr.id] = curr.getTitle();
                return acc;
            }, {})
        }
        else {
            return {};
        }
    }, [classes]);

    return(
        <MenuSheet title={StringTable.getString('Select class')}>
            { selectableParentCompetition && competition ? (
                <Button key={competition.id} label={competition.getTitle()} onPress={() => resolve?.(competition)}/>
            ) : null }
            { Object.keys(classNames).map((id: string) => (
                <Button key={id} label={classNames[id]} onPress={() => resolve?.(classes?.find(c => c.id === id) || null)}/>
            ))}
        </MenuSheet>
    )
}
