import { useCallback } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function useChangePinCode(setLastValidPinCode: React.Dispatch<React.SetStateAction<string | undefined>>, currentUserId?: string | null, lastValidPinCode?: string) {

    const changePinCode = useCallback(async(chosenPinCode: string) => {
        const changePinCode = httpsCallable(getFunctions(), 'changePinCode');
        const response : any = await changePinCode({phoneNumber: currentUserId, oldPinCode: lastValidPinCode, pinCode: chosenPinCode});
        if (response?.data === true) {
            setLastValidPinCode(chosenPinCode);
        }
        else {
            throw new Error('Failed to change PIN code');
        }        
    }, [currentUserId, lastValidPinCode]);

    return changePinCode;
}