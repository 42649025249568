import { DocumentSnapshot } from "firebase/firestore";

function firestoreFixDates(data: any) {

    function iterate(obj: any) {
        Object.keys(obj).forEach(key => {
            if (obj[key] && obj[key].toDate) {
                obj[key] = obj[key].toDate();
            }
            else if (obj[key] !== null && typeof obj[key] === 'object') {
                iterate(obj[key]);
            }
            else if (Array.isArray(obj[key])) {
                obj[key].forEach((val: any) => iterate(val));
            }
        });
    }
    if (data && typeof data === 'object') {
        iterate(data);
    }
    return data;
}

function parseVersion(data: any, version: number) {

    let settings = data?.settings;
    let participation = data?.participation;
    let state = data?.state;

    if (!data) {
        return data;
    }

    if (!participation) {
        participation = {
            admin: Array.isArray(data.admin) ? data.admin : [data.admin], 
            userRefs: version < 3 ? data.userRefs?.map((u: {id: string}) => u.id) : data.userRefs, 
            invitedRefs: version < 3 ? data.invitedRefs?.map((u: {id: string}) => u.id) : data.invitedRefs, 
            declinedRefs: version < 3 ? data.declinedRefs?.map((u: {id: string}) => u.id) : data.declinedRefs, 
            reservesRefs: version < 3 ? data.reservesRefs?.map((u: {id: string}) => u.id) : data.reservesRefs, 
            slots: data.slots
        }
    }

    if (!settings) {
        settings = {
            title: data.title || data.name, 
            byline: data.byline, 
            description: data.description, 
            imageUrl: data.imageUrl || data.photoUrl,
            public: data.public, 
            location: data.location, 
            phoneNumber: data.phoneNumber,

            //Competition-specific settings
            type: data.type, 
            date: version < 3 ? data.date?.[0] : data.date,
            slots: data.slots, 
            duration: data.duration,
            groupRef: version < 3 ? data.groupId : data.groupRef, 
            groupCount: data.groupCount, 
            fixedTeams: data.fixedTeams, 
            teamSignup: data.teamSignup, 
            matchOrder: data.matchOrder, 
            points: data.points, 
            price: data.price, 
            playoffStages: data.playoffStages, 
            clubRef: data.clubRef, 
            courts: data.courts, 
            similarRating: data.similarRating, 

            //Club-specific settings
            hasCalendar: data.hasCalendar,

            //User-specific settings
            hand: data.hand, 
            side: data.side,
            bestShot: data.bestShot, 
            worstShot: data.worstShot,
            localeCode: data.localeCode,
            region: data.region, 
            clubRefs: data.clubRefs
        }
    }

    if (!state) {
        state = {
            featured: data.featured,
            full: data.full, 
            winners: data.winners, 
            scheduled: data.scheduled,
            started: data.started, 
            closed: data.closed, 
            value: data.value,

            //User-specific states
            registered: data.registered,
            rating: data.rating, 
            matches: data.matches,
            trophies: data.trophies
        }
    }
    return { ...data, participation, settings, state };
}

export default function parse(collectionName: string, document?: DocumentSnapshot) {
    if (!document) {
        return null;
    }
    const version = document.get('meta.version') || 1;
    return parseVersion(firestoreFixDates(document.get('data')), version);
}
