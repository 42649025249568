import { getResult } from './helpers';
import { Match } from './Match';
import { capitalize, createId } from '../tools';
import { IPlayoff, IMatch } from '../interfaces';

/**
 * Playoff
 */

const stages = ['Final', 'Semifinals', 'Quarterfinals', 'Round of 8', 'Round of 16', 'Round of 32', 'Round of 64'];

export class Playoff implements IPlayoff {

    previousPlayoff?: Playoff;


    playoffRound: number;
    seedDescriptions?: string[];
    winnerPlayoff?: Playoff;
    competitionId: string;
    stage: 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Numeric' | 'Ended';
    teamCount?: number;
    matches: Match[];
    points?: boolean;

    constructor(data: IPlayoff, previousPlayoff?: Playoff) {

        this.previousPlayoff = previousPlayoff;
        this.playoffRound = data.playoffRound || 1;
        this.seedDescriptions = data.seedDescriptions;

        this.stage = data.stage === 'Numeric' ? Playoff.deriveStage(data.teamCount) : data.stage;
        this.teamCount = data.teamCount || Playoff.deriveTeamCount(this.stage);
        this.competitionId = data.competitionId;
        this.points = data.points;
        if (!this.competitionId) {
            throw "No competitionId in Playoff"
        }
        if (data.matches) {
            this.matches = data.matches.map((m: IMatch) => new Match(m));
        }
        else {
            this.matches = this.getSeeding().map(seed => new Match({
                id: createId(14), 
                competitionId: data.competitionId, 
                playoffRound: this.playoffRound,
                userRefs: [], 
                seed: seed,
                points: this.points
            }));        
        }

        if (this.stage !== 'Final') {
            this.winnerPlayoff = data.winnerPlayoff ? new Playoff(data.winnerPlayoff, this) : (this.nextStage() ? new Playoff({
                stage: this.nextStage(), 
                teamCount: Playoff.deriveTeamCount(this.nextStage()),
                playoffRound: this.playoffRound + 1, 
                competitionId: this.competitionId,
                points: this.points,
                seedDescriptions: this.deriveNextRoundSeedDescriptions()
            }) : undefined);
        }
    }

    serialize() : IPlayoff {
        return {
            stage: this.stage,
            matches: this.matches?.map(m => m.serialize()),
            winnerPlayoff: this.winnerPlayoff?.serialize(),
            playoffRound: this.playoffRound,
            seedDescriptions: this.seedDescriptions,
            competitionId: this.competitionId,
            teamCount: this.teamCount,
            points: this.points
        }
    }

    deriveNextRoundSeedDescriptions() {

        const descriptions : string[] = [];
        const teamCount = this.teamCount || Playoff.deriveTeamCount(this.stage);
        this.matches.forEach((match, matchIndex) => {
            if (match.seed) {
                const homeBye = match.seed[0] >= teamCount;
                const awayBye = match.seed[1] >= teamCount;

                if (homeBye || awayBye) {
                    descriptions.push(this.seedDescriptions?.[homeBye ? match.seed[1] : match.seed[0]] || '');
                }
                else {
                    descriptions.push(`${this.stage} ${matchIndex + 1}`)
                }
            }
        });
        return descriptions;
    }

    getSeeding() : number[][] {
        const teamCount = this.teamCount || Playoff.deriveTeamCount(this.stage);
        if(teamCount < 2) {
            return [];
        }

        /*
        function seededStyle(slotsCount: number) {
            function nextLayer(pls : number[]){
                var out : number[] = [];
                var length = pls.length*2+1;
                pls.forEach(function(d){
                    out.push(d);
                    out.push(length-d);
                });
              return out;
            }

            var rounds = Math.log(slotsCount)/Math.log(2)-1;
            var pls : number[] = [1,2];
            for(var i=0;i<rounds;i++){
                pls = nextLayer(pls);
            }

            return pls.reduce((acc: number[][], curr: number, index: number) => {
                index % 2 === 1 && acc.push([pls[index-1], pls[index]]);
                return acc;
            }, []);
        }
        */

        function worldcupStyle(slotsCount: number) {

            const left : number[] = [];
            const right : number[] = [];
    
            for (let i=1; i <= slotsCount/2; i++) {
                (i % 2 === 1 ? left : right).push(i - 1);
                (i % 2 === 1 ? left : right).push(slotsCount + 1 - i - 1);
            };

            const out = [...left, ...right];

            return out.reduce((acc: number[][], curr: number, index: number) => {
                index % 2 === 1 && acc.push([out[index-1], out[index]]);
                return acc;
            }, []);
        }

        function worldcupStyleAfterFirstPlayoffRound(slotsCount: number) {
            const out : number[][] = [];
            for (let index = 0; index < slotsCount; index++) {
                index % 2 === 1 && out.push([index-1, index]);                
            }
            return out;
        }

        if (this.playoffRound === 1) {
            const stage = this.stage !== 'Numeric' ? this.stage : Playoff.deriveStage(this.teamCount);            
            const slotsCount = Playoff.deriveTeamCount(stage);
            return worldcupStyle(slotsCount); //seededStyle(this.teamCount);
        }
        else {
            return worldcupStyleAfterFirstPlayoffRound(Playoff.deriveTeamCount(this.stage));
        }
    }
    
    swapSeed(targetSeed: number, replacementSeed: number) {
        const targetMatch = this.matches.find(m => m.seed?.includes(targetSeed));
        const replacementMatch = this.matches.find(m => m.seed?.includes(replacementSeed));

        if (targetMatch && replacementMatch) {
            targetMatch.seed?.splice(targetMatch.seed.indexOf(targetSeed), 1, replacementSeed);
            replacementMatch.seed?.splice(replacementMatch.seed.indexOf(replacementSeed), 1, targetSeed);
        }

        if (this.winnerPlayoff) {
            this.winnerPlayoff.seedDescriptions = this.deriveNextRoundSeedDescriptions();
        }
    }

    populate(teams: string[][]) {
        teams.forEach((team, teamIndex) => {
            this.qualify(team, teamIndex);
        })

        //Iterate over all matches and check for byes
        this.matches.forEach((match: Match, matchIndex: number) => {
            if (match.seed) {
                const homeTeam = teams[match.seed[0]];
                const awayTeam = teams[match.seed[1]];

                if (homeTeam === undefined) {
                    this.winnerPlayoff?.qualify(awayTeam, matchIndex);
                }
                else if (awayTeam === undefined) {
                    this.winnerPlayoff?.qualify(homeTeam, matchIndex);
                }
            }
        });
    }

    endMatch(matchId: string, endDate: Date, result?: number[]) {
        const match = this.matches.find(m => m.id === matchId);
        const matchIndex = this.matches.findIndex(m => m.id === matchId);

        if (match) {
            match.setResult(result, endDate);
            let winners = getResult(match, match.points, false)?.winners;    
            
            if (winners !== undefined && winners >= 0) {
                const winnerTeam = match.userRefs.slice(winners * 2, winners * 2 + 2);
                this.winnerPlayoff?.qualify(winnerTeam, matchIndex);
            }
        }
        else {
            this.winnerPlayoff?.endMatch(matchId, endDate, result);
        }
    }

    qualify(team: (string | null)[], seed: number) {
        const match = this.matches.find(m => m.seed?.includes(seed));
        if (match) {
            const isHomeTeam = match.seed?.[0] === seed;
            match.userRefs[isHomeTeam ? 0 : 2] = team[0];
            match.userRefs[isHomeTeam ? 1 : 3] = team[1];
        }
    }

    getCurrent() : Playoff | null {
        if (this.matches.find(m => !m.hasResult())) {
            return this;
        }
        return this.winnerPlayoff?.getCurrent() || null;
    }

    getCurrentTeams() : [string, string][] | null {
        const current = this.getCurrent();
        if (current) {
            return current.matches.reduce((acc : [string, string][], curr : Match) => {
                curr.userRefs?.[0] && curr.userRefs?.[1] && acc.push([curr.userRefs[0], curr.userRefs[1]]);
                curr.userRefs?.[2] && curr.userRefs?.[3] && acc.push([curr.userRefs[2], curr.userRefs[3]]);
                return acc;
            }, []);
        }
        return null;
    }

    /**
     * HELPERS
     */
    getMatch(matchId: string) : Match | undefined {
        return this.matches.find(m => m.id === matchId) || this.winnerPlayoff?.getMatch(matchId);
    }

    getStages() : Playoff[] {
        const rounds : Playoff[] = [];
        const remainingRounds : Playoff[] = [];
        remainingRounds.push(this);

        while (remainingRounds.length > 0) {
            let currentRound = remainingRounds.shift();
            currentRound && rounds.push(currentRound);
            if (currentRound?.winnerPlayoff) {
                remainingRounds.push(currentRound.winnerPlayoff);
            }
        }

        return rounds;
    }

    getAllMatches() : Match[] { 
        const stages = this.getStages();
        const teamCount = this.teamCount || Playoff.deriveTeamCount(this.stage);
        return stages.reduce((matches: Match[], stage: Playoff) => {
            matches.push(...stage.matches.filter(m => !m.seed?.find(n => n >= teamCount)));
            return matches;
        }, []);
    }

    getTitle() {
        return capitalize(this.stage);
    }   
    

    getMatchDescription(matchId: string, groupCount: number) : { title?: string, home?: string, away?: string } {

        const match = this.matches.find(m => m.id === matchId);
        const matchIndex = this.matches.findIndex(m => m.id === matchId);

        if (match && match.seed) {
            const homeSeed = match.seed[0];
            const awaySeed = match.seed[1];

            if (this.seedDescriptions && homeSeed > -1 && awaySeed > -1) {
                return {title: `${this.stage} ${matchIndex + 1}`, home: `${this.seedDescriptions[homeSeed]}`, away: `${this.seedDescriptions[awaySeed]}`};
            }
            else {
                return {title: `${this.stage} ${matchIndex + 1}`};
            }
        }
        else if (this.winnerPlayoff) {
            return this.winnerPlayoff.getMatchDescription(matchId, groupCount);
        }
        else {
            return {};
        }    
    }

    isStarted() {
        return this.matches.find(m => m.result?.length) ? true : false;
    }

    getWinners() {
        const final = this.getStages().pop()?.matches[0];
        const result = final && getResult(final, final.points, false);
        const winners = final && result && result.winners >= 0 && final.userRefs.slice(result?.winners * 2, result?.winners * 2 + 2);
        return winners || undefined;
    }

    nextStage() : 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Ended' {
        const index = stages.indexOf(this.stage);
        //@ts-ignore
        return index > 0 ? stages[index - 1] : 'Ended';
    }

    prevStage() : 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Ended' {

        const index = stages.indexOf(this.stage);
        //@ts-ignore
        return index > -1 ? stages[index + 1] : 'Ended';
    }

    hasStarted() {
        return this.matches.find(m => m.hasResult() ? true : false);
    }

    findPlayoffWithMatch(matchId: string) : Playoff | undefined {
        if (this.matches.find(m => m.id === matchId)) {
            return this;
        }
        return this.winnerPlayoff?.findPlayoffWithMatch(matchId);
    }

    hasMatch(matchId: string) : Playoff | undefined {
        if (this.matches.find(m => m.id === matchId)) {
            return this;
        }
        return this.winnerPlayoff?.hasMatch(matchId);
    }

    dump() {
        let str = `Stage ${this.stage}\n`;
        this.matches.forEach(match => str += `${match.seed || ''} ${match.userRefs} ${match.result} \n`)
        this.winnerPlayoff?.dump();
        console.log(str);
    }



    static deriveStage(teamCount: number = 4) : 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' {
        // (stageIndex+1)^2 = teams
        // (stageIndex+1) = sqrt(teams)
        // (stageIndex) = ceil(sqrt(teams) - 1)

        if (teamCount < 3) {
            return 'Final';
        }

        const stageIndex = Math.ceil(Math.sqrt(teamCount) - 1);

        if (stageIndex > -1 && stageIndex < stages.length) {
            //@ts-ignore
            return stages[stageIndex];
        }
        throw new Error('Error deriving stage from ' + teamCount + ' teams, index is ' + stageIndex);
    }

    static deriveTeamCount(stage: 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Numeric' | 'Ended') {
        if (stage === 'Numeric') {
            throw new Error('calling deriveTeamCount with "numeric" as stage')
        }

        const exponent = stages.indexOf(stage || 'Semifinals') + 1;
        return Math.pow(2, exponent);
    }
}
