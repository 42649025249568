import { ConversationMessage } from './ConversationMessage';
import { Participation } from './Participation';
import getVersion from './getVersion';
import { IConversation, IConversationMessage, IConversationMeta } from '../interfaces';

export class Conversation implements IConversation {

    id: string;
    collection: string;
    participation: Participation;
    groupRef?: string;
    competitionRef?: string;
    title: string;
    public?: boolean;
    saveFunc: any;
    messages: ConversationMessage[];


    constructor(data: IConversation, saveFunc?: any) {

        this.saveFunc = saveFunc || this.defaultSaveFunc;
        this.id = data.id;
        this.collection = 'conversations';
        this.participation = new Participation(data.participation);
        this.title = data.title;
        this.groupRef = data.groupRef;
        this.competitionRef = data.competitionRef;
        this.messages = data.messages?.map((m: IConversationMessage) => new ConversationMessage(m)) || [];
        this.public = data.public;
    }

    defaultSaveFunc(f: (self: Conversation) => void) {
        f(this);
    }

    serialize() : IConversation {
        return {
            id: this.id, 
            title: this.title,
            participation: this.participation.serialize(),
            groupRef: this.groupRef,
            competitionRef: this.competitionRef,        
            messages: this.messages?.map((m: ConversationMessage) => m.serialize())
        }
    }

    meta() : IConversationMeta {
        return {
            id: this.id, 
            lastModifiedDate: new Date, 
            public: this.public,
            recipients: this.participation.getRecipients(),
            version: getVersion()
        }
    }

    getTitle() {
        return this.title;
    }
    
    /**
     * Users added by someone else
     * @param userRefs 
     */
    add(userRefs: string[]) {        
        this.saveFunc((self: Conversation) => {
            self.onAdd(userRefs);
        });
    }
    onAdd(userRefs: string[]) {
        userRefs && this.participation.add(userRefs);
    }

    /**
     * Users added by themselves or their partner
     * @param userRefs 
     */
    join(userRefs?: string | string[]) {
        this.saveFunc((self: Conversation) => {
            self.onJoin(userRefs);
        }, true);
    }
    onJoin(userRefs?: string | string[]) {
        userRefs && this.participation.join(userRefs);
    }

    /**
     * Users removed by themselves or someone else
     * @param userRef 
     */
    leave(userRef?: string | string[], partnerRef?: string | null) {
        this.saveFunc((self: Conversation) => {
            self.onLeave(userRef, partnerRef);
        })
    }
    onLeave(userRef?: string | string[], partnerRef?: string | null) {
        userRef && (Array.isArray(userRef) ? userRef : [userRef]).forEach(u => {
            u && this.participation.leave(u, partnerRef);
        })
    }

    addMessage(message: IConversationMessage) {
        this.saveFunc((self: Conversation) => {
            self.onAddMessage(message);
        });
    }

    onAddMessage(message: IConversationMessage) {
        message && this.messages.unshift(new ConversationMessage(message));

    }

    deleteMessage(message: ConversationMessage) {
        this.saveFunc((self: Conversation) => {
            message && self.onDeleteMessage(message);
        })
    }

    onDeleteMessage(message: ConversationMessage) {
        const index = this.messages.findIndex(m => m.id === message.id);
        index >= 0 && this.messages?.splice(index, 1);
    }

    reactMessage(message: ConversationMessage, reaction: string, senderId: string) {
        this.saveFunc((self: Conversation) => {
            self.onReactMessage(message, reaction, senderId);
        })
    }

    onReactMessage(message: ConversationMessage, reaction: string, senderId: string) {
        const target = this.messages.find(m => m.id === message.id);
        target?.addReaction(reaction, senderId);

    }

    getLatestMessage() {
        return this.messages?.[0];
    }


}
