export function dateCompare(a?: Date, b?: Date, time?: boolean) {
    if (!(a && b)) {
        return false;
    }
    if (!time) {
        return (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate());
    }
    return (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate() && a.getHours() === b.getHours() && a.getMinutes() === b.getMinutes());
}

export function getWeekOf(date: Date, firstDay: number) {
    const start = new Date(date.getTime());
    const dayOfWeek = (date.getDay() + 7 - firstDay) % 7;
    start.setDate(start.getDate() - dayOfWeek);
    const end = new Date(start.getTime());
    end.setDate(end.getDate() + 6);
    return [start, end];
}

export function getWeeksOfMonth(date: Date, firstDay: number) {

    const weeks = [];
    const firstWeekStarts = getWeekOf(new Date(date.getFullYear(), date.getMonth(), 1), firstDay)[0];
    const lastWeekEnds = getWeekOf(new Date(date.getFullYear(), date.getMonth() + 1, 0), firstDay)[1];

    const iterator = firstWeekStarts;

    let safety = 10;

    while (iterator.getTime() <= lastWeekEnds.getTime()) {
        weeks.push(getWeekOf(iterator, firstDay)[0]);
        iterator.setDate(iterator.getDate() + 7);
        safety -= 1;
        if (safety < 0) {
            throw new Error('getWeeksOfMonth overflow');
        }
    }
    return weeks;
}

export const dayLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const shortDayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const monthLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const shortMonthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
