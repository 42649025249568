import React, { useMemo } from 'react';
import { View } from 'react-native';
import Text from '../helpers/Text';
import useThemeStyle from '../theme/useThemeStyle';


interface CalendarLabelProps {
    style?: any,
    firstDay: number,
    dayLabels: string[]
}

export default function CalendarLabel(props: CalendarLabelProps) {

    const {
        style : styleProp,
        firstDay,
        dayLabels
    } = props;

    const days = useMemo(() => {
        const d = [0, 1, 2, 3, 4, 5, 6];
        for (let i = 0; i < firstDay; i++) {
            //@ts-ignore
            d.push(d.shift());
        }
        return d;
    }, [firstDay]);

    const style = useThemeStyle('CalendarLabel', props);

    return(
        <View style={[style, styleProp, {flexDirection: 'row'}]}>
            { days.map(day => (
                <View key={day} style={{flex: 1, alignItems: 'center'}}>
                    <Text style={style}>{dayLabels?.[day].slice(0, 3)}</Text>
                </View>
            ))}
        </View>
    )
}
