import { useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { useTheme, Paragraph } from 'components';
import { useStyles } from '../hooks';
import { useNavigation } from '../navigation';

interface Props {
    goBack?: boolean,
    goBackMessage?: string,
    message?: string
}

export default function WaitingScreen(props: Props) {

    const { goBack, goBackMessage, message } = props;

    const theme = useTheme();
    const styles = useStyles();

    const { back } = useNavigation();

    useEffect(() => {
        if (goBack) {
            back();
            goBackMessage && console.log(goBackMessage);
        }
    }, [goBack, goBackMessage]);

    return(
        <View style={[styles.screen, {justifyContent: 'center', alignItems: 'center'}]}>
            <ActivityIndicator color={theme["color-ink"]}/>
            {message ? (
                <Paragraph>{message}</Paragraph>
            ) : null}
        </View>
    )
}