import { createFromInstance, getInstance } from 'bridge';
import { User } from 'business';
import { validatePhoneNumber } from 'tools';

export default async function safelyRegisterUser(currentUser?: User | null, userId?: string, settings?: {title?: string, region?: string}) {
    if (currentUser && !currentUser.state.registered) {
        currentUser.register(settings?.title, settings?.region);
    }
    else if (!currentUser && userId && validatePhoneNumber(userId)) {
        //Verify that user does in fact not exist
        let safeNewUser = await getInstance('users', userId);
        if (!safeNewUser) {
            createFromInstance(new User({
                id: userId,
                settings: {
                    title: settings?.title, 
                    region: settings?.region
                },
                state: {
                    rating: 1000,
                    registered: true
                }
            }, null));
        }
    }
}
