//@ts-nocheck
import React from 'react';
import { View } from 'react-native';
import Svg, { Circle } from 'react-native-svg';
import useTheme from './theme/useTheme';
import GradientView from './GradientView';

export interface LogoSquareProps {
    style?: any
}
export default function LogoSquare(props: LogoSquareProps) {

    const {
        style
    } = props;

    const theme = useTheme();

    const logoColor = 'gold';
    return(
        <GradientView style={style} startColor={theme["color-negative"]} endColor={theme["color-neutral"]}>
            <View style={{position: 'absolute', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                <Svg width="50%" height="50%" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <Circle cx="2.64405" cy="1.76716" r="1.75342" fill={logoColor}/>
                    <Circle cx="2.64405" cy="1.76716" r="1.75342" fill={logoColor}/>
                    <Circle cx="2.64405" cy="9.6576" r="1.75342" fill={logoColor}/>
                    <Circle cx="2.64405" cy="13.6028" r="1.75342" fill={logoColor}/>
                    <Circle cx="8.65772" cy="1.76716" r="1.75342" fill={logoColor}/>
                    <Circle cx="12.603" cy="1.76716" r="1.75342" fill={logoColor}/>
                    <Circle cx="8.65772" cy="9.6576" r="1.75342" fill={logoColor}/>
                    <Circle cx="8.65772" cy="13.6028" r="1.75342" fill={logoColor}/>
                    <Circle cx="12.603" cy="13.6028" r="1.75342" fill={logoColor}/>
                    <Circle cx="8.65772" cy="5.71235" r="1.75342" fill={logoColor}/>
                    <Circle cx="15.6714" cy="9.6576" r="1.75342" fill={logoColor}/>
                    <Circle cx="15.6714" cy="5.71235" r="1.75342" fill={logoColor}/>
                </Svg>
            </View>
        </GradientView>
    )
}
