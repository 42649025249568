import { useState, useEffect, useRef } from 'react';
import { Platform } from 'react-native';
import { getDeviceTypeAsync, DeviceType } from 'expo-device';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { User } from 'business';
import { parse, save } from 'bridge';

interface LoginStatistic {
    date: number | Date, 
    device: DeviceType,
    os: 'ios' | 'android' | 'web' | 'windows' | 'macos' | 'unknown',
    appVersion: number,
    loginCount: number,
    userId: string
}

export default function useCurrentUserImplementation() {

    const unsubscribe = useRef<any>();
    const [currentUser, setCurrentUser] = useState<User | null | undefined>();
    const [currentUserId, setCurrentUserId] = useState<string | null | undefined>();
    const [currentUserVerified, setCurrentUserVerified] = useState<boolean>(false);
    const auth = useRef(getAuth()).current;

    useEffect(() => {
        async function handleAuth() {
            const start = new Date();
            const user = await readFromLocalStorage();
            user && setCurrentUser(user);
            const t1 = new Date();
            console.log(`[useCurrentUser]: ${t1.getTime() - start.getTime()}ms predict user ${user?.id || null}`);
            onAuthStateChanged(auth, (firestoreUser: any) => {
                const uid = firestoreUser?.phoneNumber;
                setCurrentUserId(uid);
                
                uid && writeAuthStats(uid);

                if (user && user.id !== uid) {
                    unsubscribe.current?.();
                    writeToLocalStorage(null);
                    setCurrentUser(undefined);    
                    const t2 = new Date();
                    console.log(`[useCurrentUser]: ${t2.getTime() - start.getTime()}ms prediction failed`);
                }
                else if (!uid) {
                    setCurrentUser(undefined);
                }
                unsubscribe.current = uid && onSnapshot(doc(getFirestore(), 'users', uid), snap => {
                    const u = snap.exists() ? new User(parse('users', snap), save) : null;
                    writeToLocalStorage(u);
                    setCurrentUser(u);
                    setCurrentUserVerified(true);
                    const t3 = new Date();
                    console.log(`[useCurrentUser]: ${t3.getTime() - start.getTime()}ms truth received ${u?.id || null}`);
                });
            });
        }

        async function writeAuthStats(uId: string) {
            if (uId) {
                const loginStatsSnapshot = await get(ref(getDatabase(), `analytics/logins/${uId}`));
                const stats : LoginStatistic = loginStatsSnapshot.exists() && typeof loginStatsSnapshot.val() === 'object' ? loginStatsSnapshot.val() : {};
                stats.date = new Date().getTime();
                stats.device = await getDeviceTypeAsync();
                stats.os = Platform.OS;
                stats.appVersion = 2;
                stats.loginCount = (stats.loginCount || 0) + 1;
                stats.userId = uId || '';  
                uId && set(ref(getDatabase(), `analytics/logins/${uId}`), stats);
            }
        }

        handleAuth();
    }, []);

    async function readFromLocalStorage() {
        try {
            const json = await AsyncStorage.getItem('@user');
            const data = json != null ? JSON.parse(json) : null;

            const user = data ? new User(data, save) : null;
            return user;
        } catch(e) {
            console.log('[useCurrentUser]: Error reading local storage', e);
        }
    }
    async function writeToLocalStorage(user: User | null) {
        try {
            const data = user?.serialize() || null;
            await AsyncStorage.setItem('@user', JSON.stringify(data));
        } catch(e) {
            console.log('[useCurrentUser]: Error updating local storage', e);
        }
    }

    return { currentUser, currentUserId, currentUserVerified }
}
