import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useTheme, KeyboardDismissingView, Section, SectionHeader, ImageUploadButton, Button, TextInput, TextInputNumeric, Caption } from 'components';
import { StringTable } from 'utils';
import { IPressEvent, IClubSettings } from 'interfaces';
import { createId } from 'tools';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';
import RegionsSheet from './RegionsSheet';
import { useDocumentInstance } from 'bridge';
import { useAppContext } from '../../appcontext';
import useModal from '../useModal';

interface CreateClubSheetProps {
    title: string,
    doneLabel: string,
    resolve?: (data: ICreateClubSheetResult | null) => void,
    initialId?: string,
    initialSettings?: Partial<IClubSettings>,
    hideId?: boolean,
    //Note that style SHOULD be required, it's not a typo. Modals need to read flex from style
    style: any
}

CreateClubSheet.defaultProps = {
    initialSettings: {}
}

export default function CreateClubSheet(props: CreateClubSheetProps) {

    const {
        title,
        doneLabel, 
        resolve,
        initialId,
        initialSettings,
        hideId,
        style : styleProp
    } = props; 

    const { currentUser } = useAppContext();
    const theme = useTheme();

    const [uploadingImage, setUploadingImage] = useState(false);
    const [clubSettings, setClubSettings] = useState(initialSettings || {});
    const [id, setId] = useState(initialId || '');
    useEffect(() => setClubSettings(initialSettings || {}), [initialSettings]);

    const { pushModal, popModal } = useModal();

    const sheetStyle = useSheetStyle();
    const preExistingClub = useDocumentInstance('clubs', hideId ? undefined : id);

    return(
        <KeyboardDismissingView style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={title || StringTable.getString('Create event')}/>
            <View style={{flex: 1}}>
                <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>

                    {/* ID */}
                    { hideId ? null : (
                        <Section>
                            <SectionHeader icon={'ico_title'} label={StringTable.getString('UNIQUE ID')}/>
                            <Section size={'M'}>
                                <TextInput
                                    value={id || ''}
                                    onChangeText={(t: string) => setId(t.replace(/[\W_]+/g, ""))}
                                    iconLeft={'ico_title'}
                                />
                            </Section>
                            <Caption style={{color: theme["color-negative"]}}>{StringTable.getString(preExistingClub ? 'Club ID already in use' : ' ')}</Caption>
                        </Section>
                    )}

                    {/* Title */}
                    <Section>
                        <SectionHeader icon={'ico_title'} label={StringTable.getString('TITLE')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={clubSettings?.title || ''}
                                onChangeText={(t: string) => setClubSettings(prev => ({...prev, title: t}))}
                                iconLeft={'ico_title'}
                            />
                        </Section>
                    </Section>

                    {/* Byline */}
                    <Section>
                        <SectionHeader icon={'ico_title'} label={StringTable.getString('BYLINE')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={clubSettings?.byline || ''}
                                onChangeText={(t: string) => setClubSettings(prev => ({...prev, byline: t}))}
                                iconLeft={'ico_title'}
                            />
                        </Section>
                    </Section>

                    {/* Description */}
                    <Section>
                        <SectionHeader icon={'ico_document'} label={StringTable.getString('DESCRIPTION')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={clubSettings?.description || ''}
                                onChangeText={(t: string) => setClubSettings(prev => ({...prev, description: t}))}
                                iconLeft={'ico_document'}
                                multiline={true}
                                numberOfLines={4}
                            />
                        </Section>
                    </Section>

                    {/* WHERE */}
                    <Section>
                        <SectionHeader icon={'ico_location'} label={StringTable.getString('WHERE?')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={clubSettings.location?.address || ''}
                                placeholder={StringTable.getString('Club address')}
                                onChangeText={(t: string) => setClubSettings(prev => ({...prev, location: {...prev.location, address: t}}))}
                                iconLeft={'ico_location'}
                            />
                        </Section>

                        <Section size={'M'}>
                            <Button 
                                label={clubSettings?.location?.region || StringTable.getString('Select region')}
                                onPress={async (e: IPressEvent) => {
                                    const regionResult = await pushModal((
                                        <RegionsSheet style={{flex: 1}} selectedRegion={clubSettings?.location?.region}/>
                                    ), e);
                                    setClubSettings(prev => ({...prev, location: {...prev.location, region: regionResult}}))
                                    popModal();
                                }}
                                mode={'outlined'}
                                iconRight={'ico_chevronright'}
                                style={{marginTop: theme["spacing-small"], borderRadius: 4}}
                            />
                        </Section>

                        <Section style={{flexDirection: 'row'}}>
                            <View style={{flex: 1, marginRight: theme["spacing-extrasmall"]}}>
                                <SectionHeader icon={'ico_location'} label={StringTable.getString('Latitude')}/>
                                <TextInputNumeric
                                    value={clubSettings.location?.lat || ''}
                                    iconLeft={'ico_location'}
                                    float={true}
                                    onEndEditing={(n: number) => setClubSettings(prev => ({...prev, location: {...prev.location, lat: n}}))}
                                />
                            </View>
                            <View style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}}>
                                <SectionHeader icon={'ico_location'} label={StringTable.getString('Longitude')}/>
                                <TextInputNumeric
                                    style={{alignSelf: 'stretch'}}
                                    value={clubSettings.location?.lon || ''}
                                    iconLeft={'ico_location'}
                                    float={true}
                                    onEndEditing={(n: number) => setClubSettings(prev => ({...prev, location: {...prev.location, lon: n}}))}
                                />
                            </View>
                        </Section>

                        <Section>
                            <SectionHeader icon={'ico_oldphone'} label={StringTable.getString('Phone number')}/>
                            <Section size={'M'}>
                                <TextInput
                                    value={clubSettings.phoneNumber || ''}
                                    placeholder={StringTable.getString('Phone number')}
                                    onChangeText={(t: string) => setClubSettings(prev => ({...prev, phoneNumber: t}))}
                                    iconLeft={'ico_oldphone'}
                                />
                            </Section>
                        </Section>

                    </Section>

                    <Section>
                        <SectionHeader icon={'ico_addimage'} label={StringTable.getString('CLUB IMAGE')}/>
                        <Section size={'M'}>
                            <ImageUploadButton 
                                imageUrl={clubSettings?.imageUrl}
                                uploading={uploadingImage}
                                maxImageSize={500}
                                shape={'circle'}
                                upload={async(blob: any) => {
                                    if (currentUser?.id) {
                                        const metaData = {
                                            cacheControl: 'public,max-age=31536000'
                                        }
                                        setUploadingImage(true);
                                        const storage = getStorage();
                                        const storageRef = ref(storage, `uploads/${currentUser.id}/${createId(7)}.jpg`);
                                        const uploadResult = await uploadBytes(storageRef, blob, metaData);
                                        const downloadURL = await getDownloadURL(uploadResult.ref);
                                        setClubSettings(prev => ({...prev, imageUrl: downloadURL}));
                                        setUploadingImage(false);
                                    }
                                }}
                            />
                        </Section>
                    </Section>

                </ScrollView>
            </View>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button onPress={(e: any) => resolve && resolve({e: e, settings: clubSettings, id: hideId ? undefined : id})} mode={clubSettings.title && (hideId || preExistingClub === null) ? 'contained' : 'passive'} style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>

        </KeyboardDismissingView>
    )
}
