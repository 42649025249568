import { useTheme } from 'components';

export default function useSheetStyle() {

    const theme = useTheme();

    return {      
        borderRadius: theme["roundness"], 
        padding: theme["spacing-large"], 
        backgroundColor: theme["color-paper"]
    }
}
