import React from 'react';
import { View } from 'react-native';

import Icon from '../helpers/Icon';
import Paragraph from './Paragraph';
import Caption from './Caption';
import useTheme from '../theme/useTheme';
import Divider from '../helpers/Divider';

export interface SectionHeaderProps {
    icon?: string, 
    label?: string,
    mode?: string,
    style?: any,
    color?: string,
    children?: any
}

export default function SectionHeader(props: SectionHeaderProps) {

    const {
        icon, 
        label,
        style,
        color,
        children
    } = props;

    const theme = useTheme();

    return(
        <View style={style}>
            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Icon name={icon} color={color || theme["color-ink"]} size={13}/>
                    <Caption weight={'bold'} style={{marginLeft: theme["spacing-small"], color: color || theme["color-ink"]}}>{label}</Caption>
                </View>
                { children }
            </View>
            <Divider style={[{marginTop: 4}, color && {backgroundColor: color}]}/>
        </View>
    )
}