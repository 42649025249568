import React from 'react';
import { View, Pressable, ScrollView } from 'react-native';
import Padel from './Padel';
import Caption from './typography/Caption';
import Icon from './helpers/Icon';
import useTheme from './theme/useTheme';
import { Trophy } from 'interfaces';

export interface TrophyCabinetProps {
    trophies?: Trophy[], 
    style?: any, 
    onPressTrophy?: any
}

export default function TrophyCabinet(props : TrophyCabinetProps) {

    const {
        style,
        trophies,
        onPressTrophy
    } = props;

    const theme = useTheme();

    function getTrophyValue(trophy: Trophy) {
        if (trophy.value > 32000) {
            return [1, 2, 3];
        }
        else if (trophy.value > 20000) {
            return [1, 2];
        }
        else {
            return [1];
        }
    }

    return(
        <>
            <View style={style}>
                <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
                    {trophies && trophies.map((trophy, index) => (
                        <Pressable key={index} style={{padding: 0, marginRight: 6, alignItems: 'center'}} onPress={() => onPressTrophy && onPressTrophy(trophy)}>
                            <Padel fillColor={theme["color-gold"]} strokeWidth={2} strokeColor={"white"}>
                                <Icon size={20} name={'ico_trophy'} color={'white'}/>
                            </Padel>
                            <View style={{flexDirection: 'row'}}>
                                { getTrophyValue(trophy).map(t => (
                                    <Icon key={t} name={'ico_circle'} size={5} color={theme["color-gold"]}/>
                                ))}
                            </View>
                        </Pressable>
                    ))}
                </ScrollView>
            </View>
        </>
    )
}
