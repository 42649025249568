import { Match } from './Match';
import { ISchedule, IScheduleConstraint, ICourt } from '../interfaces';

export class Schedule implements ISchedule {

    courts: { [courtId: string]: ICourt };
    startDate: Date;
    matchMinutes: number;
    endedMatches: string[];
    
    getMatch: (matchId: string) => Match | undefined;

    constructor(data: ISchedule, getMatch: (matchId: string) => Match | undefined, log?: boolean) {
        this.getMatch = getMatch;
        this.courts = data.courts || { 0: {courtId: 0} };
        Object.values(this.courts).forEach(court => {
            court.queue = court.queue || [];
        })
        this.startDate = data.startDate || new Date();
        this.matchMinutes = data.matchMinutes || 30;
        this.endedMatches = data.endedMatches || [];
    }

    serialize() : ISchedule {
        return {
            courts: this.courts, 
            endedMatches: this.endedMatches,
            startDate: this.startDate, 
            matchMinutes: this.matchMinutes
        }
    }

    getSortedCourts() {
        return Object.values(this.courts).sort((a: ICourt, b: ICourt) => {
            const aQueueLength = a.queue?.length || 0;
            const bQueueLength = b.queue?.length || 0;
            if (aQueueLength !== bQueueLength) {
                return aQueueLength - bQueueLength;
            }
            //If queue is equal length, sort by court id
            return a.courtId - b.courtId;
        });
    }

    getMatchRefsAfter(matchId: string) {
        const court = Object.values(this.courts).find(c => c.queue.find(q => q.matchId === matchId));
        if (court) {
            const index = court.queue.findIndex(q => q.matchId === matchId);
            const matchRefsAfter = Object.values(this.courts).reduce((acc: (string | undefined)[], curr: ICourt) => ([...acc, ...curr.queue.slice(index + 1).filter(q => q.matchId).map(q => q.matchId)]), []);
            return matchRefsAfter;
        }
        return undefined;
    }

    clearMatches() {
        Object.values(this.courts).forEach(court => court.queue = []);
    }

    addMatches(matches: Match[], constraints?: IScheduleConstraint[]) {

        const queue = [...matches];
        const competitions : {[competitionId: string]: number } = matches.reduce((acc, curr) => ({...acc, [curr.competitionId]: 0}), {});

        let safety = 500;

        while (queue.length && safety--) {
            const sortedCourts = this.getSortedCourts();
            const busyPlayers : { [playerId: string]: boolean } = {};
            const scheduledInRound : Match[] = [];

            sortedCourts.forEach((court, index) => {

                const currentRound = court.queue?.length || 0;

                let match;
                if (constraints?.length) {
                    /*
                        When co-scheduling competitions, i.e. more than one constraint argument, we search first for a match from the least 
                        scheduled competition. Constraints:

                        1. Is the match from the least scheduled competition?
                        2. Are players busy this round?
                        3. Is match a playoff match and lower playoff matches in the same competition remain unscheduled
                        4. Is match a playoff match and any non-playoff or lower playoff matches in the same competition was scheduled this round

                        NEW: constrain by earliest round:
                        5. Is match not a playoff match and current round is lower than constraint.earliestRoundForGroups
                        6. Is match a playoff match and current round is lower than constraint.earliestRoundForPlayoffs
                    */

                    Object.keys(competitions).sort((a, b) => competitions[a] - competitions[b]).some(competitionId => {

                        const constraint = constraints?.find(c => c.competitionId === competitionId);

                        match = queue.find((match: Match) => 
                            match.competitionId === competitionId &&                         
                            !match.userRefs?.find(u => u && busyPlayers[u]) && 
                            !queue.find(m => m.competitionId === match.competitionId && (m.playoffRound || 0) < (match?.playoffRound || 0)) &&
                            !scheduledInRound.find(m => m.competitionId === match.competitionId && (m.playoffRound || 0) < (match?.playoffRound || 0)) &&
                            !this._constrainedByEarliestRound(currentRound, match.playoffRound ? constraint?.earliestRoundForPlayoffs : constraint?.earliestRoundForGroups)
                        );
                        if (match) {
                            competitions[competitionId] += 1;
                        }
                        return match ? true : false;
                    })
                }
                else {
                    /*
                        Constraints:
                        1. Are players busy this round
                        2. Is match a playoff match and playoff matches from an earlier round in the same competition remain unscheduled
                        3. Is match a playoff match and non-playoff or playoff matches from an earlier round in the same competition was scheduled this round

                        NEW: constrain by earliest round:
                        4. Is match not a playoff match and current round is lower than constraint.earliestRoundForGroups
                        5. Is match a playoff match and current round is lower than constraint.earliestRoundForPlayoffs
                    */
                   const constraint = constraints?.[0];

                    match = queue.find((match: Match) => !match.userRefs?.find(u => u && busyPlayers[u]) && 
                        !queue.find(m => m.competitionId === match.competitionId && (m.playoffRound || 0) < (match?.playoffRound || 0)) &&
                        !scheduledInRound.find(m => m.competitionId === match.competitionId && (m.playoffRound || 0) < (match?.playoffRound || 0)) && 
                        !this._constrainedByEarliestRound(currentRound, match.playoffRound ? constraint?.earliestRoundForPlayoffs : constraint?.earliestRoundForGroups)
                    );
                }

                if (match) {
                    court.queue.push({matchId: match.id});
                    match.userRefs?.forEach(u => {
                        if (u) {
                            busyPlayers[u] = true;
                        }
                    });
                    scheduledInRound.push(match);
                    queue.splice(queue.indexOf(match), 1);
                }
                else {
                    court.queue.push({matchId: undefined});
                }
            });
        }
    }

    _constrainedByEarliestRound(currentRound: number, earliestRound?: number) {
        return currentRound < (earliestRound || 0) ? true : false;
    }

    canMatchStart(matchId?: string) {

        if (!matchId) {
            return false;
        }
        const match = this.getMatch(matchId);

        //Constraint: match does not have four players yet
        if (match?.userRefs?.length !== 4) {
            return false;
        }
        //Constraint: a player in Match is already playing
        if (Object.values(this.courts).find(c => c.queue[0].matchId && c.queue[0].matchId !== matchId && this.getMatch(c.queue[0].matchId)?.userRefs?.find(u => match?.userRefs.includes(u)))) {
            return false;
        }
        return true;
    }

    start(date: Date) {
        this.startDate = date;
    }

    getMatchOrder() : string[] {
        const courts = this.getSortedCourts();
        const matchRefs : string[] = [];
        for (let i = 0; i < Math.max(...courts.map(c => c.queue.length)); i++) {
            for (let j = 0; j < courts.length; j++) {
                //@ts-ignore
                courts[j].queue[i]?.matchId && matchRefs.push(courts[j].queue[i].matchId);
            }
        }
        return [...this.endedMatches, ...matchRefs];
    }

    getMatchSchedule(matchId: string, log: boolean = false) {

        const court = Object.values(this.courts).find(c => c.queue.find(q => q.matchId === matchId));
        if (court) {
            const index = court.queue.findIndex(q => q.matchId === matchId);
            return {
                courtId: court.courtId,
                startDate: new Date((court.queue[0].startDate || this.startDate).getTime() + this.matchMinutes * 60 * 1000 * index)
            }
        }
        return undefined;
    }

    dump(showMatchId: boolean = false) {
        let str = '';
        const sortedCourts = this.getSortedCourts();
        let round = 0;
        for (let i = 0; i < Math.max(...sortedCourts.map(c => c.queue.length)); i++) {
            sortedCourts.forEach(court => {
                const matchId = court.queue[i]?.matchId;
                const match = matchId && this.getMatch(matchId);

                if (matchId && match) {
                    const schedule = this.getMatchSchedule(matchId);
                    str += `R${round} ${schedule?.startDate.toLocaleTimeString()}: [${match.competitionId}${showMatchId ? '.' + match.id : ''}] ${match.userRefs?.[0]}/${match.userRefs?.[1]} vs ${match.userRefs?.[2]}/${match.userRefs?.[3]} at ${schedule?.courtId}\n`
                }
                else {
                    str += `R${round} --\n`;
                }
            })
            round += 1;
        }
        console.log(str);
    }
}