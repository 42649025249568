import React from 'react';
import { TouchableOpacity } from 'react-native';
import Icon from './helpers/Icon';
import Caption from './typography/Caption';
import useTheme from './theme/useTheme';

interface Props {
    style?: any, 
    icon?: string, 
    label?: string,
    onPress?: (e: any) => any
}
export default function Buttonlet(props: Props) {

    const { style, icon, label, onPress } = props;
    const theme = useTheme();

    const hitSlop = {
        top: 10, 
        left: 10, 
        bottom: 10, 
        right:10
    }
    return(
        <TouchableOpacity style={[style, {flexDirection: 'row', alignItems: 'center'}]} onPress={onPress} hitSlop={hitSlop}>
            { icon ? <Icon name={icon} size={12} color={theme["color-primary"]}/> : null }
            { label ? <Caption style={{color: theme["color-primary"]}}>{label}</Caption> : null }
        </TouchableOpacity>
    )
}
           