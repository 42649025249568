import React, { useState, useEffect } from 'react';
import { View, Image, Platform } from 'react-native';
import * as Crypto from 'expo-crypto';
import * as FileSystem from 'expo-file-system';

export default function CachedImage(props: any) {
    return Platform.OS === 'web' ? <Image {...props}/> : <NativeCachedImage {...props}/>
}

function NativeCachedImage(props: any) {

    const { source, ...otherProps } = props;
    const [localPath, setLocalPath] = useState<string | null>(null);

    useEffect(() => {
        if (!source?.uri) {
            return;
        }

        async function handleImageCache() {

            const hash = source.uri && await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA1, source.uri);
            const fileExt = source.uri.split('.').pop();
            const localUri = `${FileSystem.cacheDirectory}/${hash}.${fileExt}`;

            async function downloadImage() {
                try {
                    const downloadImage = FileSystem.createDownloadResumable(
                        source.uri,
                        localUri,
                        {},
                        () => {}
                    );
                    const downloaded = await downloadImage.downloadAsync();
                    return downloaded ? {
                        cached: true,
                        err: false,
                        path: downloaded.uri,
                    } : {
                        cached: false, 
                        err: true
                    }
                } catch (error) {
                    return {
                        cached: false,
                        err: true
                    };
                }
            }

            const fileInfo = await FileSystem.getInfoAsync(localUri);
            if (fileInfo.exists) {
                setLocalPath(localUri);
            }
            else {
                const downloadResult = await downloadImage();
                setLocalPath(downloadResult.path || null);
            }
        }

        handleImageCache();

    }, [source?.uri]);

    return localPath ? <Image source={{ uri: localPath }} {...otherProps}/> : <View {...otherProps}/>;
};

