import { Location } from './Location';
import { IGroupSettings } from '../interfaces';
export class GroupSettings implements IGroupSettings {

    title?: string;
    byline?: string;
    description?: string;
    imageUrl?: string;
    clubRef?: string;   
    conversationRef?: string; 
    public?: boolean;
    private?: boolean;
    location?: Location;
    phoneNumber?: string;
     
    constructor(data: IGroupSettings) {

        this.title = data.title;
        this.byline = data.byline;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.clubRef = data.clubRef;
        this.conversationRef = data.conversationRef;
        this.public = data.public;
        this.private = data.private;
        this.location = data.location && new Location(data.location);
        this.phoneNumber = data.phoneNumber;
    }

    serialize() : IGroupSettings {
        return {
            title: this.title,
            byline: this.byline, 
            description: this.description, 
            imageUrl: this.imageUrl,
            clubRef: this.clubRef, 
            conversationRef: this.conversationRef,
            public: this.public, 
            private: this.private,
            location: this.location?.serialize(), 
            phoneNumber: this.phoneNumber
        }
    }

    update(data: IGroupSettings) {
        Object.keys(data).forEach(key => {
            //@ts-ignore
            if (typeof this[key] !== 'function') {
                if (key === "location") {
                    this.location = new Location(data.location);
                }
                else {
                    //@ts-ignore
                    this[key] = data[key];
                }
            }
        })
    }

    getTitle() {
        return this.title || '';
    }

    getByline() {
        if (this.byline) {
            return this.byline;
        }
        return '';
    }

    setPublic(flag: boolean) {
        this.public = flag;
    }
}
