import React, { useState } from 'react';
import { View, Keyboard, KeyboardAvoidingView, Platform } from 'react-native';
import { useTheme, Section, TextInput, IconButton } from 'components';
import { StringTable } from 'utils';
import { Conversation } from 'business';
import { createId } from 'tools';
import { useAppContext } from '../appcontext';
import { IConversationMessage } from 'interfaces';

interface Props {
    conversation: Conversation
}

export default function ConversationMessageComposer(props: Props) {

    const {
        conversation
    } = props;

    const { currentUser } = useAppContext();
    const [message, setMessage] = useState('');
    const theme = useTheme();
    return(
        <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={96}> 
            <Section style={{backgroundColor: theme["color-card"]}}>
                <View style={{flexDirection: 'row', alignItems: 'flex-end', margin: theme["spacing-large"], paddingBottom: theme["spacing-small"], paddingRight: theme["spacing-small"], backgroundColor: theme["color-paper"], borderRadius: theme["roundness"]}}>                    
                    <TextInput
                        value={message}
                        style={{flex: 1, marginRight: theme["spacing-small"], backgroundColor: 'transparent', borderWidth: 0}}
                        onChangeText={(d: string) => setMessage(d)}
                        placeholder={StringTable.getString('Message')}
                        multiline={true}
                        numberOfLines={3}
                    />
                    <IconButton 
                        name={'ico_send'} 
                        mode={message ? 'active' : 'default'}
                        onPress={() => {
                            if (currentUser && message && conversation) {
                                const m : IConversationMessage = {
                                    id: createId(14),
                                    sender: currentUser.id,
                                    message,
                                    date: new Date()
                                }
                                conversation.addMessage(m);
                                setMessage('');
                                Keyboard.dismiss();
                            }
                        }}
                    />
                </View>                    
            </Section>
        </KeyboardAvoidingView>

    )
}