import React, { useState } from 'react';
import { View, ScrollView, Platform } from 'react-native';
import { useTheme, KeyboardDismissingView, Button, Section, SectionHeader, TextInput, ImageUploadButton, Badge, CheckBox } from 'components';
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import ConfirmSheet from '../ConfirmSheet';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from '../useSheetStyle';
import { useAppContext } from '../../../appcontext';
import { createId, capitalize } from 'tools';
import ShotsSheet from './ShotsSheet';
import SideSheet from './SideSheet';
import LanguageSheet from './LanguageSheet';
import RegionsSheet from '../RegionsSheet';
import ChangePinCodeSheet from './ChangePinCodeSheet';
import CloseHeader from '../CloseHeader';
import useModal from '../../useModal';

export interface ISettingsSheetResult {
    e: IPressEvent
}

interface ConfirmSheetProps {
    children?: any,
    style?: any,
    resolve?: (data: ISettingsSheetResult | null ) => void
}

const shots = ['serve', 'lob', 'forehand', 'volley', 'smash', 'bandeja', 'backhand', 'vibora', 'bajada', 'chiquita'];

export default function SettingsSheet(props: ConfirmSheetProps) {

    const {
        children,
        resolve,
        style
    } = props;

    const { currentUser, locale, logout, requestNotificationPermission, canRequestNotificationPermission, notificationPermission, simpleRendering, setSimpleRendering, deleteUser } = useAppContext();
    const { pushModal, popModal, popAllModals } = useModal();
    const theme = useTheme();
    const [uploadingImage, setUploadingImage] = useState(false);
    const sheetStyle = useSheetStyle();
    
    const styles = {
        left: {
            flex: 1, 
            marginRight: theme["spacing-extrasmall"]
        },
        right: {
            flex: 1, 
            marginLeft: theme["spacing-extrasmall"]
        }
    }

    return currentUser ? (
        <KeyboardDismissingView style={[sheetStyle, style]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Settings')}/>
            <View style={{flex: 1}}>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <Section>
                        <SectionHeader
                            icon={'ico_addimage'}
                            label={`${StringTable.getString('Photo')}`}
                            style={{maxWidth: theme["card-maxwidth"]}}
                        />
                        <Section size={'M'}>
                            <ImageUploadButton 
                                shape={'padel'}
                                imageUrl={currentUser.settings.imageUrl}
                                uploading={uploadingImage}
                                maxImageSize={500}
                                upload={async(blob: any) => {
                                    const metaData = {
                                        cacheControl: 'public,max-age=31536000'
                                    }
                                    setUploadingImage(true);
                                    const storage = getStorage();
                                    const storageRef = ref(storage, `uploads/${currentUser.id}/${createId(7)}.jpg`);
                                    const uploadResult = await uploadBytes(storageRef, blob, metaData);
                                    const downloadURL = await getDownloadURL(uploadResult.ref);
                                    currentUser.setImageUrl(downloadURL);
                                    setUploadingImage(false);
                                }}
                            />
                        </Section>
                    </Section>

                    <Section>
                        <SectionHeader
                            icon={'ico_title'}
                            label={`${StringTable.getString('Name')}`}
                            style={{maxWidth: theme["card-maxwidth"]}}
                        />
                        <Section size={'M'}>
                            <TextInput
                                defaultValue={currentUser.settings.title || ''}
                                onEndEditing={(e: any) => currentUser.setTitle(e.nativeEvent.text.trim())}
                                iconLeft={'ico_title'}
                            />
                        </Section>
                    </Section>

                    {Platform.OS !== 'web' && notificationPermission !== 'granted' && canRequestNotificationPermission ? (
                        <Section>
                            <SectionHeader
                                icon={'ico_notifications'}
                                label={`${StringTable.getString('Notifications')}`}
                                style={{maxWidth: theme["card-maxwidth"]}}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={'ico_notifications'}
                                    label={StringTable.getString('Enable notifications')}
                                    onPress={requestNotificationPermission}
                                    mode={'primary'}
                                />
                                <Badge count={1} style={{position: 'absolute', top: -4, right: 0}}/>
                            </Section>
                        </Section>
                    ) : null }

                    <Section style={{flexDirection: 'row'}}>
                        <View style={styles.left}>
                            <SectionHeader
                                icon={'ico_thumbup'}
                                label={`${StringTable.getString('Best shot')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={'ico_thumbup'}
                                    label={capitalize(StringTable.getString(currentUser.settings.bestShot || 'Set best shot'))}
                                    onPress={async(e: IPressEvent) => {
                                        const bestShot = await pushModal((
                                            <ShotsSheet
                                                initialShot={currentUser.settings.bestShot}
                                                style={{flex: 1}}
                                                shots={shots}
                                            />
                                        ), {...e, pageY: 0});
                                        bestShot && currentUser.setBestShot(bestShot);
                                        popModal();
                                    }}
                                    mode={'contained'}
                                    color={theme["color-positive"]}
                                    penColor={theme["color-ink-contained"]}
                                />
                            </Section>                    
                        </View>
                        <View style={styles.right}>
                            <SectionHeader
                                icon={'ico_thumbdown'}
                                label={`${StringTable.getString('Worst shot')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={'ico_thumbdown'}
                                    label={capitalize(StringTable.getString(currentUser.settings.worstShot || 'Set worst shot'))}
                                    onPress={async(e: IPressEvent) => {
                                        const worstShot = await pushModal((
                                            <ShotsSheet
                                                initialShot={currentUser.settings.worstShot}
                                                style={{flex: 1}}
                                                shots={shots}
                                            />
                                        ), {...e, pageY: 0});
                                        worstShot && currentUser.setWorstShot(worstShot);
                                        popModal();
                                    }}
                                    mode={'negative'}
                                />
                            </Section>
                        </View>
                    </Section>

                    <Section style={{flexDirection: 'row'}}>
                        <View style={styles.left}>
                            <SectionHeader
                                icon={'ico_pickboth'}
                                label={`${StringTable.getString('Court side')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={currentUser.settings.side === "left" ? 'ico_pickleft' : ( currentUser.settings.side === "right" ? 'ico_pickright' : 'ico_pickboth')}
                                    label={capitalize(StringTable.getString(currentUser.settings.side || 'Set court side'))}
                                    onPress={async(e: IPressEvent) => {
                                        const courtSide : 'left' | 'right' | 'both' | undefined | null = await pushModal((
                                            <SideSheet initialSide={currentUser.settings.side}/>
                                        ), e);

                                        if (courtSide) {
                                            currentUser.setSide(courtSide);
                                        }
                                        popModal();    
                                    }}                            
                                    mode={'contained'}
                                />
                            </Section>                    
                        </View>
                        <View style={styles.right}>
                            <SectionHeader
                                icon={'ico_pickboth'}
                                label={`${StringTable.getString('Hand')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={currentUser.settings.hand === "left" ? 'ico_pickleft' : ( currentUser.settings.hand === "right" ? 'ico_pickright' : 'ico_pickboth')}
                                    label={capitalize(StringTable.getString(currentUser.settings.hand || 'Set hand'))}
                                    onPress={async(e: IPressEvent) => {
                                        const hand : 'left' | 'right' | 'both' = await pushModal((
                                            <SideSheet
                                                initialSide={currentUser.settings.hand}
                                            />
                                        ), e);
                                        if (hand) {
                                            currentUser.setHand(hand);
                                        }
                                        popModal();    
                                    }}                            
                                    mode={'contained'}
                                />
                            </Section>
                        </View>
                    </Section>   

                    <Section style={{flexDirection: 'row'}}>
                        <View style={styles.left}>
                            <SectionHeader
                                icon={'ico_language'}
                                label={`${StringTable.getString('Language')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    icon={locale?.flag}
                                    label={`${locale?.languageName || locale?.name}`}
                                    onPress={async(e: IPressEvent) => {
                                        const localeCode : string = await pushModal((
                                            <LanguageSheet/>
                                        ), e);
                                        localeCode && currentUser.setLocaleCode(localeCode);
                                        popModal();
                                    }}
                                    mode={'contained'}
                                    style={{justifyContent: 'flex-start'}}
                                />                        
                            </Section>
                        </View>

                        <View style={styles.right}>
                            <SectionHeader
                                icon={'ico_settings'}
                                label={`${StringTable.getString('Region')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    onPress={async (e: IPressEvent) => {
                                        const regionResult = await pushModal((
                                            <RegionsSheet
                                                selectedRegion={currentUser.settings.region}
                                                style={{flex: 1}}
                                            />
                                        ), {...e, pageY: 0})
                                        regionResult && currentUser.setRegion(regionResult);
                                        popModal();
                                    }} 
                                    mode={'contained'}
                                    label={currentUser.settings.region || StringTable.getString('Set region')}
                                    icon={'ico_language'}
                                />
                            </Section>                                    
                        </View>
                    </Section>   

                    <Section>
                        <SectionHeader
                            icon={'ico_settings'}
                            label={`${StringTable.getString('Graphics')}`}
                        />
                        <Section size={'M'}>
                            <CheckBox checked={simpleRendering} label={StringTable.getString('Simplified rendering')} onPress={() => setSimpleRendering(!simpleRendering)}/>
                        </Section>
                    </Section>

                    <Section style={{flexDirection: 'row'}}>
                        <View style={styles.left}>
                            <SectionHeader
                                icon={'ico_settings'}
                                label={`${StringTable.getString('Account')}`}
                            />
                            <Section size={'M'}>
                                <Button 
                                    onPress={async (e: IPressEvent) => {
                                        const result = await pushModal((
                                            <ChangePinCodeSheet style={{flex: 1}}/>
                                        ), {...e, pageY: 0});
                                        popModal();
                                    }} 
                                    mode={'contained'} 
                                    label={StringTable.getString('Change PIN code')}
                                />
                            </Section>                                    
                            <Section size={'M'}>
                                <Button 
                                    onPress={async (e: IPressEvent) => {
                                        const confirmResult : IConfirmSheetResult | null = await pushModal((
                                            <ConfirmSheet
                                                title={StringTable.getString('Confirm log out')}
                                                doneLabel={StringTable.getString('Log out')}
                                                doneMode={'dangerous'}
                                            >
                                            </ConfirmSheet>
                                        ), e);

                                        if (confirmResult?.confirmed) {
                                            logout();
                                            popAllModals();
                                        }
                                        popModal();
                                    }} 
                                    mode={'negative'} 
                                    label={StringTable.getString('Log out')}
                                />
                            </Section>                 

                                <Section size={'M'}>
                                    <Button 
                                        onPress={async (e: IPressEvent) => {
                                            const confirmResult : IConfirmSheetResult | null = await pushModal((
                                                <ConfirmSheet
                                                    title={StringTable.getString('Confirm delete user')}
                                                    doneLabel={StringTable.getString('Delete')}
                                                    doneMode={'dangerous'}
                                                >
                                                </ConfirmSheet>
                                            ), e);

                                            if (confirmResult?.confirmed) {
                                                deleteUser();
                                                popAllModals();
                                            }
                                            popModal();
                                        }} 
                                        mode={'dangerous'} 
                                        label={StringTable.getString('Delete user')}
                                    />
                                </Section>

                        </View>

                    </Section>                  

                </ScrollView>
            </View>

            <Section size={'XL'} style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                <Button onPress={(e: IPressEvent) => resolve && resolve({e: e})} mode={'primary'} color={theme["color-blue"]} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Done')}/>
            </Section>            
        </KeyboardDismissingView>
    ) : null;
}
