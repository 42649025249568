import React, { useState, useEffect } from 'react';
import { View, Image, Text } from 'react-native';
import { useTheme, Card, Subheader, Paragraph } from 'components';
import { getDatabase, ref, get } from "firebase/database";
import { DeviceType } from 'expo-device';
import { User, UserIdentity } from 'business';

interface UserViewProps {
    user?: User,
    userId: string, 
    date?: Date,
    loginCount?: number, 
    device?: DeviceType,
    os?: string,
    appVersion?: number, 
    accountType?: 'registered' | 'coach' | 'club',
    onPress?: any

}
export default function UserView(props: UserViewProps) {

    const {
        user,
        userId,
        date,
        loginCount,
        device,
        os, 
        appVersion, 
        accountType,
        onPress
    } = props;

    const [identity, setIdentity] = useState<UserIdentity>();
    useEffect(() => {
        user && setIdentity(user.toUserIdentity());
    }, [userId]);

    const theme = useTheme();

    return identity ? (
        <Card style={{marginTop: theme["spacing-small"], flexDirection: 'row'}} onPress={onPress}>
            <Image style={{width: 48, height: 48, borderRadius: 24, overflow: 'hidden', marginRight: theme["spacing-small"]}} source={{uri: identity.imageUrl}}/>
            <View style={{flex: 1}}>
                <Subheader numberOfLines={1}>{identity.getTitle()}</Subheader>
                <Text selectable={true} style={{color: theme["color-ink"]}}>{identity.id}</Text>
                {date ? <Paragraph>{`Logged in (${loginCount}) ${date.toLocaleString()}`}</Paragraph> : null }
                {device ? <Paragraph>{`device: ${DeviceType[device]} os: ${os} app: ${appVersion}`}</Paragraph> : null }
                {accountType ? <Paragraph>{`account: ${accountType}`}</Paragraph> : null }
                {user ? <Paragraph>{`Rating ${Math.round(user.state.rating)}`}</Paragraph> : null }
            </View>
        </Card>
    ) : (
        <Card style={{marginTop: theme["spacing-small"]}}>
            <Paragraph>{'Missing user ' + userId}</Paragraph>
        </Card>
    )
}
