import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { Group } from 'business';
import { useGroups, usePublicGroups, useStyles, usePreloadUsers } from '../hooks';
import { DoubleFlatList, TabHeader, GroupCard, SectionHeader } from '../composites';
import { NavigationProvider } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import WaitingScreen from './WaitingScreen';

export default function GroupsContainer(props: any) {

    const [showGroupsScreen, setShowGroupsScreen] = useState(false);
    const isFocused = useIsFocused();
    useEffect(() => {
        setShowGroupsScreen(isFocused);
    }, [isFocused]);

    return(
        <View style={{flex: 1}}>
            <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}>
                <WaitingScreen/>
            </View>
            {showGroupsScreen ? <GroupsScreen {...props}/> : null }
        </View>
    )
}

function GroupsScreen(props: any) {

    const { currentUser } = useAppContext();
    const styles = useStyles();
    const [publicGroups, onLoadMorePublicGroups] = usePublicGroups(currentUser?.settings.region);
    const [groups, onLoadMoreGroups] = useGroups(currentUser?.id);

    const membersLoaded = usePreloadMembers(groups);

    const isFocused = useIsFocused();

    const { setTarget } = useActions();
    useFocusEffect(
        React.useCallback(() => {
            setTarget(null);
        }, [])
    );

    const loading = 
        publicGroups === undefined || 
        groups === undefined ||
        !membersLoaded || 
        !isFocused;

    return loading ? <WaitingScreen/> : (
        <NavigationProvider>
            <View style={styles.screen}>
                <TabHeader title={StringTable.getString('Groups')}/>
                <DoubleFlatList
                    primaryData={groups}
                    onPrimaryEndReached={onLoadMoreGroups}
                    secondaryData={publicGroups}
                    onSecondaryEndReached={onLoadMorePublicGroups}
                    primaryHeader={(
                        <SectionHeader
                            header={StringTable.getString(groups?.length ? 'Your groups' : 'No groups yet')}
                            counter={groups?.length ? `${groups.length}` : undefined}
                            sectionId={'group'}
                            isAdmin={true}
                            isPrimary={true}
                            addLabel={StringTable.getString('Create')}
                        />
                    )}
                    secondaryHeader={(
                        <SectionHeader
                            header={publicGroups?.length ? StringTable.getString('Public groups') : undefined}
                            counter={publicGroups?.length ? `${publicGroups.length}` : undefined}
                            isAdmin={false}
                        />
                    )}                
                    renderPrimaryItem={({item, index}) => (
                        <GroupCard group={item}/>
                    )}
                    renderSecondaryItem={({item, index, horizontal}) => (
                        <GroupCard group={item} style={horizontal ? {marginRight: 0} : undefined}/>
                    )}
                />
            </View>
        </NavigationProvider>
    );
}

function usePreloadMembers(groups: Group[] | undefined) {

    const [memberRefs, setMemberRefs] = useState();
    useEffect(() => {
        if (groups) {
            let members : {[groupId: string]: string[] | undefined }= {};
            for (const group of groups) {
                members[group.id] = group.participation.userRefs
            }
            setMemberRefs(Object.values(members).reduce((acc : string[], curr) => curr ? ([...acc, ...curr]) : acc, []));
        }
    }, [groups]);

    const preloaded = usePreloadUsers(memberRefs);
    return preloaded;
}
