import React, { useMemo, useEffect } from 'react';
import { SectionList } from 'components';
import { useClub, useClubCompetitions, useClubGroups, useLayout, useStyles, useGoBackIfNull, useRecentClubCompetitions } from '../hooks';
import { DocHeader, DocPanel, DocMenu, CompetitionCard, GroupCard, SectionHeader } from '../composites';
import { StringTable } from 'utils';
import { Competition, Group, Club } from 'business';
import { NavigationProvider } from '../navigation';
import { useActions } from '../actions';
import { useIsFocused } from '@react-navigation/native';
import WaitingScreen from './WaitingScreen';

export default function ClubScreen(props: ScreenProps) {    

    const { route } = props;    
    const { clubId } = route.params;

    const club : Club | null | undefined = useClub(clubId)
    const competitions = useClubCompetitions(clubId);
    const recentCompetitions = useRecentClubCompetitions(clubId);
    
    const groups = useClubGroups(clubId);
    const sections = useClubSections(competitions, recentCompetitions, groups);

    const { columns } = useLayout();
    const styles = useStyles();
    const { setTarget } = useActions();

    const isFocused = useIsFocused();
    useEffect(() => {
        isFocused && setTarget({club});
    }, [isFocused, club]);

    useGoBackIfNull(club);
    
    return (
        <NavigationProvider>
            { club ? (
                <DocHeader title={club?.settings.title} floating={true}>
                    <SectionList
                        style={{flex: 1}} 
                        numColumns={columns}
                        data={sections}
                        keyExtractor={(item) => item?.id || item}
                        ListHeaderComponent={(
                            <DocPanel 
                                imageUrl={club?.settings?.imageUrl} 
                                title={club?.settings?.getTitle()} 
                                byline={club?.settings?.byline} 
                                description={club?.settings?.description}
                            >
                                <DocMenu title={club?.settings?.getTitle()}/>
                            </DocPanel>
                        )}
                        renderSectionItem={(item, index, sectionId) => sectionId === 'recent' || sectionId === 'event' ? (
                            <CompetitionCard competition={item}/> 
                        ) : (
                            <GroupCard group={item}/>
                        )}
                        renderSectionHeader={(header, counter, sectionId) => (
                            <SectionHeader 
                                header={header} 
                                counter={counter} 
                                sectionId={sectionId}
                                canAdd={sectionId === 'recent' ? false : true}
                            />
                        )}
                    />
                </DocHeader>
            ) : <WaitingScreen/> }
        </NavigationProvider>

    );
}


function useClubSections(clubCompetitions?: Competition[], recentClubCompetitions?: Competition[], clubGroups?: Group[]) {
    
    const listData : ISectionListSection[] = useMemo(() => {

        const p = [];
        clubCompetitions?.length && p.push({
            id: 'event',
            header: `${StringTable.getString('Upcoming events')}`,
            counter: `${clubCompetitions?.length || 0}`,
            data: clubCompetitions
        });
        recentClubCompetitions?.length && p.push({
            id: 'recent',
            header: `${StringTable.getString('Recent events')}`,
            counter: `${recentClubCompetitions?.length || 0}`,
            data: recentClubCompetitions
        });
        clubGroups?.length && p.push({ 
            id: 'group',
            header: StringTable.getString('Groups'),
            counter: `${clubGroups?.length || 0}`,
            data: clubGroups
        });
        return p;
    }, [clubCompetitions, clubGroups]);

    return listData;
}