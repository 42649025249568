import { Club, Competition, Group, Conversation, User } from 'business';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import parse from './parse';
import save from './save';

export default async function getInstances<T extends User | Competition | Group | Club | Conversation>(collectionId: string, documentIds: string[] | undefined, app?: any) : Promise<Array<T | null> | undefined> {

    const _classes = {
        "groups": Group,
        "clubs": Club,
        "competitions": Competition,
        "conversations": Conversation, 
        "users": User
    }
    const _class = _classes[collectionId as keyof typeof _classes];

    if (collectionId && documentIds) {
        const docs = await Promise.all(documentIds.map((documentId: string) => getDoc(doc(getFirestore(app), collectionId, documentId))));
        const instances = docs.map(doc => (doc.exists() ? new _class(parse(collectionId, doc), save) : null));
        //@ts-ignore
        return instances;
    }

    return undefined;
}
