import React from 'react';
import { View, FlatList } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { StringTable } from 'utils';
import { useConversations, useStyles, useLayout } from '../hooks';
import { TabHeader, ConversationCard } from '../composites';
import { useTheme } from 'components';
import { useAppContext } from '../appcontext';
import { useActions } from '../actions';
import { NavigationProvider } from '../navigation';

export default function ConversationsScreen(props: any) {

    const { currentUser } = useAppContext();
    const styles = useStyles();
    const theme = useTheme();
    const [conversations, onLoadMoreConversations] = useConversations(currentUser?.id);

    const { setTarget } = useActions();
    useFocusEffect(
        React.useCallback(() => {
            setTarget(null);
        }, [])
    );

    const { layout, columns } = useLayout();

    return(
        <NavigationProvider>
            <View style={styles.screen}>
                <TabHeader title={StringTable.getString('Chats')}/>
                <View style={{flex: 1, marginTop: theme["spacing-medium"]}}>
                    <FlatList
                        data={conversations}
                        key={columns}
                        numColumns={columns}
                        onEndReached={onLoadMoreConversations}
                        onEndReachedThreshold={0.1}
                        keyExtractor={item => item.id}
                        renderItem={({item, index}) => (
                            <View style={columns === 2 ? {flex: 0.5} : {flex: 1}}>
                                <ConversationCard 
                                    conversation={item} 
                                />
                            </View>
                        )}
                    />
                </View>
            </View>
        </NavigationProvider>
    ) 
}
