import React from 'react';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import EventSheet from './sheets/EventSheet/EventSheet';
import { Competition, User } from 'business';
import { Factory } from '../../factories';
import { getInstance } from 'bridge';

export default async function duplicateCompetition(e: IPressEvent, navigate: (params: INavigationParams) => any, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User | null) {

    if (!currentUser) { return }

    let competition : Competition | null | undefined = target?.competition;
    if (!competition) { return }

    if (competition.settings.parentCompetitionRef) {
        competition = await getInstance('competitions', competition.settings.parentCompetitionRef);
        if (!competition) { return }
    }

    const result : IEventSheetResult | null = await pushModal((
        <EventSheet
            style={{flex: 1}}
            title={StringTable.getString('Copy event')}
            initialSettings={{...competition.settings, date: undefined }}
            doneLabel={StringTable.getString('Copy')}
        />
    ), {...e, pageY: 0});

    if (result) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Copying event')}/>
        ), e);

        const id = await Factory.duplicateCompetition(competition, result.settings, currentUser?.id, target?.group);
        id && navigate({competitionId: id});
    }

    popAllModals();
}
