import React from 'react';
import { View } from 'react-native';
import { useTheme, Paragraph } from 'components';
import useSheetStyle from './useSheetStyle';

interface MenuSheetProps {
    title: string, 
    children?: any, 
    style?: any
}
export default function MenuSheet(props: MenuSheetProps) {

    const { 
        title, 
        children,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();

    return(
        <View style={[sheetStyle, style]}>
            <Paragraph style={{alignSelf: 'center'}}>{title}</Paragraph>
            { React.Children.map(children, child => child && (
                <View style={{paddingTop: theme["spacing-medium"]}}>
                    {child}
                </View>
            ))}
        </View>
    )
}