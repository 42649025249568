//@ts-nocheck
import React, { useState } from 'react';
import { View } from 'react-native';
import { Subheader, useTheme, Pressable, Icon } from 'components';

interface Props {
    onPress: () => void,
    value: number | string | undefined,
    color?: string,
    penColor?: string,
    asIcon?: boolean,
    disabled?: boolean,
    style?: any
}

export default function NumPadButton(props: Props) {

    const {
        onPress: onPressProp,        
        value,
        asIcon,
        color,
        penColor,
        disabled,
        style : styleProp
    } = props;

    const [pressed, setPressed] = useState(false);
    const theme = useTheme();

    const buttonStyle = {
        minWidth: 48,
        height: 48, 
        borderRadius: 18, 
        backgroundColor: color || theme["color-ink-supersoft"],
        justifyContent: 'center', 
        alignItems: 'center',
        opacity: pressed ? 0.8 : 1.0
    }

    const shadowStyle = {
        shadowColor: theme["color-ink"],
        shadowOffset: {
            width: 1,
            height: 1,
        },
        shadowOpacity: 0.4,
        shadowRadius: 1,
        elevation: 1
    }   


    return value !== undefined ? (
        <Pressable
            style={[buttonStyle, pressed ? {} : shadowStyle, styleProp]}
            onPressIn={() => setPressed(true)}
            onPressOut={() => setPressed(false)}
            onPress={() => {
                setPressed(false);
                !disabled && onPressProp();
            }}
        >
            { asIcon ? <Icon name={value} size={24} color={penColor || theme["color-ink"]}/> : <Subheader style={{color: penColor || theme["color-ink"]}}>{`${value}`}</Subheader> }
        </Pressable>
    ) : (
        <View style={[buttonStyle, shadowStyle]}/>
    )
}
