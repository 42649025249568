import React from 'react';
import { View } from 'react-native';

import Icon from './helpers/Icon';
import Paragraph from './typography/Paragraph';
import Caption from './typography/Caption';
import useTheme from './theme/useTheme';
import useThemeStyle from './theme/useThemeStyle';
import IconButton from './IconButton';

export interface BulletProps {
    icon?: string, 
    label?: string,
    mode?: string,
    color?: string,
    numberOfLines: number,
    style?: any,
    size?: string,
    iconBackgroundColor?: string
}

Bullet.defaultProps = {
    numberOfLines: 1
}
export default function Bullet(props: BulletProps) {

    const {
        icon, 
        label,
        mode,
        size,
        style : styleProp, 
        numberOfLines,
        color : colorProp, 
        iconBackgroundColor
    } = props;

    const theme = useTheme();
    //@ts-ignore
    const { iconSize, color, ...textStyle } = useThemeStyle('Bullet', props);

    return(
        <View style={[{flexDirection: 'row', alignItems: 'center'}, styleProp]}>
            <View style={{width: 20, height: 20, borderRadius: 10, backgroundColor: iconBackgroundColor || theme["color-paper"], justifyContent: 'center', alignItems: 'center'}}>
                <Icon name={icon} color={colorProp || color} size={12}/>
            </View>
            {size === 'M' ? (
                <Paragraph weight={'book'} numberOfLines={numberOfLines} style={{marginLeft: theme["spacing-extrasmall"], color: colorProp || color}}>{label}</Paragraph>
            ) : (
                <Caption numberOfLines={numberOfLines} style={{marginLeft: theme["spacing-extrasmall"], color: colorProp || color}}>{label}</Caption>
            )}
        </View>
    )
}