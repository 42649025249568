import React, { useState } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import TeamCard from '../../composites/TeamCard';

interface Props {
    title: string,
    cancelLabel: string,
    doneLabel: string,
    teams: [string, string][];
    children?: any,
    style?: any,
    resolve?: (team: [string, string] | undefined | null ) => void
}

TeamsSheet.defaultProps = {
    cancelLabel: StringTable.getString('Cancel'),
}
export default function TeamsSheet(props: Props) {

    const {
        title,
        cancelLabel,
        doneLabel,
        resolve,
        teams,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();

    const [selectedTeam, setSelectedTeam] = useState<[string, string] | undefined>();

    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            <ScrollView style={{flex: 1}}>
                <Section size={'M'} style={{paddingLeft: theme["spacing-medium"]}}>
                    { teams.map(team => (
                        <TouchableOpacity key={team[0]} style={{marginTop: theme["spacing-medium"]}} onPress={() => setSelectedTeam(team)}>
                            <View pointerEvents={'none'}>
                                <TeamCard userRefs={team}/>
                                {selectedTeam === team ? <View style={{position: 'absolute', top: theme["spacing-small"], bottom: theme["spacing-small"], left: -7, width: 4, backgroundColor: theme["color-primary"]}}/> : null }
                            </View>
                        </TouchableOpacity>
                    ))}
                </Section>
            </ScrollView>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={cancelLabel}/>
                <Button onPress={() => resolve && resolve(selectedTeam)} mode={selectedTeam ? 'primary' : 'passive'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>            
        </View>
    )
}
