import React from 'react';
import { View } from 'react-native';
import useThemeStyle from './theme/useThemeStyle';

export default function Section(props: any) {

    const {
        style : styleProp,
        children,
        ...otherProps
    } = props;

    const style = useThemeStyle("Section", props);

    return(
        <View style={[style, styleProp]} {...otherProps}>
            {children}
        </View>
    )
}