import React, { useState } from 'react';
import { View, ActivityIndicator, StyleSheet, ScrollView } from 'react-native';
import { Button, Section, useTheme, Paragraph } from 'components';
import { adminGetDocument, adminSetDocument, createFromInstance, adminGetRecentlyChanged } from 'bridge';
import { IPressEvent } from 'interfaces';
import { Competition } from 'business';
import useModal from '../../actions/useModal';
import ConfirmSheet from '../../actions/sheets/ConfirmSheet';

interface Props {
    devApp: any,
    stagingApp: any,
    prodApp: any,
    backupApp: any
}

export default function DocumentsView(props: Props) {

    const { devApp, stagingApp, prodApp, backupApp } = props;
    const { pushModal, popAllModals } = useModal();
    const theme = useTheme();
    const [busy, setBusy] = useState(false);

    async function cloneSystemDocs(fromDb: any, toDb: any) {
        setBusy(true);
        
        const locales = await adminGetDocument('system/locales', fromDb);
        await adminSetDocument('system/locales', locales, toDb);

        const regions = await adminGetDocument('system/regions', fromDb);
        await adminSetDocument('system/regions', regions, toDb);

        const settings = await adminGetDocument('system/settings', fromDb);
        await adminSetDocument('system/settings', settings, toDb);

        const stringtable = await adminGetDocument('system/stringtable', fromDb);
        await adminSetDocument('system/stringtable', stringtable, toDb);

        setBusy(false);
    }

    async function copyStats() {
        setBusy(true);
        const stats = await adminGetDocument('system/stats', prodApp);
        await adminSetDocument('system/stats', stats, devApp);
        setBusy(false);
    }

    const [log, setLog] = useState('');
    const [backupInProgress, setBackupInProgress] = useState(false);
    const [resaveInProgress, setResaveInProgress] = useState(false);

    async function resave() {
        setResaveInProgress(true);
        setLog('Start resave\n')
        setLog(prev => prev + `fetching documents\n`);
        //@ts-ignore
        const competitions : Competition[] | null | undefined = await adminGetRecentlyChanged(prodApp, 'competitions');
        if (competitions) {
            for (const competition of competitions) {
                if (competition.settings.parentCompetitionRef === undefined) {
                    await createFromInstance(competition, prodApp);
                    setLog(prev => prev + `re-saved ${competition?.getTitle()}\n`);
                }
            }
        }
        setResaveInProgress(false);
    }
    
    async function backup() {
        setBackupInProgress(true);
        const backupData = await adminGetDocument('system/backup', backupApp);
        const lastBackupDate : Date = backupData?.date?.toDate?.() || new Date(2022,10,10);
        setLog(`Back up docs from padel-id\nModified after ${lastBackupDate.toLocaleString()}\n`);
        await adminSetDocument('system/backup', { date: new Date()}, backupApp );

        const clubs = await adminGetRecentlyChanged(prodApp, 'clubs', lastBackupDate);
        const groups = await adminGetRecentlyChanged(prodApp, 'groups', lastBackupDate);
        const competitions = await adminGetRecentlyChanged(prodApp, 'competitions', lastBackupDate);
        const users = await adminGetRecentlyChanged(prodApp, 'users', lastBackupDate);
        const conversations = await adminGetRecentlyChanged(prodApp, 'conversations', lastBackupDate);
        const schedules = await adminGetRecentlyChanged(prodApp, 'schedules', lastBackupDate);

        if (clubs) {
            for (const club of clubs) {
                try {
                    await createFromInstance(club, backupApp);
                }
                catch(err) {
                    console.log('createFromInstance', err);
                }
                //@ts-ignore
                setLog(prev => prev + `[club]: ${club.settings?.title || club.id}\n`)
            }    
        }

        if (groups) {
            for (const group of groups) {
                await createFromInstance(group, backupApp);
                //@ts-ignore
                setLog(prev => prev + `[group]: ${group.settings?.title ||group.id}\n`)
            }    
        }

        if (competitions) {
            for (const competition of competitions) {
                await createFromInstance(competition, backupApp);
                //@ts-ignore
                setLog(prev => prev + `[competition]: ${competition.settings?.title ||competition.id}\n`)
            }    
        }

        if (users) {
            for (const user of users) {
                await createFromInstance(user, backupApp);
                //@ts-ignore
                setLog(prev => prev + `[user]: ${user.settings?.title ||user.id}\n`)
            }    
        }

        if (conversations) {
            for (const conversation of conversations) {
                await createFromInstance(conversation, backupApp);
                //@ts-ignore
                setLog(prev => prev + `[conversation]: ${conversation.title ||conversation.id}\n`)
            }    
        }

        if (schedules) {
            for (const schedule of schedules) {
                await createFromInstance(schedule, backupApp);
                //@ts-ignore
                setLog(prev => prev + `[schedule]: ${Object.values(schedule.competitionNames || {}).reduce((acc, curr) => `${acc} ${curr}`)}\n`);
            }
        }

        const stringtable = await adminGetDocument('system/stringtable', prodApp);
        const locales = await adminGetDocument('system/locales', prodApp);
        const regions = await adminGetDocument('system/regions', prodApp);
        const settings = await adminGetDocument('system/settings', prodApp);

        await adminSetDocument('system/stringtable', stringtable, backupApp);
        setLog(prev => prev + `[system]: stringtable\n`);
        await adminSetDocument('system/locales', locales, backupApp);
        setLog(prev => prev + `[system]: locales\n`);
        await adminSetDocument('system/regions', regions, backupApp);
        setLog(prev => prev + `[system]: regions\n`);
        await adminSetDocument('system/settings', settings, backupApp);
        setLog(prev => prev + `[system]: settings\n`);
        setLog(prev => prev + `DONE\n`);

        setBackupInProgress(false);
    }


    return busy ? (
        <View style={{ flex: 1 }}>
            <ActivityIndicator/>
        </View>
    ) : (
        <View style={{ flex: 1 }}>
            <Button 
                icon={'ico_copy'} 
                style={styles.button}
                label={'Copy system docs from dev to staging'} 
                onPress={async(e: IPressEvent) => await cloneSystemDocs(devApp, stagingApp)}
            />
            <Button 
                icon={'ico_copy'} 
                style={styles.button}
                label={'Copy system docs from staging to production'} 
                onPress={async(e: IPressEvent) => {
                    const confirmResult : IConfirmSheetResult | null = await pushModal((
                        <ConfirmSheet title={'Confirm copy system docs from staging to production'} doneLabel={'Copy'}/>
                    ), e)
                    popAllModals();
                    if (confirmResult?.confirmed) {
                        await cloneSystemDocs(stagingApp, prodApp)
                    }
                }}
            />
            <Button 
                icon={'ico_copy'} 
                style={styles.button}
                label={'Copy usage stats from production to dev'} 
                onPress={async(e: IPressEvent) => {
                    const confirmResult : IConfirmSheetResult | null = await pushModal((
                        <ConfirmSheet title={'Confirm copy usage stats to dev'} doneLabel={'Copy'}/>
                    ), e)
                    popAllModals();
                    if (confirmResult?.confirmed) {
                        await copyStats()
                    }
                }}
            />

            <Section>
                <Button 
                    icon={'ico_copy'} 
                    style={styles.button}
                    label={resaveInProgress? 'Working' : 'Re-save all competitions'} 
                    mode={resaveInProgress ? 'passive' : undefined}
                    onPress={async(e: IPressEvent) => await resave()}
                />

                <Button 
                    icon={'ico_copy'} 
                    style={styles.button}
                    label={backupInProgress? 'Working' : 'Backup all user content from padel-id'} 
                    mode={backupInProgress ? 'passive' : undefined}
                    onPress={async(e: IPressEvent) => await backup()}
                />
            </Section>

            <Section style={{height: 500}}>
                { log ? (
                    <View style={{flex: 1, marginTop: theme["spacing-large"]}}>
                        <ScrollView style={{flex: 1, padding: 8, backgroundColor: theme["color-ink-supersoft"], borderRadius: theme["roundness"]}}>
                            <Paragraph>{log}</Paragraph>
                        </ScrollView>
                    </View>
                ) : null }
            </Section>
        </View>
    );
};

const styles = StyleSheet.create({
    button: {
        marginTop: 12
    }
})