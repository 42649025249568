import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { useTheme, Section, Subheader } from 'components';
import useSheetStyle from './useSheetStyle';


interface SavingMessageSheetProps {
    title: string,
    children?: any,
    style?: any,
    resolve?: (data: null ) => void
}

export default function SavingMessageSheet(props: SavingMessageSheetProps) {

    const {
        title,
        resolve,
        children,
        style
    } = props;

    const sheetStyle = useSheetStyle();

    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            { children ? (
                <Section>
                    {children}
                </Section>

            ) : null }

            <Section>
                <ActivityIndicator/>
            </Section>

            <Section/>
        </View>
    )
}
