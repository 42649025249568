import React, { useState } from 'react';
import { View, Image, Platform, ActivityIndicator } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Pressable from './helpers/Pressable';
import Icon from './helpers/Icon';
import useTheme from './theme/useTheme';
import Paragraph from './typography/Paragraph';
import Padel from './Padel'

interface ImageUploadButtonProps {
    inkColor?: string,
    imageUrl?: string,
    uploading?: boolean,
    maxImageSize: number, 
    upload: (blob: any) => any,
    shape: 'padel' | 'square' | 'circle'
}

ImageUploadButton.defaultProps = {
    shape: 'square'
}

export default function ImageUploadButton(props : ImageUploadButtonProps) {

    const { 
        inkColor : inkColorProp,
        imageUrl,
        uploading,
        maxImageSize,
        upload,
        shape
    } = props;

    const theme = useTheme();
    const inkColor = inkColorProp || theme["color-primary"];

    const [error, setError] = useState<string>();
    if (isNaN(maxImageSize) || maxImageSize > 1000) {
        throw new Error('ImageUploadButton, bad property maxImageSize');
    }

    async function onPress() {
        if (Platform.OS !== 'web') {
            const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            if (status !== 'granted') {
                setError('Permission denied: ' + status);
                return;
            }
        }
        const result : ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: [4, 3],
            quality: 1,
        });

        const pickedImage = result?.assets?.[0];
        if (!pickedImage || result.canceled || !pickedImage.width || !pickedImage.height) {
            return;
        }

        const largestDimension = pickedImage.width > pickedImage.height ? 'width' : 'height';
        const resizedPhoto = await manipulateAsync(
            pickedImage.uri,
            [{ resize: { [largestDimension]: maxImageSize } }],
            { compress: 0.7, format: SaveFormat.JPEG },
        );
        
        if (upload && resizedPhoto?.uri) {
            try {
                // Why are we using XMLHttpRequest? See:
                // https://github.com/expo/expo/issues/2402#issuecomment-443726662
                const blob = await new Promise((resolve, reject) => {
                    const xhr = new XMLHttpRequest();
                    xhr.onload = function () {
                        resolve(xhr.response);
                    };
                    xhr.onerror = function (e) {
                        console.log(e);
                        reject(new TypeError("Network request failed"));
                    };
                    xhr.responseType = "blob";
                    xhr.open("GET", resizedPhoto.uri, true);
                    xhr.send(null);
                });
                upload(blob);
            } catch(err) {
                console.log('upload error', err);
            }
        }
    }

    return (
        <Pressable onPress={!uploading && onPress}>
            { shape === 'padel' ? (
                <Padel size={'XL'} strokeColor={theme["color-ink"]} strokeWidth={1} imageBackground={imageUrl} showLogo={false}>
                    {uploading ? <ActivityIndicator color={theme["color-ink"]}/> : (imageUrl ? null : <Icon name={'ico_addimage'} size={32} color={theme["color-ink"]}/>) }
                </Padel>
            ) : (
                <View style={{width: 100, height: 100, overflow: 'hidden', backgroundColor: theme["color-card"], borderRadius: shape === 'circle' ? 50 : theme["roundness"], justifyContent: 'center', alignItems: 'center'}}>
                    {uploading ? <ActivityIndicator color={inkColor}/> : (imageUrl ? null : <Icon name={'ico_addimage'} size={32} color={inkColor}/>) }
                    { imageUrl ? <Image source={{uri: imageUrl}} style={{width: '100%', height: '100%'}}/> : null }
                </View>                
            )}

            { error ? (
                <Paragraph style={{color: theme["color-negative"]}}>{error}</Paragraph>
            ) : null }
        </Pressable>        
    );
}

ImageUploadButton.defaultProps = {
    maxImageSize: 200
}