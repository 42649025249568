import { Competition, Group, Conversation, Club, SuperSchedule, Match, User } from 'business';
import { createFromInstance, searchAsync, destroyFromInstance, destroyFromRef, getInstance, getInstances } from 'bridge';
import { createId, arrayUnique } from 'tools';
import { StringTable } from 'utils';
import { ICompetitionSettings, IGroupSettings, IParticipation, IClubSettings, ISuperScheduleCourt } from 'interfaces';

class _Factory {

    defaultCompetitionSettings: ICompetitionSettings;

    constructor() {
        this.defaultCompetitionSettings = {
            type: "MATCH",
            slots: 4,
            duration: 90, 
            courts: { 0: { courtId: 0, courtName: 'Court 1', queue: []}},
        };
    }

    async makeCompetition(settings: ICompetitionSettings, participation: IParticipation, id?: string) {
        const competition = new Competition({ id: id || createId(14), settings, participation, state: {}});
        await createFromInstance(competition);
        return competition.id;
    }

    async updateCompetition(competition: Competition, changedSettings: ICompetitionSettings) {
        if (!competition) { return }
        competition.updateSettings(changedSettings);

        //In the case of big tournaments, some changes should be propagated to children
        if (competition.settings.type === 'BIG TOURNAMENT') {
            const propagatedChanges : Partial<ICompetitionSettings> = {};
            if (changedSettings.date) { propagatedChanges.date = changedSettings.date};
            if (changedSettings.title) { propagatedChanges.parentTitle = changedSettings.title};
            if (Object.keys(propagatedChanges).length) {
                //@ts-ignore
                const childCompetitions : Competition[] | undefined = await searchAsync('competitions', { parentCompetitionRef: competition.id});
                if (childCompetitions?.length) {
                    for (const childCompetition of childCompetitions) {
                        childCompetition.updateSettings(propagatedChanges);
                    }
                }
            }
        }
    }

    async deleteCompetition(competition: Competition) {
        //In the case of big tournaments, we should delete child tournaments
        if (competition.settings.type === 'BIG TOURNAMENT') {
            //@ts-ignore
            const childCompetitions : Competition[] | undefined = await searchAsync('competitions', { parentCompetitionRef: competition.id});
            if (childCompetitions?.length) {
                for (const childCompetition of childCompetitions) {
                    this.deleteCompetition(childCompetition);
                }
            }
        }
        if (competition.superScheduleRef) {
            destroyFromRef('schedules', competition.superScheduleRef);
        }
        await competition.destroy();
        await destroyFromInstance(competition);
    }

    /**
     * Duplicate a competition and optionally its sub-competitions.
     * @param competition The competition to duplicate.
     * @param newSettings The settings for the duplicated competition.
     * @param currentUserId The ID of the current user.
     * @param group (Optional) The group associated with the competition.
     * @returns The ID of the duplicated competition.
     */
    async duplicateCompetition(
        competition: Competition,
        newSettings: ICompetitionSettings,
        currentUserId: string,
        group?: Group
    ) {
        const id = createId(14);

        const subCompetitions: Competition[] | undefined =
            competition.settings.type === "BIG TOURNAMENT"
                ? await searchAsync<Competition>("competitions", { parentCompetitionRef: competition.id })
                : undefined;

        if (subCompetitions) {
            for (const subCompetition of subCompetitions) {
                console.log('duplicate subCompetition ' + subCompetition.settings.title);
                await this.duplicateCompetition(subCompetition, { ...subCompetition.settings, date: newSettings.date, parentCompetitionRef: id }, currentUserId, group);
            }
        }

        //Continue for both BIG TOURNAMENTS and other types
        const admin = competition.participation.admin;
        const userRefs = competition.settings.fixedTeams ? [] : [currentUserId];
        const invitedRefs = (subCompetitions || []).reduce((acc: string[], curr: Competition) => {
            return arrayUnique([
                ...acc,
                ...(curr.participation.admin || []),
                ...(curr.participation.userRefs || []),
                ...(curr.participation.reservesRefs || []),
                ...(curr.participation.invitedRefs || [])
            ]);
        }, arrayUnique([
            ...(competition.participation.admin || []),
            ...(competition.participation.userRefs || []),
            ...(competition.participation.reservesRefs || []),
            ...(competition.participation.invitedRefs || [])
        ]));

        await this.makeCompetition(newSettings, { admin, userRefs, invitedRefs }, id);
        return id;
    }


    async makeGroup(settings: IGroupSettings, participation: IParticipation) {
        const conversationRef = createId(14);
        const group = new Group({ id: createId(14), settings: {...settings, conversationRef}, participation, state: {}});
        const conversation = new Conversation({ id: conversationRef, participation, title: settings.title || 'Chat', groupRef: group.id })

        await createFromInstance(group);
        await createFromInstance(conversation);
        
        return group.id;
    }

    async deleteGroup(group: Group) {
        const competitions : Competition[] | undefined= await searchAsync('competitions', { groupRef: group.id });
        if (competitions) {
            for (const competition of competitions) {
                if (competition.state.closed) {
                    competition.setGroup();
                }
                else {
                    await destroyFromInstance(competition);
                }
            }
        }
        await destroyFromInstance(group);
    }

    async makeClub(settings: IClubSettings, participation: IParticipation, id?: string) {
        const club = new Club({id: id || createId(14), settings, participation });
        await createFromInstance(club);
        return club.id;
    }

    async deleteClub(club: Club) {        
    }

    async makeSuperSchedule(targetCompetition: Competition, options?: { shuffle?: boolean }) {

        const competitions : Competition[] | undefined = await this.getCompetitions(targetCompetition);
        const courts : ISuperScheduleCourt[] = competitions?.[competitions.length - 1].getCourts() || targetCompetition.getCourts();

        //Was there an old schedule already. If so, don't overwrite it
        let schedule : SuperSchedule | null | undefined = targetCompetition.superScheduleRef ? await getInstance<SuperSchedule>('schedules', targetCompetition.superScheduleRef) : undefined;

        if (competitions) {
            let startDate = competitions[0].settings.date;
            let matches : Match[] = [];
            for (const competition of competitions) {
                if (options?.shuffle) {
                    competition.shuffle();
                }
                else if (competition.settings.sortByRanking && !competition.settings.teamSignup) {
                    const users : (User | null)[] | undefined = await getInstances<User>('users', competition.participation.userRefs);
                    //@ts-ignore
                    const sortedUserRefs : string[] | undefined = competition.participation.userRefs?.sort((a, b) => {
                        const aRanking = users?.find(u => u?.id === a)?.state?.rating || 1000;
                        const bRanking = users?.find(u => u?.id === b)?.state?.rating || 1000;
                        return bRanking - aRanking;
                    })
                    //Set userRefs without saving
                    competition.participation.userRefs = sortedUserRefs;
                }
                //Make matches without saving
                competition.makeMatchesWithoutSaving();
                startDate = (startDate && startDate <= (competition.settings.date || startDate)) ? startDate : competition.settings.date;
                matches = [...matches, ...competition.getAllMatches()];
            };
            
            if (schedule) { 
                await schedule.setMatches(matches.map(m => m.serialize()));
            }
            else {
                schedule = new SuperSchedule({
                        settings: { 
                            courts: courts || [{id: '1', name: 'Court 1'}],
                            startDate 
                        },
                        matches: matches.map(m => m.serialize()),
                        competitionNames: competitions.reduce((acc: {[id: string]: string}, curr: Competition) => {
                            acc[curr.id] = curr.settings.title || curr.settings.type;
                            return acc;
                        }, {})
                    }, null);
                    schedule.onUpdateSchedule('Factory.makeSuperSchedule');
            
                await createFromInstance(schedule);
                //@ts-ignore
                await Promise.all(competitions.map(competition => competition.setSuperScheduleRef(schedule.id)))
                
            }
            return schedule.id;
        }
    }

    async clearSuperSchedule(targetCompetition: Competition) {
        const oldSchedule : SuperSchedule | null | undefined = targetCompetition.superScheduleRef ? await getInstance<SuperSchedule>('schedules', targetCompetition.superScheduleRef) : undefined;
        const competitions = await this.getCompetitions(targetCompetition);
        if (!competitions) { return }

        for (const competition of competitions) {
            delete competition.superScheduleRef;
            await competition.clear();
            console.log('[Factory clear]: ' + competition.settings.getTitle());
            competition.superScheduleRef && console.log('[Factory clear]: Schedule reference intact on ' + competition.settings.getTitle(), competition.superScheduleRef);
        }
        if (oldSchedule) {
            await destroyFromInstance(oldSchedule);
            console.log('[Factory clear]: schedule destroyed');
        }
    }

    async getCompetitions(competition: Competition) {
        let competitions : Competition[] | undefined;
        if (competition.settings.type === 'BIG TOURNAMENT') {            
            competitions = await searchAsync<Competition>('competitions', { parentCompetitionRef: competition.id });
            competitions && competitions.push(competition);
        }
        else if (competition.settings.parentCompetitionRef) {
            const parentCompetition : Competition | null | undefined = await getInstance('competitions', competition.settings.parentCompetitionRef);
            competitions = await searchAsync<Competition>('competitions', { parentCompetitionRef: competition.settings.parentCompetitionRef });
            competitions && parentCompetition && competitions.push(parentCompetition);
        }
        else {
            competitions = [competition];
        }
        return competitions;
    }
}

const Factory = new _Factory();
export default Factory;
