import React, { useState } from 'react';
import { View, FlatList, ScrollView } from 'react-native';
import { useTheme, Section, TextInput, Button, Paragraph } from 'components';
import { searchAsync, useBatchedQuery } from 'bridge';
import { Club } from 'business';
import { ClubCard } from '../../composites';
import { IPressEvent } from 'interfaces';
import useModal from '../../actions/useModal';
import CreateClubSheet from '../../actions/sheets/CreateClubSheet';

interface Props {
    app?: any, 
    devApp: any,
    style?: any
}
export default function ClubsList(props: Props) {

    const { app, devApp, style } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState<Club[]>();
    const [clubs, onLoadMoreClubs] = useBatchedQuery<Club>({queryType: 'allClubs'}, app);

    const { pushModal, popAllModals } = useModal();

    async function search() {
        const i = await searchAsync<Club>('clubs', { searchQuery: searchQuery}, app);
        setSearchResults(i);    
    }
    
    async function onPressInstance(e: IPressEvent, instance: Club) {

        const isPublic = instance.settings.public;

        const result : ICreateClubSheetResult = await pushModal((
            <CreateClubSheet
                title={`Publish ${instance.id}`}
                doneLabel={isPublic ? 'Unpublish' : 'Publish'}
                initialSettings={instance.settings}
                hideId={true}
                style={{flex: 1}}
            />
        ), {...e, pageY: 0});
        result?.settings && instance.updateSettings({...result.settings, public: isPublic ? false : true});
        popAllModals();
    }

    const theme = useTheme();
    return(
        <View style={[style, {flex: 1}]}>
            <Section size={'S'} style={{flexDirection: 'row'}}>
                <TextInput
                    style={{flex: 1}}
                    iconLeft={'ico_search'} 
                    iconRight={'ico_clear'} 
                    placeholder={`Query...`} 
                    value={searchQuery}
                    onChangeText={(t: string) => {
                        setSearchQuery(t);
                    }}
                    onPressIconRight={() => setSearchQuery('')}
                />
                <Button label={'Search'} onPress={search}/>
            </Section>

            <View style={{flex: 1}}>
                {searchResults?.length ? null : (
                    <FlatList
                        style={{flex: 1}}
                        data={clubs}
                        keyExtractor={item => item.id}
                        onEndReached={() => onLoadMoreClubs()}
                        onEndReachedThreshold={0.1}
                        renderItem={({item}) => (
                            <ClubCard style={{marginTop: 12}} club={item} onPress={(e: any) => onPressInstance(e, item)}/>
                        )}
                    />
                )}

                {searchResults?.length ? (
                    <ScrollView style={{flex: 1}}>
                        { searchResults.map((item: Club) => (
                            <View key={item.id} style={{marginTop: theme["spacing-small"]}}>
                                <ClubCard style={{marginTop: 12}} club={item} onPress={(e: any) => onPressInstance(e, item)}/>
                            </View>
                        ))}
                    </ScrollView>
                ) : null }
            </View>

            { searchResults?.length === 0 ? (
                <Paragraph>{'No search results'}</Paragraph>
            ) : null }
            
        </View>
    )
}
