import { useState, useEffect } from 'react';
import { useAppContext } from '../appcontext';
import useIsAdmin from '../hooks/useIsAdmin';
import { Competition, Group, Club } from 'business';

export default function useDocMenuButtons(target: IActionTarget | null, isSchedulable?: boolean) {

    const [menuButtons, setMenuButtons] = useState<ButtonType[]>([]);
    const { currentUser, currentUserId } = useAppContext();
    const isAdmin = useIsAdmin(currentUserId);

    useEffect(() => {
        const competition : Competition | null | undefined = target?.competition;
        const group : Group | null | undefined = target?.group;
        const club : Club | null | undefined = target?.club;

        const buttons : ButtonType[] = [];
        const isGroupPrivate = group?.settings.private;
        const isGroupMember = group?.participation.isMember(currentUserId);

        if (competition) {
            const isPlayer = competition.participation.isPlayer(currentUserId);
            const isReserve = competition.participation.isReserve(currentUserId);
            const isInvited = competition.participation.isInvited(currentUserId);
            const isFull = competition.isFull();
            const isRepeating = competition.settings.repeat;
            const isPublic = competition.settings.public;
            const isScheduled = competition.superScheduleRef || false;
            const isStarted = competition.state.started || false;
            const isClosed = competition.state.closed || false;
            const isEnded = competition.state.ended || false;
            const isBigTournament = competition.settings.type === 'BIG TOURNAMENT';
            const isSubCompetition = competition.settings.parentCompetitionRef ? true : false;
            const isTeamSignup = competition.settings.teamSignup || false;

            if (isEnded) {
                buttons.push('Copy link');
                isAdmin && buttons.push('Copy event');
                isAdmin && buttons.push('Reopen event');
            }
            else if (isClosed) {
                buttons.push('Copy link');
                isAdmin && buttons.push('Copy event');
            }
            else if (isStarted) {
                isAdmin && buttons.push('End event');
                buttons.push('Copy link');
                isAdmin && buttons.push('Copy event');
                isAdmin && buttons.push('Pause event');
                isAdmin && buttons.push('Clear schedule');
            }
            else if (isScheduled) {
                isAdmin && buttons.push('Start event');
                buttons.push('Copy link');
                isAdmin && buttons.push('Edit');
                isAdmin && !isTeamSignup && buttons.push('Shuffle teams');
                isAdmin && buttons.push('Copy event');
                isAdmin && buttons.push('Clear schedule');
            }
            else {
                if ((isGroupPrivate && isGroupMember) || !isGroupPrivate) {
                    !isPlayer && !(isReserve && isFull) && buttons.push(isFull ? 'Register as reserve' : (isInvited ? 'Respond to invitation' : 'Register'));
                }
                isAdmin && isSchedulable && !isSubCompetition && buttons.push('Make schedule');
                buttons.push('Copy link');
                buttons.push('Directions');
                if (isAdmin) {
                    buttons.push('Edit');
                    buttons.push('Edit admins');
                    //!isBigTournament && isFixedTeams && buttons.push(isMultiGroup ? 'Edit groups' : 'Edit teams');
                    !isSubCompetition && !isBigTournament && buttons.push(isRepeating ? 'Stop repeat' : 'Repeat weekly');
                    !isSubCompetition && buttons.push(isPublic ? 'Make private' : 'Make public');
                    buttons.push('Copy event');
                }

                //isAdmin && buttons.push('Show log');
                //isAdmin && (isDev || isStaging) && buttons.push('Publish');
                (isPlayer || isReserve) && buttons.push('Unregister');
                isAdmin && buttons.push('Delete event');
            }
        }
        else if (group) {
            const isPublic = group.settings.public || false;
            !isGroupPrivate && !isGroupMember && buttons.push('Join group');
            isGroupMember && buttons.push('Group chat');
            buttons.push('Copy link');
            buttons.push('Directions');
            buttons.push('Event history');
            isAdmin && buttons.push('Edit');
            isAdmin && buttons.push('Edit admins');
            isAdmin && buttons.push(isPublic ? 'Make private' : 'Make public');
            isAdmin && buttons.push('Export members');
            //isAdmin && buttons.push('Show log');
            isGroupMember && buttons.push('Leave group');
            isAdmin && buttons.push('Delete group');    
        }
        else if (club) {
            const isFollowing = currentUser?.settings.clubRefs?.includes(club.id);

            !isFollowing && buttons.push('Follow club');
            buttons.push('Directions');
            buttons.push('Review club');
            buttons.push('Copy link');
            isAdmin && buttons.push('Edit');
            isAdmin && buttons.push('Edit admins');
            isFollowing && buttons.push('Unfollow club');
        }

        setMenuButtons(buttons);   
    }, [target, currentUserId, currentUser, isSchedulable, isAdmin]);

    return menuButtons;
}