import { useState, useEffect } from 'react';
import * as Localization from 'expo-localization';
import { User } from 'business';
import { StringTable } from 'utils';
import { Locale } from 'interfaces';

export default function useLocaleImplementation(currentUser?: User | null, locales?: {[key: string]: Locale}) {

    const [locale, setLocale] = useState<Locale>();
    useEffect(() => {
        locales && !locale && setLocale(locales['en-GB']);
    }, [locales]);

    useEffect(() => {
        if (!locales) {
            return;
        }
        
        if (currentUser?.settings.localeCode) {
            if (locales.hasOwnProperty(currentUser?.settings.localeCode)) {
                setLocale(locales[currentUser?.settings.localeCode])
            }
        }
        else {
            const systemLocaleCode = Localization.locale;
            if (locales.hasOwnProperty(systemLocaleCode)) {
                setLocale(locales[systemLocaleCode]);
            }
            else {
                const maybeLocaleKey = Object.keys(locales).find(l => l.startsWith(systemLocaleCode.slice(0, 2)) && locales[l].isPrimary);
                if (maybeLocaleKey) {
                    setLocale(locales[maybeLocaleKey]);
                }
                else {
                    setLocale(locales['en-GB']);
                }
            }
        }
    }, [locales, currentUser?.settings.localeCode]);

    /**
     * And set the users selected language
    */
   const [reRender, setReRender] = useState(0);
    useEffect(() => {
        if (locale) {
            const dirty = StringTable.setLanguage(locale.language || 'en');
            if (dirty) {
                setReRender(Math.random());
            }
        }
    }, [locale]);

    return locale;
}