import { getTable } from './helpers';
import { Match } from './Match';
import { ICompetitionGroup, TableRow } from '../interfaces';
/**
 * CompetitionGroup
 */
export class CompetitionGroup implements ICompetitionGroup {

    userRefs: string[];
    matches: Match[];
    table?: TableRow[] | null;

    constructor(data: ICompetitionGroup) {
        this.matches = data.matches.map(m => new Match({ ...m }));
        this.userRefs = data.userRefs ? [...data.userRefs] : [];
        this.table = data.table
            ? data.table.map(row => ({ ...row }))
            : this.userRefs.map(userRef => ({ id: userRef, wins: 0, draws: 0, losses: 0, score: 0, setdiff: 0, gamediff: 0, pointsdiff: 0 }));
    }

    serialize() : ICompetitionGroup {
        return {
            userRefs: this.userRefs,
            matches: this.matches?.map(m => m.serialize()),
            table: this.table
        }
    }

    replace(userRef: string, replacementRef: string, points?: boolean) {
        const index = this.userRefs ? this.userRefs.indexOf(userRef) : -1;
        if (index >= 0) {
            this.userRefs?.splice(index, 1, replacementRef);
            this.table = getTable(this.matches, points);
        }
    }

    updateTable(points?: boolean) {
        this.table = getTable(this.matches, points);
    }

    endMatch(matchId: string, endTime: Date, result?: number[]) {
        const match = this.matches.find(m => m.id === matchId);
        if (match) {
            match.setResult(result, endTime);
            this.table = getTable(this.matches, match.points);    
        }
        else {
            throw `CompetitionGroup.endMatch failed, match ${matchId} not found`;
        }
    }

    addMatches(matches: Match[]) {
        this.matches.push(...matches);
    }

    setMatches(matches: Match[]) {
        this.matches = matches;
    }

    getLeaders(fixedTeams?: boolean) {        
        return this.table?.slice(0, fixedTeams ? 2 : 1).map(tableRow => tableRow.id);
    }

    isFinished() {
        return (this.matches && this.matches.some(match => !match.hasResult())) ? false : true;
    }

    
}
