import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { CalendarWeek, Section, Caption, useTheme, IconButton } from 'components';
import { useFeatured, useDaily, useStyles, useLayout } from '../hooks';
import { DoubleFlatList, TabHeader, CompetitionCard, SectionHeader } from '../composites';
import { NavigationProvider } from '../navigation';
import { useAppContext } from '../appcontext';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import { SearchView } from '../composites';
 
export default function DiscoverScreen(props: any) {

    const { currentUser } = useAppContext();
    const styles = useStyles();
    const [date, setDate] = useState(new Date());
    const [featured, onLoadMoreFeatured] = useFeatured(currentUser?.settings.region);
    const [daily, onLoadMoreDaily] = useDaily(currentUser?.settings.region, date);
    const theme = useTheme();
    const { layout } = useLayout();

    const [isSearching, setIsSearching] = useState(false);

    const { setTarget } = useActions();
    useFocusEffect(
        React.useCallback(() => {
            setIsSearching(false);
            setTarget(null);
        }, [])
    );

    return(
        <NavigationProvider>
            <View style={styles.screen}>
                <TabHeader title={StringTable.getString('Discover')}>
                    <IconButton name={'ico_search'} style={{marginRight: theme["spacing-medium"]}} mode={isSearching ? 'primary' : undefined} onPress={() => setIsSearching(!isSearching)}/>
                </TabHeader>

                { isSearching ?  (
                    <>
                        <SearchView 
                            style={styles.list} 
                            onClearSearch={() => setIsSearching(false)}
                        />
                    </>

                ) : (
                    <>
                        <CalendarWeek
                            selectedDate={date}
                            onChangeDate={d => setDate(d)}
                            dayLabels={StringTable.shortDayLabels}
                            monthLabels={StringTable.shortMonthLabels}
                            showChevrons={layout === 'desktop' ? true : false}
                        />
                        <DoubleFlatList
                            primaryData={daily}
                            onPrimaryEndReached={onLoadMoreDaily}
                            secondaryData={featured}
                            onSecondaryEndReached={onLoadMoreFeatured}
                            primaryHeader={(
                                <SectionHeader
                                    header={StringTable.getString(daily?.length ? 'Public events' : 'No public events today')}
                                    counter={daily?.length ? `${daily.length}` : undefined}
                                    isAdmin={false}
                                />
                            )}
                            secondaryHeader={featured?.length ? (
                                <SectionHeader
                                    header={StringTable.getString('Featured')}
                                    counter={`${featured.length}`}
                                    isAdmin={false}
                                />
                            ) : undefined }
                            renderPrimaryItem={({item, index}) => (
                                <CompetitionCard competition={item}/>
                            )}
                            renderSecondaryItem={({item, index}) => (
                                <CompetitionCard competition={item}/>
                            )}
                        />
                    </>
                )}
            </View>
        </NavigationProvider>
    ) 
}
