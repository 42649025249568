import { IState } from "../interfaces";

export class State implements IState {
    featured?: boolean;
    full?: boolean;
    winners?: string[];
    scheduled?: boolean;
    started?: boolean;
    closed?: boolean;  
    ended?: boolean;
    value?: number;
    ratingChange?: { [userRef: string] : number }

    constructor(data: IState) {
        this.featured = data.featured;
        this.full = data.full;
        this.winners = data.winners;
        this.scheduled = data.scheduled;
        this.started = data.started;
        this.closed = data.closed;
        this.ended = data.ended;
        this.value = data.value || 0;
        this.ratingChange = data.ratingChange;
    }
 
    serialize() : IState {
        return {
            featured: this.featured, 
            full: this.full, 
            winners: this.winners, 
            scheduled: this.scheduled,
            started: this.started,
            closed: this.closed, 
            ended: this.ended,
            value: this.value,
            ratingChange: this.ratingChange
        }
    }

    start() {
        this.started = true;
    }

    pause() {
        this.started = false;
    }

    clear() {
        delete this.winners;
        this.scheduled = false;
        this.started = false;
        this.closed = false;
    }

    close(winners?: string[]) {
        this.winners = winners;
        this.closed = true;
    }

    reOpen() {
        //Don't remove winners here, we need it in the cloud trigger
        delete this.ratingChange;
        this.closed = false;
        this.ended = false;
    }
}