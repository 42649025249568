import { useCallback } from 'react';
import { useNavigation, useRoute, CommonActions } from "@react-navigation/native";
import NavigationContext from "./NavigationContext";
import useActions from '../actions/useActions';
import { StackNavigationProp } from '@react-navigation/stack';

export default function NavigationProvider(props: any) {
    const { children } = props;

    const navigation = useNavigation<StackNavigationProp<any>>();
    const route = useRoute();

    const { target } = useActions();

    /**
     * Takes one or more of clubId, groupId, competitionId, conversationId
     * or a single stackId and executes navigation.
     */
    const navigate = useCallback((params: INavigationParams) => {

        if (params.clubId) {
            navigation.push('Club', {clubId: params.clubId});
        }
        else if (params.groupId) {
            const navParams = {...route.params, groupId: params.groupId};
            navigation.push('Group', navParams)
        }
        else if (params.competitionId) {
            const navParams = {...route.params, competitionId: params.competitionId};
            navigation.push('Competition', navParams);
        }
        else if (params.conversationId) {
            const navParams = {...route.params, conversationId: params.conversationId};
            navigation.push('Conversation', navParams);
        }
        else if (params.stackId) {
            navigation.navigate(params.stackId);
        }

    }, [route, navigation]);

    const back = useCallback(() => {

        if (navigation.canGoBack()) {
            navigation.goBack();
        } 
        else {
            navigation.dispatch(CommonActions.reset({index: 0, routes: [{ name: navigation.getState().routeNames[0]}]}));
        }    
    }, [target, route, navigation]);

    return(
        <NavigationContext.Provider value={{navigate, back}}>
            {children}
        </NavigationContext.Provider>
    )
}
