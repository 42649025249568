import React, { useMemo } from 'react';
import { Dimensions } from 'react-native';
import ActionsContext from "./ActionsContext";
import { IPressEvent } from 'interfaces';
import { Competition, Match, SuperSchedule } from 'business';
import { useAppContext } from '../appcontext';
import { useRootNavigatorContext } from '../navigation';
import _addCompetitionToTarget from './addCompetitionToTarget';
import _addPlayersToTarget from './addPlayersToTarget';
import _addInvitationsToTarget from './addInvitationsToTarget';
import _addClub from './addClub';
import _editTarget from './editTarget';
import _joinTarget from './joinTarget';
import _leaveTarget from './leaveTarget';
import _deleteTarget from './deleteTarget';
import _copyLinkToTarget from './copyLinkToTarget';
import _editUserSettings from './editUserSettings';
import _closeCompetition from './closeCompetition';
import _reopenCompetition from './reopenCompetition';
import _setMatchResult from './setMatchResult';
import _onboard from './onboard';
import _showUserMenu from './showUserMenu';
import _editCompetitionTeams from './editCompetitionTeams';
import _addGroupToTarget from './addGroupToTarget';
import _setAdmins from './setAdmins';
import _startCompetition from './startCompetition';
import _pauseCompetition from './pauseCompetition';
import _publishTarget from './publishTarget';
import _swapCompetitionPlayoffSeeds from './swapCompetitionPlayoffSeeds';
import _requireMatchCourt from './requireMatchCourt';
import _makeTargetPublic from './makeTargetPublic';
import _makeTargetPrivate from './makeTargetPrivate';
import _startRepeatTarget from './startRepeatTarget';
import _stopRepeatTarget from './stopRepeatTarget';
import _scheduleTarget from './scheduleTarget';
import _unscheduleTarget from './unscheduleTarget';
import _showUser from './showUser';
import _showLog from './showLog';
import _showEventHistory from './showEventHistory';
import _shuffleCompetitionTeams from './shuffleCompetitionTeams';
import _showMessage from './showMessage';
import _swapTeamsInGroup from './swapTeamsInGroup';
import _swapTeamsInPlayoffMatch from './swapTeamsInPlayoffMatch';
import _directionsToTarget from './directionsToTarget';
import _constrainSchedule from './constrainSchedule';
import _duplicateCompetition from './duplicateCompetition';
import _reviewClub from './reviewClub';
import useModal from './useModal';
import _exportMembers from './exportMembers';

interface Props {
    children: any
}

export default function ActionsProvider(props: Props) {

    const { children } = props;

    const { 
        currentUser, 
        currentUserId, 
        target, 
        setTarget, 
        login, 
        setPreventOnboarding, 
        notificationPermission, 
        canRequestNotificationPermission, 
        requestNotificationPermission,
        changePinCode
    } = useAppContext();
    
    const { 
        setNotificationView 
    } = useRootNavigatorContext();
    
    const {
        pushModal, 
        popModal, 
        popAllModals
    } = useModal();


    const actions = useMemo(() => {
        return {
            pushModal, 
            popModal, 
            popAllModals,
            target,
            setTarget,
            addCompetitionToTarget: async(e: IPressEvent) => currentUser && _addCompetitionToTarget(e, target, pushModal, popAllModals, currentUser),
            addPlayersToTarget: async(e: IPressEvent) => currentUser && _addPlayersToTarget(e, target, pushModal, popAllModals, currentUser),
            addInvitationsToTarget: async(e: IPressEvent) => currentUser && _addInvitationsToTarget(e, target, pushModal, popAllModals, currentUser),
            addGroupToTarget: async(e: IPressEvent) => currentUser && _addGroupToTarget(e, target, pushModal, popAllModals, currentUser),
            addClub: async(e: IPressEvent) => currentUser && _addClub(e, pushModal, popAllModals, currentUser),
            editTarget: async(e: IPressEvent, schedule?: SuperSchedule) => _editTarget(e, schedule, target, pushModal, popModal, popAllModals),
            joinTarget: async(e: IPressEvent) => _joinTarget(e, target, pushModal, popModal, popAllModals, login, setPreventOnboarding, currentUser),
            leaveTarget: async(e: IPressEvent) => currentUser && _leaveTarget(e, target, pushModal, popAllModals, currentUser),
            deleteTarget: async(e: IPressEvent) => _deleteTarget(e, target, pushModal, popAllModals),
            copyLinkToTarget: async(e: IPressEvent) => _copyLinkToTarget(e, target, setNotificationView),
            editUserSettings: async(e: IPressEvent) => _editUserSettings(e, pushModal, popAllModals),
            makeTargetPublic: async(e: IPressEvent) => _makeTargetPublic(e, target, pushModal, popAllModals),
            makeTargetPrivate: async(e: IPressEvent) => _makeTargetPrivate(e, target, pushModal, popAllModals),
            startRepeatTarget: async(e: IPressEvent) => _startRepeatTarget(e, target, pushModal, popAllModals),
            stopRepeatTarget: async(e: IPressEvent) => _stopRepeatTarget(e, target, pushModal, popAllModals),
            scheduleTarget: async(e: IPressEvent) => _scheduleTarget(e, target, pushModal, popAllModals),
            unscheduleTarget: async(e: IPressEvent) => _unscheduleTarget(e, target, pushModal, popAllModals),
            startCompetition: async(e: IPressEvent, requestConfirmation?: boolean) => _startCompetition(e, requestConfirmation || false, target, pushModal, popAllModals),
            pauseCompetition: async(e: IPressEvent, requestConfirmation?: boolean) => _pauseCompetition(e, requestConfirmation || false, target, pushModal, popAllModals),
            setMatchResult: async(e: IPressEvent, competition: Competition, matchId: string, schedule?: SuperSchedule ) => _setMatchResult(e, competition, matchId, pushModal, popAllModals, schedule),
            closeCompetition: async(e: IPressEvent) => _closeCompetition(e, target, pushModal, popModal, popAllModals),
            reopenCompetition: async(e: IPressEvent) => _reopenCompetition(e, target, pushModal, popModal, popAllModals),
            onboard: async() => _onboard({pageX: (Dimensions.get('window').width - 500)/2, pageY: 0}, pushModal, popAllModals, changePinCode, currentUser, currentUserId, notificationPermission, canRequestNotificationPermission, requestNotificationPermission),
            showUser: async(e: IPressEvent, userRef: string) => _showUser(e, userRef, pushModal, popAllModals),
            showUserMenu: async(e: IPressEvent, userRef: string, competition: Competition, matchId?: string) => _showUserMenu(e, userRef, target, currentUserId, pushModal, popAllModals, competition, matchId),
            swapCompetitionPlayoffSeeds: async(e: IPressEvent, competition: Competition, match: Match, schedule: SuperSchedule) => _swapCompetitionPlayoffSeeds(e, target, competition, match, schedule, pushModal, popAllModals),
            requireMatchCourt: async(e: IPressEvent, competition: Competition, matchId: string, schedule: SuperSchedule) => _requireMatchCourt(e, competition, matchId, schedule, pushModal, popAllModals),
            showLog: async(e: IPressEvent) => _showLog(e, target, pushModal, popAllModals),
            showEventHistory: async(e: IPressEvent) => _showEventHistory(e, target, pushModal, popAllModals),
            editCompetitionTeams: async(e: IPressEvent, schedule?: SuperSchedule | null) => _editCompetitionTeams(e, schedule, target, pushModal, popAllModals),
            shuffleCompetitionTeams: async(e: IPressEvent, schedule: SuperSchedule) => _shuffleCompetitionTeams(e, target, pushModal, popAllModals),
            setAdmins: async(e: IPressEvent) => _setAdmins(e, target, pushModal, popAllModals, currentUser),
            publishTarget: async(e: IPressEvent) => _publishTarget(e, target, pushModal, popAllModals),
            directionsToTarget: async() => _directionsToTarget(target),
            exportMembers: async() => _exportMembers(target),
            showMessage: async(e: IPressEvent, message: string) => _showMessage(e, message, pushModal, popModal),
            swapTeamsInGroup: async(e: IPressEvent, teams?: [string, string][] | null, competition?: Competition) => _swapTeamsInGroup(e, teams, competition, target, pushModal, popAllModals),
            swapTeamsInPlayoffMatch: async(e: IPressEvent, match: Match, competition: Competition) => _swapTeamsInPlayoffMatch(e, match, competition, target, pushModal, popAllModals), 
            constrainSchedule: async(e: IPressEvent, schedule: SuperSchedule, competitions: Competition[], roundIndex: number) => _constrainSchedule(e, schedule, competitions, roundIndex, pushModal, popAllModals),
            duplicateCompetition: async(e: IPressEvent, navigate: (params: INavigationParams) => any) => _duplicateCompetition(e, navigate, target, pushModal, popAllModals, currentUser),
            reviewClub: async(e: IPressEvent) => _reviewClub(e, target, pushModal, popAllModals, currentUser)
        }
    }, [
        pushModal, 
        popModal, 
        popAllModals, 
        currentUserId, 
        currentUser, 
        target, 
        setTarget, 
        login, 
        setPreventOnboarding, 
        notificationPermission, 
        canRequestNotificationPermission, 
        requestNotificationPermission,
        changePinCode
    ]);

    return (
        <ActionsContext.Provider value={actions}>
            {children}
        </ActionsContext.Provider>
    )
}
