import { createId } from '../tools';
import { GroupSettings } from './GroupSettings';
import { Location } from './Location';
import { Participation } from './Participation';
import { State } from './State';
import extractSearchTokens from './extractSearchTokens';
import getVersion from './getVersion';
import { IGroup, IGroupMeta, IGroupSettings } from '../interfaces';

export class Group implements IGroup {

    id: string;
    collection: string;
    participation: Participation;
    settings: GroupSettings;
    location?: Location;
    superScheduleRef?: string;
    state: State;
    saveFunc: any;


    constructor(data: IGroup, saveFunc?: any) {
        
        this.id = data.id || createId(23);
        this.collection = 'groups';
        this.saveFunc = saveFunc || this.defaultSaveFunc;
        this.participation = new Participation(data.participation);
        this.settings = new GroupSettings(data.settings);
        this.state = new State(data.state || {});
        this.superScheduleRef = data.superScheduleRef;
    }

    defaultSaveFunc(f: (self: Group) => void) {
        f(this);
    }

    serialize() : IGroup {
        return {
            id: this.id, 
            participation: this.participation.serialize(),
            settings: this.settings.serialize(), 
            state: this.state.serialize(),
            superScheduleRef: this.superScheduleRef
        }
    }

    meta() : IGroupMeta {
        return {
            id: this.id,
            recipients: this.participation.getRecipients(),
            lastModifiedDate: new Date(), 
            public: this.settings.public,
            featured: this.state?.featured || false,
            region: this.settings.location?.region,
            clubRef: this.settings.clubRef,
            searchTokens: extractSearchTokens(this.settings.title || ''),
            version: getVersion()
        }
    }

    destroy() {
        this.saveFunc((self: Group) => {
            self.onDestroy();
        })
    }

    onDestroy() {
    }

    isMember(userId?: string) {
        return this.participation.isMember();
    }

    /**
     * 
     * @param settings 
     */
    updateSettings(settings: IGroupSettings) {
        this.saveFunc((self: Group) => {
            self.onUpdateSettings(settings);
        });
    }
    onUpdateSettings(settings: IGroupSettings) {
        this.settings.update(settings);        
    }

    /**
     * User made admin by another
     * @param userRef 
     */
    makeAdmins(userRefs: string[]) {
        this.saveFunc((self: Group) => {
            self.onMakeAdmins(userRefs);
        })
    }
    onMakeAdmins(userRefs: string[]) {
        this.participation.admin = userRefs;
    }

    /**
     * Users added by themselves or their partner
     * @param userRefs 
     */
    join(userRefs: string[]) {
        this.saveFunc((self: Group) => {
            self.onJoin(userRefs);
        });
    }
    onJoin(userRefs: string[]) {
        userRefs && this.participation.join(userRefs);
    }

    /**
     * Users added by someone else
     * @param userRefs 
     */
    add(userRefs: string[]) {
        this.saveFunc((self: Group) => {
            self.onAdd(userRefs);
        });
    }
    onAdd(userRefs: string[]) {
        userRefs && this.participation.add(userRefs);
    }

    /**
     * Users removed by themselves or someone else
     * @param userRef 
     */
    leave(userRef: string) {
        this.saveFunc((self: Group) => {
            self.onLeave(userRef);
        })
    }
    onLeave(userRef: string) {
        userRef && this.participation.leave(userRef);
    }

    /**
     * User made admin by another
     * @param userRef 
     */
    makeAdmin(userRef: string) {
        this.saveFunc((self: Group) => {
            self.onMakeAdmin(userRef);
        })
    }
    onMakeAdmin(userRef: string) {
        this.participation.makeAdmin(userRef);
    }

    /**
     * 
     * @param userRef 
     */
    revokeAdmin(userRef: string) {
        this.saveFunc((self: Group) => {
            self.onRevokeAdmin(userRef);
        })
    }
    onRevokeAdmin(userRef: string) {
        this.participation.revokeAdmin(userRef);
    }


    setPublic(flag: boolean) {
        this.saveFunc((self: Group) => {
            self.onSetPublic(flag);
        })
    }
    onSetPublic(flag: boolean) {
        this.settings.setPublic(flag);
    }

    setFeatured(flag: boolean) {
        this.saveFunc((self: Group) => {
            self.onSetFeatured(flag);
        })
    }
    onSetFeatured(flag: boolean) {
        this.state.featured = flag;
    }

    getTitle() {
        return this.settings.getTitle();
    }

    getRegion() {
        return this.settings.location?.region;
    }

}
