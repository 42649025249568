import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useTheme, Padel, Pressable } from 'components';
import { StringTable } from 'utils';
import { useUsers, useStyles, useIsAdmin } from '../hooks';
import PlayerName from './PlayerName';
import { useAppContext } from '../appcontext';

interface Props {
    userRefs: string[],
    onPressUser?: (e: any, userRef: string, isAdmin?: boolean) => void,
    verbose?: boolean,
    showPortraits: boolean,
    style?: any,
    children?: any
}

TeamCard.defaultProps = {
    showPortraits: true
}
export default function TeamCard(props: Props) {

    const {
        userRefs,
        verbose,
        onPressUser : onPressUserProp,
        showPortraits,
        style,
        children
    } = props;


    const users = useUsers(userRefs);
    const user = users[userRefs[0]];
    const partner = users[userRefs[1]];
        
    const { currentUserId } = useAppContext();
    const isAdmin = useIsAdmin(currentUserId);
    const isUserAdmin = useIsAdmin(userRefs?.[0]);
    const isPartnerAdmin = useIsAdmin(userRefs?.[1])

    const onPressUser = useCallback((e: any, userRef: string) => {
        onPressUserProp?.(e, userRef, isAdmin);
    }, [isAdmin, onPressUserProp]);


    return userRefs[0] && user !== undefined ? (
        <MTeamView 
            key={userRefs[0]}
            userId={userRefs[0]}
            partnerId={userRefs[1]}
            imageUrl={user?.imageUrl}
            partnerImageUrl={partner?.imageUrl}
            title={user?.getTitle() || userRefs[0]}
            partnerTitle={partner?.getTitle() || userRefs[1] || StringTable.getString('No player')}
            rating={user?.rating || 1000}
            partnerRating={partner?.rating || (userRefs[1] ? 1000 : 0)}
            isAdmin={isUserAdmin}
            isPartnerAdmin={isPartnerAdmin}
            onPressUser={onPressUser}
            verbose={verbose}
            registered={user?.registered}
            partnerRegistered={partner?.registered}
            showPortraits={showPortraits}
            style={style}
        >
            {children}
        </MTeamView>
    ) : (
        <View/>
    )
} 

interface MTeamViewProps {
    userId: string, 
    partnerId?: string,
    imageUrl?: string,
    partnerImageUrl?: string
    title: string, 
    partnerTitle?: string,
    rating: number,
    partnerRating?: number,
    isAdmin?: boolean,
    isPartnerAdmin?: boolean,
    onPressUser?: (e: any, userRef: string) => void,
    verbose?: boolean, 
    registered?: boolean,
    partnerRegistered?: boolean,
    showPortraits: boolean,
    style?: any,
    children?: any
}

function MTeamView(props : MTeamViewProps) {
    const {
        userId, 
        partnerId,
        imageUrl,
        partnerImageUrl,
        title,
        partnerTitle,
        rating, 
        partnerRating,
        isAdmin,
        isPartnerAdmin,
        onPressUser,
        verbose,
        registered,
        partnerRegistered, 
        showPortraits,
        style,
        children
    } = props;

    const theme = useTheme();
    const styles = useStyles();

    const padelProps = {
        strokeWidth: 2, 
        strokeColor: theme["color-card"], 
    };
    
    return( 
        <View style={[styles.TeamCard, style]}>
            <View style={styles.row}>
                { showPortraits ? (
                    <>
                        <Pressable onPress={onPressUser ? (e: any) => onPressUser(e, userId) : undefined}>
                            <Padel size={'M'} imageBackground={imageUrl} showLogo={imageUrl ? false : true} {...padelProps}/>
                        </Pressable>
                        <Pressable onPress={onPressUser ? (e: any) => partnerId && onPressUser(e, partnerId) : undefined} style={{marginLeft: -6, marginRight: theme["spacing-medium"]}}>
                            <Padel size={'M'} imageBackground={partnerImageUrl} showLogo={partnerImageUrl ? false : true} {...padelProps}/>
                        </Pressable>
                    </>
                ) : null }
                <View style={{flex: 1, justifyContent: 'center'}}>
                    <PlayerName
                        onPress={onPressUser ? (e: any) => onPressUser(e, userId) : undefined}
                        verbose={verbose}
                        registered={registered}
                        title={title}
                        rating={rating}
                        isAdmin={isAdmin}
                    >
                        {children}
                    </PlayerName>
                    <PlayerName
                        onPress={onPressUser ? (e: any) => partnerId && onPressUser(e, partnerId) : undefined}
                        verbose={verbose}
                        registered={partnerRegistered}
                        title={partnerTitle || StringTable.getString('No title')}
                        rating={partnerRating || 1000}
                        isAdmin={isPartnerAdmin}
                    >
                        {children}
                    </PlayerName>
                </View>
            </View>
        </View>        
    )
}
