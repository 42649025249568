import { IMatch } from "../interfaces";
export class Match implements IMatch {

    id: string;
    userRefs: (string | null)[];
    result?: number[];
    prediction?: number[] | null;
    ratings?: {
        in: number[], 
        change?: number[] | null
    };
    competitionId: string;
    requiredCourtId?: string;
    endDate?: Date;
    clubRef?: string;
    points?: boolean;
    admin?: string[];
    playoffRound?: number;
    groupIndex?: number;
    locked?: boolean;
    seed?: number[];

    constructor(data: IMatch) {
        this.id = data.id;
        this.userRefs = data.userRefs ? [...data.userRefs] : [];
        this.result = data.result ? [...data.result] : undefined;
        this.prediction = data.prediction ? [...data.prediction] : undefined;
        this.ratings = data.ratings ? { ...data.ratings, in: [...data.ratings.in], change: data.ratings.change ? [...data.ratings.change] : undefined } : undefined;
        this.competitionId = data.competitionId;
        this.requiredCourtId = data.requiredCourtId;
        this.clubRef = data.clubRef;
        this.points = data.points;
        this.admin = data.admin ? [...data.admin] : undefined;
        this.playoffRound = data.playoffRound;
        this.groupIndex = data.groupIndex;
        this.endDate = data.endDate ? new Date(data.endDate) : undefined;
        this.seed = data.seed ? [...data.seed] : undefined;
    }

    serialize() : IMatch {

        const data = {
            id: this.id, 
            userRefs: this.userRefs, 
            result: this.result,
            prediction: this.prediction,
            ratings: this.ratings, 
            competitionId: this.competitionId, 
            requiredCourtId: this.requiredCourtId,
            clubRef: this.clubRef, 
            points: this.points, 
            admin: this.admin,
            playoffRound: this.playoffRound,
            groupIndex: this.groupIndex,
            endDate: this.endDate,
            seed: this.seed
        }
        return data;
    }

    setResult(result: number[] | undefined, endDate: Date) {
        if (result) {
            this.result = result;
            if (result.length) {
                this.endDate = endDate;
            }
            else {
                delete this.endDate;
            }
        }
        else {
            delete this.result;
            delete this.endDate;
        }
    }

    hasResult() {
        return this.result?.length ? true : false;
    }

    hasRatings() {
        if (this.ratings?.change?.length) {
            return true;
        }
        return false;
    }
    
    hasPlayers() {
        if (this.userRefs?.length === 4 && !this.userRefs.includes(null)) {
            return true;
        }
        return false;
    }

    getTeams() {
        if (this.hasPlayers()) {
            return [
                [this.userRefs[0], this.userRefs[1]], 
                [this.userRefs[2], this.userRefs[3]]
            ]
        }
        else {
            return null;
        }
    }

    isPlayer(userId?: string) {
        return userId && this.userRefs?.includes(userId) ? true : false;
    }

    replace(userRef: string, replacementRef: string) {
        const index = this.userRefs.indexOf(userRef);
        if (index >= 0) {
            this.userRefs.splice(index, 1, replacementRef);
            this.userRefs = [...this.userRefs];
        }
    }
}
