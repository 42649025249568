import { capitalize } from '../tools';
import { Location } from './Location';
import { ICompetitionSettings, ICourt } from '../interfaces';

export class Settings implements ICompetitionSettings {

    courts: {[courtId: string]: ICourt};
    courtsEdited?: boolean;
    slots?: number;
    groupCount?: number;
    type: 'MATCH' | 'AMERICANO' | 'TEAM AMERICANO' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING';
    date?: Date;
    duration?: number;
    title?: string;
    parentTitle?: string;
    byline?: string;
    description?: string;
    imageUrl?: string;
    parentCompetitionRef?: string;
    groupRef?: string;
    fixedTeams?: boolean;
    teamSignup?: boolean;
    teamSignupProhibitVacancies?: boolean;
    sortByRanking?: boolean;
    points?: boolean;
    price?: number;
    playoffStages?: 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Numeric';
    playoffSize?: number;
    clubRef?: string;    
    conversationRef?: string;
    similarRating?: boolean;
    public?: boolean;
    location?: Location;
    phoneNumber?: string;
    repeat?: boolean;
     
    constructor(data: ICompetitionSettings) {

        this.type = data.type;
        this.date = data.date;
        this.slots = data.slots;
        this.duration = data.duration;
        this.title = data.title;
        this.parentTitle = data.parentTitle;
        this.byline = data.byline;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.parentCompetitionRef = data.parentCompetitionRef;
        this.groupRef = data.groupRef;
        this.groupCount = data.groupCount;
        this.fixedTeams = data.fixedTeams;
        this.teamSignup = data.teamSignup;
        this.teamSignupProhibitVacancies = data.teamSignupProhibitVacancies;
        this.sortByRanking = data.sortByRanking;
        this.points = data.points;
        this.price = data.price;
        this.playoffStages = data.playoffStages;
        this.playoffSize = data.playoffSize;
        this.clubRef = data.clubRef;
        this.conversationRef = data.conversationRef;
        this.courts = data.courts || { 0: { courtId: 0, queue: []}};
        this.courtsEdited = data.courtsEdited;
        this.similarRating = data.similarRating;
        this.public = data.public;
        this.location = data.location && new Location(data.location);
        this.phoneNumber = data.phoneNumber;
        this.repeat = data.repeat;
    }

    serialize() : ICompetitionSettings {
        return {
            type: this.type, 
            date: this.date, 
            slots: this.slots, 
            duration: this.duration, 
            title: this.title,
            parentTitle: this.parentTitle,
            byline: this.byline, 
            description: this.description, 
            imageUrl: this.imageUrl,
            parentCompetitionRef: this.parentCompetitionRef,
            groupRef: this.groupRef, 
            groupCount: this.groupCount, 
            fixedTeams: this.fixedTeams,
            teamSignup: this.teamSignup, 
            teamSignupProhibitVacancies: this.teamSignupProhibitVacancies,
            sortByRanking: this.sortByRanking,
            points: this.points, 
            playoffStages: this.playoffStages,
            playoffSize: this.playoffSize,
            clubRef: this.clubRef, 
            conversationRef: this.conversationRef,
            courts: Object.values(this.courts || {}).reduce((acc, curr) => ({...acc, [curr.courtId]: {...curr, queue: []}}), {}),
            courtsEdited: this.courtsEdited,
            similarRating: this.similarRating, 
            public: this.public, 
            location: this.location?.serialize(), 
            phoneNumber: this.phoneNumber,
            repeat: this.repeat
        }
    }

    update(data: Partial<ICompetitionSettings>) {
        Object.keys(data).forEach(key => {
            //@ts-ignore
            if (typeof this[key] !== 'function') {
                if (key === "location") {
                    this.location = new Location(data.location);
                }
                else {
                    //@ts-ignore
                    this[key] = data[key];
                }
            }
        })
    }

    setRepeat(flag: boolean) {
        this.repeat = flag;
    }

    getTitle(withParentTitle: boolean = true) {
        return `${(this.parentTitle && withParentTitle) ? this.parentTitle + ' ' : ''}${this.title || capitalize(this.type || '')}`;
    }

    getDateTimeString(dayLabels: string[], monthLabels: string[], IANA?: string) {
        if (!(dayLabels && monthLabels && this.date)) {
            return '';
        }
        const d = this.date;
        const dateString = `${dayLabels[d.getDay()]}` + ' ' + `${d.getDate()} ${monthLabels[d.getMonth()]}`;
        const timeString = Settings.getFormattedTimeString(d, IANA);
        const endDate = this.duration ? new Date(d.getTime() + this.duration * 60 * 1000) : undefined;
        const endTimeString = Settings.getFormattedTimeString(endDate, IANA);
        return `${dateString}, ${timeString}${endTimeString ? ` - ${endTimeString}` : ''}`;
    }
    
    getTimeString(IANA?: string) {
        return Settings.getFormattedTimeString(this.date, IANA);
    }
            
    getByline() {
        if (this.byline) {
            return this.byline;
        }
        return '';
    }

    setPublic(flag: boolean) {
        this.public = flag;
    }


    static getDateTimeStrings(d: Date, dayLabels: string[], monthLabels: string[], skipDayLabel?: boolean, IANA?: string) {
        if (!d) {
            return;
        }

        const dateString = d && `${skipDayLabel ? '' : dayLabels[d.getDay()]} ${d.getDate()} ${monthLabels[d.getMonth()]}`;
        const timeString = Settings.getFormattedTimeString(d, IANA);
        return [dateString, timeString];
    }

    static getFormattedTimeString(d?: Date, IANA?: string) {

        if (!d) {
            return '';
        }

        if (IANA) {
            const targetDate = new Date(d.toLocaleString('en-GB', { timeZone: IANA }));
            const offset = d.getTime() - targetDate.getTime();
            d = new Date(d.getTime() - offset);            
        }
          
        const hours = d.getHours();
        const minutes = d.getMinutes();

        const hoursStr = hours.toString().padStart(2, '0');
        const minutesStr = minutes.toString().padStart(2, '0');

        return `${hoursStr}:${minutesStr}`;
    }
}
