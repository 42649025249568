import { useState, useEffect } from 'react';
import { getDeviceTypeAsync, DeviceType } from 'expo-device';

export default function useDeviceTypeImplementation() {

    //TODO add a resizeObserver here
    const [deviceType, setDeviceType] = useState<DeviceType>(DeviceType.UNKNOWN);
    useEffect(() => {
        async function get() {
            const d = await getDeviceTypeAsync();
            setDeviceType(d);
        }
        get();
    }, []);

    return deviceType;
}
