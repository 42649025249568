import React, { useMemo, useState, useCallback, ReactElement } from 'react';
import { View } from 'react-native';

import Pressable from './helpers/Pressable';
import useThemeStyle from './theme/useThemeStyle';

export interface PageIndicatorProps {
    renderHeader?: (pageIndex: number) => ReactElement,
    renderFooter?: (pageIndex: number) => ReactElement,
    pageCount: number, 
    onPress?: (n: number) => any, 
    highlights?: number[],
    children?: any
}

export default function PageIndicator(props: PageIndicatorProps) {

    const {
        renderHeader,
        renderFooter,
        pageCount, 
        highlights,
        onPress,
        children
    } = props;

    //@ts-ignore
    const {activeColor, highlightColor, ...style} = useThemeStyle("PageIndicator", props);

    const pages = useMemo(() => {
        const p = [];
        for (let i = 0; i < pageCount; i++) {
            p.push(i);
        }
        return p;
    }, [pageCount]);

    const [width, setWidth] = useState();
    const onLayout = useCallback((e: any) => {
        setWidth(e.nativeEvent.layout.width);
    }, []);

    const [currentPage, setCurrentPage] = useState(0);
    const onScroll = useCallback((e: any) => {
        const contentOffset = e.nativeEvent.contentOffset.x;
        width && setCurrentPage(Math.round(contentOffset/width));  
    }, [width]);

    const list = useMemo(() => React.cloneElement(React.Children.only(children), { onLayout, onScroll, scrollEventThrottle: 32 }), [onLayout, onScroll, children]);

    return(
        <>
            { renderHeader && renderHeader(currentPage) }
            <View style={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
                { pages.map(p => (
                    <Pressable 
                        key={p}
                        style={[style, p === currentPage && {backgroundColor: activeColor}, highlights?.includes(p) && {borderColor: highlightColor}]}
                        onPress={() => onPress && onPress(p)}
                    />
                ))}
            </View>
            { list }
            { renderFooter && renderFooter(currentPage) }
        </>
    )
}