import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { getFunctions, httpsCallable } from "firebase/functions";
import CloseHeader from './CloseHeader';
import useSheetStyle from './useSheetStyle';
import { StringTable } from 'utils';
import { Locale, IPressEvent } from 'interfaces';
import useModal from '../useModal';
import { composePhoneNumber, splitPhoneNumber } from 'tools';
import { useTheme, Button, TextInput, TextInputPhone, Section, Paragraph, TextInputCode } from 'components';
import { useAppContext } from '../../appcontext';
import LocalesSheet from './LocalesSheet';

interface Props {
    userId?: string,
    userTitle?: string,
    resolve?: (data: IRegistrationSheetResult | null) => void
    isTeamSignup?: boolean,
    participants: string[],
    style?: any
}

export default function RegistrationSheet(props: Props) {

    const {
        userId,
        userTitle,
        resolve,
        isTeamSignup,
        participants,
        style
    } = props;

    const { locale: systemLocale, locales } = useAppContext();
    const theme = useTheme();

    const [title, setTitle] = useState('');
    const [partnerTitle, setPartnerTitle] = useState('');
    const [digits, setDigits] = useState('');
    const [partnerDigits, setPartnerDigits] = useState('');
    const [locale, setLocale] = useState<Locale>();
    const [partnerLocale, setPartnerLocale] = useState<Locale>();
    const [pinCode, setPinCode] = useState('');
    const [pinCodeRequested, setPinCodeRequested] = useState(false);
    const [done, setDone] = useState(false);
    const [userError, setUserError] = useState(false);
    const [partnerError, setPartnerError] = useState(false);

    useEffect(() => setTitle(userTitle || ''), [userTitle]);
    useEffect(() => {
        if (userId) {
            try {
                const { locale, digits } = splitPhoneNumber(userId, locales);
                setDigits(digits);
                setLocale(locale);
                setPartnerLocale(locale);
            } catch (err) {
                setLocale(systemLocale)
                setPartnerLocale(systemLocale);
            }
        }
        else {
            setLocale(systemLocale);
            setPartnerLocale(systemLocale);
        }
    }, [userId, locales, systemLocale]);

    useEffect(() => {
        const phoneNumber = composePhoneNumber(locale, digits);
        setUserError(phoneNumber && participants.includes(phoneNumber) ? true : false)
    }, [locale, digits, participants]);

    useEffect(() => {
        const phoneNumber = composePhoneNumber(partnerLocale, partnerDigits);
        setPartnerError(phoneNumber && participants.includes(phoneNumber) ? true : false)
    }, [partnerLocale, partnerDigits, participants]);

    function requestPinCode() {
        const phoneNumber = composePhoneNumber(locale, digits);
        const sendPinCode = httpsCallable(getFunctions(), 'sendPinCode');
        sendPinCode(phoneNumber);
        setPinCodeRequested(true);
    }

    function handleRegister(e: IPressEvent) {
        const partnerId = (partnerLocale && partnerDigits) ? composePhoneNumber(partnerLocale, partnerDigits) : partnerTitle;
        setDone(true);
        resolve?.(isTeamSignup ? {
            e,
            userId: userId || composePhoneNumber(locale, digits) || '',
            userTitle: title,
            partnerId,
            partnerTitle,
            pinCode
        } : {
            e,
            userId: userId || composePhoneNumber(locale, digits) || '',
            userTitle: title,
            pinCode
        })
    }

    const { pushModal, popModal } = useModal();
    const sheetStyle = useSheetStyle();

    return (
        <View style={[sheetStyle, style, { padding: theme["spacing-small"] }]}>
            <CloseHeader onDismiss={() => resolve?.(null)} title={StringTable.getString('Registration')} />

            <View style={{ flex: 1 }}>
                <ScrollView contentContainerStyle={{ paddingBottom: 320 }} showsVerticalScrollIndicator={false}>
                    {/* Current user */}
                    <Section>
                        <Paragraph>{StringTable.getString('Your name?')}</Paragraph>
                        <TextInput
                            value={title}
                            onChangeText={(t: string) => setTitle(t)}
                            editable={userTitle ? false : true}
                        />
                        <Section>
                            <Paragraph>{StringTable.getString('Your phone number?')}</Paragraph>
                            <TextInputPhone
                                onPressLocale={async (e: IPressEvent) => {
                                    const newLocale: Locale | null = await pushModal((
                                        <LocalesSheet
                                            locales={locales}
                                            selectedLocale={locale}
                                            style={{ flex: 1 }}
                                        />
                                    ), { ...e, pageY: 0 });

                                    newLocale && setLocale(newLocale);
                                    popModal();
                                }}
                                locale={locale}
                                locales={locales}
                                digits={digits}
                                onChangeDigits={(t: string) => setDigits(t)}
                                onChangeLocale={(newLocale: Locale) => newLocale && setLocale(newLocale)}
                                editable={userId ? false : true}
                            />
                        </Section>
                        {userError ? (
                            <Section size={'M'}>
                                <Paragraph style={{ color: theme["color-negative"] }}>{StringTable.getString('User already playing')}</Paragraph>
                            </Section>
                        ) : null}
                    </Section>

                    {/* Partner */}
                    {isTeamSignup ? (
                        <Section>
                            <Paragraph>{StringTable.getString('Your partner?')}</Paragraph>
                            <TextInput
                                value={partnerTitle}
                                onChangeText={(t: string) => setPartnerTitle(t)}
                            />
                            <Section>
                                <Paragraph>{StringTable.getString('Your partners phone number?')}</Paragraph>
                                <TextInputPhone
                                    onPressLocale={async (e: IPressEvent) => {
                                        const newLocale: Locale | null = await pushModal((
                                            <LocalesSheet
                                                locales={locales}
                                                selectedLocale={partnerLocale}
                                                style={{ flex: 1 }}
                                            />
                                        ), { ...e, pageY: 0 });

                                        newLocale && setPartnerLocale(newLocale);
                                        popModal();
                                    }}
                                    locale={partnerLocale}
                                    locales={locales}
                                    digits={partnerDigits}
                                    onChangeDigits={(t: string) => setPartnerDigits(t)}
                                    onChangeLocale={(newLocale: Locale) => newLocale && setPartnerLocale(newLocale)}
                                />
                            </Section>
                            {partnerError ? (
                                <Section size={'M'}>
                                    <Paragraph style={{ color: theme["color-negative"] }}>{StringTable.getString('User already playing')}</Paragraph>
                                </Section>
                            ) : null}

                        </Section>
                    ) : null}

                    {/* Authentication */}
                    {!userId ? (
                        <Section size={'XL'}>
                            <Paragraph style={{ textAlign: 'center', paddingHorizontal: theme["spacing-large"] }}>{StringTable.getString('Verify your phone number with a code sent via SMS')}</Paragraph>
                            <Section size={'M'}>
                                <Button
                                    label={StringTable.getString('Send code')}
                                    mode={pinCodeRequested || !digits ? 'passive' : 'primary'}
                                    onPress={requestPinCode}
                                />
                            </Section>
                            <Section size={'M'} style={{ alignItems: 'center' }}>
                                <TextInputCode
                                    cells={6}
                                    columns={6}
                                    value={pinCode}
                                    onChangeText={(t: string) => setPinCode(t)}
                                    mode={'login'}
                                />
                            </Section>
                        </Section>
                    ) : null}
                </ScrollView>
            </View>


            <Section style={{ flexDirection: 'row' }}>
                <Button
                    onPress={() => resolve && resolve(null)}
                    mode={done ? 'passive' : 'outlined'}
                    style={{ flex: 1, marginRight: theme["spacing-extrasmall"] }}
                    label={StringTable.getString('Cancel')}
                />
                <Button
                    onPress={handleRegister}
                    mode={!done && !userError && !partnerError && ((userId || (title && digits && pinCode)) && (!isTeamSignup || (partnerTitle && partnerDigits))) ? 'primary' : 'passive'}
                    style={{ flex: 1, marginLeft: theme["spacing-extrasmall"] }}
                    label={StringTable.getString('Register')}
                />
            </Section>

        </View>
    )
}
