//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { View, TextInput as RNTextInput, Platform, StyleSheet } from 'react-native';
import useThemeStyle from './theme/useThemeStyle';
import useTheme from './theme/useTheme';
import Pressable from './helpers/Pressable';
import Icon from './helpers/Icon';

export interface TextInputNumericProps {
    value?: number | '', 
    placeholder?: string,
    placeholderTextColor?: string,
    style?: any, 
    containerStyle?: any,
    innerStyle?: any,
    iconLeft?: string, 
    iconRight?: string, 
    onPressIconLeft?: any, 
    onPressIconRight?: any, 
    multiline?: boolean, 
    numberOfLines?: number,
    onEndEditing?: any, 
    onBlur?: any,
    float?: boolean
}

export default function TextInputNumeric(props : TextInputNumericProps) {

    const {
        value, 
        placeholder,
        placeholderTextColor,
        style : styleProp, 
        containerStyle : containerStyleProp,
        innerStyle : innerStyleProp,
        iconLeft,
        onPressIconLeft,
        onEndEditing : onEndEditingProp,
        float
    } = props;

    const [focused, setFocused] = useState(false);
    const style = useThemeStyle('TextInput', props, focused && 'focused');
    const {textColor, ...containerStyle} = style || {};
    const theme = useTheme();

    function onEndEditing(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS !== 'web' && onEndEditingProp(float ? parseFloat(localValue) : parseInt(localValue))
    }

    //https://github.com/facebook/react-native/issues/7275
    function onBlur(e : any) {
        setFocused(false);
        onEndEditingProp && Platform.OS === 'web' && onEndEditingProp(float ? parseFloat(localValue) : parseInt(localValue));
    }

    const [localValue, setLocalValue] = useState(isNaN(value) ? '' : `${value}`);
    useEffect(() => setLocalValue(isNaN(value) ? '' : `${value}`), [value]);

    function onChangeText(t) {
        let isNum = (float ? /^[-+]?[0-9]*[\.,]?[0-9]*$/.test(t) : /^\d+$/.test(t)) || !t.length;
        isNum && setLocalValue(t);
    }

    const outlineStyle = Platform.OS === 'web' ? { outline: 'none'} : {};

    return (
        <View style={styleProp}>
            <View style={[containerStyle, containerStyleProp]}>
                <View style={{flexDirection: 'row', alignItems: 'flex-start', overflow: 'hidden'}}>
                    {iconLeft ? (
                        <Pressable tabIndex={-1} onPress={onPressIconLeft} style={{marginRight: theme["spacing-small"]}}>
                            <Icon 
                                name={iconLeft} 
                                size={18} 
                                color={textColor}
                            />
                        </Pressable>
                    ) : null}
                    <RNTextInput
                        style={[{flex: 1, color: textColor, padding: iconLeft ? 0 : theme["spacing-small"]}, outlineStyle, innerStyleProp]}
                        value={localValue}
                        placeholder={placeholder}
                        onFocus={() => setFocused(true)}
                        onBlur={onBlur}
                        onEndEditing={onEndEditing}
                        onChangeText={onChangeText}
                        numberOfLines={1}
                        placeholderTextColor={placeholderTextColor || (focused ? theme["color-ink"] : theme["color-ink-contained"])}
                        keyboardType={'number-pad'}
                    />
                </View>
            </View>
        </View>
    )
}
