import React from 'react';
import { IPressEvent } from 'interfaces';
import ConfirmSheet from './sheets/ConfirmSheet';
import { StringTable } from 'utils';

export default async function startRepeatTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet 
        title={StringTable.getString('Confirm repeat weekly')} 
        doneLabel={StringTable.getString('Repeat weekly')} 
        doneMode={'primary'}
    />), e);
    confirmResult?.confirmed && target?.competition?.setRepeat(true);
    popAllModals();

}
