import { createId } from '../tools';
import { UserSettings } from './UserSettings';
import { UserState } from './UserState';
import { UserIdentity } from './UserIdentity';
import extractSearchTokens from './extractSearchTokens';
import getVersion from './getVersion';
import { IUser, AppContact, Trophy, IUserMeta } from '../interfaces';
export class User implements IUser {

    id: string;
    collection: string;
    pName?: string; 
    onboarding?: {[key: string]: any };
    accountType?: 'registered' | 'coach' | 'club';;
    settings: UserSettings;
    state: UserState;
    saveFunc: any;
    contacts: {[key: string]: AppContact };
    systemMessage?: string;

    constructor(data: IUser, saveFunc?: any) {
        this.id = data.id || createId(23);
        this.systemMessage = data.systemMessage;
        this.collection = 'users';
        this.saveFunc = saveFunc || this.defaultSaveFunc;
        this.state = new UserState(data.state || {});
        this.pName = data.pName;
        this.onboarding = data.onboarding;
        this.accountType = data.accountType;
        this.settings = new UserSettings(data.settings || {});
        this.contacts = data.contacts || {};
    }

    defaultSaveFunc(f: (self: User) => void) {
        f(this);
    }

    serialize() : IUser {
        return {
            id: this.id, 
            pName: this.pName,
            onboarding: this.onboarding, 
            contacts: this.contacts,
            settings: this.settings.serialize(),
            state: this.state.serialize(),
            accountType: this.accountType
        }
    }

    meta() : IUserMeta {
        return {
            id: this.id, 
            clubRefs: this.settings.clubRefs, 
            rating: this.state.rating, 
            searchTokens: extractSearchTokens(this.settings.title),
            version: getVersion(),
            lastModifiedDate: new Date()
        }
    }

    toUserIdentity() {
        return new UserIdentity(this.id, {
            t: this.settings.title, 
            pt: this.pName, 
            p: this.settings.imageUrl,
            m: this.state.matches, 
            r: this.state.rating,
            l: this.settings.localeCode
        })
    }

    touch() {
        this.saveFunc((self: User) => {
            console.log('touch ' + self.id);
        })
    }

    setImageUrl(url: string) {
        this.saveFunc((self: User) => {
            self.onSetImageUrl(url);
        })
    }
    onSetImageUrl(url: string) {
        this.settings.setImageUrl(url);
    }

    /**
     * 
     * @param title 
     */
    setTitle(title: string) {
        this.saveFunc((self: User) => {
            self.onSetTitle(title);
        })
    }
    onSetTitle(title: string) {
        this.settings.setTitle(title);
    }

    getTitle() {
        return this.settings.title || this.pName || this.id;
    }

    /**
     * 
     * @param shot 
     */
    setAccountType(type: 'registered' | 'coach' | 'club') {
        this.saveFunc((self: User) => {
            self.onSetAccountType(type);
        })
    }
    onSetAccountType(type: 'registered' | 'coach' | 'club') {
        this.accountType = type;
    }

    /**
     * 
     * @param shot 
     */
    setBestShot(shot: string) {
        this.saveFunc((self: User) => {
            self.onSetBestShot(shot);
        })
    }
    onSetBestShot(shot: string) {
        this.settings.setBestShot(shot);
    }

    /**
     * 
     * @param shot 
     */
    setWorstShot(shot: string) {
        this.saveFunc((self: User) => {
            self.onSetWorstShot(shot);
        })
    }
    onSetWorstShot(shot: string) {
        this.settings.setWorstShot(shot);
    }

    /**
     * 
     * @param side 
     */
    setSide(side: 'left' | 'right' | 'both') {
        this.saveFunc((self: User) => {
            self.onSetSide(side);
        })
    }
    onSetSide(side: 'left' | 'right' | 'both') {
        this.settings.setSide(side);
    }

    /**
     * 
     * @param hand 
     */
    setHand(hand: 'left' | 'right' | 'both') {
        this.saveFunc((self: User) => {
            self.onSetHand(hand);
        })
    }
    onSetHand(hand: 'left' | 'right' | 'both') {
        this.settings.setHand(hand);        
    }

    /**
     * 
     * @param localeCode 
     */
    setLocaleCode(localeCode: string) {
        this.saveFunc((self: User) => {
            self.onSetLocaleCode(localeCode);
        })
    }
    onSetLocaleCode(localeCode: string) {
        this.settings.setLocaleCode(localeCode);
    }

    /**
     * 
     * @param region 
     */
    setRegion(region: string) {
        this.saveFunc((self: User) => {
            self.onSetRegion(region);
        })
    }
    onSetRegion(region: string) {
        this.settings.setRegion(region);
    }

    setRating(rating: number) {
        this.saveFunc((self: User) => {
            self.onSetRating(rating);
        })
    }
    onSetRating(rating: number) {
        this.state.setRating(rating);
    }

    register(title?: string, region?: string) { 
        this.saveFunc((self: User) => {
            self.onRegister(title, region);
        })
    }
    onRegister(title?: string, region?: string) {
        this.settings.setTitle(title);
        this.settings.setRegion(region);
        this.state.setRegistered(true);
    }

    /**
     * 
     * @param clubRef 
     */
    followClub(clubRef: string) {
        this.saveFunc((self: User) => {
            self.onFollowClub(clubRef);
        })
    }
    onFollowClub(clubRef: string) {
        this.settings.followClub(clubRef);
    }

    /**
     * 
     * @param clubRef 
     */
    unfollowClub(clubRef: string) {
        this.saveFunc((self: User) => {
            self.onUnfollowClub(clubRef);
        })
    }
    onUnfollowClub(clubRef: string) {
        this.settings.unfollowClub(clubRef);
    }

    /**
     * 
     */
    filterMatchHistory(matchRefs: string[]) {
        this.saveFunc((self: User) => {
            self.onFilterMatchHistory(matchRefs);
        })
    }
    onFilterMatchHistory(matchRefs: string[]) {
        this.state.filterMatchHistory(matchRefs);
    }

    /**
     * 
     */
    filterTrophies(competitionRef: string) {
        this.saveFunc((self: User) => {
            self.onFilterTrophies(competitionRef);
        })
    }
    onFilterTrophies(competitionRef: string) {
        this.state.filterTrophies(competitionRef);
    }

    isCoachAccount() {
        return this.accountType === 'coach';
    }

    isClubAccount() {
        return this.accountType === 'club';
    }

    addTrophy(trophy: Trophy) {
        this.saveFunc((self: User) => {
            self.onAddTrophy(trophy);
        })
    }
    onAddTrophy(trophy: Trophy) {
        this.state.addTrophy(trophy);        
    }

    dismissSystemMessage() {
        this.saveFunc((self: User) => {
            self.onDismissSystemMessage();
        })
    }
    onDismissSystemMessage() {
        delete this.systemMessage;
    }
}
