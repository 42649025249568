import React from 'react';
import { IPressEvent } from 'interfaces';
import SettingsSheet from './sheets/SettingsSheet/SettingsSheet';

export default async function editUserSettings(e: IPressEvent, pushModal: any, popAllModals: any) {

    const settingsResult = await pushModal((
        <SettingsSheet style={{flex: 1}}/>
    ), {...e, pageY: 0});
    popAllModals();
}
