import React from 'react';
import { IPressEvent } from 'interfaces';
import ConfirmSheet from './sheets/ConfirmSheet';
import MessageSheet from './sheets/MessageSheet';
import { StringTable } from 'utils';
import { Paragraph, Section } from 'components';

export default async function makeTargetPublic(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const clubRef = target?.competition?.settings.clubRef || target?.group?.settings.clubRef;

    if (!clubRef) {
        await pushModal((
            <MessageSheet title={StringTable.getString('Cannot publish')} showConfirmButton={true}>
                <Section>
                    <Paragraph>{StringTable.getString('Groups and events must have a club set in order to be published')}</Paragraph>
                </Section>
            </MessageSheet>
        ), e);
    }
    else {
        const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet 
            title={StringTable.getString('Confirm make public')} 
            doneLabel={StringTable.getString('Make public')} 
            doneMode={'primary'}
        />), e);

        if (confirmResult?.confirmed) {
            if (target?.competition) {
                target.competition.setPublic(true);
            }
            else if (target?.group) {
                target.group.setPublic(true);
            }
        }
    }
        
    popAllModals();
}
