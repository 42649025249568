import React, { useState, useEffect, useRef } from 'react';
import { Platform, View, Text, TextInput } from 'react-native';
import "react-native-reanimated";
import { useFonts } from 'expo-font';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ErrorBoundary from './ErrorBoundary';
import { ThemeProvider, ImageCacheProvider, Paragraph } from 'components';
import { AppContextProvider, RootNavigator, LoaderScreen } from './src/ui3';
import { initializeApp } from "firebase/app";
import Constants from 'expo-constants'
import { initializeAuth, browserLocalPersistence } from "firebase/auth";
import { getReactNativePersistence } from 'firebase/auth/react-native';
import { enableFreeze } from "react-native-screens";
import { useSystemDocument } from 'bridge';
import { StringTable } from 'utils';
import { getAmericano } from './functions/shared/business/helpers';
import { AmericanoTester, AmericanoEditor } from './testprograms';
import useCheckForUpdates from './useCheckForUpdates';

const __STAGE__ = __DEV__ || Constants.appOwnership === 'expo' || window?.location?.href?.includes('localhost') || window?.location?.href?.includes('192') || window?.location?.href?.includes('prank-dev') ? 'dev' : (window?.location?.href?.includes('staging') ? 'staging' : 'prod');

const dbParams = {
    'dev': {
        apiKey: "AIzaSyAiahfVHg67QdsTc_VAz1BUqFTNaGrj0Hg",
        authDomain: "prank-dev-150de.firebaseapp.com",
        projectId: "prank-dev-150de",
        storageBucket: "prank-dev-150de.appspot.com",
        messagingSenderId: "703394450763",
        appId: "1:703394450763:web:23f9687f10acbee5d32cce", 
        databaseURL: "https://prank-dev-150de-default-rtdb.europe-west1.firebasedatabase.app"
    },
    'staging': {
        apiKey: "AIzaSyDYEXRwoOsMLHIBDaiEVRnVmNlikASObFU",
        authDomain: "padel-id-staging.firebaseapp.com",
        projectId: "padel-id-staging",
        storageBucket: "padel-id-staging.appspot.com",
        messagingSenderId: "1072244716078",
        appId: "1:1072244716078:web:fec0e5ce2183b64a51e5bf",
        databaseURL: "https://padel-id-staging-default-rtdb.europe-west1.firebasedatabase.app",
    },
    'prod': {
        apiKey: "AIzaSyDOYdJ73FP-p0KOO5dFHWYEEchKyKtU_LY",
        authDomain: "padel-id.firebaseapp.com",
        projectId: "padel-id",
        storageBucket: "padel-id.appspot.com",
        messagingSenderId: "989899674812",
        appId: "1:989899674812:web:73d7bcba4327ddbfacecb9",
        databaseURL: "https://padel-id-default-rtdb.europe-west1.firebasedatabase.app"
    }
}

const app = initializeApp(dbParams[__STAGE__]);
const auth = initializeAuth(app, {
    persistence: Platform.OS === 'web' ? browserLocalPersistence : getReactNativePersistence(AsyncStorage),
    popupRedirectResolver: undefined,
});


/*
    CHANGE LOG:
    3.6.3
    -Adressed appstore compliance issues (delete account button + info.plist for images)

    3.6.2
    -Don't sort by ranking if team signup is true
    -Team winners court defaults to sort by ranking false 
    
    3.6.1
    -Deterministic Match ID for Mexicanos
    -Made Mexicano sorting of permutation candidates deterministic
    -Fixed clear result bug.
    -Fixed register phone number for named player bug.
    -Added clarifying text on private groups.

    3.6
    -Upgrade Expo version 
    -Fix register match result cloud function
    -Fix double prefix bug
    -Fix login button double press
    -Fix copy phone number
    
    3.5.2
    -Fixed bug with rerouting direct links from groups/groupId to group/groupId, causing navigation to crash 
    -Fixed notifications on Android
    -Sort match history on date
    -Removed automatic addition of creator of Big Tournament as player

    3.5
    -Allow un-starting and un-ending events
    -Allow signup with vacant partner, and prevent odd number of player added to team signup
    -Allow paste of phone numbers in phone number input
    -Remove delete event from scheduled events
    -Fixed bug with registering a players phone number during a tournament
    -Fixed bug with changing event type after scheduling
    -Fixed bug with searching in ContactListSheet

    3.3.2
    -Fixed deep links on some android phones (*.padel.id did not catch https://padel.id but did catch https://www.padel.id)
    -Added automatic check for, and installation of, updates when app is foregrounded

    3.3.1
    -Fixed DropdownMenu background color (made text invisible in dark mode)

    3.3
    -Supporting dark mode
    -Several improvements to contact book
        -Fixed an issue where repeat search-and-selects would drop first selection
        -Segmenting items to show Common contacts and Phonebook separately
        -Fixed "create contact" was not responsive on Android
    -More intuitive way to swap teams in groups
    -Option to start event when attempting to register results
    -Made it more obvious that you can bring up the round constraints sheet
    -Fixed a bug where an event clubRef was not always downloaded by EventSheet
    -Fixed a bug where blank mexicano rounds were introduced after editing previous round
    -Minor UI fixes to schedule constraints editor
    -Track if user has explicitly edited courts, and if so don't apply default based on slots
    -Added a class selection layer before showing the edit sheet on big tournaments
    -Make admins of big tournament automatically become admins of all tournament classes
    -Added an edit admins menu option for competitions and groups
    -Include localized characters, such as å, ä, ö, ç, á or é in search tokens
    -Minor improvements to round timer and backgrounded alarms, but still only partially done

    3.2.1
    -Critical bug fix, contact book crashed if phonebook had contact without name

    3.2
    -Rewritten contact book
        -Properly shows titles for phonebook contacts and provisional contacts
        -Reads old app contacts but writes to phonebook instead for new contacts
        -Accepts extra contacts depending on context
            -Previous group admins
            -Group members 
            -Event participants
        -Better search
    -Refactored shared modules
        -Big and risky move, could have missed a reference somewhere (risk for crash)
    -Showing two most recent events for groups and on home screen
    -Changed reserves to not be auto-promoted when slot count changes
    -Fixed issue that prevented us from registering push notification tokens for new users
    -Fixed swapping of players between list of players, reserves, invited
    -Fixed chat notifications to look good
    -Fixed issue with big tournaments not showing up on players home screens
    -Fixed issue not being able to create events from club screen
    -Various fixes related to onboarding, mostly visual glitches
    -Showing recent events in groups, clubs and on home screen
    -More roboust way of creating provisional users (used to show name of non-registered users)
        -Create provisional user joins with non-registered partner
        -Create provisional users when adding players to event
        -Create provisional users when inviting players to event
    -Fixes an issue where Big Tournaments with Mexicano classes lost matches when a new round was created

    3.1.1
    -Critical bug fix, respond to invitation did not register the player

    3.1
    -Better registration when not logged in, i.e. on web
    -Possible to register for a big tournament directly from top screen, no need to navigate to specific class
    -Copy link now copies padel.id/home/competitionId, or .../clubs/clubId, .../groups/groupId, ignoring club and group for competitions (affects back button behaviour)
    -Added padel.id/home to paths in apple-app-site-associations, to open app instead of web for such links
    -Team swap in Winners Court now consider all four permutations (AC vs DB, AD vs CB, BC vs DA, BD vs CA), not just two as before
    -Conditionally send SMS with branded ”Padel iD” sender
    -Fixed a bug where names of non Padel iD users in contact books would disappear	
    
    
    Metro bundler fails on cjs file:
    https://github.com/expo/expo/issues/17469

    Reanimated __reanimatedWorkletInit not a function issue
    https://github.com/software-mansion/react-native-reanimated/issues/3111
    Fixed like so: https://github.com/software-mansion/react-native-reanimated/issues/1423#issuecomment-1146540374

    https://medium.com/firebase-developers/should-i-query-my-firebase-database-directly-or-use-cloud-functions-fbb3cd14118c

    use expo export:web instead of expo build:web
    expo build:web
    firebase firestore:indexes
    copy to firestore.indexes.json
    copy build-web/index.html to functions/hosting/index.html

    firebase deploy -P dev

    firebase use dev | prod
    change Api db if hardcoded

    npm adduser 
    username: staffan-klashed
    pwd: 3space4me!


    update version in app.json
    update paths in apple-app-site-association and assetlinks, if applicable
    eas build --platform ios
    log in to Apple account
    ios bundle identifier: com.staffanklashed.padelid

    eas submit --platform ios
    Select Add a new ASC API key
    //Tutorial on appstore and build process:
    https://www.notjust.dev/blog/2022-03-29-how-to-publish-expo-react-native-app-to-apple-app-store

    eas submit --platform android
    Select 

    Configure Android push notification credentials:
    https://docs.expo.dev/push-notifications/push-notifications-setup/#get-credentials-for-development-builds

    https://www.anothermadworld.com/firebase-deploy-to-different-projects-with-different-configs/
*/

export default function App(props) {
    console.log('connecting to ' + __STAGE__);
   
    return (
        <AppMain {...props}/>
    )
}

function AppMain(props) {

    enableFreeze(true);
    const start = useRef(new Date()).current;

    const [error, setError] = useState();

    const [fontsLoaded, fontsError] = useFonts({
        'Roboto-Thin': require('./assets/fonts/Roboto/Roboto-Thin.ttf'),
        'Roboto-Light': require('./assets/fonts/Roboto/Roboto-Light.ttf'),
        'Circular-Book': require('./assets/fonts/Circular/Circular-Book.otf'),
        'Circular-Regular': require('./assets/fonts/Circular/Circular-Regular.otf'),
        'Circular-Bold': require('./assets/fonts/Circular/Circular-Bold.otf'),
        'Montserrat-ExtraBold': require('./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf'),
        'utilityFonts': require('./assets/fonts/utilityIcons/icomoon.ttf')
    });
    const stringTableLoaded = useStringTable(start);
    const locales = useSystemDocument('system/locales');
    const regions = useSystemDocument('system/regions');
    const settings = useSystemDocument('system/settings');

    const loading = !fontsLoaded || !locales; //(fontsLoaded && stringTable && locales && regions) || (Platform.OS === 'web' && window.location.pathname === '/storybook') ? false : true;

    useEffect(() => { fontsLoaded && console.log(`[App]: ${Date.now() - start.getTime()}ms fonts loaded`) }, [fontsLoaded]);
    useEffect(() => { locales && console.log(`[App]: ${Date.now() - start.getTime()}ms locales loaded`) }, [locales]);
    useEffect(() => { regions && console.log(`[App]: ${Date.now() - start.getTime()}ms regions loaded`) }, [regions]);
    useEffect(() => { settings && console.log(`[App]: ${Date.now() - start.getTime()}ms settings loaded`) }, [settings]);
       
    const updateStatus = useCheckForUpdates();

    return (
        <ErrorBoundary error={error}>
            <ThemeProvider>
                <ImageCacheProvider>
                    <AppContextProvider 
                        auth={auth}
                        locales={locales} 
                        regions={regions?.all} 
                        tutorials={settings?.tutorials} 
                        latestVersion={settings?.latestVersion} 
                        setError={setError}
                    >
                        <View style={{flex: 1}}>
                            { (loading || updateStatus) ? <LoaderScreen status={updateStatus}/> : <RootNavigator/> }
                        </View>
                    </AppContextProvider>                
                </ImageCacheProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

function useStringTable(start) {
    const [loaded, setLoaded] = useState(false);
    const stringTable = useSystemDocument('system/stringtable');
    useEffect(() => {
        if (stringTable) {
            StringTable.setStringTable(stringTable);
            console.log(`[App]: ${Date.now() - start.getTime()}ms stringtable loaded`);
            setLoaded(true);
        }
    }, [stringTable]);
    return loaded;
}
