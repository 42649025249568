import React from 'react';
import { View } from 'react-native';
import Icon from './helpers/Icon';
import Paragraph from './typography/Paragraph';
import useThemeStyle from './theme/useThemeStyle';
import useTheme from './theme/useTheme';
import Pressable from './helpers/Pressable';

export interface RadioButtonProps {
    selected?: string,
    options: string[],
    onSelect: (option: string) => void,
}

export default function RadioButton(props: RadioButtonProps) {

    const {
        selected,
        options,
        onSelect
    } = props;

    //@ts-ignore
    const {iconColor, iconSize, ...buttonStyle} = useThemeStyle('RadioButton', props);
    const theme = useTheme();
    return(
        <View>
            { options.map((option: string) => (
                <Pressable key={option} style={{flexDirection: 'row', marginTop: theme["spacing-extrasmall"]}} onPress={() => onSelect(option)}>
                    <View style={buttonStyle}>
                        { selected === option ? <Icon name={"ico_circle"} color={iconColor} size={iconSize}/> : null }
                    </View>
                    <Paragraph style={{color: iconColor}}>{option}</Paragraph>
                </Pressable>
            ))}
        </View>
    )
}