import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { getStorage, getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useTheme, KeyboardDismissingView, Section, SectionHeader, ImageUploadButton, Button, TextInput, CheckBox } from 'components';
import { StringTable } from 'utils';
import { createId } from 'tools';
import { useAppContext } from '../../appcontext';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';
import { useClubs } from '../../hooks';
import ClubSheet from './ClubSheet';
import useModal from '../useModal';
import { IGroupSettings } from 'interfaces';

interface GroupSheetProps {
    title: string,
    doneLabel: string,
    resolve?: (data: IGroupSheetResult | null) => void,
    initialSettings?: Partial<IGroupSettings>,
    //Note that style SHOULD be required, it's not a typo. Modals need to read flex from style
    style: any
}

GroupSheet.defaultProps = {
    initialGroup: {}
}

export default function GroupSheet(props: GroupSheetProps) {

    const {
        title,
        doneLabel, 
        resolve,
        initialSettings,
        style : styleProp
    } = props; 

    const { currentUser } = useAppContext();
    const theme = useTheme();
    const clubs = useClubs(currentUser?.settings?.region, currentUser?.settings?.clubRefs);

    const [uploadingImage, setUploadingImage] = useState(false);
    const [groupSettings, setGroupSettings] = useState(initialSettings || {});
    useEffect(() => setGroupSettings(initialSettings || {}), [initialSettings]);

    const { pushModal, popModal } = useModal();
    const sheetStyle = useSheetStyle();
    
    return(
        <KeyboardDismissingView style={[sheetStyle, styleProp]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={title || StringTable.getString('Create event')}/>
            <View style={{flex: 1}}>
                <ScrollView showsVerticalScrollIndicator={false} style={{flex: 1}}>

                    {/* Title */}
                    <Section>
                        <SectionHeader icon={'ico_title'} label={StringTable.getString('TITLE')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={groupSettings?.title || ''}
                                onChangeText={(t: string) => setGroupSettings(prev => ({...prev, title: t}))}
                                iconLeft={'ico_title'}
                            />
                        </Section>
                    </Section>

                    {/* Byline */}
                    <Section>
                        <SectionHeader icon={'ico_title'} label={StringTable.getString('BYLINE')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={groupSettings?.byline || ''}
                                onChangeText={(t: string) => setGroupSettings(prev => ({...prev, byline: t}))}
                                iconLeft={'ico_title'}
                            />
                        </Section>
                    </Section>

                    {/* Description */}
                    <Section>
                        <SectionHeader icon={'ico_document'} label={StringTable.getString('DESCRIPTION')}/>
                        <Section size={'M'}>
                            <TextInput
                                value={groupSettings?.description || ''}
                                onChangeText={(t: string) => setGroupSettings(prev => ({...prev, description: t}))}
                                iconLeft={'ico_document'}
                                multiline={true}
                                numberOfLines={4}
                            />
                        </Section>
                    </Section>

                    {/* WHERE */}
                    <Section>
                        <SectionHeader icon={'ico_location'} label={StringTable.getString('WHERE?')}/>
                        <Section size={'M'}>
                            <Button 
                                icon={'ico_location'}
                                label={groupSettings?.clubRef ? clubs?.find(c => c.id === groupSettings.clubRef)?.settings.getTitle() : StringTable.getString('Set club')}
                                onPress={async (e: any) => {
                                    const clubSelectionResult : IClubSheetResult = await pushModal((
                                        <ClubSheet selectedClub={groupSettings?.clubRef ? clubs?.find(c => c.id === groupSettings?.clubRef) : undefined} style={{flex: 1}}/>
                                    ), {...e, pageY: 0});

                                    if (clubSelectionResult) {
                                        clubSelectionResult.club && currentUser?.followClub(clubSelectionResult.club.id);
                                        setGroupSettings(prev => ({
                                            ...prev, 
                                            clubRef: clubSelectionResult.club?.id, 
                                            location: clubSelectionResult.club?.settings.location
                                        }))
                                    }

                                    popModal();
                                }}
                                mode={'contained'}
                                penColor={groupSettings.clubRef ? theme["color-ink-contained"] : theme["color-ink"]}
                                color={groupSettings.clubRef ? theme["color-primary"] : theme["color-ink-supersoft"]}
                            />
                        </Section>
                    </Section>

                    <Section>
                        <SectionHeader icon={'ico_addimage'} label={StringTable.getString('GROUP IMAGE')}/>
                        <Section size={'M'}>
                            <ImageUploadButton 
                                imageUrl={groupSettings?.imageUrl}
                                uploading={uploadingImage}
                                maxImageSize={500}
                                shape={'circle'}
                                upload={async(blob: any) => {
                                    if (currentUser?.id) {
                                        const metaData = {
                                            cacheControl: 'public,max-age=31536000'
                                        }
                                        setUploadingImage(true);
                                        const storage = getStorage();
                                        const storageRef = ref(storage, `uploads/${currentUser.id}/${createId(7)}.jpg`);
                                        const uploadResult = await uploadBytes(storageRef, blob, metaData);
                                        const downloadURL = await getDownloadURL(uploadResult.ref);
                                        setGroupSettings(prev => ({...prev, imageUrl: downloadURL}));
                                        setUploadingImage(false);
                                    }
                                }}
                            />
                        </Section>
                    </Section>

                    <Section>
                        <SectionHeader icon={'ico_groupround'} label={StringTable.getString('ADVANCED')} />
                            <Section size={'M'}>
                                <CheckBox
                                    checked={groupSettings?.private ? false : true}
                                    label={StringTable.getString('Anyone can join')}
                                    onPress={() => setGroupSettings(prev => ({ ...prev, private: prev?.private ? false : true }))}
                                    size={'L'}
                                />
                            </Section>
                    </Section>

                </ScrollView>
            </View>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button 
                    onPress={(e: any) => {
                        resolve && resolve({e: e, settings: groupSettings});
                    }} 
                    mode={groupSettings.title ? 'primary' : 'passive'} 
                    style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} 
                    label={doneLabel}
                />
            </Section>

        </KeyboardDismissingView>
    )
}
