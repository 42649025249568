import { createId } from '../tools';
import { ISuperScheduleCourt, IRoundConstraints, IScheduleCell, IMatch } from '../interfaces';

export class SuperSchedule {

    id: string;
    collection: string;
    settings: {
        startDate: Date;
        roundLength: number, 
        courts: ISuperScheduleCourt[]
    };
    data?: IScheduleCell[][];
    matches?: IMatch[];
    constraints?: {[round: number]: IRoundConstraints };
    roundLengths?: {[round: number]: number };
    competitionNames: {[competitionId: string]: string };
    saveFunc: any;
    
    constructor(data: any, saveFunc?: any) {
        this.id = data.id || createId(23);
        this.collection = "schedules";
        this.settings = {
            startDate: data.settings?.startDate || new Date(),
            roundLength: data.settings?.roundLength || 30, 
            courts: data.settings?.courts ? data.settings.courts:  [{id: '1', name: 'Court 1'}]
        };
        this.matches = data.matches;
        this.data = this.parseData(data.data);
        this.constraints = data.constraints;
        this.roundLengths = data.roundLengths;
        this.competitionNames = data.competitionNames;
        this.saveFunc = saveFunc || this.defaultSaveFunc;
        //TODO: This call should not be necessary as we save data in the order it was created.
        //But perhaps we don't update schedule properly before creating / saving...
        this.onUpdateSchedule('constructor');
    }

    defaultSaveFunc(f: (self: SuperSchedule) => void) {
        f(this);
    }

    serialize() {
        return {
            id: this.id, 
            settings: this.settings,
            data: this.serializeData(),
            matches: this.matches, 
            constraints: this.constraints,
            roundLengths: this.roundLengths,
            competitionNames: this.competitionNames
        }
    }

    meta() {
        return {
            id: this.id,
            lastModifiedDate: new Date()
        }
    }

    parseData(data: any) {

        if (!data) {return data};
        return Object.keys(data).sort().map(c => data[c]);
    }
    serializeData() {
        if (!this.data) {return this.data};        
        return this.data.reduce((acc: {[key: number]: any[]}, curr: any[], index: number) => {
            acc[index] = curr;
            return acc;
        }, {});
    }
    set(data: IScheduleCell[][]) {
        this.data = data;
    }

    async setMatches(matches: IMatch[]) {
        await this.saveFunc((self: SuperSchedule) => {
            self.onSetMatches(matches);
        })
    }
    onSetMatches(matches: IMatch[]) {
        this.matches = matches;
        this.onUpdateSchedule('onSetMatches');    
    }

    addMatches(matches: IMatch[]) {
        this.saveFunc((self: SuperSchedule) => {
            self.onAddMatches(matches);
        })
    }
    onAddMatches(matches: IMatch[]) {
        this.matches = [...(this.matches || []), ...matches];
        this.onUpdateSchedule('onAddMatches');
    }

    replaceMatch(matchId: string, match: IMatch) {
        this.saveFunc((self: SuperSchedule) => {
            self.onReplaceMatch(matchId, match);
        })
    }
    onReplaceMatch(matchId: string, match: IMatch) {
        if (!this.matches) { return }
        const matchIndex = this.matches?.findIndex(m => m.id === matchId);
        if (matchIndex !== -1) {
            this.matches[matchIndex] = match;
        }
        this.onUpdateSchedule('onReplaceMatch');
    }

    replaceAllMatchesInCompetition(competitionId: string, matches: IMatch[]) {
        this.saveFunc((self: SuperSchedule) => {
            self.onReplaceAllMatchesInCompetition(competitionId, matches);
        })
    }
    onReplaceAllMatchesInCompetition(competitionId: string, matches: IMatch[]) {
        this.matches = [...(this.matches || []).filter(m => m.competitionId !== competitionId), ...matches];
        this.onUpdateSchedule('onReplaceAllMatchesInCompetition');
    }

    replaceSomeMatchesInCompetition(competitionId: string, matches: IMatch[]) {
        this.saveFunc((self: SuperSchedule) => {
            self.onReplaceSomeMatchesInCompetition(competitionId, matches);
        })
    }
    onReplaceSomeMatchesInCompetition(competitionId: string, matches: IMatch[]) {
        matches.forEach(match => {
            const matchIndex = this.matches ? this.matches.findIndex(m => m.competitionId === competitionId && m.id === match.id) : -1;
            if (matchIndex !== -1) {
                this.matches?.splice(matchIndex, 1, match);
            }
        })
        this.onUpdateSchedule('onReplaceSomeMatchesInCompetition');
    }

    setCourts(courts: ISuperScheduleCourt[]) {
        this.saveFunc((self: SuperSchedule) => {
            self.onSetCourts(courts);
        })
    }
    onSetCourts(courts: ISuperScheduleCourt[]) {
        this.settings.courts = courts;
        this.onUpdateSchedule('onSetCourts');
    }

    setConstraints(roundIndex: number, constraints: IRoundConstraints) {
        this.saveFunc((self: SuperSchedule) => {
            self.onSetConstraints(roundIndex, constraints);
        })
    }
    onSetConstraints(roundIndex: number, constraints: IRoundConstraints) {

        this.constraints = this.constraints || {};
        this.constraints[roundIndex] = {
            ...this.constraints[roundIndex], 
            ...constraints
        }
        this.onUpdateSchedule('onSetConstraints');            
    }


    setRoundLength(time: number, roundIndex: number, global: boolean) {
        this.saveFunc((self: SuperSchedule) => {
            self.onSetRoundLength(time, roundIndex, global);
        });
    }
    onSetRoundLength(time: number, roundIndex: number, global: boolean) {
        if (global) {
            this.roundLengths = {};
            this.settings.roundLength = time;
        }
        else {
            this.roundLengths = this.roundLengths || {};
            this.roundLengths[roundIndex] = time;
        }
        //We don't need to update schedule
    }

    setStartDate(date: Date) {
        this.saveFunc((self: SuperSchedule) => {
            self.onSetStartDate(date);
        });
    }
    onSetStartDate(date: Date) {
        this.settings.startDate = date;
        //We don't need to recreate schedule
    }

    updateSchedule() {
        this.saveFunc((self: SuperSchedule) => {
            self.onUpdateSchedule('updateSchedule');
        })
    }
    onUpdateSchedule(caller: string) : IScheduleCell[][] | null {

        //const startTime = Date.now();

        const matches = this.matches;
        const constraints = this.constraints;
        const courts = this.settings.courts;

        if (!matches) { return null }
        
        const schedule : IScheduleCell[][] = [];
        const queue = [...matches];
        const scheduledMatchCounters : {[competitionId: string]: number } = matches.reduce((acc, curr) => ({...acc, [curr.competitionId]: 0}), {});

        let safety = 500;
        let currentRound = 0;

        while (queue.length && safety--) {
            
            /**
             * Since we splice matches out of the queue, we need to track 
             * what was already scheduled this round.
             */
            const scheduledInRound : IMatch[] = [];

            /**
             * get and set busy players so we don't schedule a players
             * twice in the same round
             */
            const busyPlayers : { [playerId: string]: boolean } = {};
            function getPlayersBusy(userRefs: (string | null)[]) {
                return userRefs?.find(u => u && busyPlayers[u])
            }
            function setPlayersBusy(userRefs: (string | null) []) {
                userRefs?.forEach(u => {
                    if (u) {
                        busyPlayers[u] = true;
                    }
                });
            }

            function isPlayoffRoundStarted(competitionId: string, playoffRound: number, match: IMatch) {

                if (!playoffRound) {
                    return true;
                }
                //If queue contains a match with a lower playoff round, we have not reached playoffRound
                if (queue.find(m => m.competitionId === competitionId && ((m.playoffRound || 0) < playoffRound))) {
                    return false;
                }
                //If this round contains a match with lower playoff round, we have not reached playoffRound
                if (scheduledInRound.find(m => m.competitionId === competitionId && ((m.playoffRound || 0) < playoffRound))) {
                    return false;
                }
                return true;
            }

            function constrainedCompetition(competitionId: string) {
                return constraints?.[currentRound]?.constrainedCompetitions?.includes(competitionId) || false;
            }

            function constrainedGroup(competitionId: string, groupIndex?: number) {
                if (groupIndex !== undefined) {
                    return constraints?.[currentRound]?.constrainedGroups?.[competitionId]?.includes(groupIndex) || false;
                }
                return false;
            }

            function constrainedCourt(courtId: string) {
                return constraints?.[currentRound]?.constrainedCourts?.includes(courtId) || false;
            }
            
            schedule[currentRound] = [];
            courts.forEach((court, courtIndex) => {

                if (constrainedCourt(court.id)) {
                    schedule[currentRound][courtIndex] = { roundIndex: currentRound, courtId: court.id, type: 'block' }
                }
                else {
                    const sortedQueue = [...queue].sort((a: IMatch, b: IMatch) => {

                        const prioACompetition = constraints?.[currentRound]?.prioritizedCompetitions?.includes(a.competitionId);
                        const prioAGroup = constraints?.[currentRound]?.prioritizedGroups?.[a.competitionId]?.includes(a.groupIndex === undefined ? -1 : a.groupIndex);
                            
                        const prioBCompetition = constraints?.[currentRound]?.prioritizedCompetitions?.includes(b.competitionId);
                        const prioBGroup = constraints?.[currentRound]?.prioritizedGroups?.[b.competitionId]?.includes(b.groupIndex === undefined ? -1 : b.groupIndex);

                        const prioA = (typeof prioAGroup === 'undefined' || a.playoffRound) ? prioACompetition : (prioACompetition && prioAGroup);
                        const prioB = (typeof prioBGroup === 'undefined' || b.playoffRound) ? prioBCompetition : (prioBCompetition && prioBGroup);

                        if (prioA || prioB) {
                            return (prioB ? 1 : 0) - (prioA ? 1 : 0);
                        }
                        return scheduledMatchCounters[a.competitionId] - scheduledMatchCounters[b.competitionId];
                    });

                    const filteredQueue = sortedQueue.filter((match: IMatch) => 
                        !constrainedCompetition(match.competitionId) &&
                        !constrainedGroup(match.competitionId, match.groupIndex) && 
                        !getPlayersBusy(match.userRefs) && 
                        isPlayoffRoundStarted(match.competitionId, match.playoffRound || 0, match)
                    );

                    const match = filteredQueue.find((match: IMatch) => match.requiredCourtId === court.id) || filteredQueue.filter((match: IMatch) => !match.requiredCourtId)?.[0];

                    if (match) {
                        scheduledMatchCounters[match.competitionId] += 1;
                        schedule[currentRound][courtIndex] = { roundIndex: currentRound, courtId: court.id, type: 'match', id: match.id, competitionId: match.competitionId }
                        setPlayersBusy(match.userRefs);
                        scheduledInRound.push(match);
                        queue.splice(queue.indexOf(match), 1);
                    }
                    else {
                        schedule[currentRound][courtIndex] = { roundIndex: currentRound, courtId: court.id, type: 'gap'}
                    }
                }
                schedule[currentRound][courtIndex].delay = constraints?.[currentRound]?.delay;
            });
            currentRound++;
        }
        this.data = schedule;

        //console.log(`[onUpdateSchedule]: ${Date.now() - startTime}ms from ${caller}`)
        return schedule;
    }

    getTimeLabel(roundIndex: number, delay?: number) {
        if (this.data) {
            let delta = 0;
            for (let i=0; i < roundIndex; i++) {
                delta += (this.roundLengths?.[i] || this.settings.roundLength) + (this.data[i]?.[0]?.delay || 0);
            }
            delta += delay === undefined ? (this.data[roundIndex]?.[0]?.delay || 0) : delay;

            const d = new Date(this.settings.startDate.getTime() + delta * 60 * 1000);
            return `${d.getHours().toString().padStart(2, "0")}:${d.getMinutes().toString().padStart(2, "0")}`;
        }
        return "no time"
    }

    getMatchCell(matchId: string) {
        let cell : IScheduleCell | undefined;
        if (this.data) {
            this.data.some((round: IScheduleCell[]) => {
                cell = round.find(c => c.id === matchId);
                return cell;
            })
        }
        return cell;
    }
}
