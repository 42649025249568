//@ts-nocheck
import { getFirestore, doc, setDoc, runTransaction } from "firebase/firestore";
import { noUndefined } from 'tools'
import parse from './parse';
import { Competition, Conversation, Group, Club, User } from "business";
/**
 * Inside save, this will refer to the class instance calling save. We are calling
 * the save func with this.saveFunc((self: any) => {...} from inside the class instance.
 */
export default async function save(f: (self: any) => Promise<any>, asTransaction: boolean = false) {

    const documentRef = doc(getFirestore(), `${this.collection}/${this.id}`);

    const _classes = {
        'competitions': Competition,
        'conversations': Conversation,
        'groups': Group, 
        'clubs': Club, 
        'users': User
    }

    let retVal;
    const _class = _classes[this.collection];

    if (asTransaction) {
        try {
            await runTransaction(getFirestore(), async (transaction) => {

                const document = await transaction.get(documentRef);
                if (document.exists()) {
                    const instance = new _class(parse(this.collection, document), save);                    
                    retVal = await f(instance);
                    const data = noUndefined(instance.serialize?.());
                    const meta = noUndefined(instance.meta?.());
                    transaction.set(documentRef, { meta, data });
                }
                else {
                    retVal = await f(this);
                    const data = noUndefined(this.serialize?.());
                    const meta = noUndefined(this.meta?.());            
                    transaction.set(documentRef, { meta, data });
                }
            })
        } catch (e) {
            console.log('error', e);
            return false;
        }
    }
    else {
        retVal = await f(this);
    
        const data = noUndefined(this.serialize?.());
        const meta = noUndefined(this.meta?.());

        try {
            console.log('[setDoc]: save')
            await setDoc(documentRef, { meta, data });
        } catch(err) {
            console.log('error', err);
        }
    }

    return retVal;
}

