import React, { useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { SectionList } from 'components';
import { Group, Competition, UserIdentity } from 'business';
import { useGroup, useGroupCompetitions, useRecentGroupCompetitions, useClub, useUsers, useStyles, useLayout, usePreloadUsers, useGoBackIfNull } from '../hooks';
import { DocHeader, DocPanel, DocMenu, CompetitionCard, UserCard, SectionHeader } from '../composites';
import { NavigationProvider } from '../navigation';
import { StringTable } from 'utils';
import { useActions } from '../actions';
import WaitingScreen from './WaitingScreen';
import { arrayUnique } from 'tools';

export default function GroupScreen(props: ScreenProps) {

    const { route } = props;    
    const { groupId } = route.params;

    const group : Group | null | undefined = useGroup(groupId);
    const groupCompetitions = useGroupCompetitions(groupId);
    const recentGroupCompetitions = useRecentGroupCompetitions(groupId);

    const sections = useGroupSections(group?.participation.userRefs, groupCompetitions, recentGroupCompetitions);
    const club = useClub(group?.settings.clubRef);
    const styles = useStyles();
    const { columns } = useLayout();
    const { setTarget, showUserMenu } = useActions();
    
    const isFocused = useIsFocused();
    useEffect(() => {
        isFocused && setTarget({group, club});
    }, [isFocused, group, club]);
    
    const loading = 
        (groupId && group === undefined) || 
        groupCompetitions === undefined || 
        (group?.settings.clubRef && club === undefined) ||
        sections === undefined || 
        !isFocused;

    useGoBackIfNull(group);
    
    return (
        <NavigationProvider>
            { loading ? <WaitingScreen/> : (
                    <View style={styles.screen}>
                        <DocHeader title={group?.settings.title} floating={true}>
                            <SectionList 
                                style={{flex: 1}}
                                numColumns={columns}
                                data={sections || []}
                                keyExtractor={(item) => item?.id || item}
                                windowSize={9}
                                ListHeaderComponent={(
                                    <DocPanel 
                                        imageUrl={group?.settings?.imageUrl} 
                                        userRefs={group?.participation?.userRefs} 
                                        title={group?.settings?.getTitle()} 
                                        clubTitle={club?.settings?.getTitle()}
                                        byline={group?.settings?.getByline()} 
                                        description={group?.settings?.description} 
                                        isPublic={group?.settings?.public}
                                        isPrivate={group?.settings?.private}
                                    >
                                        <DocMenu title={group?.settings?.getTitle()}/>
                                    </DocPanel>
                                )}
                                renderSectionItem={(item, index, sectionId) => (sectionId === 'event' || sectionId === 'recent') ? (
                                    <CompetitionCard competition={item}/>)
                                : (
                                    <UserCard 
                                        userRef={item} 
                                        verbose={true} 
                                        onPress={showUserMenu}
                                    />
                                )}
                                renderSectionHeader={(header, counter, sectionId) => (
                                    <SectionHeader 
                                        header={header} 
                                        counter={counter} 
                                        sectionId={sectionId}
                                        isPrimary={sectionId === 'event' || sectionId === 'members'}
                                        addLabel={sectionId === 'event' ? StringTable.getString('Create') : undefined}
                                        canAdd={sectionId === 'event' || sectionId === 'members' ? true : false}

                                    />
                                )}
                            />
                        </DocHeader>                        
                    </View>
            )}                 
        </NavigationProvider>
    )
}

function useGroupSections(groupMembers?: string[], groupCompetitions?: Competition[], recentGroupCompetitions?: Competition[]) {
    
    //Preloading users allow us to not have to re-sort users for every UserIdentity that loads
    const usersPreloaded = usePreloadUsers(groupMembers);
    const users = useUsers(usersPreloaded ? groupMembers : undefined);

    const sortedUsers = useMemo(() => {
        return arrayUnique(groupMembers)?.map(
            (memberRef: string) => users[memberRef] || new UserIdentity(memberRef, {})
        ).sort(
            (a: UserIdentity, b: UserIdentity) => (b.rating || 1000) - (a.rating || 1000)
        );
    }, [JSON.stringify(groupMembers), users]);

    const listData : ISectionListSection[] = useMemo(() => {
        const p = [];
        p.push({
            id: 'event',
            header: `${StringTable.getString('Upcoming events')}`,
            counter: `${groupCompetitions?.length || 0}`,
            data: groupCompetitions || []
        });
        recentGroupCompetitions?.length && p.push({
            id: 'recent',
            header: `${StringTable.getString('Recent events')}`,
            counter: `${recentGroupCompetitions?.length || 0}`,
            data: recentGroupCompetitions || []
        });
        p.push({ 
            id: 'members',
            header: StringTable.getString('Members'),
            counter: `${groupMembers?.length || 0}`,
            data: sortedUsers?.map((u: UserIdentity) => u.id) || []
        });
        return p;
    }, [sortedUsers, groupCompetitions, recentGroupCompetitions]);

    const loading = !usersPreloaded || groupCompetitions === undefined;

    return loading ? undefined : listData;
}
