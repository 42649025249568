import React, { useState, useEffect, useRef, useMemo } from 'react';
import { View, TextInput, Text, Pressable, Animated, Keyboard, StyleSheet, Platform } from 'react-native';
import Subheader from './typography/Subheader';
import useTheme from './theme/useTheme';
import useThemeStyle from './theme/useThemeStyle';

interface TextInputCodeProps {
    cells: number, 
    columns: number,
    value: string,
    placeholder?: string,
    onChangeText?: (t: string) => any,
    disabled?: boolean,
    doubleDigit?: boolean,
    onBlur?: any, 
    onFocus?: any,
    mode?: string,
    size?: string
}

TextInputCode.defaultProps = {
    cells: 6, 
    columns: 3,
    value: ''
}

export default function TextInputCode(props: TextInputCodeProps) {

    const {
        cells, 
        columns : columnsProp,
        value,
        placeholder,
        onChangeText,
        disabled,
        doubleDigit,
        onBlur : onBlurProp, 
        onFocus : onFocusProp
    } = props;

    const [focused, setFocused] = useState(false);
    //@ts-ignore
    const { penColor, placeholderColor, ...cellStyle } = useThemeStyle("TextInputCode", props, focused ? "focused" : "default")

    const columns : Array<number[]> = useMemo(() => makeColumns(cells, columnsProp), [cells, columnsProp]);
    const textInputRef = useRef<any>();

    function onFocus() {
        setFocused(true);
        onFocusProp && onFocusProp();
    }

    function onEndEditing() {
        setFocused(false);
        onBlurProp && Platform.OS !== 'web' && onBlurProp()
    }

    function onBlur() {
        setFocused(false);
        onBlurProp && Platform.OS === 'web' && onBlurProp();
    }

    function onPressCell(cell: number) {
        if (disabled) {
            return;
        }
        textInputRef.current && textInputRef.current.focus();

        if (doubleDigit && cell === 1 && value?.length === 1) {
            onChangeText?.(`0${value}`);
        }
        else {
            onChangeText?.(value?.slice(0, doubleDigit ? cell * 2 : cell));
        }
        onFocus && onFocus();
    }
    return(
        <View style={{flexDirection: 'row'}} pointerEvents={disabled ? 'none' : 'auto'}>
            { columns && columns.map((column: number[], index: number) => (
                <View key={index}>
                    {column.map((cell: number) => (
                        <Cell 
                            key={cell}
                            value={doubleDigit ? value?.slice(cell * 2, cell * 2 + 2) : value?.[cell]}
                            placeholder={doubleDigit ? placeholder?.slice(cell * 2, cell * 2 + 2) : placeholder?.[cell]}
                            caret={focused && value?.length === (doubleDigit ? cell * 2 : cell)} 
                            onPress={() => onPressCell(cell)}
                            style={[cellStyle, index === 0 && {marginLeft: 0}]}
                            penColor={penColor}
                            placeholderColor={placeholderColor}
                        />
                    ))}
                </View>
            ))}
            <TextInput 
                ref={textInputRef}
                pointerEvents={'none'}
                style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: 0.0}}
                value={value}
                keyboardType="number-pad"
                editable={disabled ? false : true}
                onChangeText={t => {
                    let isNum = /^\d+$/.test(t) || !t.length;
                    let isFull = t.length > (doubleDigit ? cells * 2 : cells) ? true : false;
                    isNum && !isFull && onChangeText?.(t)
                }}
                onBlur={onBlur}
                onEndEditing={onEndEditing}
                onFocus={onFocus}
            />            
        </View>
    )
}

interface CellProps {
    value?: string, 
    placeholder?: string,
    caret: boolean,
    onPress: any,
    style: any,
    penColor: string,
    placeholderColor: string
}

function Cell(props: CellProps) {

    const {
        value,
        placeholder,
        onPress,
        caret,
        style,
        penColor,
        placeholderColor
    } = props;
    
    return(
        <Pressable 
            onPress={onPress}
            style={style}
        >
            <Subheader style={{color: value ? penColor : placeholderColor}}>{value || placeholder}</Subheader>
            { caret ? (
                <View style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, alignItems: 'center', justifyContent: 'center'}}>
                    <Caret style={{width: 2, height: 27, backgroundColor: penColor}}/>
                </View>
            ) : null }
        </Pressable>
    )
}

export function Caret(props : any) {

    const {
        style
    } = props;

    const animatedOpacity = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        let toOn = false;
        const id = setInterval(() => {
            animatedOpacity.setValue(toOn ? 1.0 : 0.0);
            toOn = !toOn;
        }, 600);

        return () => {
            clearInterval(id);
        }
    }, []);
    
    return(
        <Animated.View style={[style, {opacity: animatedOpacity}]}/>
    )
}
function makeColumns(cells: number, columnsProp: number) {
    const result = new Array(columnsProp);
    const cellsPerColumn = Math.ceil(cells / columnsProp);
    for (let i = 0; i < cells; i++) {
        const column = Math.floor(i / cellsPerColumn);
        result[column] = [...(result[column] || []), i];
    }
    return result;
}
