import React, { useState, useEffect } from 'react';
import { View, Text, FlatList } from 'react-native';
import { Subheader } from 'components';
import { getFirestore, onSnapshot, query, collection, orderBy, limit } from "firebase/firestore";
import { useUser } from 'bridge';

interface Props {
    app?: any
}

interface Error {
    id: string, 
    date: Date, 
    userId?: string, 
    error: {
        message: string
    }
}

export default function ErrorList(props: Props) {
    const { app } = props;

    const [errors, setErrors] = useState<Error[]>([]);

    useEffect(() => {
        const q = query(collection(getFirestore(app), 'errors'), orderBy('date', 'desc'), limit(25));
        const unsubscribe = onSnapshot(q, querySnapshot => {
            const errors : any = [];
            querySnapshot.forEach(doc => {
            errors.push({ id: doc.id, ...doc.data(), date: doc.data().date.toDate() });
            });
            setErrors(errors);
        });
        return () => unsubscribe();
    }, []);

  return (
    <View style={{flex: 1}}>
      {errors ? (
        <FlatList
            data={errors}
            keyExtractor={item => item.id}
            renderItem={({ item }) => <ErrorMessage {...item}/>}
        />
        ) : (
            <Subheader>No errors</Subheader>
        )}
    </View>
  );
};

function ErrorMessage(props: Error) {
    const { userId, date, error } = props;

    const user = useUser(userId, true);

    return(
        <View style={{padding: 10, borderWidth: 1, borderRadius: 8, marginTop: 6, backgroundColor: 'white'}}>
            <Text>Date: {date.toLocaleString()}</Text>
            <Text>User: {user?.getTitle() || userId}</Text>
            <Text>Error: {error.message}</Text>
        </View>
    )
}