import { useState, useEffect, useRef } from 'react';
import { IdentityCache } from 'bridge';
import { UserIdentity } from 'business';

export default function usePreloadUsers(userRefs?: string[]): boolean {

    const [done, setDone] = useState(false);
    const loading = useRef<{[userRef: string]: boolean}>({}).current;

    useEffect(() => {
        //Clear old loading flags
        Object.keys(loading).forEach(userRef => {
            loading[userRef] = false;
        });
        //Set up new loading flags
        userRefs?.forEach(userRef => {
            loading[userRef] = true;
        });
    }, [userRefs]);
        
    useEffect(() => {
        function onIdentityUpdate(userRef: string, userIdentity: UserIdentity | null) {
            //Clear one loading flag
            loading[userRef] = false;
            const remainsToLoad = Object.keys(loading).filter(key => loading[key] === true);

            if (!remainsToLoad.length) {
                setDone(true);
            }
        }

        if (!userRefs) {
            return;
        }
        if (!userRefs.length) {
            setDone(true);
            return;
        }

        for (const userRef of userRefs) {
            IdentityCache.listen(userRef, onIdentityUpdate);
        }

        return () => {
            for (const userRef of userRefs) {
                IdentityCache.stop(userRef, onIdentityUpdate);
            }
        };
    }, [userRefs]);

    return done;
}
