import { useEffect, useState } from 'react';
import * as Contacts from 'expo-contacts';
import { Locale } from 'interfaces';
import { useAppContext } from '../../../appcontext';

function formatNumber(phoneNumber: Contacts.PhoneNumber, currentUserId: string, locales: {[key: string]: Locale}) : string {

    let number = phoneNumber.number?.replace(/[^0-9+]/g, "");

    if (!number) { 
        return 'ERROR: NO NUMBER';
    }

    if (number.startsWith('+')) {
        //Extract country code
        const dialCode = Object.values(locales).find((l: Locale) => l.dialCode && number!.startsWith(l.dialCode))?.dialCode;
        if (!dialCode) {
            return 'ERROR: UNKNOWN COUNTRY CODE';
        }
        //Clear leading zero after country code
        number = number.replace(dialCode, '').replace(/^0/, "");
        return `${dialCode}${number}`;
    }

    //If the os / expo provided a dial code from phonebook, use it
    else if (phoneNumber.countryCode && phoneNumber.countryCode.startsWith('+')) {
        return `${phoneNumber.countryCode}${number.replace(/^0/, "")}`
    }

    //If the os / expo provided a non-dial code from phone book, derive dial code from it
    else if (phoneNumber.countryCode) {
        const dialCode = Object.values(locales).find((l: Locale) => l?.code?.endsWith(phoneNumber.countryCode!.toUpperCase()))?.dialCode;
        if (dialCode) {
            return `${dialCode}${number.replace(/^0/, "")}`
        }
    }

    //If there was no country code in the phone number, fall back to users dial code
    else if (currentUserId) {
        const dialCode = Object.values(locales).find((l: Locale) => l.dialCode && currentUserId.startsWith(l.dialCode))?.dialCode;
        return `${dialCode}${number.replace(/^0/, "")}`;
    }

    return 'ERROR: UNKNOWN ERROR';
}

export default function usePhoneContacts(): ContactData[] {

    const [phoneContacts, setPhoneContacts] = useState<ContactData[]>([]);
    const { locales, currentUserId } = useAppContext();

    //Fetch phone contacts, only do it once
    useEffect(() => {
        const fetchContacts = async () => {
            const { status } = await Contacts.requestPermissionsAsync();

            if (currentUserId && status === 'granted') {
                const { data } = await Contacts.getContactsAsync({
                    fields: [Contacts.Fields.PhoneNumbers, Contacts.Fields.Name, Contacts.Fields.Image],
                });

                const uniqueIds = new Set();
                const phoneContacts: ContactData[] = data
                    .filter(contact => contact.name)
                    .flatMap((contact, contactIndex) => {
                        if (contact.phoneNumbers) {
                            return contact.phoneNumbers
                                .filter(phone => phone.number !== undefined && !formatNumber(phone, currentUserId, locales).startsWith('ERROR'))
                                .map(phone => {
                                    const id = formatNumber(phone, currentUserId, locales);
                                    return {
                                        id,
                                        title: contact.name,
                                    };
                                })
                                .filter(contact => {
                                    if (contact && contact.id !== '' && !uniqueIds.has(contact.id)) {
                                        uniqueIds.add(contact.id);
                                        return true;
                                    }
                                    return false;
                                });
                        }
                        return [];
                    });
                setPhoneContacts(phoneContacts);
            }
        };

        currentUserId && locales && fetchContacts();

    }, [currentUserId, locales]);

    return phoneContacts;
};
