//@ts-nocheck
import React, { useState, useMemo } from 'react';
import { View, ScrollView, TouchableOpacity, FlatList } from 'react-native';
import { useTheme, KeyboardDismissingView, Pressable, Subheader, Overline, Section, TextInput, Paragraph } from 'components';
import { Locale } from 'interfaces';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';

interface LocalesSheetProps {
    locales: {[key: string]: Locale}, 
    selectedLocale?: Locale,
    style: any,
    resolve?: (data: Locale | null) => void
}

export default function LocalesSheet(props : LocalesSheetProps) {

    const {
        locales,
        selectedLocale,
        style,
        resolve
    } = props;

    const theme = useTheme();
    const [searchLocaleQuery, setSearchLocaleQuery] = useState('');
    const stableLocales = useMemo(() => Object.values(locales), [locales]);

    const commonLocales = ['pt-PT', 'pt-BR', 'sv-SE', 'en-GB'];

    const sheetStyle = useSheetStyle();

    return(
        <KeyboardDismissingView style={[sheetStyle, style]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Select country')}/>
            <Section>
                <TextInput
                    iconLeft={'ico_search'} 
                    iconRight={'ico_clear'} 
                    placeholder={'Search countries'} 
                    value={searchLocaleQuery}
                    onChangeText={(t: string) => setSearchLocaleQuery(t)}
                    onPressIconRight={() => setSearchLocaleQuery('')}
                />
            </Section>
            <Section style={{flex: 1}}>
                { searchLocaleQuery ? (
                    <ScrollView>
                        <Pressable>
                            <Overline>{StringTable.getString('Search results')}</Overline>
                        </Pressable>
                        { Object.values(locales).filter(locale => locale.name?.toLowerCase().includes(searchLocaleQuery.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name)).map((locale: Locale) => (
                            <LocaleView key={locale.code} onPress={() => resolve && resolve(locale)} locale={locale}/>
                        ))}
                    </ScrollView>
                ) : (
                    <FlatList 
                        data={stableLocales}
                        keyExtractor={item => item.code}
                        ListHeaderComponent={(
                            <View>
                                <Pressable>
                                    <Overline>{StringTable.getString('Common')}</Overline>
                                </Pressable>
                                { Object.values(locales).filter(locale => commonLocales.includes(locale.code)).map((locale: Locale) => (
                                    <LocaleView key={locale.code} selected={locale.code === selectedLocale?.code} onPress={() => resolve && resolve(locale)} locale={locale}/>
                                ))}
                                <Pressable>
                                    <Overline style={{marginTop: theme["spacing-large"]}}>{StringTable.getString('All')}</Overline>
                                </Pressable>
                            </View>
                        )}
                        renderItem={({item}) => (
                            <LocaleView selected={item.code === selectedLocale?.code} onPress={() => resolve && resolve(item)} locale={item}/>
                        )}
                    />
                )}
            </Section>
        </KeyboardDismissingView>
    )
}

function LocaleView(props: any) {

    const {
        onPress,
        locale,
        selected
    } = props;

    const theme = useTheme();

    return(
        <TouchableOpacity onPress={onPress} hitSlop={10} style={{flexDirection: 'row', marginTop: theme["spacing-small"]}}>
            <Subheader style={{marginTop: 2, paddingRight: theme["spacing-small"], borderRightWidth: 1, borderColor: theme["color-ink"]}}>{` ${locale?.flag} `}</Subheader>
            <Paragraph style={{marginLeft: 10}}>{locale.name}</Paragraph>
            <Paragraph style={{marginLeft: 5}}>{locale.dialCode}</Paragraph>
        </TouchableOpacity>
    )
}
