import React from 'react';
import Text from '../helpers/Text';
import useThemeStyle from '../theme/useThemeStyle';

export interface SubheaderProps {
    children?: string,
    style?: any,
    numberOfLines?: number,
    onLayout?: any,
    weight?: 'bold' | 'book'
}

export default function Subheader(props: SubheaderProps) {

    const {
        children,
        style : styleProp,
        numberOfLines,
        onLayout
    } = props;

    const style = useThemeStyle('Subheader', props);

    return(
        <Text numberOfLines={numberOfLines} style={[style, styleProp]} onLayout={onLayout}>
            {children}
        </Text>
    )
}