import React, { useState, useEffect } from 'react';
import { View, Platform } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from '../useSheetStyle';
import NumPad from './NumPad';
import NumericCell from '../../../composites/NumericCell';
import TeamCard from '../../../composites/TeamCard';

export interface ISuperScheduleMatchResult {
    result?: number[],
    cancelled?: boolean,
    e: IPressEvent
}

interface Props {
    userRefs?: (string | null)[];
    initialResult?: (number | null)[];
    points?: boolean;
    style?: any;
    resolve?: (data: ISuperScheduleMatchResult | null ) => void;
}

export default function ResultSheet(props: Props) {

    const {
        userRefs,
        initialResult,
        points,
        resolve,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const backIcon = 'ico_chevronleft';
    const forwardIcon = 'ico_chevronright';
    const clearIcon = 'ico_clear';

    const [result, setResult] = useState<string[]>(resultToStrings(initialResult));
    const [index, setIndex] = useState(-1);

    function resultToStrings(result?: (number | null)[]) {
        const strings : string[] = [];
        result?.forEach((result: number | null, index: number) => strings[index] = result ? `${result}` : '');
        return strings;        
    }

    function stringsToResult(strings: string[]) {
        if (!strings.length) { 
            return [];
        }
        if (strings.length % 2 === 1) {
            strings.push('0');
        }
        return strings.map((str: string) => parseInt(str || '0'));
    }

    function handleNumber(number: number) {
        if (index === -1 && result.length < (points ? 2: 6)) {
            setResult(prev => ([...prev, `${number}`]))
        }
        else if (index > -1 && index < (points ? 2 : 6)) {
            const changedResult = [...result];
            changedResult[index] = `${number}`;
            setResult(changedResult);
        }
    }

    function handleClear() {
        setResult([]);
        setIndex(-1);
    }

    function handleKeyPress(value: number | string | undefined) {
        if (typeof value === 'number') {
            handleNumber(value);
        }
        else if (value === clearIcon) {
            handleClear();
        }
    }

    function handlePressCell(index: number) {
        const changedResult = [...result];
        changedResult[index] = '';
        setResult(changedResult);
        setIndex(index);
    }

    useEffect(() => {
        function keyboardListener(event: KeyboardEvent) {
            const isNumber = /^[0-9]$/i.test(event.key);
            if (isNumber) {
                handleNumber(parseInt(event.key));
            }
            else if (event.keyCode === 8 || event.keyCode === 46) {
                handleClear();
            }
        }
        Platform.OS === 'web' && document?.addEventListener("keydown", keyboardListener);
        return() => {
            Platform.OS === 'web' && document?.removeEventListener("keydown", keyboardListener);
        }

    }, [result]);

    return (
        <View style={[sheetStyle, style]}>
            <View style={{alignItems: 'center'}}>
                <Subheader>{StringTable.getString('Set result')}</Subheader>
            </View>

            <Section>
                <View style={{backgroundColor: theme["color-card"], padding: theme["spacing-medium"], borderRadius: theme["roundness"], flexDirection: 'row'}}>
                    <View style={{flex: 1}}>
                        <TeamCard userRefs={[userRefs?.[0] || StringTable.getString('Unknown'), userRefs?.[1] || StringTable.getString('Unknown')]}/>
                        <TeamCard userRefs={[userRefs?.[2] || StringTable.getString('Unknown'), userRefs?.[3] || StringTable.getString('Unknown')]}/>
                    </View>

                    <View>
                        <View style={{flexDirection: 'row'}}>
                            <NumericCell value={result[0]} focused={index === 0 || (index === -1 && result.length === 0)} extraWide={points} onPress={() => handlePressCell(0)}/>
                            { points ? null : (
                                <>
                                    <NumericCell value={result[2]} focused={index === 2 || (index === -1 && result.length === 2)} onPress={() => handlePressCell(2)}/>
                                    <NumericCell value={result[4]} focused={index === 4 || (index === -1 && result.length === 4)} onPress={() => handlePressCell(4)}/>
                                </>
                            )}
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <NumericCell value={result[1]} focused={index === 1 || (index === -1 && result.length === 1)} extraWide={points} onPress={() => handlePressCell(1)}/>                
                            { points ? null : (
                                <>
                                    <NumericCell value={result[3]} focused={index === 3 || (index === -1 && result.length === 3)} onPress={() => handlePressCell(3)}/>
                                    <NumericCell value={result[5]} focused={index === 5 || (index === -1 && result.length === 5)} onPress={() => handlePressCell(5)}/>
                                </>
                            )}
                        </View>                    
                    </View>
                </View>
            </Section>

            <View style={{flex: 1}}/>

            <View style={{alignItems: 'center'}}>
                <NumPad 
                    style={{width: 4 * 58, height: 5 * 58}}
                    onPress={handleKeyPress}                
                    backIcon={backIcon}
                    forwardIcon={forwardIcon}
                    clearIcon={clearIcon}
                />
            </View>

            <View style={{flex: 1}}/>

            <Section style={{flexDirection: 'row'}}>
                <Button 
                    icon={'ico_clear'} 
                    label={StringTable.getString('Cancel')}
                    onPress={(e: IPressEvent) => resolve && resolve({cancelled: true, e})}    
                    style={{flex: 1, marginRight: theme["spacing-medium"]}}
                />
                <Button 
                    icon={'ico_racket'} 
                    label={StringTable.getString('Set result')}
                    color={theme["color-neutral"]}
                    penColor={theme["color-ink-contained"]}     
                    onPress={(e: IPressEvent) => resolve && resolve({result: stringsToResult(result), e})}    
                    style={{flex: 1, marginLeft: theme["spacing-medium"]}}
                />
            </Section>            
        </View>
    )
}
