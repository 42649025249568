import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import useStyles from '../../hooks/useStyles';
import { useTheme, Paragraph, Section, TextInputNumeric, Button, CheckBox, Divider } from 'components';
import { StringTable } from 'utils';
import { SuperSchedule, Competition, CompetitionGroup } from 'business';
import { IPressEvent } from 'interfaces';
import useSheetStyle from './useSheetStyle';
import CloseHeader from './CloseHeader';

interface Props {
    competitions: Competition[];
    schedule: SuperSchedule;
    roundIndex: number;
    resolve?: (data: { 
        constrainedCompetitions?: string[], 
        constrainedGroups?: {[competitionId: string]: number[]}, 
        prioritizedCompetitions?: string[], 
        prioritizedGroups?: {[competitionId: string]: number[]}, 
        constrainedCourts?: string[], 
        roundLength?: number, 
        roundLengthGlobal: boolean,
        delay?: number 
    } | null) => void;
    style: any;
}

export default function ConstrainScheduleSheet(props : Props) {

    const {
        competitions,
        schedule,
        roundIndex,
        resolve, 
        style
    } = props;

    const styles = useStyles();
    const sheetStyle = useSheetStyle();
    const theme = useTheme();

    const [roundLength, setRoundLength] = useState(schedule.roundLengths?.[roundIndex] || schedule.settings.roundLength || 30);
    const [roundLengthGlobal, setRoundLengthGlobal] = useState(true);

    const [delay, setDelay] = useState(schedule.constraints?.[roundIndex]?.delay);
    const [constrainedCompetitions, setConstrainedCompetitions] = useState(schedule.constraints?.[roundIndex]?.constrainedCompetitions || []);
    const [constrainedCourts, setConstrainedCourts] = useState(schedule.constraints?.[roundIndex]?.constrainedCourts || []);
    const [prioritizedCompetitions, setPrioritizedCompetitions] = useState(schedule.constraints?.[roundIndex]?.prioritizedCompetitions || []);
    const [constrainedGroups, setConstrainedGroups] = useState(schedule.constraints?.[roundIndex]?.constrainedGroups || {});
    const [prioritizedGroups, setPrioritizedGroups] = useState(schedule.constraints?.[roundIndex]?.prioritizedGroups || {});

    return(
        <View style={[sheetStyle, style]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={`${StringTable.getString('Round')} ${roundIndex + 1} ${StringTable.getString('start')} ${schedule.getTimeLabel(roundIndex, delay)}`}/>

            <View style={{flex: 1}}>
                <ScrollView>
                    <Section style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Paragraph>{StringTable.getString('Round length')}</Paragraph>
                        <TextInputNumeric 
                            containerStyle={{width: 60, padding: 2, paddingVertical: 2, margin: 0, marginLeft: 6, justifyContent: 'center'}} 
                            innerStyle={{width: '100%', textAlign: 'center', padding: 0}} 
                            value={roundLength} 
                            onEndEditing={(n: number) => setRoundLength(n)}
                        />
                    </Section>

                    <Section>
                        <CheckBox 
                            checked={roundLengthGlobal} 
                            label={StringTable.getString('Apply on all rounds')}
                            onPress={() => setRoundLengthGlobal(!roundLengthGlobal)}
                            size={'L'}
                        />
                    </Section>

                    <Section>
                        <Divider/>
                    </Section>

                    <Section style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        <Paragraph style={{color: theme["color-negative"]}}>{StringTable.getString('Delay before round')}</Paragraph>
                        <TextInputNumeric 
                            containerStyle={{width: 60, padding: 2, paddingVertical: 2, margin: 0, marginLeft: 6, justifyContent: 'center'}} 
                            innerStyle={{width: '100%', textAlign: 'center', padding: 0, color: theme["color-negative"]}} 
                            value={delay || 0} 
                            onEndEditing={(n: number) => setDelay(n)}
                        />
                    </Section>

                    <Section>
                        <Divider/>
                    </Section>

                    <Section size={'L'}>
                        <Paragraph>{StringTable.getString('Playing this round')}</Paragraph>
                        <Section/>
                        { competitions.map((competition: Competition) => (
                            <View key={competition.id}>
                                <Button 
                                    icon={constrainedCompetitions?.includes(competition.id) ? 'ico_unchecked' : 'ico_checked'} 
                                    label={competition.getTitle()}
                                    style={styles.menuButton}
                                    onPress={() => {
                                        const constrained = [...(constrainedCompetitions || [])];
                                        if (constrained.includes(competition.id)) {
                                            constrained.splice(constrained.indexOf(competition.id), 1);
                                            setConstrainedGroups(prev => ({...prev, [competition.id]: [] }));
                                        }
                                        else {
                                            constrained.push(competition.id);
                                            const constrainedGroups = competition.groups?.map((group: CompetitionGroup, groupIndex: number) => groupIndex) || [];
                                            setConstrainedGroups(prev => ({...prev, [competition.id]: constrainedGroups }));
                                        }
                                        setConstrainedCompetitions(constrained);
                                    }}
                                />
                                {competition.groups?.map((group: CompetitionGroup, groupIndex: number) => (
                                    <Button 
                                        key={groupIndex}
                                        icon={(constrainedCompetitions?.includes(competition.id) || constrainedGroups?.[competition.id]?.includes(groupIndex)) ? 'ico_unchecked' : 'ico_checked'}
                                        label={`${StringTable.getString('Group')} ${groupIndex + 1}`}
                                        style={styles.menuButton}
                                        onPress={() => {
                                            const constrained = [...(constrainedGroups?.[competition.id] || [])];
                                            if (constrained.includes(groupIndex)) {
                                                constrained.splice(constrained.indexOf(groupIndex), 1);
                                            }
                                            else {
                                                constrained.push(groupIndex);
                                            }
                                            setConstrainedGroups(prev => ({...prev, [competition.id]: constrained }));
                                        }}
                                        mode={'plain'}
                                    />
                                )) || null }
                            </View>

                        ))}
                    </Section>

                    <Section>
                        <Divider/>
                    </Section>

                    <Section size={'L'}>
                        <Paragraph>{StringTable.getString('Prioritized this round')}</Paragraph>
                        <Section/>
                        { competitions.map((competition: Competition) => (
                            <View key={competition.id}>
                                <Button 
                                    icon={prioritizedCompetitions?.includes(competition.id) ? 'ico_checked' : 'ico_unchecked'} 
                                    label={competition.getTitle()}
                                    style={styles.menuButton}
                                    onPress={() => {
                                        const prioritized = [...prioritizedCompetitions];
                                        if (prioritized.includes(competition.id)) {
                                            prioritized.splice(prioritized.indexOf(competition.id), 1);
                                            setPrioritizedGroups(prev => ({...prev, [competition.id]: []}));
                                        }
                                        else {
                                            prioritized.push(competition.id);
                                            const prioritizedGroups = competition.groups?.map((group: CompetitionGroup, groupIndex: number) => groupIndex) || [];
                                            setPrioritizedGroups(prev => ({...prev, [competition.id]: prioritizedGroups }));
                                        }
                                        setPrioritizedCompetitions(prioritized);
                                    }}
                                />
                                {competition.groups?.map((group: CompetitionGroup, groupIndex: number) => (
                                    <Button 
                                        key={groupIndex}
                                        icon={(prioritizedGroups?.[competition.id]?.includes(groupIndex)) ? 'ico_checked' : 'ico_unchecked'}
                                        label={`${StringTable.getString('Group')} ${groupIndex + 1}`}
                                        style={styles.menuButton}
                                        onPress={() => {
                                            const prioritized = [...(prioritizedGroups?.[competition.id] || [])];
                                            if (prioritized.includes(groupIndex)) {
                                                prioritized.splice(prioritized.indexOf(groupIndex), 1);
                                            }
                                            else {
                                                prioritized.push(groupIndex);
                                                if (!prioritizedCompetitions.includes(competition.id)) {
                                                    setPrioritizedCompetitions(prev => ([...prev, competition.id]));
                                                }
                                            }
                                            setPrioritizedGroups(prev => ({...prev, [competition.id]: prioritized }));
                                        }}
                                        mode={'plain'}
                                    />
                                )) || null }
                            </View>
                        ))}
                    </Section>

                    <Section>
                        <Divider/>
                    </Section>


                    <Section size={'L'}>
                        <Paragraph>{StringTable.getString('Courts this round')}</Paragraph>
                        <Section/>
                        { schedule.settings.courts.map((court: { id: string, name: string }) => (
                            <Button 
                                key={court.id}
                                icon={constrainedCourts?.includes(court.id) ? 'ico_unchecked' : 'ico_checked'} 
                                label={court.name}
                                style={styles.menuButton}
                                onPress={() => {
                                    const constrained = [...constrainedCourts];
                                    if (constrained.includes(court.id)) {
                                        constrained.splice(constrained.indexOf(court.id), 1);
                                    }
                                    else {
                                        constrained.push(court.id);
                                    }
                                    setConstrainedCourts(constrained);
                                }}
                            />
                        ))}
                    </Section>

                </ScrollView>
            </View>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button 
                    onPress={(e: IPressEvent) => resolve && resolve({
                        constrainedCompetitions, 
                        constrainedGroups, 
                        prioritizedCompetitions, 
                        prioritizedGroups, 
                        constrainedCourts, 
                        roundLength, 
                        roundLengthGlobal,
                        delay,                        
                    })} 
                    mode={'primary'} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={'Set'}
                />
            </Section>            
        </View>
    )
}
