import React, { useCallback } from 'react';
import { View } from 'react-native';
import { useTheme, Paragraph, Caption, Sticker, Icon, Buttonlet } from 'components';
import TeamCard from './TeamCard';
import UserCard from './UserCard';
import { Competition } from 'business';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import { useActions } from '../actions';
import { useStyles } from '../hooks';
import { TableRow } from 'interfaces';

interface SuperScheduleTableViewProps {
    competition: Competition;
    groupIndex: number;
    editable?: boolean;
    style?: any;
    table?: TableRow[] | null;
    color: string;
    selectedPlayers?: string[] | null;
    showPortraits?: boolean;
    onLayout?: any
}

TableView.defaultProps = {
    showPortraits: true
}
export default function TableView(props: SuperScheduleTableViewProps) {

    const {
        competition,
        groupIndex,
        editable,
        style, 
        table,        
        color,
        selectedPlayers,
        showPortraits,
        onLayout
    } = props;

    const { settings, state } = competition;
    const { showUserMenu, swapTeamsInGroup } = useActions();
    const theme = useTheme();
    const styles = useStyles();

    const onPressUser = useCallback(async(e: IPressEvent, userRef: string) => {
        competition && showUserMenu(e, userRef, competition);
    }, [competition, showUserMenu]);

    return(
        <View style={[styles.TableView, style]} onLayout={onLayout}>
            <View>
                <Sticker 
                    icon={'ico_ball'}
                    label={competition.settings.title || competition.settings.type} 
                    startColor={color}
                    endColor={color}
                />
                <View style={{flexDirection: 'row', marginTop: theme["spacing-extrasmall"], alignItems: 'center'}}>
                    <Caption style={{marginRight: theme["spacing-medium"]}}>{`${StringTable.getString('Group')} ${groupIndex + 1}`}</Caption>
                    {editable ? (
                        <Buttonlet
                            icon={'ico_settings'}
                            label={StringTable.getString('Edit')}
                            onPress={(e: any) => swapTeamsInGroup(e, competition.getTeams(groupIndex), competition)}
                        />
                    ) : null }
                </View>
            </View>

            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <View style={{flex: 4}}/>
                <View style={{flex: 2, flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Caption style={{color: theme["color-ink-soft"]}}>{`W`}</Caption>
                    </View>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Caption style={{color: theme["color-ink-soft"]}}>{`D`}</Caption>
                    </View>
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Caption style={{color: theme["color-ink-soft"]}}>{`L`}</Caption>
                    </View>
                    { competition.settings.points ? null : (
                        <View style={{flex: 1, alignItems: 'center'}}>
                            <Caption style={{color: theme["color-ink-soft"]}}>{`±`}</Caption>
                        </View>
                    )}
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Caption>{`P`}</Caption>
                    </View>
                </View>
            </View>

            { table ? table.map((row: TableRow, index: number) => {

                if (settings.fixedTeams && index % 2 === 1) {
                    return null;
                }
                const partnerRow = table[index + 1];

                return (
                    <View 
                        key={row.id}
                        style={{borderRadius: theme["roundness"], backgroundColor: (selectedPlayers?.includes(row.id) || (settings.fixedTeams && selectedPlayers?.includes(partnerRow?.id))) ? theme["color-ink-supersoft"] : undefined}}
                    >
                        <MTableRowView 
                            playerId={row.id}
                            partnerId={settings.fixedTeams ? partnerRow?.id : undefined}
                            onPressUser={onPressUser}
                            wins={row.wins}
                            draws={row.draws}
                            losses={row.losses}
                            score={row.score}
                            rating={state?.ratingChange?.[row.id]}
                            gamediff={competition.settings.points ? undefined : row.gamediff}
                            showPortraits={showPortraits}
                        />
                    </View>
                )
                }) : null }
        </View>
    )
} 

interface MTableRowViewProps {
    playerId: string,
    partnerId?: string,
    onPressUser?: (e: IPressEvent, userRef: string) => Promise<any>
    wins?: number, 
    draws?: number, 
    losses?: number, 
    score?: number,
    rating?: number, 
    gamediff?: number,
    showPortraits?: boolean
}

const MTableRowView = React.memo((props : MTableRowViewProps) => {
    const {
        playerId,
        partnerId,
        onPressUser,
        wins, 
        draws, 
        losses, 
        score,
        rating, 
        gamediff,
        showPortraits
    } = props;

    const theme = useTheme();
    return(
        <View style={{marginTop: theme["spacing-small"], flexDirection: 'row', alignItems: 'center'}}>
            <View style={{flex: 4}}>
                { partnerId ? (
                    <TeamCard 
                        userRefs={[playerId, partnerId]}
                        onPressUser={onPressUser} 
                        style={{marginHorizontal: 0}}
                        showPortraits={showPortraits}
                    >
                        { rating !== undefined ? (
                            <View style={{flexDirection: 'row'}}>
                                <Icon name={'ico_graph'} size={13} color={rating > 0 ? theme["color-positive"] : theme["color-negative"]}/>
                                <Caption style={{color: rating > 0 ? theme["color-positive"] : theme["color-negative"]}}>{`${rating > 0 ? '+' : ''}${Math.round(rating)}`}</Caption>
                            </View>
                        ) : null }
                    </TeamCard>
                ) : (
                    <UserCard 
                        userRef={playerId}
                        onPress={onPressUser} 
                        style={{margin: 0}}
                        showPortrait={showPortraits}
                    >
                        { rating !== undefined ? (
                            <View style={{flexDirection: 'row'}}>
                                <Icon name={'ico_graph'} size={13} color={rating > 0 ? theme["color-positive"] : theme["color-negative"]}/>
                                <Caption style={{color: rating > 0 ? theme["color-positive"] : theme["color-negative"]}}>{`${Math.round(rating)}`}</Caption>
                            </View>
                        ) : null }
                    </UserCard>
                )}
            </View>

            <View style={{flex: 2, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <View style={{flex: 1, alignItems: 'center'}}>
                    <Paragraph style={{color: theme["color-ink-soft"]}} weight={'book'}>{`${wins || 0}`}</Paragraph>
                </View>
                <View style={{flex: 1, alignItems: 'center'}}>
                    <Paragraph style={{color: theme["color-ink-soft"]}} weight={'book'}>{`${draws || 0}`}</Paragraph>
                </View>
                <View style={{flex: 1, alignItems: 'center'}}>
                    <Paragraph style={{color: theme["color-ink-soft"]}} weight={'book'}>{`${losses || 0}`}</Paragraph>
                </View>
                { gamediff !== undefined ? (
                    <View style={{flex: 1, alignItems: 'center'}}>
                        <Paragraph style={{color: theme["color-ink-soft"]}} weight={'book'}>{`${gamediff || 0}`}</Paragraph>
                    </View>
                ) : null }
                <View style={{flex: 1, alignItems: 'center'}}>
                    <Paragraph weight={'bold'}>{`${score || 0}`}</Paragraph>
                </View>
            </View>

        </View>        
    )
});

