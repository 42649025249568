import React, { useMemo } from 'react';
import { View, Pressable } from 'react-native';

import Caption from './typography/Caption';
import useTheme from './theme/useTheme';

type TabHeaderProps = {
    tabs: string[],
    current: number, 
    onPressTab?: (n: number) => void
    style?: any
}

TabHeader.defaultProps = {
    current: 0,
    tabs: ['One', 'Two', 'Three']
}

export default function TabHeader(props: TabHeaderProps) {

    const {
        tabs, 
        current, 
        onPressTab,
        style
    } = props;

    const theme = useTheme();
    if (tabs.length > 8) {
        throw new Error('TabHeader supports no more than 8 tabs');
    }

    const topRow = useMemo(() => tabs?.slice(0, 4), [tabs]);
    const bottomRow = useMemo(() => {
        if (tabs?.length > 4) {
            const t = [];
            for (let i = 0; i < 4; i++) {
                t.push(tabs[4 + i]);
            }
            return t;
        }
        return null;
    }, [tabs]);

    return(
        <View style={style}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                { tabs?.length === 2 ? <View style={{flex: 1}}/> : null }
                { topRow ? topRow.map((tab: string | undefined, index: number) => (
                    <Pressable key={index} style={{flex: 1, alignItems: 'center'}} onPress={() => onPressTab && onPressTab(index)}>
                        <Caption weight={current === index ? 'bold' : 'book'}>{tab}</Caption>
                    </Pressable>
                )) : null }
                { tabs?.length === 2 ? <View style={{flex: 1}}/> : null }
            </View>
            { bottomRow ? (
                <View style={[{flexDirection: 'row', alignItems: 'center', marginTop: theme["spacing-medium"]}]}>
                    { bottomRow.map((tab: string, index: number) => tab ? (
                        <Pressable key={index} style={{flex: 1, alignItems: 'center'}} onPress={() => onPressTab && onPressTab(4 + index)}>
                            <Caption weight={current === (4+index) ? 'bold' : 'book'}>{tab}</Caption>
                        </Pressable>
                    ) : (
                        <View key={index} style={{flex: 1}}/>
                    ))}
                </View>
            ) : null }
        </View>
    )
}