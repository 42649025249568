//@ts-nocheck
import React, { useRef, useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Section, useTheme, Icon, Button, Subheader } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';

interface Props {
    initialReview?: number,
    title: string,
    resolve?: (review: number | null) => void, 
    style?: any
}

export default function ReviewSheet(props : Props) {

    const {
        initialReview,
        title,
        resolve, 
        style
    } = props;

    const [review, setReview] = useState(initialReview);
    const sheetStyle = useSheetStyle();
    const theme = useTheme();

    const score = useRef([1, 2, 3, 4, 5]).current;

    return(
        <View style={[sheetStyle, style]}>
            <Subheader style={{alignSelf: 'center'}}>{title}</Subheader>
            <Section size={'L'} style={{alignItems: 'center'}}>                
                <View style={{flexDirection: 'row'}}>
                    { score.map(s => (
                        <TouchableOpacity key={s} style={{marginRight: theme["spacing-medium"]}} onPress={() => setReview(s)}>
                            <Icon name={review && review >= s ? 'ico_star' : 'ico_staroutline'} size={32} color={theme["color-ink"]}/>
                        </TouchableOpacity>
                    ))}
                </View>
            </Section>

            <Section size={'XL'} style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button onPress={() => resolve(review)} mode={review ? 'primary' : 'passive'} style={{flex: 1, marginLeft: theme["spacing-extrasmall"]}} label={StringTable.getString('Review')}/>
            </Section>
           
        </View>
    )
}
