import React, { useRef, useState, useMemo, ReactElement, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { useLayout, useStyles, useIsAdmin } from '../hooks';
import { useTheme, Section, DropdownMenu, IconButton, TextInputNumeric, Icon, Pressable, Subheader, Sticker, Buttonlet, Paragraph } from 'components';
import { StringTable } from 'utils';
import { SuperSchedule, Competition, CompetitionGroup } from 'business';
import useFilteredSuperScheduleData from '../hooks/useFilteredSuperScheduleData';
import { useActions } from '../actions';
import TableView from './TableView';
import WinnersView from './WinnersView';
import MatchCard from './MatchCard';
import SimpleMatchCard from './SimpleMatchCard';
import { IPressEvent } from 'interfaces';
import { useAppContext } from '../appcontext';
import { IScheduleCell } from 'interfaces';

interface Props {
    schedule: SuperSchedule,
    parentCompetition?: Competition,
    competitions: Competition[],
    ListHeaderComponent?: ReactElement,
    palette: {[competitionId: string]: string}
}
export default function SuperScheduleView(props: Props) {

    const { schedule, parentCompetition, competitions, ListHeaderComponent, palette } = props;
    const { currentUserId } = useAppContext();
    const { layout } = useLayout();
    const theme = useTheme();

    const isAdmin = useIsAdmin(currentUserId);
    const isSingleMatch = competitions.length === 1 && competitions[0].settings.type === 'MATCH' ? true : false;

    const [scrollViewWidth, setScrollViewWidth] = useState(0);
    const cellWidth = layout === 'mobile' ? scrollViewWidth : 400;
    
    const [limitToCompetition, setLimitToCompetition] = useState<Competition | undefined>(competitions && competitions.length === 1 ? competitions[0] : undefined);
    const [limitToCourt, setLimitToCourt] = useState<string>();
    const [limitToGroup, setLimitToGroup] = useState<number>();
    const filteredData = useFilteredSuperScheduleData(schedule, limitToCompetition, limitToCourt, limitToGroup, layout);
    
    useEffect(() => {
        limitToCompetition && console.log(`[Limit to competition]: ${limitToCompetition?.id}`)
    }, [limitToCompetition]);

    useEffect(() => {
        const updatedLimitToCompetition = limitToCompetition && competitions?.find(c => c !== limitToCompetition && c.id === limitToCompetition?.id);
        updatedLimitToCompetition && setLimitToCompetition(updatedLimitToCompetition);
    }, [competitions, limitToCompetition]);

    /*
    If we clear the competition filter, automatically clear the group filter as well
    */
    useEffect(() => {
        if (!limitToCompetition) {
            setLimitToGroup(undefined);
        }
    }, [limitToCompetition]);

   /*
   const isFocused = useIsFocused(); 
   useEffect(() => {
       if (!isFocused) { return }
        if (limitToCompetition && target?.competition !== limitToCompetition) {
            setTarget(prev => ({...prev, parentCompetition: parentCompetition, competition: limitToCompetition}));
        }
        else if (!limitToCompetition) {
            const owner : Competition | undefined = competitions.length === 1 ? competitions[0] : parentCompetition;
            if (target?.competition !== owner) {
                setTarget(prev => ({...prev, parentCompetition: null, competition: owner}));
            }
        }
    }, [isFocused, limitToCompetition, competitions, parentCompetition, target, setTarget]);

    useEffect(() => {
        if (target?.schedule !== schedule) {
            setTarget({...target, schedule})
        }
    }, [target, schedule]);
    */

    return (
        <View style={{flex: 1, margin: theme["spacing-medium"]}}>            
            <ScrollView style={{flex: 1}} contentContainerStyle={{paddingBottom: 40}} showsVerticalScrollIndicator={layout === 'mobile' ? false : true}>

            { parentCompetition && !parentCompetition.state.closed ? (
                    <Sticker 
                        style={{alignSelf: 'flex-end', marginRight: theme["spacing-small"]}}
                        label={parentCompetition.state.started ? StringTable.getString('LIVE') : StringTable.getString('NOT STARTED')}
                        startColor={parentCompetition.state.started ? theme["color-negative"] : theme["color-ink-supersoft"]}
                    />  
                ) : null }

                { ListHeaderComponent }

                <FilterSection
                    competitions={competitions}
                    schedule={schedule}
                    limitToCompetition={limitToCompetition}
                    setLimitToCompetition={setLimitToCompetition}
                    limitToCourt={limitToCourt}
                    setLimitToCourt={setLimitToCourt}
                    limitToGroup={limitToGroup}
                    setLimitToGroup={setLimitToGroup}
                    palette={palette}
                />

                <WinnerSection
                    competitions={competitions}
                    palette={palette}
                    cellWidth={cellWidth}
                />

                <StandingsSection
                    competitions={competitions}
                    limitToCompetition={limitToCompetition}
                    limitToCourt={limitToCourt}
                    limitToGroup={limitToGroup}
                    cellWidth={cellWidth}
                    isSingleMatch={isSingleMatch}
                    isAdmin={isAdmin}
                    palette={palette}
                    closed={parentCompetition?.state.closed}
                />

                <View onLayout={e => !scrollViewWidth && setScrollViewWidth(e.nativeEvent.layout.width)}>
                    {scrollViewWidth ? (
                    <ScheduleSection
                        competitions={competitions}
                        schedule={schedule}
                        filteredData={filteredData}
                        isAdmin={isAdmin}
                        isSingleMatch={isSingleMatch}
                        cellWidth={cellWidth}
                        palette={palette}
                        layout={layout}
                    /> ) : null }
                </View>

            </ScrollView>
        </View>
    )
}

interface FilterProps {
    competitions: Competition[],
    limitToCompetition?: Competition,
    setLimitToCompetition: (c: Competition | undefined) => void,
    limitToCourt?: string,
    setLimitToCourt: (c: string | undefined) => void,
    limitToGroup?: number,
    setLimitToGroup: (g: number | undefined) => void,
    schedule: SuperSchedule,
    palette: {[competitionId: string]: string}
}

function FilterSection(props: FilterProps) {

    const { competitions, limitToCompetition, setLimitToCompetition, limitToCourt, setLimitToCourt, limitToGroup, setLimitToGroup, schedule } = props;

    const styles = useStyles();


    const competitionNames : { [id: string]: string } = useMemo(() => {
        return competitions.reduce((acc, curr) => ({...acc, [curr.id]: curr.settings.title || curr.settings.type}), {all: StringTable.getString('All competitions')});
    }, [competitions]);

    const courtNames : { [id: string]: string } = useMemo(() => {
        return schedule.settings.courts.reduce((acc, curr) => ({...acc, [curr.id]: curr.name}), { all: StringTable.getString('All courts')});
    }, [schedule.settings.courts]);

    const groupNames : { [index: string]: string } = useMemo(() => {
        const groups = limitToCompetition?.groups;
        return (groups || []).reduce((acc, curr, index) => ({...acc, [`${index}`]: `${StringTable.getString('Group')} ${index + 1}`}), { all: StringTable.getString('All groups')});
    }, [limitToCompetition]);

    return competitions.length > 1 || (competitions[0].groups?.length || 1) > 1 ? (
        <Section style={{flexDirection: 'row', zIndex: 2}}>
            {Object.keys(competitionNames).length > 2 ? <DropdownMenu options={competitionNames} selectedValue={limitToCompetition?.id || 'all'} style={styles.dropdownButton} onSelectValue={(id: string) => setLimitToCompetition(id === 'all' ? undefined : competitions.find(c => c.id === id))}/> : null }
            {limitToCompetition && Object.keys(groupNames).length > 1 ? (
                <DropdownMenu options={groupNames} selectedValue={limitToGroup === undefined ? 'all' : `${limitToGroup}`} style={styles.dropdownButton} onSelectValue={(id: string) => setLimitToGroup(id === 'all' ? undefined : parseInt(id))}/>
            ) : (
                <DropdownMenu options={courtNames} selectedValue={limitToCourt === undefined ? 'all' : limitToCourt} style={styles.dropdownButton} onSelectValue={(id: string) => setLimitToCourt(id === 'all' ? undefined : id)}/>
            )}
        </Section>
    ) : null;
}

interface WinnerProps {
    competitions: Competition[], 
    cellWidth: number, 
    palette: {[competitionId: string]: string}
}
function WinnerSection(props: WinnerProps) {

    const { competitions, cellWidth, palette } = props;
    const { showUserMenu } = useActions();

    return cellWidth && competitions.find(c => c.state.closed) ? (
        <Section style={{width: cellWidth}}>
            <Subheader>{StringTable.getString('Winners')}</Subheader>
            { competitions.find((c: Competition) => c.state.closed) ? competitions.filter((c: Competition) => c.state.closed).map((competition: Competition) => (
                    <WinnersView
                        key={competition.id}
                        competition={competition}
                        color={palette[competition.id]}
                        onPressUser={(e: IPressEvent, userRef: string) => competition && showUserMenu(e, userRef, competition)}
                    />
            )) : null }
        </Section>
    ) : null;
}


interface StandingsProps {
    competitions: Competition[], 
    limitToCompetition?: Competition, 
    limitToCourt?: string, 
    limitToGroup?: number,
    cellWidth: number, 
    isSingleMatch?: boolean,
    isAdmin?: boolean,
    palette: {[competitionId: string]: string}, 
    closed?: boolean
}

function StandingsSection(props: StandingsProps) {

    const { cellWidth, limitToCourt, limitToGroup, isSingleMatch, isAdmin, competitions, limitToCompetition, palette, closed } = props;
    const styles = useStyles();
    const { layout } = useLayout();

    const [tableHeights, setTableHeights] = useState<{[columnIndex: number]: number}>({});

    const scrollViewRef = useRef<any>();
    useEffect(() => {
        setTimeout(() => {
            scrollViewRef.current?.scrollTo({x: 0, y: 0, animated: false});
        }, 0);
    }, []);

    const [currentColumn, setCurrentColumn] = useState(0);    
    function onScroll(e: any) {
        const offset = e.nativeEvent.contentOffset.x;
        setCurrentColumn(Math.round(offset / cellWidth));
    }

    const groups = useMemo(() => {
        const groups : { competition: Competition, group: CompetitionGroup, groupIndex: number}[] = [];
        competitions.filter((c: Competition) => !limitToCompetition || c.id === limitToCompetition.id).forEach((competition: Competition) => {
            competition.groups?.forEach((group: CompetitionGroup, groupIndex: number) => {
                if (limitToGroup === undefined || groupIndex === limitToGroup) {
                    groups.push({ competition, group, groupIndex });
                }
            });
        })
        return groups;
    }, [competitions, limitToCompetition, limitToGroup]);

    return cellWidth && limitToCourt === undefined && !isSingleMatch ? (
        <Section>
            <View style={layout === 'mobile' ? {alignItems: 'center'} : {}}>
                <Subheader>{StringTable.getString(closed ? 'Results' : 'Standings')}</Subheader>
                {layout === 'mobile' ? (
                    <View style={styles.row}>
                        { groups?.map((group, index) => (
                            <Pressable 
                                key={`${group.competition.id}${group.groupIndex}`}
                                style={[styles.pageIndicator, currentColumn === index ? styles.pageIndicatorCurrent : {}]}
                                onPress={() => scrollViewRef?.current?.scrollTo({x: index * cellWidth, y: 0, animated: true})}
                            />
                        ))}
                    </View>
                ) : null }
            </View>

            <ScrollView
                horizontal={true}
                pagingEnabled={true}
                onScroll={onScroll}
                scrollEventThrottle={64}
                ref={scrollViewRef}
                showsHorizontalScrollIndicator={layout === 'mobile' ? false : true}
                style={{height: (layout === 'mobile' && tableHeights[currentColumn]) ? (tableHeights[currentColumn] + 6) : undefined}}
            >
                {groups.map((g, index: number) => (
                    <View 
                        key={`${g.competition.id}${g.groupIndex}`}
                        style={{width: cellWidth}}
                    >
                        <TableView 
                            onLayout={(e: any) => {
                                const height = e.nativeEvent?.layout?.height;
                                if (height) {
                                    setTableHeights(prev => ({...prev, [index]: height}));
                                }
                            }}
                            competition={g.competition}
                            groupIndex={g.groupIndex}
                            editable={isAdmin && ((g.competition.groups?.length || 0) > 1) && !g.competition.state.started ? true : false}
                            color={palette[g.competition.id]}
                            table={g.group.table}
                            showPortraits={cellWidth < 320 ? false : true}                            
                        />
                    </View>
                ))}
            </ScrollView>
        </Section>
    ) : null;
}

interface TimerProps {
    layout: string, 
    cellWidth: number, 
    defaultMinutes: number
}

interface ScheduleProps {
    schedule: SuperSchedule;
    competitions: Competition[];
    filteredData: IScheduleCell[][] | null;
    isAdmin: boolean;
    isSingleMatch: boolean;
    cellWidth: number, 
    palette: {[competitionId: string]: string},
    layout: string
}

const ScheduleSection = React.memo((props : ScheduleProps) => {

    const { schedule, competitions, palette, isSingleMatch, isAdmin, filteredData, cellWidth, layout } = props;

    const { simpleRendering } = useAppContext();
    const { constrainSchedule } = useActions();
    const theme = useTheme();

    const MatchRenderer = simpleRendering ? SimpleMatchCard : MatchCard;

    const scrollViewRef = useRef<any>();
    useEffect(() => {
        setTimeout(() => {
            scrollViewRef.current?.scrollTo({x: 0, y: 0, animated: false});
        }, 0);
    }, []);

    function onPreviousRound() {
        scrollViewRef.current?.scrollTo({x: (currentColumn - 1) * cellWidth, y: 0, animated: true})
    }
    function onNextRound() {
        scrollViewRef.current?.scrollTo({x: (currentColumn + 1) * cellWidth, y: 0, animated: true})
    }

    const [currentColumn, setCurrentColumn] = useState(0);
    function onScroll(e: any) {
        if (layout === 'mobile') {
            const offset = e.nativeEvent.contentOffset.x; 
            setCurrentColumn(Math.round(offset/cellWidth));
        }
    }

    //On desktop, where we can afford the re-render, measure cell height and set all cells to be max height
    const [cellHeight, setCellHeight] = useState<number>(0);
    function measureCell(e: any) {
        const height = e.nativeEvent.layout.height;
        setCellHeight((prev: number) => prev && prev > height ? prev : height);
    }

    const styles = useStyles();

    return (
        <Section size={'L'}>
            <View style={layout === 'mobile' ? {alignItems: 'center'} : {}}>
                { isSingleMatch ? null : (
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Subheader>{StringTable.getString('Schedule')}</Subheader> 
                    </View>
                )}
                { layout === 'mobile' && (filteredData?.length || 1) > 1 ? (
                    <View style={styles.row}>
                        { filteredData?.map((column: IScheduleCell[], columnIndex: number) => (
                            <Pressable 
                                key={columnIndex}
                                style={[styles.pageIndicator, currentColumn === columnIndex ? styles.pageIndicatorCurrent : {}]}
                                onPress={() => scrollViewRef?.current?.scrollTo({x: columnIndex * cellWidth, y: 0, animated: true})}
                            />
                        ))}
                    </View>
                ) : null }
            </View>

            <ScrollView
                ref={scrollViewRef}
                horizontal={true}
                pagingEnabled={true}
                onScroll={onScroll}
                scrollEventThrottle={50}
                style={layout === 'desktop' ? undefined : { width: cellWidth }}
                showsHorizontalScrollIndicator={layout === 'mobile' ? false : true}
            >
                {filteredData?.map((column: IScheduleCell[], columnIndex: number) => {
                    if (layout === 'mobile' && Math.abs(columnIndex - currentColumn) > 1) {
                        //Culled
                        return (
                            <View style={{ width: cellWidth }} key={columnIndex} />
                        )
                    }
                    //Rendered
                    return (
                        <View style={{ flexDirection: 'row' }} key={columnIndex}>
                            {isAdmin && layout === 'desktop' && column[0]?.delay ? (
                                <View>
                                    <Section size={'S'} style={{height: 20, alignItems: 'center'}}>
                                    </Section>
                                    <DelayView
                                        delay={column[0]?.delay}
                                        onChangeDelay={(n: number) => schedule.setConstraints(columnIndex, { delay: n })}
                                        style={{padding: theme["spacing-small"]}}                                        
                                    />
                                </View>
                            ) : null }
                            <View style={{ width: cellWidth }}>
                                <Section size={'S'} style={{height: 20}}>
                                    <RoundHeaderView
                                        canAddDelay={isAdmin && layout === 'desktop' && !column[0].delay ? true : false}
                                        onAddDelay={() => schedule.setConstraints(column[0].roundIndex, { delay: 15 })}
                                        canEditConstraints={isAdmin && competitions?.[0]?.settings.type !== 'MATCH'}
                                        onEditConstraints={(e: IPressEvent) => constrainSchedule(e, schedule, competitions, column[0].roundIndex)}
                                        label={schedule.getTimeLabel(column[0].roundIndex)}
                                    />
                                </Section>
                                <RoundView
                                    round={column}
                                    competitions={competitions}
                                    schedule={schedule}
                                    palette={palette}
                                    MatchRenderer={MatchRenderer}
                                    measureCell={measureCell}
                                    minCellHeight={cellHeight}
                                    showPortraits={cellWidth < 320 ? false : true}
                                />
                                <RoundFooterView
                                    columnIndex={columnIndex}
                                    numColumns={filteredData?.length || 0}
                                    layout={layout}
                                    onPressPrevious={onPreviousRound}
                                    onPressNext={onNextRound}
                                />
                            </View>
                        </View>
                    )
                })}
            </ScrollView>

        </Section>
    )
});

function DelayView(props: any) {
    const { delay, onChangeDelay, style } = props;

    return(
        <View style={style}>
            <TextInputNumeric 
                containerStyle={{width: 60, padding: 2, justifyContent: 'center', margin: 0}} 
                innerStyle={{width: '100%', textAlign: 'center', padding: 0}} 
                value={delay} 
                onEndEditing={onChangeDelay}
            />
        </View>
    )
}

function RoundHeaderView(props: any) {

    const { canAddDelay, onAddDelay, canEditConstraints, onEditConstraints, label } = props;
    const theme = useTheme();

    return(
        <View style={{flexDirection: 'row', justifyContent: 'center'}}>
            { canAddDelay ? (
                <Buttonlet
                    style={{position: 'absolute', left: theme["spacing-medium"], top: theme["spacing-small"]}}
                    icon={'ico_add'}
                    label={StringTable.getString('Delay')}
                    onPress={onAddDelay}
                />
            ) : null}

            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Icon name={'ico_time'} size={18} color={theme["color-ink"]}/>
                <Subheader style={{marginLeft: theme["spacing-extrasmall"]}}>{label}</Subheader>
            </View>

            { canEditConstraints ? (
                <Buttonlet
                    style={{position: 'absolute', right: theme["spacing-medium"], top: theme["spacing-small"]}}
                    icon={'ico_settings'}
                    label={StringTable.getString('Edit')}
                    onPress={onEditConstraints}
                />
            ) : null }
        </View>
    )
}

interface RoundViewProps {
    round: IScheduleCell[], 
    competitions: Competition[], 
    schedule: SuperSchedule, 
    palette: {[competitionId: string]: string}, 
    MatchRenderer: any,
    minCellHeight: number,
    measureCell: (e: any) => any,
    showPortraits?: boolean
}
function RoundView(props: RoundViewProps) {

    const { round, competitions, schedule, palette, MatchRenderer, measureCell, minCellHeight, showPortraits } = props;
    const styles = useStyles();

    return(
        <>
            {round.map((cell: IScheduleCell, index: number) => (
                <View style={[styles.scheduleCell, { minHeight: minCellHeight }]} key={index} onLayout={measureCell}>
                    {cell.type === 'match' ? (
                        <MatchRenderer
                            competition={competitions.find(c => c.id === cell.competitionId)}
                            match={competitions.find(c => c.id === cell.competitionId)?.getMatch(cell.id)}
                            court={schedule.settings.courts.find(c => c.id === cell.courtId)}
                            schedule={schedule}
                            palette={palette}
                            showCompetitionName={competitions.length > 1}
                            showCourtName={(schedule?.settings.courts.length || 0) > 1}
                            showPortraits={showPortraits}
                        />
                    ) : null}
                </View>
            ))}
        </>
    )
}

function RoundFooterView(props: any) {
    const { columnIndex, numColumns, layout, onPressPrevious, onPressNext } = props;
    const styles = useStyles();
    
    return (
        <View>
            { layout === 'mobile' ? (
                <View style={[styles.row, { justifyContent: 'space-between', alignItems: 'flex-end'}]}>
                    {columnIndex > 0 ? <IconButton mode={'plain'} name={'ico_chevronleft'} onPress={onPressPrevious}/> : <View/>}
                    {columnIndex < (numColumns - 1) ? <IconButton mode={'plain'} name={'ico_chevronright'} onPress={onPressNext}/> : <View/>}
                </View>
            ) : null }
        </View>

    )
}











