import { Match } from './Match';
import { IUserState, Trophy, IMatch } from '../interfaces';
export class UserState implements IUserState {

    registered?: boolean;
    rating: number;
    matches?: number;
    trophies?: Trophy[];
    matchHistory?: Match[];

    constructor(data: IUserState) {
        this.registered = data.registered;
        this.rating = data.rating || 1000;
        this.matches = data.matches;
        this.trophies = data.trophies;
        this.matchHistory = data.matchHistory?.map((matchData: IMatch) => new Match(matchData));
    }

    serialize() : IUserState {
        return {
            registered: this.registered,
            rating: this.rating, 
            matches: this.matches,
            trophies: this.trophies,
            matchHistory: this.matchHistory?.map(m => m.serialize())
        }
    }

    setRegistered(flag: boolean) {
        this.registered = flag;
    }

    modifyRating(ratingModifier?: number) {
        if (ratingModifier) {
            this.rating = this.rating + ratingModifier;
        }
    }

    setRating(rating: number) {
        this.rating = rating;
    }

    updateMatchHistory(match: Match) {
        this.matchHistory = this.matchHistory || [];
        const index = this.matchHistory.findIndex(m => m.id === match.id);

        if (match.result?.length) {
            if (index === -1) {
                this.matchHistory.push(match);
            }
            else {
                this.matchHistory[index] = match;
            }
        }
        else {
            if (index >= 0) {
                this.matchHistory.splice(index, 1);
            }
        }
        this.matchHistory = this.matchHistory.slice(-100);        
    }

    filterMatchHistory(matchRefs: string[]) {
        this.matchHistory = (this.matchHistory || []).filter(m => !matchRefs.includes(m.id));
    }

    addTrophy(trophy: Trophy) {
        this.trophies = this.trophies || [];
        if (!this.trophies?.find(t => t.competitionId === trophy.competitionId)) {
            this.trophies?.push(trophy);
        }
    }

    removeTrophy(trophy: Trophy) {
        const index = this.trophies ? this.trophies.findIndex(t => t.competitionId === trophy.competitionId) : -1;
        if (index >= 0) {
            this.trophies?.splice(index, 1);
        }
    }

    filterTrophies(competitionRef: string) {
        this.trophies = this.trophies?.filter((t: Trophy) => t.competitionId !== competitionRef);
    }
}
