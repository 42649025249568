//@ts-nocheck
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { NavigationContainer, NavigationState, getStateFromPath, useIsFocused } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Constants from 'expo-constants'
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { StringTable, navigateUrl } from 'utils';
import { Icon, Overline, useTheme, Pressable, Badge } from 'components';
import { HomeScreen, CompetitionScreen, GroupsScreen, GroupScreen, DiscoverScreen, ConversationScreen, ConversationsScreen, ClubsScreen, ClubScreen, LandingScreen, LoginScreen, OnboardingScreen, SupportScreen, TermsScreen, PrivacyScreen, AdminScreen } from '../screens';
import Animated from 'react-native-reanimated';
import { DeviceType } from 'expo-device';
import { useNotificationCounter } from '../hooks';
import { ActionsProvider, ModalProvider } from '../actions';
import { NotificationView } from '../composites';
import { useAppContext } from '../appcontext';
import RootNavigatorContext from './RootNavigatorContext';

export interface RouteProps {
    route: any, 
    navigation: any
}

const Tabs = createBottomTabNavigator();
const HomeStack = createStackNavigator();
const ConversationsStack = createStackNavigator();
const DiscoverStack = createStackNavigator();
const GroupsStack = createStackNavigator();
const ClubsStack = createStackNavigator();
const prefix = Linking.createURL('/');

export default function RootNavigator(props : any) {
    
    const { currentUser, deviceType } = useAppContext();
    const [notificationView, setNotificationView] = useState<ReactElement | null>(null);
    const [hideTabBar, setHideTabBar] = useState<boolean>(false);

    const linking = useMemo(() => ({
        prefixes: [prefix, 'https://prank-dev-150de.web.app/', 'https://staging.padel.id', 'https://padel.id'],
        config: {
            screens: {
                HomeStack: {
                    screens: {
                        Home: 'home',
                        Competition: 'home/:competitionId',
                        Conversation: 'home/conversation/:conversationId'
                    }
                },
                GroupsStack: {
                    screens: {
                        Groups: 'groups',
                        Group: 'groups/:groupId',
                        Competition: 'groups/competition/:groupId/:competitionId',
                        Conversation: 'groups/conversation/:groupId/:conversationId',
                    }
                },
                ConversationsStack: {
                    screens: {
                        Conversations: 'conversations', 
                        Conversation: 'conversations/:conversationId',
                    }
                }, 
                DiscoverStack: {
                    screens: {
                        Discover: 'discover', 
                        Competition: 'discover/:competitionId',
                        Conversation: 'discover/conversation/:conversationId',
                        Group: 'discover/group/:groupId',
                        Club: 'discover/club/:clubId'
                    }
                },
                ClubsStack: {
                    screens: {
                        Clubs: 'clubs',
                        Club: 'clubs/:clubId',
                        Competition: 'clubs/competition/:clubId/:competitionId',
                        Group: 'clubs/group/:clubId/:groupId',
                        Conversation: 'clubs/conversation/:/clubId/:conversationId'
                    }
                },
                UserStack: {
                    screens: {
                        User: 'user/:id?', 
                        Competition: 'user/conversation/:competitionId'
                    }
                },
                Home: '',
                Settings: 'settings',
                Admin: 'admin',
                Support: 'support',
                Terms: 'terms',
                Privacy: 'privacy'
            }
        },
        getStateFromPath: (path, options) => {
            const pathWithoutQuery = path.split('?')[0];
            return getStateFromPath(pathWithoutQuery, options);            
        }        
    }), [currentUser]);
    
    /**
     * Handle navigation from deep links and notifications
     * https://negativeepsilon.com/en/posts/expo-deep-linking/
     */
    const navigationRef = useRef();
    const url = Linking.useURL();
    useEffect(() => {
        navigateUrl(navigationRef.current, url);
    }, [url]);

    const lastNotificationResponse = Notifications.useLastNotificationResponse();
    useEffect(() => {
        const url = lastNotificationResponse?.notification?.request?.content?.data?.url;
        navigateUrl(navigationRef.current, url);
    }, [lastNotificationResponse]);


    /**
     * Track the current conversation ID, if we are currently in a conversation. We don't 
     * want foreground push notifications from the conversation we're in.
     */
    const currentConversationId = useRef<string>();
    function onNavigationStateChange(state: NavigationState | undefined) {
        if (state) {
            const route = getCurrentRoute(state);
            currentConversationId.current = route?.params?.conversationId;
        }
    }
    function getCurrentRoute(state: any) {
        if (!state) {
            return undefined;
        }
        else if (state.index === undefined || state.index < 0) {
        return undefined;
        }
        const nestedState = state.routes[state.index].state;
        if (nestedState !== undefined) {
            return getCurrentRoute(nestedState);
        }
        return state.routes[state.index];
    };

    /**
     * Hook to handle push notifications while foregrounded
     */
    React.useEffect(() => {
        let notificationSubscription;

        if (Platform.OS !== 'web') {

            Notifications.setNotificationHandler({
                handleNotification: async () => ({
                    shouldShowAlert: false,
                    shouldPlaySound: false,
                    shouldSetBadge: false,
                })
            });

            notificationSubscription = Notifications.addNotificationReceivedListener(notification => {
                if (notification && notification.request?.content?.data?.url?.endsWith(`conversations/${currentConversationId.current}`)) {
                    //Ignore notification
                }
                else if (notification) {
                    setNotificationView(
                        <NotificationView notification={notification} onDismiss={() => setNotificationView(null)}/>
                    )
                }        
            });
        }
        return () => {
            if (Platform.OS !== 'web') {
                notificationSubscription.remove();
            }
        }
    }, []);
    
    //Because of https://github.com/react-native-modal/react-native-modal#i-cant-show-multiple-modals-at-the-same-time we need to nest our modals
    return(
        <RootNavigatorContext.Provider value={{setNotificationView, setHideTabBar}}>
            <View style={{flex: 1}}>
                <NavigationContainer ref={navigationRef} linking={linking} onStateChange={onNavigationStateChange}>
                    <ModalProvider>
                        <ActionsProvider>
                            <OnboardingScreen/>
                            { notificationView ? (
                                <View style={{zIndex: 2, elevation: 2, position: 'absolute', top: Constants.statusBarHeight, left: 0, right: 0, flexDirection: 'row-reverse'}}>
                                    <View style={{flex: 3}}>
                                        <Animated.View>
                                            { notificationView || null }
                                        </Animated.View>
                                    </View>
                                    { deviceType === DeviceType.DESKTOP ? (
                                        <View style={{flex: 1}}/>
                                    ) : null }
                                </View>
                            ) : null }
                            <Tabs.Navigator 
                                backBehavior={'history'}
                                tabBar={({state, descriptors, navigation}) => (
                                    <PortraitTabBar state={state} descriptors={descriptors} navigation={navigation} hideTabBar={(deviceType === DeviceType.DESKTOP || deviceType === DeviceType.TABLET || Platform.OS === 'web') ? true : hideTabBar}/>
                                )}
                                screenOptions={({ route }) => ({
                                    headerShown: false,
                                    animationEnabled: false
                                })}
                            >
                                { currentUser?.state.registered ? (
                                    <>
                                        <Tabs.Screen name={'HomeStack'} component={HomeStackScreen} options={{tabBarLabel: StringTable.getString("Home"), icon: "ico_home"}}/>
                                        <Tabs.Screen name={'GroupsStack'} component={GroupsStackScreen} options={{tabBarLabel: StringTable.getString("Groups"), icon: "ico_groupround"}}/>
                                        <Tabs.Screen name={'DiscoverStack'} component={DiscoverStackScreen} options={{tabBarLabel: StringTable.getString("Discover"), icon: "ico_searchheavy"}}/>
                                        <Tabs.Screen name={'ConversationsStack'} component={ConversationsStackScreen} options={{tabBarLabel: StringTable.getString("Chats"), icon: "ico_chatround"}}/>
                                        <Tabs.Screen name={'ClubsStack'} component={ClubsStackScreen} options={{tabBarLabel: StringTable.getString("Clubs"), icon: "ico_location"}}/>
                                        <Tabs.Screen name={'Terms'} component={TermsScreen}/>
                                        <Tabs.Screen name={'Privacy'} component={PrivacyScreen}/>
                                        <Tabs.Screen name={'Support'} component={SupportScreen}/>
                                        <Tabs.Screen name={'Admin'} component={currentUser?.id === '+46123123456' ? AdminScreen : HomeScreen}/>

                                    </>
                                ) : (
                                    <>
                                        <Tabs.Screen name={'HomeStack'} component={HomeStackScreen}/>
                                        <Tabs.Screen name={'GroupsStack'} component={GroupsStackScreen}/>
                                        <Tabs.Screen name={'Terms'} component={TermsScreen}/>
                                        <Tabs.Screen name={'Privacy'} component={PrivacyScreen}/>
                                        <Tabs.Screen name={'Support'} component={SupportScreen}/>
                                    </>
                                )}
                            </Tabs.Navigator>
                        </ActionsProvider>                        
                    </ModalProvider>
                </NavigationContainer>
            </View>
        </RootNavigatorContext.Provider>
    )
}

function HomeStackScreen(props) {

    const { currentUser } = useAppContext();

    const navigationRef = useRef();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (!isFocused) {
            navigationRef.current?.reset({
                index: 0,
                routes: [{name: 'Home'}],
            });            
        }
    }, [isFocused]);


    return(
        <HomeStack.Navigator 
            screenOptions={{
                header: ({ navigation, route, options, back }) => {
                    navigationRef.current = navigation;
                    return null;
                },
                animationEnabled: false
            }}
        >
            <HomeStack.Screen name={'Home'} component={currentUser ? HomeScreen : (Platform.OS === 'web' ? LandingScreen : LoginScreen)}/>
            <HomeStack.Screen name={'Competition'} component={CompetitionScreen}/>
            <HomeStack.Screen name={'Conversation'} component={currentUser ? ConversationScreen : (Platform.OS === 'web' ? LandingScreen : LoginScreen)}/>
        </HomeStack.Navigator>
    )
}


function GroupsStackScreen(props) {

    const { currentUser } = useAppContext();   
    
    const navigationRef = useRef();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (!isFocused) {
            navigationRef.current?.reset({
                index: 0,
                routes: [{name: 'Groups'}],
            });            
        }
    }, [isFocused]);

    return(
        <GroupsStack.Navigator
            screenOptions={{
                header: ({ navigation, route, options, back }) => {
                    navigationRef.current = navigation;
                    return null;
                },
                animationEnabled: false
            }}
        >
            <GroupsStack.Screen name={'Groups'} component={currentUser ? GroupsScreen : (Platform.OS === 'web' ? LandingScreen : LoginScreen)}/>
            <GroupsStack.Screen name={'Group'} component={GroupScreen}/>
            <GroupsStack.Screen name={'Competition'} component={CompetitionScreen}/>
            <GroupsStack.Screen name={'Conversation'} component={currentUser ? ConversationScreen : (Platform.OS === 'web' ? LandingScreen : LoginScreen)}/>
        </GroupsStack.Navigator>
    )
}

function ConversationsStackScreen(props) {

    const navigationRef = useRef();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (!isFocused) {
            navigationRef.current?.reset({
                index: 0,
                routes: [{name: 'Conversations'}],
            });            
        }
    }, [isFocused]);

    return(
        <ConversationsStack.Navigator 
            screenOptions={{
                header: ({ navigation, route, options, back }) => {
                    navigationRef.current = navigation;
                    return null;
                },
                animationEnabled: false
            }}
        >
            <ConversationsStack.Screen name={'Conversations'} component={ConversationsScreen}/>
            <ConversationsStack.Screen name={'Conversation'} component={ConversationScreen}/>
            <ConversationsStack.Screen name={'Admin'} component={AdminScreen}/>
        </ConversationsStack.Navigator>
    )
}

function DiscoverStackScreen(props) {

    const navigationRef = useRef();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (!isFocused) {
            navigationRef.current?.reset({
                index: 0,
                routes: [{name: 'Discover'}],
            });            
        }
    }, [isFocused]);

    return(
        <DiscoverStack.Navigator 
            screenOptions={{
                header: ({ navigation, route, options, back }) => {
                    navigationRef.current = navigation;
                    return null;
                },
                animationEnabled: false
            }}
        >
            <DiscoverStack.Screen name={'Discover'} component={DiscoverScreen}/>
            <DiscoverStack.Screen name={'Competition'} component={CompetitionScreen}/>
            <DiscoverStack.Screen name={'Conversation'} component={ConversationScreen}/>
            <DiscoverStack.Screen name={'Group'} component={GroupScreen}/>
            <DiscoverStack.Screen name={'Club'} component={ClubScreen}/>
        </DiscoverStack.Navigator>
    )
}

function ClubsStackScreen(props) {

    const navigationRef = useRef();
    const isFocused = useIsFocused();

    useEffect(() => {
        if (!isFocused) {
            navigationRef.current?.reset({
                index: 0,
                routes: [{name: 'Clubs'}],
            });            
        }
    }, [isFocused]);

    return(
        <ClubsStack.Navigator
            screenOptions={{
                header: ({ navigation, route, options, back }) => {
                    navigationRef.current = navigation;
                    return null;
                },
                animationEnabled: false
            }}
        >
            <ClubsStack.Screen name={'Clubs'} component={ClubsScreen}/>
            <ClubsStack.Screen name={'Club'} component={ClubScreen}/>
            <ClubsStack.Screen name={'Competition'} component={CompetitionScreen}/>
            <ClubsStack.Screen name={'Group'} component={GroupScreen}/>
            <ClubsStack.Screen name={'Conversation'} component={ConversationScreen}/>
        </ClubsStack.Navigator>
    )
}

function PortraitTabBar(props: any) {

    const {
        state, 
        descriptors, 
        navigation,
        hideTabBar
    } = props;
    
    const theme = useTheme(); 
    const { currentUser } = useAppContext();
    const conversationsNotificationCounter = useNotificationCounter(currentUser?.id, 'conversations');

    if (hideTabBar) {
        //If the tabBar returns null or zero height, the screens get undefined height and scrollviews don't work
        return (
            <View style={{height: 1}}/>
        );
    }

    return (
        <View style={{paddingBottom: Platform.OS === 'android' ? 0 : 21, paddingTop: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', borderTopWidth: 1, borderColor: theme["color-ink-supersoft"], backgroundColor: theme["color-card"]}}>
            { state.routes.map((route: any, index: number) => {

                const { options } = descriptors[route.key];
                const label = options.tabBarLabel || options.title || route.name;

                const icon = options.icon;
                const isFocused = state.index === index;

                return icon ? (
                    <React.Fragment key={index}>
                        <TouchableOpacity
                            style={{alignItems: 'center'}} 
                            onPress={() => {
                                if (!isFocused) {
                                    navigation.navigate({ name: route.name, merge: true });
                                }
                            }}                        
                        >
                            <Icon name={icon} color={isFocused ? theme["color-primary"] : theme["color-ink-soft"]} size={24}/>
                            <Overline style={{textTransform: null, marginTop: 2, color: isFocused ? theme["color-primary"] : theme["color-ink-soft"]}}>{label}</Overline>
                            { index === 3 ? (
                                <View style={{position: 'absolute', top: 0, right: 0}}>
                                    <Badge count={Object.keys(conversationsNotificationCounter || {}).filter(key => conversationsNotificationCounter[key] > 0).length}/>
                                </View>
                            ) : null }
                        </TouchableOpacity>
                    </React.Fragment>
                ) : null;
            })}
        </View>
    )
}

