import { useState, useEffect } from 'react';
import { AppState, AppStateStatus, Platform } from 'react-native';
import * as Updates from 'expo-updates';

export default function useCheckForUpdates(): string | null {

    const [status, setStatus] = useState<string | null>(null);
    useEffect(() => {
        const handleAppStateChange = async (nextAppState: AppStateStatus) => {
            if (nextAppState === 'active') {
                try {
                    const update = await Updates.checkForUpdateAsync();
                    if (update.isAvailable) {
                        setStatus('downloading update...');
                        await Updates.fetchUpdateAsync();
                        setStatus('installing update...');
                        await Updates.reloadAsync();
                        setStatus(null);
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        };

        if (Platform.OS === 'web') {
            return;
        }
        const subscription = AppState.addEventListener('change', handleAppStateChange);
        return () => {
            subscription.remove();
        };
    }, []);

    return status;
};
