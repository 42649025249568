import { Identity } from "../interfaces";

export class UserIdentity {

    id: string;
    title?: string;
    pName?: string;
    imageUrl?: string;
    rating: number;
    matches: number;
    localeCode: string;
    registered: boolean;

    constructor(id: string, data: Identity) {
        this.id = id;
        this.title = data.t;
        this.pName = data.pt;
        this.imageUrl = data.p;
        this.matches = data.m || 0;
        this.rating = data.r || 1000;
        this.localeCode = data.l || 'en-GB';
        this.registered = data.t ? true : false;
    }

    getTitle() {
        return this.title || this.pName || this.id;
    }  
    
    getOnlyTitle() {
        return this.title;
    }

    getOnlyProvisionalName() {
        return this.pName;
    }

    hasNumber() {
        return this.id && (this.pName || this.title) ? true : false;
    }
}
