import { useEffect } from 'react';
import { useNavigation, CommonActions } from "@react-navigation/native";

export default function useGoBackIfNull(...refs: any[]) {

    const navigation = useNavigation();

    useEffect(() => {
        if (refs.some((ref) => ref === null)) {
            //Go back
            if (navigation.canGoBack()) {
                navigation.goBack();
            }
            else {
                navigation.dispatch(CommonActions.reset({ index: 0, routes: [{ name: navigation.getState().routeNames[0] }] }));
            }
        }
    }, [...refs, navigation]);
}
