import React from 'react';
import { View, Text } from 'react-native';
import { getFirestore, addDoc, collection } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import * as Application from 'expo-application';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        return { hasError: true };
    }
  
    componentDidUpdate(prevProps) {
        if (this.props.error && !prevProps.error) {

            console.error(this.props.error);
    
            /*
            const writeError = { message: this.props.error.message || 'no message' }
            const firestore = getFirestore();     
            addDoc(collection(firestore, 'errors'), {
                date: new Date(), 
                error: writeError
            });
            */
        }
    }

    componentDidCatch(error, errorInfo) {
        
        const auth = getAuth();
        /*
        if (process?.env?.NODE_ENV === 'development') {
            return;
        }
        */

        let writeError;
        if (error.name && error.name === 'FirebaseError') {
            writeError = {
                message: error.message, 
                name: error.name,
                stack: error.stack || null
            }
        }
        else {
            writeError = {
                message: error.message
            }
        }

        const version = Application.nativeApplicationVersion;

        const firestore = getFirestore();     
        addDoc(collection(firestore, 'errors'), {
            userId: auth.currentUser.phoneNumber,
            date: new Date(), 
            version: version,
            error: writeError
        });
    }

    render() {
      if (this.state.hasError) {
          return(
              <View style={{flex: 1, alignItems: 'center', justifyContent: 'center', padding: 12}}>
                  <Text>{'Something went wrong... Your issue has been reported.'}</Text>
                  <Text>{JSON.stringify(this.props.error, null, 4)}</Text>
              </View>
          )
      }
  
      return this.props.children; 
    }
  }