import React from 'react';
import { View } from 'react-native';
import Text from '../helpers/Text';
import useTheme from '../theme/useTheme';
import useThemeStyle from '../theme/useThemeStyle';

interface CalendarDigitProps {
    style?: any,
    value: number | string,
    selected?: boolean,
    primaryMark?: number, 
    secondaryMark?: number,
    compact?: boolean,
    pad?: boolean
}

export default function CalendarDigit(props: CalendarDigitProps) {

    const {
        style : styleProp,
        value, 
        selected,
        primaryMark, 
        secondaryMark,
        pad
    } = props;

    const theme = useTheme();
    const style = useThemeStyle('CalendarDigit', props);
    //@ts-ignore
    const { fontSize, fontFamily, color, ...containerStyle } = style || {};
    
    const textStyle = { fontSize, fontFamily, color };

    return(
        <View style={[containerStyle, {alignItems: 'center', justifyContent: 'center'}]}>
            <Text style={textStyle}>{pad ? String(value).padStart(2, '0') : value}</Text>
            { (primaryMark || secondaryMark) ? (
                <View style={{position: 'absolute', marginTop: -5, overflow: 'visible', top: 0, right: -4, flexDirection: 'row'}}>
                    { primaryMark ? (
                        <View style={{width: 14, height: 14, borderRadius: 7, backgroundColor: theme["color-positive"], justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{fontSize: 10, color: theme["color-ink-contained"]}}>{primaryMark}</Text>
                        </View>
                    ) : null }
                    { secondaryMark ? (
                        <View style={{marginLeft: primaryMark ? 1 : undefined, width: 14, height: 14, borderRadius: 7, backgroundColor: theme["color-neutral"], justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{fontSize: 10, color: theme["color-ink-contained"]}}>{secondaryMark}</Text>
                        </View>
                    ) : null }
                </View>
            ) : null }
        </View>
    )
}
