import React, { ReactElement, useEffect, useRef } from 'react';
import { View, Pressable } from 'react-native';
import { useTheme, Sticker, Paragraph } from 'components';
import { Match, Competition, SuperSchedule } from 'business';
import { StringTable } from 'utils';
import NumericCell from './NumericCell';
import { useStyles, useIsAdmin, useUsers } from '../hooks';
import { useActions } from '../actions';
import { useAppContext } from '../appcontext';

interface Props {
    competition?: Competition, 
    match?: Match,
    court?: { id: string, name: string }
    schedule?: SuperSchedule,
    editable?: boolean,
    palette?: {[competitionId: string]: string},
    showCompetitionName?: boolean,
    showCourtName?: boolean,
    children?: ReactElement,
    style?: any
}

export default function MatchCard(props: Props) {

    const {
        competition,
        match,
        court,
        schedule,
        editable : editableProp,
        palette,
        showCompetitionName,
        showCourtName,
        children,
        style
    } = props;
    
    const styles = useStyles();
    const theme = useTheme();

    const competitionRef = useRef<Competition | undefined>(competition);
    useEffect(() => {
        competitionRef.current = competition;
    }, [competition]);

    const { setMatchResult, showUserMenu, requireMatchCourt } = useActions();
    const { currentUserId } = useAppContext();
    const isAdmin = useIsAdmin(currentUserId);

    const editable = editableProp !== undefined ? editableProp : (competition?.state.started && !competition?.state.closed) && (isAdmin || match?.userRefs?.includes(currentUserId || '')) ? true : false;

    const userRefs = match?.userRefs || [];
    const users = useUsers(userRefs);

    return competition && match ? (
        <View style={[styles.SimpleMatchCard, style]}>
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <View style={{flex: 1, maxWidth: '50%', marginRight: theme["spacing-medium"]}}>
                    { showCompetitionName ? <Sticker style={{maxWidth: '100%'}} icon={'ico_ball'} label={competition.settings.title || competition.settings.type} startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/> : null}
                </View>
                {(court && showCourtName) ? ( 
                    isAdmin ? (
                        <Pressable onPress={(e: any) => requireMatchCourt(e, competition, match.id, schedule)}>
                            <Sticker style={{marginLeft: theme["spacing-medium"]}} icon={'ico_court'} label={court.name} startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/>
                        </Pressable>
                    ) : (
                        <Sticker style={{marginLeft: theme["spacing-medium"]}} icon={'ico_court'} label={court.name} startColor={palette?.[competition.id] || theme["color-competition-1"]} endColor={palette?.[competition.id] || theme["color-competition-1"]}/>
                    )
                ) : null }
            </View>

            <View style={{flexDirection: 'row', marginTop: theme["spacing-small"]}}>
                <View style={{flex: 1, justifyContent: 'space-between'}}>
                    <View style={{marginVertical: theme["spacing-small"]}}>
                        <Pressable onPress={(e: any) => userRefs[0] && competitionRef.current && showUserMenu(e, userRefs[0], competitionRef.current, match?.id)}>
                            <Paragraph>{userRefs[0] ? (users[userRefs[0]]?.getTitle() || userRefs[0]) : StringTable.getString('Player 1')}</Paragraph>
                        </Pressable>
                        <Pressable onPress={(e: any) => userRefs[1] && competitionRef.current && showUserMenu(e, userRefs[1], competitionRef.current, match?.id)}>
                            <Paragraph>{userRefs[1] ? (users[userRefs[1]]?.getTitle() || userRefs[1]) : StringTable.getString('Player 2')}</Paragraph>
                        </Pressable>
                    </View>
                    <View style={{marginVertical: theme["spacing-small"]}}>
                        <Pressable onPress={(e: any) => userRefs[2] && competitionRef.current && showUserMenu(e, userRefs[2], competitionRef.current, match?.id)}>
                            <Paragraph>{userRefs[2] ? (users[userRefs[2]]?.getTitle() || userRefs[2]) : StringTable.getString('Player 3')}</Paragraph>
                        </Pressable>
                        <Pressable onPress={(e: any) => userRefs[3] && competitionRef.current && showUserMenu(e, userRefs[3], competitionRef.current, match?.id)}>
                            <Paragraph>{userRefs[3] ? (users[userRefs[3]]?.getTitle() || userRefs[3]) : StringTable.getString('Player 4')}</Paragraph>
                        </Pressable>
                    </View>
                </View>
                <View style={{justifyContent: 'space-between'}}>
                    <Pressable onPress={(e: any) => editable && setMatchResult(e, competitionRef.current, match?.id, schedule)}>
                        <View style={{flexDirection: 'row'}}>
                            <NumericCell value={match?.result?.length ? match?.result?.[0] : ' '} extraWide={match.points}/>
                            {match.points ? null : (
                                <>
                                    <NumericCell value={match?.result?.length ? match?.result?.[2] : ' '}/>
                                    <NumericCell value={match?.result?.length ? match?.result?.[4] : ' '}/>
                                </>
                            )}
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <NumericCell value={match?.result?.length ? match?.result?.[1] : ' '} extraWide={match.points}/>
                            { match.points ? null : (
                                <>
                                    <NumericCell value={match?.result?.length ? match?.result?.[3] : ' '}/>
                                    <NumericCell value={match?.result?.length ? match?.result?.[5] : ' '}/>
                                </>
                            )}
                        </View>
                    </Pressable>
                </View>
            </View>
        </View>
    ) : null;
}

