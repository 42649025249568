import { useCallback } from 'react';
import { validatePhoneNumber } from 'tools';
import { getFunctions, httpsCallable } from "firebase/functions";
import { Auth, signInWithCustomToken } from 'firebase/auth';

export default function useLoginImplementation(auth: Auth) {

    const login = useCallback(async(phoneNumber : string, pinCode : string) => {       
        const verifyPinCode = httpsCallable(getFunctions(), 'verifyPinCode');
        const validNumber = validatePhoneNumber(phoneNumber);
        if (!validNumber) {
            throw new Error('Invalid phone number');
        }
        if (!pinCode) {
            throw new Error('Invalid pin code');
        }
        const response : any = await verifyPinCode({phoneNumber, pinCode});
        if (response?.data && !response?.data.error) {
            const jwt = response.data;
            if (!jwt) {
                throw new Error('No custom token');
            } 

            try {
                await signInWithCustomToken(auth, jwt);
            }
            catch(err : any) {
                throw new Error(`Error, ${err.code} - ${err.message}`);
            }
        }
        else {
            throw new Error(`Error, ${response?.data?.message}`);
        }
    }, [auth]);

    return login;
}
