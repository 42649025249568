//@ts-nocheck
import { useState, useEffect, useRef } from 'react';
import { arraysOverlap } from 'tools';
import IdentityCache from './IdentityCache';
import { UserIdentity } from 'business';

export default function useUsers(userRefs?: (string | null)[], once?: boolean, onlyKeys?: string[]) : {[key: string]: UserIdentity} {
    
    const [users, setUsers] = useState<{[key: string]: UserIdentity}>({}); 

    const stateless = useRef<{[key: string]: UserIdentity}>({});
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        }
    }, []);

    const hasUpdated = useRef(false);

    function onPlayerChange(userRef: string, identity: Identity, changedKeys?: string[]) {   

        if (onlyKeys && changedKeys && !arraysOverlap(onlyKeys, changedKeys)) {
            return;
        }
        
        const p : UserIdentity = new UserIdentity(userRef, identity || {});
        stateless.current[p.id] = p;
        
        if (userRefs && !userRefs.find(u => !stateless.current[u]) && !(once && hasUpdated.current)) {
            mounted.current && setUsers({...stateless.current});
            hasUpdated.current = true;
        }
    }

    useEffect(() => {

        hasUpdated.current = false;
        
        if (userRefs?.length) {
            userRefs.forEach((userRef: string) => {
                userRef && IdentityCache.listen(userRef, (identity, changedKeys) => onPlayerChange(userRef, identity, changedKeys));
            })
        }
        else {
            mounted.current && setUsers({});
        }

        return () => {
            if (userRefs) {
                userRefs.forEach((userRef: string) => {
                    userRef && IdentityCache.stop(userRef, onPlayerChange);
                })
                stateless.current = {};
            }
        }
    }, [userRefs?.reduce((acc, curr) => acc + curr, '') || '', userRefs?.length]);

    return users;
}
