import { useState, useEffect } from 'react';
import { IdentityCache } from 'bridge';
import { UserIdentity } from 'business';

type UserIdentityMap = { [userRef: string]: UserIdentity | null | undefined };

export default function useUsersOnce(userRefs?: (string | null)[]): UserIdentityMap | undefined {
    const [identities, setIdentities] = useState<UserIdentityMap>();

    useEffect(() => {
        async function fetchUsers(userRefs: (string | null)[]) {
            const promises = userRefs.map(userRef => IdentityCache.get(userRef));
            const userIdentities : (UserIdentity | undefined | null)[] = await Promise.all(promises);

            setIdentities(userRefs.reduce((acc, curr, index) => {
                return curr ? {...acc, [curr]: userIdentities[index] } : acc;
            }, {}));
        }
        userRefs && fetchUsers(userRefs);
    }, [userRefs]);

    return identities;
}
