import { View, Image } from 'react-native';
import { Paragraph } from 'components';

interface Props {
    status?: string
}
export default function LoaderScreen(props: Props) {

    const { status } = props;
    return(
        <View style={{flex: 1}}>
            <Image style={{width: '100%', height: '100%'}} source={require('../../../assets/splash.png')}/>
            { status ? (
                <View style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'flex-end', alignItems: 'center'}}>
                    <Paragraph style={{marginBottom: 50}}>{status}</Paragraph>
                </View>
            ) : null }
        </View>
    )
}
