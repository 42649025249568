import React from 'react';
import { useTheme, Section, SectionHeader, TextInput } from 'components';
import { StringTable } from 'utils';

interface Props {
    competitionSettings: ICompetitionSettings,
    setCompetitionSettings: any
}

export default function CourtNameInput(props: Props) {

    const { competitionSettings, setCompetitionSettings } = props;
    const theme = useTheme();

    return(
        <Section>
            <SectionHeader icon={'ico_court'} label={StringTable.getString('COURT NAMES')}/>
            <Section size={'M'}>
            { Object.values(competitionSettings.courts).sort((a: ICourt, b: ICourt) => a.courtId - b.courtId).map((court, index) => (
                <TextInput
                    key={court.courtId}
                    value={court.courtName || ''}
                    iconLeft={'ico_draghandle'}
                    style={{marginTop: index > 0 ? theme["spacing-extrasmall"] : 0}}
                    onChangeText={(n: string) => {
                        setCompetitionSettings((prev: ICompetitionSettings) => {

                            const data : ICompetitionSettings = {...prev};
                            data.courts = {
                                ...data.courts, 
                                [court.courtId]: {
                                    ...data.courts[court.courtId], 
                                    courtName: n
                                }
                            }
                            return data;
                        });
                    }}
                />
            ))}
            </Section>
        </Section>
    )
}
