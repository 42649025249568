import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useTheme } from 'components';
import NumPadButton from './NumPadButton';
import { StringTable } from 'utils';

interface Props {
    style?: any,
    onPress: (value: number | string | undefined) => void;
    backIcon: string, 
    forwardIcon: string,
    clearIcon: string,
    children?: any
}

NumPad.defaultProps = {
    canPressDirections: ['right']
}

export default function NumPad(props: Props) {

    const { style, onPress, children, backIcon, forwardIcon, clearIcon } = props;
    const theme = useTheme();

    const [keyboardIndex, setKeyboardIndex] = useState(0);
    const keyboardSize = 13;

    const buttons : (number | string | undefined)[][] = [
        [0, 1, 2, undefined],
        [3, 4, 5, 6],
        [7, 8, 9, 10],
        [keyboardIndex === 0 ? undefined : backIcon, 11, 12, keyboardIndex < 9 ? forwardIcon : undefined],
    ];
    buttons.forEach(row => row.forEach((number, index) => { 
        if (typeof number === 'number') {
            row[index] = number + keyboardIndex * keyboardSize; 
        }
    }));

    function handleOnPress(value: number | string | undefined) {
        if (value === backIcon) {
            setKeyboardIndex(keyboardIndex > 0 ? keyboardIndex - 1 : 0);
        }
        else if (value === forwardIcon) {
            setKeyboardIndex(keyboardIndex < 6 ? keyboardIndex + 1 : keyboardIndex);
        }
        else {
            onPress(value);
        }
    }
    return (
        <View style={[style, styles.container]}>
            <View style={[styles.row, {justifyContent: 'flex-end'}]}>
                <NumPadButton 
                    value={StringTable.getString('Clear')} 
                    onPress={() => handleOnPress(clearIcon)} 
                    asIcon={false} 
                    color={theme["color-neutral"]}
                    penColor={theme["color-ink-contained"]}
                    style={{minWidth: 96}}
                />
            </View>
            {buttons.map((row, rowIndex) => (
                <View key={rowIndex} style={styles.row}>
                    {row.map((value : number | string | undefined, index : number) => (
                        <NumPadButton 
                            key={index} 
                            value={value} 
                            onPress={() => handleOnPress(value)} 
                            asIcon={typeof value === 'string'}
                            color={typeof value === 'string' ? theme["color-neutral"] : undefined}
                            penColor={typeof value === 'string' ? theme["color-ink-contained"] : undefined}
                        />
                    ))}
                </View>
            ))}
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});
