import React from 'react';
import { View } from 'react-native';
import { Pressable, Paragraph, Icon, Caption, useTheme } from 'components';
import { useStyles } from '../hooks';

interface Props {
    verbose?: boolean,
    isAdmin?: boolean,
    registered?: boolean,
    title: string,
    rating?: number,
    onPress?: any,
    children?: any
}

export default function PlayerName(props: Props) {

    const { 
        verbose, 
        registered, 
        isAdmin, 
        title, 
        rating, 
        onPress, 
        children 
    } = props;

    const theme = useTheme();
    const styles = useStyles();

    function render() {
        return (
            <>
                <Paragraph numberOfLines={1} weight={'book'} style={{flex: 1}}>
                    {title || ' '}
                    { isAdmin ? (
                        <>
                            {' '}
                            <Icon name={'ico_settings'} size={13} color={theme["color-blue"]}/>
                        </>
                     ) : null }
                    { registered ? (
                        <>
                            {isAdmin ? '' : ' '}
                            <Icon name={'ico_checkedcircle'} size={13} color={theme["color-blue"]}/>
                        </>
                     ) : null }
                </Paragraph>

                { verbose && rating ? (
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Icon name={'ico_graph'} size={12} color={theme["color-ink-soft"]}/>
                        <Caption style={{color: theme["color-ink-soft"]}}>{`${Math.round(rating || 1000)}`}</Caption>
                    </View>
                ) : children }
            </>
        )
    }
    return onPress ? (
        <Pressable style={[styles.PlayerName, {flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', maxWidth: '100%'}]} onPress={onPress}>
            {render()}
        </Pressable>
    ) : (
        <View style={[styles.PlayerName, {flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', maxWidth: '100%'}]}>
            {render()}
        </View>
    )
}