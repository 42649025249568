//@ts-nocheck
import React, { useState, useEffect, useMemo } from 'react';
import { View, ScrollView, TextInput, ActivityIndicator } from 'react-native';
import { useTheme, Button, Paragraph, RadioButtons } from 'components';
import { useSystemDocument } from 'bridge';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import Constants from 'expo-constants'

const __STAGE__ = __DEV__ || Constants.appOwnership === 'expo' || window?.location?.href?.includes('localhost') || window?.location?.href?.includes('192') || window?.location?.href?.includes('prank-dev') ? 'dev' : (window?.location?.href?.includes('staging') ? 'staging' : 'prod');

interface Props {
    style?: any
}
export default function StringsList(props: Props) {

    const {
        style
    } = props

    const theme = useTheme();
    const stringTableDoc = useSystemDocument('system/stringtable');
    const [language, setLanguage] = useState('sv');
    const [stringTable, setStringTable] = useState();
    useEffect(() => {
        setStringTable(stringTableDoc);
    }, [stringTableDoc]);

    const strings = useMemo(() => {
        return Object.keys(stringTableDoc?.en || {}).sort();
    }, [stringTableDoc]);

    const [saving, setSaving] = useState(false);
    async function save() {
        setSaving(true);
        console.log('[setDoc]: StringsList')
        await setDoc(doc(getFirestore(), 'system/stringtable'), stringTable);
        setSaving(false);
    }

    return (
        <View style={[style, {flex: 1}]}>
            <View style={{flexDirection: 'row'}}>
                <RadioButtons selected={language} options={['sv', 'pt']} onSelect={(option: string) => setLanguage(option)}/>
                <View style={{flex: 1, marginLeft: 12}}>
                    <Button icon={'ico_check'} label={'Save'} onPress={save}/>
                    { saving ? <ActivityIndicator/> : null }
                    <Paragraph style={{margin: 10, color: __STAGE__ === 'prod' ? 'red' : theme["color-ink"]}}>{`Edits will be saved to ${__STAGE__}. Transfer upstream from the Documents tab.`}</Paragraph>
                </View>
            </View>
            <ScrollView style={{flex: 1}}>
                { strings.map(string => (
                    <View style={{marginTop: 8}} key={string}>
                        <Paragraph>{string}</Paragraph>
                        <TranslationInput
                            value={stringTable?.[language]?.[string]}
                            onBlur={(text: string) => {
                                setStringTable(prev => {
                                    return {
                                        ...prev, 
                                        [language]: {
                                            ...prev[language], 
                                            [string]: text
                                        }
                                    }
                                });
                            }}
                        />
                    </View>
                ))}
            </ScrollView>
        </View>
    )
}

interface TranslationInputProps {
    value: string, 
    onEndEditing: (str: string) => any
}
function TranslationInput(props: TranslationInputProps) {

    const { value, onBlur } = props;

    const [translation, setTranslation] = useState('');
    useEffect(() => {
        setTranslation(value || '');
    }, [value]);

    return(
        <TextInput
            style={{flex: 1, padding: 10, backgroundColor: 'white'}}
            value={translation}
            onChangeText={(text: string) => setTranslation(text)}
            onBlur={() => onBlur(translation)}
        />
    )
}