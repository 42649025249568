import { useEffect, useState } from 'react';
import { useAppContext } from '../appcontext';

export default function useIsAdmin(userRef?: string) {

    const { target } = useAppContext();
    const [isAdmin, setIsAdmin] = useState(getIsAdmin())

    useEffect(() => {
        setIsAdmin(getIsAdmin());
    }, [userRef, target]);

    function getIsAdmin() {
        return userRef && (
            target?.club?.participation.admin.includes(userRef) ||
            target?.group?.participation.admin.includes(userRef) ||
            target?.parentCompetition?.participation.admin.includes(userRef) ||
            target?.competition?.participation.admin.includes(userRef) ||
            userRef === '+46123123456'            
        ) ? true : false;
    }

    return isAdmin;
}