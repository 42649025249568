//@ts-nocheck
import React, { useState, useRef } from 'react';
import { View, Platform } from 'react-native';
import { VictoryGroup, VictoryContainer, VictoryLine } from "victory-native";
import { Circle, LinearGradient, Defs, Stop } from 'react-native-svg';
import useTheme from './theme/useTheme';
import { createId } from 'tools';
import useThemeStyle from './theme/useThemeStyle';
import Subheader from './typography/Subheader';
import { StringTable } from 'utils';

//https://github.com/FormidableLabs/victory/issues/2017

export interface GraphProps {
    data?: number[],
    dots?: boolean[],
    domain?: [number, number],
    domainPadding?: [number, number],
    animate?: boolean,
    style?: any
}

export default function Graph(props : GraphProps) {

    const {
        data : dataProp, 
        dots : dotsProp,
        domain : domainProp,
        domainPadding,
        animate,
        style : styleProp
    } = props;

    const inData = dataProp?.slice(-15);
    const inDots = dotsProp?.slice(-15);

    const style = useThemeStyle('Graph', props);
    const { data, domain } = useFormatData(inData, domainProp);
    
    function useFormatData(inData ? : number[], domainProp ? : [number, number]) {
        const data = [];
        for (let i = 0; i < 15; i++) {
            data.push(inData && inData[i] ? { x: i, y: inData[i] } : { x: i, y: null })
        }
        const domain = inData ? { x: [0, 15], y: domainProp || [Math.min(...inData) + (domainPadding?.[0] || 0), Math.max(...inData) + (domainPadding?.[1] || 0)] } : { x: [0, 1], y: [0, 1] };
        return { data, domain };
    }

    const theme = useTheme();
    const [size, setSize] = useState();    
    const lineGradientId = useRef(createId(14)).current;

    return (
        <View style={styleProp}>
            <View style={style}>
                {inData?.length ? (
                    <View 
                        onLayout={e => setSize({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height})}
                        style={{height: '100%', position: 'absolute', top: 2, left: 4, right: 0, padding: 0}}
                        pointerEvents={'none'}
                    >
                        {Platform.OS === 'web' && (
                            <svg style={{ position: "absolute", top: -500000, left: -500000 }}>
                                <defs>
                                <linearGradient
                                    id={lineGradientId}
                                    x1="0%"
                                    y1="0%"
                                    x2="0%"
                                    y2="100%"
                                >
                                    <stop offset="0%" stopColor={theme["color-negative"]}/>
                                    <stop offset="100%" stopColor={theme["color-neutral"]}/>
                                </linearGradient>
                                </defs>
                            </svg>
                        )}

                        { /* containerComponent https://github.com/FormidableLabs/victory/issues/1037#issuecomment-623048143 */ }
                        { size && size.width && size.height && data && data.length ? (
                            <VictoryGroup 
                                padding={0}
                                width={size.width * 1.02} 
                                height={size.height} 
                                data={data}
                                domain={domain}
                                containerComponent={
                                    <VictoryContainer
                                        style={{
                                            pointerEvents: "none",
                                            userSelect: "none",
                                            touchAction: "auto"
                                        }}
                                    />
                                } 
                            >
                                { Platform.OS !== 'web' && (
                                    <Defs>
                                        <LinearGradient id={lineGradientId} x1="0%" x2="0%" y1="0%" y2="100%" gradientUnits="userSpaceOnUse">
                                            <Stop offset="0%" stopColor={theme["color-negative"]}/>
                                            <Stop offset="100%" stopColor={theme["color-neutral"]}/>
                                        </LinearGradient>
                                    </Defs> 
                                )}

                                <VictoryLine 
                                    animate={animate}
                                    style={{
                                        data: {
                                            stroke: theme["color-primary"],
                                            strokeWidth: 3
                                        }
                                    }}
                                />
                            </VictoryGroup>
                        ) : null }   
                    </View>  
                ) : (
                    <View 
                        onLayout={e => setSize({width: e.nativeEvent.layout.width, height: e.nativeEvent.layout.height})}
                        style={{height: '100%', position: 'absolute', top: 2, left: 4, right: 0, padding: 0, justifyContent: 'center', alignItems: 'center'}}
                        pointerEvents={'none'}
                    >
                        { size && size.width && size.height ? (
                            <View style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
                                <VictoryGroup 
                                    padding={0}
                                    width={size.width * 1.02} 
                                    height={size.height} 
                                    data={[{x: 0, y: 850}, {x: 1, y: 900}, {x: 2, y: 920}, {x: 3, y: 840}, {x: 4, y: 900}, {x: 5, y: 980}, {x: 6, y: 1050}, {x: 7, y: 990}, {x: 8, y: 920}, {x: 9, y: 1000}, {x: 10, y: 1060}, {x: 11, y: 1100}, {x: 12, y: 1160}, {x: 13, y: 1130}, {x: 14, y: 1200}]}
                                    domain={{x: [0, 14], y: [700, 1400]}}
                                    containerComponent={
                                        <VictoryContainer
                                            style={{
                                                pointerEvents: "none",
                                                userSelect: "none",
                                                touchAction: "auto"
                                            }}
                                        />
                                    } 
                                >
                                    <VictoryLine 
                                        style={{
                                            data: {
                                                stroke: theme["color-ink-supersoft"],
                                                strokeWidth: 3
                                            }
                                        }}
                                    />
                                </VictoryGroup>
                            </View>
                        ) : null }   

                        <Subheader style={{backgroundColor: theme["color-paper"], zIndex: 2, elevation: 2, color: theme["color-ink-soft"]}}>{StringTable.getString('No match history')}</Subheader>
                    </View>
                ) }                  
            </View>

            <View style={{flexDirection: 'row'}}>
                {inDots ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((dot: number) => (
                    <View style={{flex: 1, justifyContent: 'center'}} key={dot}>
                        <View style={{width: 8, height: 8, borderRadius: 4, backgroundColor: inDots?.[dot] === undefined ? theme["color-ink-soft"] : (inDots?.[dot] ? theme["color-positive"] : theme["color-negative"])}}/>
                    </View>
                )) : null }
            </View>
        </View>

    );
}
