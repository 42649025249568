import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { VictoryChart, VictoryLine, VictoryAxis } from 'victory-native';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { arrayRollingAverage } from 'tools';

interface Props {
    app?: any, 
    style?: any
}
export default function UsageGraphs(props: Props) {
    const { app, style } = props;

    const [rollingAverage, setRollingAverage] = useState(7);
    const [dailyUniqueVisitors, setDailyUniqueVisitors] = useState<Array<{x: number, y: number}>>([]);
    const [dailyUniqueEvents, setDailyUniqueEvents] = useState<Array<{x: number, y: number}>>([]);
    const [dailyUniqueCreators, setDailyUniqueCreators] = useState<Array<{x: number, y: number}>>([]);
    const [dailyMatches, setDailyMatches] = useState<Array<{x: number, y: number}>>([]);

    //Reverse the graphs, because in the system/stats document new days are unshifted into the
    //beginning of the arrays and we want today to be the rightmost point...
    useEffect(() => {
        getDoc(doc(getFirestore(app), 'system/stats')).then(doc => {
            if (doc.data()) {
                setDailyUniqueVisitors(arrayRollingAverage(doc.data()?.dailyUniqueVisitors?.reverse(), rollingAverage).map((v: number, i: number) => ({x: i, y: v})) || []);
                setDailyUniqueEvents(arrayRollingAverage(doc.data()?.dailyUniqueEvents?.reverse(), rollingAverage).map((v: number, i: number) => ({x: i, y: v})) || []);
                setDailyUniqueCreators(arrayRollingAverage(doc.data()?.dailyUniqueCreators?.reverse(), rollingAverage).map((v: number, i: number) => ({x: i, y: v})) || []);
                setDailyMatches(arrayRollingAverage(doc.data()?.dailyMatches?.reverse(), rollingAverage).map((v: number, i: number) => ({x: i, y: v})) || []);
            } else {
                console.log("Document does not exist");
            }
        });
    }, [rollingAverage]);

    function generateMonthTickValuesAndLabels(totalDays: number) {
        const today = new Date();
        const tickValues = [];
        const tickLabels : {[key: number]: string}= {};

        for (let i = totalDays - 1; i >= 0; i--) {
            const currentDate = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
            const currentMonth = currentDate.getMonth();
            const previousMonth = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000).getMonth();

            if (currentMonth !== previousMonth) {
                tickValues.push(totalDays - i);
                tickLabels[totalDays - i] = currentDate.toLocaleString('default', { month: 'short' });
            }
        }
        return { tickValues, tickLabels };
    }

    const totalDays = dailyUniqueVisitors.length;
    const { tickValues, tickLabels } = generateMonthTickValuesAndLabels(totalDays);
    
      
    return(
        <View style={[style, {flexDirection: 'row'}]}>
            <View style={{flex: 1}}>
                <VictoryChart padding={{left: 40, bottom: 40, top: 20, right: 20}}>
                    <VictoryLine data={dailyUniqueVisitors}/>
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(x) => tickLabels[x] || ''}
                        style={{ tickLabels: { fontSize: 9, padding: 5 } }}
                        label={'Month'}
                    />

                    <VictoryAxis
                        dependentAxis
                        tickValues={[0, 15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180]}
                        tickFormat={(y) => y}
                        style={{tickLabels: { fontSize: 7, padding: 5}}}
                        label={'Daily unique vistors'}
                    />            
                </VictoryChart>

                <VictoryChart padding={{left: 40, bottom: 40, top: 20, right: 20}}>
                    <VictoryLine data={dailyUniqueEvents}/>
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(x) => tickLabels[x] || ''}
                        style={{ tickLabels: { fontSize: 9, padding: 5 } }}
                        label={'Month'}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                        tickFormat={(y) => y}
                        style={{tickLabels: { fontSize: 7, padding: 5}}}
                        label={'Daily events'}
                    />            
                </VictoryChart>

            </View>

            <View style={{flex: 1}}>
                <VictoryChart padding={{left: 40, bottom: 40, top: 20, right: 20}}>
                    <VictoryLine data={dailyUniqueCreators}/>
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(x) => tickLabels[x] || ''}
                        style={{ tickLabels: { fontSize: 9, padding: 5 } }}
                        label={'Month'}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                        tickFormat={(y) => y}
                        style={{tickLabels: { fontSize: 7, padding: 5}}}
                        label={'Daily unique creators'}
                    />
                </VictoryChart>

                <VictoryChart padding={{left: 40, bottom: 40, top: 20, right: 20}}>
                    <VictoryLine data={dailyMatches}/>
                    <VictoryAxis
                        tickValues={tickValues}
                        tickFormat={(x) => tickLabels[x] || ''}
                        style={{ tickLabels: { fontSize: 9, padding: 5 } }}
                        label={'Month'}
                    />
                    <VictoryAxis
                        dependentAxis
                        tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130]}
                        tickFormat={(y) => y}
                        style={{tickLabels: { fontSize: 7, padding: 5}}}
                        label={'Daily registered matches'}
                    />
                </VictoryChart>
                
            </View>
        </View>
    )
    
}