import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { useTheme, Button, Section, Subheader, Sticker, Pressable } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from './useSheetStyle';
import { ISuperScheduleCourt } from 'interfaces';
import { useStyles } from '../../hooks';

interface Props {
    currentRequiredCourt?: string,
    courts: ISuperScheduleCourt[]
    style?: any,
    resolve?: ( result: { courtId: string | undefined } | null ) => void
}

export default function CourtsSheet(props: Props) {

    const {
        currentRequiredCourt,
        courts,
        style,
        resolve
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const styles = useStyles();

    const [selectedCourt, setSelectedCourt] = useState(currentRequiredCourt);

    return (
        <View style={[sheetStyle, style]}>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Subheader>{`${StringTable.getString('Play on court:')}`}</Subheader>
            </View>

            <Section> 
                <Button icon={'ico_clear'} label={StringTable.getString('Clear court')} onPress={() => setSelectedCourt(undefined)}/>
            </Section>

            <Section size={'M'}>
                {courts.map((court: ISuperScheduleCourt) => (
                    <Button 
                        key={court.id} 
                        icon={'ico_court'} 
                        label={court.name} 
                        onPress={() => setSelectedCourt(court.id)}
                        mode={court.id === selectedCourt ? 'primary' : 'default'}
                        style={styles.menuButton}
                    />
                ))}
            </Section>

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Cancel')}/>
                <Button onPress={() => resolve && resolve({courtId: selectedCourt })} mode={'primary'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Set court')}/>
            </Section>            
        </View>
    )
}
