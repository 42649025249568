import React from 'react';
import Text from '../helpers/Text';
import useThemeStyle from '../theme/useThemeStyle';

export interface OverlineProps {
    children?: string,
    weight?: string,
    style?: any
}

export default function Overline(props: OverlineProps) {

    const {
        style : styleProp,
        children
    } = props;
    const style = useThemeStyle('Overline', props);

    return(
        <Text style={[style, styleProp]}>
            {children}
        </Text>
    )
}