import React from 'react';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { Factory } from '../../factories';
import EventSheet from './sheets/EventSheet/EventSheet';
import CreateClubSheet from './sheets/CreateClubSheet';
import GroupSheet from './sheets/GroupSheet';
import ClassSelectionSheet from './sheets/ClassSelectionSheet';
import { SuperSchedule, Competition } from 'business';

export default async function editTarget(e: IPressEvent, schedule: SuperSchedule | undefined, target: IActionTarget | null, pushModal: any, popModal: any, popAllModals: any) {

    if (target?.competition) {

        let competition : Competition = target.competition;

        //Step 0: Select class if competition has classes
        if (target.competition.settings.type === 'BIG TOURNAMENT') {
            const subCompetition = await pushModal((
                <ClassSelectionSheet
                    competition={target.competition}
                    selectableParentCompetition={true}
                />
            ), e)
            if (subCompetition) {
                competition = subCompetition;
            }
            else {
                popAllModals();
                return;
            }
        }

        const preType = competition.settings.type;
        const preCourts = competition.settings.courts;
        const preGroupCount = competition.settings.groupCount;
        const prePlayoffSize = competition.settings.playoffSize;
        const prePlayoffStages = competition.settings.playoffStages;
        const preSlots = competition.settings.slots;
        const preStartDate = competition.settings.date;

        //@ts-ignore
        const result : IEventSheetResult | null = await pushModal((
            <EventSheet
                style={{flex: 1}}
                title={StringTable.getString('Edit event')}
                initialSettings={competition.settings}
                doneLabel={StringTable.getString('Save')}
                isScheduled={competition.superScheduleRef ? true : false}
            />
        ), {...e, pageY: 0});
        if (result?.settings) {
            await Factory.updateCompetition(competition, result.settings);
 
            //Only apply the court settings if no parent competition
            if (!competition.settings.parentCompetitionRef && (result.settings.courts !== preCourts)) {
                schedule?.setCourts(competition.getCourts());
            }

            //Always remake matches if type, groupCount, slots or playoff changes.
            if ( schedule && (
                result.settings.type !== preType ||
                result.settings.groupCount !== preGroupCount || 
                result.settings.playoffSize !== prePlayoffSize || 
                result.settings.playoffStages !== prePlayoffStages ||
                result.settings.slots !== preSlots
            )) {
                await competition.makeMatches();
                schedule?.replaceAllMatchesInCompetition(competition.id, competition.getAllMatches().map(m => m.serialize()));        
            }

            //Only apply date settings if no parent competition
            if(!competition.settings.parentCompetitionRef && result.settings.date && result.settings.date !== preStartDate) {
                schedule?.setStartDate(result.settings.date);
            }
        }
    }
    else if (target?.group) {
        //@ts-ignore
        const result : IGroupSheetResult | null = await pushModal((
            <GroupSheet
                style={{flex: 1}}
                title={StringTable.getString('Edit group')}
                initialSettings={target.group.settings}
                doneLabel={StringTable.getString('Save')}
            />    
        ), {...e, pageY: 0});
        result?.settings && target.group.updateSettings(result.settings);
    }
    else if (target?.club) {
        //@ts-ignore
        const result : ICreateClubSheetResult | null = await pushModal((
            <CreateClubSheet
                hideId={true}
                title={StringTable.getString('Edit club')}
                doneLabel={StringTable.getString('Save')}
                initialSettings={target.club.settings}
                style={{flex: 1}}
            />
        ), {...e, pageY: 0})
        result?.settings && target.club.updateSettings(result.settings);
    }
    popModal();
}