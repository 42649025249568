import { useMemo } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { useTheme } from 'components';
import Constants from 'expo-constants';

export default function useStyles() {

    const theme = useTheme();

    const stylesheet = useMemo(() => {
        return StyleSheet.create({
            SectionHeader: {
                marginTop: theme["spacing-medium"],
                marginBottom: theme["spacing-small"], 
                marginHorizontal: theme["spacing-medium"]
            },
            UserCard: {
                marginBottom: theme["spacing-small"], 
                marginHorizontal: theme["spacing-medium"],
                borderRadius: theme["roundness-sharp"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-small"],
                maxWidth: 500
            },
            ContactsListItem: {
                marginBottom: theme["spacing-small"], 
                borderRadius: theme["roundness-sharp"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-small"],
                maxWidth: 500
            },
            TeamCard: {
                marginBottom: theme["spacing-small"], 
                marginHorizontal: theme["spacing-medium"],
                borderRadius: theme["roundness-sharp"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-small"],
                maxWidth: 500
            },
            MatchCard: {
                borderRadius: theme["roundness-sharp"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-small"],
                maxWidth: 500
            },
            SimpleMatchCard: {
                borderRadius: theme["roundness-sharp"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-small"],
                maxWidth: 500
            },
            TableView: {
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-medium"],
                margin: theme["spacing-small"],
                maxWidth: 500
            },
            WinnersView: {
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-medium"],
                maxWidth: 500
            },
            ClubCard: {
                maxWidth: 500, 
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"]
            },
            ClubCardImage: {
                borderTopLeftRadius: theme["roundness"], 
                borderBottomLeftRadius: theme["roundness"],
                overflow: 'hidden',
                width: 95, 
                height: 95
            },
            ClubCardContent: {
                padding: theme["spacing-medium"],
                paddingLeft: theme["spacing-large"]
            },
            CompetitionCard: {
                maxWidth: 500, 
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-medium"],
                marginBottom: theme["spacing-medium"],
                marginHorizontal: theme["spacing-medium"]
            },
            ConversationCard: {
                maxWidth: 500, 
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-medium"],
                marginBottom: theme["spacing-medium"],
                marginHorizontal: theme["spacing-medium"]
            }, 
            GroupCard: {
                maxWidth: 500, 
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"],
                padding: theme["spacing-medium"],
                marginBottom: theme["spacing-medium"],
                marginHorizontal: theme["spacing-medium"]
            },
            UpdateVersionMenu: {
                marginRight: theme["spacing-medium"],
            },
            TutorialsMenu: {
                marginRight: theme["spacing-medium"],
            },
            PlayerName: {
                marginRight: theme["spacing-small"]
            },
            RoundTimer: {
                backgroundColor: theme['color-card'],
                borderRadius: theme['roundness'],
                margin: theme["spaing-medium"], 
                padding: theme["spacing-medium"]
            },
            RoundTimerInput: {
                width: 60,
                padding: 10,
                paddingHorizontal: 11,
                backgroundColor: theme['color-ink-supersoft'],
                borderRadius: theme['roundness-sharp'],
                marginRight: theme["spacing-small"]
            },
            screen: {
                flex: 1, 
                backgroundColor: theme["color-paper"],
                overflow: 'hidden'
            },
            screenHeader: {
                height: 48 + Constants.statusBarHeight + theme["spacing-small"], 
                top: 0, 
                left: 0, 
                right: 0, 
                backgroundColor: theme["color-card"],
                paddingHorizontal: 10,
                paddingTop: Constants.statusBarHeight,
                paddingBottom: theme["spacing-small"],
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                zIndex: 2
            },
            list: {
                flex: 1,
            },
            row: {
                flexDirection: 'row'
            }, 
            column: {
                flexDirection: 'column'
            },
            listItem: {
                marginBottom: 8, 
                marginHorizontal: theme["spacing-medium"]
            },
            listItemColumns: { 
                flex: 0.5, 
                marginBottom: 8,
                marginHorizontal: theme["spacing-medium"]
            },
            listItemHorizontal: {
                width: Dimensions.get('window').width * 0.7,
                marginBottom: theme["spacing-small"],
                marginHorizontal: theme["spacing-small"]
            },
            listSectionHeader: {
                flexDirection: 'row', 
                justifyContent: 'space-between', 
                alignItems: 'flex-end',
                marginTop: theme["spacing-large"],
                marginBottom: theme["spacing-small"],
                marginLeft: theme["spacing-medium"]
            },
            menuButton: {
                marginVertical: theme["spacing-small"],
            },
            menuButtonDesktop: {
                marginTop: theme["spacing-small"],
                marginRight: theme["spacing-medium"]
            },
            userStatsSection: {
                flexDirection: 'row', 
                width: '100%', 
                alignItems: 'flex-end'
            },
            tutorialsPlayer: {
                flex: 1,
                width: '100%',
                backgroundColor: theme["color-ink"]
            },
            scheduleCell: {
                margin: theme["spacing-small"],
                padding: theme["spacing-small"],
                borderRadius: theme["roundness"], 
                backgroundColor: theme["color-card"]
            },
            dropdownButton: {
                flex: 1, 
                margin: theme["spacing-medium"]
            },
            pageIndicator: {
                width: 8, 
                height: 8, 
                borderRadius: 4, 
                backgroundColor: theme["color-ink-supersoft"],
                margin: theme["spacing-extrasmall"]
            },
            pageIndicatorCurrent: {
                backgroundColor: theme["color-ink"]            
            }
        })
    }, [theme]);

    return stylesheet;
}