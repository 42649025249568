import React, { useEffect, useState } from 'react';
import { View, Keyboard, PanResponder, Platform } from 'react-native';

export default function KeyboardDismissingView(props: any) {

    const [keyboardVisible, setKeyboardVisible] = useState(false);

    useEffect(() => {
      const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
        setKeyboardVisible(true);
      });
      const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
        setKeyboardVisible(false);
      });
  
      return () => {
        showSubscription.remove();
        hideSubscription.remove();
      };
    }, []);

    const responder = PanResponder.create({
        onStartShouldSetPanResponder: (evt, gestureState) => keyboardVisible ? true : false,
        onStartShouldSetPanResponderCapture: (evt, gestureState) => keyboardVisible ? true : false,
        onPanResponderGrant: (evt, gestureState) => {
            Keyboard.dismiss();
        }
    });
    
    return Platform.OS === 'android' || props.alwaysActive ? (
        <View {...props} {...responder.panHandlers}/>
    ) : (
        <View {...props}/>
    )
}