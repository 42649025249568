import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { Section, Caption, TextInput, useTheme } from 'components';
import { StringTable } from 'utils';
import UserCard from './UserCard';
import CompetitionCard from './CompetitionCard';
import GroupCard from './GroupCard';
import ClubCard from './ClubCard';
import { useActions } from '../actions';
import { useStyles, useSearch } from '../hooks';
 
interface Props {
    onClearSearch: () => any
    style: any
}

export default function SearchView(props: Props) {

    const { onClearSearch, style } = props;

    const { showUserMenu } = useActions();
    const styles = useStyles();
    const theme = useTheme();

    const [searchQuery, setSearchQuery] = useState('');
    const searchResults = useSearch(searchQuery);

    return(
        <Section style={[style, {marginHorizontal: theme["spacing-medium"]}]}>
            <TextInput 
                iconLeft={'ico_search'} 
                iconRight={'ico_clear'} 
                placeholder={StringTable.getString("Search")} 
                value={searchQuery}
                onChangeText={(t: string) => setSearchQuery(t)}
                onPressIconRight={() => {
                    setSearchQuery('');
                    onClearSearch();
                }}
                autoFocus={true}
            />

            <View style={{flex: 1}}>
                <ScrollView style={{flex: 1}}>
                    { searchResults?.userIdentities?.length ? (
                        <Section key={'userIdentities'}>
                            <Caption style={styles.listItem}>{StringTable.getString('USERS')}</Caption>
                            { searchResults.userIdentities.map(userIdentity => (
                                <UserCard 
                                    key={userIdentity.id}
                                    userRef={userIdentity.id} 
                                    userIdentity={userIdentity} 
                                    verbose={true} 
                                    onPress={showUserMenu}
                                    style={styles.listItem}
                                />                                
                            ))}
                        </Section>
                    ) : null }
                    { searchResults?.competitions?.length ? (
                        <Section key={'competitions'}>
                            <Caption style={styles.listItem}>{StringTable.getString('EVENTS')}</Caption>
                            { searchResults.competitions.map(competition => (
                                <CompetitionCard 
                                    key={competition.id}
                                    style={styles.listItem} 
                                    competition={competition}
                                />
                            ))}
                        </Section>
                    ) : null }
                    { searchResults?.groups?.length ? (
                        <Section key={'groups'}>
                            <Caption style={styles.listItem}>{StringTable.getString('GROUPS')}</Caption>
                            { searchResults.groups.map(group => (
                                <GroupCard 
                                    key={group.id}
                                    style={styles.listItem} 
                                    group={group}
                                />
                            ))}
                        </Section>
                    ) : null }
                    { searchResults?.clubs?.length ? (
                        <Section key={'clubs'}>
                            <Caption style={styles.listItem}>{StringTable.getString('CLUBS')}</Caption>
                            { searchResults.clubs.map(club => (
                                <ClubCard 
                                    key={club.id}
                                    club={club} 
                                    style={styles.listItem}
                                />
                            ))}
                        </Section>
                    ) : null }

                </ScrollView>
            </View>
        </Section>
    ) 
}
