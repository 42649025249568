export default function extractSearchTokens(name?: string) {
    if (!name) { return [] };
    const minLength = 3;
    const alphanumericName = name.replace(/[^\p{L}\p{N} ]/gu, '');
    const lowerCaseName = alphanumericName.trim().toLowerCase();
    let searchStrings: string[] = [];

    for (let i = 0; i < lowerCaseName.length; i++) {
        for (let j = i + minLength; j <= lowerCaseName.length; j++) {
            const token = lowerCaseName.substring(i, j).trim();
            if (token.length >= minLength && !searchStrings.includes(token)) {
                searchStrings.push(token);
            }
        }
    }

    return searchStrings;
}
