import React from 'react';
import { View } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from './useSheetStyle';

interface BooleanSheetProps {
    title: string,
    noLabel?: string,
    yesLabel?: string,
    children?: any,
    style?: any,
    resolve?: (data: IBooleanSheetResult | null ) => void
}

BooleanSheet.defaultProps = {
    noLabel: StringTable.getString('No'),
    yesLabel: StringTable.getString('Yes')
}
export default function BooleanSheet(props: BooleanSheetProps) {

    const {
        title,
        noLabel,
        yesLabel,
        children,
        resolve,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    
    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            {children}

            <Section style={{flexDirection: 'row'}}>
                <Button onPress={(e: IPressEvent) => resolve && resolve({response: false, e: e})} mode={'primary'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={noLabel}/>
                <Button onPress={(e: IPressEvent) => resolve && resolve({response: true, e: e})} mode={'primary'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={yesLabel}/>
            </Section>            
        </View>
    )
}
