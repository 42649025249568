export interface Document {
    id: string
}

export enum NotificationImportance {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH'
}

export interface PadelNotification {
    "content-available": number,
    id: string,
    recipient?: string,
    importance: NotificationImportance,
    date: Date,
    title?: string,
    message?: string, 
    host: string,
    link: string
}

export interface NotificationTransaction {
    recipients: string[], 
    title: string, 
    message: string, 
    host: string,
    link: string,
    importance: NotificationImportance
}

export interface SmsTransaction {
    recipients: string[], 
    body: string
}

export enum CollectionName {
    users = 'users',
    competitions = 'competitions',
    groups = 'groups',
    clubs = 'clubs',
    conversations = 'conversations',
    matches = 'matches',
    support = 'support'
}

export interface Locale {
    dialCode?: string, 
    flag?: string, 
    code?: string, 
    name?: string, 
    language?: string,
    languageName?: string,
    translation?: boolean,
    isPrimary?: boolean
}

export interface PhoneContact {
    id: string,
    title: string, 
    phoneNumbers?: string[],
    registered?: boolean
}

export interface LoginStatistic {
    date: number | Date, 
    device: any,
    os: 'ios' | 'android' | 'web' | 'windows' | 'macos' | 'unknown',
    appVersion: number,
    loginCount: number,
    userId: string
}

export interface ICompetitionRepeat {
    repeat?: boolean, 
    repeatData?: {
        settings: ICompetitionSettings, 
        participation: IParticipation
    }
}

export interface IMatchSchedule {
    court?: ICourt, 
    startDate?: Date, 
    endDate?: Date
}

export interface IQueuePosition {
    matchId: string, 
    round?: number,
    playoffRound?: number,
    courtId?: string,
    startDate?: Date,
    probableStartDate?: Date, 
    probableCourt?: string,
    endDate?: Date
}

export interface IPressEvent {
    pageX: number;
    pageY: number;
}

export interface IRegion {
    title: string, 
    iana?: string,
    common?: boolean, 
    points?: boolean,
    country: string
}

/**
 * 
 * A club is, perhaps most importantly, a location. It also uses the generalized IParticipation to 
 * track administrators, and some more specific structures for settings, reviews and similar 
 * player interactions.
 * 
 */
export interface IClub {
    id: string;
    participation: IParticipation;
    followerCount?: number;
    playerCount?: number;
    courtCount?: number;
    reviews?: {[uId: string]: number };
    creationDate?: Date;
    settings: IClubSettings;
}
export interface IClubMeta {
    id: string;
    location?: ILocation;
    region: string;
    lastModifiedDate: Date;
    creationDate?: Date;
    public?: boolean;
    rating: number;
    searchTokens?: string[];
    version: number;
}
export interface IClubSettings {
    title?: string;
    byline?: string;
    imageUrl?: string;
    description?: string;
    location?: ILocation;
    hasCalendar?: boolean;
    phoneNumber?: string;
    public?: boolean;
} 

/**
 * 
 * Everything that is an actual event, whether a match or a big americano, is on the structure
 * of an ICompetition. The ICompetition uses the generalized IParticipation to track users, reserves, 
 * invitations and administration, and uses a large number of additional structures to handle settings, 
 * group stage, playoff stage, actual results and more. 
 * 
 */
export interface ICompetition {
    id: string,
    settings: ICompetitionSettings, 
    participation: IParticipation,
    groups?: ICompetitionGroup[], 
    playoff?: IPlayoff,
    state?: IState,
    schedule?: ISchedule,
    superScheduleRef?: string,
    log?: string
}

export interface ICompetitionMeta {
    id: string,
    recipients?: string[],
    admin: string[],
    invitedRefs?: string[]
    date?: Date,
    lastModifiedDate: Date,
    clubRef?: string, 
    groupRef?: string,
    parentCompetitionRef: string | false,
    public?: boolean,
    featured?: boolean, 
    region?: string,
    repeat?: boolean,
    version: number,
    searchTokens?: string[]
}

export interface ICompetitionSettings {
    type: 'MATCH' | 'AMERICANO' | 'TEAM AMERICANO' | 'WINNERS COURT' | 'TEAM WINNERS COURT' | 'TOURNAMENT' | 'BIG TOURNAMENT' | 'TRAINING';
    date?: Date;
    slots?: number;
    duration?: number;
    title?: string;
    parentTitle?: string;
    byline?: string;
    description?: string;
    imageUrl?: string;
    parentCompetitionRef?: string;
    groupRef?: string;
    groupCount?: number;
    fixedTeams?: boolean;
    teamSignup?: boolean;
    teamSignupProhibitVacancies?: boolean;
    sortByRanking?: boolean;
    points?: boolean;
    price?: number;
    playoffStages?: 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Numeric';
    playoffSize?: number;
    clubRef?: string;    
    conversationRef?: string;
    courts: {[courtId: string]: ICourt};
    courtsEdited?: boolean;
    similarRating?: boolean;
    public?: boolean;
    location?: ILocation;
    phoneNumber?: string;
    repeat?: boolean;
}
export interface ICompetitionGroup {
    userRefs: string[];
    matches: IMatch[];
    table?: TableRow[] | null;
}
export interface TableRow {
    id: string, 
    wins: number, 
    draws: number, 
    losses: number, 
    score: number, 
    setdiff: number, 
    gamediff: number, 
    pointsdiff: number, 
    tiebreakers?: {[key: string]: boolean }
}
export interface IPlayoff {    
    stage: 'Final' | 'Semifinals' | 'Quarterfinals' | 'Round of 8' | 'Round of 16' | 'Round of 32' | 'Round of 64' | 'Numeric' | 'Ended';
    competitionId: string;
    matches?: IMatch[];
    winnerPlayoff?: IPlayoff;
    seedDescriptions?: string[];
    playoffRound: number;
    teamCount?: number;
    points?: boolean;
}
export interface IMatch {
    id: string;
    userRefs: (string | null)[];
    result?: number[];
    prediction?: number[] | null;
    ratings?: {
        in: number[], 
        change?: number[] | null
    };
    competitionId: string;
    requiredCourtId?: string;
    clubRef?: string;
    points?: boolean;
    admin?: string[];
    playoffRound?: number;
    groupIndex?: number;
    endDate?: Date;
    locked?: boolean;
    seed?: number[];
}

export interface ICourt {
    courtId: number;
    courtName?: string;
    queue: { matchId?: string, startDate?: Date }[];
}
export interface ISchedule {
    courts: {[courtId: string]: ICourt };
    endedMatches?: string[];
    startDate: Date;
    matchMinutes: number;
}
export interface IScheduleConstraint {
    competitionId: string,
    earliestRoundForGroups: number, 
    earliestRoundForPlayoffs: number    
}

export interface IScheduleCell {
    roundIndex: number,
    courtId: string,
    type: 'match' | 'gap' | 'block', 
    id?: string, 
    competitionId?: string,
    time?: number,
    delay?: number
}

export interface IRoundConstraints {
    delay?: number, 
    constrainedCompetitions?: string[],
    prioritizedCompetitions?: string[],
    constrainedGroups?: {[competitionId: string]: number[]},
    prioritizedGroups?: {[competitionid: string]: number[]},
    constrainedCourts?: string[]
}

export interface ISuperScheduleCourt {
    id: string, 
    name: string
}

/**
 * 
 * A conversation is a limited-length array of messages, where each message can have
 * several reactions attached to it.
 * 
 */
export interface IConversation {
    id: string, 
    participation: IParticipation,
    title: string,
    messages?: IConversationMessage[],
    groupRef?: string, 
    competitionRef?: string,
    public?: boolean
}
export interface IConversationMeta {
    id: string, 
    lastModifiedDate: Date, 
    public?: boolean,
    recipients?: string[],
    version?: number
}
export interface IConversationMessage {
    id: string, 
    sender: string, 
    message: string, 
    date: Date,
    reactions?: {[reaction: string]: IConversationMessageReaction[]}
}
export interface IConversationMessageReaction {
    date: Date, 
    sender: string
}

/**
 * 
 * A group is a collection of some settings and a generalized IParticipation structure.
 * 
 */
export interface IGroup {
    id: string;
    participation: IParticipation;
    settings: IGroupSettings;
    state?: IState;
    superScheduleRef?: string;
}
export interface IGroupMeta {
    id: string,
    recipients?: string[],
    lastModifiedDate: Date,
    public?: boolean, 
    featured?: boolean, 
    region?: string,
    clubRef?: string,
    version: number,
    searchTokens?: string[]
}
export interface IGroupSettings {
    title?: string;
    byline?: string;
    description?: string;
    imageUrl?: string;
    clubRef?: string;    
    conversationRef?: string;
    similarRating?: boolean;
    public?: boolean;
    private?: boolean;
    location?: ILocation;
    phoneNumber?: string;
}

/**
 * 
 * Interface for User.
 * 
 */
export interface IUser {
    id: string;
    pName?: string; 
    systemMessage?: string;
    onboarding?: {[key: string]: any };
    contacts?: {[key: string]: AppContact };
    accountType?: 'registered' | 'coach' | 'club';
    settings: IUserSettings;
    state: IUserState;
}
export interface IUserMeta {
    id: string;
    clubRefs?: string[];
    rating?: number, 
    searchTokens?: string[];
    version?: number;
    lastModifiedDate: Date;
}
export interface IUserSettings {
    title?: string;
    imageUrl?: string;
    hand?: 'left' | 'right' | 'both';
    side?: 'left' | 'right' | 'both';
    bestShot?: string;
    worstShot?: string;
    localeCode?: string;
    region?: string;
    clubRefs?: string[];
}
export interface IUserState {
    registered?: boolean;
    rating: number;
    matches?: number;
    trophies?: Trophy[];
    matchHistory?: IMatch[];
}
export interface AppContact {
    id: string, 
    playCount?: number, 
    imageUrl?: string,
    rating?: number,
    dateAdded?: Date,
    title: string
}
export interface Trophy {
    competitionId: string,
    value: number, 
    date?: Date,
    title?: string
}


/**
 * 
 * Generalized and shared interface for a location
 * 
 */
export interface ILocation {
    lat?: number, 
    lon?: number,
    geohash?: string,
    region?: string,
    address?: string
}

/**
 * 
 * Generalized and shared interface for participation, used in competition, group, club etc
 * 
 */
export interface IParticipation {
    admin: string[];
    userRefs?: string[]; 
    invitedRefs?: string[];
    declinedRefs?: string[];
    reservesRefs?: string[];
    slots?: number;
}

/**
 * 
 * Generalized and shared interface for state, used to track value, featured, etc.
 * 
 */
export interface IState {
    featured?: boolean,
    full?: boolean,
    winners?: string[],
    scheduled?: boolean,
    started?: boolean,
    closed?: boolean, 
    ended?: boolean,
    repeat?: boolean,  
    value?: number,
    ratingChange?: { [userRef: string]: number }
}

/**
 * TODO: This structure, as well as the UserIdentity class, has no place in the business module, should 
 * not be there. But that requires a bit of refactoring.
 */
export interface Identity {
    t?: string,                     //title
    pt?: string,                    //provisional title
    r?: number,                     //rating
    p?: string,                     //imageUrl
    m?: number,                      //matches
    l?: string                      //localeCode
}

export interface IUpdateUserContext {
    rating?: number,
    title?: string,
    accountType?: 'registered' | 'coach' | 'club',
    oldId: string,
    id: string
}

export interface IMergeUserContext {
    targetId: string,
    subjectId: string
}