import React from 'react';
import { IPressEvent } from 'interfaces';
import EventHistorySheet from './sheets/EventHistorySheet';

export default async function showEventHistory(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {
    const group = target?.group;
    if (group) {
        await pushModal((
            <EventHistorySheet
                group={group}
                style={{flex: 1}}
            />
        ), {...e, pageY: 0});
    }
    popAllModals();

}
