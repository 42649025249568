import { useState, useEffect, useRef } from 'react';
import { UserIdentity } from 'business';
import { IdentityCache } from 'bridge';

export default function useUser(userRef?: string, once?: boolean, defaultValue?: UserIdentity) : UserIdentity | null | undefined {
    
    const [user, setUser] = useState<UserIdentity | null | undefined>(defaultValue || (userRef ? IdentityCache.getSync(userRef) : null));
    const hasUpdated = useRef(false);
    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        }
    }, []);

    function onPlayerChange(userRef: string, identity: UserIdentity | null) {   

        if (!(once && hasUpdated.current)) {
            mounted.current && setUser(prev => prev === identity ? prev : identity);
            hasUpdated.current = true;
        }
    }

    useEffect(() => {

        hasUpdated.current = false;

        if (userRef) {
            IdentityCache.listen(userRef, onPlayerChange);
            return () => {
                IdentityCache.stop(userRef, onPlayerChange);
            }    
        }
        else if (defaultValue) {
            setUser(defaultValue);
        }
        
    }, [userRef, defaultValue]);

    return user;
}
