import { getFirestore, doc, getDoc } from "firebase/firestore";

export default async function adminGetDocument(path: string, app: any) {
        
    try {
        const documentSnapshot = await getDoc(doc(getFirestore(app), path));
        if (documentSnapshot.exists()) {
            return documentSnapshot.data();
        }
    } catch(err) {
        console.log('error', err);
    }
    return null;
}