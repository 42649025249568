import React from 'react';
import { View } from 'react-native';
import { Subheader } from 'components';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import { searchAsync, getInstance } from 'bridge';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import ConfirmSheet from './sheets/ConfirmSheet';
import BooleanSheet from './sheets/BooleanSheet';
import { Competition } from 'business';

export default async function closeCompetition(e: IPressEvent, target: IActionTarget | null, pushModal: any, popModal: any, popAllModals: any) {

    const targetCompetition = target?.competition;
    if (!targetCompetition) { return };
    
    //@ts-ignore
    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet
            title={StringTable.getString('Confirm end event')}
            doneLabel={StringTable.getString('End')}
            doneMode={'primary'}
        >
            <View style={{margin: 12, justifyContent: 'center', alignItems: 'center'}}>
                <Subheader>{targetCompetition.settings.title}</Subheader>
            </View>
        </ConfirmSheet>
    ), e);

    if (!(confirmResult?.confirmed)) {
        popAllModals();
        return;
    }

    let competitions : Competition[] | undefined;
    if (targetCompetition.settings.type === 'BIG TOURNAMENT') {
        const subCompetitions = await searchAsync<Competition>('competitions', { parentCompetitionRef: targetCompetition.id });
        competitions = [...(subCompetitions || []), targetCompetition];
    }
    else if (targetCompetition.settings.parentCompetitionRef) {
        const booleanResult : IBooleanSheetResult | null = await pushModal((
            <BooleanSheet title={StringTable.getString('End all classes?')}>
                <View style={{margin: 12, justifyContent: 'center', alignItems: 'center'}}>
                    <Subheader>{targetCompetition.settings.title}</Subheader>
                </View>
            </BooleanSheet>
        ), e);
        if (booleanResult?.response) {
            const parentCompetition = await getInstance<Competition>('competitions', targetCompetition.settings.parentCompetitionRef);
            const subCompetitions = await searchAsync<Competition>('competitions', { parentCompetitionRef: targetCompetition.settings.parentCompetitionRef });
            competitions = [...(subCompetitions || [])];
            parentCompetition && competitions.push(parentCompetition);
        }
        popModal();
    }
    else {
        competitions = [targetCompetition];
    }

    if (!competitions) { 
        popAllModals();
        return 
    }

    pushModal((
        <SavingMessageSheet title={StringTable.getString('Ending event')}/>
    ), confirmResult.e);

    for (const competition of competitions) {
        competition?.close();
    }

    popAllModals();
}
