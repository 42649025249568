import React from 'react';
import { View, FlatList } from 'react-native';
import { useNavigation } from "@react-navigation/native";
import { Section } from 'components';
import { StringTable } from 'utils';
import { useBatchedQuery } from 'bridge';
import { Group, Competition } from 'business';
import useSheetStyle from './useSheetStyle';
import CompetitionCard from '../../composites/CompetitionCard';
import { useStyles } from '../../hooks';
import CloseHeader from './CloseHeader';

interface Props {
    group: Group,
    resolve?: (data: null) => void
    style?: any
}

export default function EventHistorySheet(props: Props) {

    const {
        group,
        style,
        resolve
    } = props;

    const [competitionHistory, onLoadMoreCompetitionHistory] = useBatchedQuery<Competition>({queryType: 'groupCompetitionHistory', groupId: group.id});

    const styles = useStyles();
    const sheetStyle = useSheetStyle();

    //Sheets are typically rendered by ActionProvider which is outside of the Navigation context
    //provided by screens. As such we have to fall back to the core navigation system
    const navigation = useNavigation();
    function navigate(params: INavigationParams) {
        resolve?.(null);
        //@ts-ignore
        navigation.navigate('Competition', params);
    }

    return (
        <View style={[sheetStyle, style]}>
            <CloseHeader title={`${group.getTitle()} ${StringTable.getString('history')}`} onDismiss={() => resolve?.(null)}/>
            <Section style={{flex: 1}}>
                <FlatList
                    data={competitionHistory}
                    onEndReached={onLoadMoreCompetitionHistory}
                    onEndReachedThreshold={0.01}
                    keyExtractor={item => item.id}
                    renderItem={({item}) => <CompetitionCard competition={item} onPress={() => navigate({competitionId: item.id, groupId: group.id})} style={styles.listItem}/>}
                />
            </Section>
        </View>            
    )
} 
