//@ts-nocheck
import React, { useState, useRef } from 'react';
import { View } from 'react-native';
import Svg, { LinearGradient, Defs, Rect, Stop } from 'react-native-svg';
import { createId } from 'tools';

/**
 * 
 * @prop {*} startColor
 * @prop {*} endColor
 * @prop {*} startOpacity
 * @prop {*} endOpacity
 * @prop {*} startOffset percentage string
 * @prop {*} endOffset percentage string
 */
export default function GradientView(props : any) {

    const {
        startColor, 
        endColor, 
        startOpacity,
        endOpacity,
        startOffset,
        endOffset,
        children,
        axis,
        ...otherProps
    } = props;

    const id = useRef(createId(10)).current;

    return(
        <View {...otherProps}>
            <View style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
                <Svg height="100%" width="100%">
                    <Defs>
                        <LinearGradient id={id} x1="0%" x2="100%" y1="0%" y2="100%" gradientUnits="userSpaceOnUse">
                            <Stop offset={startOffset} stopColor={startColor} stopOpacity={startOpacity}/>
                            <Stop offset={endOffset} stopColor={endColor} stopOpacity={endOpacity}/>
                        </LinearGradient>
                    </Defs>
                    <Rect fill={`url(#${id})`} height={"100%"} width={"100%"} x="0" y="0" />
                </Svg>
            </View>
            { children }
        </View>
    )
}

GradientView.defaultProps = {
    startOpacity: 1, 
    endOpacity: 1,
    axis: 'y',
    startOffset: '0%', 
    endOffset: '100%'
}