import { useState, useEffect } from 'react';
import { IdentityCache } from 'bridge';
import { UserIdentity } from 'business';

type UserIdentityMap = { [userRef: string]: UserIdentity | null | undefined };

export default function useUsers(userRefs?: (string | null)[], once?: boolean): UserIdentityMap {
    const [identities, setIdentities] = useState<UserIdentityMap>(initializeIdentities());

    function initializeIdentities(): UserIdentityMap {
        const initialIdentities: UserIdentityMap = {};

        if (userRefs) {
            for (const userRef of userRefs) {
                if (userRef) {
                    initialIdentities[userRef] = IdentityCache.getSync(userRef);
                }
            }
        }
        return initialIdentities;
    }

    useEffect(() => {

        function onIdentityUpdate(userRef: string, userIdentity: UserIdentity | null) {
            
            setIdentities((prevIdentities) => {
                if (prevIdentities[userRef] === userIdentity) {
                    // Return prev, avoid state update if user was preloaded and hasn't changed
                    return prevIdentities;
                }
                if (prevIdentities[userRef] && once) {
                    //Return prev if user was fetched and once was requested
                    return prevIdentities;
                }

                const updatedIdentities = { ...prevIdentities };
                updatedIdentities[userRef] =
                    userIdentity || new UserIdentity(userRef, {});
                return updatedIdentities;
            });
        }

        if (!userRefs) {
            return;
        }

        for (const userRef of userRefs) {
            userRef && IdentityCache.listen(userRef, onIdentityUpdate);
        }

        return () => {
            for (const userRef of userRefs) {
                userRef && IdentityCache.stop(userRef, onIdentityUpdate);
            }
        };
    }, [userRefs, once]);

    return identities;
}
