import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useTheme, Card, Subheader, Section, Button, Paragraph, TextInputPhone, TextInputNumeric, TextInput, SectionHeader, DropdownMenu, Caption } from 'components';
import { User } from 'business';
import useSheetStyle from '../../actions/sheets/useSheetStyle';
import { useStyles } from '../../hooks';
import { useAppContext } from '../../appcontext';
import { IPressEvent, Locale, IUpdateUserContext } from 'interfaces';
import { composePhoneNumber, splitPhoneNumber, validatePhoneNumber } from 'tools';
import { useModal } from '../../actions';
import LocalesSheet from '../../actions/sheets/LocalesSheet';
import { useDocumentInstance } from 'bridge';

const accountTypes = {
    "registered": "registered", 
    "coach": "coach", 
    "club": "club"
}

interface Props {
    user?: User,
    style?: any,
    resolve?: (userEdits: IUpdateUserContext | null) => any, 
}

export default function UserEditorSheet(props: Props) {

    const {
        user,
        style,
        resolve
    } = props;

    const { locales, locale : systemLocale } = useAppContext();
    const { pushModal, popModal } = useModal();
    
    const [rating, setRating] = useState(user?.state.rating);
    const [title, setTitle] = useState(user?.settings.title);
    const [type, setType] = useState(user?.accountType)

    const [locale, setLocale] = useState<Locale>(systemLocale);
    const [digits, setDigits] = useState('');

    const id = composePhoneNumber(locale, digits);
    const preExistingUser = useDocumentInstance('users', id === user?.id ? undefined : id);

    useEffect(() => {
        const id = user?.id;
        if (id) { 
            const result = splitPhoneNumber(id, locales);
            setLocale(result.locale);
            setDigits(result.digits);
        }
    }, [user, locales]);

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const styles = useStyles();

    return user ? (
        <View style={[sheetStyle, style]}>
            <View style={{flex: 1}}>
                <Subheader>{title}</Subheader>

                <Section>
                    <SectionHeader label={'ID'}/>
                    <Section size={'M'}/>
                    <TextInputPhone
                        onPressLocale={async (e: IPressEvent) => {
                            const newLocale: Locale | null = await pushModal((
                                <LocalesSheet
                                    locales={locales}
                                    selectedLocale={locale}
                                    style={{ flex: 1 }}
                                />
                            ), { ...e, pageY: 0 });

                            newLocale && setLocale(newLocale);
                            popModal();
                        }}
                        locale={locale}
                        locales={locales}
                        digits={digits}
                        onChangeDigits={(t: string) => setDigits(t)}
                        onChangeLocale={(newLocale: Locale) => newLocale && setLocale(newLocale)}
                    />
                </Section>

                <Section>
                    <SectionHeader label={'title'}/>
                    <Section size={'M'}/>
                    <TextInput
                        value={title}
                        onChangeText={setTitle}
                    />
                </Section>

                <Section>
                    <SectionHeader label={'rating'}/>
                    <Section size={'M'}/>
                    <TextInputNumeric
                        value={rating}
                        onEndEditing={setRating}
                    />
                </Section>

                <Section>
                    <SectionHeader label={'account type'}/>
                    <Section size={'M'}/>
                    <DropdownMenu 
                        options={accountTypes} 
                        selectedValue={type} 
                        style={styles.dropdownButton} 
                        onSelectValue={(id: string) => setType(id as 'registered' | 'coach' | 'club')}
                    />
                </Section>

            </View>
            <Section style={{ flexDirection: 'row' }}>
                <Button 
                    onPress={() => {
                        resolve && resolve(null)
                    }} 
                    mode={'outlined'} 
                    style={{ flex: 1, marginRight: theme["spacing-extrasmall"] }} 
                    label={'Cancel'}
                />
                <Button
                    onPress={(e: any) => {
                        resolve && id && resolve({
                            rating, 
                            title,
                            accountType: type,
                            oldId: user.id,
                            id: id
                        });
                    }}
                    mode={preExistingUser || !validatePhoneNumber(id) ? 'passive' : 'primary'}
                    style={{ flex: 1, marginLeft: theme["spacing-extrasmall"] }}
                    label={!validatePhoneNumber(id) ? 'Bad phone number' : (preExistingUser ? 'Used phone number' : 'Save')}
                />
            </Section>
        </View>
    ) : (
        <Card style={{marginTop: theme["spacing-small"]}}>
            <Paragraph>{'Missing user'}</Paragraph>
        </Card>
    )
}
