//@ts-nocheck
import { useState, useEffect } from 'react';
import { Club, Competition, Group, Conversation, User, SuperSchedule } from 'business';
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import parse from './parse';
import save from './save';

export default function useDocumentInstance<T>(collectionId: string, documentId?: string, defaultData?: ICompetition | IClub | IGroup) : T | undefined {

    const [instance, setInstance] = useState();

    useEffect(() => {

        if (!(collectionId && documentId)) {
            setInstance(undefined);
            return;
        }
        
        const _classes = {
            "groups": Group,
            "clubs": Club,
            "competitions": Competition,
            "conversations": Conversation, 
            "users": User, 
            "schedules": SuperSchedule
        }
        const _class = _classes[collectionId as keyof typeof _classes];

        const unsubscribe = collectionId && documentId && onSnapshot(doc(getFirestore(), collectionId, documentId), snap => {
            
            if (snap.exists()) {
                setInstance(new _class(parse(collectionId, snap), save));
            }
            else if (defaultData) {
                setInstance(new _class(defaultData, save));
            }
            else {
                setInstance(null);
            }
        });

        return () => {
            unsubscribe && unsubscribe();
        }
    }, [collectionId, documentId]);

    return instance;    
}
