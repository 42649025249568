import React, { useCallback, useState } from 'react';
import { View, ScrollView, TouchableOpacity } from 'react-native';
import { useTheme, Overline, Button, TextInput, TextInputPhone, Section, Paragraph } from 'components';
import { StringTable } from 'utils';
import { IPressEvent, Locale } from 'interfaces';
import { useAppContext } from '../../../appcontext';
import useSheetStyle from '../useSheetStyle';
import LocalesSheet from '../LocalesSheet';
import CloseHeader from '../CloseHeader';
import useModal from '../../useModal';

interface Props {
    canCreateMany?: boolean,
    style?: any,
    resolve?: (result: ICreateContactSheetResult | null ) => void

}
export default function CreateContactSheet(props: Props) {

    const {
        canCreateMany,
        style,
        resolve
    } = props;

    const { locales, locale } = useAppContext();
    const { pushModal, popModal } = useModal();

    const theme = useTheme();
    const [contactsDigits, setContactsDigits] = useState<string[]>(['']);
    const [contactsLocales, setContactsLocales] = useState<Locale[]>([locale]);
    const [contactsNames, setContactsNames] = useState<string[]>(['']);

    const addContactInput = useCallback(() => {
        setContactsDigits(prev => ([...prev, '']));
        setContactsLocales(prev => ([...prev, locale]));
        setContactsNames(prev => ([...prev, '']));
    }, []);

    const overlineStyle = {
        marginTop: 16,
        marginBottom: 4
    }
    const sheetStyle = useSheetStyle();

    return (
        <View style={[style, sheetStyle]}>
            <CloseHeader title={StringTable.getString('Add by phonenumber')} onDismiss={() => resolve && resolve(null)}/>
            <ScrollView style={{flex: 1}} contentContainerStyle={{paddingBottom: 320}}>
                { contactsNames.map((contactName, contactIndex) => (
                    <View key={contactIndex}>
                        <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>                            
                            <Overline style={overlineStyle}>{StringTable.getString('Phone number')}</Overline>
                            { contactIndex === 0 && canCreateMany ? (
                                <TouchableOpacity onPress={() => addContactInput()} style={{paddingBottom: 6}}>
                                    <Paragraph style={{color: theme["color-blue"]}}>{StringTable.getString('Add many')}</Paragraph>
                                </TouchableOpacity>
                            ) : null }
                        </View>
                        <TextInputPhone
                            onPressLocale={async (e: IPressEvent) => {
                                const changedLocale = await pushModal((
                                    <LocalesSheet
                                        locales={locales}
                                        selectedLocale={contactsLocales[contactIndex]}
                                        style={{flex: 1}}
                                    />
                                ), {...e, pageY: 0});
                                popModal();
                                changedLocale && setContactsLocales((prev: Locale[]) => {
                                    const changed = [...prev];
                                    changed[contactIndex] = changedLocale;
                                    return changed;
                                });
                            }}
                            locale={contactsLocales[contactIndex]}
                            locales={locales}
                            digits={contactsDigits[contactIndex]}
                            onChangeDigits={(t: string) => {
                                setContactsDigits((prev: string[]) => {
                                    const changed = [...prev];
                                    changed[contactIndex] = t;
                                    return changed;
                                })
                            }}
                            onChangeLocale={(changedLocale: Locale) => {
                                setContactsLocales((prev: Locale[]) => {
                                    const changed = [...prev];
                                    changed[contactIndex] = changedLocale;
                                    return changed;
                                });
                            }}
                        />
                        <Overline style={overlineStyle}>{StringTable.getString('Name')}</Overline>
                        <TextInput
                            value={contactsNames[contactIndex]}
                            onChangeText={(t: string) => {
                                setContactsNames((prev: string[]) => {
                                    const changed = [...prev];
                                    changed[contactIndex] = t;
                                    return changed;
                                })
                            }}
                        />
                    </View>    
                ))}
            </ScrollView>
            <Section style={{flexDirection: 'row'}}>
                <Button 
                    onPress={() => resolve && resolve(null)} 
                    mode={'outlined'} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={StringTable.getString('Cancel')}
                />
                <Button 
                    onPress={async () => {
                        const createdContacts : ContactData[] = contactsNames.map((name: string, index: number) => {

                            const trimmedName = name.trim();
                            const trimmedDigits = contactsDigits[index]?.replace(/^0+/, '').trim();
                            const locale = contactsLocales[index];
                            const trimmedPhoneNumber = trimmedDigits && locale ? `${locale.dialCode}${trimmedDigits}` : '';
                            const id = trimmedPhoneNumber || trimmedName;
                            return {
                                id, 
                                title: trimmedName
                            }
                        });
                        resolve && resolve({created: createdContacts});
                    }}
                    mode={contactsNames.length && contactsNames[0].trim().length ? 'primary' : 'passive'}
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={StringTable.getString('Add')}
                />                
            </Section>
        </View>
    )
}