import { useMemo } from 'react';
import { UserIdentity } from 'business';
import { useAppContext } from '../appcontext';


export default function useParticipationPortraits(users: {[userRef: string]: UserIdentity | null | undefined}, maxCount: number = 4) {

    const { currentUserId } = useAppContext();

    const portraits = useMemo(() => {
        const p : string[] = [];
        Object.values(users || {}).some((u: UserIdentity | null | undefined) => {
            if (u?.id !== currentUserId && u?.imageUrl) {
                p.push(u.imageUrl);
                if (p.length === maxCount) {
                    return true;
                }
            }
        });
        return p;
    }, [currentUserId, users, maxCount]);

    return portraits;
}
