import { useMemo, useState, useEffect } from 'react';
import { getFirestore, onSnapshot, query, collection, orderBy, getDocs, where, limit, DocumentSnapshot } from "firebase/firestore";
import { getDatabase, ref, set, increment } from "firebase/database";
import { Club, Competition, Group } from 'business';
import parse from './parse';
import save from './save';

interface IQueryOptions {
    userId?: string,
    groupId?: string,
    clubId?: string,
    isClubAdmin?: boolean,
    parentCompetitionId?: string,
    region?: string, 
    queryType: 'subCompetitions' | 'recentCompetitions' | 'groupCompetitions' | 'recentGroupCompetitions' | 'clubCompetitions' | 'recentClubCompetitions' | 'clubGroups' | 'clubs'
    date?: Date
}

export default function useQuery<T extends Club | Competition | Group>(options: IQueryOptions, app?: any) : T[] | undefined {
    
    const stableOptions = useMemo(() => {
        return {...options};
    }, [options.userId, options.groupId, options.region, options.queryType, options.date]);

    const [instances, setInstances] = useState<T[] | undefined>();

    useEffect(() => {
        
        const querySettings = getQuerySettings(stableOptions);
        if (!querySettings) {
            return;
        }

        const { params, max, collectionName, classType } = querySettings;
        const queryParms = [...params];
        queryParms.push(limit(max));    
        const q = query(collection(getFirestore(app), collectionName), ...queryParms);
    
        //use to get an index link
        //const res = getDocs(q);

        const unsubscriber = onSnapshot(q, qSnapshot => {
            set(ref(getDatabase(app), `analytics/reads/useQuery`), increment(qSnapshot.size || 1));
            if (!qSnapshot.empty) {
                setInstances(qSnapshot.docs.map((doc: DocumentSnapshot) => new classType(parse(collectionName, doc), save)));
            }
            else {
                setInstances([]);
            }
        });
        return () => {
            unsubscriber?.();
        }

    }, [stableOptions, app]);
    
    return instances;
}
    


function getQuerySettings(options: IQueryOptions) : { params: any[], max: number, collectionName: string, classType: any} | undefined {

    const {
        queryType, 
        userId,
        groupId,
        clubId, 
        isClubAdmin,
        parentCompetitionId,
        region,
        date
    } = options

    const today = date ? new Date(date) : new Date();
    today.setHours(0,0,0,0);
    const tomorrow = date ? new Date(date) : new Date();
    tomorrow.setHours(23,59,59,999);
    
    const querySettings = {
        'subCompetitions': parentCompetitionId ? {
            params: [
                where('meta.parentCompetitionRef', '==', parentCompetitionId)
            ],
            max: 20, 
            collectionName: 'competitions',
            classType: Competition

        } : undefined, 
        'groupCompetitions': groupId ? {
            params: [
                where('meta.groupRef', '==', groupId), 
                where('meta.date', '>=', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'asc')  
            ],
            max: 20, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'recentGroupCompetitions': groupId ? {
            params: [
                where('meta.groupRef', '==', groupId), 
                where('meta.date', '<', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'desc')    
            ],
            max: 2,
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'recentCompetitions': userId ? {
            params: [
                where('meta.recipients', 'array-contains', userId), 
                where('meta.date', '<', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'desc')    
            ],
            max: 2,
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'clubCompetitions': clubId ? {
            params: isClubAdmin ? [
                where('meta.clubRef', '==', clubId), 
                where('meta.date', '>=', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'asc')    
            ] : [
                where('meta.clubRef', '==', clubId), 
                where('meta.date', '>=', today),
                where('meta.public', '==', true),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'asc')
            ], 
            max: 20, 
            collectionName: 'competitions',
            classType: Competition
        } : undefined, 
        'recentClubCompetitions': clubId ? {
            params: [
                where('meta.clubRef', '==', clubId), 
                where('meta.date', '<', today),
                where('meta.parentCompetitionRef', '==', false),
                orderBy('meta.date', 'desc')    
            ],
            max: 2,
            collectionName: 'competitions',
            classType: Competition
        } : undefined,
        'clubGroups': clubId ? {
            params: isClubAdmin ? [
                where('meta.clubRef', '==', clubId),
                orderBy('meta.lastModifiedDate', 'desc')    

            ] : [
                where('meta.clubRef', '==', clubId), 
                where('meta.public', '==', true),
                orderBy('meta.lastModifiedDate', 'desc')
            ], 
            max: 20, 
            collectionName: 'groups',
            classType: Group
        } : undefined,
        'clubs': {
            params: region ? [
                where('meta.public', '==', true), 
                where('meta.region', '==', region),
                orderBy('meta.rating', 'desc')  
            ] : [
                where('meta.public', '==', true),
                orderBy('meta.rating', 'desc')
            ],
            max: 40, 
            collectionName: 'clubs',
            classType: Club
        },
    }

    return querySettings[queryType as keyof typeof querySettings];
}

