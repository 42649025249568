import React, { useState, useRef, useEffect } from 'react';
import { View, Image, ScrollView, Dimensions, Animated } from 'react-native';
import * as Linking from 'expo-linking';
import Constants from 'expo-constants'
import { Logo, Headline, Paper, useTheme, Section, Card, Icon, Pressable, Paragraph, Button } from 'components';
import { Slogan } from '../composites';
import { StringTable } from 'utils';
import { useModal } from '../actions';
import LoginSheet from '../actions/sheets/LoginSheet'

export default function LandingScreen(props: any) {

    const headerHeight = 54;
    const spacingBottom = 96;

    const theme = useTheme();
    const aspect = Dimensions.get('window').height / Dimensions.get('window').width;
    const scrollViewRef = useRef<any>();

    const [chevronVisible, setChevronVisible] = useState(true);
    const [applinkVisible, setApplinkVisible] = useState(true);
    const chevronOpacity = useRef(new Animated.Value(1)).current;
    const applinkOpacity = useRef(new Animated.Value(1)).current;

    function onScroll(e: any) {
        setChevronVisible(e.nativeEvent.contentOffset.y > 0 ? false : true);
        setApplinkVisible(e.nativeEvent.contentOffset.y > 100 ? false : true);
    }
    useEffect(() => {
        Animated.timing(chevronOpacity, {
            toValue: chevronVisible ? 1.0 : 0.0, 
            duration: 300,
            useNativeDriver: true
        }).start();
    }, [chevronVisible]);

    useEffect(() => {
        Animated.timing(applinkOpacity, {
            toValue: applinkVisible ? 1.0 : 0.0, 
            duration: 300,
            useNativeDriver: true
        }).start();
    }, [applinkVisible]);

    const { pushModal, popAllModals } = useModal();
    async function login(e: any) {
        await pushModal((
            <LoginSheet style={{flex: 1}}/>
        ), {...e, pageY: 0});    
        popAllModals();
    }

    return (
            <View style={{flex: 1}}>
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: theme["color-card"], padding: theme["spacing-small"], paddingHorizontal: theme["spacing-large"], paddingTop: Constants.statusBarHeight || theme["spacing-small"]}}>
                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <Logo/>
                        <Headline style={{marginLeft: theme["spacing-small"]}}>{'Padel iD'}</Headline>
                    </View>
                </View>

                <View style={{flex: 1, overflow: 'hidden'}}>
                    <Image source={require('../../../assets/pictures/2-unsplash.jpeg')} style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}/>

                    <ScrollView 
                        style={{flex: 1}} 
                        ref={scrollViewRef}
                        onScroll={onScroll}
                        scrollEventThrottle={1}
                        showsVerticalScrollIndicator={false}
                    >
                        <View style={{justifyContent: 'space-between', padding: theme["spacing-large"], height: Dimensions.get('window').height - headerHeight - spacingBottom}}>

                            <Animated.View style={{opacity: applinkOpacity, alignSelf: 'flex-end'}}>
                                <Section size={'S'}>
                                    <Button mode={'primary'} label={StringTable.getString('Log in')} onPress={login}/>
                                </Section>

                                <Section size={'L'}>
                                </Section>
                                <Section size={'S'}>
                                    <Pressable onPress={() => Linking.openURL('https://apps.apple.com/pt/app/padel-id/id1630485168')}>
                                        <Image source={require('../../../assets/fonts/utilityIcons/handmade/ico_appstore.svg')} style={{width: 180, height: 180/3.406}}/>
                                    </Pressable>
                                </Section>
                                <Section size={'S'}>
                                    <Pressable onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.staffanklashed.padelid')}>
                                        <Image source={require('../../../assets/fonts/utilityIcons/handmade/ico_googleplay.svg')} style={{width: 180, height: 180/3.406}}/>
                                    </Pressable>
                                </Section>

                            </Animated.View>

                            <Animated.View style={{opacity: chevronOpacity}}>
                                <Paper style={{backgroundColor: 'transparent'}}>
                                    <Slogan/>
                                </Paper>
                            </Animated.View>
                        </View>

                        <Section style={{backgroundColor: theme["color-card"]}}>
                            <Animated.View style={{opacity: chevronOpacity, justifyContent: 'flex-start', alignItems: 'center', height: spacingBottom}}>
                                <Pressable onPress={() => scrollViewRef.current.scrollTo({x: 0, y: 800, animated: true})}>
                                    <Icon name={'ico_chevrondown'} size={64} color={theme["color-ink-soft"]}/>
                                </Pressable>
                            </Animated.View>

                            <View style={{alignItems: 'center'}}>
                                <Animated.View style={{maxWidth: 600, paddingBottom: 64, opacity: chevronOpacity.interpolate({inputRange: [0, 1], outputRange: [1, 0]})}}>
                                    <Headline style={{textAlign: 'center'}}>{StringTable.getString('Join the club')}</Headline>
                                    <Section>
                                        <Paper style={{backgroundColor: 'transparent'}}>
                                            <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                                {StringTable.getString('Join the club pitch')}
                                            </Paragraph>
                                        </Paper>
                                    </Section>
                                </Animated.View>
                            </View>
                        </Section>

                        <Section>
                            <Paper style={{backgroundColor: 'transparent', flexDirection: aspect < 1 ? 'row' : 'column'}}>
                                <Card style={{flex: 1, margin: theme["spacing-large"]}}>
                                    <Headline style={{alignSelf: 'center'}}>{'Plan'}</Headline>
                                    <Section size={'M'}>
                                        <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                            {StringTable.getString('Plan pitch')}                                        
                                        </Paragraph>
                                    </Section>
                                </Card>
                                <Card style={{flex: 1, margin: theme["spacing-large"]}}>
                                    <Headline style={{alignSelf: 'center'}}>{'Play'}</Headline>
                                    <Section size={'M'}>
                                        <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                            {StringTable.getString('Play pitch')}                                        
                                        </Paragraph>
                                    </Section>
                                </Card>
                                <Card style={{flex: 1, margin: theme["spacing-large"]}}>
                                    <Headline style={{alignSelf: 'center'}}>{'Rank'}</Headline>
                                    <Section size={'M'}>
                                        <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                            {StringTable.getString('Rank pitch')}                                        
                                        </Paragraph>
                                    </Section>
                                </Card>
                            </Paper>
                        </Section>

                        <Section size={'XL'} style={{backgroundColor: theme["color-card"]}}>
                            <View style={{alignItems: 'center'}}>
                                <View style={{maxWidth: 600, paddingVertical: 64}}>                                    
                                    <Headline style={{textAlign: 'center'}}>{StringTable.getString('Get the app')}</Headline>
                                    <Section style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                                        <Section size={'S'}>
                                            <Pressable onPress={() => Linking.openURL('https://apps.apple.com/pt/app/padel-id/id1630485168')}>
                                                <Image source={require('../../../assets/fonts/utilityIcons/handmade/ico_appstore.svg')} style={{width: 180, height: 180/3.406}}/>
                                            </Pressable>
                                        </Section>
                                            <Section size={'S'}>
                                                <Pressable onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.staffanklashed.padelid')}>
                                                    <Image source={require('../../../assets/fonts/utilityIcons/handmade/ico_googleplay.svg')} style={{width: 180, height: 180/3.406}}/>
                                                </Pressable>
                                            </Section>
                                    </Section>
                                </View>
                                <View style={{maxWidth: 600, paddingVertical: 64}}>
                                    <Headline style={{textAlign: 'center'}}>{StringTable.getString('Get in touch')}</Headline>
                                    <Paper style={{backgroundColor: 'transparent'}}>
                                        <Section style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                                            <Icon name={'ico_location'} size={24} color={theme["color-ink-soft"]}/>
                                            <View style={{marginTop: -4, marginLeft: theme["spacing-large"]}}>
                                            <Paragraph weight={'book'} style={{fontSize: 20, lineHeight: 26}}>
                                                {'Bonäsvägen 33'}
                                            </Paragraph>
                                            <Paragraph weight={'book'} style={{fontSize: 20, lineHeight: 26}}>
                                                {'605 60 Svärtinge'}
                                            </Paragraph>
                                            </View>
                                        </Section>
                                        <Section style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                                            <Icon name={'ico_oldphone'} size={24} color={theme["color-ink-soft"]}/>
                                            <View style={{marginTop: -4, marginLeft: theme["spacing-large"]}}>
                                            <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                                {StringTable.getString('+46-704-945000')}
                                            </Paragraph>
                                            </View>
                                        </Section>
                                        <Section style={{flexDirection: 'row', alignItems: 'flex-start'}}>
                                            <Icon name={'ico_at'} size={24} color={theme["color-ink-soft"]}/>
                                            <View style={{marginTop: -4, marginLeft: theme["spacing-large"]}}>
                                            <Paragraph weight={'book'} style={{textAlign: 'center', fontSize: 20, lineHeight: 26}}>
                                                {StringTable.getString('hello@padel.id')}
                                            </Paragraph>
                                            </View>
                                        </Section>
                                    </Paper>
                                </View>
                            </View>
                        </Section>

                        <View style={{height: 100}}/>

                        <View style={{paddingVertical: 60, backgroundColor: theme["color-card"]}}>
                            <Section style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                                <a href={'/terms'} style={{textDecoration: 'none'}}>
                                    <Paragraph weight={'book'}>{StringTable.getString('Terms of use')}</Paragraph>
                                </a>
                                <a href={'/privacy'} style={{textDecoration: 'none'}}>
                                    <Paragraph weight={'book'}>{StringTable.getString('Privacy policy')}</Paragraph>
                                </a>
                            </Section>
                        </View>

                    </ScrollView>
                </View>
            </View>
    )
}



/*
<ScrollView style={{flex: 1}} contentContainerStyle={{backgroundColor: 'red', paddingTop: width * aspect - (81 + theme["spacing-large"])}}>
                        <Slogan/>
                        <Section style={{flexDirection: 'row'}}>
                            <View style={{flex: 1}}>
                                <Headline>{'Plan'}</Headline>
                                <Section size={'M'}>
                                    <Paragraph>{'Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  '}</Paragraph>
                                </Section>
                            </View>
                            <View style={{flex: 1}}>
                                <Headline>{'Play'}</Headline>
                                <Section size={'M'}>
                                    <Paragraph>{'Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  '}</Paragraph>
                                </Section>
                            </View>
                            <View style={{flex: 1}}>
                                <Headline>{'Rank'}</Headline>
                                <Section size={'M'}>
                                    <Paragraph>{'Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  Lorem ipsum  '}</Paragraph>
                                </Section>
                            </View>
                        </Section>
                    </ScrollView>

*/
