import React, { useEffect } from 'react';
import { View, Image, Dimensions, TouchableOpacity } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useTheme, GradientView, Subheader } from 'components';
import { StringTable } from 'utils';
import { useModal } from '../actions';
import LoginSheet from '../actions/sheets/LoginSheet';
import { useRootNavigatorContext } from '../navigation';

export default function LoginScreen(props: any) {

    const { setHideTabBar } = useRootNavigatorContext();

    useFocusEffect(
        React.useCallback(() => {
            setHideTabBar(true);
            return () => {
                setHideTabBar(false);
            };
        }, [])
    );

    const theme = useTheme();

    const { pushModal, popAllModals } = useModal();
    async function login(e: any) {
        await pushModal((
            <LoginSheet style={{ flex: 1 }} />
        ), { ...e, pageY: 0 });

        popAllModals();
    }
    return (
        <GradientView style={{ flex: 1 }} startColor={theme["color-neutral"]} endColor={theme["color-negative"]}>
            <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Image source={require('../../../assets/tinysplash.png')} style={{ width: 110, height: 112 }} />
                <Subheader style={{ color: 'white' }}>{StringTable.getString('Padel iD')}</Subheader>
                <TouchableOpacity style={{ marginTop: 150, backgroundColor: 'white', borderRadius: theme["roundness-sharp"], padding: theme["spacing-medium"], paddingHorizontal: theme["spacing-large"] }} onPress={login}>
                    <Subheader style={{ color: theme["color-negative"] }}>{StringTable.getString('Sign in')}</Subheader>
                </TouchableOpacity>
            </View>
        </GradientView>
    )
}
