import React from 'react';
import { View } from 'react-native';
import { useTheme, Icon, Caption, Padel, Pressable } from 'components';
import { useUser, useStyles, useIsAdmin } from '../hooks';
import PlayerName from './PlayerName';
import { IPressEvent } from 'interfaces';
import { UserIdentity } from 'business';

interface UserCardProps {
    userRef: string,
    userIdentity?: UserIdentity,
    style?: any, 
    verbose?: boolean,
    onPress?: (e: IPressEvent, userRef: string) => any,
    onLayout?: any,
    caption?: string,
    showPortrait?: boolean,
    children?: any
}

const UserCard = React.memo((props : UserCardProps) => {
    const {
        userRef, 
        userIdentity,
        style,
        verbose,
        onPress,
        onLayout,
        caption,
        showPortrait,
        children
    } = props;
    
    const user = useUser(userIdentity ? undefined : userRef, false, userIdentity);
    const styles = useStyles();

    return user === undefined ? null : ( onPress ? (
        <Pressable style={[styles.UserCard, style]} onPress={(e: IPressEvent) => onPress?.(e, userRef)} onLayout={onLayout}>
            <MPlayerView
                id={userRef}
                imageUrl={user?.imageUrl}
                title={user?.getTitle() || userRef}
                rating={user?.rating || 1000 }
                registered={user?.registered}
                verbose={verbose}
                caption={caption}
                showPortrait={showPortrait}
            >
                {children}
            </MPlayerView>                        
        </Pressable>
    ) : (
        <View style={[styles.UserCard, style]} onLayout={onLayout}>
            <MPlayerView
                id={userRef}
                imageUrl={user?.imageUrl}
                title={user?.getTitle() || userRef}
                rating={user?.rating || 1000 }
                registered={user?.registered}
                verbose={verbose}
                caption={caption}
                showPortrait={showPortrait}
            >
                {children}
            </MPlayerView>                        
        </View>            
    ))
});

export default UserCard;

interface MPlayerView {
    id: string, 
    imageUrl?: string, 
    title: string, 
    rating: number,
    registered?: boolean,
    caption?: string,
    verbose?: boolean,
    showPortrait?: boolean,
    children?: any
}

MPlayerView.defaultProps = {
    showPortrait: true
}
function MPlayerView(props : MPlayerView) {

    const {
        imageUrl,
        title, 
        id, 
        rating, 
        registered,
        caption,
        verbose,
        showPortrait,
        children
    } = props;

    const theme = useTheme();
    const padelProps = {
        strokeWidth: 2, 
        strokeColor: theme["color-card"], 
    };

    const isAdmin = useIsAdmin(id);
    const redactedId = id.startsWith('+') ? `${id?.slice(0,6)}***${id?.slice(-3)}` : id;
    
    return(
        <View style={{flexDirection: 'row'}}>
            {showPortrait ? <Padel size={'M'} imageBackground={imageUrl} showLogo={imageUrl ? false : true} style={{marginRight: theme["spacing-small"]}} {...padelProps}/> : null }
            <View style={{flex: 1, justifyContent: 'center'}}>
                <PlayerName
                    verbose={verbose}
                    registered={registered}
                    title={title}
                    rating={rating}
                    isAdmin={isAdmin}
                />
                { caption ? (
                    <Caption style={{color: theme["color-ink-soft"]}}>{caption}</Caption>
                ) : ( verbose ? (
                    <View style={{flexDirection: 'row'}}>
                        <View style={{flexDirection: 'row', alignItems: 'center'}}>
                            <Icon name={'ico_oldphone'} size={12} color={theme["color-ink-soft"]}/>
                            <Caption style={{marginRight: theme["spacing-small"], color: theme["color-ink-soft"]}}>{redactedId}</Caption>
                        </View>
                    </View>
                ) : null )}
            </View>
            {children}
        </View>
    )
};
