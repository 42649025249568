import React from 'react';
import { View } from 'react-native';
import Icon from './helpers/Icon';
import Pressable from './helpers/Pressable';
import Paragraph from './typography/Paragraph';
import useTheme from './theme/useTheme';
import useThemeStyle from './theme/useThemeStyle';

export interface StickerProps {
    icon?: string, 
    label?: string,
    style?: any,
    startColor?: string, 
    endColor?: string, 
    onPress?: any
}

export default function Sticker(props: StickerProps) {

    const {
        icon, 
        label,
        style : styleProp,
        startColor : startColorProp, 
        endColor : endColorProp,
        onPress
    } = props;

    const theme = useTheme();
    //@ts-ignore
    const {penColor, iconSize, ...style} = useThemeStyle('Sticker', props);

    const startColor = startColorProp || theme["color-ink-negative"];
    const endColor = endColorProp || theme["color-ink-neutral"]

    return onPress ? (
        <Pressable style={[style, styleProp, {backgroundColor: startColor}]} onPress={onPress}>
            {icon ? <Icon name={icon} color={penColor} size={iconSize}/> : null }
            <Paragraph weight={'book'} numberOfLines={1} style={{zIndex: 2, elevation: 2, marginLeft: icon ? theme["spacing-extrasmall"] : 0, color: penColor}}>{label}</Paragraph>
        </Pressable>
    ) : (
        <View style={[style, styleProp, {backgroundColor: startColor}]}>
            {icon ? <Icon name={icon} color={penColor} size={iconSize}/> : null }
            <Paragraph weight={'book'} numberOfLines={1} style={{zIndex: 2, elevation: 2, marginLeft: icon ? theme["spacing-extrasmall"] : 0, color: penColor}}>{label}</Paragraph>
        </View>
    )
}
