import React from 'react';
import { View } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { Paragraph, Button, Section, Subheader } from 'components';
import useSheetStyle from './useSheetStyle';
import { useUser, useStyles, useIsAdmin } from '../../hooks';
import { Competition, Group, Match, SuperSchedule, swapPlayer } from 'business';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import ContactsListSheet from './ContactsSheet/ContactsListSheet';
import ConfirmSheet from './ConfirmSheet';
import { useAppContext } from '../../appcontext';
import UserSheet from './UserSheet';
import UserMenuPhoneNumberSheet from './UserMenuPhoneNumberSheet';
import SavingMessageSheet from './SavingMessageSheet';
import MessageSheet from './MessageSheet';
import { Factory } from '../../../factories';
import useModal from '../useModal';
import { createProvisionalUsers } from 'bridge';

interface Props {
    userRef: string, 
    targetCompetition?: Competition, 
    targetGroup?: Group,
    targetSchedule?: SuperSchedule,
    targetMatchId?: string,
    resolve?: () => any
}

export default function UserMenuSheet(props: Props) {

    const { userRef, targetCompetition, targetSchedule, targetGroup, targetMatchId, resolve } = props;

    const { currentUserId } = useAppContext();
    const user = useUser(userRef);
    const isAdmin = useIsAdmin(currentUserId);
    const userIsAdmin = useIsAdmin(userRef);
    const styles = useStyles();
    const sheetStyle = useSheetStyle();
    const { pushModal, popAllModals } = useModal();

    const userIsPlayer = targetCompetition?.participation.isPlayer(userRef);
    const userIsReserve = targetCompetition?.participation.isReserve(userRef);
    const userIsInvited = targetCompetition?.participation.isInvited(userRef);
    const userIsMember = !targetCompetition && targetGroup?.participation.isMember(userRef);
    const isTeamSignup = targetCompetition?.settings.teamSignup;
    const isFull = targetCompetition?.isFull();
    const isStarted = targetCompetition?.state.started || false;
    const isScheduled = targetCompetition?.state.scheduled;
    const isFixedTeams = targetCompetition?.settings.fixedTeams;
    const isMultiGroup = (targetCompetition?.groups?.length || 1) > 1 ? true : false;

    const matchHasResult = targetMatchId && targetCompetition?.getMatch(targetMatchId)?.hasResult() || false;
    const matchIsPlayoff = targetMatchId && targetCompetition?.getMatch(targetMatchId)?.playoffRound ? true : false;
    const userHasNumber = user?.hasNumber() || false;

    let modifiedMatches : Match[] | undefined = undefined;
    
    function renderAdminButtons() {
        return(
            <>
                {userHasNumber ? (<Button style={styles.menuButton} icon={'ico_oldphone'} label={`${StringTable.getString('Copy number')}`} onPress={onPressCopyNumber}/>) : null}
                { !isScheduled && (userIsReserve || userIsInvited) ? <Button style={styles.menuButton} label={StringTable.getString(isTeamSignup && userIsReserve ? 'Register as players' : 'Register as player')} icon={'ico_arrowup'} onPress={onPressRegisterPlayer}/> : null }
                { userHasNumber && (targetCompetition || targetGroup) ? <Button style={styles.menuButton} label={StringTable.getString(userIsAdmin ? 'Revoke admin' : 'Make admin')} icon={'ico_settings'} onPress={userIsAdmin ? onPressRevokeAdmin : onPressMakeAdmin}/> : null }
                { (targetCompetition && (isFixedTeams || !matchHasResult)) && (userIsPlayer || userIsReserve) ? <Button style={styles.menuButton} icon={'ico_repeat'} label={StringTable.getString('Swap')} onPress={onPressSwap}/> : null}
                { !userHasNumber ? <Button style={styles.menuButton} icon={'ico_oldphone'} label={StringTable.getString('Register phone number')} onPress={onPressRegisterPhone}/> : null }
                { (userIsPlayer || userIsReserve) && !isScheduled ? <Button style={styles.menuButton} icon={'ico_clear'} label={StringTable.getString(isTeamSignup ? 'Unregister players' : 'Unregister player')} onPress={onPressRemove} mode={'negative'}/> : null }
                { userIsMember ? <Button style={styles.menuButton} icon={'ico_clear'} label={StringTable.getString('Remove group member')} onPress={onPressRemove} mode={'negative'}/> : null }

            </>
        )
    }

    async function onPressCopyNumber() {
        await Clipboard.setStringAsync(userRef);
        resolve?.();
    }
    async function onPressRegisterPlayer() {
        const partnerRef = isTeamSignup && targetCompetition?.participation.getPartner(userRef);
        targetCompetition?.add(partnerRef ? [userRef, partnerRef] : [userRef]);
        resolve?.();
    }

    async function onPressMakeAdmin() {
        if (targetCompetition) { 
            const competitions = await Factory.getCompetitions(targetCompetition);
            if (competitions) {
                for (const competition of competitions) {
                    competition.makeAdmin(userRef);
                }    
            }
            else {
                console.log('error making admin, no competitions');
            }
        }
        else if (targetGroup) {
            targetGroup?.makeAdmin(userRef);
        }
        resolve?.();
    }

    async function onPressRevokeAdmin() {
        if (targetCompetition) { 
            const competitions = await Factory.getCompetitions(targetCompetition);
            if (competitions) {
                for (const competition of competitions) {
                    competition.revokeAdmin(userRef);
                }    
            }
            else {
                console.log('error making admin, no competitions');
            }
        }
        else if (targetGroup) {
            targetGroup?.revokeAdmin(userRef);
        }
        resolve?.();
    }

    async function onPressSwap(e: IPressEvent) {
        if (!targetCompetition) {
            resolve?.();
            return;
        }

        const contactsResult: ContactData[] | null = await pushModal((
            <ContactsListSheet
                style={{ flex: 1 }}
                headlineLabel={StringTable.getString('Swap player')}
                doneLabel={StringTable.getString('Done')}
                extraContacts={targetCompetition?.participation?.getAllParties()}
                filterContacts={[userRef]}
                filterMessage={StringTable.getString('Swapping this player')}
                singleSelect={true}
                requireSelected={true}
                showVacancy={true}
            />
        ), { ...e, pageY: 0 });

        if (contactsResult) {
            const confirmResult: IConfirmSheetResult = await pushModal((
                <ConfirmSheet
                    title={StringTable.getString('Confirm swap')}
                    doneLabel={StringTable.getString('Swap')}
                    doneMode={'primary'}
                >
                    <Section>
                        <Subheader>{targetCompetition.getTitle()}</Subheader>
                    </Section>
                    <Section>
                        <Paragraph>{`${StringTable.getString('Swap')} ${user?.getTitle() || userRef} ${StringTable.getString('with')} ${contactsResult[0].title}?`}</Paragraph>
                    </Section>
                </ConfirmSheet>
            ), e);

            if (confirmResult?.confirmed) {
                createProvisionalUsers(contactsResult.map(contact => ({ ...contact, region: targetCompetition.getRegion() })));

                swapPlayer(
                    targetCompetition, 
                    userRef, 
                    contactsResult[0].id, 
                    targetSchedule || null, 
                    { swapFromPlayoffMatchView: matchIsPlayoff }
                );
            }
        }

        resolve?.();
    }

    async function onPressRemove(e: IPressEvent) {
        const confirmResult : IConfirmSheetResult = await pushModal((
            <ConfirmSheet
                title={StringTable.getString(`Confirm ${targetCompetition ? (isTeamSignup ? 'unregister players' : 'unregister player') : 'remove group member'}`)}
                doneLabel={StringTable.getString('Unregister')}
            >
                <Section>
                    <Subheader>{targetCompetition?.getTitle() || targetGroup?.getTitle()}</Subheader>
                </Section>
            </ConfirmSheet>
        ), e);

        if (confirmResult?.confirmed) {
            (targetCompetition || targetGroup)?.leave(userRef);
        }
        resolve?.();
    }

    async function onPressRegisterPhone(e: IPressEvent) {
        const number : string = await pushModal((
            <UserMenuPhoneNumberSheet
                title={`${StringTable.getString('Phone for')} ${user?.getTitle() || userRef}`}
                doneLabel={StringTable.getString('Register')}
                style={{flex: 1}}
            />
        ), {...e, pageY: 0});

        if (number) {
            if (targetCompetition?.participation.userRefs?.includes(number)) {
                await pushModal((
                    <MessageSheet
                        showConfirmButton={true}
                        confirmButtonLabel={StringTable.getString('OK')}
                    >
                        <Paragraph>{number}</Paragraph>
                        <Paragraph>{StringTable.getString('This number belongs to another player in the event')}</Paragraph>
                    </MessageSheet>
                ), e);
                popAllModals();
            }
            else { 
                pushModal((<SavingMessageSheet title={StringTable.getString('Registering user')}/>), e);
                createProvisionalUsers([{id: number, title: user?.getTitle() || userRef, region: targetCompetition?.getRegion()}]);

                if (targetCompetition) {
                    const modifiedMatches = targetCompetition._swapPlayer(targetCompetition.getAllMatches(), userRef, number, { updateTables: true });
                    targetSchedule && modifiedMatches?.length && targetSchedule.replaceSomeMatchesInCompetition(targetCompetition.id, modifiedMatches.map(m => m.serialize()));
                }
            }
        }
        resolve?.();
    }

    return(
        <View style={[sheetStyle]}>
            <Subheader style={{alignSelf: 'center'}}>{user?.getTitle() || StringTable.getString('User')}</Subheader>
            <Section>
                { userHasNumber ? (
                    <Button 
                        style={styles.menuButton} 
                        icon={'ico_person'} 
                        label={`${StringTable.getString('View')} ${user?.getTitle()}`} 
                        onPress={async(e: any) => {
                            await pushModal((
                                <UserSheet userId={userRef} style={{flex: 1}}/>
                            ), { ...e, pageY: 0});
                            resolve?.();
                        }}
                    />
                ) : (
                    <View style={{alignItems: 'center'}}>
                        <Paragraph>{StringTable.getString('User not registered with Padel iD')}</Paragraph>
                    </View>
                )}
                {isAdmin ? renderAdminButtons() : null }
            </Section>
        </View>
    )
}
