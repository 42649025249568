import { Location } from './Location';
import { IClubSettings } from '../interfaces';

export class ClubSettings implements IClubSettings {

    title?: string;
    byline?: string;
    imageUrl?: string;
    description?: string;
    location?: Location;
    hasCalendar?: boolean;
    phoneNumber?: string;
    public?: boolean;


    constructor(data: IClubSettings) {

        this.title = data.title;
        this.byline = data.byline;
        this.description = data.description;
        this.imageUrl = data.imageUrl;
        this.phoneNumber = data.phoneNumber;
        this.public = data.public;
        this.hasCalendar = data.hasCalendar;
        this.location = data.location && new Location(data.location);
    }

    serialize() : IClubSettings {
        return {
            title: this.title, 
            byline: this.byline, 
            description: this.description, 
            imageUrl: this.imageUrl,
            phoneNumber: this.phoneNumber, 
            public: this.public,
            hasCalendar: this.hasCalendar,
            location: this.location?.serialize()
        }
    }

    update(data: IClubSettings) {
        Object.keys(data).forEach(key => {
            //@ts-ignore
            if (typeof this[key] !== 'function') {
                if (key === "location") {
                    this.location = new Location(data.location);
                }
                else {
                    //@ts-ignore
                    this[key] = data[key];
                }
            }
        })
    }

    getTitle() {
        return this.title || '';
    }

    getByline() {
        return this.byline || '';
    }

    setPublic(flag: boolean) {
        this.public = flag;
    }
}
