import { createContext } from 'react';

interface IModalContext {
    pushModal: (element: React.ReactElement, pressEvent?: any) => Promise<any>;
    popModal: () => void;
    popAllModals: () => void;
}

const ModalContext = createContext<IModalContext>({
    pushModal: () => Promise.resolve(),
    popModal: () => {},
    popAllModals: () => {}
});

export default ModalContext;