import { useContext } from 'react';
import { User } from 'business';
import AppContext from './AppContext';
import { IRegion } from 'interfaces';

interface IAppContext {
    lastValidPinCode?: string, 
    setLastValidPinCode: (code: string) => any,
    currentUser?: User | null,
    currentUserId?: string,
    deviceType?: any, 
    regions?: IRegion[], 
    locale?: any,
    locales?: any, 
    latestVersion?: string,
    notificationPermission?: any,
    requestNotificationPermission?: any,
    canRequestNotificationPermission?: any,
    tutorials?: {title: string, url: string}[],
    setError?: any,
    offline?: boolean,
    login: (phoneNumber: string, pinCode: string) => Promise<void>,
    logout: () => Promise<any>,
    startTime: { current: number },
    setStartTime: (n: number) => void,
    simpleRendering: boolean, 
    setSimpleRendering: any,
    target: IActionTarget | null,
    setTarget: React.Dispatch<React.SetStateAction<IActionTarget | null>>,
    preventOnboarding: boolean, 
    setPreventOnboarding: React.Dispatch<React.SetStateAction<boolean>>,
    changePinCode: (chosenPinCode: string) => Promise<void>,
    deleteUser: () => Promise<any>
}

export default function useAppContext() : IAppContext {

    const context : IAppContext = useContext<IAppContext>(AppContext);
    return context;
}