import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { useTheme, Button, Section, Subheader, Pressable, TextInputPhone } from 'components';
import { StringTable } from 'utils';
import { IPressEvent, Locale } from 'interfaces';
import useSheetStyle from './useSheetStyle';
import LocalesSheet from './LocalesSheet';
import { useAppContext } from '../../appcontext';
import useModal from '../useModal';

interface Props {
    title: string,
    cancelLabel: string,
    doneLabel: string,
    children?: any,
    style?: any,
    resolve?: (completePhoneNumber: string | null ) => void
}

UserMenuPhoneNumberSheet.defaultProps = {
    cancelLabel: StringTable.getString('Cancel'),
}
export default function UserMenuPhoneNumberSheet(props: Props) {

    const {
        title,
        cancelLabel,
        doneLabel,
        resolve,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    const { pushModal, popModal } = useModal();
    const [digits, setDigits] = useState('');

    const { locale : systemLocale, locales } = useAppContext();
    const [locale, setLocale] = useState(systemLocale);
    useEffect(() => {
        setLocale(systemLocale);
    }, [systemLocale])

    function handleResolve(e: IPressEvent) {
        const digitsTrimmed = digits.replace(/^0+/, '');
        resolve?.(digitsTrimmed ? `${locale.dialCode}${digitsTrimmed}` : null);
    }

    return (
        <View style={[sheetStyle, style]}>
            {title ? (
                <Subheader style={{alignSelf: 'center'}}>{title}</Subheader>
            ): null }

            <Section size={'M'}>
                <Pressable>
                    <TextInputPhone
                        onPressLocale={async (e: IPressEvent) => {
                            const newLocale : Locale | null = await pushModal((
                                <LocalesSheet
                                    locales={locales}
                                    selectedLocale={locale}
                                    style={{flex: 1}}
                                />
                            ), {...e, pageY: 0});

                            newLocale && setLocale(newLocale);
                            popModal();
                        }}
                        locale={locale}
                        locales={locales}
                        digits={digits}
                        onChangeDigits={(t: string) => setDigits(t)}
                        onChangeLocale={(newLocale: Locale) => {
                            newLocale && setLocale(newLocale);
                        }}
                        editable={true}
                    />
                </Pressable>
            </Section>

            <Section size={'L'} style={{flexDirection: 'row'}}>
                <Button onPress={() => resolve && resolve(null)} mode={'outlined'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={cancelLabel}/>
                <Button onPress={handleResolve} mode={'primary'} style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} label={doneLabel}/>
            </Section>            
        </View>
    )
}
