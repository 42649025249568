import React from 'react';
import { IPressEvent } from 'interfaces';
import { Paragraph } from 'components';
import ConfirmSheet from './sheets/ConfirmSheet';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import { StringTable } from 'utils';
import { Factory } from '../../factories';

export default async function unscheduleTarget(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {
    const competition = target?.competition;
    if (!competition) { return }    
    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet title={StringTable.getString('Confirm clear schedule')} doneLabel={StringTable.getString('Clear')} doneMode={'dangerous'}>
            <Paragraph>{StringTable.getString('This action is irreversible and will erase all match results')}</Paragraph>
        </ConfirmSheet>)
    , e);

    pushModal((<SavingMessageSheet title={StringTable.getString('Clearing schedule')}/>), e);        
    confirmResult?.confirmed && await Factory.clearSuperSchedule(competition);
    popAllModals();

}
