import React from 'react';
import { View, FlatList } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useTheme } from 'components';
import { useClubs, useStyles, useLayout } from '../hooks';
import { TabHeader, ClubCard, SectionHeader } from '../composites';
import { StringTable } from 'utils';
import { NavigationProvider } from '../navigation';
import { useAppContext } from '../appcontext';
import { useActions } from '../actions';

export default function ClubsScreen(props: any) {

    const { currentUser } = useAppContext();
    const clubs = useClubs(currentUser?.settings?.region, currentUser?.settings?.clubRefs);

    const styles = useStyles();
    const theme = useTheme();
    const { columns } = useLayout();

    const { setTarget } = useActions();

    useFocusEffect(
        React.useCallback(() => {
            setTarget(null);
        }, [])
    );

    return(
        <NavigationProvider>
            <View style={styles.screen}>
                <TabHeader title={StringTable.getString('Clubs')}/>
                <View style={{flex: 1, marginTop: theme["spacing-medium"]}}>
                    <FlatList
                        data={clubs}
                        key={columns}
                        numColumns={columns}
                        onEndReachedThreshold={0.1}
                        keyExtractor={item => item.id}
                        ListHeaderComponent={(
                            <SectionHeader
                                header={StringTable.getString('Clubs')}
                                isAdmin={true}
                                isPrimary={true}
                                sectionId={'club'}
                            />
                        )}
                        renderItem={({item, index}) => (
                            <ClubCard club={item} style={columns === 2 ? styles.listItemColumns : styles.listItem}/>
                        )}
                    />
                </View>
            </View>
        </NavigationProvider>
    ) 
}
