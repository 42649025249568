export function createId(length: number) {
    let result = '';
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charsLength = chars.length;
    for ( var i = 0; i < length; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
}

export function createPin() {
    let result = '';
    const chars = '0123456789';
    var charsLength = chars.length;
    for ( var i = 0; i < 6; i++ ) {
        result += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return result;
}

export function getRightmostSetBit(n: number) {
    return Math.log2(n & -n);
}

export function getLeftmostSetBit(n: number) {
    return Math.floor(Math.log(n | 0) / Math.log(2));
}

//TODO: move value to before key and rename key to filterKey
export function arrayRemove(arr: any[] | undefined, key: string | null, value: any) {
    if (!arr) {
        return arr;
    }
    const index = arr.findIndex(e => key ? (e[key] === value) : (e === value));
    index >= 0 && arr.splice(index, 1);
    return [...arr];
}

export function arrayReplace(arr: any[], key: string, from: any, to: any) {
    if (!arr) {
        return arr;
    }
    const index = arr.findIndex(e => e[key] === from[key]);
    if (index >= 0) {
        arr[index] = to;
    }
    return [...arr];
}

export function arrayUnion(arr: any[] | undefined, value: any, filterKey?: string ) {
    if (!arr) {
        return Array.isArray(value) ? [...value] : [value];
    }
    return arrayUnique(Array.isArray(value) ? [...arr, ...value] : [...arr, value], filterKey)
}

//Also removed undefined entries
export function arrayUnique(arr: any[] | undefined, filterKey?: string) {
    if (!arr) {
        return;
    }
    return arr.reduce((acc, curr) => {
        if (filterKey && (curr !== undefined) && !acc.find((i: any) => (i && (typeof i === 'object')) ? i[filterKey] === curr[filterKey] : i === curr)) {
            acc.push(curr);
        }
        else if ((curr !== undefined) && !filterKey && !acc.includes(curr)) {
            acc.push(curr);
        }
        return acc;
    }, []);
}

export function arrayShuffle(array?: any[]) {
    if (!array) {
        return array;
    }
    
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
}

/**
 * Consider an array arr = ['a', 'b', 'c', 'd']. 
 * 
 * Moving from 1 to 3 in this array should mean to first remove item
 * 1 from the array, i.e. produce a temporary array arrTemp = ['a', 'c', 'd'] and
 * then to produce the final result insert the removed item into the 
 * temporary array i.e. arr2 = ['a', 'c', 'd', 'b'].
 * 
 * Moving from 3 to 1 in the original array should mean to first remove
 * item 3 from the array, i.e. produce a new array arrTemp = ['a', b', 'c'] and
 * then to produce the final result insert the removed item into the
 * temporary array i.e. arr2 = ['a', 'd', 'b', 'c']
 * @param arr 
 * @param from 
 * @param to 
 */
export function arrayMove(arr: any[], from: number, to: number) {
    if (!arr) {
        return arr;
    }
    let el = arr.splice(from, 1)[0];
    arr.splice(to, 0, el);
    return arr;
}

export function arraySwap(arr: any[], a: any, b: any) {
    if (!arr) {
        return arr;
    }
    let aIndex = arr.indexOf(a);
    let bIndex = arr.indexOf(b);

    if (aIndex >= 0 && bIndex >= 0) {
        arr[aIndex] = b;
        arr[bIndex] = a;
    }
    return arr;
}

export function arraySwapByIndex(arr: any[], aIndex: number, bIndex: number) {

    if (!arr) {
        return arr;
    }

    if (aIndex >= 0 && bIndex >= 0) {
        const temp = arr[aIndex];
        arr[aIndex] = arr[bIndex];
        arr[bIndex] = temp;
    }
    return arr;   
}

export function arrayIncludesAll(array?: any[], includes?: any[]) {
    return includes?.every(e => array?.includes(e));
}

export function arrayHasDuplicate(arr: (string | number | null)[]) {
    return new Set(arr).size !== arr.length;
}

export function arraysEqual(a: any, b: any) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
      
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

interface ArrayDiffResult<T> {
    removed: T[];
    added: T[];
}

export function arrayDiff<T, K extends keyof T>(
    oldArr: T[],
    newArr: T[],
    key?: K
): ArrayDiffResult<T> {
    const removed = oldArr.filter(
        oldItem =>
            !newArr.some(
                newItem => (key ? newItem[key] === oldItem[key] : newItem === oldItem)
            )
    );
    const added = newArr.filter(
        newItem =>
            !oldArr.some(
                oldItem => (key ? oldItem[key] === newItem[key] : oldItem === newItem)
            )
    );
    return { removed, added };
}

export function arrayRollingAverage(arr: number[], n: number) {

    n = Math.min(n, arr.length);

    if (n <= 0) {
        return [];
    }

    let rollingAverages: number[] = [];

    for (let i = n - 1; i < arr.length; i++) {
        let sum = 0;
        for (let j = 0; j < n; j++) {
            sum += arr[i - j];
        }
        let average = sum / n;
        rollingAverages.push(average);
    }

    return rollingAverages;
}

export function objectsEqual(a: any, b: any) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (Object.keys(a).length !== Object.keys(b).length) return false;
    
    for (const key of Object.keys(a)) {
        if (a[key] !== b[key]) return false;
    }
    return true;
}

export function arraysOverlap(a: any, b: any) {
    if (a === b) return true;
    if (!(Array.isArray(a) && Array.isArray(b))) return false;
    return a.filter(v => b.includes(v)).length ? true : false;
}

export function clamp(val: number, min: number, max: number) {
    return Math.max(Math.min(val, max), min);
}

export function capitalize(str: string, all?: boolean) {
    if (!str) { return str }
    if (typeof str !== 'string') {
        return str;
    }
    if (all) {
        return str.toUpperCase();
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function splitString(str: string) {
    if (!str) {
        return ['', ''];
    }

    const trimmedString = str.trim();

    const spacesAt : number[] = [];
    const SAFETY = 5;
    
    while (spacesAt.length < SAFETY && trimmedString.indexOf(' ', (spacesAt[spacesAt.length - 1] || -1) + 1) !== -1) {
        spacesAt.push(trimmedString.indexOf(' ', (spacesAt[spacesAt.length - 1] || -1) + 1));
    }

    spacesAt.sort((a, b) => {
        const aDistToCenter = Math.abs(a - trimmedString.length / 2);
        const bDistToCenter = Math.abs(b - trimmedString.length / 2);
        return aDistToCenter - bDistToCenter;
    });
    return spacesAt.length ? [trimmedString.slice(0, spacesAt[0]), trimmedString.slice(spacesAt[0] + 1)] : [trimmedString, ''];
}

export function dateCompare(a?: Date, b?: Date, time?: boolean) {
    if (!(a && b)) {
        return false;
    }

    if (!time) {
        return (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate());
    }

    return (a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate() && a.getHours() === b.getHours() && a.getMinutes() === b.getMinutes());
}

export function noUndefined(data: any) {
    function iterate(obj: any) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === undefined) {
                delete obj[key];
            }
            else if (Array.isArray(obj[key])) {
                //Iterate like so instead of forEach to catch empty items
                for (let i = 0; i < obj[key].length; i++) {
                    if (obj[key][i] === undefined) {
                        obj[key][i] = null;
                    }
                    else if (obj[key][i] !== null && typeof obj[key][i] === 'object') {
                        iterate(obj[key][i])                        
                    }
                }
            }
            else if (obj[key] !== null && typeof obj[key] === 'object') {
                iterate(obj[key]);
            }
        });
    }
    if (data && typeof data === 'object') {
        iterate(data);
    }
    return data;
}

const _daysOfWeek = { sunday: 0, monday: 1, tuesday: 2, wednesday: 3, thursday: 4, friday: 5, saturday: 6 }
function _getDay(date: Date, firstDay: string) {
    firstDay = (firstDay || 'monday').toLowerCase();
    return (date.getDay() + 7 - _daysOfWeek[firstDay as keyof typeof _daysOfWeek]) % 7;
}

export function getWeekOf(date: Date, firstDay: string) {
    const start = new Date(date.getTime());
    const dayOfWeek = _getDay(date, firstDay);
    start.setDate(start.getDate() - dayOfWeek);
    const end = new Date(start.getTime());
    end.setDate(end.getDate() + 6);
    return [start, end];
}

export function getPath(url: string) {
    if (!url) return '';
    const segments = url.split('/');

    let index = segments.length-1;
    while(index) {
        if (segments[index].includes('.') || segments[index].includes('localhost')) {
            break;
        }
        index--;
    }
    return segments.slice(index+1).join('/');
}


export function splitPhoneNumber(phoneNumber: string, locales: { [key: string]: any }) {

    const cleanPhoneNumber = phoneNumber.replace(/[\s\-\(\)#\*]/g, '');
    if (!validatePhoneNumber(cleanPhoneNumber, true)) {
        throw new Error('Phone number is not recognized');
    }

    const locale = cleanPhoneNumber.startsWith('+') && Object.values(locales || {}).find((locale) =>
        locale.dialCode && cleanPhoneNumber.startsWith(locale.dialCode)
    );

    let digits = locale?.dialCode ? cleanPhoneNumber.replace(locale.dialCode, '') : cleanPhoneNumber;
    digits = digits.startsWith('0') ? digits.slice(1) : digits;

    if (cleanPhoneNumber.startsWith('+') && !locale?.dialCode) {
        throw new Error('Country code is not recognized');
    }

    return { locale, digits };
}

export function composePhoneNumber(locale: any, digits: string) {
    if (!(locale && digits)) {
        return;
    }
    const { dialCode } = locale;
    const cleanedDigits = digits.replace(/^0+/, '');
    return dialCode + cleanedDigits;
}

export function validatePhoneNumber(phoneNumber?: string, allowNonInternational?: boolean) {
    if (!phoneNumber) {
        return false;
    }

    if (!allowNonInternational && phoneNumber.startsWith('+351') && phoneNumber.length !== 13) {
        return false;
    }
    if (!allowNonInternational && phoneNumber.startsWith('+46') && phoneNumber.length < 11) {
        return false;
    }

    const pattern = allowNonInternational ?  /^\+?\d+$/ : /^\+\d+$/;
    return pattern.test(phoneNumber);
}
