import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { LogoSquare, Subheader, Paragraph, Bullet, CachedImage, useTheme } from 'components';
import { useStyles } from '../hooks';
import { useNavigation } from '../navigation';
import { Club } from 'business';
import { StringTable } from 'utils';

interface Props {
    club: Club,
    style?: any,
    onPress?: any
}

export default function ClubCard(props: Props) {

    const { club, style, onPress } = props;
    const theme = useTheme();
    const styles = useStyles();
    const { navigate } = useNavigation();
    const averageReview = club?.getAverageReview();

    function handlePress(e: any) {
        if (onPress) {
            onPress(e);
        }
        else {
            navigate({clubId: club.id});
        }
    }

    return(
        <TouchableOpacity style={[styles.ClubCard, style, styles.row]} onPress={handlePress}>
            <View style={styles.ClubCardImage}>
                {club?.settings.imageUrl ? (
                    <CachedImage style={{width: '100%', height: '100%'}} source={{uri: club.settings.imageUrl}}/>
                ) : (
                    <LogoSquare style={{width: '100%', height: '100%'}}/>
                )}
            </View>
            <View style={[styles.ClubCardContent, {flex: 1, marginLeft: theme["spacing-medium"]}]}>
                <Subheader style={{width: '100%'}} numberOfLines={1}>{`${club?.settings.getTitle()}`}</Subheader>
                <Paragraph weight={'book'} style={{flex: 1}} numberOfLines={1}>{club?.settings.getByline()}</Paragraph>
                <Bullet size={'M'} style={{marginTop: 8, width: '70%'}} icon={'ico_star'} label={averageReview ? averageReview.toFixed(1) : StringTable.getString('No reviews')} color={theme['color-ink']}/>
            </View>
        </TouchableOpacity>
    )
}
