import React from 'react';
import { User } from 'business';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import ContactsListSheet from './sheets/ContactsSheet/ContactsListSheet';
import { Competition, Group, Club } from 'business';
import { validatePhoneNumber } from 'tools';

export default async function setAdmins(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any, currentUser?: User | null) {

    const club : Club | undefined = target?.club;
    const group : Group | undefined = target?.group;
    const competition : Competition | undefined = target?.competition;

    const currentAdmin = competition?.participation.admin || group?.participation.admin || club?.participation.admin;

    if (!currentAdmin?.length) {
        popAllModals();
        return;
    }

    if (!(currentUser?.id && currentAdmin.includes(currentUser?.id))) {
        popAllModals();
        return;
    }
    //@ts-ignore
    const result : ContactData[] | null = await pushModal((
        <ContactsListSheet
            style={{flex: 1}}
            headlineLabel={StringTable.getString('Set admins')} 
            doneLabel={StringTable.getString('Set')}
            extraContacts={currentAdmin}
            selected={currentAdmin}
        />
    ), {...e, pageY: 0});

    if (result?.length) {
        const target = competition || group || club;
        target?.makeAdmins(result.filter(contact => validatePhoneNumber(contact.id)).map(contact => contact.id));
    }
    popAllModals();
}
