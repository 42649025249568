import React, { useRef, useState, useEffect, ReactElement } from 'react';
import { View, Dimensions, Modal } from 'react-native';
import useTheme from './theme/useTheme';
import Pressable from './helpers/Pressable';

export interface PopupProps {
    visible: boolean,
    onDismiss: () => any,
    children?: any,
    style?: any
}

export default function Popup(props: PopupProps) {

    const {
        visible,
        onDismiss,
        children, 
        style
    } = props;

    const [popup, setPopup] = useState<ReactElement | null>(null);
    const theme = useTheme();
    const containerRef = useRef<any>();

    useEffect(() => {
        if (children && visible) {
            containerRef.current.measure((x: number, y: number, w: number, h: number, px: number, py: number) => {

                const spaceAbove = py;
                const spaceBelow = Dimensions.get('window').height - py - h;

                const placement = (spaceAbove > spaceBelow) ? {
                    left: px,
                    width: Dimensions.get('window').width - px - theme["spacing-large"],
                    bottom: Dimensions.get('window').height - py
                } : {
                    left: px,
                    width: Dimensions.get('window').width - px - theme["spacing-large"],
                    top: py + h + theme['spacing-extrasmall'],
                }

                setPopup(
                    <View style={[{position: 'absolute'}, placement]}>
                        <Pressable>
                            {children}
                        </Pressable>
                    </View>
                )
            });
        }
        else {
            setPopup(null);
        }
    }, [children, visible])

    return (
        <>
            <View 
                ref={containerRef} 
                pointerEvents={'none'} 
                style={[{
                    position: 'absolute', 
                    top: 0, 
                    bottom: 0, 
                    right: 0, 
                    left: 0,
                    opacity: 0
                }, style]}
            />
            <Modal visible={visible} transparent={true} animationType={'fade'}>
                <Pressable 
                    style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.5)'}}
                    onPress={onDismiss}
                >
                    {popup}
                </Pressable>
            </Modal>
        </>
    )
}
