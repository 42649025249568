import React from 'react';
import { View } from 'react-native';
import { Button, Section, Subheader } from 'components';
import useSheetStyle from './useSheetStyle';
import { StringTable } from 'utils';

interface RespondInvitationSheetProps {
    title: string,
    asReserve?: boolean,
    style?: any,
    resolve?: (data: IRespondInvitationResult | null ) => void
}

export default function RespondInvitationSheet(props: RespondInvitationSheetProps) {

    const {
        title,
        asReserve,
        resolve,
        style
    } = props;

    const sheetStyle = useSheetStyle();

    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader>{title}</Subheader> : null }

            <Button 
                icon={'ico_ball'}
                label={`${StringTable.getString(asReserve ? 'Accept as reserve' : 'Accept invitation')}`} 
                onPress={(e: any) => resolve && resolve({e, response: true})}
                mode={'primary'}
                style={{marginTop: 8}}
            />
            <Button 
                icon={'ico_clear'}
                label={StringTable.getString('Decline invitation')} 
                onPress={(e: any) => resolve && resolve({e, response: false})}
                mode={'negative'}
                style={{marginTop: 8}}
            />
            <Section/>
        </View>
    )
}

