import React from 'react';
import { IPressEvent } from 'interfaces';
import UserSheet from './sheets/UserSheet';

export default async function showUser(e: IPressEvent, userRef: string, pushModal: any, popAllModals: any) {
    await pushModal((
        <UserSheet
            userId={userRef}
            style={{flex: 1}}
        />
    ), {...e, pageY: 0});
    popAllModals();        
}
