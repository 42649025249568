import React from 'react';
import { IPressEvent } from 'interfaces';
import ConfirmSheet from './sheets/ConfirmSheet';
import { StringTable } from 'utils';

export default async function makeTargetPrivate(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const confirmResult : IConfirmSheetResult | null = await pushModal((
        <ConfirmSheet 
            title={StringTable.getString('Confirm make private')} 
            doneLabel={StringTable.getString('Make private')} 
            doneMode={'primary'}
        />), e);

    if (confirmResult?.confirmed) {
        if (target?.competition) {
            target.competition.setPublic(false);
        }
        else if (target?.group) {
            target.group.setPublic(false);
        }
    }
    popAllModals();
}
