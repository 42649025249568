import React from 'react';
import { View} from 'react-native';
import useThemeStyle from '../theme/useThemeStyle';

export interface DividerProps {
    style?: any
}

export default function Divider(props : DividerProps) {

    const {
        style : styleProp
    } = props;

    const style = useThemeStyle('Divider', props);
 
    return (
        <View style={[style, styleProp]}/>
    )
}
