import { Club, Competition, Group, Conversation, User, SuperSchedule } from 'business';
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { noUndefined } from 'tools';

export default async function createFromInstance(instance: Club | Competition | Group | Conversation | User | SuperSchedule, app?: any) {

    const collectionId = (instance instanceof Competition && "competitions") || (instance instanceof Club && "clubs") || (instance instanceof Group && "groups") || (instance instanceof Conversation && "conversations") || (instance instanceof User && "users") || (instance instanceof SuperSchedule && "schedules");
    if (!collectionId) {
        throw "createFromInstance error, bad instance type"
    }

    if (!instance.id) {
        throw "createfromInstance error, bad instance id"
    }

    const data = noUndefined(instance.serialize?.());
    const meta = noUndefined(instance.meta?.());

    try {
        const documentRef = doc(getFirestore(app), `${collectionId}/${instance.id}`);
        console.log('[setDoc]: createFromInstance')
        await setDoc(documentRef, { meta, data });
    }
    catch(err) {
        console.log('createFromInstance error ' + (app?._name || 'default app') + ': ' + collectionId + '/' + instance.id, err);
    }
    return 0;
}
