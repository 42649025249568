import React from 'react';
import { View } from 'react-native';
import { useTheme, Button, Section, Subheader } from 'components';
import { StringTable } from 'utils';
import { IPressEvent } from 'interfaces';
import useSheetStyle from './useSheetStyle';

interface ConfirmSheetProps {
    title: string,
    cancelLabel: string,
    requirePinCodeFor?: string, 
    doneLabel: string,
    doneMode?: string,
    children?: any,
    style?: any,
    resolve?: (data: IConfirmSheetResult | null ) => void
}

ConfirmSheet.defaultProps = {
    cancelLabel: StringTable.getString('Cancel'),
    doneMode: 'dangerous'
}
export default function ConfirmSheet(props: ConfirmSheetProps) {

    const {
        title,
        cancelLabel,
        doneLabel,
        doneMode,
        children,
        resolve,
        style
    } = props;

    const theme = useTheme();
    const sheetStyle = useSheetStyle();


    async function onPressDone(e: IPressEvent) {
        resolve && resolve({confirmed: true, e: e})
    }

    return (
        <View style={[sheetStyle, style]}>
            {title ? <Subheader style={{alignSelf: 'center'}}>{title}</Subheader> : null }

            {children}

            <Section size={'L'} style={{flexDirection: 'row'}}>
                <Button 
                    onPress={() => resolve && resolve(null)} 
                    mode={'outlined'} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={cancelLabel}
                />
                <Button 
                    onPress={onPressDone} 
                    mode={doneMode} 
                    style={{flex: 1, marginRight: theme["spacing-extrasmall"]}} 
                    label={doneLabel}
                />
            </Section>            
        </View>
    )
}
