import React from 'react';
import { IPressEvent } from 'interfaces';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import { StringTable } from 'utils';
import { Factory } from '../../factories';

export default async function shuffleCompetitionTeams(e: IPressEvent, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const competition = target?.competition;
    if (!competition) { return };
    pushModal((<SavingMessageSheet title={StringTable.getString('Shuffling teams')}/>), e);
    await Factory.makeSuperSchedule(competition, { shuffle: true });
    popAllModals();
}
