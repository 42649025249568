import React from 'react';
import { IPressEvent } from 'interfaces';
import UserMenuSheet from './sheets/UserMenuSheet';
import { Competition, Match } from 'business';

export default async function showUserMenu(
    e: IPressEvent, 
    userRef: string, 
    target: IActionTarget | null, 
    currentUserId: string | undefined, 
    pushModal: any, 
    popAllModals: any, 
    competition?: Competition, 
    matchId?: string
) {

    if (!currentUserId) {
        popAllModals();
        return;
    }

    if (!userRef) {
        popAllModals();
        return;
    }

    const targetSchedule = target?.schedule;

    await pushModal((
        <UserMenuSheet 
            userRef={userRef} 
            targetCompetition={competition} 
            targetGroup={target?.group} 
            targetSchedule={targetSchedule}
            targetMatchId={matchId}
        />
    ), e);
    popAllModals();
}
