import React, { useState, useEffect } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { getFunctions, httpsCallable } from "firebase/functions";
import { useTheme, Section, Subheader, TextInputCode, Paragraph } from 'components';
import { StringTable } from 'utils';
import useSheetStyle from '../useSheetStyle';
import CloseHeader from '../CloseHeader';
import PinCodeInput from '../../../composites/PinCodeInput';
import { useAppContext } from '../../../appcontext'

interface ChangePinCodeSheetProps {
    resolve?: (data: true | null) => void,
    style: any
}

export default function ChangePinCodeSheet(props: ChangePinCodeSheetProps) {

    const {
        resolve,
        style
    } = props;

    const { currentUser, setLastValidPinCode, changePinCode } = useAppContext();
    const theme = useTheme();
    const sheetStyle = useSheetStyle();
    
    const [oldPinCode, setOldPinCode] = useState('');
    const [verifyingOldPinCode, setVerifyingOldPinCode] = useState(false);
    const [oldPinCodeVerified, setOldPinCodeVerified] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        async function verify() {
            setVerifyingOldPinCode(true);
            const verifyPinCode = httpsCallable(getFunctions(), 'verifyPinCode');

            const response : any = await verifyPinCode({phoneNumber: currentUser?.id, pinCode: oldPinCode});
            if (response?.data && !response?.data.error) {
                setOldPinCodeVerified(true);
                setLastValidPinCode(oldPinCode);
                setVerifyingOldPinCode(false);
            }
            else {
                setOldPinCodeVerified(false);
                setVerifyingOldPinCode(false);
                setError(StringTable.getString('Wrong PIN code'));
            }
        }
        if (oldPinCode.length === 6) {
            verify();
        }
        else {
            setError('');
        }
    }, [oldPinCode]);

    return (
        <View style={[sheetStyle, style]}>
            <CloseHeader onDismiss={() => resolve && resolve(null)} title={StringTable.getString('Change PIN code')}/>

            <Section size={'L'} style={{alignItems: 'center'}}>
                <Subheader style={{color: theme["color-ink"]}}>{StringTable.getString('Enter old PIN code')}</Subheader>
                <TextInputCode
                    cells={6}
                    columns={6} 
                    value={oldPinCode}
                    onChangeText={(t: string) => setOldPinCode(t)}
                    mode={'login'}
                />
            </Section>

            { verifyingOldPinCode ? (
                <Section size={'M'} style={{alignItems: 'center'}}>
                    <ActivityIndicator color={theme["color-ink"]}/>
                </Section>
            ) : null }

            { error ? (
                <Section size={'M'} style={{alignItems: 'center'}}>
                    <Paragraph style={{color: theme["color-negative"]}}>{error}</Paragraph>
                </Section>
            ) : null }

            {oldPinCodeVerified ? (
                <PinCodeInput 
                    onDone={async(chosenPinCode: string) => {
                        try {
                            await changePinCode(chosenPinCode);
                            resolve && resolve(true);
                        } catch(err) {
                            setError(err instanceof Error ? err.message : StringTable.getString('Unknown error'));
                        }
                    }}
                />
            ) : null}
        </View>
    )
}
