import { Platform } from 'react-native';
import * as Contacts from 'expo-contacts';
import { validatePhoneNumber } from 'tools';

export default async function saveContactsToPhonebook(contacts: ContactData[]) {

    if (Platform.OS === 'web') { return };
    const validContacts = contacts.filter(contact => validatePhoneNumber(contact.id));

    const { status } = await Contacts.requestPermissionsAsync();
    if (status !== 'granted') {
        return;
    }

    for (const { id, title } of validContacts) {
        const formattedContact: Contacts.Contact = {
            id: '', // Set an empty string for id, it will be auto-assigned by the Contacts API
            contactType: 'person', // Set the contactType as 'person'
            name: title, // Set the name field
            [Contacts.Fields.FirstName]: title,
            [Contacts.Fields.PhoneNumbers]: [
                {
                    label: 'mobile',
                    number: id,
                },
            ],
        };
        await Contacts.addContactAsync(formattedContact);
    }
};
