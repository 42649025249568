import { useState, useCallback } from 'react';
import { getAuth } from "firebase/auth";

export default function useLogoutImplementation() {
    const [reRender, setReRender] = useState(0);

    const logout = useCallback(async() => {
        try {
            await getAuth().signOut();
        } catch (err) {
            console.log(err);
        }
        setReRender(Math.random());
        console.log('signed out: ', getAuth().currentUser);
    }, []);
    return logout;
}