import React from 'react';
import { View } from 'react-native';
import { Paragraph, Headline } from 'components';

export default function PrivacyScreen() {
    return (
        <View>
            <Headline>
            {`Privacy policy`}
            </Headline>

            <Headline size={'S'}>
            {`Highlights from the policy:`}
            </Headline>
            <Paragraph>
            {`Padel.id does NOT share your data with advertisers & the like for commercial purposes.`}
            </Paragraph>
            <Paragraph>
            {`As a user, you have the right to have your personal data deleted from our system (however, some information may need to be saved in accordance with the Swedish Accounting Act).`}
            </Paragraph>
            <Paragraph>
            {`You can see what personal data relating to you has been processed and how your personal data is used.`}
            </Paragraph>
            <Paragraph>
            {`Information about the operating environment and how the data is protected.`}
            </Paragraph>
            <Paragraph>
            {`Pre-approved subcontractors/suppliers of Guesswork AB (Henceforth referred to as “Padel.id”).`}
            </Paragraph>

            <Headline size={'S'}>
            {`Privacy policy for users of Padel.id`}
            </Headline>
            <Paragraph>
            {`As a platform provider, we work continuously to ensure that your personal privacy is protected when you use the services we provide. Your personal data is processed in accordance with applicable legislation (the GDPR). Therefore, we have implemented this privacy policy that determines how your personal data will be processed by us.`}
            </Paragraph>
            <Paragraph>
            {`Padel.id handles the buyer's personal data in accordance with the Data Protection Regulation (GDPR) and never distributes it to parties who are not affiliated with Padel.id. The only exception is if we explicitly request your approval at the time of purchase.`}
            </Paragraph>
            <Paragraph> 
            {`Padel.id processes all information about our users, i.e., personal name, phone number and other information, with data support. This data is processed in order to manage the customer relationship between you and the facilities and partners affiliated with Padel.id, as well as to provide important information about your purchased services (such as booking confirmation).`}
            </Paragraph>
 
            <Headline size={'S'}>
            {`Parties and liabilities for the processing of your personal data`}
            </Headline>
            <Paragraph>
            {`Padel.id (owned and operated by Guesswork AB, 559200-8097, Hornsgatan 141B, SE-11728 Stockholm) is a platform provider of systems for organising Padel through matchmaking, rank, groups, events and memberships. Padel.id is the data controller for processing your data in Google’s Firebase Analytics (the data processor). Padel.id is a data processor of your personal data for third parties using our ranking and matching algorithm. Users who have their own login information for our website and application are henceforth called “Users”. `}
            </Paragraph>
            <Paragraph>
            {`Padel.id is the data controller for processing the personal data that you share with us when:`}
            </Paragraph>
            <Paragraph>
            {`you create an account on Padel.id`}
            </Paragraph>
            <Paragraph>
            {`you use Padel.id's app`}
            </Paragraph>
            <Paragraph>
            {`you have a question and/or contact us`}
            </Paragraph>
            <Paragraph>
            {`you visit our website and accept cookies`}
            </Paragraph>
 
            <Headline size={'S'}>
            {`What personal data relating to you do we process?`}
            </Headline>
            <Paragraph>
            {`When you create an account with us as a User, we collect data that are required for identification and association with your profile. This includes your Phone number. Completing your information on your profile is optional, such as selecting a username and uploading a profile image.`}
            </Paragraph>


            <Headline size={'S'}>
            {`Handling personal data`}
            </Headline>
            <Paragraph>
            {`Padel.id saves the User's personal data, and other such data that is attributable to them, only to the extent that it is necessary for the User to use their account in so far as the User does not consent to additional services, such as customer surveys and newsletters. The user can always request that Padel.id delete all data stored about them at Padel.id, but then the User can no longer avail of the service.`}
            </Paragraph>
            <Paragraph> 
            {`Padel.id certifies that when data is shared with third parties, it is done in accordance with prevailing legislation and Padel.id is responsible for ensuring that all Padel.id's suppliers comply with EU requirements regarding the handling of personal data.`}
            </Paragraph>
            <Paragraph> 
            {`In this privacy policy, the User's personal data refers to any information about the User through which the User is directly or indirectly identifiable, including, but not limited to, the User's name, phone number, address, date of birth, IP address, account and email address, and other information through which the User can be identified. Padel.id is, unless otherwise specified, the data processor in accordance with the GDPR for Padel.id's processing of personal data. Padel.id may avail of this data from the User's account when the User creates an account, through the User's acceptance of this privacy policy. Padel.id is the data controller for the database of Users hosted on Google's Firebase Analytics (data processor).`}
            </Paragraph>
            <Paragraph>
            {`The personal data is processed by Padel.id for the overall purpose of being able to provide services such as, but not limited to, matchmaking, ranking, event organisations to the User. By registering a user account, the User agrees to the processing of their personal data for such purposes. The User has the right to find out which of their data is processed by Padel.id by contacting Padel.id via email: info@guessenger.com. `}
            </Paragraph>
            <Paragraph>
            {`The User may at any time request that Padel.id ceases processing their personal data and thereby deletes such data belonging to the User and being stored by Padel.id. However, such a request may mean that Padel.id cannot continue to provide an account with Padel.id. The User can terminate his/her user account at any time by deregistering as a user or by notifying Padel.id in writing of such a request. After the user's account has been terminated, the User's personal data will be deleted after a reasonable period of time, providing no other legal requirements such as the Swedish Accounting Act prevent this. `}
            </Paragraph>
            <Paragraph>
            {`Padel.id does not have the right to pass on the User's personal data to a third party unless this is directly necessary for the provision of the service. Consequently, Padel.id does not share personal data for any other commercial purpose.`}
            </Paragraph>
            
            <Headline size={'S'}>
            {`Data Controller and Data Protection Officer`}
            </Headline>
            <Paragraph>
            {`Padel.id, which is operated by Guesswork AB, Org. No. 59200-8097, is the data processor for any processing of personal data carried out by a third party (the seller) and which is described in this privacy policy, as well as the data controller for the database owned by Padel.id.`}
            </Paragraph>
            <Paragraph> 
            {`Our postal address is Hornsgatan 141B, SE-11728 Stockholm. Email address: info@guessenger.com. Phone: +46 763 078 715.`}
            </Paragraph>
            <Paragraph>
            {`Our Data Protection Officer is: Daniel Arnberg`}
            </Paragraph>
            
            <Headline size={'S'}>
            {`Forwarding of personal data`}
            </Headline>
            <Paragraph>
            {`Your personal data that we may send to third parties, and also save, include name, date of birth, address, email address, phone number. Personal identity numbers are only collected and shared when required by federations in each country. All the data that you submit to us through the app is encrypted before storage.`}
            </Paragraph>
 
            <Headline size={'S'}>
            {`Consent`}
            </Headline>
            <Paragraph>
            {`In order for Padel.id to be able to process your personal data, you, the User, must consent. Consent is voluntary for our users. You can withdraw your consent at any time by contacting us at Padel.id. Consent is required for our users to utilise the services provided by Padel.id.`}
            </Paragraph>
            
            <Headline size={'S'}>
            {`Information security`}
            </Headline>
            <Paragraph>
            {`Padel.id is currently being hosted at Google Firebase. Google Firebase is, in part, a subset of the Google Cloud Platform,  a collection of services and tools that constitute a world-leading cloud-based platform for applications. Google Cloud Services has data centres in the US, Europe, Asia and Oceania, where we store our data on servers in the EU.`}
            </Paragraph>
 
            <Headline size={'S'}>
            {`Personal data breaches`}
            </Headline>
            <Paragraph>
            {`Padel.id is liable for processing your data as securely as possible by following the requirements set out in the GDPR.`}
            </Paragraph>
            <Paragraph>
            {`Padel.id assumes its obligations in the event of a personal data breach. In the event of such a breach, Padel.id will report the breach to the Data Inspection Board within 72 hours, and inform the affected parties as well as specify what we will do to remedy and prevent a breach in the future.`}
            </Paragraph>

            <Headline size={'S'}>
            {`Personal data categories (Users)`}
            </Headline>
            <Paragraph>
            {`In order to fulfil our obligations to the User from the moment User creates a profile and starts using Padel.ID’s services; Padel.id process Online information (IP-address), Login information (Phone number) , Contact information (Phone number and User name) and Profile information (Username). `}
            </Paragraph>

            <Headline size={'S'}>
            {`Subcontractor for processing personal data`}
            </Headline>
            <Paragraph>
            {`Padel.id subcontracts processing of personal data to Google Firebase, a subset of the Google Cloud Platform.`}
            </Paragraph>

        </View>
    )
}