import React from 'react';
import { IPressEvent } from 'interfaces';
import { StringTable } from 'utils';
import SavingMessageSheet from './sheets/SavingMessageSheet';
import EditTeamsSheet from './sheets/EditTeamsSheet';
import { Competition, SuperSchedule } from 'business';

export default async function editCompetitionTeams(e: IPressEvent, schedule: SuperSchedule | undefined | null, target: IActionTarget | null, pushModal: any, popAllModals: any) {

    const competition : Competition | undefined = target?.competition;
    if (!competition) { return }

    const editTeamsResult : IEditTeamsSheetResult | null = await pushModal((
        <EditTeamsSheet
            userRefs={competition.participation.userRefs || []}
            fixedTeams={competition.settings.fixedTeams}
            teamSignup={competition.settings.teamSignup}
            groupCount={competition.settings.groupCount || 1}
            groupSizes={competition.getGroupSizes()}
            style={{flex: 1}}
        />
    ), e);

    if (editTeamsResult?.orderedUserRefs) {
        pushModal((
            <SavingMessageSheet title={StringTable.getString('Updating schedule')}/>
        ), e);

        competition.participation.userRefs = editTeamsResult.orderedUserRefs;
        await competition.makeMatches();
        schedule?.replaceAllMatchesInCompetition(competition.id, competition.getAllMatches().map(m => m.serialize()));
    }
    popAllModals();
}
