import { IdentityCache } from 'bridge';
import { UserIdentity } from 'business';

export default async function getUsers(userRefs?: (string | null)[]): Promise<(UserIdentity | null | undefined)[] | void> {

    if (userRefs?.length) {
        const promises = userRefs.map(userRef => IdentityCache.get(userRef));
        const userIdentities : (UserIdentity | null | undefined)[] = await Promise.all(promises);
        return userIdentities;
    }
}
