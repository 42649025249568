import { IPressEvent } from 'interfaces';
import * as Clipboard from 'expo-clipboard';
import NotificationView from '../composites/NotificationView';
import { ReactElement } from 'react';
import { StringTable } from 'utils';

export default async function copyLinkToTarget(e: IPressEvent, target: IActionTarget | null, setNotificationView: (e: ReactElement) => any) {

    const host = window?.location?.host || 'padel.id';
    const protocol = host.includes('localhost') ? '' : 'https://';
    const location = `${protocol}${host}`;

    if (target?.competition) {
        await Clipboard.setStringAsync(`${location}/home/${target.competition.id}`);
        /*
        if (target?.group) {
            await Clipboard.setStringAsync(`${location}/groups/competition/${target.group.id}/${target.competition.id}`);
        }
        else if (target?.club) {
            await Clipboard.setStringAsync(`${location}/clubs/competition/${target.club.id}/${target.competition.id}`);
        }
        else if (target?.competition.settings?.parentCompetitionRef) {
            await Clipboard.setStringAsync(`${location}/home/${target.competition.settings?.parentCompetitionRef}`);
        }
        else {
            await Clipboard.setStringAsync(`${location}/home/${target.competition.id}`);
        }
        */
    }
    else if (target?.group) {
        await Clipboard.setStringAsync(`${location}/groups/${target.group.id}`);
        /*
        if (target?.club) {
            await Clipboard.setStringAsync(`${location}/clubs/group/${target.club.id}/${target.group.id}`);
        }            
        else {
            await Clipboard.setStringAsync(`${location}/groups/${target.group.id}`);
        }
        */
    }
    else if (target?.club) {
        await Clipboard.setStringAsync(`${location}/clubs/${target.club.id}`);
    }

    //@ts-ignore
    setNotificationView(<NotificationView notification={{request: { content: { body: StringTable.getString('Link is copied and can now be pasted anywhere')}}}} onPress={() => setNotificationView(null)} onDismiss={() => setNotificationView(null)}/>);
}