import { useState, useEffect } from 'react';
import NetInfo from '@react-native-community/netinfo';

export default function useOfflineDetection() {

    const [offline, setOffline] = useState(false);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener(state => {
            setOffline(state.isConnected ? false : true);
        });
    
        return () => {
            unsubscribe();
        };
    }, []);

    return offline;
}
