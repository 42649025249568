import React, { useRef, useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import Pressable from '../helpers/Pressable';
import useThemeStyle from '../theme/useThemeStyle';
import useTheme from '../theme/useTheme';

export interface ParagraphProps {
    children?: any,
    style?: any,
    numberOfLines?: number,
    weight?: 'bold' | 'book',
    seeMore?: boolean,
    seeMoreLabel?: string, 
    seeLessLabel?: string
}

export default function Paragraph(props: ParagraphProps) {

    const {
        style: styleProp,
        children,
        numberOfLines,
        seeMore, 
        seeMoreLabel,
        seeLessLabel
    } = props;

    const style = useThemeStyle('Paragraph', props);
    const theme = useTheme();
    const [expanded, setExpanded] = useState(false);
    const [overflows, setOverflows] = useState(false);
    const textRef = useRef<Text>(null);

    const handlePress = () => {
        setExpanded(!expanded);
    };

    function measureText() {
        numberOfLines && textRef.current?.measure((x, y, width, height) => {
            if (height > numberOfLines * theme['text-medium-lineheight']) {
                setOverflows(true);
            }
            else {
                setOverflows(false);
            }
        });
    }

    return (
        <>
            <Text 
                style={[style, styleProp]} 
                numberOfLines={expanded ? undefined : numberOfLines} 
                onPress={seeMore ? handlePress : undefined}
            >
                {children}
            </Text>
            { overflows && seeMore ? (
                <Pressable onPress={handlePress}>
                    <Text style={[style, styleProp, {color: theme["color-primary"], marginTop: theme["spacing-small"]}]}>{expanded ? (seeLessLabel || 'See less') : (seeMoreLabel || 'See more')}</Text>
                </Pressable>
            ) : null }
            { seeMore && numberOfLines ? (
                <Text 
                    ref={textRef}
                    style={[style, styleProp, { opacity: 0, position: 'absolute', zIndex: -1}]} 
                    selectable={false}
                    onLayout={measureText}
                >
                    {children}
                </Text>
            ) : null }
        </>
    )
}
