import { ReactElement } from "react";
import { View, FlatList, Dimensions } from 'react-native';
import { useLayout, useStyles } from '../hooks';
import { Caption, useTheme } from "components";

interface Props {
    header?: ReactElement,
    primaryData: any[], 
    secondaryData?: any[],
    onPrimaryEndReached?: () => void,
    onSecondaryEndReached?: () => void,
    primaryHeader?: ReactElement, 
    secondaryHeader?: ReactElement
    renderPrimaryItem: (props: {item: any, index: number}) => ReactElement,
    renderSecondaryItem: (props: {item: any, index: number, horizontal?: boolean }) => ReactElement, 
    invertPrimaryList?: boolean
}

export default function DoubleFlatList(props: Props) {
    
    const { 
        header, 
        primaryData, 
        secondaryData, 
        onPrimaryEndReached,
        onSecondaryEndReached,
        primaryHeader, 
        secondaryHeader, 
        renderPrimaryItem, 
        renderSecondaryItem,
        invertPrimaryList
    } = props;

    const { layout, columns } = useLayout();
    const styles = useStyles();
    const theme = useTheme();
    
    function keyExtractor(item : any) {
        return Array.isArray(item) ? (item[0].id || item[0]) : (item.id || item);
    }

    function renderSecondaryList() {
        return (
            <View style={layout === 'desktop' ? {flex: 1} : {}}>
                <FlatList
                    ListHeaderComponent={layout === 'desktop' ? (
                        <>
                            { header }
                            {secondaryHeader}
                        </>
                    ) : undefined }
                    horizontal={layout === 'mobile'}
                    data={secondaryData}
                    onEndReached={onSecondaryEndReached}
                    onEndReachedThreshold={0.3}
                    keyExtractor={keyExtractor}
                    renderItem={({item, index}) => (
                        <View style={layout === 'mobile' ? {width: Dimensions.get('window').width * 0.85} : {flex: 1}}>
                            {renderSecondaryItem({item, index, horizontal: true})} 
                        </View>
                    )}
                />

                {(layout === 'mobile' && primaryHeader) || null }
            </View>
        )
    }
    return (
        <View style={[styles.list, layout === 'mobile' ? styles.column : styles.row]}>
            {layout === 'desktop' ? renderSecondaryList() : null }
            <View style={{flex: 2}}>
                {(layout === 'desktop' && primaryHeader) || null }
                <FlatList
                    data={primaryData}
                    key={columns}
                    numColumns={columns}
                    onEndReached={onPrimaryEndReached}
                    inverted={invertPrimaryList}
                    onEndReachedThreshold={0.3}
                    keyExtractor={keyExtractor}
                    ListHeaderComponent={layout === 'mobile' ? (
                        <View>
                            { header }
                            { secondaryHeader }
                            { renderSecondaryList() }
                        </View>
                    ): undefined}
                    renderItem={({item, index}) => (
                        <View style={columns === 2 ? {flex: 0.5} : {flex: 1}}>
                            {renderPrimaryItem({item, index})}
                        </View>
                    )}
                />
            </View>
        </View>
    );
}

